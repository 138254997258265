import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '~services/user.service';
import { WellApiService } from '~services/api/well.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '~services/error-handling.service';
import { WellService } from '~services/well.service';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { CrudService } from '~services/crud.service';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '~models/user';
import { LOCAL_STORAGE_CONST } from '../../constants/local-storage-constants';
// import { BillingSocketService } from '~services/billing-socket.service';
import { NewBillingPortalOptInComponent } from 'src/app/ui-components/new-billing-portal-opt-in/new-billing-portal-opt-in.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

export interface OrderCount {
  notBilledCount: number;
  approvedByDispatcherCount: number;
  approvedByLmo: number;
  billedCount: number;
  approvedCount: number;
}

@Component({
  selector: 'sa-billing-orders',
  templateUrl: './billing-orders.component.html',
  styleUrls: ['./billing-orders.component.scss'],
})
export class BillingOrdersComponent implements OnInit, OnDestroy {
  public displayNav$$ = new BehaviorSubject<boolean>(false);
  isVendor = false;
  userLabel: { name: string; email: string; account: string };
  fracId: number;
  allFracs: any[] = [];
  linkMap = {
    needDataForTicketing: 'notBilledCount',
    waitingForTicketApproval: 'approvedByDispatcherCount',
    readyForInvoicing: 'approvedByLmo',
    waitingForInvoiceApproval: 'billedCount',
    invoiceApproved: 'approvedCount',
  };

  counts: OrderCount;

  navLinks = [];
  filterFracs: FormControl = new FormControl();
  selectedFracs: FormControl = new FormControl();
  orderedAfter: FormControl = new FormControl(null);
  filteredFracsOptions: Observable<any[]>;
  todayDate = new Date();
  dispatcherTabs = ['needDataForTicketing', 'readyForInvoicing'];
  lmoTabs = ['waitingForTicketApproval', 'waitingForInvoiceApproval'];
  compareFn: (o1: any, o2: any) => boolean = this.compareByValue;
  allSelected = false;
  allCompletedSelected = false;
  allNotCompletedSelected = false;
  @ViewChild('allFracSel', { static: false }) fracSel: MatSelect;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.saveDataBeforeDestroy();
  }

  constructor(
    private userService: UserService,
    private wellApiService: WellApiService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlingService,
    private router: Router,
    public wellService: WellService,
    public crud: CrudService,
    public matDialog: MatDialog,
  ) {
    this.isVendor = !(this.userService.isLMOAccount() || this.userService.isShaleAccount());
  }

  ngOnInit() {
    const fracsFromLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONST.BILLING_SELECTED_FRACS));
    const localOrderedAfterFilter = localStorage.getItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER);
    if (localOrderedAfterFilter) {
      this.orderedAfter.setValue(new Date(localOrderedAfterFilter), { emitEvent: false });
      this.wellService.setOrderedAfterFilter(localOrderedAfterFilter);
    }
    if (fracsFromLocalStorage && fracsFromLocalStorage.length) {
      this.router
        .navigate([], {
          relativeTo: this.route,
          queryParams: { fracIds: fracsFromLocalStorage.map((frac) => frac.id).join() },
        })
        .then((value) => {
          this.loadBaseData();
        });
    } else {
      this.loadBaseData();
    }
    this.fracId = this.route.snapshot.params['id'];
    this.userLabel = this.userService.getLabel();
    if (this.userService.isDispatcherAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('/map');
    } else if (this.userService.isLMOAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('//lmo');
    }
    this.navLinks = [
      {
        path: 'needDataForTicketing',
        label: 'Awaiting Loader Data',
        selector: 'billing__tab_awaiting_loader_data',
      },
      {
        path: 'waitingForTicketApproval',
        label: 'Waiting for Ticket Approval',
        selector: 'billing__tab_waiting_for_ticket_approval',
      },
      {
        path: 'readyForInvoicing',
        label: 'Ready for Invoicing',
        selector: 'billing__tab_ready_for_invoicing',
      },
      {
        path: 'waitingForInvoiceApproval',
        label: 'Waiting for Invoice Approval',
        selector: 'billing__tab_waiting_for_invoice_approval',
      },
      {
        path: 'invoiceApproved',
        label: 'Invoice Approved',
        selector: 'billing__tab_invoice_approved',
      },
    ];
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    this.allCompletedSelected = false;
    this.allNotCompletedSelected = false;

    if (this.allSelected) {
      this.fracSel.options.forEach((item: MatOption) => {
        if (item.value) {
          item.select();
        }
      });
    } else {
      this.fracSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.fracSel.close();
  }

  toggleAllCompletedSelection() {
    this.allSelected = false;
    this.allNotCompletedSelected = false;
    this.allCompletedSelected = !this.allCompletedSelected; // to control select-unselect

    this.fracSel.options.forEach((item: MatOption) => {
      item.deselect();
    });

    if (this.allCompletedSelected) {
      this.fracSel.options.forEach((item: MatOption) => {
        if (item.value && item.value.billingComplete) {
          item.select();
        }
      });
    }
    this.fracSel.close();
  }

  toggleAllNotCompletedSelection() {
    this.allSelected = false;
    this.allCompletedSelected = false;
    this.allNotCompletedSelected = !this.allNotCompletedSelected;

    this.fracSel.options.forEach((item: MatOption) => {
      item.deselect();
    });

    if (this.allNotCompletedSelected) {
      this.fracSel.options.forEach((item: MatOption) => {
        if (item.value && !item.value.billingComplete) {
          item.select();
        }
      });
    }
    this.fracSel.close();
  }

  backToWellsUrl(): string[] {
    if (this.userService.isDispatcherAccount()) {
      return ['/', 'billing'];
    } else {
      return ['/', 'lmo'];
    }
  }

  private compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  loadBaseData() {
    let fracIdsFromParams = this.route.snapshot.queryParams['fracIds'] || '';
    fracIdsFromParams = fracIdsFromParams.split(',');
    this.wellService.setValue({});
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        switchMap(() => this.wellApiService.getAllFracs()),
      )
      .subscribe((well) => {
        this.wellService.setAllFracs(well.fracVendors);
        well.fracVendors.forEach((selectedWell) => {
          const index = this.allFracs.findIndex((frac) => frac.id === selectedWell.fracId);
          if (index > -1) {
            if (this.allFracs[index].vendors && this.allFracs[index].vendors.length) {
              this.allFracs[index].vendors.push({
                vendorId: selectedWell.vendorId,
                vendorName: selectedWell.vendorName,
              });
            } else {
              this.allFracs[index].vendors = [{ vendorId: selectedWell.vendorId, vendorName: selectedWell.vendorName }];
            }
          } else {
            this.allFracs.push({
              id: selectedWell.fracId,
              name: selectedWell.fracName,
              billingComplete: selectedWell.fracBillingCompleted,
              vendors: [{ vendorId: selectedWell.vendorId, vendorName: selectedWell.vendorName }],
            });
          }
        });
        const selectedFracsFromParam = this.allFracs.filter((frac) =>
          fracIdsFromParams.find((ids) => +ids === frac.id),
        );
        // this.billingSocketService.sendMessage('All Frac Page Opened');
        if (selectedFracsFromParam.length) {
          this.selectedFracs.setValue(selectedFracsFromParam, { emitEvent: false });
          this.allSelected = selectedFracsFromParam.length === this.allFracs.length;
          this.setSelectedFracs();
        }
        this.addFracListener();
      });
  }

  private addFracListener() {
    this.wellService.orderCounts$.subscribe((counts) => {
      this.counts = counts;
    });
    this.wellService.selectedFracs$.subscribe((fracs) => {
      this.selectedFracs.setValue(fracs, { emitEvent: false });
    });
    this.selectedFracs.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.loadRecent();
    });

    this.orderedAfter.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        localStorage.setItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER, this.orderedAfter.value);
        this.wellService.setOrderedAfterFilter(this.orderedAfter.value);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER);
        this.wellService.setOrderedAfterFilter('');
      }
    });
    this.filteredFracsOptions = this.filterFracs.valueChanges.pipe(
      startWith<string | any>(''),
      map((name) => (name ? this._filter(name) : this.allFracs)),
    );
  }

  private setSelectedFracs() {
    this.wellService.setSelectedFracs(this.selectedFracs.value);
  }
  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.allFracs.filter((option) => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  checkCountForTab(path: string) {
    return (
      this.counts &&
      ((this.userService.isDispatcherAccount() && this.dispatcherTabs.find((tabLink) => tabLink === path)) ||
        (this.userService.isLMOAccount() && this.lmoTabs.find((tabLink) => tabLink === path)))
    );
  }

  menuClick() {
    this.displayNav$$.next(!this.displayNav$$.value);
  }

  loadRecent() {
    if (this.selectedFracs.value.length) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { fracIds: this.selectedFracs.value.map((frac) => frac.id).join() },
      });
    } else {
      this.router.navigate([], { relativeTo: this.route });
    }
    this.setSelectedFracs();
  }

  clearOrderedAfterFilter() {
    this.orderedAfter.setValue(null);
  }

  ngOnDestroy() {
    this.saveDataBeforeDestroy();
  }

  private saveDataBeforeDestroy() {
    this.wellService.setAllFracs([]);
    localStorage.setItem(LOCAL_STORAGE_CONST.BILLING_SELECTED_FRACS, JSON.stringify(this.selectedFracs.value));
  }

  public optIn() {
    this.matDialog.open(NewBillingPortalOptInComponent, {
      width: ' 500px',
    });
  }
}
