import { BoxPickup } from '~lmo/models/order.model';
import { Account } from './account.model';
import { Mesh } from './mesh.model';
import { Mine } from './mine.model';
import { Truck } from './truck.model';
import { User } from './user.model';

export type OrderStatus =
  | 'pending'
  | 'driver_rejected'
  | 'declined'
  | 'dispatched'
  | 'driver_accepted'
  | 'canceled'
  | 'completed';

export interface Order {
  id: number;
  truck: Truck;
  mine: Mine;
  fracId: number;
  mesh: Mesh;
  user: User;
  vendor: Account;
  vendorContractId: number | null;
  loaded: boolean;
  eta: number;
  etaStatus: 'none' | 'arrived' | 'unloading' | 'loading' | 'staging';
  endTimestamp: string;
  loadWeight: number;
  loadNumber: number;
  orderStatus: OrderStatus;
  bolNumber: string;
  boxes: Box[];
  poNumber: string;
  isUpForGrabs: boolean;
  createTimestamp: string;
  startTimestamp: string;
  dispatchTimestamp: string;
  acceptTimestamp: string;
  updateTimestamp: string;
  estimateDeliveryTimestamp: string;
  waitAtLoader: number;
  waitAtWell: number;
  hourlyDetentionRate: number;
  pickupFreeTime: number;
  loadingDetention: number;
  dropOffFreeTime: number;
  unloadingDetention: number;
  deadheadFreeMileage: number;
  deadheadCostPerMile: number;
  deadheadCost: number;
  lineHaul: number;
  billingStatus: 'approved_by_lmo' | 'approved' | 'approved_by_dispatcher' | 'not_billed' | 'billed';
  additionCharge: number;
  additionDeduction: number;
  comment: string;
  dataUploadTimestamp: string | null;
  dataMatched: string | null;
  orderStatusChangeHistory: OrderStatusChangeHistory[];
  lmoLoaderEmail: LmoLoaderEmail;
  mineSiteVisit: MineSiteVisit;
  fracSiteVisit: FracSiteVisit;
  trailer?: {
    name: string;
  };
  reroutedFrom?: Reroute;
  reroutedTo?: Reroute;
  ticketNumber: string;
  description: string;
  actualLoadWeight: number;
  bulkStorageNumber: string;
  isPreLoad: boolean;
  boxPickup: BoxPickup;
  pendingBoxPickup: boolean;
  onBoxMove: boolean;
}

interface Reroute {
  uid: number;
  orderId: number;
  fracId: number;
  fracName: string;
  mineId: number;
  mineName: string;
  loadNumber: number;
  orderStatus: OrderStatus;
}

export interface OrderStatusChangeHistory {
  orderId: number;
  changedBy: string;
  changedTimestamp: string;
}

export interface Box {
  id: number;
  name: string;
  orderId: number;
  loadWeight: number;
  actualLoadWeight?: number;
}

export interface LmoLoaderEmail {
  id: number;
  emailSettingsId: number;
  sender: string;
  uploadTime: string | null;
  uploadCompleted: boolean;
  csvConverted: boolean;
  processed: boolean;
  truckTimeOrdersMatched: string | null;
  lmoLoaderEmailSettings: string | null;
  files: string[] | null;
}

export interface MineSiteVisit {
  id: number;
  orderId: number;
  siteId: number;
  startTimestamp: string;
  endTimestamp: string;
}

export interface FracSiteVisit extends MineSiteVisit {
  id: number;
  orderId: number;
  siteId: number;
  startTimestamp: string;
  endTimestamp: string;
  wellEntryTimestamp?: string;
}

export interface OrderSummary {
  bolNumber: string;
  boxNames: string[];
  destinationEta: string;
  destinationType: 'N/A';
  driverId: number;
  driverName?: string;
  id: number;
  isLocked: boolean;
  loadDetailsIncomplete: boolean;
  loadNumber: number;
  lockReason: string;
  meshId: number;
  meshName: string;
  mineId: number;
  mineName: string;
  missingLoadDetails: string[];
  reroutedFromSiteName: string;
  reroutedToSiteName: string;
  status: OrderStatus;
  ticketNumber: string;
  time: string;
  trailerName: string;
  truckName: string;
  truckingVendorId: number;
  truckingVendorName: string;
}

export interface OrderDetailsUpdate {
  actualLoadWeight: number;
  boxes: Box[];
  bulkStorageNumber: string;
  bolNumber: string;
  ticketNumber: string;
  description: string;
  truckId: number;
}

export interface OrderCreate {
  fracId: number;
  mine: {
    id: number;
  };
  mesh: {
    id: number;
  };
  vendor: {
    id: number;
  };
  startTimestamp: string;
  createTimestamp: string;
}

interface WithOrderStatus {
  orderStatus: OrderStatus;
}

export function isPending(order: WithOrderStatus): boolean {
  return order.orderStatus === 'pending';
}

export function isDriverRejected(order: WithOrderStatus): boolean {
  return order.orderStatus === 'driver_rejected';
}

export function isDeclined(order: WithOrderStatus): boolean {
  return order.orderStatus === 'declined';
}

export function isDispatched(order: WithOrderStatus): boolean {
  return order.orderStatus === 'dispatched';
}

export function isDriverAccepted(order: WithOrderStatus): boolean {
  return order.orderStatus === 'driver_accepted';
}

export function isCancelled(order: WithOrderStatus): boolean {
  return order.orderStatus === 'canceled';
}

export function isComplete(order: WithOrderStatus): boolean {
  return order.orderStatus === 'completed';
}

export function isPendingGroup(order: WithOrderStatus): boolean {
  return isPending(order) || isDriverRejected(order) || isDeclined(order);
}

export function isInProgressGroup(order: WithOrderStatus): boolean {
  return isDispatched(order) || isDriverAccepted(order);
}

export function isCompleteGroup(order: WithOrderStatus): boolean {
  return isCancelled(order) || isComplete(order);
}

export function isEtaNone(order: Order): boolean {
  return order.etaStatus === 'none';
}

export function isEtaArrived(order: Order): boolean {
  return order.etaStatus === 'arrived';
}

export function isEtaLoading(order: Order): boolean {
  return order.etaStatus === 'loading';
}

export function isEtaUnloading(order: Order): boolean {
  return order.etaStatus === 'unloading';
}

export function isEtaStaging(order: Order): boolean {
  return order.etaStatus === 'staging';
}

interface WithBillingStatusStatus {
  billingStatus: 'approved_by_lmo' | 'approved' | 'approved_by_dispatcher' | 'not_billed' | 'billed';
}

export function isBillingStatusApprovedByLmo(order: WithBillingStatusStatus): boolean {
  return order.billingStatus === 'approved_by_lmo';
}

export function isBillingStatusApprovedByDispatcher(order: WithBillingStatusStatus): boolean {
  return order.billingStatus === 'approved_by_dispatcher';
}

export function isBillingStatusApproved(order: WithBillingStatusStatus): boolean {
  return order.billingStatus === 'approved';
}

export function isBillingStatusNotBilled(order: WithBillingStatusStatus): boolean {
  return order.billingStatus === 'not_billed';
}

export function isBillingStatusBilled(order: WithBillingStatusStatus): boolean {
  return order.billingStatus === 'billed';
}

export function isLmoEditable(order: WithBillingStatusStatus): boolean {
  return isBillingStatusApproved(order) || isBillingStatusApprovedByLmo(order) || isBillingStatusNotBilled(order);
}
