<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <div class="heading">
      <div>Users</div>
      <button class="first-btn" *ngIf="canInvite" mat-flat-button color="primary" (click)="inviteVendor(2)">
        Add LMO Account
      </button>
      <button class="first-btn" *ngIf="canInvite" mat-flat-button color="primary" (click)="inviteVendor(3)">
        Add Trucking Vendor
      </button>
      <button class="first-btn" *ngIf="canInvite" mat-flat-button color="primary" (click)="inviteVendor(8)">
        Add Sand Vendor
      </button>
    </div>

    <sa-subnav [subnavMenu]="subnavMenu" (onTabSwitch)="activeMenu = $event"></sa-subnav>

    <div class="content">
      <div class="drivers" *ngIf="activeMenu === subnavLabels.drivers">
        <sa-driver-settings></sa-driver-settings>
      </div>

      <div *ngIf="activeMenu === subnavLabels.users">
        <sa-user-settings></sa-user-settings>
      </div>

      <div *ngIf="activeMenu === subnavLabels.userGroups">
        <sa-user-group></sa-user-group>
      </div>

      <div *ngIf="activeMenu === subnavLabels.crews">
        <sa-crews></sa-crews>
      </div>
    </div>
  </div>
</div>
