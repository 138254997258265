import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';
import { Site } from '../../models/site';
import { Observable } from 'rxjs';

@Injectable()
export class SiteApiService {
  get route(): string {
    return environment.api + '/site';
  }

  constructor(private crud: CrudService) {}

  getSite(id: number, params?: any): Observable<Site> {
    return this.crud.get(this.route + '/' + id, params);
  }

  getSites(params?: any): Observable<Site[]> {
    return this.crud.get(this.route, params);
  }

  getClusteredSites(params: any): Observable<any> {
    return this.crud.get(environment.api + '/clusterSite', params);
  }

  update(id: number, body: any) {
    return this.crud.put(this.route + '/' + id, body);
  }

  delete(id: number): Observable<void> {
    return this.crud.delete(this.route + '/' + id);
  }
}
