<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <div class="heading">
      Unsubscribe
    </div>

    <div class="status">
      <mat-spinner *ngIf="currentState === 'processing'" [diameter]="100"></mat-spinner>
      <span *ngIf="currentState === 'success'"
        >You have been successfully unsubscribed from notifications for this purchase order. You can
        <a [routerLink]="['/', 'login']">Login</a> in to manage your other notifications preferences and to view this
        purchase order.</span
      >
      <span *ngIf="currentState === 'failure'"
        >Something went wrong, please contact ShaleApps support or <a [routerLink]="['/', 'login']">Login</a> in to
        manage your notifications preferences.</span
      >
    </div>
  </div>
</div>
