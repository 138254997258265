<div class="back-layer" [@slideInOut]></div>

<div class="email-form-component" [@slideInOut]>
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span>Upload GR/GI File</span>
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!fileForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="fileForm" [formGroup]="fileForm" autocomplete>
      <div class="w-full">
        <input
          class="w-full"
          type="file"
          placeholder="Upload File"
          (change)="fileChanged($event.target.files)"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
    </form>

    <div class="button-wrap">
      <button
        *ngIf="fileForm"
        mat-flat-button
        color="primary"
        [disabled]="!fileForm.controls['uploadFile'].valid"
        (click)="onSubmit($event)"
      >
        <span>Upload</span>
      </button>
    </div>
  </div>
</div>
