import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ChoiceDialogComponent } from '../../choice-dialog/choice-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderApiService } from '../../../services/api/order.api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, combineLatest, Observable, race, interval, timer } from 'rxjs';
import { filter, map, take, switchMap } from 'rxjs/operators';
import { UserApiService } from '../../../services/api/user.api.service';
import { StoreService } from '../../../services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from '../../../services/crud.service';
import { MapUtilService, MapUtilState } from '../../../services/map-util.service';
import { PhoneNumberPipe } from '../../../pipes/phone-number.pipe';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { RerouteInformation, getTabFromStatus, Order, OrderError, isOrder } from '~models/order.model';
import { RouterStateService } from 'src/app/services/router-state.service';
import { SearchApiService } from '../../../services/api/search.api.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { UserService } from '~services/user.service';
import { OrderService } from '~services/order.service';
import { HelperService } from '~services/helper.service';
import { FileUploadService } from '~services/api/file-upload.service';
import { UploadedBillingFile } from './../../../models/order.model';
import { trackById } from '~utilities/trackById';

@Component({
  selector: 'sa-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  providers: [PhoneNumberPipe],
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @ViewChild('confirmPreload') private confirmPreloadModal: TemplateRef<any>;
  selectedOrder: Order | OrderError;
  selectedFrac: any;
  loading = true;
  driverStatus = '';
  unassignedTrucks: any;
  fracs: any;
  private currentOrderSub: Subscription;
  subscriptions: Subscription[] = [];
  pageInit = false;
  selectedFracId: number;
  selectedLoadNumber: number;
  uploading = '';
  private fileToUpload: File;
  showSection = 1;
  private fileTypesWithUrl = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png'];
  private allowedFileTypes = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt'];
  public fileLoading = false;
  public selectedFileName = '';
  public previewFileUrl = '';
  public bolAttachments: UploadedBillingFile[] = [];
  public truckTicketAttachments: UploadedBillingFile[] = [];
  public otherAttachments: UploadedBillingFile[] = [];
  public trackById = trackById;
  public selectedPurchaseOrder: any;
  public isLoHi: boolean;

  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private orderApiService: OrderApiService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private userApiService: UserApiService,
    private store: StoreService,
    private route: ActivatedRoute,
    private crud: CrudService,
    private router: Router,
    private mapUtilService: MapUtilService,
    private errorHandler: ErrorHandlingService,
    private location: Location,
    private routerStateService: RouterStateService,
    private searchApiService: SearchApiService,
    private orderService: OrderService,
    public featureFlagService: FeatureFlagService,
    public userService: UserService,
    public helperService: HelperService,
    private snackbar: MatSnackBar,
    private fileUploadService: FileUploadService,
  ) {}

  month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  public getShowMarkAsLoaded$(): boolean {
    if (this.selectedOrder['boxPickup']?.beforeOrder) {
      return (
        this.selectedOrder['orderStatus'] === 'driver_accepted' &&
        this.selectedOrder['loaded'] === false &&
        this.selectedOrder['boxPickup']?.completed === true
      );
    } else {
      return this.selectedOrder['orderStatus'] === 'driver_accepted' && this.selectedOrder['loaded'] === false;
    }
  }

  get storeService(): StoreService {
    return this.store;
  }

  ngOnInit() {
    this.pageInit = true;
    this.subscriptions.push(
      this.routerStateService.routerState$.subscribe((routerState) => {
        const newSelectedFracId = parseInt(routerState.params.id, 10);
        const newSelectedLoadNumber = parseInt(routerState.params.load, 10);
        if (newSelectedFracId !== this.selectedFracId || newSelectedLoadNumber !== this.selectedLoadNumber) {
          // Redraw the map
          if (this.selectedFracId !== newSelectedFracId) {
            this.pageInit = true;
          }
          this.selectedFracId = newSelectedFracId;
          this.selectedLoadNumber = newSelectedLoadNumber;
          this.loadData();
        }
      }),
    );

    this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
      if (ready) {
        this.userApiService.getUnassignedUsers().subscribe((trucks) => {
          this.store.set('unassignedTrucks', trucks);
        });
      }
    });

    this.subscriptions.push(
      this.store.select<Array<any>>('unassignedTrucks').subscribe((trucks) => {
        this.unassignedTrucks = trucks;
      }),
    );
    this.isLoHi = this.userService.isLoHi();
  }

  loadData() {
    this.loading = true;
    if (this.currentOrderSub) {
      this.currentOrderSub.unsubscribe();
    }

    this.store.loadSingleOrderForFrac(this.selectedFracId, this.selectedLoadNumber);

    this.currentOrderSub = combineLatest([
      this.store.select<Array<any>>('fracs'),
      this.store.getFracById(this.selectedFracId),
      // race(this.store.getFracById(this.selectedFracId), timer(6000).pipe(take(1))),
      this.store.getOrderByFracAndLoadNumber(this.selectedFracId, this.selectedLoadNumber),
    ]).subscribe(([fracs, selectedFrac, selectedOrder]) => {
      this.fracs = fracs;
      this.selectedFrac = selectedFrac;
      this.selectedOrder = selectedOrder;
      if (this.selectedOrder['purchaseOrder']) {
        this.selectedPurchaseOrder = this.selectedOrder['purchaseOrder'];
      }
      this.loading = false;
      if (isOrder(this.selectedOrder)) {
        let state = 6;
        if (this.selectedOrder.uploadedFiles) {
          this.seperateFiles(this.selectedOrder.uploadedFiles);
        }
        if (this.selectedOrder.orderStatus === 'completed' || this.selectedOrder.orderStatus === 'canceled') {
          state = 7;
        }
        if (this.pageInit) {
          this.updateMap(state);
          if (!this.isFinished()) {
            this.orderService.loadPrediction(this.selectedFracId, this.selectedLoadNumber);
          }
          this.pageInit = false;
        }
      }
      if (this.route.snapshot.queryParamMap.get('swap') === 'true') {
        this.swapDrivers();
        this.router.navigate([], {});
      }
    });
  }

  ngOnDestroy() {
    if (this.currentOrderSub) {
      this.currentOrderSub.unsubscribe();
    }
    this.subscriptions.forEach((_) => {
      _.unsubscribe();
    });
  }

  calculateEta(minutes) {
    if (!minutes) {
      minutes = 0;
    }
    return this.datePipe.transform(new Date(new Date().getTime() + minutes * 60000), 'shortTime');
  }

  calculateReceivingTime(timestamp) {
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    const secDiff = Math.round((now - created) / 1000);
    if (secDiff < 0) {
      return '--';
    }
    const minDiff = Math.round(secDiff / 60);
    const hoursDiff = Math.round(minDiff / 60);
    const daysDiff = Math.round(hoursDiff / 24);
    if (daysDiff >= 1) {
      if (daysDiff > 1) {
        return `${daysDiff} days`;
      } else {
        return `${daysDiff} day`;
      }
    }
    if (hoursDiff >= 1) {
      if (hoursDiff > 1) {
        return `${hoursDiff} hours`;
      } else {
        return `${hoursDiff} hour`;
      }
    }
    if (minDiff >= 0) {
      if (minDiff > 1) {
        return `${minDiff} minutes`;
      } else {
        return `${minDiff} minute`;
      }
    }
    if (secDiff > 1) {
      return `${secDiff} seconds`;
    } else {
      return `${secDiff} second`;
    }
  }

  checkForDriver(order): Boolean {
    if (!order || !order.user) {
      return false;
    }
    if (!(order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted')) {
      return false;
    }
    if (order.user.logs) {
      this.driverStatus = 'Enable Location Services';
      return true;
    }
    if (order.user.activeSession) {
      const now = new Date().getTime();
      const lastSeen = new Date(order.user.lastTimeSeen).getTime();
      if ((now - lastSeen) / (1000 * 60) > 30) {
        this.driverStatus = 'Driver Unreachable';
        return true;
      }
    } else {
      this.driverStatus = 'Login Again';
      return true;
    }
    return false;
  }

  unassignOrder() {
    if (isOrder(this.selectedOrder)) {
      const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
        width: '25%',
        maxWidth: '968px',
        data: {
          context: 'Unassign Driver',
          desc: `This will remove ${
            this.selectedOrder.user ? this.selectedOrder.user.name : 'the driver'
          } from this order. This will move the order back to the Pending list where you can decline, edit, or reassign the order.`,
          button: ['Cancel', 'Unassign Driver'],
        },
      });
      choiceDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.orderApiService.unAssignOrderById(this.selectedOrder.id).subscribe(
            (res) => {
              this.snackBar.open('Successfully Unassigned', null, {
                duration: 5000,
                panelClass: ['snackbar-success'],
              });
              this.goBack();
            },
            (err) => {
              this.errorHandler.showError(err, 5000);
            },
          );
        }
      });
    }
  }

  checkForEditableOrder() {
    return (
      isOrder(this.selectedOrder) &&
      (this.selectedOrder.billingStatus === 'not_billed' || this.selectedOrder.billingStatus === 'approved_by_lmo')
    );
  }

  acceptForDriver() {
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Accept Dispatch for Driver',
        desc: 'Are you sure?',
        button: ['Cancel', 'Accept'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.acceptForDriver(this.selectedOrder).subscribe(
          (res) => {
            this.snackBar.open('Successfully Accepted', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
            this.selectedOrder = res;
            this.store.setOrder(res);
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  private updateStore() {
    this.store
      .select<Array<any>>('fracs')
      .pipe(
        take(1),
        filter((_) => _.length > 0),
        map((fracs) => {
          return fracs.find((frac) => frac.id === this.selectedFrac.id);
        }),
      )
      .subscribe((frac) => {
        frac.orders = frac.orders.map((o) => {
          if (o.id === this.selectedOrder.id) {
            return this.selectedOrder;
          }
          return o;
        });
        this.store.updateFracOrders(frac.orders);
      });
  }

  onBack() {
    this.showSection = 1;
    let state = MapUtilState.InProgressOrderDetail;
    if (
      isOrder(this.selectedOrder) &&
      (this.selectedOrder.orderStatus === 'completed' || this.selectedOrder.orderStatus === 'canceled')
    ) {
      state = MapUtilState.CompletedOrderDetail;
    }
    this.updateMap(state);
  }

  setDriver(driver) {
    const body = {
      userId: driver.id,
    };
    this.orderApiService.swapDrivers(this.selectedOrder.id, body).subscribe(
      (res) => {
        this.snackBar.open('Driver Swapped Successfully', null, {
          duration: 5000,
          panelClass: ['snackbar-success'],
        });
        this.selectedOrder = res;
        let totalFracs: any;
        this.subscriptions.push(
          this.store
            .select<Array<any>>('fracs')
            .pipe(
              filter((_) => _.length > 0),
              take(1),
            )
            .subscribe((fracs) => {
              totalFracs = fracs;
            }),
        );

        totalFracs.forEach((frac) => {
          if (frac.id === this.selectedFrac.id) {
            for (let i = 0; i < frac.orders.length; i++) {
              if (frac.orders[i].id === this.selectedOrder.id) {
                frac.orders[i] = this.selectedOrder;
              }
            }
          }
        });
        this.store.set('fracs', totalFracs);
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  completeOrder() {
    if (isOrder(this.selectedOrder)) {
      let mainText = '';
      let secondaryText = '';
      if (!this.selectedOrder.loaded) {
        mainText = 'Wait for Driver to Depart the Well Geofence?';
        secondaryText = `The driver has not yet picked up their load.
         If you complete this order, you will lose their in & out time at the loader and at the well.
        Please wait for the driver to depart the well and SANDi will automatically complete the load for you.`;
      } else {
        if (this.selectedOrder.etaStatus !== 'arrived') {
          mainText = 'Wait for Driver to Arrive at the Well Geofence?';
          secondaryText = `
            This driver's current ETA to the well is
            ${this.calculateEta(this.selectedOrder.eta)}.
             If you complete this order, you will lose their in & out time at the well.
             Please wait for the driver to depart the well and SANDi will automatically complete the load for you.`;
        } else {
          mainText = 'Wait for Driver to Leave the Well Geofence?';
          secondaryText = `
          This driver's is currently on location.
           If you complete this order, you will lose their in & out time.
           Please wait for the driver to depart the well and SANDi will automatically complete the load for you once they are 1 mile away.`;
        }
      }
      const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
        width: '50%',
        maxWidth: '968px',
        data: {
          context: mainText,
          desc: secondaryText,
          button: ['Wait for Driver', 'Complete Anyway'],
        },
      });
      choiceDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.orderApiService.completeOrderById(this.selectedOrder.id).subscribe(
            (res) => {
              this.snackBar.open('Successfully Completed', null, {
                duration: 5000,
                panelClass: ['snackbar-success'],
              });
              this.goBack();
            },
            (err) => {
              this.errorHandler.showError(err, 5000);
            },
          );
        }
      });
    }
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`sms:${url}`);
  }

  editLoad() {
    this.showSection = 2;
  }

  swapDrivers() {
    this.showSection = 3;
    this.updateMap(MapUtilState.AddDriver);
  }

  goBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigate(['../', '../']);
    }
  }

  isComplete(): boolean {
    return isOrder(this.selectedOrder) && this.selectedOrder.orderStatus === 'completed';
  }

  isCanceled(): boolean {
    return isOrder(this.selectedOrder) && this.selectedOrder.orderStatus === 'canceled';
  }

  isFinished(): boolean {
    return this.isCanceled() || this.isComplete();
  }

  getOrderURL(rerouteInfo: RerouteInformation): string[] | null {
    if (rerouteInfo && rerouteInfo.fracId && rerouteInfo.orderStatus && rerouteInfo.loadNumber) {
      return [
        '/',
        'map',
        'jobs',
        'detail',
        `${rerouteInfo.fracId}`,
        getTabFromStatus(rerouteInfo.orderStatus),
        'order',
        `${rerouteInfo.loadNumber}`,
      ];
    }
    return null;
  }

  isOrder(): boolean {
    return isOrder(this.selectedOrder);
  }

  public backFromEdit(event) {
    this.showSection = event;
    this.loadData();
  }

  public markBoxPickedUp() {
    this.store
      .markBoxPickedUp(this.selectedOrder.id)
      .pipe(
        switchMap(() => this.store.getOrderByFracAndLoadNumber(this.selectedFracId, this.selectedLoadNumber)),
        filter((order) => !!order),
        take(1),
      )
      .subscribe((order) => {
        if (isOrder(order)) {
          order.pendingBoxPickup = false;
          this.selectedOrder = order;
          this.selectedOrder.pendingBoxPickup = false;
          this.selectedOrder.boxPickup.beforeOrder = true;
          this.updateMap(MapUtilState.InProgressOrderDetail);
        }
      });
  }

  public markAsLoaded() {
    const payload = { loaded: !((this.selectedOrder as Order).loaded || false) };
    this.store
      .patchOrder(this.selectedOrder.id, payload)
      .pipe(
        switchMap(() => this.store.getOrderByFracAndLoadNumber(this.selectedFracId, this.selectedLoadNumber)),
        filter((order) => !!order),
        take(1),
      )
      .subscribe((order) => {
        this.selectedOrder = order;
        this.updateMap(MapUtilState.InProgressOrderDetail);
      });
  }

  public startMarkAsPreload() {
    this.dialog.open(this.confirmPreloadModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
    });
  }

  markPreload(notes: string) {
    if (isOrder(this.selectedOrder)) {
      this.store
        .markPreload(this.selectedOrder.id, notes)
        .pipe(
          switchMap(() => this.store.getOrderByFracAndLoadNumber(this.selectedFracId, this.selectedLoadNumber)),
          filter((order) => !!order),
          take(1),
        )
        .subscribe((order) => {
          this.selectedOrder = order;
          this.updateMap(MapUtilState.InProgressOrderDetail);
        });
    }
  }

  private updateMap(state: number) {
    this.mapUtilService.updateMap({ frac: this.selectedFrac, state: state, order: this.selectedOrder });
  }

  public seperateFiles(attachmentsList: UploadedBillingFile[]) {
    this.bolAttachments = [];
    this.truckTicketAttachments = [];
    this.otherAttachments = [];
    attachmentsList.forEach((file: UploadedBillingFile) => {
      switch (file.category) {
        case 'bol_image':
          this.bolAttachments.push(file);
          break;
        case 'ticket_image':
          this.truckTicketAttachments.push(file);
          break;
        default:
          this.otherAttachments.push(file);
          break;
      }
    });
  }

  public onFileChange(event, currentOrderId: number, category: string) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileToUpload = file;
      this.uploadFile(currentOrderId, category);
    }
  }

  private uploadFile(currentOrderId: number, category: string) {
    this.uploading = category;
    this.fileUploadService.uploadFile(currentOrderId, this.fileToUpload, category).subscribe(
      (result: UploadedBillingFile[]) => {
        this.uploading = '';
        this.snackbar.open('File uploaded Successfully', null, {
          duration: 5000,
        });
        this.seperateFiles(result);
      },
      (err) => {
        console.error(err);
        console.log('Upload Failed', currentOrderId, this.fileToUpload);
        this.uploading = '';
        this.errorHandler.showError(`Upload Failed`, 5000);
      },
    );
  }

  public smallFileName(name: string) {
    const parts = name.split('/');
    return parts[parts.length - 1];
  }

  public getFileIcon(fileName: string) {
    const fileNameArray = fileName.toLowerCase().split('.');
    let extension = fileNameArray[fileNameArray.length - 1];
    if (this.allowedFileTypes.includes(extension)) {
      if (extension === 'xlsx') {
        extension = 'xls';
      }
      if (extension === 'docx') {
        extension = 'doc';
      }
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      return `assets/icons/${extension}.svg`;
    } else {
      return `assets/icons/other.png`;
    }
  }

  public downloadFile(file: UploadedBillingFile) {
    this.fileLoading = true;
    this.selectedFileName = file.fileName;
    this.fileUploadService.downloadFile(file.id, file.orderId).subscribe(
      (url) => {
        const fileNameArray = file.fileName.toLowerCase().split('.');
        if (this.fileTypesWithUrl.includes(fileNameArray[fileNameArray.length - 1])) {
          this.previewFileUrl = `${url}`;
          window.open(this.previewFileUrl, '_blank');
        } else {
          this.previewFileUrl = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
          window.open(this.previewFileUrl, '_blank');
        }
        this.fileLoading = false;
      },
      (err) => {
        this.fileLoading = false;
        this.errorHandler.showError('File Download Failed', 5000);
      },
    );
  }

  public deleteFile(file: UploadedBillingFile, event) {
    event.stopPropagation();
    const parts = file.fileName.split('/');
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      data: {
        button: ['Cancel', 'Remove'],
        context: `Are you sure you want to remove ${parts[parts.length - 1]} ?`,
        desc: '',
      },
      maxWidth: '968px',
      width: '30%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileUploadService.removeFile(file.id, file.orderId).subscribe(
          (files) => {
            this.snackbar.open('File Removed Successfully', null, {
              duration: 5000,
            });
            this.seperateFiles(files);
            this.selectedFileName = '';
            this.previewFileUrl = '';
          },
          (err) => {
            this.errorHandler.showError('File Removing Failed', 5000);
          },
        );
      }
    });
  }

  public openInWindow(fileUrl) {
    window.open(fileUrl, '_blank');
  }

  public isImage(fileName) {
    const imageExtensions = ['.jpeg', '.jpg', '.png'];
    return imageExtensions.some((ext) => fileName.includes(ext));
  }
}
