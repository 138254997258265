import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsApiService } from '../../services/api/constants.api.service';
import { WellApiService } from '../../services/api/well.api.service';
import { StorageCurrentStockValidator, StorageSafetyStockValidator } from '../../services/custom-validators';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'sa-mine-selection',
  templateUrl: './mine-selection.component.html',
  styleUrls: ['./mine-selection.component.scss'],
})
export class MineSelectionComponent {
  mines;
  storageForm: FormGroup;
  submitting = false;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  canEdit = false;
  constructor(
    public dialogRef: MatDialogRef<MineSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
  ) {
    this.mines = this.data;
    this.buildForm();
  }

  buildForm() {
    this.storageForm = this.fb.group({
      id: [this.mines[0].id, [Validators.required]],
    });
  }

  submit() {
    if (this.storageForm.valid) {
      this.dialogRef.close(this.storageForm.controls['id'].value);
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }
}
