import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '~services/user.service';

@Directive({
  selector: '[saUserPermission]',
})
export class UserPermissionDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private hasView = false;
  @Input() saUserPermission: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  public ngOnInit() {
    const shouldDisplay = this.userService.hasPermission(this.saUserPermission);
    if (!this.hasView && shouldDisplay) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (this.hasView && !shouldDisplay) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
  }
}
