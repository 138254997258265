import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { omit, sort } from 'remeda';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, mapTo, tap, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import {
  TruckingVendor,
  TruckingVendorContract,
  TruckingVendorContractRequest,
} from '~lmo/models/trucking-vendor.model';
import { ErrorHandlingService } from '~services/error-handling.service';
import { sortByAlpha, sortByName } from '~utilities/commonSorters';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { recordToArray } from '~utilities/recordToArray';

export interface NewTruckingContractBase {
  name: string;
  expirationTime: string;
  hourlyDetentionRate: number;
  maxDetentionPerLoad: number;
  deadheadCostPerMile: number;
  pickupFreeTime: number;
  dropoffFreeTime: number;
  deadheadFreeMileage: number;
  costType: 'fixed' | 'per_ton';
  costsPerLoad: {
    vendorContractId: number;
    minimumMileage: number;
    maximumMileage: number;
    cost: number;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class LmoTruckingVendorsService {
  private networkActive$$ = new BehaviorSubject<boolean>(false);
  private allContractsThrottle$$ = new Subject();
  private allContracts$$ = new BehaviorSubject<Record<string, TruckingVendorContract>>({});
  private vendorsThrottle$$ = new Subject();
  private vendors$$: BehaviorSubject<Record<number, TruckingVendor>> = new BehaviorSubject({});
  private inNetwork$$: BehaviorSubject<TruckingVendor[]> = new BehaviorSubject([]);
  private outOfNetwork$$: BehaviorSubject<TruckingVendor[]> = new BehaviorSubject([]);
  private vendorContracts$$: BehaviorSubject<Record<number, TruckingVendorContract>> = new BehaviorSubject({});

  public get vendors$(): Observable<TruckingVendor[]> {
    return this.vendors$$.asObservable().pipe(map(recordToArray), map(sort(sortByName)));
  }

  public get inNetwork$(): Observable<TruckingVendor[]> {
    this.vendorsThrottle$$.next();
    return this.inNetwork$$.asObservable();
  }

  public get outOfNetwork$(): Observable<TruckingVendor[]> {
    this.vendorsThrottle$$.next();
    return this.outOfNetwork$$.asObservable();
  }

  public get allContracts$(): Observable<TruckingVendorContract[]> {
    this.allContractsThrottle$$.next(null);
    return this.allContracts$$.asObservable().pipe(
      map(recordToArray),
      map(
        sort(
          sortByAlpha<TruckingVendorContract>(['vendorName', 'name']),
        ),
      ),
    );
  }

  public get networkActive$(): Observable<boolean> {
    return this.networkActive$$.asObservable();
  }

  public get vendorContracts$(): Observable<TruckingVendorContract[]> {
    return this.vendorContracts$$
      .asObservable()
      .pipe(
        map(
          (contractsRecord) =>
            Object.keys(contractsRecord).map((key) => contractsRecord[key]) as TruckingVendorContract[],
        ),
      );
  }

  constructor(private http: HttpClient, private errorService: ErrorHandlingService, private snackBar: MatSnackBar) {
    this.setupInAndOutNetwork();
    this.loadTruckingVendors();
    this.loadAllTruckingContracts();
    this.allContractsThrottle$$.pipe(throttleTime(1000)).subscribe(() => this.loadAllTruckingContracts());
    this.allContractsThrottle$$.next(null);
    this.vendorsThrottle$$.pipe(throttleTime(1000)).subscribe(() => this.loadTruckingVendors());
    this.vendorsThrottle$$.next(true);
  }

  public loadContractFor(vendorId: number): void {
    this.vendorContracts$$.next({});
    this.http
      .get<TruckingVendorContract[]>(`${environment.api}/vendor/${vendorId}/contracts`)
      .subscribe((vendorContracts) => {
        const contractsRecord = (vendorContracts || []).reduce((record, contract) => {
          record[contract.id] = contract;
          return record;
        }, {});
        this.vendorContracts$$.next(contractsRecord);
      });
  }

  public updateTruckingContract$(request: TruckingVendorContractRequest): Observable<TruckingVendorContract> {
    return this.http.patch<TruckingVendorContract>(`${environment.api}/vendor/contract/${request.id}`, request).pipe(
      tap((contract) => {
        this.vendorContracts$$.next({
          ...this.vendorContracts$$.value,
          [contract.id]: contract,
        });
        this.allContracts$$.next({
          ...this.allContracts$$.value,
          [contract.id]: contract,
        });
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(null);
      }),
    );
  }

  public createTruckingContract$(request: TruckingVendorContractRequest): Observable<TruckingVendorContract> {
    return this.http.post<TruckingVendorContract>(`${environment.api}/vendor/contract`, request).pipe(
      tap((contract) => {
        this.snackBar.open(
          `Successfully created trucking contract: ${contract.name}, company name: ${contract.vendorName}`,
          null,
          { duration: 5000 },
        );
        this.vendorContracts$$.next({
          ...this.vendorContracts$$.value,
          [contract.id]: contract,
        });
        this.allContracts$$.next({
          ...this.allContracts$$.value,
          [contract.id]: contract,
        });
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(null);
      }),
    );
  }

  public downloadVendorContractPdf$(vendorContractId: number, currentTimezone: string): Observable<any> {
    return this.http.get(`${environment.api}/vendor/contract/${vendorContractId}/pdf?timezone=${currentTimezone}`);
  }

  public async removeTruckingContract(id: number): Promise<boolean> {
    try {
      await this.http.delete<null>(`${environment.api}/vendor/contract/${id}`).toPromise();
      this.vendorContracts$$.next({
        ...omit(this.vendorContracts$$.value, [id]),
      });
      this.allContracts$$.next({
        ...this.allContracts$$.value,
        [id]: { ...this.allContracts$$.value[id], archived: true },
      });
      return true;
    } catch (error) {
      this.errorService.showError(error);
      console.log(error);
      return false;
    }
  }

  public addVendorToNetwork$(vendorId): Observable<boolean> {
    return this.http.post<null>(`${environment.api}/account/network_vendor/${vendorId}`, {}).pipe(
      tap(() => {
        const updatedVendor: TruckingVendor = {
          ...this.vendors$$.value[vendorId],
          inNetwork: true,
        };
        this.vendors$$.next({
          ...this.vendors$$.value,
          [updatedVendor.id]: updatedVendor,
        });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public requestContact$(vendorId): Observable<boolean> {
    return this.http.post<null>(`${environment.api}/account/network_vendor/${vendorId}/contact`, {}).pipe(
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public async createTruckingContracts(vendorIds: number[], base: NewTruckingContractBase): Promise<boolean> {
    this.networkActive$$.next(true);
    try {
      await forkJoin(
        vendorIds.map((vendorId) => {
          return this.createTruckingContract$({
            ...base,
            vendorId,
          });
        }),
      ).toPromise();
      this.networkActive$$.next(false);
      return true;
    } catch (error) {
      this.errorService.showError(error);
      console.log(error);
      this.networkActive$$.next(false);
      return false;
    }
  }

  private setupInAndOutNetwork() {
    this.vendors$$.subscribe((vendorMap) => {
      const inNetwork: TruckingVendor[] = [];
      const outOfNetwork: TruckingVendor[] = [];
      Object.keys(vendorMap)
        .map((key) => vendorMap[key] as TruckingVendor)
        .forEach((truckingVendor) => {
          truckingVendor.inNetwork ? inNetwork.push(truckingVendor) : outOfNetwork.push(truckingVendor);
        });
      inNetwork.sort((a, b) => a.name.localeCompare(b.name));
      outOfNetwork.sort((a, b) => a.name.localeCompare(b.name));
      this.inNetwork$$.next(inNetwork);
      this.outOfNetwork$$.next(outOfNetwork);
    });
  }

  private async loadTruckingVendors(): Promise<void> {
    this.networkActive$$.next(true);
    try {
      const vendors = await this.http.get<TruckingVendor[]>(`${environment.api}/account/trucking-vendor`).toPromise();
      this.vendors$$.next(
        vendors.reduce((record, vendor) => {
          record[vendor.id] = vendor;
          return record;
        }, {}),
      );
    } catch (error) {
      this.errorService.showError(error);
    }
    this.networkActive$$.next(false);
  }

  private async loadAllTruckingContracts() {
    this.networkActive$$.next(true);
    try {
      const contracts = await this.http
        .get<TruckingVendorContract[]>(`${environment.api}/vendor/contracts`)
        .toPromise();
      this.allContracts$$.next(idArrayToRecord(contracts));
    } catch (error) {
      this.errorService.showError(error);
    }
    this.networkActive$$.next(false);
  }
}
