import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TruckingVendorContract {
  archived: boolean;
  costType: 'fixed';
  costsPerLoad: {
    cost: number;
    createdTimestamp: string;
    id: number;
    maximumMileage: number;
    minimumMileage: number;
    updatedTimestamp: string;
    vendorContractId: number;
  }[];
  createdTimestamp: string;
  deadheadCostPerMile: number;
  deadheadFreeMileage: number;
  dropoffFreeTime: number;
  hourlyDetentionRate: number;
  id: number;
  lmoId: number;
  lmoName: string;
  maxDetentionPerLoad: number;
  name: string;
  pickupFreeTime: number;
  updatedTimestamp: string;
  vendorId: number;
  vendorName: string;
}

interface PrimitiveForm {
  deadheadCostPerMile: number;
  deadheadFreeMileage: number;
  dropoffFreeTime: number;
  hourlyDetentionRate: number;
  maxDetentionPerLoad: number;
  name: string;
  pickupFreeTime: number;
}

const primitiveFormKeys: (keyof PrimitiveForm)[] = [
  'deadheadCostPerMile',
  'deadheadFreeMileage',
  'dropoffFreeTime',
  'hourlyDetentionRate',
  'maxDetentionPerLoad',
  'name',
  'pickupFreeTime',
];

@Component({
  selector: 'sa-switch-vendor-contract',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './switch-vendor-contract.component.html',
  styleUrls: ['./switch-vendor-contract.component.scss'],
})
export class SwitchVendorContractComponent implements OnInit {
  @ViewChild('contractSelect', { static: false }) contractSelect;

  public contractForm: FormGroup;
  public selectedContract: FormControl = new FormControl();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id && o1.name === o2.name;
  }

  public get costsPerLoad(): FormArray {
    return this.contractForm.get('costsPerLoad') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<SwitchVendorContractComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private fb: FormBuilder,
  ) {
    this.createCostPerLoadGroup = this.createCostPerLoadGroup.bind(this);
  }

  public ngOnInit() {
    this.selectedContract.valueChanges.subscribe((_) => {
      this.switchContract();
    });
    this.selectedContract.setValue({ ...this._data.contract });
  }

  public switchContract() {
    this.contractForm = null;
    this.setupFormGroup(this.selectedContract.value);
  }

  public setupFormGroup(contract: Partial<TruckingVendorContract>): void {
    const contractInPennies = this.convertPriceToDollar(contract);
    this.contractForm = this.fb.group(contractInPennies);
    primitiveFormKeys.forEach((key) => {
      this.contractForm.get(key).disable();
    });

    const costsPerLoadArray = (contractInPennies.costsPerLoad || []).map(this.createCostPerLoadGroup);

    // Use a form array instead of the stuff that comes in from the endpoint
    this.contractForm.removeControl('costsPerLoad');
    if (costsPerLoadArray.length) {
      this.contractForm.addControl('costsPerLoad', this.fb.array(costsPerLoadArray));
    }
    if (this.costsPerLoad && this.costsPerLoad.length < 1) {
      this.addCostPerLoadLine();
    }
  }

  convertPriceToDollar(contract) {
    const contractInPennies = {
      ...contract,
      hourlyDetentionRate: (contract.hourlyDetentionRate / 100).toFixed(2),
      maxDetentionPerLoad: (contract.maxDetentionPerLoad / 100).toFixed(2),
      deadheadCostPerMile: (contract.deadheadCostPerMile / 100).toFixed(2),
      costsPerLoad: contract.costsPerLoad.map((costPerLoad) => ({
        ...costPerLoad,
        cost: (costPerLoad.cost / 100).toFixed(2),
      })),
    };
    return contractInPennies;
  }

  public addCostPerLoadLine() {
    this.costsPerLoad.push(this.createCostPerLoadGroup({ cost: null, maximumMileage: null, minimumMileage: null }));
  }

  public checkCurrentContract(contract) {
    const liveContract = this._data.vendorContracts.find((selectedContract) => selectedContract.live);
    if (liveContract) {
      return contract.id === liveContract.id && !contract.live;
    } else {
      return false;
    }
  }

  private createCostPerLoadGroup<T extends { cost: number; maximumMileage: number; minimumMileage: number }>(
    t: T,
  ): FormGroup {
    return this.fb.group({
      cost: [{ value: t.cost, disabled: true }, [Validators.required]],
      maximumMileage: [{ value: t.maximumMileage, disabled: true }, [Validators.required, Validators.min(0)]],
      minimumMileage: [{ value: t.minimumMileage, disabled: true }, [Validators.required, Validators.min(0)]],
    });
  }
}
