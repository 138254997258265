import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { WellApiService } from '../services/api/well.api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SubnavMenu } from '../models/subnav-menu';
import { Location } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { ClusterChoiceDialogComponent } from './clustering/cluster-choice-dialog.component';
import { LocalStorageService } from '../services/local-storage.service';
import { IWell } from './models/wells.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../services/error-handling.service';
import { take, filter } from 'rxjs/operators';
import { getFracName } from '../ui-components/pipes/frac-name.pipe';
// import { ConsoleReporter } from 'jasmine';

type SubnavLabel = 'Active Well' | 'Archived Wells';

const SubnavLabels = {
  active: 'Active' as SubnavLabel,
  archive: 'Archived' as SubnavLabel,
};

@Component({
  selector: 'sa-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WellComponent implements OnInit {
  showRemoveFromClusterButton = false;
  displayedArchivedColumns = ['name', 'prefill', 'frac', 'invoice'];
  isLmoAccount = false;
  isCrewLeader = false;
  isStandardUser: boolean;

  isLoading = false;

  activeDataSource: MatTableDataSource<any> = new MatTableDataSource();
  archiveDataSource: MatTableDataSource<any> = new MatTableDataSource();

  selection = new SelectionModel<any>(true, []);
  wells: IWell[] = [];
  wellsInClusters: IWell[] = [];
  wellsNotInClusters: IWell[] = [];
  groupedClusters = [];
  clustersDataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild('wellSelect', { static: false }) wellSelect;

  canEdit = false;

  constructor(
    private crud: CrudService,
    private wellApiService: WellApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private location: Location,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private errorHandler: ErrorHandlingService,
  ) {
    this.canEdit = userService.canEditWell();
  }

  ngOnInit() {
    this.isStandardUser = this.userService.isStandardUser();
    if (this.userService.isDispatcherAccount()) {
      this.router.navigate(['/map']);
      this.location.replaceState('/map');
    }

    if (this.userService.isLMOAccount()) {
      this.isLmoAccount = true;
    }
    if (this.userService.isCrewLeader()) {
      this.isCrewLeader = true;
    }
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe(() => {
        this.getWells();
      });

    this.selection.changed.subscribe(() => {
      this.showRemoveFromClusterButton = this.selection.selected.some((frac) => frac.cluster && !frac.cluster.virtual);
    });
  }

  openInvoicing(well) {
    this.router.navigateByUrl('/fracs/' + well.id + '/orders/needDataForTicketing');
  }

  getWells() {
    this.isLoading = true;
    this.wellApiService.getWells().subscribe(
      (wells) => {
        this.wells = wells;
        this.separateWells();
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
        this.isLoading = false;
      },
    );

    this.wellApiService.getArchivedWells().subscribe(
      (wells) => {
        this.archiveDataSource.data = wells;
        this.isLoading = false;
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
        this.isLoading = false;
      },
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.activeDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.activeDataSource.data.forEach((row) => this.selection.select(row));
  }

  separateWells() {
    this.clearData();
    this.wells.forEach((well) => {
      if (well.cluster && !well.cluster.virtual) {
        this.wellsInClusters.push(well);
        this.groupClusters();
      } else {
        this.wellsNotInClusters.push(well);
        this.activeDataSource.data = this.wellsNotInClusters;
        this.isLoading = false;
      }
    });
  }

  groupClusters() {
    const groupedClusters = this.wellsInClusters.reduce((acc, well, i) => {
      if (acc[well.cluster.id]) {
        acc[well.cluster.id].push(well);
      } else {
        acc[well.cluster.id] = [well];
      }
      return acc;
    }, {});

    const organizedClusters = Reflect.ownKeys(groupedClusters)
      .sort()
      .map((key) => groupedClusters[key]);

    const newGroup = [];
    organizedClusters.forEach((cluster) => {
      const clusterGroup = new MatTableDataSource(cluster);
      newGroup.push(clusterGroup);
    });
    this.groupedClusters = newGroup;
    this.isLoading = false;
  }

  getClusterName(i) {
    return this.groupedClusters[i].data[0].cluster.name;
  }

  openClusterChoices(value?, singleSelection?) {
    const dialogRef = this.dialog.open(ClusterChoiceDialogComponent, {
      width: '300px',
      data: {
        selectedWells: singleSelection ? [singleSelection] : this.selection.selected,
        addToCluster: value ? value : false,
        clusters: Array.from(this.wellsInClusters),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result instanceof HttpErrorResponse) {
        if (result.status >= 400 && result.status < 500) {
          this.errorHandler.showError(result);
        } else {
          this.errorHandler.showError('Failed to add to cluster', 2000);
        }
      } else if (result === 'Success') {
        this.loadData();
        this.snackBar.open('Success!', null, {
          duration: 2000,
        });
      }
      this.selection.clear();
      this.getWells();
    });
  }

  sortClusterTable(event, type, index?) {
    if (type === 'cluster') {
      this.sortFields(event, this.groupedClusters[index]);
    } else if (type === 'uncluster') {
      this.sortFields(event, this.activeDataSource);
    } else if (type === 'archive') {
      this.sortFields(event, this.archiveDataSource);
    }
  }

  sortFields(event, sourceData) {
    switch (event.active) {
      case 'name':
        const sortedArray = sourceData.data.sort((a, b) => getFracName(a).localeCompare(getFracName(b)));
        if (event.direction === 'desc') {
          sortedArray.reverse();
        }
        sourceData.data = sortedArray;
        break;
      case 'prefill':
        const sortedDate = sourceData.data.sort((a, b) => {
          return +new Date(a.prefillDate) - +new Date(b.prefillDate);
        });
        if (event.direction === 'desc') {
          sortedDate.reverse();
        }
        sourceData.data = sortedDate;
        break;
      case 'frac':
        const sortedFracDate = sourceData.data.sort((a, b) => {
          return +new Date(a.fracDate) - +new Date(b.fracDate);
        });
        if (event.direction === 'desc') {
          sortedFracDate.reverse();
        }
        sourceData.data = sortedFracDate;
        break;
    }
  }

  clearData() {
    this.groupedClusters = [];
    this.wellsNotInClusters = [];
    this.wellsInClusters = [];
  }

  removeCluster() {
    const fracIds = [];

    this.selection.selected
      .filter((well) => well.cluster && !well.cluster.virtual)
      .forEach((well) => {
        fracIds.push(well.id);
      });

    const requestBody = {
      fracIds: fracIds,
    };

    this.wellApiService.removeCluster(requestBody).subscribe(
      (res) => {
        this.clearData();
        this.loadData();
        this.snackBar.open('Cluster successfully removed', null, {
          duration: 2000,
        });
      },
      (err) => {
        this.errorHandler.showError(err);
      },
    );
  }

  loadData() {
    this.selection.clear();
    this.getWells();
  }

  openWellForm(id) {
    if (id) {
      this.router.navigate(['/wells/edit/' + id]);
    } else {
      this.router.navigate(['/wells/edit/new']);
    }
  }

  openWellFormWithExistingCluster(cluster) {
    this.localStorageService.setItem(`cluster-${cluster.id}`, cluster.name);
    this.router.navigate(['/wells/edit/new'], { queryParams: { clusterId: cluster.id } });
  }
}
