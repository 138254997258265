import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SubnavMenu } from '~models/subnav-menu';

type SubnavLabel = 'Carriers' | 'None';

const SubnavLabels = {
  carriers: 'Carriers' as SubnavLabel,
};

@Component({
  selector: 'sa-subcarrier',
  templateUrl: './subcarrier.component.html',
  styleUrls: ['./subcarrier.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubcarrierComponent implements OnInit {
  subnavLabels = SubnavLabels;
  subnavMenu: SubnavMenu[] = [{ label: this.subnavLabels.carriers, active: true }];
  activeMenu = this.subnavLabels.carriers;

  constructor() {}

  ngOnInit(): void {}
}
