<div class="order-detail-content">
  <h3>Map Details</h3>
  <div class="button-row">
    <button (click)="driverReplay.loadOrderMapInfo(fracId, loadNumber)" [disabled]="driverReplay.loading$ | async">
      Load Map
      <mat-spinner [diameter]="20" *ngIf="driverReplay.loading$ | async"></mat-spinner>
    </button>
  </div>
  <div class="waypoint-stats" *ngIf="waypointStats$ | async as waypointStats">
    <strong>{{ waypointStats.goodWaypoints }}</strong> "good" waypoints out of
    <strong>{{ waypointStats.totalWaypoints }}</strong> total
  </div>
  <div class="scrubber" *ngIf="(waypoints$ | async)?.length as length">
    <input
      type="range"
      min="0"
      [max]="length"
      (input)="sliderChange($event)"
      [value]="driverReplay.selectedIndex$ | async"
    />
  </div>
  <div class="tools" *ngIf="(waypoints$ | async)?.length > 0">
    <span class="tool">
      <mat-icon (click)="driverReplay.goToBeginning()">fast_rewind</mat-icon>
    </span>
    <span class="tool">
      <mat-icon (click)="driverReplay.play()">play_arrow</mat-icon>
    </span>
    <span class="tool">
      <mat-icon (click)="driverReplay.pause()">pause</mat-icon>
    </span>
    <span class="tool">
      <mat-icon (click)="driverReplay.goToEnd()">fast_forward</mat-icon>
    </span>
  </div>
  <div class="tools" *ngIf="(waypoints$ | async)?.length > 0">
    <span class="tool">
      <mat-slider
        min="1"
        [max]="100"
        (input)="speedChange($event)"
        thumbLabel
        [displayWith]="speedFormatter"
        [value]="driverReplay.speed$ | async"
      ></mat-slider>
    </span>
  </div>
  <div class="details" *ngIf="selectedWaypoint$ | async as waypoint">
    <h4>{{ waypoint.timestamp | date: 'medium' }} (accuracy: {{ waypoint.accuracy }})</h4>
    <h4>{{ waypoint.speed | mph | number: '1.0-0' }} MPH</h4>
  </div>
</div>
