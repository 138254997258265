<div class="back-layer" [@slideInOut]></div>

<div class="email-form-component" [@slideInOut]>
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span *ngIf="!selectedEmail">Add</span>
        <span *ngIf="selectedEmail">Edit</span>
        Loader Integration
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!emailForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="emailForm" [formGroup]="emailForm" autocomplete>
      <div class="form-field">
        <label>Loader</label>

        <mat-form-field>
          <mat-select
            placeholder="{{
              (allowMultipleMines$ | async) && !emailForm.controls['loader'].value
                ? 'Loader defined in file data'
                : 'Loader'
            }}"
            saAutomatedSelector="loader-integration__loader"
            [formControl]="emailForm.controls['loader']"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'Loader'"
                [noEntriesFoundLabel]="'No matching loader found'"
                [formControl]="filterLoader"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'" [value]="null">
              Loader defined in file data
            </mat-option>

            <mat-option *ngFor="let mine of filteredMines$ | async" [value]="mine.id">
              {{ mine.site.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="emailForm.controls['loader'].hasError('required')">
            Input is required
          </mat-error>
          <mat-hint *ngIf="emailForm.controls['loader'].value">
            <ng-container *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'">
              Data in files sent to this email will be associated with this loader
            </ng-container>
          </mat-hint>
          <mat-hint *ngIf="!emailForm.controls['loader'].value">
            <ng-container *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'">
              Loader will be determined based on data in files sent to this email
            </ng-container>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>Email</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            saAutomatedSelector="loader-integration__email"
            [formControl]="emailForm.controls['email']"
          />
          <span matSuffix>@{{ email }}</span>
          <mat-error *ngIf="emailForm.controls['email'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>Loader Email</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Loader Email"
            saAutomatedSelector="loader-integration__loaderEmail"
            [formControl]="emailForm.controls['loaderEmail']"
          />
          <mat-error *ngIf="emailForm.controls['loaderEmail'].hasError('email')">
            Input should be email
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="button-wrap">
      <button
        *ngIf="emailForm"
        mat-flat-button
        color="primary"
        [disabled]="!emailForm.controls['email'].valid || !emailForm.controls['loader'].valid"
        (click)="onSubmit($event)"
      >
        <span *ngIf="!selectedEmail" saAutomatedSelector="loader-integration__save-button">Save</span>
        <span *ngIf="selectedEmail" saAutomatedSelector="loader-integration__update-button">Update</span>
      </button>
    </div>
  </div>
</div>
