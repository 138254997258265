import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Session } from '../../models/session';
import { Site } from '../../models/site';
import { DetailViewModels } from '../../constants/detail-view-models';
import { User } from '../../models/user';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subcarrier } from '~models/subcarrier.model';

@Component({
  selector: 'sa-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class DetailViewComponent {
  @Input() model: any;
  @Output() onDateSelected: EventEmitter<Session> = new EventEmitter<Session>();
  @Output() onSiteUpdated: EventEmitter<{ id: number; site: Site }> = new EventEmitter<{ id: number; site: Site }>();
  @Output() onUserUpdated: EventEmitter<User> = new EventEmitter<User>();
  @Output() onSubcarrierUpdated: EventEmitter<Subcarrier> = new EventEmitter<Subcarrier>();
  @Output() onCloseClicked: EventEmitter<void> = new EventEmitter<void>();

  detailViewModels = DetailViewModels;

  dateSelected(order: any): void {
    this.onDateSelected.emit(order);
  }

  siteUpdated(site: { id: number; site: Site }): void {
    this.onSiteUpdated.emit(site);
  }

  userUpdated(user: User): void {
    this.onUserUpdated.emit(user);
  }

  subcarrierUpdated(subcarrier: Subcarrier): void {
    this.onSubcarrierUpdated.emit(subcarrier);
  }

  closeClicked() {
    this.onCloseClicked.emit();
  }
}
