import { NgModule } from '@angular/core';
import { MaterialModule } from '../material-module/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import {
  ButtonComponent,
  SaButtonDirective,
  SaDangerButtonDirective,
  SaSecondaryButtonDirective,
} from './button/button.component';
import { MapSearchComponent } from './map-search/map-search.component';
import { MapSearchInputComponent } from './map-search/map-search-input.component';
import { MapSearchResultsComponent } from './map-search/map-search-results.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { DriverDetailViewComponent } from './detail-view/driver-detail-view.component';
import { SiteDetailViewComponent } from './detail-view/site-detail-view.component';
import { DriverProfileDetailViewComponent } from './detail-view/driver-profile-detail-view.component';
import { NavComponent } from './nav/nav.component';
import { SubnavComponent } from './subnav/subnav.component';
import { HighlightSearchPipe } from '../pipes/highlight.pipe';
import { DetailViewInputComponent } from './detail-view-input/detail-view-input.component';
import { UserDetailViewComponent } from './detail-view/user-detail-view.component';
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CardComponent } from './card/card.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SaNumberInputDirective } from './sa-number-input/sa-number-input.directive';
import { TooltipTimestampPipe } from '../pipes/tooltip-timestamp.pipe';
import { JobsComponent } from './jobs-view/jobs.component';
import { FracListComponent } from './jobs-view/frac-list/frac-list.component';
import { FracDetailComponent } from './jobs-view/frac-detail/frac-detail.component';
import { PendingOrderDetailComponent } from './jobs-view/pending-order-detail/pending-order-detail.component';
import { AddDriverComponent } from './jobs-view/add-driver/add-driver.component';
import { OrderDetailComponent } from './jobs-view/order-detail/order-detail.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChoiceDialogComponent } from './choice-dialog/choice-dialog.component';
import { UpdateLoadComponent } from './jobs-view/update-load/update-load.component';
import { ReassignDialogComponent } from './reassign-dialog/reassign-dialog.component';
import { DeclineDialogComponent } from './decline-dialog/decline-dialog.component';
import { MapGeneralComponent } from './map-general/map-general.component';
import { MapVendorComponent } from './map-vendor/map-vendor.component';
import { AddLoaderComponent } from './jobs-view/add-loader/add-loader.component';
import { NumberDecimalPipe } from '../pipes/number-decimal.pipe';
import { SandiPageComponent } from './sandi-page/sandi-page.component';
import { BulkDeclineDialogComponent } from './bulk-decline-dialog/bulk-decline-dialog.component';
import { AutomatedSelectorDirective } from './automated-selector/automated-selector.directive';
import { DisableFormatDirective } from './scroll-input/disable-format.directive';
import { NavTopBarComponent } from './nav-top-bar/nav-top-bar.component';
import { FeatureFlagPipe } from '../feature-flag.pipe';
import { FeatureFlagDirective } from '../feature-flag.directive';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { ReassignTruckComponent } from './reassign-truck/reassign-truck.component';
import { OrderMapControlsComponent } from './order-map-controls/order-map-controls.component';
import { MphPipe } from '../pipes/mph.pipe';
import { OrderPredictionComponent } from './order-prediction/order-prediction.component';
import { FracDetailCompletedOrdersComponent } from './frac-detail-completed-orders/frac-detail-completed-orders.component';
import { FilteredAutocompleteComponent } from './filtered-autocomplete/filtered-autocomplete.component';
import { CurrencyInputDirective } from './currency-input.directive';
import { pipes } from './pipes';
import { NetworkActiveButtonComponent } from './network-active-button/network-active-button.component';
import { DispatcherModule } from '~dispatcher/dispatcher.module';
import { AllowedAccountTypesDirective } from './allowed-account-types.directive';
import { AllowedAccountTypesEnabledForDirective } from './allowed-account-types-enabled.directive';
import { TopNavWithIconComponent } from './top-nav-with-icon/top-nav-with-icon.component';
import { NavSandVendorComponent } from './nav-sand-vendor/nav-sand-vendor.component';
import { CountdownPipe } from '../countdown.pipe';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { UserPermissionDirective } from '../user-permission.directive';
import { FracNamePipe } from './pipes/frac-name.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { OrderSearchDirective } from './order-search.directive';
import { OrderSearchHotkeyComponent } from './order-search-hotkey/order-search-hotkey.component';
import { NavLmoComponent } from './nav-lmo/nav-lmo.component';
import { NavDispatcherComponent } from './nav-dispatcher/nav-dispatcher.component';
import { OnOrderTooLongPipe } from '../pipes/on-order-too-long.pipe';
import { LongEscapeTipComponent } from './long-escape-tip/long-escape-tip.component';
import { LongEscapeComponent } from './long-escape/long-escape.component';
import { LongEscapeDirective } from './long-escape.directive';
import { ProgressiveRenderPipe } from './pipes/progressive-render.pipe';
import { CurrencyInPenniesPipe } from './pipes/currency-in-pennies.pipe';
import { AccountTypeDirective } from './account-type-with-permissions.directive';
import { NewBillingPortalOptInComponent } from './new-billing-portal-opt-in/new-billing-portal-opt-in.component';
import { ConfirmActionDialogComponent } from './confirm-action-dialog/confirm-action-dialog.component';
import { PluckPipe } from './pipes/pluck.pipe';
import { JoinWithAndPipe } from './pipes/join-with-and.pipe';
import { LohiPreloadStatusComponent } from './lohi-preload-status/lohi-preload-status.component';
import { TrackByKeyPipe } from './track-by-key.pipe';
import { StandardDatePipe } from './standard-date.pipe';
import { DistributionCenterNamePipe } from './pipes/distribution-center-name.pipe';
import { PoLoaderPoundTonConversionPipe } from './pipes/po-loader-pound-ton-conversion.pipe';
import { PoLoaderPoundTonUnitPipe } from './pipes/po-loader-pound-ton-unit.pipe';
import { MinZeroPipe } from './pipes/min-zero.pipe';
import { IsBrokerageDirective } from './is-brokerage.directive';
import { CarrierDetailViewComponent } from './detail-view/carrier-detail-view.component';
import { AssignSubcarrierDialogComponent } from './jobs-view/pending-order-detail/assign-subcarrier-dialog/assign-subcarrier-dialog.component';
import { SubcarrierChangeConfirmationDialogComponent } from './jobs-view/pending-order-detail/subcarrier-change-confirmation-dialog/subcarrier-change-confirmation-dialog.component';
import { DcDetailComponent } from './jobs-view/dc-detail/dc-detail.component';
import { DcOrderDetailComponent } from './jobs-view/dc-order-detail/dc-order-detail.component';
import { DcPendingOrderDetailComponent } from './jobs-view/dc-pending-order-detail/dc-pending-order-detail.component';
import { OrderPickupSiteNamePipe } from './pipes/order-pickup-site-name.pipe';
import { DcOrderDirectionPillComponent } from './jobs-view/dc-detail/dc-order-direction-pill/dc-order-direction-pill.component';

@NgModule({
  imports: [
    DispatcherModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    MatAutocompleteModule,
    NgxMatIntlTelInputModule,
  ],
  declarations: [
    ButtonComponent,
    SaButtonDirective,
    SaSecondaryButtonDirective,
    SaDangerButtonDirective,
    MapSearchComponent,
    MapSearchInputComponent,
    MapSearchResultsComponent,
    DetailViewComponent,
    SiteDetailViewComponent,
    DriverDetailViewComponent,
    DriverProfileDetailViewComponent,
    UserDetailViewComponent,
    NavComponent,
    TopNavComponent,
    SubnavComponent,
    DetailViewInputComponent,
    LoadingSpinnerComponent,
    CardComponent,
    HighlightSearchPipe,
    TooltipTimestampPipe,
    PhoneNumberPipe,
    FilterPipe,
    NumberDecimalPipe,
    SaNumberInputDirective,
    JobsComponent,
    FracListComponent,
    FracDetailComponent,
    AddDriverComponent,
    PendingOrderDetailComponent,
    OrderDetailComponent,
    ChoiceDialogComponent,
    UpdateLoadComponent,
    ReassignDialogComponent,
    DeclineDialogComponent,
    MapGeneralComponent,
    MapVendorComponent,
    AddLoaderComponent,
    SandiPageComponent,
    BulkDeclineDialogComponent,
    AutomatedSelectorDirective,
    DisableFormatDirective,
    NavTopBarComponent,
    FeatureFlagPipe,
    FeatureFlagDirective,
    OrderSummaryComponent,
    ReassignTruckComponent,
    OrderMapControlsComponent,
    MphPipe,
    OrderPredictionComponent,
    FracDetailCompletedOrdersComponent,
    FilteredAutocompleteComponent,
    CurrencyInputDirective,
    ...pipes,
    NetworkActiveButtonComponent,
    AllowedAccountTypesDirective,
    AllowedAccountTypesEnabledForDirective,
    TopNavWithIconComponent,
    NavSandVendorComponent,
    UserPermissionDirective,
    CountdownPipe,
    FracNamePipe,
    DistributionCenterNamePipe,
    HighlightPipe,
    OrderSearchDirective,
    OrderSearchHotkeyComponent,
    NavLmoComponent,
    NavDispatcherComponent,
    OnOrderTooLongPipe,
    LongEscapeTipComponent,
    LongEscapeComponent,
    LongEscapeDirective,
    ProgressiveRenderPipe,
    CurrencyInPenniesPipe,
    AccountTypeDirective,
    NewBillingPortalOptInComponent,
    ConfirmActionDialogComponent,
    PluckPipe,
    JoinWithAndPipe,
    LohiPreloadStatusComponent,
    TrackByKeyPipe,
    StandardDatePipe,
    PoLoaderPoundTonConversionPipe,
    PoLoaderPoundTonUnitPipe,
    MinZeroPipe,
    IsBrokerageDirective,
    CarrierDetailViewComponent,
    AssignSubcarrierDialogComponent,
    SubcarrierChangeConfirmationDialogComponent,
    DcDetailComponent,
    DcOrderDetailComponent,
    DcPendingOrderDetailComponent,
    OrderPickupSiteNamePipe,
    DcOrderDirectionPillComponent,
  ],
  exports: [
    ButtonComponent,
    SaButtonDirective,
    SaSecondaryButtonDirective,
    SaDangerButtonDirective,
    MapSearchComponent,
    MapSearchInputComponent,
    MapSearchResultsComponent,
    DetailViewComponent,
    SiteDetailViewComponent,
    DriverDetailViewComponent,
    DriverProfileDetailViewComponent,
    UserDetailViewComponent,
    NavComponent,
    TopNavComponent,
    SubnavComponent,
    DetailViewInputComponent,
    LoadingSpinnerComponent,
    CardComponent,
    HighlightSearchPipe,
    PhoneNumberPipe,
    FilterPipe,
    NumberDecimalPipe,
    SaNumberInputDirective,
    JobsComponent,
    FracListComponent,
    PendingOrderDetailComponent,
    AddDriverComponent,
    FracDetailComponent,
    ReassignDialogComponent,
    DeclineDialogComponent,
    MapGeneralComponent,
    MapVendorComponent,
    SandiPageComponent,
    BulkDeclineDialogComponent,
    AutomatedSelectorDirective,
    DisableFormatDirective,
    NavTopBarComponent,
    FeatureFlagPipe,
    FeatureFlagDirective,
    UpdateLoadComponent,
    MphPipe,
    OrderSummaryComponent,
    FilteredAutocompleteComponent,
    CurrencyInputDirective,
    ...pipes,
    NetworkActiveButtonComponent,
    AllowedAccountTypesDirective,
    AllowedAccountTypesEnabledForDirective,
    TopNavWithIconComponent,
    CountdownPipe,
    NgxMatIntlTelInputModule,
    UserPermissionDirective,
    FracNamePipe,
    DistributionCenterNamePipe,
    OrderSearchDirective,
    OrderSearchHotkeyComponent,
    NavLmoComponent,
    NavDispatcherComponent,
    OnOrderTooLongPipe,
    LongEscapeDirective,
    AccountTypeDirective,
    NewBillingPortalOptInComponent,
    ConfirmActionDialogComponent,
    PluckPipe,
    JoinWithAndPipe,
    LohiPreloadStatusComponent,
    TrackByKeyPipe,
    StandardDatePipe,
    PoLoaderPoundTonConversionPipe,
    PoLoaderPoundTonUnitPipe,
    MinZeroPipe,
    IsBrokerageDirective,
    OrderPickupSiteNamePipe,
  ],
  entryComponents: [
    OrderSearchHotkeyComponent,
    LongEscapeComponent,
    LongEscapeTipComponent,
    NewBillingPortalOptInComponent,
    ConfirmActionDialogComponent,
  ],
})
export class UIComponentsModule {}
