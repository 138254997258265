<div class="back-layer" [@slideInOut]></div>

<div class="email-form-component" [@slideInOut]>
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span *ngIf="!selectedEmail">Add</span>
        <span *ngIf="selectedEmail">Edit</span>
        Loader Integration
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!emailForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="emailForm" [formGroup]="emailForm" autocomplete>
      <div class="form-field">
        <label>Loader</label>

        <mat-form-field>
          <mat-select
            placeholder="Loader"
            saAutomatedSelector="loader-integration__loader"
            [compareWith]="compareFn"
            [formControl]="emailForm.controls['loader']"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'Loader'"
                [noEntriesFoundLabel]="'No matching loader found'"
                [formControl]="filterLoader"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let mine of filteredMines | async" [value]="mine">
              {{ mine.site.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="emailForm.controls['loader'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>Email</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            saAutomatedSelector="loader-integration__email"
            [formControl]="emailForm.controls['email']"
          />
          <span matSuffix>@{{ email }}</span>
          <mat-error *ngIf="emailForm.controls['email'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field checkbox-field">
        <label>Date Only</label>
        <mat-checkbox
          saAutomatedSelector="loader-integration__date-checkbox"
          [formControl]="emailForm.controls['isDateOnly']"
        ></mat-checkbox>
        <mat-hint
          >Check this option if the provided excel file has only date to match with the order. The Excel does not have
          the exact truck entry time.</mat-hint
        >
        <mat-error *ngIf="emailForm.controls['isDateOnly'].hasError('required')">
          Input is required
        </mat-error>
      </div>

      <div class="form-field">
        <label>Time Parser</label>
        <mat-form-field>
          <mat-select
            placeholder="Time Parser"
            saAutomatedSelector="loader-integration__time-parser"
            [formControl]="emailForm.controls['timeParser']"
          >
            <mat-option *ngFor="let format of timeFormats" [value]="format.key">
              {{ currentTime | date: format.value }} ({{ format.value }})
            </mat-option>
          </mat-select>

          <mat-error *ngIf="emailForm.controls['timeParser'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>File Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="File Name"
            saAutomatedSelector="loader-integration__file-name"
            [formControl]="emailForm.controls['fileName']"
          />

          <mat-error *ngIf="emailForm.controls['fileName'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>Sheet Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Sheet Name"
            saAutomatedSelector="loader-integration__sheet-name"
            [formControl]="emailForm.controls['sheetName']"
          />
        </mat-form-field>
      </div>

      <div class="form-field checkbox-field">
        <label>Overwrite</label>
        <mat-checkbox [formControl]="emailForm.controls['canOverwrite']"></mat-checkbox>
        <mat-hint
          >Check this option if you want to overwrite existing data in case uploaded excel truck name and timings
          matches with the order truck name and timings. Overwrite BOL Number, Actual Load Weight and Ticket Number.
        </mat-hint>
        <mat-error *ngIf="emailForm.controls['canOverwrite'].hasError('required')">
          Input is required
        </mat-error>
      </div>

      <mat-accordion class="field-section">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>Excel Fields</h3>
              <h4>
                Field's default names are given below. You can enter the values if you want to override the field names.
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="form-field">
            <label>BOL Number</label>
            <mat-form-field>
              <input matInput placeholder="BOL Number" [formControl]="emailForm.controls['bolNumber']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('bolNumber') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Vendor Name</label>
            <mat-form-field>
              <input matInput placeholder="Vendor Name" [formControl]="emailForm.controls['vendorName']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('vendorName') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Truck Number</label>
            <mat-form-field>
              <input matInput placeholder="Truck Number" [formControl]="emailForm.controls['truckNumber']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('truckNumber') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Arrival Time</label>
            <div class="toggle-wrap">
              <mat-form-field>
                <input matInput placeholder="Arrival Date" [formControl]="emailForm.controls['arrivalDate']" />
                <mat-hint align="start">
                  <span>
                    {{ getDefaultValues('arrivalTime') }}
                  </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field *ngIf="arrivalToggle == 2">
                <input matInput placeholder="Arrival Time" [formControl]="emailForm.controls['arrivalTime']" />
              </mat-form-field>
            </div>
            <mat-button-toggle-group
              name="arrivalType"
              #arrivalGroup="matButtonToggleGroup"
              [value]="arrivalToggle"
              (change)="onToggle($event, 1)"
              [disabled]="emailForm.controls['isDateOnly'].value"
            >
              <mat-button-toggle value="1">1</mat-button-toggle>
              <mat-button-toggle value="2">2</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="form-field">
            <label>Departure Time</label>
            <div class="toggle-wrap">
              <mat-form-field>
                <input matInput placeholder="Departure Date" [formControl]="emailForm.controls['departureDate']" />
                <mat-hint align="start">
                  <span>
                    {{ getDefaultValues('departureTime') }}
                  </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field *ngIf="departureToggle == 2">
                <input matInput placeholder="Departure Date" [formControl]="emailForm.controls['departureTime']" />
              </mat-form-field>
            </div>
            <mat-button-toggle-group
              name="departureType"
              #departureGroup="matButtonToggleGroup"
              [value]="departureToggle"
              (change)="onToggle($event, 2)"
              [disabled]="emailForm.controls['isDateOnly'].value"
            >
              <mat-button-toggle value="1">1</mat-button-toggle>
              <mat-button-toggle value="2">2</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="form-field">
            <label>PO Number</label>
            <mat-form-field>
              <input matInput placeholder="PO Number" [formControl]="emailForm.controls['poNumber']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('poNumber') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Net Weight</label>
            <mat-form-field>
              <input matInput placeholder="Net Weight" [formControl]="emailForm.controls['netWeight']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('netWeight') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Weight Unit</label>
            <mat-form-field>
              <mat-select placeholder="Weight Unit" [formControl]="emailForm.controls['weightUnit']">
                <mat-option *ngFor="let unit of weightUnits" [value]="unit">
                  {{ unit }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="emailForm.controls['weightUnit'].hasError('required')">
                Input is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Ticket Number</label>
            <mat-form-field>
              <input matInput placeholder="Ticket Number" [formControl]="emailForm.controls['ticketNumber']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('ticketNumber') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field">
            <label>Load Number</label>
            <mat-form-field>
              <input matInput placeholder="Load Number" [formControl]="emailForm.controls['loadNumber']" />
              <mat-hint align="start">
                <span>
                  {{ getDefaultValues('loadNumber') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>

    <div class="button-wrap">
      <button *ngIf="emailForm" mat-flat-button color="primary" (click)="onSubmit($event)">
        <span *ngIf="!selectedEmail" saAutomatedSelector="loader-integration__save-button">Save</span>
        <span *ngIf="selectedEmail" saAutomatedSelector="loader-integration__update-button">Update</span>
      </button>
      <label class="upload-btn" *ngIf="emailForm && !fieldsChanged && selectedEmail">
        <input (click)="setFileNull($event)" (change)="onFileChange($event)" id="file-upload" type="file" />
        <div class="test-btn">
          <span *ngIf="!uploading">Upload And Test</span>
          <span *ngIf="uploading">
            <mat-spinner class="white-spinner" [diameter]="20"></mat-spinner>
          </span>
        </div>
      </label>
    </div>
  </div>
</div>
