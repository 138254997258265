<div class="storage-form-container">
  <h2 mat-dialog-title>
    Please Select A Mine to Edit
  </h2>

  <form *ngIf="storageForm" [formGroup]="storageForm" (submit)="submit()">
    <div>
      <mat-form-field>
        <mat-select placeholder="Mine" [formControl]="storageForm.controls['id']" [compareWith]="compareFn">
          <mat-option *ngFor="let mine of mines" [value]="mine.id">
            {{ mine.site.name }}
            <span *ngFor="let mesh of mine.meshes; let isLast = last">{{ mesh.type }}{{ isLast ? '' : ', ' }}</span>
            <span class="coordinates">({{ mine.site.lngLat[1] }}, {{ mine.site.lngLat[0] }})</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="storageForm.controls['id'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>
    </div>

    <div matDialogActions fxLayout="row" fxLayoutAlign="flex-end" class="actions">
      <button mat-flat-button color="primary" mat-dialog-close color="accent">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="submitting">
        {{ submitting ? 'Submitting...' : 'Edit' }}
      </button>
    </div>
  </form>
</div>
