import { Injectable, OnInit } from '@angular/core';
import { environment } from '~environments/environment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter, switchMap, take } from 'rxjs/operators';
import { CrudService } from '~services/crud.service';
import { RouterStateService } from '~services/router-state.service';
import { CREW_DATA_DATE, CREW_DATA_FRAC_ID } from '../app-routing.constants';

export interface CrewDataFrac {
  fracId: number;
  jobName: string;
  billingIdentifier: string;
}

export interface CrewDataStatsForDate {
  date: string;
  fracId: number;
  fracCrewName: string;
  fracBillingIdentifier: string;
  totalLoads: number;
  totalMatched: number;
  totalUnmatched: number;
}

export interface CrewDataRecord {
  crewDataRecordId: number;
  deliveredDate: string;
  fullWeight: number;
  bolNumber: string;
  poNumber: string;
  matched: boolean;
  sandiOrderId?: number;
}

export interface FracData {
  fracId: number;
  fracCrewName: string;
  fracBillingIdentifier: string;
  fracTimezone: string;
}

export interface FracForFilter {
  fracId: number;
  crewName: string;
  billingIdentifier: string;
}

@Injectable({
  providedIn: 'root',
})
export class CrewDataService {
  get route(): string {
    return environment.api + '/crew_data';
  }

  private crewDataEnabled$$ = new BehaviorSubject<boolean>(false);
  public crewDataEnabled$ = this.crewDataEnabled$$.asObservable();

  private fracOptionsForUpload$$ = new BehaviorSubject<CrewDataFrac[]>([]);
  public fracOptionsForUpload$ = this.fracOptionsForUpload$$.asObservable();

  private fracOptionsForStatistics$$ = new BehaviorSubject<CrewDataFrac[]>([]);
  public fracOptionsForStatistics$ = this.fracOptionsForStatistics$$.asObservable();

  private recordsForDate$$ = new BehaviorSubject<CrewDataRecord[]>(null);
  public recordsForDate$ = this.recordsForDate$$.asObservable();

  private fracData$$ = new BehaviorSubject<FracData>(null);
  public fracData$ = this.fracData$$.asObservable();

  private fracsForFilter$$ = new BehaviorSubject<FracForFilter[]>([]);
  public fracsForFilter$ = this.fracsForFilter$$.asObservable();

  constructor(private http: HttpClient, private crud: CrudService, private routerState: RouterStateService) {
    this.http.get<{ crewDataEnabled: boolean }>(this.route + '/enabled').subscribe((res) => {
      this.crewDataEnabled$$.next(res.crewDataEnabled);
    });

    this.http.get<CrewDataFrac[]>(this.route + '/frac_options_for_upload').subscribe((res) => {
      this.fracOptionsForUpload$$.next(res);
    });

    combineLatest([
      this.routerState.listenForParamChange$(CREW_DATA_FRAC_ID),
      this.routerState.listenForParamChange$(CREW_DATA_DATE),
    ]).subscribe(([settingsID, date]) => {
      if (settingsID && date) {
        this.getRecordsOnDate(date, parseInt(settingsID, 10));
      } else {
        this.recordsForDate$$.next(null);
        this.fracData$$.next(null);
      }
    });
  }

  public doManualFileUpload(file: File, fracId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fracId', fracId.toString());

    return this.http.post(`${this.route}/manual_file_upload`, formData);
  }

  public getCrewDataStatistics(startTime: Date, endTime: Date, fracId: number): Observable<CrewDataStatsForDate[]> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() =>
        this.crud.post(`${this.route}/statistics`, {
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          fracId,
        }),
      ),
    );
  }

  private async getRecordsOnDate(date: string, fracId: number) {
    const value = await this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.crud.post(`${this.route}/records_on_date`, {
            date,
            fracId,
          }),
        ),
      )
      .toPromise();

    this.recordsForDate$$.next(value.records);
    this.fracData$$.next(value.fracData);
  }

  public loadFracsForFilter() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(`${this.route}/records_on_date/fracs_for_filter`)),
      )
      .subscribe((fracs) => {
        if (fracs) {
          this.fracsForFilter$$.next(fracs);
        } else {
          this.fracsForFilter$$.next([]);
        }
      });
  }
}
