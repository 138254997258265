import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { Report } from '~lmo/models/report.model';
import { ErrorHandlingService } from '~services/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class LmoReportsService {
  private reports$$ = new BehaviorSubject<Report[]>([]);
  public get reports$(): Observable<Report[]> {
    return this.reports$$.asObservable().pipe(map((reports) => Object.values(reports)));
  }

  public getReportBySiteId$(siteId: number): Observable<Report> {
    return this.reports$$.asObservable().pipe(
      map((reports) => reports.find((r) => r.site && r.site.id === siteId)),
      filter((report: Report) => !!report),
    );
  }
  constructor(private http: HttpClient, private errorHandler: ErrorHandlingService, private snackBar: MatSnackBar) {
    this.getReports();
  }

  public createSiteReport$(report: {
    siteId: number;
    available: boolean;
    eta?: number;
    description?: string;
    meshId?: number;
    accountId?: number;
    sendToReload: boolean;
  }): Observable<boolean> {
    return this.http.post<Report>(`${environment.api}/report`, report).pipe(
      tap(() => {
        this.getReports();
      }),
      tap(() => {
        this.snackBar.open('Status Updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorHandler.showError(error);
        return of(false);
      }),
    );
  }

  private getReports() {
    this.http.get<Report[]>(`${environment.api}/report`).subscribe((response) => {
      this.reports$$.next(response);
    });
  }
}
