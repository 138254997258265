import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  isLogisticsCluster,
  RunboardLogisticsCluster,
  RunboardSiteSummary,
  UnclusteredRunboardSites,
} from '~dispatcher/models/frac.model';
import { ClusterPatchRequest, DispatcherFracsService } from '~dispatcher/services/dispatcher-fracs.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-dispatcher-logistics-cluster-edit',
  styleUrls: ['./dispatcher-logistics-cluster-edit.component.scss'],
  templateUrl: './dispatcher-logistics-cluster-edit.component.html',
})
export class DispatcherLogisticsClusterEditComponent implements OnInit, OnDestroy {
  public nameControl: FormControl = new FormControl(null);
  private confirmDialog: MatDialogRef<any, any>;
  @ViewChild('cancelDialog', { static: true }) private cancelDialog: TemplateRef<any>;
  public loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public clusterId: number;
  public unclusteredFracs: UnclusteredRunboardSites;
  public selectedCluster: RunboardLogisticsCluster;

  constructor(
    private fracService: DispatcherFracsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DispatcherLogisticsClusterEditComponent, any>,
  ) {}

  public ngOnInit() {
    const clusters$ = this.fracService.fracClusterSummaries$;

    clusters$.pipe(take(1)).subscribe((allClusters) => {
      const unclustered = allClusters.filter((cluster) => !isLogisticsCluster(cluster));
      if (unclustered && unclustered.length) {
        this.unclusteredFracs = unclustered[0] as UnclusteredRunboardSites;
      }

      const selectedCluster = allClusters.find(
        (cluster) => isLogisticsCluster(cluster) && cluster.id === this.clusterId,
      );
      if (selectedCluster) {
        this.selectedCluster = selectedCluster as RunboardLogisticsCluster;
        this.nameControl.setValue(this.selectedCluster.name);
      }
    });

    clusters$.pipe();
  }

  public ngOnDestroy(): void {}

  public drop(event: CdkDragDrop<RunboardSiteSummary[]>) {
    if (event.previousContainer !== event.container) {
      this.loading$$.next(true);
      const fracSummary: RunboardSiteSummary = event.previousContainer.data[event.previousIndex];
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      if (event.container.id === 'selectedCluster') {
        this.fracService.addFracToCluster$(this.clusterId, fracSummary.id).subscribe((result) => {
          this.loading$$.next(false);
        });
      } else if (event.container.id === 'unclustered') {
        this.fracService.removeFromFracCluster$(this.clusterId, fracSummary.id).subscribe((result) => {
          this.loading$$.next(false);
        });
      }
    }
  }

  public promptClose() {
    const modalText = {
      buttonText: 'Remove',
      heading: 'Remove Cluster',
      subheading: `Are you sure you want to remove ${this.selectedCluster.name}?`,
    };
    this.confirmDialog = this.dialog.open(this.cancelDialog, {
      data: modalText,
      maxWidth: '968px',
      minWidth: '300px',
      width: '30%',
    });
  }

  public closeConfirmDialog() {
    if (this.confirmDialog) {
      this.confirmDialog.close();
      this.confirmDialog = null;
    }
  }

  public removeCluster() {
    this.closeConfirmDialog();
    this.loading$$.next(true);
    this.fracService.removeCluster$(this.clusterId).subscribe((success) => {
      if (success) {
        this.dialogRef.close();
      }
      this.loading$$.next(false);
    });
  }

  public renameCluster() {
    if (!this.nameControl.value || this.nameControl.value === '') {
      return;
    }
    this.loading$$.next(true);
    const request: ClusterPatchRequest = {
      name: this.nameControl.value,
    };
    this.fracService.patchCluster$(this.clusterId, request).subscribe((success) => {
      if (success) {
        this.dialogRef.close();
      }
      this.loading$$.next(false);
    });
  }

  public isSemiAuto(frac: RunboardSiteSummary) {
    return frac.autopilotLevel === 'semi';
  }

  public isFullAuto(frac: RunboardSiteSummary) {
    return frac.autopilotLevel === 'full';
  }
}
