import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-detail-view-input',
  templateUrl: './detail-view-input.component.html',
  styleUrls: ['./detail-view-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailViewInputComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() type: string;
  @Input() optional: boolean;
  @Output() onValueUpdated: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('detailInput', { static: true }) input: ElementRef;

  placeholder: string;
  editableValue: string;
  error: string = null;
  touched = false;

  constructor() {}

  ngOnInit() {
    this.optional ? (this.placeholder = 'Optional') : (this.placeholder = 'Insert Value');

    if (this.type === 'coordinates') {
      this.editableValue = this.value[1] + ', ' + this.value[0];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.type === 'coordinates') {
      this.editableValue = this.value[1] + ', ' + this.value[0];
    } else {
      this.editableValue = changes.value.currentValue;
    }
  }

  revert() {
    if (this.type === 'coordinates') {
      this.editableValue = this.value[1] + ', ' + this.value[0];
    } else {
      this.editableValue = this.value;
    }
    this.blur();
  }

  blur() {
    this.input.nativeElement.blur();
  }

  onBlur() {
    this.touched = true;
    if (this.type === 'email' && this.editableValue) {
      this.editableValue = this.editableValue.trim();
    }
    if (this.value !== this.editableValue || this.editableValue === undefined) {
      const emittedValue: any = this.validate();

      if (!this.error) {
        this.onValueUpdated.emit(emittedValue);
      }
    } else {
      this.error = null;
    }
  }

  validate() {
    const emittedValue: any = this.editableValue;

    if (this.touched) {
      if (!this.optional && !emittedValue) {
        this.error = 'Input is required';
        return;
      }

      if (this.type === 'coordinates' && ((this.optional && emittedValue) || !this.optional)) {
        if (emittedValue === this.value[1] + ', ' + this.value[0]) {
          return emittedValue
            .trim()
            .split(',')
            .map(Number)
            .reverse();
        }

        if (
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(emittedValue)
        ) {
          this.error = null;
          const coordinates = emittedValue.split(',');
          return [parseFloat(coordinates[1]), parseFloat(coordinates[0])];
        } else {
          this.error = 'Invalid lat, lng values';
          return;
        }
      }

      if (this.type === 'integer' && ((this.optional && emittedValue) || !this.optional)) {
        if (/^\d+$/.test(emittedValue)) {
          this.error = null;
          return parseInt(emittedValue, 10);
        } else {
          this.error = 'Value must be a number';
          return;
        }
      }

      if (this.type === 'pin' && ((this.optional && emittedValue) || !this.optional)) {
        if (/^\d{4}$/.test(emittedValue)) {
          this.error = null;
          return emittedValue;
        } else {
          this.error = 'Value must be 4 numerical characters';
          return;
        }
      }

      if (this.type === 'phone' && ((this.optional && emittedValue) || !this.optional)) {
        this.error = null;
        return emittedValue;
      }

      if (this.type === 'email' && ((this.optional && emittedValue) || !this.optional)) {
        if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(emittedValue)) {
          this.error = null;
          return emittedValue;
        } else {
          this.error = 'Value must be a valid email address';
          return;
        }
      }

      this.error = null;
    }

    return emittedValue;
  }
}
