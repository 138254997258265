<div mat-dialog-title>{{ po.mineName }} Timeline</div>
<div mat-dialog-content>
  <div class="p-2 grid grid-cols-4 gap-x-5">
    <!-- Created Status -->
    <ng-container>
      <div class="flex flex-row gap-x-2">
        <div class="flex flex-col items-center h-full">
          <div class="border-2 rounded-full border-indigo-500 min-w-2 min-h-2 w-2 h-2"></div>
          <div class="border-r-2 border-dotted border-indigo-500 flex flex-1"></div>
        </div>
        {{ po.createdTimestamp | date: 'short' }}
      </div>
      <div class="col-span-3">
        <div class="flex flex-col">
          <div>Created</div>
          <ul class="px-2 list-disc list-none px-0 pb-3">
            <li>Created By {{ po.createdBy }}</li>
          </ul>
        </div>
      </div>
    </ng-container>

    <!-- Timeline Status -->
    <ng-container *ngFor="let detail of detailedHistory; trackBy: trackByDetail">
      <div class="flex flex-row gap-x-2">
        <div class="flex flex-col items-center h-full">
          <div class="border-2 rounded-full border-indigo-500 min-w-2 min-h-2 w-2 h-2"></div>
          <div class="border-r-2 border-dotted border-indigo-500 flex flex-1"></div>
        </div>
        {{ detail.createdTimestamp | date: 'short' }}
      </div>
      <div class="col-span-3">
        <div class="flex flex-col">
          <div>
            Updated
          </div>
          <ul class="px-2 list-disc list-none px-0 pb-3">
            <li *ngIf="detail.username != null">Updated By {{ detail.username }}</li>
            <li *ngFor="let act of detail.actionList; trackBy: trackByChange">
              {{ act }}
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <!-- Paused Status -->
    <ng-container *ngIf="po.pausedTimestamp && !po.closed">
      <div class="flex flex-row gap-x-2">
        <div class="flex flex-col items-center h-full">
          <div class="border-2 rounded-full border-indigo-500 min-w-2 min-h-2 w-2 h-2"></div>
          <div class="border-r-2 border-dotted border-indigo-500 flex flex-1"></div>
        </div>
        {{ po.pausedTimestamp | date: 'short' }}
      </div>
      <div class="col-span-3">
        <div class="flex flex-col">
          <div>Paused</div>
          <ul class="px-2 list-disc list-none px-0 pb-3">
            <li>Paused By {{ po.pausedBy }}</li>
            <li>Reason: {{ po.pauseReason }}</li>
          </ul>
        </div>
      </div>
    </ng-container>

    <!-- Closed Status -->
    <ng-container *ngIf="po.closed && po.closedAt">
      <div class="flex flex-row gap-x-2">
        <div class="flex flex-col items-center h-full">
          <div class="border-2 rounded-full border-indigo-500 min-w-2 min-h-2 w-2 h-2"></div>
          <div class="border-r-2 border-dotted border-indigo-500 flex flex-1"></div>
        </div>
        {{ po.closedAt | date: 'short' }}
      </div>
      <div class="col-span-3">
        <div class="flex flex-col">
          <div>Closed</div>
          <ul class="px-2 list-disc list-none px-0 pb-3">
            <li>Closed By {{ po.closedBy }}</li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
