import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { FeatureFlagService } from './services/feature-flag.service';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[saFeatureFlag]',
})
export class FeatureFlagDirective implements OnDestroy {
  private hasView = false;
  private hasElseView = false;
  private subscription: Subscription;
  private _elseTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
  private couldBeTrueInstead = false;
  private mustAlsoBeTrue = true;

  constructor(
    private renderer: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input() set saFeatureFlagAnd(mustAlsoBeTrue: boolean) {
    this.mustAlsoBeTrue = mustAlsoBeTrue;
  }

  @Input() set saFeatureFlagOr(couldBeTrueInstead: boolean) {
    this.couldBeTrueInstead = couldBeTrueInstead;
  }

  @Input() set saFeatureFlagElse(elseTemplate: TemplateRef<any>) {
    this._elseTemplate$.next(elseTemplate);
  }

  @Input() set saFeatureFlag(flag: string) {
    this.subscription = combineLatest(
      this.featureFlagService.isFlagActive(flag),
      this.featureFlagService.isSAOnlyFlag(flag),
      this._elseTemplate$,
    ).subscribe(([flagActive, saOnly, elseTemplate]) => {
      if ((!flagActive || !this.mustAlsoBeTrue) && !this.couldBeTrueInstead) {
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.cd.markForCheck();
        }
        if (elseTemplate && !this.hasElseView) {
          this.viewContainer.createEmbeddedView(elseTemplate);
          this.hasElseView = true;
          this.cd.markForCheck();
        }
        return;
      }

      if ((flagActive && this.mustAlsoBeTrue) || this.couldBeTrueInstead) {
        if (!this.hasView) {
          if (this.hasElseView) {
            this.viewContainer.clear();
            this.hasElseView = false;
          }
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        }

        const createdElement = this.renderer.nextSibling(this.templateRef.elementRef.nativeElement);
        if (createdElement && createdElement.classList) {
          if (saOnly) {
            this.renderer.addClass(createdElement, 'sa-only-flag');
          } else {
            this.renderer.removeClass(createdElement, 'sa-only-flag');
          }
        }
      }
    });
  }
}
