import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';

type states = 'processing' | 'success' | 'failure';

@Component({
  selector: 'sa-unsubscribe-po',
  templateUrl: './unsubscribe-po.component.html',
  styleUrls: ['./unsubscribe-po.component.scss'],
})
export class UnsubscribePoComponent implements OnInit {
  currentState: states = 'processing';
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  public ngOnInit() {
    const guid = this.route.snapshot.paramMap.get('uuid');
    if (!guid) {
      this.currentState = 'failure';
      return;
    }
    this.http
      .post<null>(`${environment.api}/unsubscribe/po`, { guid })
      .subscribe(
        () => (this.currentState = 'success'),
        () => (this.currentState = 'failure'),
      );
  }
}
