<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <div class="heading">
      Notifications
    </div>
    <div class="notification-group" *ngFor="let group of notificationService.notifications$ | async">
      <h2>{{ group.name }}</h2>
      <div
        class="notification"
        saAutomatedSelector="notification__row"
        [automatedName]="notification.name"
        *ngFor="let notification of group.notifications; trackBy: notificationTrackBy"
      >
        <span class="name" saAutomatedSelector="notification__name">{{ notification.name }}</span>
        <span
          class="toggle"
          saAutomatedSelector="notification__emailContainer"
          [class.enabled]="notification.emailEnabled"
          (click)="notificationService.updateEmailNotification(notification.id, !notification.emailEnabled)"
        >
          <mat-icon class="clickable" *ngIf="notification.emailActive">email</mat-icon>
        </span>
        <span
          class="toggle"
          saAutomatedSelector="notification__phoneContainer"
          [class.enabled]="notification.pushEnabled"
          (click)="notificationService.updatePushNotification(notification.id, !notification.pushEnabled)"
        >
          <mat-icon class="clickable" *ngIf="notification.pushActive">settings_cell</mat-icon>
        </span>
      </div>
    </div>
  </div>
</div>
