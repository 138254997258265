import { Component } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SaErrorStateMatcher } from '../services/form-validation.service';
import { PasswordValidator } from '../services/custom-validators';
import { AccountApiService } from '../services/api/account.api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from '../services/error-handling.service';

@Component({
  selector: 'sa-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  matcher: ErrorStateMatcher;
  loginForm: FormGroup;
  submitting: boolean;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private accountApiService: AccountApiService,
    private errorHandler: ErrorHandlingService,
  ) {
    this.createForms();
    const code = this.route.snapshot.params['code'];
    this.validate(code);
  }

  validate(code) {
    this.accountApiService
      .validateInvite({
        key: code,
      })
      .subscribe(
        (resp) => {
          this.loginForm.controls['email'].setValue(resp.email);
          this.loginForm.controls['userName'].setValue(resp.name);
        },
        (err) => {
          this.errorHandler.showError(err, 5000);
          this.router.navigate(['/login']);
        },
      );
  }

  createForms() {
    this.loginForm = this.fb.group(
      {
        accountName: ['', [Validators.required]],
        userName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required, PasswordValidator('password', 'confirmPassword')]],
      },
      {
        validator: [PasswordValidator('password', 'confirmPassword')],
      },
    );

    this.matcher = new SaErrorStateMatcher();
  }

  register() {
    if (this.loginForm.valid) {
      this.submitting = true;
      this.accountApiService
        .registerAccount({
          accountName: this.loginForm.controls['accountName'].value,
          accountType: { name: 'trucking-vendor', id: 3 },
          userName: this.loginForm.controls['userName'].value,
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
        })
        .subscribe(
          (resp) => {
            window.open('https://www.shaleapps.com', '_self');
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
    }
  }
}
