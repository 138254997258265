import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { Mesh } from '~lmo/models/mesh.model';
import { idArrayToRecord } from '~utilities/idArrayToRecord';

@Injectable({
  providedIn: 'root',
})
export class LmoConstantsService {
  private meshes$$: BehaviorSubject<Record<number, Mesh>> = new BehaviorSubject<Record<number, Mesh>>({});
  private meshesByName$$: BehaviorSubject<Record<string, Mesh>> = new BehaviorSubject<Record<string, Mesh>>({});

  public get meshes$(): Observable<Mesh[]> {
    return this.meshes$$.asObservable().pipe(map((meshes) => Object.keys(meshes).map((key) => meshes[key]) as Mesh[]));
  }

  public get meshesById$(): Observable<Record<number, Mesh>> {
    return this.meshes$$.asObservable();
  }

  public get meshesByName$(): Observable<Record<string, Mesh>> {
    return this.meshesByName$$.asObservable();
  }

  constructor(private http: HttpClient) {
    this.loadMeshes();
  }

  private loadMeshes() {
    this.http.get<Mesh[]>(`${environment.api}/mesh`).subscribe((meshes) => {
      this.meshes$$.next(idArrayToRecord(meshes));
      const meshesByName = meshes.reduce((record: Record<string, Mesh>, mesh) => {
        record[mesh.type] = mesh;
        return record;
      }, {});
      this.meshesByName$$.next(meshesByName);
    });
  }
}
