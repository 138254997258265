<sa-nav [visible]="displayNav" (onMenuClose)="displayNav = false"></sa-nav>

<div class="email-details-component">
  <div class="top-menu">
    <div class="side-menu"><img class="menu-icon" src="/assets/icons/menu-24.svg" (click)="menuClick()" /></div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="/assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel.email || userLabel.name || 'Unknown' }}
    </div>
  </div>

  <a class="back-wrap" [routerLink]="['/', 'loader-integration']">
    <i class="material-icons">arrow_back</i>
    <span>Back to Loader Integration</span>
  </a>

  <div class="head-wrap">
    <h1>Email Details</h1>
  </div>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
        <td mat-cell *matCellDef="let emailDetails">
          {{ emailDetails.sender }}
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload Time</th>
        <td mat-cell *matCellDef="let emailDetails">
          {{ emailDetails.uploadTime | date: 'MM/dd/yy hh:mm aa' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Found Attachment ?</th>
        <td mat-cell class="centered-cell" *matCellDef="let emailDetails">
          <span *ngIf="emailDetails.uploadCompleted">Yes</span>
          <span *ngIf="!emailDetails.uploadCompleted">No</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="csv">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Converted to CSV</th>
        <td mat-cell class="centered-cell" *matCellDef="let emailDetails">
          <span *ngIf="emailDetails.csvConverted">Yes</span>
          <span *ngIf="!emailDetails.csvConverted">No</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="processed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matched</th>
        <td mat-cell class="centered-cell" *matCellDef="let emailDetails">
          <span *ngIf="emailDetails.processed">Yes</span>
          <span *ngIf="!emailDetails.processed">No</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="files">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Files</th>
        <td mat-cell *matCellDef="let emailDetails">
          <ng-container *ngIf="emailDetails.files">
            <div class="file-wrap" *ngFor="let file of emailDetails.files" (click)="downloadFile(file.fileName)">
              {{ file.fileName }}
            </div>
          </ng-container>
          <div *ngIf="!emailDetails.files">-</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let emailDetails; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
