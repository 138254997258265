<div class="rti-demo">
  <div class="logo-wrap"><img class="shaleapps-icon" src="/assets/icons/shale-logo.svg" /></div>
  <mat-tab-group>
    <mat-tab label="RealTime">
      <div class="embed">
        <iframe src="https://shaleapps.looker.com/embed/dashboards/11?allow_login_screen=true"></iframe>
      </div>
    </mat-tab>
    <mat-tab label="Ops KPIs">
      <div class="embed">
        <iframe src="https://shaleapps.looker.com/embed/dashboards/12?allow_login_screen=true"> </iframe>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
