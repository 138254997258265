import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailSettingsV2Service } from '~services/email-settings-v2.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from '~services/error-handling.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CrewDataService, CrewDataFrac } from '~services/crew-data.service';
import { fuse } from '~utilities/fuse';

const searchOptions: Fuse.FuseOptions<CrewDataFrac> = {
  distance: 100,
  keys: ['billingIdentifier', 'jobName'],
  location: 0,
  maxPatternLength: 9,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  selector: 'sa-upload-crew-data-file',
  templateUrl: './upload-crew-data-file.component.html',
  styleUrls: ['./upload-crew-data-file.component.scss'],
})
export class UploadCrewDataFileComponent implements OnInit, OnDestroy {
  fracs$: Observable<CrewDataFrac[]>;
  fileForm: FormGroup;
  subs: Subscription[] = [];
  filterFrac: FormControl = new FormControl();
  filteredFracs$: Observable<CrewDataFrac[]>;

  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByFracID;

  constructor(
    private fb: FormBuilder,
    private emailSettingsV2Service: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private crewDataService: CrewDataService,
  ) {
    this.fracs$ = this.crewDataService.fracOptionsForUpload$;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.fileForm = this.fb.group({
      frac: [null, [Validators.required]],
      uploadFile: [null, [Validators.required]],
    });

    this.filteredFracs$ = combineLatest([
      this.fracs$.pipe(map(fuse(searchOptions))),
      this.filterFrac.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([fracs, filterString]) => {
        if (filterString) {
          return fracs.fuse.search(filterString) as any;
        } else {
          return fracs.data;
        }
      }),
    );
  }

  onSubmit(event) {
    if (this.fileForm.valid) {
      this.crewDataService
        .doManualFileUpload(this.fileForm.controls['uploadFile'].value, this.fileForm.controls['frac'].value.fracId)
        .subscribe(
          (res) => {
            this.snackBar.open('File uploaded successfully', null, {
              duration: 5000,
            });
            this.goBack.emit();
          },
          (err) => {
            this.snackBar.open('Failed to upload file.', null, {
              duration: 5000,
              panelClass: ['snackbar-error'],
            });
          },
        );
    } else {
      this.errorHandler.showError('Please fill all the fields');
    }
  }

  compareByFracID(o1, o2) {
    return o1 && o2 && o1.fracId === o2.fracId;
  }

  close() {
    this.goBack.emit();
  }

  fileChanged(files: File[]) {
    if (files && files.length > 0) {
      this.fileForm.controls['uploadFile'].setValue(files[0]);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }
}
