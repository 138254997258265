<div class="nav-container" *ngIf="visible" [@slideInOut]>
  <div class="header">
    <span class="logo">
      <img src="../../../favicon.ico" />
    </span>
    <span class="close" (click)="close()" saAutomatedSelector="menu__close"><i class="material-icons">clear</i></span>
  </div>
  <ng-container *ngIf="isSandVendor; else oldMenu">
    <sa-nav-sand-vendor [userLabel]="userLabel" [isAdmin]="isAdmin" class="flex-1"></sa-nav-sand-vendor>
  </ng-container>

  <ng-template #oldMenu>
    <ng-container *ngIf="isLMO; else dispatcherOrShaleappsMenu">
      <sa-nav-lmo [userLabel]="userLabel" class="flex-1" style="overflow: hidden;"></sa-nav-lmo>
    </ng-container>
    <ng-template #dispatcherOrShaleappsMenu>
      <ng-container *ngIf="isDispatcher; else shaleappsAdmin">
        <sa-nav-dispatcher [userLabel]="userLabel" class="flex-1" style="overflow: hidden;"></sa-nav-dispatcher>
      </ng-container>
      <ng-template #shaleappsAdmin>
        <ng-container *saAllowedAccountRoles="['default', 'viewOnly', 'sandCoordinator']">
          <ul class="top-nav nav-list font-medium">
            <li>
              <a routerLink="/lmo/frac/list" routerLinkActive="true" saAutomatedSelector="menu__wells">Wells</a>
            </li>

            <li>
              <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notifications"
                >Notifications</a
              >
            </li>
            <li *ngIf="isLMO">
              <a routerLink="/lmo/training-videos" routerLinkActive="true" saAutomatedSelector="menu__training"
                >Training Videos</a
              >
            </li>
          </ul>
        </ng-container>

        <ng-container *saAllowedAccountRoles="['admin', 'dispatcher']">
          <ul class="top-nav nav-list font-medium">
            <li *ngIf="isLMO || userService.isShaleAccount()">
              <a
                [routerLink]="['/billing/settings']"
                routerLinkActive="true"
                saAutomatedSelector="menu__billing_settings"
                >Billing Settings</a
              >
            </li>

            <li *ngIf="userService.isShaleAccount()">
              <a
                routerLink="/loader-analytics-dashboard"
                routerLinkActive="true"
                saAutomatedSelector="menu__loader-analytics"
                >Loader Analytics</a
              >
            </li>

            <li>
              <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notifications"
                >Notifications</a
              >
            </li>

            <li>
              <a routerLink="/bulk-export" routerLinkActive="true" saAutomatedSelector="menu__bulk-export"
                >Bulk Export</a
              >
            </li>

            <li *ngIf="isDispatcher || isLMO">
              <span class="fake-link" saOrderSearch>Order Lookup</span>
            </li>

            <li *ngIf="userService.isShaleAccount()">
              <a [routerLink]="['/', 'admin']">Admin</a>
            </li>
          </ul>
        </ng-container>

        <div class="footer">
          <div class="user">
            <div class="user-icon"></div>

            <div class="user-info font-medium">
              <div class="user-email">{{ userLabel.email || userLabel.name || 'Unknown' }}</div>
              <div class="user-company">{{ userLabel.account || 'Unknown' }}</div>
            </div>
          </div>

          <ng-container *ngIf="ssoLoginUrl$ | async as ssoLoginUrl">
            <div *saFeatureFlag="'ssoSwitchAccount'" class="user-account-switch">
              <a (click)="ssoLogin()">SSO Switch Account</a>
            </div>
          </ng-container>

          <span class="logout font-medium" (click)="logout()" saAutomatedSelector="menu__sign_out">Sign Out</span>
          <div class="version">v{{ versioningService.currentVersion }}</div>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
