import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MineApiService, UploadedFile } from '../../services/api/mine.api.service';
import { ConstantsApiService } from '../../services/api/constants.api.service';
import { LatitudeValidator, LongitudeValidator } from '../../services/custom-validators';
import { UserService } from '../../services/user.service';
import { config } from '../../config';
import { LightTheme } from '../../map/mapStyles';
import { MapService } from '../../services/map.service';
import { SiteApiService } from '../../services/api/site.api.service';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Site } from '../../models/site';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { debounceTime } from 'rxjs/operators';
import { ConfirmActionDialogComponent } from '../../ui-components/confirm-action-dialog/confirm-action-dialog.component';

@Component({
  selector: 'sa-mine-creation',
  templateUrl: './mine-creation.component.html',
  styleUrls: ['./mine-creation.component.scss'],
})
export class MineCreationComponent implements OnInit, OnDestroy {
  map: google.maps.Map;
  mapConfig: google.maps.MapOptions;
  mineDirectionLocationMap: google.maps.Map;
  mineDirectionLocationMapConfig: google.maps.MapOptions;
  mineDirectionLocationMapDragEndListner: Subject<any>;
  mineDirectionLocationMapDragEndSubscription: Subscription;
  mineDirectionLocationManuallyChanged = false;
  meshTypes;
  mineForm: FormGroup;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  submitting = false;
  canEdit = false;
  mapAreaChangeListner: Subject<any>;
  mapAreaChangeSubscription: Subscription;
  mapDragEndListner: Subject<any>;
  mapDragEndSubscription: Subscription;
  latLngManuallyChanged = false;
  public uploadingPOAttachment$$ = new BehaviorSubject(false);

  radius = 1609;
  shape: any;
  displayedMarkers = [];
  displayedShapes: any = [];

  firstChange = true;

  centerLat: number;
  centerLng: number;
  uploadedFiles$$ = new BehaviorSubject<UploadedFile[]>([]);

  constructor(
    public dialogRef: MatDialogRef<MineCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private constantsApiService: ConstantsApiService,
    private mineApiService: MineApiService,
    private userService: UserService,
    private siteService: SiteApiService,
    private mapService: MapService,
    private errorHandler: ErrorHandlingService,
    private matDialog: MatDialog,
  ) {
    this.canEdit = userService.canEditWell();
    this.constantsApiService.getMeshTypes().subscribe((meshTypes) => {
      this.meshTypes = meshTypes;
    });

    this.data ? this.buildForm(this.data) : this.buildForm();

    this.mapAreaChangeListner = new Subject<any>();
    this.mapDragEndListner = new Subject<any>();
    this.mineDirectionLocationMapDragEndListner = new Subject<any>();

    if (this.data) {
      this.radius = this.data.site.radius;
    }

    // window['__onGoogleLoaded'] = (ev) => {
    // this.initMap();
    // };
    this.initMap();

    this.loadFiles();

    // this.loadScripts();
  }

  ngOnInit() {
    this.mapAreaChangeSubscription = this.mapAreaChangeListner
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe(() => this.drawOtherSites());
    this.mapDragEndSubscription = this.mapDragEndListner
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => this.changeLatLng());
    this.mineDirectionLocationMapDragEndSubscription = this.mineDirectionLocationMapDragEndListner
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => this.changeMineDirectionLocationLatLng());
  }

  ngOnDestroy() {
    this.mineDirectionLocationMapDragEndSubscription.unsubscribe();
    this.mapAreaChangeSubscription.unsubscribe();
    this.mapDragEndSubscription.unsubscribe();
  }

  loadScripts() {
    const node = document.createElement('script');
    node.src = config.googleMapsScriptUrl;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  drawOtherSites() {
    this.siteService
      .getSites({
        north: this.map
          .getBounds()
          .getNorthEast()
          .lat(),
        east: this.map
          .getBounds()
          .getNorthEast()
          .lng(),
        south: this.map
          .getBounds()
          .getSouthWest()
          .lat(),
        west: this.map
          .getBounds()
          .getSouthWest()
          .lng(),
      })
      .subscribe((sites: Site[]) => {
        this.displayedMarkers.forEach((marker) => {
          marker.setMap(null);
        });
        this.displayedMarkers = [];
        this.displayedShapes.forEach((shape) => {
          shape.setMap(null);
        });
        this.displayedShapes = [];
        for (let i = 0; i < sites.length; i++) {
          if (this.data == null || this.data.site.id !== sites[i].id) {
            const site = sites[i];
            const marker = this.mapService.createSiteMarker(sites[i]);
            marker.setMap(this.map);
            this.displayedMarkers.push(marker);
            let shape: any;
            shape = this.mapService.createCircle(this.map, site.radius, '#FF0000');
            shape.setCenter(marker.getPosition());
            shape.setEditable(false);
            this.displayedShapes.push(shape);
          }
        }
      });
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (this.isImage(file.name) || this.isPDF(file.name)) {
        this.uploadAttachments(file, this.data.id);
        this.loadFiles();
      } else {
        this.snackBar.open('Image and PDF files can only be uploaded.', null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      }
    }
  }

  public isImage(fileName): boolean {
    const imageExtensions = ['jpeg', 'jpg', 'png'];
    return imageExtensions.includes(this.getFileExtension(fileName));
  }

  public isPDF(fileName): boolean {
    return this.getFileExtension(fileName) === 'pdf';
  }

  public getFileExtension(fileName: string): string {
    const lastDot = fileName.lastIndexOf('.');
    return fileName.substring(lastDot + 1);
  }

  private uploadAttachments(file, siteID) {
    this.uploadingPOAttachment$$.next(true);
    this.mineApiService.uploadMineAttachment$$(siteID, file, file.name).subscribe(
      (res) => {
        this.uploadingPOAttachment$$.next(false);
        this.loadFiles();
        this.snackBar.open('File uploaded Successfully', null, {
          duration: 5000,
        });
      },
      (err) => {
        this.uploadingPOAttachment$$.next(false);
        this.snackBar.open('Upload Failed!', null, {
          duration: 5000,
        });
        console.error(err, 'Upload Failed', file);
      },
    );
  }

  public deleteAttachment(fileId, poId) {
    const confirmDialogRef = this.matDialog.open(ConfirmActionDialogComponent, {
      data: {
        cancelButtonText: 'Cancel',
        desc: 'Are you sure you want delete this attachment? This cannot be undone.',
        submitButtonText: 'Delete',
        title: `Delete Attachment`,
      },
    });

    confirmDialogRef.afterClosed().subscribe((keepGoing) => {
      if (!keepGoing) {
        return;
      }

      this.mineApiService.deletePOAttachment$(fileId, poId).subscribe(
        (res) => {
          this.snackBar.open('Attachment Deleted Successfully', null, {
            duration: 5000,
          });
        },
        (err) => {
          this.uploadingPOAttachment$$.next(false);
          this.snackBar.open('Deletion Failed!', null, {
            duration: 5000,
          });
          console.error(err, 'Deletion Failed', fileId, poId);
        },
      );
    });
  }

  public openInWindow(fileUrl) {
    window.open(fileUrl, '_blank');
  }

  changeMineDirectionLocationLatLng() {
    if (!this.mineDirectionLocationManuallyChanged) {
      this.mineForm.controls['mineDirectionLocationLat'].setValue(this.mineDirectionLocationMap.getCenter().lat());
      this.mineForm.controls['mineDirectionLocationLng'].setValue(this.mineDirectionLocationMap.getCenter().lng());
    }
    this.mineDirectionLocationManuallyChanged = false;
  }

  changeLatLng() {
    const lngChanged = this.centerLng - this.map.getCenter().lng();
    const latChanged = this.centerLat - this.map.getCenter().lat();

    if (lngChanged !== 0 || latChanged !== 0 || this.firstChange) {
      this.firstChange = false;

      this.centerLat = this.map.getCenter().lat();
      this.centerLng = this.map.getCenter().lng();

      if (!this.latLngManuallyChanged) {
        this.mineForm.controls['lng'].setValue(this.map.getCenter().lng());
        this.mineForm.controls['lat'].setValue(this.map.getCenter().lat());
      }
      this.latLngManuallyChanged = false;

      this.setupCircle();
    }
  }

  private setupCircle() {
    if (!this.shape) {
      this.shape = this.mapService.createCircle(this.map, this.mineForm.controls['radius'].value * 1609, '#00FF00');
      let ignore = false;
      google.maps.event.addListener(this.shape, 'radius_changed', () => {
        if (this.shape.getRadius() <= 16090) {
          this.mineForm.controls['radius'].setValue(this.shape.getRadius() / 1609);
        } else {
          this.shape.setRadius(16090);
          this.errorHandler.showError('Radius Cannot be greater than 10 mile');
        }
      });
      google.maps.event.addListener(this.shape, 'center_changed', () => {
        if (ignore) {
          ignore = false;
          return;
        }
        ignore = true;
        this.shape.setCenter(this.map.getCenter());
      });
    } else {
      this.shape.setRadius(this.mineForm.controls['radius'].value * 1609);
    }

    this.shape.setCenter(this.map.getCenter());
  }

  onChanges() {
    this.mineForm
      .get('radius')
      .valueChanges.pipe(debounceTime(400))
      .subscribe((value) => {
        if (value <= 10) {
          this.setupCircle();
        } else {
          this.errorHandler.showError('Radius Cannot be greater than 10 Miles');
        }
      });
    this.mineForm
      .get('lng')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.latLngManuallyChanged = true;
        this.setMapPosition();
      });

    this.mineForm
      .get('lat')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.latLngManuallyChanged = true;
        this.setMapPosition();
      });
    this.mineForm
      .get('mineDirectionLocationLat')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.mineDirectionLocationManuallyChanged = true;
        this.setMineDirectionLocationMapPosition();
      });

    this.mineForm
      .get('mineDirectionLocationLng')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.mineDirectionLocationManuallyChanged = true;
        this.setMineDirectionLocationMapPosition();
      });
  }

  setMineDirectionLocationMapPosition() {
    if (
      this.mineDirectionLocationMap &&
      this.mineForm.get('mineDirectionLocationLat').value &&
      this.mineForm.get('mineDirectionLocationLng').value
    ) {
      if (
        this.centerLat !== this.mineForm.get('mineDirectionLocationLat').value ||
        this.centerLng !== this.mineForm.get('mineDirectionLocationLng').value
      ) {
        this.mineDirectionLocationMap.setCenter({
          lat: +this.mineForm.get('mineDirectionLocationLat').value,
          lng: +this.mineForm.get('mineDirectionLocationLng').value,
        });
        this.firstChange = true;
      }
    }
  }

  initMap() {
    setTimeout(() => {
      if (this.map == null) {
        let center = { lat: 39.7392, lng: -104.9903 };
        if (this.data != null) {
          center = { lat: +this.data.site.lngLat[1], lng: +this.data.site.lngLat[0] };
        }
        this.mapConfig = {
          center: center,
          zoom: 12,
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: 3,
          },
          zoomControlOptions: {
            position: 3,
          },
          mapTypeId: google.maps.MapTypeId.HYBRID,
          styles: <any>LightTheme,
        };

        const element = document.getElementById('map-mine');
        if (element != null) {
          this.map = new google.maps.Map(element, this.mapConfig);
          this.mapDragEndListner.next(2);
          this.mapAreaChangeListner.next(2);
          google.maps.event.addListener(this.map, 'center_changed', () => {
            this.mapDragEndListner.next(2);
            this.mapAreaChangeListner.next(2);
          });
          google.maps.event.addListener(this.map, 'bounds_changed', () => {
            this.mapDragEndListner.next(2);
            this.mapAreaChangeListner.next(2);
          });
        }
      }
      this.initMineDirectionLocationMap();
    }, 500);
  }

  private initMineDirectionLocationMap() {
    if (this.mineDirectionLocationMap == null) {
      let center = { lat: 39.7392, lng: -104.9903 };
      if (this.data != null) {
        if (this.data.mineDirectionLocation != null) {
          center = { lat: +this.data.mineDirectionLocation[1], lng: +this.data.mineDirectionLocation[0] };
        } else {
          center = { lat: +this.data.site.lngLat[1], lng: +this.data.site.lngLat[0] };
        }
      }
      this.mineDirectionLocationMapConfig = {
        center: center,
        zoom: 12,
        streetViewControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 3,
        },
        zoomControlOptions: {
          position: 3,
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        styles: <any>LightTheme,
      };
      const mineDirectionLocationElement = document.getElementById('map-mine-direction-location');
      if (mineDirectionLocationElement != null) {
        this.mineDirectionLocationMap = new google.maps.Map(
          mineDirectionLocationElement,
          this.mineDirectionLocationMapConfig,
        );
        this.mineDirectionLocationMapDragEndListner.next(2);
        google.maps.event.addListener(this.mineDirectionLocationMap, 'center_changed', () => {
          this.mineDirectionLocationMapDragEndListner.next(2);
        });
      }
    }
  }

  setMapPosition() {
    if (this.map && this.mineForm.get('lng').value && this.mineForm.get('lat').value) {
      if (this.centerLng !== this.mineForm.get('lng').value || this.centerLat !== this.mineForm.get('lat').value) {
        this.map.setCenter({ lat: +this.mineForm.get('lat').value, lng: +this.mineForm.get('lng').value });
        this.firstChange = true;
      }
    }
  }

  buildForm(mine?) {
    this.mineForm = this.fb.group({
      name: [{ value: mine ? mine.site.name : null, disabled: !this.canEdit }, [Validators.required]],
      radius: [
        (mine ? mine.site.radius / 1609 : 0.5) as number,
        [Validators.required, Validators.max(10), Validators.min(0)],
      ],
      // meshes: [{value: mine ? mine.meshes : null, disabled: !this.canEdit}, [Validators.required]],
      lat: [
        { value: mine ? mine.site.lngLat[1] : null, disabled: !this.canEdit },
        [Validators.required, LatitudeValidator()],
      ],
      lng: [
        { value: mine ? mine.site.lngLat[0] : null, disabled: !this.canEdit },
        [Validators.required, LongitudeValidator()],
      ],
      showMineDirectionLocation: [mine && !!mine.mineDirectionLocation, []],
      mineDirectionLocationLat: [
        mine && mine.mineDirectionLocation ? mine.mineDirectionLocation[0] : 51,
        [Validators.required, LatitudeValidator()],
      ],
      mineDirectionLocationLng: [
        mine && mine.mineDirectionLocation ? mine.mineDirectionLocation[1] : 100,
        [Validators.required, LongitudeValidator()],
      ],
      siteDirections: [mine && mine.site && mine.site.directions ? mine.site.directions : null],
      // numOfLanes: [null, [Validators.required]],
      // loadTime: [null, [Validators.required, Validators.min(0)]],
    });

    this.onChanges();
  }

  submit() {
    if (this.mineForm.valid) {
      const requestBody = {
        site: {
          name: this.mineForm.controls['name'].value.trim(),
          lngLat: [+this.mineForm.controls['lng'].value, +this.mineForm.controls['lat'].value],
          radius: Math.round(this.mineForm.controls['radius'].value * 1609),
          directions: this.mineForm.controls['siteDirections'].value,
        },
        // numOfLanes: this.mineForm.controls['numOfLanes'].value,
        // loadTime: this.mineForm.controls['loadTime'].value,
        // meshes: this.mineForm.controls['meshes'].value,
      };

      if (this.data) {
        requestBody.site['id'] = this.data.site.id;
        if (this.mineForm.controls['showMineDirectionLocation'].value) {
          requestBody['mineDirectionLocation'] = [
            this.mineForm.controls['mineDirectionLocationLng'].value,
            this.mineForm.controls['mineDirectionLocationLat'].value,
          ];
        } else {
          delete requestBody['mineDirectionLocation'];
        }
        this.submitting = true;

        this.mineApiService.updateMine(this.data.id, requestBody).subscribe(
          (resp) => {
            this.submitting = false;
            this.snackBar.open('Mine successfully updated', null, {
              duration: 5000,
            });

            this.dialogRef.close(resp);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      } else {
        this.mineApiService.createMine(requestBody).subscribe(
          (resp) => {
            this.submitting = false;
            this.snackBar.open('Mine successfully created', null, {
              duration: 5000,
            });

            this.dialogRef.close(resp);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  private async loadFiles() {
    if (this.data) {
      const files = await this.mineApiService.getAttachments$(this.data.id);
      this.uploadedFiles$$.next(files);
    }
  }
}
