import { ChangeDetectorRef, Directive, HostBinding, Input, OnInit } from '@angular/core';

// temp repeat until I can full untwist ui-components
@Directive({
  selector: '[saAutomatedSelector]',
})
export class AutomatedSelectorDirective implements OnInit {
  private _automatedName: string;
  @Input()
  public saAutomatedSelector;

  @Input()
  set automatedName(name: string) {
    this._automatedName = name;
    this.automatedDataName = this._automatedName;
    this.cd.markForCheck();
  }

  get automatedName(): string {
    return this._automatedName;
  }

  @HostBinding('attr.data-aid') public automatedTag;

  @HostBinding('attr.data-aid-name') public automatedDataName;

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.automatedTag = this.saAutomatedSelector;
    this.automatedDataName = this.automatedName;
  }
}
