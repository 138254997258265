<sa-nav [visible]="displayNav" (onMenuClose)="displayNav = false"></sa-nav>

<div class="email-settings-component">
  <div class="top-menu">
    <div class="side-menu"><img class="menu-icon" src="../../../assets/icons/menu-24.svg" (click)="menuClick()" /></div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="../../../assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel.email || userLabel.name || 'Unknown' }}
    </div>
  </div>

  <div class="head-wrap">
    <h1>Loader Integration</h1>
    <div class="button-wrap">
      <a
        *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'"
        [routerLink]="['/', 'loader-integration-v2', 'loader-name-mapping']"
      >
        <div class="mapping-button">Loader Names</div>
      </a>
      <a [routerLink]="['/', 'crew-data']" *ngIf="crewDataEnabled$ | async">
        <div class="analytics-button">Crew Data</div>
      </a>
      <a [routerLink]="['/', 'loader-analytics-dashboard-v2']" *saFeatureFlag="'loaderAnalyticsV2'">
        <div class="analytics-button">Analytics</div>
      </a>
      <div class="add-button" (click)="addForm()">
        <i class="material-icons" saAutomatedSelector="loader-integration__add-button">add</i>
      </div>
    </div>
  </div>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="loaderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loader</th>
        <td
          mat-cell
          *matCellDef="let emailDetails"
          saAutomatedSelector="loader__email"
          automatedname="email"
          (click)="router.navigate(['/loader-integration-v2/detail', emailDetails.id])"
        >
          <ng-container *ngIf="emailDetails.loaderId; else definedInFile">
            <a [routerLink]="['/loader-integration-v2/detail', emailDetails.id]">{{ emailDetails.loaderName }}</a>
          </ng-container>
          <ng-template #definedInFile>
            Defined in data
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td
          mat-cell
          *matCellDef="let emailDetails"
          saAutomatedSelector="loader__email"
          automatedname="email"
          (click)="router.navigate(['/loader-integration-v2/detail', emailDetails.id])"
        >
          <h4>{{ emailDetails.email }}</h4>
        </td>
      </ng-container>

      <ng-container matColumnDef="import">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Most Recent Import</th>
        <td
          mat-cell
          *matCellDef="let emailDetails"
          (click)="router.navigate(['/loader-integration-v2/detail', emailDetails.id])"
        >
          <div *ngIf="emailDetails.lastEmail.truckTimeOrdersMatched !== null">
            {{ emailDetails.lastEmail.truckTimeOrdersMatched }} loads imported on
            {{ emailDetails.lastEmail.uploadTime | date: 'MM/dd/yy' }}
          </div>
          <div *ngIf="emailDetails.lastEmail.truckTimeOrdersMatched === null">--</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td
          mat-cell
          *matCellDef="let emailDetails"
          (click)="router.navigate(['/loader-integration-v2/detail', emailDetails.id])"
        >
          <i
            class="material-icons"
            saAutomatedSelector="loader-integration__edit-button"
            (click)="$event.stopPropagation(); addForm(emailDetails)"
            >edit</i
          >
          <i
            class="material-icons delete-icon"
            (click)="deleteEmailSettings(emailDetails.id)"
            saAutomatedSelector="loader__delete"
            >delete</i
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let emailDetails; columns: displayedColumns" saAutomatedSelector="loader__row"></tr>
    </table>
  </div>

  <sa-email-form-v2 *ngIf="displayForm" [selectedEmail]="selectedEmail" (goBack)="closeForm()"> </sa-email-form-v2>
</div>
