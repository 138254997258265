export const FRAC_ID = 'lmo_frac_id';
export const DISTRIBUTION_CENTER_ID = 'lmo_distribution_center_id';
export const ORDER_ID = 'lmo_order_id';
export const FCTV_ID = 'lmo_fctv_id';
export const FCPO_ID = 'lmo_fcpo_id';
export const STAGE_NUMBER = 'lmo_stage_number';
export const LOADER_CLOSED_HOURS_ID = 'lmo_loader_id_closed_hours';
export const PURCHASE_ORDER_ID = 'lmo_purchase_order_id';
export const DCTV_ID = 'lmo_dctv_id';
export const DCPO_ID = 'lmo_dcpo_id';
