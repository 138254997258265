import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-bulk-decline-dialog',
  templateUrl: './bulk-decline-dialog.component.html',
  styleUrls: ['./bulk-decline-dialog.component.scss'],
})
export class BulkDeclineDialogComponent implements OnInit {
  data: any;
  showScreen = 1;

  reasons = ['No longer needed', 'Do not have drivers', 'Change mesh'];
  reasonInput: string;

  constructor(public dialogRef: MatDialogRef<BulkDeclineDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.data = _data;
  }

  ngOnInit() {}

  cancelAction() {
    this.dialogRef.close(false);
  }

  continueAction(reason) {
    this.dialogRef.close(reason);
  }
}
