import { Truck } from './truck.model';

export interface User {
  id: number;
  name: string;
  phone: string;
  lastTimeSeen: string;
  lastLngLat: [number, number];
  activeSession: boolean;
  lastUsedTruck: Truck;
  deleted: boolean;
  userStatus: UserStatus;
  isOnHauliRunboard: boolean;
  subcontractorName: string;
  eligibleForBoost: boolean;
  logs: AppLog[];
  is5f: boolean;
}

export interface AppLog {
  id: number;
  timestamp: string;
  ownerI: number;
  deviceMode: string;
  os: string;
  osVersion: string;
  appVersion: string;
  description: string;
}

export interface UserStatus {
  userId: number;
  type: 'Unreachable' | 'Online' | 'Offline' | 'ActionNeeded';
  shortTitle: string;
  isException: boolean;
  exceptionTitle: string;
  exceptionDescription: string;
  exceptionUrl: string;
  lastTimeSeen: string;
  lastLngLat: [number, number];
  shiftEndTimestamp: string;
  currentOrderId: number | null;
  isReusable: boolean;
}

export function isUnreachable(userStatus: UserStatus): boolean {
  if (!userStatus) {
    return false;
  }
  return userStatus.type === 'Unreachable';
}

export function isOnline(userStatus: UserStatus): boolean {
  if (!userStatus) {
    return false;
  }
  return userStatus.type === 'Online';
}

export function isActionNeeded(userStatus: UserStatus): boolean {
  if (!userStatus) {
    return false;
  }
  return userStatus.type === 'ActionNeeded';
}
