import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderApiService } from '../../services/api/order.api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  map,
  startWith,
  withLatestFrom,
  take,
  filter,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';
import { combineLatest, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { FileUploadService } from '../../services/api/file-upload.service';
import { ChoiceDialogComponent } from '../../ui-components/choice-dialog/choice-dialog.component';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReasonDialogComponent } from '../reason-dialog/reason-dialog.component';
import { WellApiService } from '../../services/api/well.api.service';
import { WellService } from '../../services/well.service';
import { CancelDialogComponent } from '../cancel-dialog/cancel-dialog.component';
import { CrudService } from '../../services/crud.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Truck } from '~models/truck.model';
import { TrucksService } from '../../trucks.service';
import { SearchApiService } from '~services/api/search.api.service';
import { SettingsService } from '~billing/services/settings.service';
import { Charge, ChargeType } from '~billing/models';
import { AddChargeDialogComponent } from '../add-charge-dialog/add-charge-dialog.component';
import { HelperService } from '~services/helper.service';
import { getFracName } from 'src/app/ui-components/pipes/frac-name.pipe';
import { ConstantsApiService } from '~services/api/constants.api.service';
import { LOCAL_STORAGE_CONST } from '../../constants/local-storage-constants';
import { BillingSocketService } from '~services/billing-socket.service';
import { SwitchVendorContractComponent } from '../switch-vendor-contract/switch-vendor-contract.component';

const BILLING_STATUS = {
  notBilled: 'not_billed',
  approvedByDispatcher: 'approved_by_dispatcher',
  approvedByLMO: 'approved_by_lmo',
  billed: 'billed',
  approved: 'approved',
};
const PRESENCE_PING_TIME = 60 * 1000;

@Component({
  selector: 'sa-completed-order-detail',
  templateUrl: './completed-order-detail.component.html',
  styleUrls: ['./completed-order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompletedOrderDetailComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private orderApiService: OrderApiService,
    private wellApiService: WellApiService,
    private constantApiService: ConstantsApiService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private wellService: WellService,
    private crud: CrudService,
    private router: Router,
    private errorHandler: ErrorHandlingService,
    private sanitizer: DomSanitizer,
    public truckService: TrucksService,
    private searchApiService: SearchApiService,
    public helperService: HelperService,
    public billingSocketService: BillingSocketService,
  ) {}
  @Input() selectedOrderId: any;
  @Input() selectedFracId: any;
  @Input() loaderList: any[] = [];
  @Input() payloadList: any[] = [];
  @Input() driverList: any[] = [];
  @Input() truckList: any[] = [];
  @Output() onMenuClose: EventEmitter<boolean> = new EventEmitter();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  orderForm: FormGroup;
  orderFormOld: FormGroup;
  updatedFields = new Set();
  vendorContracts: any[];
  orderIdForSocket = 0;
  historyFields = new Set();
  selectedOrder: any;
  showSaveChanges = false;
  chargeChange = false;
  subs: Subscription[] = [];
  canApproveChanges = false;
  isLmoAccount = false;
  useShaleAppsInvoice = false;
  isChanged = false;
  fileToUpload: File = null;
  uploading = false;
  filterMesh: FormControl = new FormControl();
  filterLoader: FormControl = new FormControl();
  filterDriver: FormControl = new FormControl();
  filteredLoaderOptions: Observable<string[]>;
  filteredMeshOptions: Observable<string[]>;
  filteredDriverOptions: Observable<string[]>;
  selectedOrderStatus = '';
  lineHaulCharge = 0;
  lineHaulContractCost = 0;
  deadheadCharge = 0;
  unloadingDetentionCharge = 0;
  loadingDetentionCharge = 0;
  previewFileUrl = '';
  fileLoading = false;
  updatingContract = false;
  selectedFileName = '';
  imageFileTypes = ['svg', 'png', 'jpg', 'jpeg', 'png'];
  fileTypes = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png'];
  allowedFileTypes = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt'];
  decimalFields = ['additionCharge', 'additionDeduction', 'hourlyDetentionRate', 'deadheadCostPerMile'];
  dropdownFields = ['loader', 'payload', 'driverId', 'truckId'];
  nonEditableBillingStatus = [BILLING_STATUS.approvedByDispatcher, BILLING_STATUS.billed, BILLING_STATUS.approved];
  isAutomatedCSVFile = false;
  downloadedData: any;
  selectedIndex = -1;
  rotateDegree = 0;
  public files: NgxFileDropEntry[] = [];
  selectedTruck: any;
  chargeTypes: ChargeType[] = [];
  orderCharges: Charge[] = [];
  savingData = false;
  allFracView = true;
  newOrderCharges: Charge[] = [];
  socketUnsubscribed = false;
  LMOChargeType = {
    deadhead: 'Deadhead',
    det_loading: 'LoadingDetention',
    linehaul: 'Linehaul',
    accessorial: 'Accessorial',
    deduction: 'Deduction',
    det_unloading: 'UnLoadingDetention',
    fuel_surcharge: 'FuelSurcharge',
  };
  activeUsers$$ = new Observable();

  socketTimer = interval(PRESENCE_PING_TIME);

  private destroy$ = new Subject();

  public get formVendorContract() {
    return this.orderForm.controls['vendorContract'].value;
  }

  public validation_messages = {
    invoice: [
      { type: 'required', message: 'Invoice number is required' },
      { type: 'pattern', message: 'Invoice must contain only numbers, letters, hyphen and dash' },
    ],
  };

  saveData = (function() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    // a.style = "display: none";
    return function(data, fileName) {
      const blob = new Blob([data.Data], { type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    };
  })();

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (!this.socketUnsubscribed) {
      this.unsubscribeOnDestroy();
    }
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.close();
      }
    }
  }

  public get deadheadUnit() {
    return this.orderForm.controls['deadheadMileage'].value - this.orderForm.controls['deadheadFreeMileage'].value;
  }

  public get maxDetentionPerLoad() {
    return (this.selectedOrder.vendorContract.maxDetentionPerLoad / 100).toFixed(2);
  }

  public get deadheadUnitCost() {
    return this.orderForm.controls['deadheadCostPerMile'].value;
  }

  public get loadingDetentionUnit() {
    return this.orderForm.controls['waitAtLoader'].value - this.orderForm.controls['pickupFreeTime'].value;
  }

  public get unloadingDetentionUnit() {
    return this.orderForm.controls['waitAtWell'].value - this.orderForm.controls['dropOffFreeTime'].value;
  }

  public get hourlyDetentionCost() {
    return this.orderForm.controls['hourlyDetentionRate'].value;
  }

  public get boostPercent() {
    return this.orderForm.controls['boostPercent'].value;
  }

  public get lineHaulUnit() {
    if (this.selectedOrder.boxes) {
      let totalLoadWeight = this.orderForm.controls['box1Weight'].value || 0;
      if (this.selectedOrder.boxes.length > 1) {
        totalLoadWeight += this.orderForm.controls['box2Weight'].value || 0;
      }
      return +(Math.round((totalLoadWeight / 2000 + Number.EPSILON) * 100) / 100).toFixed(2);
    } else {
      return (
        +(Math.round((this.orderForm.controls['loadWeight'].value / 2000 + Number.EPSILON) * 100) / 100).toFixed(2) || 0
      );
    }
  }

  public get loadedMiles() {
    return this.orderForm.controls['tripMileage'].value;
  }

  ngOnInit() {
    this.selectedFracId = this.route.firstChild.snapshot.params['fracId'];
    if (!this.selectedFracId) {
      this.allFracView = false;
      this.selectedFracId = this.route.parent.snapshot.params['id'];
    }
    this.billingSocketService.initSocket();
    this.loadWellData();
    this.subscribeToRoute();
    this.canApproveChanges = this.userService.isLMOAccount() || this.userService.isShaleAccount();
    this.isLmoAccount = this.userService.isLMOAccount();
  }

  private subscribeToRoute() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedOrderId = this.route.firstChild && this.route.firstChild.snapshot.params['orderId'];
        this.selectedFracId =
          (this.route.firstChild && this.route.firstChild.snapshot.params['fracId']) ||
          this.route.parent.snapshot.params['id'];
        this.previewFileUrl = '';
        this.isAutomatedCSVFile = false;
        this.previewFileUrl = '';
        this.isAutomatedCSVFile = false;
        this.showSaveChanges = false;
        this.setSelectedFrac();
        if (this.selectedOrderId) {
          this.loadWellData();
        }
      }
    });
  }

  private setSelectedFrac() {
    this.wellService.selectedFracs$.pipe(take(1)).subscribe((fracs) => {
      const currentFrac = fracs.find((frac) => frac.id === +this.selectedFracId);
      if (!currentFrac && this.selectedFracId) {
        this.wellService.setSelectedFracs([...fracs, { id: +this.selectedFracId }]);
      }
    });
  }

  loadWellData() {
    if (!this.selectedFracId || isNaN(this.selectedFracId) || this.selectedFracId === '0') {
      return;
    }
    this.wellService.setValue({});
    this.crud.httpClientReady.pipe(filter(Boolean)).subscribe((_) => {
      combineLatest([
        this.wellApiService.getWellMinimal(this.selectedFracId),
        this.constantApiService.getMeshTypes(),
      ]).subscribe(([well, meshes]) => {
        this.useShaleAppsInvoice = well.useShaleAppInvoiceNumber || false;
        this.payloadList = meshes;
        if (well.fracMines) {
          this.loaderList = [...well.fracMines];
        }
        this.wellService.setValue(well);
        this.loadData();
      });
    });
  }

  private loadData() {
    if (!this.selectedOrderId || isNaN(this.selectedOrderId) || this.selectedOrderId === '0') {
      return;
    }
    this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((_) => {
      combineLatest([
        this.orderApiService.getCompletedBillingOrderDetails(this.selectedOrderId),
        this.orderApiService.getChargeTypesForOrder(this.selectedOrderId),
        this.orderApiService.getVendorContracts(this.selectedOrderId),
      ]).subscribe(([order, chargeTypes, contracts]: [any, any, any]) => {
        this.listenToPresenceList();
        this.setPresenceTime();
        this.selectedOrder = order;
        if (this.selectedOrder.duplicatedBolOrderIds) {
          this.selectedOrder.duplicatedBolOrderIds = this.selectedOrder.duplicatedBolOrderIds.filter(
            (id) => id !== this.selectedOrderId,
          );
        }
        this.vendorContracts = contracts;
        this.chargeTypes = chargeTypes.filter((type) => !type.isDefaultForType);
        this.orderCharges = order.charges && [...order.charges.filter((charge) => charge.unitOnQuantity !== '%')];
        this.sortBoxes();
        this.setUpdateHistory();
        this.setPricingData();
        this.buildForm();
        this.setSelectedFrac();
      });
    });
  }

  private getIntSafeNewOrderCharges(): Charge[] {
    return this.newOrderCharges.map((charge) => ({
      ...charge,
      pricePerUnit: Math.trunc(charge.pricePerUnit),
      totalAmount: Math.trunc(charge.totalAmount),
    }));
  }

  private setPricingData() {
    const lineHaulCharge = this.selectedOrder.charges.find((charge) => charge.defaultShaleAppsCharge === 'linehaul');
    if (lineHaulCharge) {
      this.lineHaulCharge = lineHaulCharge.totalAmount;
    }
    const deadheadCharge = this.selectedOrder.charges.find((charge) => charge.defaultShaleAppsCharge === 'deadhead');
    if (deadheadCharge) {
      this.deadheadCharge = deadheadCharge.totalAmount;
    }
    const detUnloadingCharge = this.selectedOrder.charges.find(
      (charge) => charge.defaultShaleAppsCharge === 'det_unloading',
    );
    if (detUnloadingCharge) {
      this.unloadingDetentionCharge = detUnloadingCharge.totalAmount;
    }
    const detLoadingCharge = this.selectedOrder.charges.find(
      (charge) => charge.defaultShaleAppsCharge === 'det_loading',
    );
    if (detLoadingCharge) {
      this.loadingDetentionCharge = detLoadingCharge.totalAmount;
    }
  }

  public getOldValueAndAnomalousValue(field: string, isAnomalous: boolean) {
    const oldValueString = this.getOldValue(field);
    const anomalousValueString = isAnomalous ? 'SANDi® thinks this is very high' : '';

    let finalString = '';
    finalString += oldValueString;
    if (oldValueString && oldValueString.length > 0 && anomalousValueString && anomalousValueString.length > 0) {
      finalString += '<br/><br/>';
    }
    finalString += anomalousValueString;

    return finalString;
  }

  public getOldValue(field) {
    const selectedField = (this.selectedOrder['orderBillingHistory'] || [])
      .reverse()
      .find((value) => value.changedField === field);

    if (!selectedField) {
      return '';
    }

    if (selectedField && (selectedField.oldValue < 0 || !selectedField.oldValue)) {
      return this.prettyPrintPreviousValue(
        `No Previous Value Found.`,
        selectedField.changedByName,
        selectedField.changedTimestamp,
      );
    } else {
      if (field === 'vendorContract') {
        const oldContract = JSON.parse(selectedField.oldValue);
        return this.prettyPrintPreviousValue(
          oldContract.name,
          selectedField.changedByName,
          selectedField.changedTimestamp,
        );
      } else {
        return this.decimalFields.includes(field)
          ? this.prettyPrintPreviousValue(
              selectedField.oldValue / 100,
              selectedField.changedByName,
              selectedField.changedTimestamp,
            )
          : this.prettyPrintPreviousValue(
              selectedField.oldValue,
              selectedField.changedByName,
              selectedField.changedTimestamp,
            );
      }
    }
  }

  public getOldValueFromData(field) {
    const selectedField = this.selectedOrder['orderBillingHistory']
      .sort((a, b) => {
        return new Date(b.changedTimestamp).getTime() - new Date(a.changedTimestamp).getTime();
      })
      .find((fields) => fields.changedField === field);
    const selectedFieldOldValue = selectedField.oldValue;
    switch (field) {
      case 'loader':
        const loaderOldValue =
          this.loaderList && this.loaderList.find((loader) => loader.id === +selectedFieldOldValue);
        return `${(loaderOldValue &&
          this.prettyPrintPreviousValue(
            loaderOldValue.site.name,
            selectedField.changedByName,
            selectedField.changedTimestamp,
          )) ||
          'No Value Found'}`;
      case 'payload':
        const payloadOldValue =
          this.payloadList && this.payloadList.find((payload) => payload.id === +selectedFieldOldValue);
        return `${(payloadOldValue &&
          this.prettyPrintPreviousValue(
            payloadOldValue.type,
            selectedField.changedByName,
            selectedField.changedTimestamp,
          )) ||
          'No Value Found'}`;
      case 'driverId':
        const driverOldValue =
          this.driverList && this.driverList.find((driver) => driver.id === +selectedFieldOldValue);
        return `${(driverOldValue &&
          this.prettyPrintPreviousValue(
            driverOldValue.name,
            selectedField.changedByName,
            selectedField.changedTimestamp,
          )) ||
          'No Value Found'}`;
      case 'truckId':
        const truckOldValue = this.truckList && this.truckList.find((truck) => truck.id === +selectedFieldOldValue);
        return `${(truckOldValue &&
          this.prettyPrintPreviousValue(
            truckOldValue.name,
            selectedField.changedByName,
            selectedField.changedTimestamp,
          )) ||
          'No Value Found'}`;
      default:
        return 'Previous value not found';
    }
  }

  public prettyPrintPreviousValue(value: string | number, name: string, date: string): string {
    return `Previous Value: <b>${value}</b> changed by <b>${name}</b> on <b>${new Date(date).toLocaleString('en-US', {
      hour12: true,
    })}</b>`;
  }

  public prettyPrintAnomalousValue(): string {
    return `This value is exceptionally high.`;
  }

  setUpdateHistory() {
    if (!this.selectedOrder) {
      return;
    }
    if (this.selectedOrder['orderBillingHistory']) {
      this.selectedOrder['orderBillingHistory'].forEach((item) => {
        if (!this.updatedFields.has(item.changedField)) {
          this.updatedFields.add(item.changedField);
        }
      });
    }

    if (this.selectedOrder['completeOrderBillingHistory']) {
      this.selectedOrder['completeOrderBillingHistory'].forEach((item) => {
        if (!this.historyFields.has(item.changedField)) {
          this.historyFields.add(item.changedField);
        }
      });
    }
  }

  sortBoxes() {
    if (!this.selectedOrder || !this.selectedOrder.boxes) {
      return;
    }
    this.selectedOrder.boxes.sort((a, b) => {
      return a.id - b.id;
    });
  }

  buildForm() {
    const isBilled = this.checkForEditableOrder() || this.canApproveChanges;
    const oldFormData = {
      boostPercent: [
        { value: this.selectedOrder.boostPercent ? this.selectedOrder.boostPercent : 0, disabled: true },
        [Validators.required],
      ],
      loader: [
        { value: this.selectedOrder.mine ? this.selectedOrder.mine : (null as any), disabled: isBilled },
        [Validators.required],
      ],
      sequenceNumber: [{ value: this.selectedOrder.loadNumber, disabled: true }, [Validators.required]],
      payload: [{ value: this.selectedOrder.mesh, disabled: isBilled }, [Validators.required]],
      loadWeight: [
        {
          value:
            this.selectedOrder.actualLoadWeight != null && this.selectedOrder.actualLoadWeight > -1
              ? this.selectedOrder.actualLoadWeight
              : null,
          disabled: isBilled,
        },
        [Validators.required, Validators.max(200000)],
      ],
      box1Name: [
        {
          value:
            this.selectedOrder.boxes && this.selectedOrder.boxes.length >= 1
              ? this.selectedOrder.boxes[0].name
              : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      box1Weight: [
        {
          value:
            this.selectedOrder.boxes &&
            this.selectedOrder.boxes.length >= 1 &&
            this.selectedOrder.boxes[0].actualLoadWeight != null &&
            this.selectedOrder.boxes[0].actualLoadWeight > -1
              ? this.selectedOrder.boxes[0].actualLoadWeight
              : null,
          disabled: isBilled,
        },
        [Validators.required, Validators.max(200000)],
      ],
      box2Name: [
        {
          value:
            this.selectedOrder.boxes && this.selectedOrder.boxes.length === 2
              ? this.selectedOrder.boxes[1].name
              : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      box2Weight: [
        {
          value:
            this.selectedOrder.boxes &&
            this.selectedOrder.boxes.length === 2 &&
            this.selectedOrder.boxes[1].actualLoadWeight != null &&
            this.selectedOrder.boxes[1].actualLoadWeight > -1
              ? this.selectedOrder.boxes[1].actualLoadWeight
              : null,
          disabled: isBilled,
        },
        [Validators.required, Validators.max(200000)],
      ],
      bolNumber: [
        {
          value: this.selectedOrder.bolNumber ? this.selectedOrder.bolNumber : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],

      driverId: [
        { value: this.selectedOrder.user ? this.selectedOrder.user : (null as any), disabled: isBilled },
        [Validators.required],
      ],
      truckId: [
        { value: this.selectedOrder.truck ? this.selectedOrder.truck : (null as any), disabled: isBilled },
        [Validators.required],
      ],
      deadheadMileage: [
        {
          value: this.selectedOrder.deadHead ? this.selectedOrder.deadHead : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      tripMileage: [
        {
          value: this.selectedOrder.loadedMiles ? this.selectedOrder.loadedMiles : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      waitAtLoader: [
        {
          value: this.selectedOrder.waitAtLoader
            ? this.checkForNegativeValue(this.selectedOrder.waitAtLoader)
            : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      waitAtWell: [
        {
          value: this.selectedOrder.waitAtWell
            ? this.checkForNegativeValue(this.selectedOrder.waitAtWell)
            : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],

      ticketNumber: [
        {
          value: this.selectedOrder.ticketNumber ? this.selectedOrder.ticketNumber : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      bulkStorageNumber: [
        {
          value: this.selectedOrder.bulkStorageNumber ? this.selectedOrder.bulkStorageNumber : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      invoiceNumber: [
        {
          value: this.selectedOrder.invoiceNumber ? this.selectedOrder.invoiceNumber : ('' as string),
          disabled: isBilled || this.useShaleAppsInvoice,
        },
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_-]+$')]),
      ],

      billingIdentifier: [
        {
          value: this.selectedOrder.billingIdentifier ? this.selectedOrder.billingIdentifier : ('' as string),
          disabled: true,
        },
        [Validators.required],
      ],

      additionCharge: [
        {
          value: this.selectedOrder.additionCharge
            ? (this.selectedOrder.additionCharge / 100).toFixed(2)
            : (0.0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      additionDeduction: [
        {
          value: this.selectedOrder.additionDeduction
            ? (this.selectedOrder.additionDeduction / 100).toFixed(2)
            : (0.0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      comment: [
        {
          value: this.selectedOrder.comment ? this.selectedOrder.comment : ('' as string),
          disabled: isBilled,
        },
        [Validators.required],
      ],

      hourlyDetentionRate: [
        {
          value: this.selectedOrder.hourlyDetentionRate
            ? (this.selectedOrder.hourlyDetentionRate / 100).toFixed(2)
            : (0.0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      pickupFreeTime: [
        {
          value: this.selectedOrder.pickupFreeTime ? this.selectedOrder.pickupFreeTime : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      dropOffFreeTime: [
        {
          value: this.selectedOrder.dropOffFreeTime ? this.selectedOrder.dropOffFreeTime : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      deadheadFreeMileage: [
        {
          value: this.selectedOrder.deadheadFreeMileage ? this.selectedOrder.deadheadFreeMileage : (0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      deadheadCostPerMile: [
        {
          value: this.selectedOrder.deadheadCostPerMile
            ? (this.selectedOrder.deadheadCostPerMile / 100).toFixed(2)
            : (0.0 as number),
          disabled: isBilled,
        },
        [Validators.required],
      ],
      vendorContract: [this.selectedOrder.vendorContract || null, [Validators.required]],
      carrierNotes: [{ value: this.selectedOrder.carrierNotes || '', disabled: isBilled }, []],
    };

    this.orderFormOld = this.fb.group(oldFormData);
    this.orderForm = this.fb.group(oldFormData);
    this.selectedTruck = this.orderForm.controls['truckId'].value;

    if (this.orderForm.controls['loadWeight'].value > 200000) {
      this.orderForm.controls['loadWeight'].setErrors({ max: true });
      this.orderForm.controls['loadWeight'].markAsTouched();
    }
    if (this.orderForm.controls['box1Weight'].value > 200000) {
      this.orderForm.controls['box1Weight'].setErrors({ max: true });
      this.orderForm.controls['box1Weight'].markAsTouched();
    }
    if (this.orderForm.controls['box2Weight'].value > 200000) {
      this.orderForm.controls['box2Weight'].setErrors({ max: true });
      this.orderForm.controls['box2Weight'].markAsTouched();
    }
    this.orderForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.showSaveChanges = true;
    });
    this.listenForPrices();

    this.filteredLoaderOptions = this.filterLoader.valueChanges.pipe(
      startWith<string | any>(''),
      map((value) => (typeof value === 'string' ? value : value.site.name)),
      map((name) => (name ? this._filter(name) : this.loaderList)),
    );

    this.filteredMeshOptions = this.filterMesh.valueChanges.pipe(
      startWith<string | any>(''),
      map((value) => (typeof value === 'string' ? value : value.type)),
      map((type) => (type ? this._filterMesh(type) : this.payloadList)),
    );

    this.filteredDriverOptions = this.filterDriver.valueChanges.pipe(
      startWith<string | any>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name
          ? this._filterDriver(name)
          : this.selectedOrder && this.selectedOrder.user
          ? this.driverList.filter((driver) => driver.id !== this.selectedOrder.user.id)
          : this.driverList,
      ),
    );

    this.orderForm.controls['truckId'].valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchTerm) => this.truckService.searchTruck(searchTerm));

    this.showSaveChanges = false;
  }

  contractFieldCheck() {
    return (
      !this.canApproveChanges ||
      this.selectedOrder.billingStatus === BILLING_STATUS.approved ||
      !this.userService.hasPermission('can_approve_ticket')
    );
  }

  openSwitchContractDialog() {
    if (!this.userService.hasPermission('can_approve_ticket')) {
      return;
    }
    const dialogRef = this.dialog.open(SwitchVendorContractComponent, {
      data: {
        contract: this.orderForm.get('vendorContract').value,
        vendorContracts: this.vendorContracts.reverse(),
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((data) => {
        this.orderForm.get('vendorContract').setValue(data, { emitEvent: false });
      });
  }

  displayTruckFn(truck?: Truck): string | undefined {
    return truck ? truck.name : undefined;
  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.loaderList.filter((option) => option.site.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterMesh(value) {
    const filterValue = value.toLowerCase();
    return this.payloadList.filter((option) => option.type.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterDriver(value) {
    const filterValue = value.toLowerCase();
    return this.driverList.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onFieldChange(fieldName) {
    this.orderForm.controls[fieldName].markAsTouched();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file]: File[] = event.target.files;
      if (file.size > 0) {
        this.fileToUpload = file;
        this.uploadFile();
      } else {
        console.log(`Files Length: ${event.target.files.length}`);
        console.log(`onFileChange file: ${file.name} with size: ${file.size} of type: ${file.type}`);
        this.errorHandler.showError(`${file.name} has a size of 0kb`);
      }
    }
  }

  private listenForPrices() {
    combineLatest([
      this.orderForm.controls['waitAtLoader'].valueChanges.pipe(
        startWith(this.orderForm.controls['waitAtLoader'].value),
      ),
      this.orderForm.controls['pickupFreeTime'].valueChanges.pipe(
        startWith(this.orderForm.controls['pickupFreeTime'].value),
      ),
      this.orderForm.controls['hourlyDetentionRate'].valueChanges.pipe(
        startWith(this.orderForm.controls['hourlyDetentionRate'].value),
      ),
    ])
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.setloadingCharge();
      });

    combineLatest([
      this.orderForm.controls['waitAtWell'].valueChanges.pipe(startWith(this.orderForm.controls['waitAtWell'].value)),
      this.orderForm.controls['dropOffFreeTime'].valueChanges.pipe(
        startWith(this.orderForm.controls['dropOffFreeTime'].value),
      ),
      this.orderForm.controls['hourlyDetentionRate'].valueChanges.pipe(
        startWith(this.orderForm.controls['hourlyDetentionRate'].value),
      ),
    ])
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.setUnloadingCharge();
      }),
      combineLatest([
        this.orderForm.controls['deadheadMileage'].valueChanges.pipe(
          startWith(this.orderForm.controls['deadheadMileage'].value),
        ),
        this.orderForm.controls['deadheadFreeMileage'].valueChanges.pipe(
          startWith(this.orderForm.controls['deadheadFreeMileage'].value),
        ),
        this.orderForm.controls['deadheadCostPerMile'].valueChanges.pipe(
          startWith(this.orderForm.controls['deadheadCostPerMile'].value),
        ),
      ])
        .pipe(debounceTime(400), takeUntil(this.destroy$))
        .subscribe((_) => {
          this.setDeadheadCharge();
        });

    combineLatest([
      this.orderForm.controls['tripMileage'].valueChanges.pipe(startWith(this.orderForm.controls['tripMileage'].value)),
      this.orderForm.controls['loadWeight'].valueChanges.pipe(startWith(this.orderForm.controls['loadWeight'].value)),
      this.orderForm.controls['box1Weight'].valueChanges.pipe(startWith(this.orderForm.controls['box1Weight'].value)),
      this.orderForm.controls['box2Weight'].valueChanges.pipe(startWith(this.orderForm.controls['box2Weight'].value)),
    ])
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.setLinehaulCharge();
      });
  }

  private setloadingCharge() {
    const loadCharge = Number(
      ((this.orderForm.get('waitAtLoader').value - this.orderForm.get('pickupFreeTime').value) *
        this.orderForm.get('hourlyDetentionRate').value *
        100) /
        60,
    );
    this.loadingDetentionCharge = loadCharge >= 0 ? loadCharge : 0;
  }

  private setUnloadingCharge() {
    const unloadCharge = Number(
      ((this.orderForm.get('waitAtWell').value - this.orderForm.get('dropOffFreeTime').value) *
        this.orderForm.get('hourlyDetentionRate').value *
        100) /
        60,
    );
    this.unloadingDetentionCharge = unloadCharge >= 0 ? unloadCharge : 0;
  }

  private setDeadheadCharge() {
    const deadheadCharge = Number(
      (this.orderForm.get('deadheadMileage').value - this.orderForm.get('deadheadFreeMileage').value) *
        this.orderForm.get('deadheadCostPerMile').value *
        100,
    );
    this.deadheadCharge = deadheadCharge >= 0 ? deadheadCharge : 0;
  }

  private setLinehaulCharge() {
    const [costs] = this.selectedOrder.vendorContract.costsPerLoad.filter((cost) => {
      return (
        cost.minimumMileage <= this.orderForm.get('tripMileage').value &&
        cost.maximumMileage >= this.orderForm.get('tripMileage').value
      );
    });

    if (costs) {
      if (this.orderForm.get('vendorContract').value.costType === 'fixed') {
        this.lineHaulCharge = Number(costs.cost);
        this.lineHaulContractCost = +costs.cost;
      } else {
        let loadWeight = 0;
        if (this.selectedOrder.boxes) {
          loadWeight = this.orderForm.controls['box1Weight'].value + this.orderForm.controls['box2Weight'].value;
        } else {
          loadWeight = this.orderForm.controls['loadWeight'].value;
        }
        if (loadWeight) {
          const totalLoadWeightInTonsToTwoDecimalPlaces = Math.round((loadWeight / 2000 + Number.EPSILON) * 100) / 100;
          this.lineHaulCharge = Math.round(costs.cost * totalLoadWeightInTonsToTwoDecimalPlaces);
        } else {
          this.lineHaulCharge = 0;
        }
        this.lineHaulContractCost = +costs.cost;
      }
    } else {
      this.lineHaulCharge = 0;
    }
  }

  uploadFile() {
    console.log(
      `Uploading file: ${this.fileToUpload.name} with size: ${this.fileToUpload.size} of type: ${this.fileToUpload.type}`,
    );
    this.uploading = true;
    this.fileUploadService.uploadFile(this.selectedOrderId, this.fileToUpload).subscribe(
      (result) => {
        this.uploading = false;
        this.snackbar.open('File uploaded Successfully', null, {
          duration: 5000,
        });
        this.selectedOrder.uploadedFiles = result;
      },
      (err) => {
        console.error(
          `Error uploading file Uploading file: ${this.fileToUpload.name} with size: ${this.fileToUpload.size} of type: ${this.fileToUpload.type}`,
        );
        console.error(err);
        this.uploading = false;
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  checkForEditableOrder() {
    return (
      this.selectedOrder.billingStatus === BILLING_STATUS.approvedByDispatcher ||
      this.selectedOrder.billingStatus === BILLING_STATUS.approved ||
      this.selectedOrder.billingStatus === BILLING_STATUS.billed
    );
  }

  checkForPayloadChange() {
    const payloadFields = [
      'loader',
      'payload',
      'loadWeight',
      'bolNumber',
      'box1Name',
      'box1Weight',
      'box2Name',
      'box2Weight',
    ];

    return payloadFields.some((field) => this.historyFields.has(field));
  }

  isLoadoutReport() {
    return !this.checkForPayloadChange() && this.selectedOrder.dataMatched;
  }

  isManuallyEntered() {
    return this.checkForPayloadChange() || !this.selectedOrder.dataMatched;
  }

  isTripDetails() {
    const tripFields = ['driverId', 'truckId', 'deadheadMileage', 'tripMileage', 'waitAtLoader', 'waitAtWell'];
    return tripFields.some((field) => this.historyFields.has(field));
  }

  public setFile(arg) {
    if (
      (arg === -1 && this.selectedIndex > 0) ||
      (arg === 1 && this.selectedIndex < this.selectedOrder.uploadedFiles.length)
    ) {
      this.selectedIndex += arg;
      if (this.selectedIndex === 0) {
        this.downloadCSVData();
      } else if (this.selectedOrder.dataMatched && this.selectedIndex === 1) {
        this.downloadImportedData();
      } else {
        this.downloadFile(this.selectedOrder.uploadedFiles[this.selectedIndex + arg]);
      }
    }
  }

  downloadFile(file) {
    this.fileLoading = true;
    this.selectedFileName = file.fileName;
    this.isAutomatedCSVFile = false;
    this.fileUploadService.downloadFile(file.id, file.orderId).subscribe(
      (url) => {
        const fileNameArray = file.fileName.toLowerCase().split('.');
        if (this.fileTypes.includes(fileNameArray[fileNameArray.length - 1])) {
          this.previewFileUrl = `${url}`;
        } else {
          this.previewFileUrl = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
        }
        this.fileLoading = false;
      },
      (err) => {
        this.fileLoading = false;
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  downloadCSVData() {
    this.fileLoading = true;
    this.isAutomatedCSVFile = true;
    this.selectedFileName = this.getCSVFileName();
    this.wellApiService
      .getCSVDataForOrder(this.selectedOrder.fracId, this.selectedOrder.id)
      .pipe(withLatestFrom(this.wellService.billingDetails$), take(1))
      .subscribe(
        ([data, well]) => {
          this.downloadedData = { data: data, well: well };
          this.fileLoading = false;
        },
        (error) => {
          this.fileLoading = false;
          this.errorHandler.showError(error, 5000);
        },
      );
  }

  downloadImportedData() {
    this.fileLoading = true;
    this.isAutomatedCSVFile = false;
    this.selectedFileName = this.getImportedFileName();
    this.wellApiService.getImportedDataForOrder(this.selectedOrder.fracId, this.selectedOrder.id).subscribe(
      (url) => {
        // window.open(url, '_blank');
        // this.saveData(data, `${this.wellName}-${this.selectedOrder.loadNumber}-Loader-Backup.csv`);
        const importedFileNameArray = this.getImportedFileName()
          .toLowerCase()
          .split('.');
        if (this.fileTypes.includes(importedFileNameArray[importedFileNameArray.length - 1])) {
          this.previewFileUrl = `${url}`;
        } else {
          this.previewFileUrl = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
        }
        this.fileLoading = false;
      },
      (error) => {
        this.fileLoading = false;
        this.errorHandler.showError(error, 5000);
      },
    );
  }

  public getPreviewDownloadLink() {
    if (this.isAutomatedCSVFile) {
      this.saveData(this.downloadedData.data, `${this.selectedOrder.saUniqueId}.csv`);
    } else if (this.previewFileUrl) {
      if (this.checkFileUrl()) {
        window.open(this.getPreviewLink(), '_blank');
      } else {
        window.open(this.previewFileUrl, '_blank');
      }
    }
  }

  public getPreviewLink() {
    return this.previewFileUrl.split('src=')[1];
  }

  public checkFileUrl() {
    return this.previewFileUrl.startsWith('https://view.officeapps.live.com/op/view.aspx?src=');
  }

  public checkForImageFile() {
    const selectedFileNameArray = this.selectedFileName.toLowerCase().split('.');
    return this.imageFileTypes.includes(selectedFileNameArray[selectedFileNameArray.length - 1]);
  }

  public checkPlaceholder() {
    return this.checkFileUrl() || this.isAutomatedCSVFile;
  }

  public checkForDeleteFile() {
    return (
      this.selectedOrder &&
      this.selectedOrder.uploadedFiles &&
      this.selectedOrder.uploadedFiles.some((file) => file.fileName === this.selectedFileName)
    );
  }

  public rotateImage(deg) {
    if (this.rotateDegree === 0 && deg === -90) {
      this.rotateDegree = 270;
    } else if (this.rotateDegree === 360 && deg === 90) {
      this.rotateDegree = 0;
    } else {
      this.rotateDegree += deg;
    }
  }

  public getCSVFileName() {
    let name = '';
    this.wellService.billingDetails$.pipe(take(1)).subscribe((well) => {
      name = getFracName(well);
    });
    return this.selectedOrder && name
      ? `${name}-${this.selectedOrder && this.selectedOrder.loadNumber}-trucking-Backup.csv`
      : '';
  }

  public getImportedFileName() {
    let name = '';
    this.wellService.billingDetails$.pipe(take(1)).subscribe((well) => {
      name = getFracName(well);
    });
    return `${name}-${this.selectedOrder && this.selectedOrder.loadNumber}-Loader-Backup.csv`;
  }

  checkForNegativeValue(value) {
    if (value < 0) {
      return null;
    } else {
      return value;
    }
  }

  public deleteFile() {
    this.removeFile(this.selectedOrder.uploadedFiles.find((file) => file.fileName === this.selectedFileName));
  }

  removeFile(file) {
    const parts = file.fileName.split('/');
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Are you sure you want to remove ${parts[parts.length - 1]} ?`,
        desc: '',
        button: ['Cancel', 'Remove'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileUploadService.removeFile(file.id, file.orderId).subscribe(
          (files) => {
            this.selectedOrder.uploadedFiles = files;
            this.selectedFileName = '';
            this.previewFileUrl = '';
            this.snackbar.open('File Removed Successfully', null, {
              duration: 5000,
            });
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  close() {
    this.orderIdForSocket = +this.selectedOrderId;
    const showSaveDialog = (this.showSaveChanges && this.orderForm.touched) || this.chargeChange;
    if (showSaveDialog && !this.nonEditableBillingStatus.includes(this.selectedOrder.billingStatus)) {
      const dialogRef = this.dialog.open(CancelDialogComponent, {
        width: '30%',
        maxWidth: '968px',
        data: {
          context: `Do you want to save the changes you made to this order?`,
          desc: `Your changes will be lost if you don\'t save them.`,
          button: [`Don't Save`, 'Cancel', 'Save'],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'close') {
          this.goBack();
        } else if (result === 'save') {
          if (!this.isLmoAccount && this.checkForSaveAndSendButton()) {
            this.saveOrderDetails();
          } else if (!this.isLmoAccount && this.checkForSaveButton()) {
            this.saveAndSendOrderDetails();
          }
        }
      });
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.router.navigate(['../' + this.selectedOrderStatus], { relativeTo: this.route });
    this.onMenuClose.emit(this.isChanged);
  }

  checkForApprove() {
    return !(
      this.selectedOrder &&
      this.selectedOrder.orderBillingHistory &&
      this.orderForm &&
      this.orderForm.controls['bolNumber'].value !== ''
    );
  }

  checkForLMOPushBack(order) {
    if (order) {
      if (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.notBilled) {
        if (
          order.orderStatusChangeHistory.oldValue === 'approved_by_dispatcher' &&
          order.orderStatusChangeHistory.newValue === 'not_billed'
        ) {
          return true;
        }
      } else if (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByLMO) {
        if (
          order.orderStatusChangeHistory.oldValue === 'billed' &&
          order.orderStatusChangeHistory.newValue === 'approved_by_lmo'
        ) {
          return true;
        }
      }
      return false;
    }
  }

  checkForDispatcherPushBack(order) {
    if (order) {
      if (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByDispatcher) {
        if (
          order.orderStatusChangeHistory.oldValue === 'approved_by_lmo' &&
          order.orderStatusChangeHistory.newValue === 'approved_by_dispatcher'
        ) {
          return true;
        }
      }
      return false;
    }
  }

  isAllChangesApproved() {
    return !(this.selectedOrder && !this.selectedOrder.orderBillingHistory);
  }

  saveOrderDetails() {
    this.savingData = true;
    const changedData = this.mapOrderData();
    const body = {
      newValues: changedData,
      charges: this.getIntSafeNewOrderCharges(),
    };
    this.orderApiService.updateOrderDetails(this.selectedOrderId, body).subscribe(
      (orderData) => {
        this.savingData = false;
        this.snackbar.open('Order updated Successfully', null, {
          duration: 5000,
        });
        this.updateOrderData(orderData);
      },
      (err) => {
        this.savingData = false;
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  isOtherThanPayloadOrManualChanged() {
    const newData = this.orderForm.value;
    const oldData = this.orderFormOld.value;
    const changedData = {};
    let isChanged = false;
    const dontChangeKeys = [
      'loader',
      'payload',
      'loadWeight',
      'bolNumber',
      'box1Name',
      'box1Weight',
      'box2Name',
      'box2Weight',
      'ticketNumber',
      'bulkStorageNumber',
      'invoiceNumber',
    ];
    for (const key in oldData) {
      if (oldData[key] !== newData[key] && !dontChangeKeys.includes(key)) {
        isChanged = true;
        break;
      }
    }

    return isChanged;
  }

  checkForPushback() {
    const changedData = this.mapOrderData();
    const body = {
      newValues: changedData,
      charges: this.getIntSafeNewOrderCharges(),
    };
    this.orderApiService.checkForPushback(this.selectedOrderId, body).subscribe((result) => {
      if (result) {
        const dialogRef = this.dialog.open(CancelDialogComponent, {
          width: '30%',
          maxWidth: '968px',
          data: {
            context: `Re-submit for Customer Approval?`,
            desc:
              'Editing this order will automatically re-submit it for your customer to approve. Are you sure you want to edit this order?',
            button: [`Don't Save`, 'Cancel', 'Save and Re-submit'],
          },
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.goBack();
          } else if (resp === 'save') {
            this.saveAndSendData();
          }
        });
      } else {
        this.saveOrderDetails();
      }
    });
  }

  saveAndSendOrderDetails() {
    if (
      (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByLMO) ||
      (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByDispatcher)
    ) {
      this.checkForPushback();
    } else {
      this.saveAndSendData();
    }
  }

  saveAndSendData() {
    let pushbackReason = '';
    if (this.selectedOrder.billingStatus === BILLING_STATUS.approvedByLMO) {
      const dialogRef = this.dialog.open(ReasonDialogComponent, {
        width: '30%',
        maxWidth: '968px',
        data: `${this.selectedOrder.billingStatus}-edit`,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.status) {
          pushbackReason = result.reason;
          this.saveDataAfterPushback(pushbackReason);
        }
      });
    } else {
      this.saveDataAfterPushback(pushbackReason);
    }
  }

  saveDataAfterPushback(pushbackReason) {
    this.savingData = true;
    const changedData = this.mapOrderData();
    const body = {
      newValues: changedData,
      charges: this.getIntSafeNewOrderCharges(),
    };
    if (pushbackReason) {
      changedData['pushbackReason'] = pushbackReason;
    }
    this.orderApiService.updateAndSendOrderDetails(this.selectedOrderId, body).subscribe(
      (orderData) => {
        this.savingData = false;
        this.snackbar.open('Order updated Successfully', null, {
          duration: 5000,
        });
        if (this.getNextOrderId()) {
          this.showSaveChanges = false;
          this.resetNewOrderCharges();
          this.navigateToNextOrder();
        } else {
          this.updateOrderData(orderData);
        }
      },
      (err) => {
        this.savingData = false;
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  private getNextOrderId() {
    const orderIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONST.BILLING_ORDER_IDS));
    const selectedOrderIndex = orderIds.findIndex((id) => id === this.selectedOrder.id);
    if (selectedOrderIndex > -1 && selectedOrderIndex < orderIds.length) {
      return orderIds[selectedOrderIndex + 1];
    } else {
      return false;
    }
  }

  mapOrderData() {
    const newData = this.orderForm.value;
    const oldData = this.orderFormOld.value;
    const changedData = {};
    for (const key in oldData) {
      if (oldData[key] !== newData[key]) {
        changedData[key] = newData[key];
        if (this.decimalFields.includes(key)) {
          changedData[key] = Math.round(changedData[key] * 100);
        } else if (this.dropdownFields.includes(key)) {
          changedData[key] = changedData[key].id;
        }
      }
    }
    this.isChanged = true;
    return changedData;
  }

  updateOrderData(orderData) {
    this.selectedOrder = orderData;
    this.sortBoxes();
    this.setUpdateHistory();
    this.showSaveChanges = false;
    this.chargeChange = false;
    this.resetNewOrderCharges();
    this.close();
  }

  sendOrderDetails() {
    this.isChanged = true;
    this.orderApiService.sendToLmoOrder(this.selectedOrderId).subscribe(
      (_) => {
        this.snackbar.open('Order Sent to LMO', null, {
          duration: 5000,
        });
        if (this.getNextOrderId()) {
          this.navigateToNextOrder();
        } else {
          this.close();
        }
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  approveTicketDetails() {
    this.isChanged = true;
    this.orderApiService.sendToInvoicing(this.selectedOrderId).subscribe(
      (_) => {
        this.snackbar.open('Order Sent To Invoicing Successfully', null, {
          duration: 5000,
        });
        if (this.getNextOrderId()) {
          this.navigateToNextOrder();
        } else {
          this.close();
        }
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  rejectTicketDetails() {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: this.selectedOrder.billingStatus,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        this.isChanged = true;
        const body = {
          pushbackReason: result.reason,
        };
        this.orderApiService.backToDispatcher(this.selectedOrderId, body).subscribe(
          (_) => {
            this.snackbar.open('Order moved back to dispatcher Successfully', null, {
              duration: 5000,
            });
            if (this.getNextOrderId()) {
              this.navigateToNextOrder();
            } else {
              this.close();
            }
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  checkForDetails() {
    return !(
      this.orderForm &&
      this.checkForLoadWeight() &&
      this.orderForm.controls['bolNumber'].value !== '' &&
      this.checkTripDetails()
    );
  }

  checkForLoadWeight() {
    if (this.selectedOrder.boxes) {
      if (this.selectedOrder.boxes.length > 1) {
        return (
          this.orderForm.controls['box1Weight'].value !== null &&
          this.orderForm.controls['box1Weight'].value > 0 &&
          this.orderForm.controls['box2Weight'].value !== null &&
          this.orderForm.controls['box2Weight'].value > 0
        );
      } else {
        return this.orderForm.controls['box1Weight'].value !== null && this.orderForm.controls['box1Weight'].value > 0;
      }
    } else {
      return this.orderForm.controls['loadWeight'].value !== null && this.orderForm.controls['loadWeight'].value > 0;
    }
  }

  checkForSaveButton() {
    return (
      (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByDispatcher) ||
      (this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByLMO)
    );
  }

  checkForRequiredField(fieldName) {
    return this.orderForm.controls[fieldName].value === '' || this.orderForm.controls[fieldName].value === null;
  }

  checkForSaveAndSendButton() {
    return this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.notBilled;
  }

  public checkIfReceivedByCustomer() {
    return this.selectedOrderStatus === 'invoiceApproved' && this.userService.isAPIUser();
  }

  public getRetreivedTooltip() {
    return `Sent to Customer: ${this.datePipe.transform(this.selectedOrder.retrievedByAPI, 'short')}`;
  }

  checkTripDetails() {
    return (
      this.orderForm.controls['driverId'].value &&
      this.orderForm.controls['driverId'].value !== null &&
      this.orderForm.controls['truckId'].value &&
      this.orderForm.controls['truckId'].value != null &&
      this.orderForm.controls['deadheadMileage'].value != null &&
      this.orderForm.controls['deadheadMileage'].value > -1 &&
      this.orderForm.controls['tripMileage'].value != null &&
      this.orderForm.controls['tripMileage'].value > 0 &&
      this.orderForm.controls['waitAtLoader'].value != null &&
      this.orderForm.controls['waitAtLoader'].value > -1 &&
      this.orderForm.controls['waitAtWell'].value != null &&
      this.orderForm.controls['waitAtWell'].value > -1
    );
  }

  checkForTicketActionButton() {
    return this.selectedOrder && this.selectedOrder.billingStatus === BILLING_STATUS.approvedByDispatcher;
  }

  saveContract() {
    this.updatingContract = true;
    this.orderApiService
      .switchVendorContracts(this.selectedOrderId, { vendorContract: this.formVendorContract })
      .subscribe(
        (res) => {
          this.updatingContract = false;
          this.snackbar.open('Contract Updated Successfully', null, {
            duration: 2500,
          });
          this.goBack();
        },
        (err) => {
          this.updatingContract = false;
          this.snackbar.open('Updating Contract Failed', null, {
            duration: 2500,
            panelClass: ['snackbar-error'],
          });
        },
      );
  }

  isContractChanged() {
    return (
      this.selectedOrder &&
      ((this.selectedOrder.vendorContract.id === this.formVendorContract.id && !this.formVendorContract.live) ||
        this.selectedOrder.vendorContract.id !== this.formVendorContract.id)
    );
  }

  private sendPresenceMsg() {
    const socketData: any = {
      type: 'presenceMessage',
      orderId: +this.selectedOrderId,
    };
    this.billingSocketService.sendMessage(JSON.stringify(socketData));
  }

  private listenToPresenceList() {
    this.activeUsers$$ = this.billingSocketService.presenceMap$.pipe(
      filter(Boolean),
      map((presenceMap) => presenceMap[this.selectedOrderId]),
      map((usersList) => (usersList ? usersList.filter((user) => user.userId !== this.userService.userId()) : [])),
    );
  }

  getNameAbbreviation(name: string) {
    return name
      .replace('+', ' ')
      .split(' ')
      .map((x) => x.charAt(0))
      .join('')
      .substr(0, 2)
      .toUpperCase();
  }

  private setPresenceTime() {
    this.sendPresenceMsg();
    this.socketTimer.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.sendPresenceMsg();
    });
  }

  unsubscribeOnDestroy() {
    this.socketUnsubscribed = true;
    const socketData: any = {
      type: 'closePresenceMessage',
      orderId: this.orderIdForSocket,
    };
    this.billingSocketService.sendMessage(JSON.stringify(socketData));
    this.billingSocketService.close();
  }

  ngOnDestroy() {
    if (!this.socketUnsubscribed) {
      this.unsubscribeOnDestroy();
    }
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  getOrderStatus() {
    switch (this.selectedOrder && this.selectedOrder.billingStatus) {
      case BILLING_STATUS.notBilled:
        this.selectedOrderStatus = 'needDataForTicketing';
        return 'Awaiting Loader Data';
      case BILLING_STATUS.approvedByDispatcher:
        this.selectedOrderStatus = 'waitingForTicketApproval';
        return 'Waiting For Ticket Approval';
      case BILLING_STATUS.approvedByLMO:
        this.selectedOrderStatus = 'readyForInvoicing';
        return 'Ready For Invoicing';
      case BILLING_STATUS.billed:
        this.selectedOrderStatus = 'waitingForInvoiceApproval';
        return 'Waiting For Invoice Approval';
      case BILLING_STATUS.approved:
        this.selectedOrderStatus = 'invoiceApproved';
        return 'Invoice Approved';
    }
  }

  smallFileName(name) {
    const parts = name.split('/');
    return parts[parts.length - 1];
  }

  public getFileIcon(fileName) {
    const fileNameArray = fileName.toLowerCase().split('.');
    let extension = fileNameArray[fileNameArray.length - 1];
    if (this.allowedFileTypes.includes(extension)) {
      if (extension === 'xlsx') {
        extension = 'xls';
      }
      if (extension === 'docx') {
        extension = 'doc';
      }
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      return `assets/icons/${extension}.svg`;
    } else {
      return `assets/icons/other.png`;
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  formatWeightAsTons(pounds: number): string {
    if (!isNaN(pounds)) {
      if (pounds >= 2000) {
        const tonsToTwoDecimalPlaces = Math.round((pounds / 2000 + Number.EPSILON) * 100) / 100;
        const displayTons =
          tonsToTwoDecimalPlaces % 1 === 0 ? tonsToTwoDecimalPlaces : tonsToTwoDecimalPlaces.toFixed(2);
        const suffix = tonsToTwoDecimalPlaces === 1 ? 'Ton' : 'Tons';
        return `${displayTons} ${suffix}`;
      }
    }
    return null;
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          console.log(`dropped file: ${file.name} with size: ${file.size} of type: ${file.type}`);
          this.fileToUpload = file;
          this.uploadFile();
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  clearTruck() {
    if (
      (this.selectedTruck &&
        this.selectedTruck.name !== this.orderForm.controls['truckId'].value &&
        this.selectedTruck.name !== this.orderForm.controls['truckId'].value.name) ||
      !this.selectedTruck
    ) {
      this.selectedTruck = this.selectedOrder.truck;
      this.orderForm.controls['truckId'].setValue(this.selectedOrder.truck);
    }
  }

  onTruckSelected(truck: Truck | string) {
    if (typeof truck === 'string') {
      this.searchApiService.createTruck(truck).subscribe((_) => {
        this.orderForm.controls['truckId'].patchValue(_);
        this.selectedTruck = _;
      });
    } else {
      this.selectedTruck = truck;
      this.orderForm.controls['truckId'].setValue(truck);
    }
  }

  checkForAddTruck$(): Observable<boolean> {
    if (!this.orderForm.controls['truckId'].value) {
      return of(false);
    }
    const truckName = this.orderForm.controls['truckId'].value.name;
    return this.truckService.truckList$.pipe(map((truckList) => truckList.every((truck) => truck.name !== truckName)));
  }

  public addCharge(charge?: Charge) {
    let existingAccessorialCharges = this.orderCharges.filter(
      (selectedCharge) =>
        !selectedCharge.chargeType.isDefaultForType ||
        this.LMOChargeType[selectedCharge.chargeType.type] === this.LMOChargeType.accessorial,
    );
    if (charge) {
      existingAccessorialCharges = existingAccessorialCharges.filter((c) => c.id !== charge.id);
    }
    const dialogRef = this.dialog.open(AddChargeDialogComponent, {
      data: {
        chargeTypes: this.chargeTypes,
        charge: charge,
        existingCharges: existingAccessorialCharges,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (charge) {
          // existing charge cannot have its type change
          const existingCharge: Charge = { ...charge };
          let changed = false;
          if (existingCharge.numberOfUnits !== result.quantity) {
            existingCharge.numberOfUnits = this.formatFloatNumbers(result.quantity);
            existingCharge.totalAmount = existingCharge.numberOfUnits * existingCharge.pricePerUnit;
            existingCharge.numberOfUnitsPriceChanged = true;
            changed = true;
          }
          if (existingCharge.pricePerUnit !== result.unitCost * 100) {
            existingCharge.pricePerUnit = result.unitCost * 100;
            existingCharge.totalAmount = existingCharge.numberOfUnits * existingCharge.pricePerUnit;
            existingCharge.pricePerUnitPriceChanged = true;
            changed = true;
          }
          if (existingCharge.unitOnCost !== result.quantityUnit) {
            existingCharge.unitOnCost = result.quantityUnit;
            existingCharge.unitOnQuantity = result.quantityUnit;
            changed = true;
          }
          if (changed) {
            const existIndex = this.newOrderCharges.findIndex((oldCharge) => oldCharge.id === charge.id);
            const existDefaultIndex = this.orderCharges.findIndex((oldCharge) => oldCharge.id === charge.id);
            if (existIndex > -1) {
              this.newOrderCharges.splice(existIndex, 1);
            }
            if (existDefaultIndex > -1) {
              this.orderCharges.splice(existDefaultIndex, 1);
            }
            this.orderCharges.push(existingCharge);
            this.newOrderCharges.push(existingCharge);
            this.chargeChange = true;
          }
        } else {
          const existingTypes = existingAccessorialCharges.map(
            (existingCharge: Charge) => existingCharge.chargeType.id,
          );
          if (existingTypes.includes(result.type.id)) {
            this.snackbar.open('An accessorial charge of that type has already been added to this order.', null, {
              duration: 5000,
              panelClass: ['snackbar-error'],
            });
            return;
          }
          const newCharge = {
            chargeTypeId: result.type.id,
            orderId: +this.selectedOrderId,
            numberOfUnits: this.formatFloatNumbers(result.quantity),
            unitOnQuantity: result.quantityUnit,
            unitOnCost: result.quantityUnit,
            chargeType: result.type,
            defaultShaleAppsCharge: '',
            id: -1,
            numberOfUnitsEdited: false,
            numberOfUnitsPriceChanged: false,
            pricePerUnit: result.unitCost * 100,
            pricePerUnitEdited: false,
            pricePerUnitPriceChanged: false,
            markDeleted: false,
            totalAmount: result.quantity * result.unitCost * 100,
          };
          this.orderCharges.push(newCharge);
          this.newOrderCharges.push(newCharge);
          this.chargeChange = true;
        }
      });
  }

  private formatFloatNumbers(value: number): number {
    return Number.isInteger(value) ? value : +value.toFixed(4);
  }

  public deleteCharge(charge: Charge, chargeIdx: number) {
    this.orderCharges.splice(chargeIdx, 1);
    const idx = this.newOrderCharges.findIndex((oldCharge) => oldCharge.id === charge.id);
    if (idx > -1) {
      if (this.newOrderCharges[idx].id === -1) {
        this.newOrderCharges.splice(idx, 1);
      } else {
        this.newOrderCharges[idx].markDeleted = true;
      }
    } else {
      charge.markDeleted = true;
      this.newOrderCharges.push(charge);
    }
    this.chargeChange = true;
  }

  public getTotalLoadCost() {
    let defaultCharges =
      this.deadheadCharge +
      this.loadingDetentionCharge +
      this.unloadingDetentionCharge +
      this.lineHaulCharge +
      (this.lineHaulCharge * this.boostPercent) / 100;
    const fuelSurcharge = this.orderCharges.find((charge) => {
      return charge.chargeType.type === 'fuel_surcharge';
    });
    if (fuelSurcharge) {
      defaultCharges += fuelSurcharge && fuelSurcharge.pricePerUnit * this.loadedMiles;
    }
    const nonDefaultCharges = this.orderCharges.reduce((total, charge) => {
      if (
        !charge.chargeType.isDefaultForType ||
        this.LMOChargeType[charge.chargeType.type] === this.LMOChargeType.accessorial
      ) {
        return total + charge.numberOfUnits * charge.pricePerUnit;
      } else {
        return total;
      }
    }, 0);
    return ((defaultCharges + nonDefaultCharges) / 100).toFixed(2);
  }

  public getLineTotalCost(charge: Charge): string {
    let lineTotal = 0;
    switch (this.LMOChargeType[charge.chargeType.type]) {
      case this.LMOChargeType.accessorial:
        lineTotal = charge.pricePerUnit * charge.numberOfUnits;
        break;
      case this.LMOChargeType.deadhead:
        lineTotal = this.deadheadCharge;
        break;
      case this.LMOChargeType.det_loading:
        lineTotal = this.loadingDetentionCharge;
        break;
      case this.LMOChargeType.det_unloading:
        lineTotal = this.unloadingDetentionCharge;
        break;
      case this.LMOChargeType.fuel_surcharge:
        lineTotal = this.loadedMiles * charge.pricePerUnit;
        break;
      case this.LMOChargeType.linehaul:
        lineTotal = this.lineHaulCharge;
        if (this.checkForBoost(charge)) {
          lineTotal += lineTotal * (this.boostPercent / 100);
        }
        break;
      case this.LMOChargeType.deduction:
        lineTotal = charge.totalAmount;
        break;
      default:
        lineTotal = 0;
    }
    return (lineTotal / 100).toFixed(2);
  }

  public getNumberOfUnit(charge: Charge): number | string {
    switch (this.LMOChargeType[charge.chargeType.type]) {
      case this.LMOChargeType.accessorial:
        return `${charge.numberOfUnits} ${charge.unitOnQuantity}`;
      case this.LMOChargeType.deadhead:
        return `${this.deadheadUnit < 0 ? 0 : this.deadheadUnit} ${charge.unitOnQuantity}`;
      case this.LMOChargeType.det_loading:
        return `${this.loadingDetentionUnit < 0 ? 0 : this.loadingDetentionUnit} ${charge.unitOnQuantity}`;
      case this.LMOChargeType.det_unloading:
        return `${this.unloadingDetentionUnit < 0 ? 0 : this.unloadingDetentionUnit} ${charge.unitOnQuantity}`;
      case this.LMOChargeType.fuel_surcharge:
        return `${this.loadedMiles} ${charge.unitOnQuantity}`;
      case this.LMOChargeType.linehaul:
        if (charge.unitOnQuantity === '%') {
          return `${charge.numberOfUnits} ${charge.unitOnQuantity}`;
        } else {
          return this.orderForm.get('vendorContract').value.costType === 'fixed'
            ? `${this.loadedMiles} ${charge.unitOnQuantity}`
            : `${this.loadedMiles} ${charge.unitOnQuantity} at ${
                !this.lineHaulUnit || this.lineHaulUnit < 0 ? 0 : this.lineHaulUnit
              } ${this.lineHaulUnit < 2 ? 'Ton' : 'Tons'}`;
        }
      default:
        return '--';
    }
  }

  public checkForBoost(charge: Charge) {
    return this.LMOChargeType[charge.chargeType.type] === this.LMOChargeType.linehaul && this.boostPercent > 0;
  }

  public getUnitCost(charge: Charge): number | string {
    let lineTotal = 0;
    switch (this.LMOChargeType[charge.chargeType.type]) {
      case this.LMOChargeType.accessorial:
        lineTotal = charge.pricePerUnit;
        break;
      case this.LMOChargeType.deadhead:
        lineTotal = this.deadheadUnitCost;
        break;
      case this.LMOChargeType.det_loading:
        lineTotal = this.hourlyDetentionCost;
        break;
      case this.LMOChargeType.det_unloading:
        lineTotal = this.hourlyDetentionCost;
        break;
      case this.LMOChargeType.fuel_surcharge:
        lineTotal = +(charge.pricePerUnit / 100).toFixed(3);
        break;
      case this.LMOChargeType.linehaul:
        lineTotal = +(this.lineHaulContractCost / 100).toFixed(2);
        break;
      default:
        lineTotal = 0;
    }
    return lineTotal;
  }

  private resetNewOrderCharges() {
    this.newOrderCharges = [];
  }

  navigateToNextOrder() {
    if (this.allFracView) {
      this.router.navigate(['../', this.selectedOrderStatus, this.selectedFracId, this.getNextOrderId()], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['../', this.selectedOrderStatus, this.getNextOrderId()], { relativeTo: this.route });
    }
  }
}
