<ng-container *ngIf="showScreen === 1">
  <div mat-dialog-content>
    <h3>Decline Orders</h3>
    <p>Are you sure you want to decline the selected orders?</p>
  </div>
  <div mat-dialog-actions class="row-div">
    <button mat-button color="primary" mat-dialog-close="true" (click)="cancelAction()" cdkFocusInitial>Cancel</button>
    <button mat-button color="warn" (click)="showScreen = 2">Confirm</button>
  </div>
</ng-container>

<ng-container *ngIf="showScreen === 2">
  <div mat-dialog-content>
    <h3>Decline Orders</h3>
    <p>Please select a reason for declining these orders</p>
  </div>
  <div mat-dialog-actions class="column-div">
    <button mat-button color="primary" *ngFor="let reason of reasons" (click)="continueAction(reason)">
      {{ reason }}
    </button>
    <button mat-button color="primary" (click)="showScreen = 3">Other</button>
    <button
      mat-button
      color="primary"
      class="cancel-btn"
      mat-dialog-close="true"
      (click)="cancelAction()"
      cdkFocusInitial
    >
      Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showScreen === 3">
  <div mat-dialog-content>
    <h3>Decline Order Reason</h3>
    <p>Please enter the reason for declining orders</p>
  </div>
  <div mat-dialog-actions class="row-div">
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="reasonInput" placeholder="Decline reason" />
    </mat-form-field>
    <button mat-button color="primary" mat-dialog-close="true" (click)="cancelAction()" cdkFocusInitial>Cancel</button>
    <button mat-button color="warn" [disabled]="!reasonInput" (click)="continueAction(reasonInput)">Submit</button>
  </div>
</ng-container>
