<i
  *ngIf="canCrudUsers$ | async"
  class="material-icons floating-plus"
  saAutomatedSelector="users__add-button"
  (click)="openUserDetailView({})"
  >add</i
>

<mat-table #table (matSortChange)="sortTable($event)" [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row" saAutomatedSelector="user__name">{{ row.name || 'No Name' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
    <mat-cell *matCellDef="let row" saAutomatedSelector="user__email">{{ row.email || 'No Email' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef>Permission</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.accountRoles[0].id == 1">Admin</span>
      <span *ngIf="row.accountRoles[0].id == 2">Standard</span>
      <span *ngIf="row.accountRoles[0].id == 5">Crew Leader</span>
      <span *ngIf="row.accountRoles[0].id == 4">View Only</span>
      <span *ngIf="row.accountRoles[0].id == 9">Sand Coordinator</span>
      <span *ngIf="row.accountRoles[0].id == 10">Dispatcher</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="accountName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.account.name || 'No Account' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row" saAutomatedSelector="user__edit"
      ><i class="material-icons" (click)="openUserDetailView(row)">edit</i></mat-cell
    >
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    saAutomatedSelector="user__row"
    [automatedName]="row.name"
  ></mat-row>
</mat-table>

<sa-detail-view [model]="detailView" (onUserUpdated)="userUpdated($event)" (onCloseClicked)="closeUserDetailView()">
</sa-detail-view>
