import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../models/user';
import { UserApiService } from '../services/api/user.api.service';
import { CrudService } from '../services/crud.service';
import { DetailView } from '../models/detail-view';
import { DetailViewModels } from '../constants/detail-view-models';
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';
import { UserService } from '../services/user.service';
import { switchMap, filter, take, map, shareReplay } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'sa-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PhoneNumberPipe],
})
export class UserSettingsComponent implements OnInit {
  displayedColumns = ['userName', 'email', 'phone', 'accountName'];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  public canCrudUsers$: Observable<boolean>;

  detailView: DetailView = {
    open: false,
    modelName: null,
    model: null,
  };

  constructor(
    private userApiService: UserApiService,
    private crudService: CrudService,
    private userService: UserService,
  ) {
    this.canCrudUsers$ = this.userApiService.canCreateUsers$.pipe(
      filter((result) => result?.initialized),
      map((res) => res.canCrud),
    );
  }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    const canCrud = await this.canCrudUsers$.pipe(take(1)).toPromise();
    if (canCrud) {
      if (!this.displayedColumns.includes('action')) {
        this.displayedColumns = [...this.displayedColumns, 'action'];
      }
    }
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
      if (ready) {
        this.userApiService
          .getWebUsers()
          .pipe(
            map((data) => {
              data.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              return data;
            }),
          )
          .subscribe(async (data) => {
            this.dataSource = new MatTableDataSource(data);
          });
      }
    });
  }

  sortTable(event) {
    switch (event.active) {
      case 'userName':
        const sortedName = this.dataSource.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        if (event.direction === 'desc') {
          sortedName.reverse();
        }
        this.dataSource.data = sortedName;
        break;
      case 'email':
        const sortedEmail = this.dataSource.data.sort((a, b) => {
          return a.email.localeCompare(b.email);
        });
        if (event.direction === 'desc') {
          sortedEmail.reverse();
        }
        this.dataSource.data = sortedEmail;
        break;
      case 'accountName':
        const sortedPhone = this.dataSource.data.sort((a, b) => {
          return a.account.name.localeCompare(b.account.name);
        });
        if (event.direction === 'desc') {
          sortedPhone.reverse();
        }
        this.dataSource.data = sortedPhone;
        break;
    }
  }

  openUserDetailView(user: User) {
    this.detailView.open = true;
    this.detailView.modelName = DetailViewModels.user;
    this.detailView.model = user;
  }

  closeUserDetailView() {
    this.detailView.open = false;
    this.detailView.modelName = null;
    this.detailView.model = null;
  }

  userUpdated(user: User) {
    this.loadData();

    if (!user) {
      this.closeUserDetailView();
    }
  }
}
