<div class="map-search-results-container" *ngIf="items">
  <li
    #resultsList
    class="row"
    *ngFor="let item of items; let i = index"
    [ngClass]="{ focused: i === focusedIndex }"
    (click)="selectItem(item)"
  >
    <div class="result" *ngIf="item.model === searchInputTypes.user">
      <i class="material-icons user-icon">face</i>
      <div class="user">
        <div class="item-name" [innerHtml]="item.name | highlight: searchText"></div>
        <div class="equipment-name">{{ item.equipmentType || 'No Equipment' }}</div>
      </div>
    </div>
    <div class="result" *ngIf="item.model === searchInputTypes.site">
      <i class="material-icons">location_on</i>
      <span class="item-name" [innerHtml]="item.name | highlight: searchText"></span>
    </div>

    <span class="account">{{ item.accountName || 'No Account' }}</span>
  </li>
  <div class="empty-results" *ngIf="items.length === 0">
    No Results
  </div>
</div>
