<div class="add-driver-component">
  <div class="toolbar">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <!-- <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">sort</i>
    </div> -->
    <div class="filter-wrap">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="filterStatus$$.next('available')">Available</button>
        <button mat-menu-item (click)="filterStatus$$.next('assigned')">Assigned</button>
        <button mat-menu-item (click)="filterStatus$$.next('all')">All</button>
      </mat-menu>

      <mat-menu #sortMenu="matMenu">
        <button mat-menu-item (click)="sortType$$.next('time')">Last Assignment Time</button>
        <button mat-menu-item (click)="sortType$$.next('miles')">Distance to Loader</button>
      </mat-menu>
      <ng-container *ngIf="userService.isShaleappsEmail()">
        <mat-checkbox (change)="is5f$$.next($event.checked)" class="fivef-checkbox">5F</mat-checkbox>
      </ng-container>
      <button mat-icon-button [matMenuTriggerFor]="sortMenu">
        <img src="/assets/icons/filter.svg" alt="filter" />
      </button>
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <img src="/assets/icons/filter-tool.svg" alt="filter" />
      </button>
    </div>
  </div>

  <div class="search-bar">
    <input
      type="text"
      placeholder="Search Driver by Name or Phone #"
      autofocus
      [formControl]="searchForm"
      #searchInput
      saAutomatedSelector="add_driver__driver_search"
    />
  </div>

  <ng-container *ngIf="driversToShow$ | async as driversToShow">
    <cdk-virtual-scroll-viewport [itemSize]="120" class="driver-list" *ngIf="driversToShow?.length">
      <ng-container *cdkVirtualFor="let driver of driversToShow; trackBy: trackById">
        <ng-container
          *ngIf="(doesNotExists$$ | async) === false && (tryFull$$ | async) === false && (loading$$ | async) === false"
        >
          <div
            class="driver-item"
            *ngIf="!driver.order"
            (mouseover)="hoverDriver(driver, true)"
            (click)="selectDriver(driver)"
          >
            <div class="driver-detail" saAutomatedSelector="add_driver__driver_detail">
              <div class="driver-name">
                {{ driver.name }}
                <ng-container class="subcontractor" *ngIf="userService.isShaleappsEmail() && driver.is5f">
                  <span> (5F)</span>
                </ng-container>
                <ng-container *saFeatureFlag="'hauliRunboardWarning'">
                  <span *ngIf="driver.isOnHauliRunboard" class="in-hauli-runboard">(In HAULi Runboard)</span>
                </ng-container>
              </div>
              <div class="subcontractor" *ngIf="userService.isShaleappsEmail() && driver.subcontractorName">
                {{ driver.subcontractorName }}
              </div>
              <div class="distance-wrap">
                {{ driver.distance }} mi. to
                {{ selectedOrder | orderPickupSiteName }}
              </div>
              <div class="driver-name boost" *ngIf="driver.eligibleForBoost && elligibleForBoost$ | async">
                ELIGIBLE FOR BOOST
              </div>
            </div>
            <div class="driver-status">
              <span class="red-text" *ngIf="driver.prettyStatus !== 'Online' && driver.prettyStatus !== 'Offline'"
                >{{ driver.prettyStatus }} {{ driver.prettyStatusTime }}</span
              >
              <span class="green-text" *ngIf="driver.prettyStatus == 'Online'">{{ driver.prettyStatus }}</span>
              <span class="gray-text" *ngIf="driver.prettyStatus == 'Offline'">{{ driver.prettyStatus }}</span>
            </div>
          </div>

          <div class="driver-item column-div" *ngIf="driver.order" (click)="selectDriver(driver)">
            <div class="driver-detail" saAutomatedSelector="add_driver__driver_detail">
              <div class="driver-name">
                {{ driver.order.vendor.name }}
              </div>
              <div class="driver-name">{{ driver.name }}, {{ driver.order.truck?.name }}</div>
            </div>
            <div class="sub-wrap">
              <div class="driver-detail">
                <div class="driver-name">
                  {{ driver.order | orderPickupSiteName }}
                </div>
                <div class="driver-name">
                  {{ driver.order.loadWeight / 1000 }}k lbs. of {{ driver.order.mesh.type }} Mesh
                </div>
              </div>
              <div class="driver-status" *ngIf="driver.order?.eta">
                <div>Est Pickup</div>
                <div>{{ driver.order.eta | until }}</div>
              </div>
              <div class="driver-status" *ngIf="!driver.order.eta">
                <div class="red-text" *ngIf="driver.userStatus.type === 'ActionNeeded'">Action Needed</div>
                <span class="green-text" *ngIf="driver.userStatus.type === 'Online'">ONLINE</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <div class="placeholder-text" *ngIf="loading$$ | async">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="placeholder-text p-2" *ngIf="doesNotExists$$ | async">
    <h3>Driver not Registered</h3>
    <h5>
      This driver has not registered for GoHawk. Please ask them to download GoHawk and register with their phone
      number.
    </h5>
    <a href="https://support.gohawk.us">How to Register for GoHawk</a>
  </div>
  <div class="placeholder-text p-2" *ngIf="tryFull$$ | async">
    <h3>Try Full Phone Number ?</h3>
    <h5>
      There are no results for this driver in your account. Try searching for the driver's full phone number to add them
      to your account.
    </h5>
  </div>
</div>

<ng-template #hauliRunboardWarning>
  <div class="hauli-runboard-warning-dialog">
    <h2 mat-dialog-title>Driver on HAULi Runboard</h2>
    <mat-dialog-content
      ><div class="hauli-runboard-content">
        <div>
          If you would like to assign this driver to this order, please
          <a href="https://hauli.shaleapps.com/" target="_blank"
            >open HAULi
            <i class="material-icons arrow-text">
              arrow_right_alt
            </i></a
          >
          and end their shift.
        </div>
      </div></mat-dialog-content
    >
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>OK</button>
    </mat-dialog-actions>
  </div>
</ng-template>
