import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '~services/auth.service';
import { StoreService } from '~services/store.service';
import { UserService } from '~services/user.service';
import { ConfigValuesService } from '~services/config-values.service';
import { map } from 'rxjs/operators';
import {
  TruckingRequestVendorService,
  PendingTruckingRequest,
} from 'src/app/marketplace/services/trucking-request-vendor.service';
import { IntercomService } from 'src/app/intercom.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-nav-dispatcher',
  templateUrl: './nav-dispatcher.component.html',
  styleUrls: ['./nav-dispatcher.component.scss'],
})
export class NavDispatcherComponent implements OnInit {
  public optedInToNewBillingPortal = true;
  public isAdmin = false;
  public isDefault = false;
  public isViewOnly = false;
  public isCrewLeader = false;
  public ssoLoginUrl$: Observable<string>;
  @Input() userLabel: { name: string; email: string; account: string };
  public emailHelpLink = 'mailto:help@shaleapps.com';
  public pendingTruckingRequestCount$: Observable<number>;
  public isRunning = false;

  constructor(
    private authService: AuthService,
    private storeService: StoreService,
    public userService: UserService,
    private configService: ConfigValuesService,
    private requestService: TruckingRequestVendorService,
    private intercom: IntercomService,
    private snackBar: MatSnackBar,
  ) {
    this.isAdmin = userService.isAdmin();
    this.isDefault = userService.isDefault();
    this.isViewOnly = userService.isViewOnly();
    this.isCrewLeader = userService.isCrewLeader();
    this.ssoLoginUrl$ = this.configService.configs$.pipe(map((configs) => configs.sso_login_url));
    const user = userService.getUserContractFromCache();
    if (user && user.account) {
      this.emailHelpLink = `mailto:help@shaleapps.com?body=Hi%20ShaleApps%20Help%20Team%2C%0D%0A%0D%0A%5BLet%20us%20know%20how%20we%20can%20help%20you%20here!%5D%0D%0A%0D%0AThanks%2C%0D%0A${user.name
        }%0D%0A%0D%0ADiagnostic%20Info%3A%0D%0AName%3A%20${user.name}%0D%0AEmail%3A%20${user.email}%0D%0AAccount%3A%20${user.account.name
        }%0D%0AURL%3A%20${location.href}%0D%0ABrowser%3A%20${navigator.userAgent}%0D%0A%0D%0A`;
    }
    this.pendingTruckingRequestCount$ = this.requestService.pendingRequests$.pipe(
      map((requests) => (requests ? requests.length : 0)),
    );
  }

  ngOnInit() { }

  goToGoHawkAndroid() {
    (<any>window).open('https://play.google.com/store/apps/details?id=com.shaleapps.agnus');
  }

  goToGoHawkIOS() {
    (<any>window).open('https://itunes.apple.com/US/app/id1296380634?mt=8');
  }

  goToSandi() {
    (<any>window).open('https://itunes.apple.com/US/app/id1360876904?mt=8');
  }

  showIntercom() {
    this.intercom.showNewMessage();
  }

  logout() {
    this.storeService.clear();
    this.authService.logout();
  }

  public ssoLogin() {
    this.authService.ssoURL(this.authService.user.email).subscribe(
      (url) => {
        window.open(url, '_self');
      },
      (error) => {
        this.snackBar.open(error.error.Message, null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }
}
