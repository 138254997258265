<h1 mat-dialog-title>Enter Invoice Number</h1>
<div mat-dialog-content>
  <mat-form-field class="full">
    <input
      matInput
      type="text"
      [(ngModel)]="invoiceNumber"
      placeholder="Invoice Number"
      saAutomatedSelector="invoice_modal__invoice_input"
    />
    <span matPrefix *ngIf="invoiceNumber.length > 0">SA</span>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!invoiceNumber"
    (click)="continueAction()"
    cdkFocusInitial
    saAutomatedSelector="invoice_modal__submit"
  >
    Submit
  </button>
  <button mat-button color="primary" (click)="cancelAction()">Cancel</button>
</div>
