<div class="dispatcher-frac-list-summary">
  <div class="group">
    <span class="value pending">{{ pendingCount$ | async }}</span>
    <span class="key">pending</span>
  </div>
  <div class="group">
    <span class="value up-for-grabs">{{ upForGrabsCount$ | async }}</span>
    <span class="key">up for grabs</span>
  </div>

  <div class="group">
    <span class="value action-needed">{{ actionNeededCount$ | async }}</span>
    <span class="key">action needed</span>
  </div>
</div>
