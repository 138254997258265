import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { WellApiService } from 'src/app/services/api/well.api.service';
import { filter } from 'bluebird';
import { IWell } from '../models/wells.model';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sa-cluster-choice-dialog',
  templateUrl: './cluster-choice-dialog.component.html',
  styleUrls: ['./cluster-choice-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClusterChoiceDialogComponent implements OnInit {
  localWells = [];
  clusterChoices = [];
  selectedWells: IWell[] = [];
  addToCluster = false;
  selectedCluster = '';
  uniqueClusters = [];
  clusterName = '';

  constructor(
    public dialogRef: MatDialogRef<ClusterChoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wellApiService: WellApiService,
    private featureFlag: FeatureFlagService,
  ) {
    this.selectedWells = data.selectedWells;
    this.addToCluster = data.addToCluster;
    this.localWells = data.clusters;
  }

  ngOnInit() {
    this.initializeClusters();
  }

  initializeClusters() {
    const mapOfSelectedClusterNames = this.selectedWells.reduce((selectedMap: Record<string, boolean>, well: IWell) => {
      return {
        ...selectedMap,
        [well.cluster.id]: true,
      };
    }, {});
    const filteredClusters = this.localWells.reduce((clusterMap, well) => {
      if (!mapOfSelectedClusterNames[well.cluster.id]) {
        return {
          ...clusterMap,
          [well.cluster.id]: well.cluster.name,
        };
      }
      return clusterMap;
    }, {});
    this.uniqueClusters = Object.keys(filteredClusters).map((key) => filteredClusters[key]);
  }

  makeCluster() {
    const requestBody = {
      name: this.clusterName,
      fracId: this.selectedWells[0].id,
    };

    this.wellApiService.createCluster(requestBody).subscribe(
      (resp) => {
        this.dialogRef.close('Success');
      },
      (err) => {
        this.dialogRef.close('Failed');
      },
    );
  }

  addToExistingCluster() {
    const fracIds = [];

    this.selectedWells.forEach((well) => {
      fracIds.push(well.id);
    });

    let clusterId = 0;
    this.localWells.map((cluster) => {
      if (this.selectedCluster === cluster.cluster.name) {
        clusterId = cluster.cluster.id;
      }
    });

    const requestBody = {
      fracIds: fracIds,
    };

    this.wellApiService.addToCluster(clusterId, requestBody).subscribe(
      (resp) => {
        this.dialogRef.close('Success');
      },
      (err) => {
        this.dialogRef.close(err);
      },
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
