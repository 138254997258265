import { Directive, Input, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserService, roles } from '~services/user.service';

const rolesLowercase = Object.keys(roles).reduce((record: Record<string, number>, name: string) => {
  record[name.toLowerCase()] = roles[name];
  return record;
}, {});

@Directive({
  selector: '[saAllowedAccountRoles]',
})
export class AllowedAccountTypesDirective implements OnDestroy {
  private allowedAccountTypes$$: BehaviorSubject<Record<number, boolean>> = new BehaviorSubject({});
  private hasView = false;
  private subscription: Subscription;

  @Input() set saAllowedAccountRoles(accountTypes: (number | string)[]) {
    const allowedAccountTypes: Record<number, boolean> = {};
    accountTypes.forEach((accountType) => {
      if (typeof accountType === 'string') {
        const roleId = rolesLowercase[accountType.toLowerCase()];
        if (roleId) {
          allowedAccountTypes[roleId] = true;
        }
      } else if (typeof accountType === 'number') {
        allowedAccountTypes[accountType] = true;
      }
    });
    this.allowedAccountTypes$$.next(allowedAccountTypes);
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
  ) {
    this.subscription = this.allowedAccountTypes$$.subscribe((record) => {
      const user = this.userService.getUserContractFromCache();
      const accountRoleId = user && user.account && user.account.role && user.account.role.id;
      if (!accountRoleId) {
        this.clear();
        return;
      }

      if (record[accountRoleId] === true) {
        this.show();
      } else {
        this.clear();
      }
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private clear() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private show() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
