import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Crew, CrewsService, GroupedCrew } from '../../crews.service';
import { UserService } from '~services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '~models/user';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { fuse } from '~utilities/fuse';

const searchOptions: Fuse.FuseOptions<User> = {
  distance: 100,
  keys: ['name', 'phone'],
  location: 0,
  maxPatternLength: 9,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  selector: 'sa-crews',
  templateUrl: './crews.component.html',
  styleUrls: ['./crews.component.scss'],
})
export class CrewsComponent implements OnInit {
  @ViewChild('addCrewModal', { static: true }) private addCrewModal: TemplateRef<any>;
  @ViewChild('updateCrewModal', { static: true }) private updateCrewModal: TemplateRef<any>;
  public crews$: Observable<GroupedCrew[]>;
  public isAdmin = false;
  public possibleLeaders$: Observable<User[]>;
  public leaderSearch = new FormControl(null);
  public addCrewForm = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.minLength(2)]),
    leader: new FormControl(null, [Validators.required]),
  });
  public editCrewForm = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required, Validators.minLength(2)]),
  });
  public addLeaderFormControl = new FormControl(null);
  public selectedCrew$$ = new BehaviorSubject<Crew>(null);

  constructor(
    private crewService: CrewsService,
    private userService: UserService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.crews$ = this.crewService.crews$.pipe(
      map((groupedCrews) => {
        return groupedCrews.filter((a) => a.accountId === this.userService.accountId());
      }),
    );

    this.possibleLeaders$ = combineLatest([
      this.leaderSearch.valueChanges.pipe(startWith(null as any), distinctUntilChanged()),
      this.crewService.possibleLeaders$.pipe(map(fuse(searchOptions))),
      this.selectedCrew$$,
    ]).pipe(
      map(([searchValue, users, crew]) => {
        const selectedLeaderIds = crew?.leaders?.map((leader) => leader.id);
        let filteredUsers: User[];
        if (!searchValue) {
          filteredUsers = users.data;
        } else {
          filteredUsers = users.fuse.search(searchValue) as any;
        }
        return filteredUsers.filter((user) => !selectedLeaderIds?.includes(user.id));
      }),
    );
    this.possibleLeaders$ = this.possibleLeaders$.pipe(map((users) => this.sortByName(users)));
    this.isAdmin = this.userService.isAdmin();
  }

  private sortByName = (users: User[]) => {
    return users.slice().sort((a, b) => a.name.localeCompare(b.name));
  };

  public startAddCrew() {
    this.matDialog.open(this.addCrewModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
    });
  }

  public doAddCrew() {
    if (this.addCrewForm.invalid) {
      return;
    }
    const value: { name: string; leader: number } = this.addCrewForm.value;
    this.crewService.createCrew$(value.name, [value.leader]).subscribe((crew) => {
      if (crew) {
        this.matDialog.closeAll();
      }
    });
  }

  public startEditCrew(crew: Crew) {
    this.selectedCrew$$.next(crew);
    this.editCrewForm.get('name').setValue(crew.name);
    this.editCrewForm.get('id').setValue(crew.id);
    this.matDialog
      .open(this.updateCrewModal, {
        panelClass: ['w-full', 'lg:w-1/3'],
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedCrew$$.next(null);
      });
  }

  public doEditCrew() {
    if (this.editCrewForm.invalid) {
      return;
    }
    const value: { id: number; name: string } = this.editCrewForm.value;
    this.crewService.updateCrew$(value.id, value.name).subscribe((crew) => {
      if (crew) {
        this.selectedCrew$$.next(crew);
        this.snackBar.open('Crew name updated successfully', null, {
          duration: 5000,
        });
      }
    });
  }

  public removeLeader(userId: number) {
    if (!this.selectedCrew$$.value) {
      return;
    }

    this.crewService.removeLeaders(this.editCrewForm.value.id, [userId]).subscribe((crew) => {
      if (crew) {
        this.selectedCrew$$.next(crew);
        this.snackBar.open('Removed leader successfully', null, {
          duration: 5000,
        });
      }
    });
  }

  public addLeader(user: User) {
    if (!this.selectedCrew$$.value || !user) {
      return;
    }
    this.crewService.addLeaders(this.editCrewForm.value.id, [user.id]).subscribe((crew) => {
      if (crew) {
        this.selectedCrew$$.next(crew);
        this.addLeaderFormControl.setValue(null);
        this.snackBar.open('Added leader successfully', null, {
          duration: 5000,
        });
      }
    });
  }
}
