import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

@Injectable()
export class CrudService {
  httpClientReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  headers: HttpHeaders;
  authedUser: firebase.User;
  userAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.afAuth.authState.subscribe((user) => {
      if (user) {
        this.authedUser = user;
        this.httpClientReady.next(true);
      }
      this.userAuthenticated.next(true);
    });
  }

  get(url, params?): Observable<any> {
    const route = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) => route.searchParams.append(key, params[key]));
    }

    if (!this.authedUser) {
      throw new Error(`this.authedUser is not defined when trying to get ${url}`);
    }

    return from(this.authedUser.getIdToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: token,
          }),
      ),
      switchMap((headers) => {
        return this.http.get(route.toString(), {
          headers: headers,
        });
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }

  put(url, body): Observable<any> {
    const route = new URL(url);

    return from(this.authedUser.getIdToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: token,
          }),
      ),
      switchMap((headers) => {
        return this.http.put(route.toString(), body, {
          headers: headers,
        });
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }

  patch(url, body): Observable<any> {
    const route = new URL(url);

    return from(this.authedUser.getIdToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: token,
          }),
      ),
      switchMap((headers) => {
        return this.http.patch(route.toString(), body, {
          headers: headers,
        });
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }

  post(url, body?): Observable<any> {
    const route = new URL(url);

    return from(this.authedUser.getIdToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: token,
          }),
      ),
      switchMap((headers) => {
        return this.http.post(route.toString(), body, {
          headers: headers,
        });
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }

  postWithoutAuth(url, body?): Observable<any> {
    const route = new URL(url);
    return this.http.post(route.toString(), body).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }

  delete(url): Observable<any> {
    const route = new URL(url);

    return from(this.authedUser.getIdToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: token,
          }),
      ),
      switchMap((headers) => {
        return this.http.delete(route.toString(), {
          headers: headers,
        });
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }
}
