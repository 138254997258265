import { Pipe, PipeTransform } from '@angular/core';
import { DistributionCenter } from '~lmo/models/distributionCenter.model';

@Pipe({
  name: 'distributionCenterName',
})
export class DistributionCenterNamePipe implements PipeTransform {
  transform(distributionCenter: DistributionCenter): string {
    return getDistributionCenterName(distributionCenter);
  }
}

export function getDistributionCenterName(distributionCenter: DistributionCenter): string {
  if (!distributionCenter) {
    return null;
  }
  return distributionCenter.site ? distributionCenter.site.name : null;
}
