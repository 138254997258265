import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { config } from '../config';
import { SaErrorStateMatcher } from '../services/form-validation.service';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { VersioningService } from '../services/versioning.service';
import { environment } from '~environments/environment';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('ssoEmail', { static: true }) private ssoEmailFormModal: TemplateRef<any>;
  private ssoEmailFormDialog: MatDialogRef<any>;
  ssoEmailForm = new FormControl();
  public running = false;

  displayForgotPassword: boolean;
  config = config;
  matcher: ErrorStateMatcher;
  loginForm: FormGroup;
  activeRequest$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    public versionService: VersioningService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.authService.afAuth.authState.subscribe((user) => {
      const userContract = this.userService.getUserContractFromCache();

      if (user && userContract) {
        this.authService.redirectIfLoggedIn();
      }
    });

    this.createForms();
    this.activeRequest$ = this.authService.isLoading$;
  }

  createForms() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.matcher = new SaErrorStateMatcher();
  }

  submitLogin() {
    // trying login changes for errors seen on edge based on - https://stackoverflow.com/questions/38860900/firebase-project-results-in-auth-network-request-failed-error-on-login
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value);
    }
  }

  isDev(): boolean {
    return environment.api === 'https://dev.sand.shaleapps.com/api';
  }

  isStage(): boolean {
    return environment.api === 'https://stage.sand.shaleapps.com/api';
  }

  isProd(): boolean {
    return environment.api === 'https://sand.shaleapps.com/api';
  }

  useDev(): void {
    this.useEnv('https://dev.sand.shaleapps.com/api', 'devimport.shaleapps.com');
  }

  useStage(): void {
    this.useEnv('https://stage.sand.shaleapps.com/api', 'stage.import.shaleapps.com');
  }

  useProd(): void {
    this.useEnv('https://sand.shaleapps.com/api', 'import.shaleapps.com');
  }

  private useEnv(api: string, email: string) {
    environment.api = api;
    environment.email = email;
    console.log('Using', environment);
  }

  public openSSOEmail() {
    const email = this.userService.getSSOEmailFromCache();
    this.ssoEmailForm.setValue(email);
    this.ssoEmailFormDialog = this.matDialog.open(this.ssoEmailFormModal, {
      maxWidth: '500px;',
    });
  }

  public ssoLogin() {
    this.running = true;
    this.authService.ssoURL(this.ssoEmailForm.value).subscribe(
      (url) => {
        this.running = false;
        this.cacheSSOEmail();
        window.open(url, '_self');
        this.closeTab();
      },
      (error) => {
        this.running = false;
        this.snackBar.open(error.error.Message, null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  public cacheSSOEmail() {
    this.userService.storeSSOEmailInCache({
      email: this.ssoEmailForm.value,
    });
  }

  public closeTab() {
    this.ssoEmailFormDialog.close();
    this.ssoEmailFormDialog = null;
  }
}
