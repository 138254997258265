import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { Order } from '~models/order.model';

@Injectable()
export class OrderApiService {
  get route(): string {
    return environment.api + '/order/load_orders';
  }

  get orderRoute(): string {
    return environment.api + '/order';
  }

  get ordersRoute(): string {
    return environment.api + '/orders';
  }

  constructor(private crud: CrudService) {}

  getOrders(body): Observable<any[]> {
    return this.crud.post(this.route, body);
  }

  getCompletedOrderDetails(id): Observable<any[]> {
    return this.crud.get(`${this.orderRoute}/${id}`);
  }

  downloadAllVendorData(body): Observable<any> {
    return this.crud.post(this.orderRoute + '/vendor_csvurl', body);
  }

  downloadAllLmoData(body): Observable<any> {
    return this.crud.post(this.orderRoute + '/lmo_csvurl', body);
  }

  getOrderById(orderId: number): Observable<Order> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.orderRoute}/${orderId}`)),
    );
  }

  getVendorContracts(orderId) {
    return this.crud.get(`${this.orderRoute}/${orderId}/contracts`);
  }

  switchVendorContracts(orderId, body) {
    return this.crud.put(`${this.orderRoute}/${orderId}/update_vendor_contract`, body);
  }

  getCompletedBillingOrderDetails(id): Observable<any> {
    return this.crud.get(this.orderRoute + '/' + id + '/billing_details');
  }

  dispatchOrder(body): Observable<any> {
    return this.crud.post(
      this.orderRoute +
        '/' +
        body.id +
        '/dispatch' +
        (body.isReassign ? '?isReassign=true&canDriverLoadComplete=' + body.canDriverLoadComplete : ''),
      body,
    );
  }

  declineOrder(orderId: number, declineReason: string): Observable<Order> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.post(`${environment.api}/v2/order/${orderId}/decline`, { declineReason })),
    );
  }

  declineBulkOrders(orderIds: number[], declineReason: string): Observable<Order> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.post(`${environment.api}/v2/orders/decline`, { orderIds, declineReason })),
    );
  }

  unAssignOrderById(orderId: number): Observable<any> {
    return this.crud.post(`${this.orderRoute}/${orderId}/unassign?fulfilled=false`);
  }

  markPreload(orderId: number, notes: string): Observable<any> {
    return this.crud.post(`${this.orderRoute}/internal/make_preload`, {
      orderId,
      notes,
    });
  }

  completeOrderById(orderId: number): Observable<any> {
    return this.crud.post(`${environment.api}/v2/order/${orderId}/complete`);
  }

  acceptForDriver(body): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + body.id + '/accept', {});
  }

  updateDetails(id, body): Observable<any> {
    return this.crud.patch(`${this.orderRoute}/${id}/details`, body);
  }

  updateOrderDetails(id, body): Observable<any> {
    return this.crud.put(this.orderRoute + '/' + id + '/updateOrder?sendToLMO=false', body);
  }

  updateAndSendOrderDetails(id, body): Observable<any> {
    if (!id || isNaN(id)) {
      return throwError(new Error(`orderId: ${id} not valid`));
    }
    return this.crud.put(this.orderRoute + '/' + id + '/updateOrder?sendToLMO=true', body);
  }

  checkForPushback(id, body): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + id + '/needsPushback', body);
  }

  sendToLmoOrder(id): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + id + '/send_to_lmo');
  }

  sendToInvoicing(id): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + id + '/send_to_invoicing');
  }

  getRecommendedDriver(id): Observable<any> {
    return this.crud.get(this.orderRoute + '/' + id + '/recommended_user');
  }

  rebillOrder(id): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + id + '/bill');
  }

  backToDispatcher(id, body): Observable<any> {
    return this.crud.post(this.orderRoute + '/' + id + '/back_to_dispatcher', body);
  }

  billOrders(body): Observable<any> {
    return this.crud.post(this.orderRoute + 's/bill', body);
  }

  approveOrders(body): Observable<any> {
    return this.crud.post(this.ordersRoute + '/send_to_invoicing', body);
  }

  approveInvoices(body): Observable<any> {
    return this.crud.post(this.ordersRoute + '/approve_invoice', body);
  }

  swapDrivers(id, body): Observable<any> {
    return this.crud.patch(this.orderRoute + '/' + id + '/swap_driver', body);
  }

  updateTruckForOrder(id, truckId): Observable<any> {
    return this.crud.patch(this.orderRoute + '/' + id + '/change_truck', { truckId: truckId });
  }

  patchOrder(id: number, payload: Partial<Order>): Observable<any> {
    return this.crud.patch(this.orderRoute + '/' + id, payload);
  }

  markBoxPickedUp(id: number): Observable<any> {
    return this.crud.post(this.orderRoute + '/mark_box_picked_up/' + id);
  }

  public getChargeTypesForOrder(orderId: number) {
    return this.crud.get(`${this.orderRoute}/${orderId}/charge_types`);
  }
}
