<sa-top-nav></sa-top-nav>

<div class="loader-analytics-component">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="filter" (change)="filterTypeChange()">
    <mat-radio-button value="all">All</mat-radio-button>
    <mat-radio-button value="matched">Matched Only</mat-radio-button>
    <mat-radio-button value="unmatched">Unmatched Only</mat-radio-button>
  </mat-radio-group>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="deliveredDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered Date</th>
        <td mat-cell *matCellDef="let record">
          {{ record.deliveredDate | inTimezone: fracTimezone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="poNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PO Number</th>
        <td mat-cell *matCellDef="let record">
          {{ record.poNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bolNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>BOL Number</th>
        <td mat-cell *matCellDef="let record">
          {{ record.bolNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fullWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Load Weight</th>
        <td mat-cell *matCellDef="let record">
          {{ record.fullWeight | poLoaderPoundTonConversion: false | poLoaderPoundTonUnit }}
        </td>
      </ng-container>

      <ng-container matColumnDef="matched">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matched to Loader Data?</th>
        <td mat-cell *matCellDef="let record">
          <i *ngIf="record.matched" class="material-icons">check</i>
        </td>
      </ng-container>

      <ng-container matColumnDef="sandiOrderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let record">
          <a [routerLink]="['/', 'lmo', 'frac', fracId, 'orders', record.sandiOrderId]">{{ record.sandiOrderId }}</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let load; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
