<div class="logistics-cluster-edit">
  <div class="rename">
    <mat-form-field>
      <input matInput placeholder="Cluster Name" [formControl]="nameControl" autocomplete="off" />
    </mat-form-field>
    <div class="rename-button">
      <button mat-stroked-button color="primary" (click)="renameCluster()">Rename</button>
    </div>
  </div>
  <div class="dragable">
    <div class="example-container">
      <h2>Non Clustered</h2>
      <button
        class="remove"
        mat-stroked-button
        color="warn"
        saAutomatedSelector="cluster_remove_button"
        (click)="promptClose()"
      >
        Remove Cluster
      </button>
      <div
        cdkDropList
        id="unclustered"
        #unclusteredList="cdkDropList"
        [cdkDropListData]="unclusteredFracs.siteSummaries"
        [cdkDropListConnectedTo]="[selectedClusterList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="example-box"
          *ngFor="let item of unclusteredFracs.siteSummaries"
          cdkDrag
          saAutomatedSelector="unclustered_well__row"
          [automatedName]="item.name"
        >
          {{ item.name }}
          <div class="semi-auto" *ngIf="isSemiAuto(item)" matTooltip="Auto Ordering" matTooltipPosition="left">
            <img class="autopilot-icon" src="/assets/icons/autopilot.svg" />
          </div>
          <div
            class="full-auto"
            *ngIf="isFullAuto(item)"
            matTooltip="Auto Ordering + Auto Dispatching"
            matTooltipPosition="left"
          >
            <img class="autopilot-icon" src="/assets/icons/lmo-full-auto.svg" />
          </div>
        </div>
      </div>
    </div>

    <div class="example-container">
      <h2>{{ nameControl.value }}</h2>

      <div
        cdkDropList
        #selectedClusterList="cdkDropList"
        id="selectedCluster"
        [cdkDropListData]="selectedCluster.siteSummaries"
        [cdkDropListConnectedTo]="[unclusteredList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
        saAutomatedSelector="unclustered_well_drop"
      >
        <div class="example-box" *ngFor="let item of selectedCluster.siteSummaries" cdkDrag>
          {{ item.name }}
          <div class="semi-auto" *ngIf="isSemiAuto(item)" matTooltip="Auto Ordering" matTooltipPosition="left">
            <img class="autopilot-icon" src="/assets/icons/autopilot.svg" />
          </div>
          <div
            class="full-auto"
            *ngIf="isFullAuto(item)"
            matTooltip="Auto Ordering + Auto Dispatching"
            matTooltipPosition="left"
          >
            <img class="autopilot-icon" src="/assets/icons/lmo-full-auto.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading$$ | async">
  <mat-spinner></mat-spinner>
</div>

<ng-template #cancelDialog>
  <h1 mat-dialog-title>Remove Cluster</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to remove {{ selectedCluster.name }}</p>
  </div>
  <div mat-dialog-actions class="action-row">
    <button mat-button color="primary" (click)="closeConfirmDialog()">Cancel</button>
    <button mat-button color="warn" saAutomatedSelector="cluster_remove_confirm_button" (click)="removeCluster()">
      Remove Cluster
    </button>
  </div>
</ng-template>
