import { AutopilotLevel } from '~v2Models/frac.model';

export type RunboardSiteSummaryType = 'frac' | 'dc';

export interface RunboardSiteSummary {
  siteType: RunboardSiteSummaryType;
  actionNeededCount: number;
  autopilotLevel: AutopilotLevel;
  id: number;
  inProgressLoadCount: number;
  name: string;
  pendingLoadCount: number;
  upForGrabsLoadCount: number;
  up: boolean;
}

export interface RunboardLogisticsCluster {
  isCluster: true;
  siteSummaries: RunboardSiteSummary[];
  id: number;
  name: string;
}

export interface UnclusteredRunboardSites {
  isCluster: false;
  siteSummaries: RunboardSiteSummary[];
}

export function isLogisticsCluster(
  c: RunboardLogisticsCluster | UnclusteredRunboardSites,
): c is RunboardLogisticsCluster {
  return c.isCluster;
}
