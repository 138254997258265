import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { actionNeededPluralMapping } from 'src/app/pluralMappings';
import { RunboardLogisticsCluster, RunboardSiteSummary, UnclusteredRunboardSites } from '~dispatcher/models/frac.model';
import { DispatcherLogisticsClusterAddComponent } from '../dispatcher-logistics-cluster-add/dispatcher-logistics-cluster-add.component';
import { DispatcherLogisticsClusterEditComponent } from '../dispatcher-logistics-cluster-edit/dispatcher-logistics-cluster-edit.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-dispatcher-logistics-cluster-summary',
  styleUrls: ['./dispatcher-logistics-cluster-summary.component.scss'],
  templateUrl: './dispatcher-logistics-cluster-summary.component.html',
})
export class DispatcherLogisticsClusterSummaryComponent implements OnInit {
  public actionNeededPluralMapping = actionNeededPluralMapping;
  @Input() public logCluster: RunboardLogisticsCluster | UnclusteredRunboardSites;

  constructor(private matDialog: MatDialog) {}

  public ngOnInit() {}

  public asCluster(cluster: RunboardLogisticsCluster | UnclusteredRunboardSites): RunboardLogisticsCluster {
    return cluster.isCluster ? cluster : null;
  }

  public showInProgress(summary: RunboardSiteSummary): boolean {
    return summary.actionNeededCount === 0 && summary.upForGrabsLoadCount === 0 && summary.inProgressLoadCount !== 0;
  }

  public showUpForGrabsAndActionNeeded(summary: RunboardSiteSummary): boolean {
    return summary.actionNeededCount !== 0 && summary.upForGrabsLoadCount !== 0;
  }

  public showActionNeeded(summary: RunboardSiteSummary): boolean {
    return summary.actionNeededCount !== 0;
  }

  public showUpForGrabs(summary: RunboardSiteSummary): boolean {
    return summary.upForGrabsLoadCount !== 0;
  }

  public editCluster(id: number) {
    const clusterEditor = this.matDialog.open(DispatcherLogisticsClusterEditComponent);
    clusterEditor.componentInstance.clusterId = id;
  }

  public newCluster() {
    this.matDialog.open(DispatcherLogisticsClusterAddComponent);
  }

  public isFullAuto(site: RunboardSiteSummary) {
    return site.autopilotLevel === 'full';
  }

  public trackById(site: RunboardSiteSummary) {
    return site.siteType + site.id;
  }
}
