<div class="storage-form-container">
  <h2 mat-dialog-title>
    {{ data?.storage ? 'Edit Distribution Center Storage' : 'Create New Distribution Center Storage' }}
  </h2>

  <form *ngIf="storageForm" [formGroup]="storageForm" (submit)="submit()" autocomplete="off">
    <div>
      <mat-form-field>
        <input
          matInput
          placeholder="Storage Unit Identifier"
          [formControl]="storageForm.controls['name']"
          saAutomatedSelector="well-creation-new-storage__storage-unit-identifier"
        />
        <mat-error *ngIf="storageForm.controls['name'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="Storage Type"
          [formControl]="storageForm.controls['storageType']"
          [compareWith]="compareFn"
          saAutomatedSelector="well-creation-new-storage__storage-type"
        >
          <mat-option *ngFor="let storageType of storageTypes" [value]="storageType">
            {{ storageType.name }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="storageForm.controls['storageType'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="storageForm.controls['storageType'].value?.id === 2">
        <mat-select
          placeholder="Box Technology"
          [formControl]="storageForm.controls['boxTechnology']"
          [compareWith]="compareFn"
        >
          <mat-option *ngFor="let boxType of boxTechnologies$ | async" [value]="boxType">
            {{ boxType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isHalAccount">
      <mat-form-field>
        <mat-select
          placeholder="Material"
          [formControl]="storageForm.controls['material']"
          [compareWith]="materialCompareFn"
          saAutomatedSelector="well-creation-new-storage__material-type"
        >
          <mat-optgroup *ngFor="let group of materialNumbers" [label]="'Mesh ' + group[0]">
            <mat-option *ngFor="let materialNumber of group[1]" [value]="materialNumber.materialNumber">{{
              materialNumber.materialNumber
            }}</mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error *ngIf="storageForm.controls['material'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="Sales Order Line Item"
          [formControl]="storageForm.get('salesOrderLineItem')"
          saAutomatedSelector="well-creation-new-storage__salesOrderLineItem"
        />
        <mat-error *ngIf="storageForm.controls['salesOrderLineItem'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-select
          placeholder="Mesh Type"
          [formControl]="storageForm.controls['mesh']"
          [compareWith]="compareFn"
          saAutomatedSelector="well-creation-new-storage__mesh-type"
        >
          <mat-option *ngFor="let mesh of meshTypes" [value]="mesh">{{ mesh.type }} Mesh</mat-option>
        </mat-select>
        <mat-error *ngIf="storageForm.controls['mesh'].hasError('required')">
          Input is required
        </mat-error>
        <!-- <mat-hint *ngIf="storageForm.get('mesh').disabled">
          Please create a new storage if you would like to use a different mesh.
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field>
        <div *ngIf="isBox(); then roomForMeshBoxes; else meshCapacity"></div>
        <ng-template #roomForMeshBoxes>
          <input
            matInput
            placeholder="Room for {{ meshName }} Mesh boxes"
            sa-number-input
            (input)="onMeshCapacityChange()"
            type="text"
            [formControl]="storageForm.controls['capacity']"
            saAutomatedSelector="well-creation-new-storage__mesh-capacity"
          />
        </ng-template>
        <ng-template #meshCapacity>
          <input
            matInput
            placeholder="Mesh Capacity (lbs)"
            sa-number-input
            (input)="onMeshCapacityChange()"
            type="text"
            [formControl]="storageForm.controls['capacity']"
            saAutomatedSelector="well-creation-new-storage__mesh-capacity"
          />
        </ng-template>
        <mat-error *ngIf="storageForm.controls['capacity'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error
          *ngIf="
            storageForm.controls['capacity'].hasError('min') && !storageForm.controls['capacity'].hasError('required')
          "
        >
          Value cannot be negative
        </mat-error>
        <mat-error
          *ngIf="
            storageForm.controls['capacity'].hasError('max') && !storageForm.controls['capacity'].hasError('required')
          "
        >
          Value cannot be more than 100,000,000
        </mat-error>
        <!-- <mat-hint *ngIf="isBox()">
          How many {{ meshName }} Mesh Boxes do you have room to unload on location?
        </mat-hint> -->
      </mat-form-field>
    </div>

    <div>
      <mat-form-field *ngIf="isBox()">
        <mat-select placeholder="Boxes Per Load" formControlName="numberBoxes">
          <mat-option *ngFor="let boxSize of boxSizes" [value]="boxSize"> {{ boxSize }} Box </mat-option>
        </mat-select>

        <mat-error *ngIf="storageForm.controls['storageType'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          type="text"
          sa-number-input
          autocomplete="off"
          placeholder="Load Weight (lbs)"
          [formControl]="storageForm.get('loadWeight')"
          saAutomatedSelector="well-creation-new-storage__load-weight"
        />
        <mat-error *ngIf="storageForm.get('loadWeight').hasError('required')">
          Input is required
        </mat-error>
        <mat-error *ngIf="storageForm.get('loadWeight').hasError('min')">
          Value must be greater than 0
        </mat-error>
        <mat-error *ngIf="storageForm.get('loadWeight').hasError('max')">
          Value must be less than or equal to {{ maxWeight | number }}
        </mat-error>
      </mat-form-field>
    </div>

    <div matDialogActions fxLayout="row" fxLayoutAlign="flex-end" class="actions">
      <button
        mat-flat-button
        color="primary"
        mat-dialog-close
        color="accent"
        saAutomatedSelector="well-creation-new-storage__cancel-button"
      >
        Cancel
      </button>
      <button
        *ngIf="canEdit"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="submitting"
        saAutomatedSelector="well-creation-new-storage__submit-button"
      >
        {{ submitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </form>
</div>
