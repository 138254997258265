import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  EmailDetails,
  EmailSetting,
  Data,
  LoaderIntegrationStatsForDate,
  LoadsForEmailOnDate,
  EmailData,
  LoaderIntegrationLoad,
  LoaderIntegrationDataForLoad,
  PotentialMatch,
  Mine,
  PurchaseOrderData,
} from '~models/index';
import { filter, share, switchMap, take, map, tap } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { environment } from '~environments/environment';
import { MineService } from './mine.service';
import { RouterStateService } from '~services/router-state.service';
import {
  LOADER_INTEGRATION_DATE,
  LOADER_INTEGRATION_LOADER_DATA_ID,
  LOADER_INTEGRATION_MINE_ID,
  LOADER_INTEGRATION_PURCHASE_ORDER_ID,
} from '../app-routing.constants';
import { HttpClient } from '@angular/common/http';

interface EmailSettingsBody {
  loaderId: string;
  email: string;
}

interface AnalyticsDataParameters {
  startDate: Date;
  endDate: Date;
  mineId: number;
}

interface GoodsReceipt {
  orderId: number;
  goodsReceipt: string;
}

interface GoodsIssued {
  orderId: number;
  goodsIssued: string;
}

export interface MineForFilter {
  id: number;
  name: string;
}

export interface PLONumberForExportSelection {
  id: number;
  ploNumber: string;
  fracName: string;
}

export interface LoaderIntegrationExportParameters {
  startDate?: Date;
  endDate?: Date;
  mineId?: number;
  ploExport: boolean;
  fracId?: number;
  purchaseOrderId?: number;
}

export interface LoaderNameMapping {
  id: number;
  nameInFile: string;
  loaderName: string;
  loaderId: number;
}

export interface LoaderNameMappingUpdate {
  nameInFile: string;
  loaderId: number;
}

@Injectable({
  providedIn: 'root',
})
export class EmailSettingsV2Service {
  get route(): string {
    return environment.api + '/email_parser';
  }
  private emailSettings$$ = new BehaviorSubject<Data<EmailSetting[]>>({
    meta: {
      loaded: false,
      loading: false,
    },
    data: [],
  });

  private emailData$$ = new BehaviorSubject<EmailData>(null);
  public emailData$ = this.emailData$$.asObservable();

  private purchaseOrderData$$ = new BehaviorSubject<PurchaseOrderData>(null);
  public purchaseOrderData$ = this.purchaseOrderData$$.asObservable();

  private loadsForDate$$ = new BehaviorSubject<LoaderIntegrationLoad[]>(null);
  public loadsForDate$ = this.loadsForDate$$.asObservable();

  private loadsForPurchaseOrder$$ = new BehaviorSubject<LoaderIntegrationLoad[]>(null);
  public loadsForPurchaseOrder$ = this.loadsForPurchaseOrder$$.asObservable();

  private loadDataForMatches$$ = new BehaviorSubject<LoaderIntegrationDataForLoad>(null);
  public loadDataForMatches$ = this.loadDataForMatches$$.asObservable();

  private potentialMatchesForLoad$$ = new BehaviorSubject<PotentialMatch[]>(null);
  public potentialMatchesForLoad$ = this.potentialMatchesForLoad$$.asObservable();

  private loaderAnalyticsData$$ = new BehaviorSubject<LoaderIntegrationStatsForDate[]>(null);
  public loaderAnalyticsData$ = this.loaderAnalyticsData$$.asObservable();

  private analyticsDataParameters$$ = new BehaviorSubject<AnalyticsDataParameters>(null);

  private minesForFilter$$ = new BehaviorSubject<MineForFilter[]>([]);
  public minesForFilter$ = this.minesForFilter$$.asObservable();

  private ploNumbers$$ = new BehaviorSubject<PLONumberForExportSelection[]>([]);
  public ploNumbers$ = this.ploNumbers$$.asObservable();

  private loaderNameMappings$$ = new BehaviorSubject<LoaderNameMapping[]>([]);
  public loaderNameMappings$ = this.loaderNameMappings$$.asObservable();

  public get emailSettings$(): Observable<Data<EmailSetting[]>> {
    return this.emailSettings$$.asObservable().pipe(
      filter((settings) => !!settings),
      share(),
    );
  }

  constructor(
    private crud: CrudService,
    private mineService: MineService,
    private routerState: RouterStateService,
    private http: HttpClient,
  ) {
    this.loadingComplete = this.loadingComplete.bind(this);
    this.loadData();
    this.loadLoaderNameMappings();

    combineLatest([
      this.routerState.listenForParamChange$(LOADER_INTEGRATION_MINE_ID),
      this.routerState.listenForParamChange$(LOADER_INTEGRATION_DATE),
    ]).subscribe(([settingsID, date]) => {
      if (settingsID && date) {
        this.emailData$$.next(null);
        this.getLoadsOnDate(date, parseInt(settingsID, 10));
      } else {
        this.loadsForDate$$.next(null);
        this.emailData$$.next(null);
      }
    });

    this.routerState.listenForParamChange$(LOADER_INTEGRATION_LOADER_DATA_ID).subscribe((loaderDataID) => {
      if (loaderDataID) {
        this.loadDataForMatches$$.next(null);
        this.getPotentialMatchesForLoad(parseInt(loaderDataID, 10));
      } else {
        this.potentialMatchesForLoad$$.next(null);
        this.loadDataForMatches$$.next(null);
      }
    });

    this.routerState.listenForParamChange$(LOADER_INTEGRATION_PURCHASE_ORDER_ID).subscribe((purchaseOrderID) => {
      if (purchaseOrderID) {
        this.purchaseOrderData$$.next(null);
        this.getLoadsForPurchaseOrder(parseInt(purchaseOrderID, 10));
      } else {
        this.loadsForPurchaseOrder$$.next(null);
        this.purchaseOrderData$$.next(null);
      }
    });

    this.analyticsDataParameters$$.subscribe(async (parameters) => {
      if (parameters && parameters.startDate && parameters.endDate) {
        const analytics = await this.getEmailStatisticsV2(
          parameters.startDate,
          parameters.endDate,
          parameters.mineId,
        ).toPromise();
        this.loaderAnalyticsData$$.next(analytics);
      }
    });
  }

  public loadData() {
    this.startEmailSummariesLoading();
  }

  private loadLoaderNameMappings() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(`${this.route}/v2/loader_name_mapping`)),
      )
      .subscribe((loaderNameMappings) => {
        this.loaderNameMappings$$.next(loaderNameMappings);
      });
  }

  public updateLoaderNameMapping(id: number, body: LoaderNameMappingUpdate): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(`${this.route}/v2/loader_name_mapping/${id}`, body)),
      tap(() => this.loadLoaderNameMappings()),
    );
  }

  public deleteLoaderNameMapping(id: number): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.delete(`${this.route}/v2/loader_name_mapping/${id}`)),
      tap(() => this.loadLoaderNameMappings()),
    );
  }

  public createLoaderNameMapping(body: LoaderNameMappingUpdate): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(`${this.route}/v2/loader_name_mapping`, body)),
      tap(() => this.loadLoaderNameMappings()),
    );
  }

  public isNewLoaderIntegration(): Observable<boolean> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(`${environment.api}/is_new_loader_integration `)),
    );
  }

  public getEmailsDetailsById(id: number): Observable<EmailDetails[]> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(`${this.route}/${id}`)),
    );
  }

  public saveEmail(body: EmailSettingsBody): Observable<any> {
    this.loadingOn();
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(this.route + '/v2/create_new_loader_integration', body)),
      tap(() => this.loadData()),
    );
  }

  public updateEmail(id: number, body: EmailSettingsBody): Observable<any> {
    this.loadingOn();
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.put(`${this.route}/${id}`, body)),
      tap(() => this.loadData()),
    );
  }

  public deleteEmailSettings(id: number): void {
    this.loadingOn();
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.delete(`${this.route}/${id}`)),
      )
      .subscribe(() => {
        this.loadData();
      });
  }

  public downloadFile(fileName: string): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(this.route + '/link', { fileName })),
    );
  }

  private loadingOn() {
    const current = this.emailSettings$$.value;
    this.emailSettings$$.next({
      ...current,
      meta: {
        ...current.meta,
        loading: true,
      },
    });
  }

  private loadingComplete(data: EmailSetting[], loaded = true) {
    const current = this.emailSettings$$.value;
    this.emailSettings$$.next({
      ...current,
      data,
      meta: {
        ...current.meta,
        loading: false,
        loaded: true,
      },
    });
  }

  private startEmailSummariesLoading() {
    this.loadingOn();
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(this.route + '/v2/list_loader_integration') as Observable<EmailSetting[]>),
      )
      .subscribe(this.loadingComplete, (error) => {
        this.loadingComplete([], false);
      });
  }

  public setEmailStatisticsDateRange(startDate: Date, endDate: Date, mineId: number) {
    this.analyticsDataParameters$$.next({ startDate, endDate, mineId });
  }

  public getEmailStatisticsDateRange(): AnalyticsDataParameters {
    return this.analyticsDataParameters$$.value;
  }

  public getEmailStatisticsV2(
    startTime: Date,
    endTime: Date,
    mineId: number,
  ): Observable<LoaderIntegrationStatsForDate[]> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() =>
        this.crud.post(`${this.route}/v2/statistics_by_date`, {
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          mineId,
        }),
      ),
    );
  }

  private async getLoadsOnDate(date: string, mineId: number) {
    const value = await this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.crud.post(`${this.route}/v2/loads_on_date`, {
            date,
            mineId,
          }),
        ),
      )
      .toPromise();

    this.loadsForDate$$.next(value.loads);
    this.emailData$$.next(value.emailData);
  }

  private async getLoadsForPurchaseOrder(purchaseOrderId: number) {
    const value = await this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.crud.post(`${this.route}/v2/loads_for_purchase_order`, {
            purchaseOrderId,
          }),
        ),
      )
      .toPromise();

    this.loadsForPurchaseOrder$$.next(value.loads);
    this.purchaseOrderData$$.next(value.purchaseOrderData);
  }

  private async getPotentialMatchesForLoad(loaderIntegrationDataId: number) {
    const value = await this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(`${this.route}/v2/potential_matches/${loaderIntegrationDataId}`)),
      )
      .toPromise();

    this.loadDataForMatches$$.next(value.loaderIntegrationData);
    this.potentialMatchesForLoad$$.next(value.matches);
  }

  public doManualFileUpload(file: File, loaderId?: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mineId', loaderId?.toString() || '');

    return this.http.post(`${this.route}/v2/manual_file_upload`, formData);
  }

  public doManualGRGIFileUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.route}/v2/manual_gr_gi_file_upload`, formData);
  }

  public setLoaderIntegrationDataMatch(potentialMatchId: number, orderId: number, loaderIntegrationDataId: number) {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() =>
        this.crud.post(`${this.route}/v2/set_loader_integration_data_match`, {
          potentialMatchId,
          orderId,
          loaderIntegrationDataId,
        }),
      ),
    );
  }

  public unsetLoaderIntegrationDataMatch(potentialMatchId: number, orderId: number, loaderIntegrationDataId: number) {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() =>
        this.crud.post(`${this.route}/v2/unset_loader_integration_data_match`, {
          potentialMatchId,
          orderId,
          loaderIntegrationDataId,
        }),
      ),
    );
  }

  public refreshPotentialMatchesForLoad() {
    if (this.loadDataForMatches$$.value) {
      this.getPotentialMatchesForLoad(this.loadDataForMatches$$.value.integrationDataId);
    }
  }

  public loadMinesForFilter() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(`${this.route}/v2/loads_on_date/mines_for_filter`)),
      )
      .subscribe((mines) => {
        if (mines) {
          this.minesForFilter$$.next(mines);
        } else {
          this.minesForFilter$$.next([]);
        }
      });
  }

  public loadPLONumbersForExportSelection() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(`${this.route}/v2/export/plo_numbers`)),
      )
      .subscribe((ploNumbers) => {
        if (ploNumbers) {
          this.ploNumbers$$.next(ploNumbers);
        } else {
          this.ploNumbers$$.next([]);
        }
      });
  }

  public async updateGoodsReceipt(load: LoaderIntegrationLoad, purchaseOrderId: number) {
    const body = {
      orderId: load.orderId,
      goodsReceipt: load.goodsReceipt,
      integrationDataId: load.integrationDataId,
      purchaseOrderId: purchaseOrderId?.toString(),
      loadWeight: load.loadWeight,
      bolNumber: load.bolNumber,
    };
    return this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.put(`${this.route}/v2/loads_on_date/goods_receipt`, body)),
      )
      .toPromise();
  }

  public async updateGoodsIssued(load: LoaderIntegrationLoad, purchaseOrderId: number) {
    const body = {
      orderId: load.orderId,
      goodsIssued: load.goodsIssued,
      integrationDataId: load.integrationDataId,
      purchaseOrderId: purchaseOrderId?.toString(),
      loadWeight: load.loadWeight,
      bolNumber: load.bolNumber,
    };
    return this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.put(`${this.route}/v2/loads_on_date/goods_issued`, body)),
      )
      .toPromise();
  }

  public async exportLoaderIntegrationAnalytics(parameters: LoaderIntegrationExportParameters): Promise<string> {
    return this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.crud.post(`${this.route}/v2/export`, {
            startDate: parameters.startDate?.toISOString(),
            endDate: parameters.endDate?.toISOString(),
            mineId: parameters.mineId,
            ploExport: parameters.ploExport,
            fracId: parameters.fracId,
            purchaseOrderId: parameters.purchaseOrderId,
          }),
        ),
      )
      .pipe(
        map((response) => {
          return response.url;
        }),
      )
      .toPromise();
  }
}
