import { Component, OnInit } from '@angular/core';
import { CompletedOrdersService } from 'src/app/completed-orders.service';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { OrderSummary } from '~models/orderSummary.model';

interface HeaderItem {
  id: string;
  name: string;
  isHeaderItem: true;
}

@Component({
  selector: 'sa-frac-detail-completed-orders',
  templateUrl: './frac-detail-completed-orders.component.html',
  styleUrls: ['./frac-detail-completed-orders.component.scss'],
})
export class FracDetailCompletedOrdersComponent implements OnInit {
  totalCompletedElements: number;
  completedOrdersEndIncrement = 25;
  completedOrdersEndIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(this.completedOrdersEndIncrement);
  completedOrdersSearch: FormControl = new FormControl(this.completedOrdersService.searchTerm);
  subscriptions: Subscription[] = [];

  public virtualScroll: Observable<Array<HeaderItem | OrderSummary>>;

  get completedOrdersEndIndex$(): Observable<number> {
    return this.completedOrdersEndIndex$$.asObservable();
  }

  constructor(public completedOrdersService: CompletedOrdersService) {}

  ngOnInit() {
    this.virtualScroll = combineLatest(
      this.completedOrdersService.completedOrders$,
      this.completedOrdersService.cancelledOrders$,
      this.completedOrdersEndIndex$.pipe(distinctUntilChanged()),
    ).pipe(
      map(([completed, cancelled, endIndex]) => {
        const completedHeader: HeaderItem = {
          id: 'completedHeader',
          name: `Completed (${completed.length})`,
          isHeaderItem: true,
        };
        const cancelledHeader: HeaderItem = {
          id: 'cancelledHeader',
          name: `Cancelled (${cancelled.length})`,
          isHeaderItem: true,
        };
        completed.forEach((order) => (order.isCompleted = true));
        cancelled.forEach((order) => (order.isCompleted = false));
        const arr: Array<HeaderItem | OrderSummary> = [completedHeader, ...completed, cancelledHeader, ...cancelled];
        return arr.slice(0, Math.min(endIndex, arr.length)) as Array<HeaderItem | OrderSummary>;
      }),
    );

    this.subscriptions.push(
      this.completedOrdersSearch.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
        this.completedOrdersEndIndex$$.next(25);
        this.completedOrdersService.searchTerm = value;
      }),
    );
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      const currentCompletedOrdersEndIndex = this.completedOrdersEndIndex$$.value;
      if (currentCompletedOrdersEndIndex < this.completedOrdersService.totalFilterMatchedOrders + 2) {
        this.completedOrdersEndIndex$$.next(currentCompletedOrdersEndIndex + this.completedOrdersEndIncrement);
      }
    }
  }

  trackByFn(index, item) {
    return item.id;
  }
}
