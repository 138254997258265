import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SubnavMenu } from '../../models/subnav-menu';
import { SubnavService } from '../../services/subnav.service';

@Component({
  selector: 'sa-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubnavComponent {
  @Input() subnavMenu: SubnavMenu[];
  @Output() onTabSwitch: EventEmitter<string> = new EventEmitter();

  constructor(private subnavService: SubnavService) {}

  setActiveMenu(label: string) {
    this.subnavMenu = this.subnavService.setActiveMenu(label, this.subnavMenu);
    this.onTabSwitch.emit(label);
  }
}
