import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud.service';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FileUploadService {
  get route(): string {
    return environment.api + '/order';
  }
  get emailRoute(): string {
    return environment.api + '/email_parser';
  }

  constructor(private crud: CrudService, private http: HttpClient) {}

  uploadFile(id, fileToUpload, category?: string): Observable<any[]> {
    if (fileToUpload) {
      const formData = new FormData();
      if (fileToUpload.name) {
        console.log('Uploading file with name');
        formData.append('file', fileToUpload, fileToUpload.name);
      } else {
        console.log('Uploading file without name');
        formData.append('file', fileToUpload);
      }
      if (category) {
        formData.append('category', category);
      }
      return this.http.post<any[]>(this.route + '/' + id + '/uploadFile', formData);
    } else {
      return throwError('File was not set, please try again');
    }
  }

  removeFile(fileId, orderId): Observable<any[]> {
    return this.crud.delete(this.route + '/' + orderId + '/remove/' + fileId);
  }

  testUpload(id, fileToUpload): Observable<any[]> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    return this.crud.post(this.emailRoute + '/' + id + '/test_upload', formData);
  }

  downloadFile(fileId, orderId): Observable<any> {
    return this.crud.get(this.route + '/' + orderId + '/download_link/' + fileId);
  }
}
