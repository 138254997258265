import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchApiService {
  get route(): string {
    return environment.api;
  }

  constructor(private http: HttpClient) {}

  allTrucks(): Observable<any> {
    return this.http.get(this.route + '/truck');
  }

  searchTrucks(searchText): Observable<any> {
    return this.http.get(this.route + '/truck/search', { params: { 'like-name': searchText } });
  }

  searchTrailers(searchText: string, trailerPreAssign: boolean = false): Observable<any> {
    return this.http.get(this.route + '/trailer/search', {
      params: {
        'like-name': searchText,
        'pre-assign': trailerPreAssign.toString(),
      },
    });
  }

  searchTrailerBySubcarrier(subcarrier, searchText: string): Observable<any> {
    return this.http.get(this.route + '/trailer/search_by_subcarrier', {
      params: { subcarrierId: subcarrier.subcarrierId, searchText: searchText },
    });
  }

  createTruck(searchText): Observable<any> {
    return this.http.post(this.route + '/truck', { name: searchText });
  }

  createTrailer(searchText): Observable<any> {
    return this.http.post(this.route + '/trailer', { name: searchText });
  }
}
