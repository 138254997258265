import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'sa-nav-top-bar',
  templateUrl: './nav-top-bar.component.html',
  styleUrls: ['./nav-top-bar.component.scss'],
})
export class NavTopBarComponent implements OnInit {
  public displayNav = false;
  userLabel: { name: string; email: string; account: string };

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userLabel = this.userService.getLabel();
  }

  public menuClick() {
    this.displayNav = !this.displayNav;
  }
}
