type EditableSiteField = 'name' | 'api' | 'ownerName' | 'siteTypeName' | 'lngLat' | 'radius';

export const EditableSiteFields = {
  name: 'name' as EditableSiteField,
  api: 'api' as EditableSiteField,
  owner: 'ownerName' as EditableSiteField,
  type: 'siteTypeName' as EditableSiteField,
  lngLat: 'lngLat' as EditableSiteField,
  radius: 'radius' as EditableSiteField,
};
