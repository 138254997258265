import { add } from 'date-fns';
import { equals } from 'remeda';
import { prettyPrintTimes } from './prettyPrintTimes';
import { Restriction, RestrictionException, RestrictionPoint, RestrictionTime, SiteMinimal } from './types';

export interface RoadRestrictionApiResponse {
  id: number;
  name: string;
  polygon: RestrictionPoint[];
  times: RestrictionTime[];
  fracsAffected: Record<number, SiteMinimal>;
  minesAffected: Record<number, SiteMinimal>;
  fracMinePairs: { fracId: number; mineId: number; route: RestrictionPoint[] }[];
  exceptions: RestrictionException[];
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  applicableNow: boolean;
  curfew: boolean;
}
export function toLocalRestriction(response: RoadRestrictionApiResponse): Restriction {
  const temp: Restriction = {
    id: response.id,
    name: response.name,
    polygon: response.polygon || [],
    times: response.times || [],
    prettyPrintTimes: [],
    fracsAffected: response.fracsAffected || {},
    minesAffected: response.minesAffected || {},
    fracMinePairs: [],
    fracMineForcedPairs: [],
    createdAt: response.createdAt,
    updatedAt: response.updatedAt,
    startDate: response.startDate,
    endDate: response.endDate,
    isActive: response.isActive,
    applicableNow: response.applicableNow,
    curfew: response.curfew,
  };
  temp.prettyPrintTimes = prettyPrintTimes(temp.times);

  temp.fracMinePairs = (response.fracMinePairs || []).map((pair) => {
    return {
      frac: temp.fracsAffected[pair.fracId],
      mine: temp.minesAffected[pair.mineId],
      route: pair.route,
    };
  });

  temp.fracMineForcedPairs = (response.exceptions || [])
    .filter((pair) => pair.exceptionType === 'include')
    .map((pair) => {
      return {
        frac: temp.fracsAffected[pair.fracId],
        mine: temp.minesAffected[pair.mineId],
      };
    });

  if (response.startDate) {
    const asDate = new Date(response.startDate);
    const dateCopy = add(asDate, {
      minutes: asDate.getTimezoneOffset(),
    });
    temp.startDate = dateCopy.toISOString();
  }

  if (response.endDate) {
    const asDate = new Date(response.endDate);
    const dateCopy = add(asDate, {
      minutes: asDate.getTimezoneOffset(),
    });
    temp.endDate = dateCopy.toISOString();
  }

  // Google maps doesn't like closed polygons
  if (temp.polygon.length > 1 && equals(temp.polygon[0], temp.polygon[temp.polygon.length - 1])) {
    temp.polygon = response.polygon.slice(0, response.polygon.length - 1);
  }

  return temp;
}
