/// <reference types="@types/googlemaps" />
// import { } from '@types/googlemaps';
import { Injectable } from '@angular/core';
import { Waypoint } from '../models/waypoint';
import { Site } from '../models/site';
import { TooltipTimestampPipe } from '../pipes/tooltip-timestamp.pipe';
import { Session } from '../models/session';
import { DatePipe } from '@angular/common';
import { InfoBox } from '../map/google-maps-utility/infobox';
import { SessionService } from './session.service';
import Marker = google.maps.Marker;
import Cirle = google.maps.Circle;
import Map = google.maps.Map;
import { BoxPickup } from '~lmo/models/order.model';

@Injectable()
export class MapService {
  constructor(
    private timestampPipe: TooltipTimestampPipe,
    private datePipe: DatePipe,
    private sessionService: SessionService,
  ) {}

  convertWaypointsToLatLng(waypoints: Waypoint[]): google.maps.LatLng[] {
    const result = [];

    waypoints.forEach((waypoint) => {
      result.push(new google.maps.LatLng(waypoint.lngLat[1], waypoint.lngLat[0]));
    });

    return result;
  }

  getClusterIconByCount(count) {
    const basePath = '../../assets/icons/clustering/m';

    if (count <= 10) {
      return basePath + '1.png';
    }

    if (count > 10 && count <= 100) {
      return basePath + '2.png';
    }

    if (count > 100 && count <= 1000) {
      return basePath + '3.png';
    }

    if (count > 1000 && count <= 10000) {
      return basePath + '4.png';
    }

    if (count > 10000) {
      return basePath + '5.png';
    }
  }

  createUserWaypointMarker(waypoint: Waypoint): google.maps.Marker {
    return new google.maps.Marker({
      position: new google.maps.LatLng(waypoint.lngLat[1], waypoint.lngLat[0]),
      icon: '../../assets/icons/user-icon.svg',
    });
  }

  createTruckWaypointMarker(waypoint: Waypoint): google.maps.Marker {
    return new google.maps.Marker({
      position: new google.maps.LatLng(waypoint.lngLat[1], waypoint.lngLat[0]),
      icon: '../../assets/icons/delivery-truck.svg',
    });
  }

  createTruckMarker(lngLat, color): google.maps.Circle {
    return new google.maps.Circle({
      strokeColor: color,
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.25,
      center: { lat: lngLat[1], lng: lngLat[0] },
      radius: 500,
    });
  }

  createUnassignedTruckMarker(lngLat): google.maps.Marker {
    const markerImage = {
      url: '../../assets/icons/loaded-truck.svg',
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
    };
    return new google.maps.Marker({
      position: new google.maps.LatLng(lngLat[1], lngLat[0]),
      icon: markerImage,
    });
  }

  createTruckMarkerWithStatus(
    lngLat: number[],
    loaded: boolean,
    loadNumber: number,
    meshType: string,
    onBoxMove: boolean,
    pendingBoxPickup?: boolean,
  ): google.maps.Marker {
    const markerImage = this.truckMarkerIcon(loadNumber, loaded, onBoxMove, pendingBoxPickup);
    markerImage.labelOrigin = new google.maps.Point(25, 45);
    return new google.maps.Marker({
      position: new google.maps.LatLng(lngLat[1], lngLat[0]),
      icon: markerImage,
      label: {
        fontSize: '12px;',
        text: meshType,
      },
    });
  }

  truckMarkerIconColor(loaded: boolean, onBoxMove: boolean, pendingBoxPickup?: boolean): string {
    return onBoxMove
      ? '#f4c430'
      : loaded && !pendingBoxPickup
      ? '#448AFF'
      : !loaded && !pendingBoxPickup
      ? '#5F9A7F'
      : pendingBoxPickup
      ? '#770000'
      : '#5F9A7F'; // default color
  }

  truckMarkerIcon(
    loadNumber: string | number,
    loaded: boolean,
    onBoxMove: boolean,
    pendingBoxPickup?: boolean,
  ): google.maps.Icon {
    const marker = this.getDataSvg(loadNumber, this.truckMarkerIconColor(loaded, onBoxMove, pendingBoxPickup));
    const markerImage = {
      url: marker,
      scaledSize: new google.maps.Size(50, 50),
      anchor: new google.maps.Point(25, 25),
    };
    return markerImage;
  }

  getDataSvg(innerText: string | number, color: string, stroke = 'white', strokeWidth = 2): string {
    const svg = `<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">\
      <g filter="url(#filter0_d)">\
        <path d="M41 59.8889C51.432 59.8889 59.8889 51.432 59.8889 41C59.8889 30.5679 51.432 22.1111 41 22.1111C30.5679 22.1111 22.1111 30.5679 22.1111 41C22.1111 51.432 30.5679 59.8889 41 59.8889Z" fill="${color}" stroke="${stroke}" stroke-width="${strokeWidth}"/>\
        <text font-size="14" text-anchor="middle" x="41" y="46" fill="white">${innerText}</text>
      </g>\
      <defs>\
        <filter id="filter0_d" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>\
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\
          <feOffset/>\
          <feGaussianBlur stdDeviation="10"/>\
          <feColorMatrix type="matrix" values="0 0 0 0 0.266667 0 0 0 0 0.541176 0 0 0 0 1 0 0 0 0.5 0"/>\
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\
        </filter>\
      </defs>\
    </svg>`;
    return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`;
  }

  createSiteMarker(site: Site): Marker {
    const markerImage = {
      url: '../../assets/icons/site-icon.svg',
      size: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
    };
    return new google.maps.Marker({
      position: new google.maps.LatLng(site.lngLat[1], site.lngLat[0]),
      icon: markerImage,
    });
  }

  createFracMarker(site, color): google.maps.Circle {
    return new google.maps.Circle({
      strokeColor: color,
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.25,
      center: { lat: site.lngLat[1], lng: site.lngLat[0] },
      radius: site.radius,
    });
  }

  createBoxPickupMarker(boxPickup: BoxPickup): google.maps.Circle {
    return new google.maps.Circle({
      strokeColor: '#FFA500',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: '#FFA500',
      fillOpacity: 0.25,
      center: { lat: boxPickup.lngLat[1], lng: boxPickup.lngLat[0] },
      radius: boxPickup.siteRadius,
    });
  }

  createLoaderMarker(site, color): google.maps.Circle {
    return new google.maps.Circle({
      strokeColor: '#53687A',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.25,
      center: { lat: site.lngLat[1], lng: site.lngLat[0] },
      radius: site.radius,
    });
  }

  createOuterRadius(marker: Marker, map: Map): Cirle {
    const circle = new google.maps.Circle({
      map: map,
      radius: 1609.3,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    });
    circle.bindTo('center', marker, 'position');
    return circle;
  }

  createRouteStartMarker(firstWaypoint: Waypoint) {
    return new google.maps.Marker({
      position: new google.maps.LatLng(firstWaypoint.lngLat[1], firstWaypoint.lngLat[0]),
      icon: '../../assets/icons/waypoint-start.svg',
    });
  }

  createRouteEndMarker(lastWaypoint: Waypoint) {
    return new google.maps.Marker({
      position: new google.maps.LatLng(lastWaypoint.lngLat[1], lastWaypoint.lngLat[0]),
      icon: '../../assets/icons/waypoint-end.svg',
    });
  }

  createPolylineInfobox(waypoint: Waypoint, loadId, first): any {
    return new InfoBox({
      alignBottom: true,
      boxClass: 'tooltip-waypoint',
      maxWidth: 240,
      pixelOffset: new google.maps.Size(-120, -36),
      closeBoxURL: '',
      content: this.generatePolylineTooltip(waypoint, loadId, first),
      disableAutoPan: true,
    });
  }

  createUserInfobox(waypoint: Waypoint): any {
    return new InfoBox({
      alignBottom: true,
      boxClass: 'tooltip-waypoint',
      maxWidth: 240,
      pixelOffset: new google.maps.Size(-120, -36),
      closeBoxURL: '',
      content: this.generateUserTooltip(waypoint),
      disableAutoPan: true,
    });
  }

  createFracInfobox(site, isAvailable): any {
    return new InfoBox({
      alignBottom: true,
      pixelOffset: new google.maps.Size(0, -8),
      maxWidth: 140,
      closeBoxURL: '',
      content: this.generateMapSiteTooltip(site, isAvailable),
      disableAutoPan: true,
    });
  }

  createBoxPickupInfobox(boxPickup: BoxPickup): any {
    return new InfoBox({
      alignBottom: true,
      pixelOffset: new google.maps.Size(0, -8),
      maxWidth: 140,
      closeBoxURL: '',
      content: this.generateBoxPickupSiteTooltip(boxPickup.siteName),
      disableAutoPan: true,
    });
  }

  createUnassignedTruckInfobox(name, time?): InfoBox {
    return new InfoBox({
      alignBottom: true,
      pixelOffset: new google.maps.Size(0, -(10 + 8)),
      closeBoxURL: '',
      maxWidth: 500,
      content: this.generateTruckTooltip(name, time),
      disableAutoPan: true,
    });
  }

  createLoaderInfobox(site, isClosed: boolean): any {
    return new InfoBox({
      alignBottom: true,
      pixelOffset: new google.maps.Size(0, -8),
      maxWidth: 500,
      closeBoxURL: '',
      content: this.generateLoaderTooltip(site.name, isClosed),
      disableAutoPan: true,
    });
  }

  generateTruckTooltip(name, time?): String {
    return `
       <div class="loader-tooltip">${name} <span>${time || ''}</span></div>
      `;
  }

  generateLoaderTooltip(name, isClosed: boolean): String {
    return `
       <div class="${isClosed ? 'loader-tooltip-down' : 'loader-tooltip'}">${name}</div>
      `;
  }

  generateMapSiteTooltip(site, isAvailable): String {
    return `
        <div class="tooltip-content c-tooltip">
          <div class="tooltip-details c-details">
            <div class="tooltip-info c-info">
               <div class="${isAvailable ? 'header' : 'header-down'}">${site.name || 'No Name Found'}</div>
               <div class="img-wrap" style="background: ${
                 isAvailable ? 'rgba(45, 45, 46, 0.9)' : 'rgba(119, 0, 0, 0.9)'
               }"><img src="../../assets/icons/Rig.svg" alt="Icon"></div>
            </div>
          </div>
        </div>
      `;
  }

  generateBoxPickupSiteTooltip(site): String {
    return `
        <div class="tooltip-content c-tooltip">
          <div class="tooltip-details c-details">
            <div class="tooltip-info c-info">
             <div class="header">(BOX PICKUP) ${site}</div>
          </div>
          </div>
        </div>
      `;
  }

  createSiteInfobox(site: Site): any {
    return new InfoBox({
      alignBottom: true,
      boxClass: 'tooltip-site',
      maxWidth: 180,
      pixelOffset: new google.maps.Size(-90, -36),
      closeBoxURL: '',
      content: this.generateSiteTooltip(site),
      disableAutoPan: true,
    });
  }

  generateUserTooltip(waypoint: Waypoint): string {
    return `
            <div class="tooltip-content">
              <div class="tooltip-details">
                <img class="tooltip-icon" src="../../assets/icons/user-icon-white-transparent.svg">
                <div class="tooltip-info">
                  <div class="header">${waypoint.userName || 'No Name Found'}</div>
                  <div>${waypoint.equipmentTypeName || 'No Equipment Found'}</div>
                </div>
            </div>
              <div class="tooltip-timestamp">${this.timestampPipe.transform(waypoint.timestamp) || 'No Timestamp'}</div>
           </div>
            <div class="tooltip-pointer tooltip-pointer-waypoint"></div>
          `;
  }

  generateSiteTooltip(site: Site): string {
    return `
            <div class="tooltip-content">
              <div class="tooltip-details">
                <img class="tooltip-icon" src="../../assets/icons/site-icon-white-transparent.svg">
                  <div class="tooltip-info">
                    <div class="header">${site.name || 'No Name Found'}</div>
                    <div>${site.ownerName || 'No Owner Found'}</div>
                  </div>
              </div>
            </div>
            <div class="tooltip-pointer tooltip-pointer-site"></div>
           `;
  }

  generatePolylineTooltip(waypoint: Waypoint, loadId, first): string {
    return `
            <div class="tooltip-content">
              <div class="tooltip-details">
                <img class="tooltip-icon" src="../../assets/icons/user-icon-white-transparent.svg">
                <div class="tooltip-info">
                  <div class="header">Load Number ${loadId}</div>
                    <div>${first ? 'Start Point' : 'End Point'}</div>
                </div>
            </div>
              <div class="tooltip-timestamp">${this.datePipe.transform(waypoint.timestamp, 'shortTime') ||
                'No Timestamp'}</div>
           </div>
            <div class="tooltip-pointer tooltip-pointer-waypoint"></div>
          `;
  }

  createCircle(map, radius, color) {
    return new google.maps.Circle({
      map: map,
      radius: radius,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
      editable: true,
    });
  }

  createRectangle(map, bounds, color) {
    return new google.maps.Rectangle({
      map: map,
      bounds: bounds,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
      editable: true,
    });
  }

  createPolygon(map, bounds, color) {
    return new google.maps.Polygon({
      map: map,
      paths: bounds,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
      editable: true,
    });
  }

  movePolygon(shape: any, latChanged: number, lngChanged: number) {
    const path = shape.getPaths();
    const newPath = [];
    for (let i = 0; i < path.getAt(0).getArray().length; i++) {
      newPath.push({
        lat:
          path
            .getAt(0)
            .getArray()
            [i].lat() - latChanged,
        lng:
          path
            .getAt(0)
            .getArray()
            [i].lng() - lngChanged,
      });
    }
    shape.setPaths(newPath);
  }

  moveRectangle(shape: any, latChanged: number, lngChanged: number) {
    const north =
      shape
        .getBounds()
        .getNorthEast()
        .lat() - latChanged;
    const south =
      shape
        .getBounds()
        .getSouthWest()
        .lat() - latChanged;
    const east =
      shape
        .getBounds()
        .getNorthEast()
        .lng() - lngChanged;
    const west =
      shape
        .getBounds()
        .getSouthWest()
        .lng() - lngChanged;
    shape.setBounds({
      north: north,
      south: south,
      east: east,
      west: west,
    });
  }

  setPolygonBoundsByMapCenter(center, delta) {
    return [
      {
        lat: center.lat() - delta,
        lng: center.lng(),
      },
      {
        lat: center.lat() + delta,
        lng: center.lng() + delta,
      },
      {
        lat: center.lat() + delta,
        lng: center.lng() - delta,
      },
      {
        lat: center.lat() - delta,
        lng: center.lng(),
      },
    ];
  }

  setRactangleBoundsByMapCenter(center, delta) {
    return new google.maps.LatLngBounds(
      {
        lat: center.lat() - delta,
        lng: center.lng() - delta,
      },
      {
        lat: center.lat() + delta,
        lng: center.lng() + delta,
      },
    );
  }

  shapeFromBounds(bounds) {
    return [
      [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()],
      [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()],
    ];
  }

  boundsFromShape(shape) {
    return new google.maps.LatLngBounds(
      {
        lat: shape[1][0],
        lng: shape[1][1],
      },
      {
        lat: shape[0][0],
        lng: shape[0][1],
      },
    );
  }

  shapeFromPath(path) {
    const newPath = [];
    for (let i = 0; i < path.getAt(0).getArray().length; i++) {
      newPath.push([
        path
          .getAt(0)
          .getArray()
          [i].lat(),
        path
          .getAt(0)
          .getArray()
          [i].lng(),
      ]);
    }
    return newPath;
  }

  pathFromShape(shape) {
    const path = [];
    for (let i = 0; i < shape.length; i++) {
      path.push({
        lat: shape[i][0],
        lng: shape[i][1],
      });
    }
    return path;
  }
}
