export const config = {
  statusPageUrl: 'http://status.shaleapps.com',
  supportPageUrl: 'http://support.shaleapps.com',
  releaseNotesUrl: 'https://releases.shaleapps.com',
  statusPageApiUrl: 'https://v74xll8c306l.statuspage.io/api/v2/status.json',
  releaseNotesApiUrl: 'https://releases.shaleapps.com/ghost/api/v0.1/posts/',
  googleMapsScriptUrl:
    'https://maps.googleapis.com/maps/api/js?loading=async&callback=__onGoogleLoaded&libraries=visualization&key=AIzaSyDBoueBR5-Viwx1M01l0W7S11u5tQbjtnA',
  googleMapsApiKey: 'AIzaSyDBoueBR5-Viwx1M01l0W7S11u5tQbjtnA',
  intercomId: 'd6qjveaa',
};
