import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

export function debounce(delay: number = 300): MethodDecorator {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

@Directive({
  selector: '[saDisableFormat]',
  providers: [CurrencyPipe],
})
export class DisableFormatDirective {
  onChange: Function;
  onTouched: Function;
  value: number;

  @Input()
  step = 0.01;

  @HostListener('blur', ['$event'])
  @debounce()
  handleBlur(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    event.stopPropagation();

    let { value } = event.target as any;
    value = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
    switch (event.keyCode) {
      case 38: // up arrow
        value += this.step;
        break;
      case 40: // down arrow
        value -= this.step;
        break;
    }
    this.writeValue(value);
  }

  @HostListener('mousewheel', ['$event'])
  handleScroll(event) {
    this._renderer.selectRootElement(this._elementRef.nativeElement).blur();
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private currencyPipe: CurrencyPipe) {}

  writeValue(input: number): void {
    this.value = input;
    const formattedField = input === null ? '' : this.currencyPipe.transform(input, 'USA', '', '1.2-2');
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
