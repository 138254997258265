import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'onOrderTooLong',
})
export class OnOrderTooLongPipe implements PipeTransform {
  transform(dispatchTime: string, minutesTooLong: number = 4 * 60) {
    if (!dispatchTime) {
      return false;
    }
    const now = moment();
    const momentDispatch = moment(dispatchTime);
    const duration = moment.duration(now.diff(momentDispatch));
    const asMinutes = duration.asMinutes();
    return asMinutes > minutesTooLong;
  }
}
