import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderIntegrationStatsForDate } from '~models/emailSettings.model';
import { MatSort } from '@angular/material/sort';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  EmailSettingsV2Service,
  LoaderIntegrationExportParameters,
  MineForFilter,
  PLONumberForExportSelection,
} from '~services/email-settings-v2.service';
import { UserService } from '~services/user.service';
import { Router } from '@angular/router';
import { map, startWith, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LmoFracFcpoService } from '~lmo/services/lmo-frac-fcpo.service';
import { PurchaseOrder } from '~lmo/models/purchaseOrders.model';

@Component({
  selector: 'sa-export-loader-analytics-data',
  templateUrl: './export-loader-analytics-data.component.html',
  styleUrls: ['./export-loader-analytics-data.component.scss'],
})
export class ExportLoaderAnalyticsDataComponent implements OnInit {
  public analyticsForm: FormGroup;
  public isDownloading = false;

  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  filterLoader: FormControl;
  subscriptions: Subscription[] = [];

  public mines$: Observable<MineForFilter[]>;
  public filteredMines$: Observable<MineForFilter[]>;

  filterPloNumber: FormControl;

  public ploNumbers$: Observable<PLONumberForExportSelection[]>;
  public filteredPloNumbers$: Observable<PLONumberForExportSelection[]>;

  filterPurchaseOrder: FormControl;

  public purchaseOrders$: Observable<PurchaseOrder[]>;
  public filteredPurchaseOrders$: Observable<PurchaseOrder[]>;

  public today: Date;

  public isPLOExportControl: FormControl;

  constructor(
    private matDialogRef: MatDialogRef<ExportLoaderAnalyticsDataComponent>,
    private fb: FormBuilder,
    private emailSettingsService: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
    private fcpoService: LmoFracFcpoService,
  ) {
    this.today = new Date();
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

    this.emailSettingsService.loadPLONumbersForExportSelection();
  }

  public ngOnInit() {
    this.mines$ = this.emailSettingsService.minesForFilter$;
    this.ploNumbers$ = this.emailSettingsService.ploNumbers$;

    this.purchaseOrders$ = this.fcpoService.purchaseOrders$;

    this.emailSettingsService.loadMinesForFilter();

    this.subscriptions.push(
      this.mines$.pipe(take(1)).subscribe((mines) => {
        this.setupForm(mines);
      }),
    );
  }

  private setupForm(initialMines: MineForFilter[]) {
    let mine = null;

    const currParameters = this.emailSettingsService.getEmailStatisticsDateRange();

    if (currParameters) {
      mine = initialMines.find((m) => m.id === currParameters.mineId) || null;
    }

    this.analyticsForm = this.fb.group({
      startDate: [this.today, [Validators.required]],
      endDate: [this.today, [Validators.required]],
      mine: [mine, []],
      frac: [null, []],
      purchaseOrder: [null, []],
    });

    this.filterLoader = this.fb.control('');

    this.filteredMines$ = combineLatest([this.mines$, this.filterLoader.valueChanges.pipe(startWith(''))]).pipe(
      map(([mines, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          const val = mines.filter(
            (option) =>
              option.name.toLowerCase().indexOf(filterValue) > -1 ||
              option.id === this.analyticsForm.controls['mine'].value?.id,
          );
          return val;
        } else {
          return mines;
        }
      }),
    );

    this.filterPloNumber = this.fb.control('');

    this.filteredPloNumbers$ = combineLatest([
      this.ploNumbers$,
      this.filterPloNumber.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([ploNumbers, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          const val = ploNumbers.filter(
            (option) =>
              option.ploNumber.toLowerCase().indexOf(filterValue) > -1 ||
              option.id === this.analyticsForm.controls['ploNumber'].value?.id,
          );
          return val;
        } else {
          return ploNumbers;
        }
      }),
    );

    this.filterPurchaseOrder = this.fb.control('');

    this.filteredPurchaseOrders$ = combineLatest([
      this.purchaseOrders$,
      this.filterPurchaseOrder.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([purchaseOrders, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          const val = purchaseOrders.filter((option) => option.name.toLowerCase().indexOf(filterValue) > -1);
          return val;
        } else {
          return purchaseOrders;
        }
      }),
    );

    this.isPLOExportControl = this.fb.control(false);

    this.subscriptions.push(
      this.isPLOExportControl.valueChanges.subscribe((value) => {
        if (value) {
          this.analyticsForm.get('purchaseOrder').setValue(null);
          this.analyticsForm.get('startDate').setValidators([]);
          this.analyticsForm.get('endDate').setValidators([]);
          this.analyticsForm.get('frac').setValidators([Validators.required]);
          this.analyticsForm.get('startDate').updateValueAndValidity();
          this.analyticsForm.get('endDate').updateValueAndValidity();
          this.analyticsForm.get('frac').updateValueAndValidity();
        } else {
          this.analyticsForm.get('startDate').setValidators([Validators.required]);
          this.analyticsForm.get('endDate').setValidators([Validators.required]);
          this.analyticsForm.get('frac').setValidators([]);
          this.analyticsForm.get('startDate').updateValueAndValidity();
          this.analyticsForm.get('endDate').updateValueAndValidity();
          this.analyticsForm.get('frac').updateValueAndValidity();
        }
      }),
    );

    this.subscriptions.push(
      this.analyticsForm.get('purchaseOrder').valueChanges.subscribe((value) => {
        if (value) {
          this.analyticsForm.get('startDate').setValidators([]);
          this.analyticsForm.get('endDate').setValidators([]);
          this.analyticsForm.get('startDate').updateValueAndValidity();
          this.analyticsForm.get('endDate').updateValueAndValidity();
        } else {
          this.analyticsForm.get('startDate').setValidators([Validators.required]);
          this.analyticsForm.get('endDate').setValidators([Validators.required]);
          this.analyticsForm.get('startDate').updateValueAndValidity();
          this.analyticsForm.get('endDate').updateValueAndValidity();
        }
      }),
    );
  }

  public async exportLoaderAnalytics() {
    try {
      this.isDownloading = true;

      const formValue = this.analyticsForm.value;

      if (!formValue.startDate) {
        formValue.startDate = new Date(0);
      }

      if (!formValue.endDate) {
        formValue.endDate = new Date();
      }

      const parameters: LoaderIntegrationExportParameters = {
        startDate: formValue.startDate as Date,
        endDate: formValue.endDate as Date,
        mineId: formValue.mine?.id as number,
        fracId: formValue.frac?.id as number,
        ploExport: this.isPLOExportControl.value as boolean,
        purchaseOrderId: formValue.purchaseOrder?.id as number,
      };

      const url = await this.emailSettingsService.exportLoaderIntegrationAnalytics(parameters);

      window.open(url, '_blank');

      this.snackBar.open('Export complete', null, { duration: 3000 });

      this.matDialogRef.close();
    } catch {
      this.snackBar.open('Export failed', null, { duration: 3000, panelClass: ['snackbar-error'] });
    } finally {
      this.isDownloading = false;
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }
}
