import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigValuesService {
  private configs$$ = new BehaviorSubject<Record<string, any>>(null);

  get configs$(): Observable<Record<string, any>> {
    return this.configs$$.asObservable().pipe(
      filter((configs) => !!configs),
      map((configs) => Object.assign({}, configs)),
      share(),
    );
  }

  constructor(private db: AngularFirestore) {
    this.subscribeToConfigVals();
  }

  private subscribeToConfigVals() {
    this.db
      .doc<Record<string, any>>(`sandi-config-values/${environment.featureFlagKey}`)
      .valueChanges()
      .subscribe(
        (configs) => {
          if (configs) {
            this.configs$$.next(configs);
          } else {
            this.configs$$.next({});
          }
        },
        (error) => {
          this.configs$$.next({});
        },
      );
  }
}
