import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { switchMap, filter, share, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationGroup } from '~models/notifications.model';
import { environment } from '~environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notifications$$: BehaviorSubject<NotificationGroup[]> = new BehaviorSubject<NotificationGroup[]>([]);

  public notifications$ = this.notifications$$.asObservable().pipe(share());

  constructor(private crudService: CrudService, private authService: AuthService) {
    this.publishNewNotification = this.publishNewNotification.bind(this);
    this.loadNotifications();
    // For people using multiple accounts without refreshing
    this.authService.getSocketEventListener().subscribe((event) => {
      if (event === 'logout') {
        this.notifications$$.next([]);
      } else if (event === 'login') {
        this.loadNotifications();
      }
    });
  }

  private loadNotifications() {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/v2/notifications`)),
      )
      .subscribe(this.publishNewNotification);
  }

  public updateEmailNotification(notificationId: number, enabled: boolean): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() =>
          this.crudService.put(`${environment.api}/v2/notification/${notificationId}/email`, { enabled }),
        ),
      )
      .subscribe(this.publishNewNotification);
  }

  public updatePushNotification(notificationId: number, enabled: boolean): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.put(`${environment.api}/v2/notification/${notificationId}/push`, { enabled })),
      )
      .subscribe(this.publishNewNotification);
  }

  publishNewNotification(groups: NotificationGroup[]): void {
    groups.forEach((g) => g.notifications.sort((a, b) => (a.name < b.name ? -1 : 1)));
    this.notifications$$.next(groups);
  }
}
