<div class="form-wrap">
  <form [formGroup]="chargeForm">
    <h2>
      Add Accessorial Charges
    </h2>
    <div class="input-wrap">
      <mat-form-field>
        <mat-select
          placeholder="Type"
          formControlName="type"
          [compareWith]="compareId"
          saAutomatedSelector="add_charge__type"
        >
          <mat-option
            *ngFor="let chargeType of chargeTypes"
            [value]="chargeType"
            saAutomatedSelector="add_charge__charge_type"
          >
            {{ chargeType.customerAbbrev }}-{{ chargeType.customerName }}({{ chargeType.customerEDICode }}) -
            {{ LMOChargeType[chargeType.type] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="input-wrap">
        <mat-form-field>
          <input
            type="number"
            matInput
            formControlName="unitCost"
            placeholder="Unit Cost"
            saAutomatedSelector="add_charge__unit_cost"
          />
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-select
          formControlName="quantityUnit"
          placeholder="Quantity Unit"
          [compareWith]="compareId"
          saAutomatedSelector="add_charge__quantity_unit"
        >
          <mat-option
            *ngFor="let unit of units"
            [value]="unit.value"
            saAutomatedSelector="add_charge__quantity_unit_option"
          >
            {{ unit.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input
          type="number"
          matInput
          formControlName="quantity"
          placeholder="Quantity"
          saAutomatedSelector="add_charge__quantity"
        />
      </mat-form-field>
    </div>
    <div class="error" *ngIf="duplicateError" saAutomatedSelector="add_charge__duplicate_error">
      An accessorial charge of that type has already been added to this order.
    </div>
    <div class="button-wrap">
      <button
        mat-stroked-button
        color="primary"
        (click)="submitForm()"
        *ngIf="!submitting"
        saAutomatedSelector="add_charge__submit"
      >
        Submit
      </button>
      <span class="loading" *ngIf="submitting">
        <mat-spinner [diameter]="20"></mat-spinner>
      </span>
      <button mat-stroked-button color="error" (click)="closeDialog()">Cancel</button>
    </div>
  </form>
</div>
