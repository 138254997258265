<i *ngIf="userService.isAdmin()" class="material-icons floating-plus" (click)="openUserDetailView({})">add</i>

<mat-table #table (matSortChange)="sortTable($event)" [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>
      ID
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.name || 'No Name' }}</mat-cell>
  </ng-container>

  <!--<ng-container matColumnDef="email">-->
  <!--<mat-header-cell *matHeaderCellDef>Email</mat-header-cell>-->
  <!--<mat-cell *matCellDef="let row">{{row.email || 'No Email'}}</mat-cell>-->
  <!--</ng-container>-->

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ (row.phone | phone) || 'No Phone Number' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="pin">
    <mat-header-cell *matHeaderCellDef>Pin</mat-header-cell>
    <mat-cell *matCellDef="let row" (click)="row.displayPin = true" style="cursor: pointer">
      <ng-container *ngIf="row.displayPin">{{ row.pin || 'No Pin in System' }}</ng-container>
      <ng-container *ngIf="!row.displayPin">Click To Show</ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="accountName">
    <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.account.name || 'No Account' }}</mat-cell>
  </ng-container>

  <ng-container *saIsBrokerage matColumnDef="subcarrier">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Subcarrier</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.subcarrierName || '-' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="cdl">
    <mat-header-cell *matHeaderCellDef mat-sort-header>CDL</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.cdl || '-' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="trailerType">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Trailer Type</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.trailerTypeName || '-' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="assetStatus">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Asset Status</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.assetStatus || '-' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="trailer">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Trailer Number</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.trailerName || '-' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row"
      ><i *ngIf="!userService.isLoHi()" class="material-icons" (click)="openUserDetailView(row)">edit</i></mat-cell
    >
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<sa-detail-view [model]="detailView" (onUserUpdated)="userUpdated($event)" (onCloseClicked)="closeUserDetailView()">
</sa-detail-view>
