<div class="back-layer" [@slideInOut]></div>

<div class="lmo-loader-name-mapping-form-component" [@slideInOut]>
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span *ngIf="!selectedMapping">Add</span>
        <span *ngIf="selectedMapping">Edit</span>
        Loader Name Mapping
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!mappingForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="mappingForm" [formGroup]="mappingForm" autocomplete>
      <div class="form-field">
        <label>Name in File</label>

        <mat-form-field>
          <input matInput placeholder="Name in File" formControlName="nameInFile" />
          <mat-error *ngIf="mappingForm.controls['nameInFile'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <label>Loader</label>

        <mat-form-field>
          <mat-select
            placeholder="Loader"
            saAutomatedSelector="loader-integration__loader"
            [formControl]="mappingForm.controls['loader']"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'Loader'"
                [noEntriesFoundLabel]="'No matching loader found'"
                [formControl]="filterLoader"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let mine of filteredMines$ | async" [value]="mine.id">
              {{ mine.site.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="mappingForm.controls['loader'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="button-wrap">
      <button
        *ngIf="mappingForm"
        mat-flat-button
        color="primary"
        [disabled]="!mappingForm.controls['nameInFile'].valid || !mappingForm.controls['loader'].valid"
        (click)="onSubmit()"
      >
        <span *ngIf="!selectedMapping" saAutomatedSelector="loader-integration__save-button">Save</span>
        <span *ngIf="selectedMapping" saAutomatedSelector="loader-integration__update-button">Update</span>
      </button>
    </div>
  </div>
</div>
