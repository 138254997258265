import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService } from '~services/error-handling.service';
import { User, UserGroup } from '~models/user';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserApiService } from '~services/api/user.api.service';

@Component({
  selector: 'sa-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: ['./add-user-group.component.scss'],
})
export class AddUserGroupComponent implements OnInit {
  userGroup: UserGroup;
  users: User[];
  addUserGroupForm: FormGroup;
  filterUser: FormControl = new FormControl();
  filteredUserOptions: Observable<User[]>;
  submitting = false;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  constructor(
    public dialogRef: MatDialogRef<AddUserGroupComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private userApiService: UserApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.userGroup = this.data.userGroup;
    this.users = this.data.users;
    this.addAllowedUserListener();
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.addUserGroupForm = this.fb.group({
      name: [this.userGroup.name ? this.userGroup.name : '', [Validators.required]],
      users: [this.userGroup.users ? this.userGroup.users : [], [Validators.required]],
    });
  }

  private addAllowedUserListener() {
    this.filteredUserOptions = this.filterUser.valueChanges.pipe(
      startWith<string | any>(''),
      // map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.users)),
    );
    this.filteredUserOptions = this.filteredUserOptions.pipe(map((users) => this.sortByName(users)));
  }

  private sortByName = (users: User[]) => {
    return users.slice().sort((a, b) => a.name.localeCompare(b.name));
  };

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.users.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  public submit() {
    if (this.addUserGroupForm.valid) {
      if (!this.userGroup.id) {
        this.userApiService.addUserGroup(this.addUserGroupForm.value).subscribe(
          (resp) => {
            this.snackBar.open('User Group successfully added', null, {
              duration: 5000,
            });
            this.dialogRef.close(true);
          },
          (err) => {
            this.showError(err);
          },
        );
      } else {
        this.userApiService.editUserGroup(this.userGroup.id, this.addUserGroupForm.value).subscribe(
          (resp) => {
            this.snackBar.open('User Group successfully updated', null, {
              duration: 5000,
            });
            this.dialogRef.close(true);
          },
          (err) => {
            this.showError(err);
          },
        );
      }
    } else {
      this.showError('Please Fill in all fields');
    }
  }

  private showError(err) {
    this.submitting = false;
    this.errorHandler.showError(err, 5000);
  }
}
