import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { SubnavMenu } from '../../models/subnav-menu';
import { Session } from '../../models/session';
import { SessionApiService } from '../../services/api/session.api.service';
import { OrderApiService } from '../../services/api/order.api.service';
import { SessionService } from '../../services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';

type SubnavLabel = 'Trips';

const SubnavLabels = {
  trips: 'Trips' as SubnavLabel,
};

@Component({
  selector: 'sa-driver-detail-view',
  templateUrl: './driver-detail-view.component.html',
  styleUrls: ['./driver-detail-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DriverDetailViewComponent implements OnInit {
  @Input() id;
  @Output() onDateSelected: EventEmitter<{}> = new EventEmitter<{}>();

  sessions: Session[];
  subnavLabels = SubnavLabels;
  subnavMenu: SubnavMenu[] = [{ label: this.subnavLabels.trips, active: true }];
  activeMenu = this.subnavLabels.trips;
  dates = [];
  dateFilter;
  orders = [];
  selectedDate;
  // disabledFilter = () => false;

  constructor(
    public dialog: MatDialog,
    private crud: CrudService,
    private orderApiService: OrderApiService,
    private sessionApiService: SessionApiService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe((ready) => {
        if (ready) {
          this.filterDate();
        }
      });
  }

  filterDate() {
    const startOfMonth = moment()
      .startOf('month')
      .format();
    const endOfMonth = moment()
      .hour(23)
      .minute(59)
      .second(59)
      .format();
    const filteredDates = [];
    const req = {
      UserId: this.id,
      StartTime: startOfMonth,
      endTime: endOfMonth,
    };
    this.orderApiService.getOrders(req).subscribe((data) => {
      data.forEach((date) => {
        if (
          filteredDates.indexOf(
            moment(date.acceptTimestamp)
              .startOf('day')
              .format(),
          ) < 0
        ) {
          filteredDates.push(
            moment(date.acceptTimestamp)
              .startOf('day')
              .format(),
          );
        }
      });
      this.dateFilter = (currentDate) => {
        return filteredDates.indexOf(moment(currentDate).format()) >= 0;
      };
    });
  }

  selectDate(date): void {
    this.selectedDate = date;
    const startDate = moment(date)
      .hour(0)
      .minute(0)
      .second(0)
      .format();
    const endDate = moment(date)
      .hour(23)
      .minute(59)
      .second(59)
      .format();
    // this.onDateSelected.emit({
    //   sessions: this.sessionService.findAllSessionsForDay(date, this.sessions),
    //   date: date
    // });
    //
    const body = {
      UserId: this.id,
      StartTime: startDate,
      endTime: endDate,
    };
    this.orderApiService.getOrders(body).subscribe((data) => {
      this.orders = data;
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '50%',
        maxWidth: '968px',
        data: this.orders,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (!result.hasOwnProperty('endTimestamp')) {
            result.endTimestamp = moment().format();
          }
          const selectedOrder = {
            loadId: result.loadNumber,
            id: this.id,
            startTime: result.acceptTimestamp,
            endTime: result.endTimestamp,
          };
          this.onDateSelected.emit(selectedOrder);
        }
      });
    });
  }
}
