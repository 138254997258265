import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountFeatureFlagsService {
  private flags$$: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject<Record<string, boolean>>(null);

  public get flags$(): Observable<Record<string, boolean>> {
    return this.flags$$.asObservable();
  }

  constructor(private http: HttpClient) {
    // this.getFlags();
  }

  public hasFlag$(string): Observable<boolean> {
    return this.flags$.pipe(map((flags) => flags && flags[string] === true));
  }

  public clearFlags() {
    this.flags$$.next(null);
  }

  public getFlags() {
    this.http.get<Record<string, boolean>>(`${environment.api}/account/feature_flags`).subscribe(
      (featureFlags) => {
        this.flags$$.next(featureFlags);
      },
      (error) => {
        console.log('Account feature flags failed to load, will try again later.');
      },
    );
  }
}
