import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

interface SocketPayload<T> {
  data: T;
  modelName: string;
  messageType: 'upsert' | 'removal';
}

const apiUrl: URL = new URL(environment.api);
const isLocal = environment.api.includes(':8080');
const server = isLocal
  ? `ws://${apiUrl.hostname}:${apiUrl.port}${apiUrl.pathname}`
  : `wss://${apiUrl.hostname}${apiUrl.pathname}`;

@Injectable({
  providedIn: 'root',
})
export class NoAuthSocketService {
  private socketMessages = new Subject<SocketPayload<any>>();
  private socket: WebSocket;

  private get url(): string {
    return `${server}/ws/no_auth_sockets`;
  }

  public getMessagesOfModelType<T>(modelName: string): Observable<SocketPayload<T>> {
    return this.socketMessages.asObservable().pipe(filter((payload) => payload.modelName === modelName));
  }

  public constructor() {
    // this.socket = new WebSocket(this.url);
    // this.socket.onmessage = (event) => {
    //   this.socketMessages.next(JSON.parse(event.data));
    // };
  }
}
