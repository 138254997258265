<sa-nav></sa-nav>

<sa-loading *ngIf="formLoading"></sa-loading>

<div class="outer-container" *ngIf="!formLoading">
  <div class="create-well-form-container container">
    <div class="frac-form" *ngIf="displayFracCreation">
      <a
        *ngIf="well && !well.archive && well.id"
        class="back-wrap"
        [routerLink]="['/', 'lmo', 'frac', well.id]"
        saAutomatedSelector="well-creation__back-to-well"
      >
        <i class="material-icons">arrow_back</i>
        Back to Well
      </a>
      <a
        class="back-wrap"
        [routerLink]="['/', 'wells']"
        saAutomatedSelector="well-creation__back-to-archive"
        *ngIf="well && well.archive"
      >
        <i class="material-icons">arrow_back</i>
        Back to Archived Wells
      </a>
      <a class="back-wrap" [routerLink]="['/', 'lmo']" saAutomatedSelector="well-creation__back-to-wells" *ngIf="!well">
        <i class="material-icons">arrow_back</i>
        Back to Wells
      </a>
      <div style="display: flex;justify-content: center;align-items: center; margin-bottom: 24px;padding-left: 24px;">
        <button
          mat-flat-button
          color="primary"
          *ngIf="wellId && !isLMOCrewLeader()"
          (click)="downloadRecords()"
          style="height: fit-content"
        >
          <span *ngIf="!downloadProgress"
            >Reporting
            <mat-icon style="vertical-align: middle;">vertical_align_bottom</mat-icon>
          </span>
          <mat-spinner class="white-spinner" *ngIf="downloadProgress" [diameter]="20"></mat-spinner>
        </button>
        <h2
          fxLayout="row"
          fxLayoutAlign="start stretch"
          style="flex: 1 1 0; padding-bottom: 0px"
          [ngClass]="{ 'center-text': wellId }"
        >
          <div>
            {{ wellId ? 'Edit Well ' + wellCreationForm.get('jobName').value : 'Create Well' }}
            <span class="api-pill" *ngIf="well?.createdByAPI">API</span>
          </div>
        </h2>

        <button
          mat-flat-button
          color="primary"
          *ngIf="wellId"
          color="{{ this.well.archive ? 'primary' : 'warn' }}"
          (click)="changeWellStatus()"
          style="height: fit-content;color:white;"
          saAutomatedSelector="well-creation__change-well-status"
        >
          <ng-container *saAllowedAccountRoles="['admin', 'dispatcher']">
            {{ this.well.archive ? 'Reactivate' : 'Archive' }}
          </ng-container>
          <ng-container *saAllowedAccountRoles="['default']">
            <ng-container *ngIf="this.well.archive">
              Reactivate
            </ng-container>
            <ng-container *ngIf="!this.well.archive && (isEligibleToArchive$ | async)">
              Archive
            </ng-container>
          </ng-container>
        </button>
      </div>
      <form
        autocomplete="off"
        *ngIf="wellCreationForm"
        [formGroup]="wellCreationForm"
        (submit)="validateFracInput(true)"
      >
        <h3 class="sub-form-heading">Details</h3>
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Site Name"
            type="text"
            [formControl]="wellCreationForm.controls['name']"
            saAutomatedSelector="well-creation__name"
          />
          <mat-error *ngIf="wellCreationForm.controls['name'].hasError('required')">
            Site Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Crew Name"
            type="text"
            [formControl]="wellCreationForm.get('crewName')"
            saAutomatedSelector="well-creation__crewname"
          />
        </mat-form-field>
        <mat-form-field class="form-field">
          <mat-label>Crew</mat-label>
          <mat-select formControlName="crewId">
            <mat-optgroup *ngFor="let groupCrew of crews$ | async" [label]="groupCrew.accountName">
              <mat-option *ngFor="let crew of groupCrew.crews" [value]="crew.id">{{ crew.name }}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <div class="form-field">
          <div class="flex flex-col">
            <mat-slide-toggle color="primary" [formControl]="wellCreationForm.get('percentBasedAutoOrdering')"
              >Percent Based Auto Ordering</mat-slide-toggle
            >
            <mat-hint>If turned on, this will prioritize purchase order percents over costs</mat-hint>
          </div>
        </div>
        <div class="job-name">
          <mat-form-field class="form-field">
            <input
              matInput
              placeholder="Job Name"
              type="text"
              [formControl]="wellCreationForm.get('jobName')"
              saAutomatedSelector="well-creation__name"
            />
            <mat-error *ngIf="wellCreationForm.get('name').hasError('required')">
              Job Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-job-id">
          <mat-form-field class="form-field">
            <input
              matInput
              placeholder="Custom Job ID (Optional)"
              type="text"
              [formControl]="wellCreationForm.get('customJobId')"
              saAutomatedSelector="well-creation__customJobId"
            />
          </mat-form-field>
        </div>

        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Offload Capacity"
            type="number"
            autocomplete="off"
            [formControl]="wellCreationForm.controls['maxTrucks']"
            [value]="wellCreationForm.controls['maxTrucks'].value"
            saAutomatedSelector="well-creation__offload-capacity"
          />
          <mat-hint
            >The number of trucks which can be offloaded at one time. Used to optimize deliveries to avoid detention.
          </mat-hint>
          <mat-error *ngIf="wellCreationForm.controls['maxTrucks'].hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              wellCreationForm.controls['maxTrucks'].invalid &&
              !wellCreationForm.controls['maxTrucks'].hasError('required')
            "
          >
            Value can not be less than 1
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Total # of Stages"
            type="number"
            autocomplete="off"
            [formControl]="wellCreationForm.controls['numOfStages']"
            [value]="wellCreationForm.controls['numOfStages'].value"
            (blur)="onStageChange()"
            saAutomatedSelector="well-creation__number-of-stages"
          />
          <mat-error *ngIf="wellCreationForm.controls['numOfStages'].hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              wellCreationForm.controls['numOfStages'].invalid &&
              !wellCreationForm.controls['numOfStages'].hasError('required')
            "
          >
            Value must be greater than 0
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
          <input
            matInput
            class="datepicker-input"
            placeholder="Prefill Date"
            autocomplete="off"
            [formControl]="wellCreationForm.controls['prefillDate']"
            [owlDateTime]="dt2"
            [owlDateTimeTrigger]="dt2"
            saAutomatedSelector="well-creation__prefill-date"
          />
          <span [owlDateTimeTrigger]="dt2"><i class="material-icons">calendar_today</i></span>
          <owl-date-time #dt2></owl-date-time>
          <!--<mat-datepicker-toggle matSuffix [for]="prefillPicker"></mat-datepicker-toggle>-->
          <!--<mat-datepicker #prefillPicker></mat-datepicker>-->
          <mat-error *ngIf="wellCreationForm.controls['prefillDate'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
          <input
            matInput
            class="datepicker-input"
            placeholder="Frac Date"
            autocomplete="off"
            [formControl]="wellCreationForm.controls['fracDate']"
            [owlDateTime]="dt3"
            [owlDateTimeTrigger]="dt3"
            saAutomatedSelector="well-creation__frac-date"
          />
          <span [owlDateTimeTrigger]="dt3"><i class="material-icons">calendar_today</i></span>
          <owl-date-time #dt3></owl-date-time>
          <mat-error *ngIf="wellCreationForm.controls['fracDate'].hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              wellCreationForm.controls['fracDate'].hasError('older') &&
              !wellCreationForm.controls['fracDate'].hasError('required')
            "
          >
            Frac Date can not be older than Prefill Date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field" *ngIf="districtList.length">
          <mat-select
            [formControl]="wellCreationForm.controls['district']"
            placeholder="Select District"
            [compareWith]="compareFn"
          >
            <mat-option *ngFor="let district of districtList" [value]="district">
              <span>{{ district.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *saFeatureFlag="'fracBasedFuelSurcharge'">
          <div class="form-field">
            <div class="flex flex-col">
              <mat-slide-toggle
                color="primary"
                [formControl]="wellCreationForm.get('enableFuelSurcharge')"
                [disabled]="!billingSettings.addFuelSurcharge"
                >Enable Fuel Surcharge</mat-slide-toggle
              >
              <ng-container *ngIf="billingSettings.addFuelSurcharge; else fscSettingsDisabled">
                <mat-hint>If turned on, fuel surcharge will be applied to loads</mat-hint>
              </ng-container>
              <ng-template #fscSettingsDisabled>
                <mat-hint>Enable Fuel Surcharge in Billing Configuration to use this feature</mat-hint>
              </ng-template>
            </div>
          </div>
        </ng-container>

        <div class="hseRequirements">
          <mat-form-field class="form-field">
            <textarea
              matInput
              placeholder="Health, Safety and Environment (HSE) Requirements"
              maxlength="5000"
              matAutosizeMinRows="2"
              matAutosizeMaxRows="10"
              matTextareaAutosize
              [formControl]="wellCreationForm.controls['hseRequirements']"
              saAutomatedSelector="well-creation__hse-requirements"
            >
            </textarea>
            <mat-hint align="end">{{ wellCreationForm.controls['hseRequirements'].value?.length || 0 }}/5000</mat-hint>
          </mat-form-field>
        </div>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              [formControl]="wellCreationForm.controls['allowedUsers']"
              placeholder="Subscribed Users"
              [compareWith]="compareByTypeAndId"
              multiple
              saAutomatedSelector="well-creation__subscribed-users"
            >
              <mat-select-trigger>
                <span
                  *ngFor="
                    let user of wellCreationForm.controls['allowedUsers'].value;
                    trackBy: trackById;
                    let i = index
                  "
                >
                  <span *ngIf="user.type === 'User'">
                    {{ getName(user, i, wellCreationForm.controls['allowedUsers'].value.length) }}
                  </span>
                </span>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Subscribed Users'"
                  [noEntriesFoundLabel]="'No User found'"
                  [formControl]="filterUser"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-optgroup *ngFor="let groupType of filteredUserOptions | async" [label]="groupType.type">
                <mat-option *ngFor="let user of groupType.users" [value]="user" (click)="userSelected(user)">
                  <span>{{ user.name }}</span>
                  <span *ngIf="user.sso" class="blue-pill"> <span>(</span>SSO<span>)</span> </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-hint
              >Select which <b>Standard</b> users should have access to this well in SANDi. <b>Admin</b> users have
              access to all wells by default.
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              formControlName="driverCertifications"
              multiple
              placeholder="Required Driver Certifications"
              [compareWith]="compareByValue"
            >
              <mat-option *ngFor="let dc of driverCertifications" [value]="dc">{{ dc.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              formControlName="trailerTypes"
              multiple
              placeholder="Required Trailer Types"
              [compareWith]="compareByValue"
            >
              <mat-option *ngFor="let tt of trailerTypes" [value]="tt">{{ tt.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h3 class="sub-form-heading">Reporting</h3>
        <ng-container *ngIf="isShaleAppsUser">
          <mat-checkbox class="form-field" [formControl]="wellCreationForm.get('boxMove')">
            Box Move
          </mat-checkbox>
        </ng-container>

        <mat-form-field class="form-field block-style">
          <input
            matInput
            [placeholder]="isHalAccount ? 'Billing Identifier' : 'Billing Identifier (Optional)'"
            type="text"
            [formControl]="wellCreationForm.controls['billingIdentifier']"
            saAutomatedSelector="well-creation__billing-identifier"
          />
          <mat-hint
            >This identifier will be added to each row in the .CSV exports of loads.Useful for adding things like the
            API Number, or other internal billing identifiers for this location.
          </mat-hint>
          <mat-error *ngIf="wellCreationForm.get('billingIdentifier').hasError('required')">
            Input is required
          </mat-error>
          <mat-error *ngIf="wellCreationForm.get('billingIdentifier').hasError('billingIdentifierLengthError')">
            Do not put a wrong value. The billing identifier is of 9 characters.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field email-wrap">
          <input
            matInput
            placeholder="Distribution List (emails, comma separated)"
            autocomplete="new-email"
            [formControl]="wellCreationForm.controls['emails']"
            saAutomatedSelector="well-creation__distribution-list"
          />
        </mat-form-field>
        <mat-form-field class="form-field">
          <mat-select
            placeholder="Distribution Frequency"
            [formControl]="wellCreationForm.controls['schedule']"
            saAutomatedSelector="well-creation__distribution-frequency"
          >
            <mat-option *ngFor="let schedule of schedules" [value]="schedule.key">{{ schedule.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="wellCreationForm.controls['schedule'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              [formControl]="wellCreationForm.controls['fracDownUsers']"
              placeholder="Frac Down SMS Users"
              [compareWith]="compareByIntValue"
              multiple
              saAutomatedSelector="well-creation__subscribed-lineup_users"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Frac Down SMS Users'"
                  [noEntriesFoundLabel]="'No User found'"
                  [formControl]="fracDownFilterUser"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option *ngFor="let user of fracDownFilteredUserOptions | async" [value]="user.id">
                <span>{{ user.name }}</span>
                <span *ngIf="user.ssoEnabled" class="blue-pill"> <span>(</span>SSO<span>)</span> </span>
              </mat-option>
            </mat-select>
            <mat-hint>Select which users will receive SMS when frac down is reported.</mat-hint>
          </mat-form-field>
        </div>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              [formControl]="wellCreationForm.controls['stageUpdateReminderUsers']"
              placeholder="Frac Stage Update Reminder"
              [compareWith]="compareByIntValue"
              multiple
              saAutomatedSelector="well-creation__subscribed-lineup_users"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Frac Stage Update Reminder'"
                  [noEntriesFoundLabel]="'No User found'"
                  [formControl]="stageUpdateFilterUser"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option *ngFor="let user of stageUpdateFilterOptions | async" [value]="user.id">
                <span>{{ user.name }}</span>
                <span *ngIf="user.ssoEnabled" class="blue-pill"> <span>(</span>SSO<span>)</span> </span>
              </mat-option>
            </mat-select>
            <mat-hint>Select which users will receive SMS when frac stage update reminder is sent.</mat-hint>
          </mat-form-field>
        </div>

        <h3 class="sub-form-heading" *ngIf="!isStandardUser">Location</h3>
        <sa-frac-creation-map
          *ngIf="!isStandardUser"
          [site]="this.well && this.well.site"
          style="height:700px; display:block;"
        ></sa-frac-creation-map>

        <div class="gps-direction-location-heading">
          <h3 class="sub-form-heading">GPS Direction Location</h3>
        </div>
        <div class="gps-direction-location-help">
          Use where the lease road begins. This location is provided to drivers for their use in getting directions from
          3rd party applications.
        </div>
        <div id="map-gps-direction-location"></div>
        <div>
          <mat-form-field class="form-field">
            <input
              matInput
              placeholder="Latitude"
              type="number"
              [formControl]="wellCreationForm.controls['gpsDirectionLocationLat']"
            />
            <mat-error *ngIf="wellCreationForm.controls['gpsDirectionLocationLat'].hasError('required')">
              Input is required
            </mat-error>
            <mat-error
              *ngIf="
                wellCreationForm.controls['gpsDirectionLocationLat'].hasError('latitude') &&
                !wellCreationForm.controls['gpsDirectionLocationLat'].hasError('required')
              "
            >
              Latitude value should be between -90 and 90
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field">
            <input
              matInput
              placeholder="Longitude"
              type="number"
              [formControl]="wellCreationForm.controls['gpsDirectionLocationLng']"
            />
            <mat-error *ngIf="wellCreationForm.controls['gpsDirectionLocationLng'].hasError('required')">
              Input is required
            </mat-error>
            <mat-error
              *ngIf="
                wellCreationForm.controls['gpsDirectionLocationLng'].hasError('longitude') &&
                !wellCreationForm.controls['gpsDirectionLocationLng'].hasError('required')
              "
            >
              Longitude value should be between -180 and 180
            </mat-error>
          </mat-form-field>
        </div>
        <div class="direction">
          <mat-form-field class="form-field">
            <textarea
              matInput
              placeholder="Directions to Well"
              maxlength="5000"
              matAutosizeMinRows="2"
              matAutosizeMaxRows="10"
              matTextareaAutosize
              [formControl]="wellCreationForm.controls['direction']"
              saAutomatedSelector="well-creation__directions"
            >
            </textarea>
            <mat-hint align="end">{{ wellCreationForm.controls['direction'].value?.length || 0 }}/5000</mat-hint>
          </mat-form-field>
          <mat-form-field class="form-field" *ngIf="isShaleAppsUser">
            <mat-label>Entrance Speed Limit</mat-label>
            <input type="number" matInput [formControl]="wellCreationForm.controls['entranceSpeed']" />
          </mat-form-field>
        </div>

        <div class="storages" *ngIf="!isStandardUser">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <h3>Storage Capacity at Well</h3>
            <a
              *ngIf="canEdit && !well?.createdByAPI"
              (click)="openStorageForm(null)"
              saAutomatedSelector="well-creation__add-storage-button"
              >Add New</a
            >
          </div>
          <mat-checkbox class="form-field" [formControl]="wellCreationForm.get('autoStorageUpdate')">
            Update Storage Via API
          </mat-checkbox>
          <mat-list>
            <mat-list-item *ngFor="let storage of wellCreationForm.controls['storages'].value; let i = index">
              {{ storage.name }} - {{ storage.mesh.type }} Mesh
              <div class="edit-delete">
                <a
                  *ngIf="canEdit"
                  saAutomatedSelector="well-creation__edit-storage-button"
                  (click)="openStorageForm(storage)"
                  >Edit</a
                >
                <ng-container *saFeatureFlag="'manageSupplementalMeshes'">
                  <a
                    *ngIf="canEdit"
                    saAutomatedSelector="well-creation__manage-supplemental-meshes-button"
                    (click)="openStorageSupplementalMeshForm(storage)"
                    >Manage Supplemental Meshes</a
                  >
                </ng-container>
                <a
                  *ngIf="canEdit && !well?.createdByAPI"
                  saAutomatedSelector="well-creation__delete-storage-button"
                  (click)="removeStorage(i)"
                  >Delete</a
                >
              </div>
            </mat-list-item>
          </mat-list>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end" class="action-buttons" style="overflow: auto">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="next-btn"
            saAutomatedSelector="well-creation__next-button"
            [disabled]="isStandardUser"
          >
            Next
          </button>
        </div>
        <br /><br />
      </form>
    </div>

    <div class="schedule-form" *ngIf="displayFracSchedule">
      <h2 fxLayout="row" fxLayoutAlign="center">Frac Schedule</h2>

      <div class="table-header" fxHide.lt-md>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <span fxFlex.lt-md="100%"></span>
          <span *ngFor="let storage of wellCreationForm.controls['storages'].value" fxFlex.lt-md="calc(50% - 16px)"
            >{{ storage.mesh.type }} Mesh Volume (lbs)</span
          >
          <span fxFlex.lt-md="calc(50% - 16px)">Pump Time (mins)</span>
          <span fxFlex.lt-md="calc(50% - 16px)">Time Between Stages (mins)</span>
          <span style="width: 16px"></span>
        </div>

        <hr />
      </div>

      <form
        *ngIf="wellScheduleForm"
        [formGroup]="wellScheduleForm"
        (submit)="validateScheduleInput(true)"
        autocomplete="off"
        class="well-schedule-form"
      >
        <div
          *ngFor="let stage of stages; let i = index"
          fxLayout="row"
          fxLayoutAlign="space-between"
          class="stage-container"
        >
          <span fxFlex.lt-md="100%" class="stage">Stage {{ i + 1 }}</span>

          <div
            fxFlex.lt-md="calc(50% - 16px)"
            style="display: inline-block"
            *ngFor="let storage of wellCreationForm.controls['storages'].value"
          >
            <input
              type="text"
              sa-number-input
              required
              [placeholder]="storage.mesh.type + 'Mesh (lbs)'"
              [ngClass]="{
                'input-error': wellScheduleForm.controls[i + ' ' + storage.mesh.type].invalid && formSubmitted
              }"
              [formControl]="wellScheduleForm.controls[i + ' ' + storage.mesh.type]"
              saAutomatedSelector="well-creation__schedule-mesh-volume"
              [automatedName]="storage.mesh.type"
            />
          </div>

          <div fxFlex.lt-md="calc(50% - 16px)" style="display: inline-block">
            <input
              required
              type="number"
              placeholder="Pump Time (mins)"
              [ngClass]="{ 'input-error': wellScheduleForm.controls[i + ' pumpTime'].invalid && formSubmitted }"
              [formControl]="wellScheduleForm.controls[i + ' pumpTime']"
              [value]="wellScheduleForm.controls[i + ' pumpTime'].value"
              saAutomatedSelector="well-creation__schedule-pump-time"
            />
          </div>

          <div fxFlex.lt-md="calc(50% - 16px)" style="display: inline-block">
            <input
              required
              type="number"
              placeholder="Time Between Stages (mins)"
              [ngClass]="{ 'input-error': wellScheduleForm.controls[i + ' downTime'].invalid && formSubmitted }"
              [formControl]="wellScheduleForm.controls[i + ' downTime']"
              [value]="wellScheduleForm.controls[i + ' downTime'].value"
              saAutomatedSelector="well-creation__schedule-down-time"
            />
          </div>

          <div>
            <i class="material-icons" [matMenuTriggerFor]="menu" saAutomatedSelector="well-creation__schedule-copy-menu"
              >content_copy</i
            >
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="copyIntoNRowsAbove($event)"
                *ngIf="i > 1"
                saAutomatedSelector="well-creation__schedule-copy-into-n-below"
              >
                <span *ngIf="!copyNRowsAboveEdit">Copy Into Every Nth Row Above</span>
                <input
                  *ngIf="copyNRowsAboveEdit"
                  #nRowsEditNum
                  matInput
                  placeholder="Copy Into Every Nth Row Above"
                  type="number"
                  (keyup.enter)="enterCopyNRowsAbove(nRowsEditNum.value, i)"
                />
              </button>
              <button
                mat-menu-item
                (click)="copyIntoPreviousRow(i)"
                *ngIf="i !== 0"
                saAutomatedSelector="well-creation__schedule-copy-into-previous"
              >
                Copy Into Previous
              </button>
              <button
                mat-menu-item
                (click)="copyIntoAllPreviousRows(i)"
                *ngIf="i !== 0"
                saAutomatedSelector="well-creation__schedule-copy-into-all-above"
              >
                Copy Into All Above
              </button>
              <button
                mat-menu-item
                (click)="copyIntoAllRows(i)"
                *ngIf="i !== 0"
                saAutomatedSelector="well-creation__schedule-copy-into-all"
              >
                Copy Into All
              </button>
              <button
                mat-menu-item
                (click)="copyIntoNextRow(i)"
                *ngIf="i !== stages.length - 1"
                saAutomatedSelector="well-creation__schedule-copy-into-next"
              >
                Copy Into Next
              </button>
              <button
                mat-menu-item
                (click)="copyIntoAllNextRows(i)"
                *ngIf="i !== stages.length - 1"
                saAutomatedSelector="well-creation__schedule-copy-into-all-below"
              >
                Copy Into All Below
              </button>
              <button
                mat-menu-item
                (click)="copyIntoEveryOtherRows(i)"
                *ngIf="i < stages.length - 2"
                saAutomatedSelector="well-creation__schedule-copy-into-every-other-below"
              >
                Copy Into Every Other Below
              </button>
              <button mat-menu-item (click)="copyPumpTimeIntoAllRowsBelow(i)" *ngIf="i < stages.length - 1">
                Copy Pump Time to All Below
              </button>
              <button mat-menu-item (click)="copyDownTimeIntoAllRowsBelow(i)" *ngIf="i < stages.length - 1">
                Copy Time Between Stages to All Below
              </button>
              <button
                mat-menu-item
                (click)="copyIntoNRowsBelow($event)"
                *ngIf="i < stages.length - 3"
                saAutomatedSelector="well-creation__schedule-copy-into-n-below"
              >
                <span *ngIf="!copyNRowsBelowEdit">Copy Into Every Nth Row Below</span>
                <input
                  *ngIf="copyNRowsBelowEdit"
                  #nRowsEditNum
                  matInput
                  placeholder="Copy Into Every Nth Row Below"
                  type="number"
                  (keyup.enter)="enterCopyNRowsBelow(nRowsEditNum.value, i)"
                />
              </button>
            </mat-menu>
          </div>
        </div>

        <hr />

        <div class="table-footer" fxHide.lt-md>
          <div class="stage" fxLayout="row" fxLayoutAlign="space-between">
            <span fxFlex.lt-md="100%">Totals</span>
            <span *ngFor="let storage of wellCreationForm.controls['storages'].value" fxFlex.lt-md="calc(50% - 16px)">
              {{ numberWithCommas(totalVolumesByMesh[storage.mesh.type]) }} lbs
            </span>
            <span fxFlex.lt-md="calc(50% - 16px)">=</span>
            <div class="total" fxLayout="row" fxLayoutAlign="end">
              {{ numberWithCommas(totalVolumesByMesh['totalVolume']) }} lbs
            </div>
            <span></span>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" class="action-buttons" style="overflow: auto">
          <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="backFromFracSchedule()"
            [disabled]="submitting"
          >
            Back
          </button>
          <button
            mat-flat-button
            color="primary"
            type="submit"
            saAutomatedSelector="well-creation__save-button"
            [disabled]="submitting"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
