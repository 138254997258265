interface HasName {
  name: string;
}

// Keep a record of all the names we have lowercased
const lowerCaseNameMap: Record<string, string> = {};
export function sortByName<T extends HasName>(a: T, b: T): number {
  if (!lowerCaseNameMap[a.name]) {
    lowerCaseNameMap[a.name] = a.name.toLocaleLowerCase();
  }
  if (!lowerCaseNameMap[b.name]) {
    lowerCaseNameMap[b.name] = b.name.toLocaleLowerCase();
  }
  return lowerCaseNameMap[a.name].localeCompare(lowerCaseNameMap[b.name]);
}

export function sortByAlpha<T>(keys: Array<keyof T>) {
  if (keys.length === 0) {
    return (_a: T, _b: T) => 0;
  }
  let nextKeySorter: (a: T, b: T) => number;
  const key = keys[0];
  if (keys.length > 1) {
    nextKeySorter = sortByAlpha(keys.slice(1));
  }
  return function(a: T, b: T): number {
    const aValue = getLowerCaseMapValue(a[key]);
    const bValue = getLowerCaseMapValue(b[key]);
    if (aValue && bValue) {
      const result = aValue.localeCompare(bValue);
      if (result === 0 && nextKeySorter) {
        return nextKeySorter(a, b);
      }
      return result;
    }
    return aValue ? -1 : bValue ? 1 : nextKeySorter ? nextKeySorter(a, b) : 0;
  };
}

function getLowerCaseMapValue(value: any): string {
  if (!value) {
    return null;
  }
  const valueString = typeof value === 'string' ? value : value.toString();
  if (!lowerCaseNameMap[valueString]) {
    lowerCaseNameMap[valueString] = valueString.toLocaleLowerCase();
  }
  return lowerCaseNameMap[valueString];
}
