import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, take, map } from 'rxjs/operators';
import { Order, UploadedBillingFile } from '~models/order.model';
import { AccountFeatureFlagsService } from '~services/account-feature-flags.service';
import { OrderApiService } from '~services/api/order.api.service';
import { UserApiService } from '~services/api/user.api.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { MapUtilService } from '~services/map-util.service';
import { RouterStateService } from '~services/router-state.service';
import { StoreService } from '~services/store.service';
import { ChoiceDialogComponent } from '../ui-components/choice-dialog/choice-dialog.component';
import { LogisticsRunboardService } from '~services/logistics-runboard.service';
import * as fromRouterConstants from '../app-routing.constants';
import { UserStatusWithExtra } from '../ui-components/jobs-view/frac-detail/runboard-summary.model';
import { Observable } from 'rxjs';
import { FileUploadService } from '~services/api/file-upload.service';
import { UserService } from '~services/user.service';

@Component({
  selector: 'sa-logistics-runboard-driver-options',
  styleUrls: ['./logistics-runboard-driver-options.component.scss'],
  templateUrl: './logistics-runboard-driver-options.component.html',
})
export class LogisticsRunboardDriverOptionsComponent implements OnInit {
  uploading = '';
  private fileToUpload: File;
  showSection = 1;
  private fileTypesWithUrl = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png'];
  private allowedFileTypes = ['pdf', 'svg', 'png', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt'];
  public fileLoading = false;
  public selectedFileName = '';
  public previewFileUrl = '';
  public bolAttachments$: Observable<UploadedBillingFile[]>;
  public truckTicketAttachments$: Observable<UploadedBillingFile[]>;
  public otherAttachments$: Observable<UploadedBillingFile[]>;
  public selectedDriver$: Observable<UserStatusWithExtra>;
  public endingShiftInProgress = false;
  public isShaleApps = this.userService.isShaleappsEmail();

  public currentHOSCalculation: {
    input: string;
    result: number;
  };

  constructor(
    private logisticsRunboardService: LogisticsRunboardService,
    private userApiService: UserApiService,
    private routerStateService: RouterStateService,
    private snackBar: MatSnackBar,
    private errorService: ErrorHandlingService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private mapUtilService: MapUtilService,
    private orderApiService: OrderApiService,
    private store: StoreService,
    private errorHandler: ErrorHandlingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private location: Location,
    private fileUploadService: FileUploadService,
    private userService: UserService,
  ) {}

  public ngOnInit() {
    this.selectedDriver$ = this.logisticsRunboardService.selectedDriver$;
    function filterImages(categoryName: string) {
      return (driver: UserStatusWithExtra) => {
        if (driver.selectedOrder) {
          const uploadedFiles: UploadedBillingFile[] = driver.selectedOrder.uploadedFiles || [];
          return uploadedFiles.filter((file) => file.category === categoryName);
        }
        return [];
      };
    }
    this.bolAttachments$ = this.selectedDriver$.pipe(map(filterImages('bol_image')));

    this.truckTicketAttachments$ = this.selectedDriver$.pipe(map(filterImages('ticket_image')));

    this.otherAttachments$ = this.selectedDriver$.pipe(map(filterImages('other_image')));
  }

  public getHoursLeftOnShift(shiftEndTimestamp: string): number {
    if (!this.currentHOSCalculation || this.currentHOSCalculation.input !== shiftEndTimestamp) {
      if (shiftEndTimestamp) {
        const now = new Date().getTime();
        const created = new Date(shiftEndTimestamp).getTime();
        const result = Math.max(Math.round((created - now) / 1000 / 60 / 60), 0);
        this.currentHOSCalculation = {
          input: shiftEndTimestamp,
          result,
        };
      } else {
        this.currentHOSCalculation = {
          input: shiftEndTimestamp,
          result: 0,
        };
      }
    }
    return this.currentHOSCalculation.result;
  }

  public endDriverShift() {
    this.endingShiftInProgress = true;
    this.routerStateService.routerState$
      .pipe(
        take(1),
        switchMap((routerState) => {
          const userId = routerState.params[fromRouterConstants.LOGISTICS_CLUSTER_USER_ID];
          return this.userApiService.updateHOSv2(+userId, 0);
        }),
      )
      .subscribe(
        () => {
          this.snackBar.open(`Removed from Driver Pool`, null, { duration: 5000 });
          // Probably not needed since we are closing
          this.endingShiftInProgress = false;
          this.logisticsRunboardService.loadLogisticsRunboardSummary();
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        (error) => {
          this.endingShiftInProgress = false;
          this.errorService.showError(error);
        },
      );
  }

  public updateHOS(matSelectResult: MatSelectChange) {
    this.routerStateService.routerState$
      .pipe(
        take(1),
        switchMap((routerState) => {
          const userId = routerState.params[fromRouterConstants.LOGISTICS_CLUSTER_USER_ID];
          return this.userApiService.updateHOSv2(+userId, matSelectResult.value * 60);
        }),
      )
      .subscribe(
        () => {
          // Needed until we get sockets working for runboard service
          this.logisticsRunboardService.loadLogisticsRunboardSummary();
          this.snackBar.open(`Hours Updated`, null, { duration: 5000 });
        },
        (error) => {
          this.errorService.showError(error);
        },
      );
  }

  public calculateReceivingTime(timestamp) {
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    const secDiff = Math.round((now - created) / 1000);
    if (secDiff < 0) {
      return '--';
    }
    const minDiff = Math.round(secDiff / 60);
    const hoursDiff = Math.round(minDiff / 60);
    const daysDiff = Math.round(hoursDiff / 24);
    if (daysDiff >= 1) {
      if (daysDiff > 1) {
        return `${daysDiff} days`;
      } else {
        return `${daysDiff} day`;
      }
    }
    if (hoursDiff >= 1) {
      if (hoursDiff > 1) {
        return `${hoursDiff} hours`;
      } else {
        return `${hoursDiff} hour`;
      }
    }
    if (minDiff >= 0) {
      if (minDiff > 1) {
        return `${minDiff} minutes`;
      } else {
        return `${minDiff} minute`;
      }
    }
    if (secDiff > 1) {
      return `${secDiff} seconds`;
    } else {
      return `${secDiff} second`;
    }
  }

  public sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`sms:${url}`);
  }

  public editLoad() {
    this.showSection = 2;
  }

  public swapDrivers() {
    this.showSection = 3;
    // this.mapUtilService.updateMap({ frac: selectedFrac, state: 10, order: selectedOrder });
  }

  public unassignOrder(selectedOrder) {
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Unassign Driver',
        desc: `This will remove ${selectedOrder.user.name} from this order. This will move the order back to the Pending list where you can decline, edit, or reassign the order.`,
        button: ['Cancel', 'Unassign Driver'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.unAssignOrderById(selectedOrder.id).subscribe(
          (res) => {
            this.logisticsRunboardService.loadLogisticsRunboardSummary();
            this.snackBar.open('Successfully Unassigned', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
            this.goBack();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  public completeOrder(selectedOrder) {
    let mainText = '';
    let secondaryText = '';
    if (selectedOrder.etaStatus !== 'arrived') {
      mainText = 'Wait for Driver to Arrive at the Well Geofence?';
      secondaryText = `
        This driver's current ETA to the well is
        ${this.calculateEta(selectedOrder.eta)}.
          If you complete this order, you will lose their in & out time at the well.
          Please wait for the driver to depart the well and SANDi will automatically complete the load for you.`;
    } else {
      mainText = 'Wait for Driver to Leave the Well Geofence?';
      secondaryText = `
      This driver's is currently on location.
        If you complete this order, you will lose their in & out time.
        Please wait for the driver to depart the well and SANDi will automatically complete the load for you once they are 1 mile away.`;
    }
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '50%',
      maxWidth: '968px',
      data: {
        context: mainText,
        desc: secondaryText,
        button: ['Wait for Driver', 'Complete Anyway'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.completeOrderById(selectedOrder.id).subscribe(
          (res) => {
            // Needed until we get sockets working for runboard service
            this.logisticsRunboardService.loadLogisticsRunboardSummary();
            this.snackBar.open('Successfully Completed', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
            this.goBack();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  public acceptForDriver(selectedOrder) {
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Accept Dispatch for Driver',
        desc: 'Are you sure?',
        button: ['Cancel', 'Accept'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.acceptForDriver(selectedOrder).subscribe(
          (res) => {
            this.store.setOrder(res);
            // Needed until we get sockets working for runboard service
            this.logisticsRunboardService.loadLogisticsRunboardSummary();
            this.snackBar.open('Successfully Accepted', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  public markAsLoaded(selectedOrder: Order) {
    const payload = { loaded: true };
    this.store.patchOrder(selectedOrder.id, payload).subscribe();
  }

  public goBack() {
    this.location.back();
  }

  public onBack() {
    this.showSection = 1;
  }

  public setDriver(driver, driverDetails) {
    const body = {
      userId: driver.id,
    };
    this.orderApiService.swapDrivers(driverDetails.selectedOrder.id, body).subscribe(
      (res) => {
        this.snackBar.open('Driver Swapped Successfully', null, {
          duration: 5000,
          panelClass: ['snackbar-success'],
        });
        let totalFracs: any;
        this.store
          .select<Array<any>>('fracs')
          .pipe(
            filter((_) => _.length > 0),
            take(1),
          )
          .subscribe((fracs) => {
            totalFracs = fracs;
          }),
          totalFracs.forEach((frac) => {
            if (frac.id === driverDetails.selectedOrder.fracId) {
              for (let i = 0; i < frac.orders.length; i++) {
                if (frac.orders[i].id === driverDetails.selectedOrder.id) {
                  frac.orders[i] = res;
                }
              }
            }
          });
        this.store.set('fracs', totalFracs);
        this.router.navigate(['../' + driver.id], { relativeTo: this.route, replaceUrl: true });
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  public calculateEta(minutes) {
    if (!minutes) {
      minutes = 0;
    }
    return this.datePipe.transform(new Date(new Date().getTime() + minutes * 60000), 'shortTime');
  }

  public onFileChange(event, currentOrderId: number, category: string) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileToUpload = file;
      this.uploadFile(currentOrderId, category);
    }
  }

  private uploadFile(currentOrderId: number, category: string) {
    this.uploading = category;
    this.fileUploadService.uploadFile(currentOrderId, this.fileToUpload, category).subscribe(
      (result: UploadedBillingFile[]) => {
        this.uploading = '';
        this.snackBar.open('File uploaded Successfully', null, {
          duration: 5000,
        });
        this.store.loadSingleOrderByOrderId(currentOrderId);
      },
      (err) => {
        console.error(err, 'Upload Failed', currentOrderId, this.fileToUpload);
        this.uploading = '';
        this.errorHandler.showError(`Upload Failed`, 5000);
      },
    );
  }

  public downloadFile(file: UploadedBillingFile) {
    this.fileLoading = true;
    this.selectedFileName = file.fileName;
    this.fileUploadService.downloadFile(file.id, file.orderId).subscribe(
      (url) => {
        const fileNameArray = file.fileName.toLowerCase().split('.');
        if (this.fileTypesWithUrl.includes(fileNameArray[fileNameArray.length - 1])) {
          this.previewFileUrl = `${url}`;
          window.open(this.previewFileUrl, '_blank');
        } else {
          this.previewFileUrl = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
          window.open(this.previewFileUrl, '_blank');
        }
        this.fileLoading = false;
      },
      (err) => {
        this.fileLoading = false;
        this.errorHandler.showError('File Download Failed', 5000);
      },
    );
  }

  public smallFileName(name: string) {
    const parts = name.split('/');
    return parts[parts.length - 1];
  }

  public getFileIcon(fileName: string) {
    const fileNameArray = fileName.toLowerCase().split('.');
    let extension = fileNameArray[fileNameArray.length - 1];
    if (this.allowedFileTypes.includes(extension)) {
      if (extension === 'xlsx') {
        extension = 'xls';
      }
      if (extension === 'docx') {
        extension = 'doc';
      }
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      return `assets/icons/${extension}.svg`;
    } else {
      return `assets/icons/other.png`;
    }
  }

  public deleteFile(file: UploadedBillingFile, event) {
    event.stopPropagation();
    const parts = file.fileName.split('/');
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      data: {
        button: ['Cancel', 'Remove'],
        context: `Are you sure you want to remove ${parts[parts.length - 1]} ?`,
        desc: '',
      },
      maxWidth: '968px',
      width: '30%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileUploadService.removeFile(file.id, file.orderId).subscribe(
          (files) => {
            this.snackBar.open('File Removed Successfully', null, {
              duration: 5000,
            });
            this.store.loadSingleOrderByOrderId(file.orderId);
            this.selectedFileName = '';
            this.previewFileUrl = '';
          },
          (err) => {
            this.errorHandler.showError('File Removing Failed', 5000);
          },
        );
      }
    });
  }
}
