import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptorService implements HttpInterceptor {
  constructor(private deviceService: DeviceDetectorService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.deviceService.browser === 'IE' && req.method === 'GET') {
      const httpRequest = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'),
      });
      return next.handle(httpRequest);
    }
    return next.handle(req);
  }
}
