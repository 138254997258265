import { Injectable } from '@angular/core';
import { environment } from '~environments/environment';
import { HttpClient } from '@angular/common/http';
import { BillingSettings, Charge, ChargeType, IFracBillingRawSummary, UserSettings } from '~billing/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private get settingsBaseRoute(): string {
    return `${environment.api}/billing/lmo`;
  }

  private get chargeTypeBaseRoute(): string {
    return `${environment.api}/billing/lmo/charge_type`;
  }

  constructor(private http: HttpClient) {}

  public loadSettings(lmoId: number) {
    return this.http.get<BillingSettings>(`${this.settingsBaseRoute}/settings/${lmoId}`);
  }

  public updateSettings(lmoId: number, body) {
    return this.http.post<BillingSettings>(`${this.settingsBaseRoute}/settings/${lmoId}`, body);
  }

  public updateUserSettings(body) {
    return this.http.post<UserSettings>(`${this.settingsBaseRoute}/user_permissions`, body);
  }

  public loadChargeTypes(lmoId: number) {
    return this.http.get<ChargeType[]>(`${this.chargeTypeBaseRoute}/${lmoId}`);
  }

  public addChargeType(lmoId: number, body) {
    return this.http.post<ChargeType>(`${this.chargeTypeBaseRoute}/${lmoId}`, body);
  }

  public updateChargeType(chargeId: number, body: ChargeType) {
    return this.http.put<ChargeType>(`${this.chargeTypeBaseRoute}/${chargeId}`, body);
  }

  public deleteChargeType(chargeId: number) {
    return this.http.delete(`${this.chargeTypeBaseRoute}/${chargeId}`);
  }

  public markDefaultChargeType(lmoId: number) {
    return this.http.put<ChargeType>(`${this.chargeTypeBaseRoute}/mark_default/${lmoId}`, {});
  }
}
