import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { RoadRestrictionApiResponse, toLocalRestriction } from 'src/app/restrictions/toLocalRestriction';
import { Restriction } from 'src/app/restrictions/types';
import { environment } from '~environments/environment';
import {
  DeadheadLinkableOrder,
  DiversionRoutes,
  Order,
  OrderCreate,
  OrderDCDropoffConfirmation,
  OrderDCPickupConfirmation,
  OrderDetailsUpdate,
  OrderDetailsVerify,
  OrderStatus,
} from '~lmo/models/order.model';
import { AuthService } from '~services/auth.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { RouterStateService } from '~services/router-state.service';
import { UserService } from '~services/user.service';
import * as fromRouterConstants from '../lmo-routing.constants';

const GROUP_BY_DESTINATION = 'group_by_destination';

@Injectable({
  providedIn: 'root',
})
export class LmoOrdersService {
  private currentOrder$$: BehaviorSubject<Order> = new BehaviorSubject(null);
  private currentOrderRestrictions$$ = new BehaviorSubject<Restriction[]>(null);
  private orders$$: BehaviorSubject<Record<number, Order>> = new BehaviorSubject<Record<number, Order>>({});
  private pendingOrders$$: BehaviorSubject<Order[]> = new BehaviorSubject([]);
  private inProgressOrders$$: BehaviorSubject<Order[]> = new BehaviorSubject([]);
  private groupByDestination$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private lastOrderUpdate$$: Subject<Order[]> = new Subject<Order[]>();
  private loHiSitesForBoxPickup$$ = new BehaviorSubject<{ id: number; name: string }[]>([]);
  private loHiSitesForBoxPickupThrottle$ = new Subject();
  private loHiSitesForBoxPickupShared$ = this.loHiSitesForBoxPickup$$.pipe(shareReplay(1));

  public get loHiSitesForBoxPickup$() {
    this.loHiSitesForBoxPickupThrottle$.next();
    return this.loHiSitesForBoxPickupShared$;
  }

  public get pendingOrders$(): Observable<Order[]> {
    return this.pendingOrders$$.asObservable();
  }

  public get inProgress$(): Observable<Order[]> {
    return this.inProgressOrders$$.asObservable();
  }

  public get groupByDestination$(): Observable<boolean> {
    return this.groupByDestination$$.asObservable();
  }

  public get currentOrder$(): Observable<Order> {
    return this.currentOrder$$.asObservable();
  }

  public get currentOrderRestrictions$(): Observable<Restriction[]> {
    return this.currentOrderRestrictions$$.asObservable();
  }

  public get lastOrderUpdate$(): Observable<Order[]> {
    return this.lastOrderUpdate$$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private routerState: RouterStateService,
    private errorService: ErrorHandlingService,
    private snackBar: MatSnackBar,
    private userService: UserService,
  ) {
    const groupByDestination = localStorage.getItem(GROUP_BY_DESTINATION);
    if (groupByDestination !== null) {
      this.groupByDestination$$.next(JSON.parse(groupByDestination));
    }
    this.logout = this.logout.bind(this);
    this.setupAuthListener();
    this.setupPendingAndInProgress();
    this.subscribeToRouterFracIdChanges();
    this.subscribeToRouterDistributionCenterIdChanges();
    this.subscribeToRouterOrderIdChanges();
    this.loHiSitesForBoxPickupThrottle$.pipe(throttleTime(1000)).subscribe(() => {
      this.loadLoHiSites();
    });
  }

  public setGroupByDestination(groupByDestination: boolean) {
    this.groupByDestination$$.next(groupByDestination);
    localStorage.setItem(GROUP_BY_DESTINATION, JSON.stringify(groupByDestination));
  }

  // Will use current order if an id is not passed in
  public updateLoad$(update: OrderDetailsUpdate, orderId?: string): Observable<boolean> {
    const id = orderId ? orderId : this.currentOrder$$.value.id;
    if (!id) {
      this.errorService.showError('Error updating order, order ID is not set', 5000);
      return of(false);
    }
    return this.http.patch<Order>(`${environment.api}/order/${id}/details`, update).pipe(
      map((order) => {
        this.lastOrderUpdate$$.next([order]);
        if (order.id === this.currentOrder$$.value.id) {
          this.currentOrder$$.next(order);
        }
        this.orders$$.next({
          ...this.orders$$.value,
          [order.id]: order,
        });
        this.success('Order updated');
        return true;
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public toggleOrderDataLocked$(orderId: number, locked: boolean): Observable<any> {
    return this.http.post(`${environment.api}/order/data_lock`, { orderId, locked }).pipe(
      tap((_) => {
        this.getOrder(orderId + '');
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public cancelOrders$(cancelReason: string, orderIdsToCancel?: number[], skipLoaded = false): Observable<boolean> {
    const orderIds = orderIdsToCancel ? orderIdsToCancel : [this.currentOrder$$.value.id];
    if (orderIds.length === 0) {
      this.errorService.showError('Error cancelling order(s), order IDs not set', 5000);
      return of(false);
    }

    const payload = {
      cancelReason,
      orderIds,
      skipLoaded,
    };
    return this.http.post<Order[]>(`${environment.api}/v2/orders/cancel`, payload).pipe(
      map((orders) => {
        this.lastOrderUpdate$$.next(orders);
        const currentOrders = { ...this.orders$$.value };
        const currentOrderId = this.currentOrder$$.value && this.currentOrder$$.value.id;
        orders.forEach((order) => {
          currentOrders[order.id] = order;
          if (order.id === currentOrderId) {
            this.currentOrder$$.next(order);
          }
        });
        this.orders$$.next(currentOrders);
        this.success('Orders successfully cancelled');
        return true;
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public completeOrders$(orderId: number): Observable<boolean> {
    return this.http.post<Order>(`${environment.api}/v2/order/${orderId}/complete`, {}).pipe(
      map((updatedOrder) => {
        const orders = [updatedOrder];
        this.lastOrderUpdate$$.next(orders);
        const currentOrders = { ...this.orders$$.value };
        const currentOrderId = this.currentOrder$$.value && this.currentOrder$$.value.id;
        orders.forEach((order) => {
          currentOrders[order.id] = order;
          if (order.id === currentOrderId) {
            this.currentOrder$$.next(order);
          }
        });
        this.orders$$.next(currentOrders);
        this.success('Order successfully completed');
        return true;
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public orderDiversionRoutes$(orderId: number): Observable<DiversionRoutes[]> {
    return this.http.get(`${environment.api}/possible_divert_routes/${orderId}`) as Observable<DiversionRoutes[]>;
  }

  public divertOrder(orderId: number, diversionData) {
    return this.http.post(`${environment.api}/reroute_order/${orderId}`, diversionData);
  }

  public verifyOrder(verifyData: OrderDetailsVerify) {
    const currentOrder = this.currentOrder$$.value;
    return this.http.post<Order>(`${environment.api}/order/${currentOrder.id}/verify`, verifyData).pipe(
      map((order) => {
        this.lastOrderUpdate$$.next([order]);
        if (order.id === this.currentOrder$$.value.id) {
          this.currentOrder$$.next(order);
        }
        this.orders$$.next({
          ...this.orders$$.value,
          [order.id]: order,
        });
        this.success('Order verified');
        return true;
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public confirmOrderDCDropoff(confirmationData: OrderDCDropoffConfirmation) {
    const currentOrder = this.currentOrder$$.value;
    return this.http
      .post<Order>(`${environment.api}/order/${currentOrder.id}/confirm_dc_delivery`, confirmationData)
      .pipe(
        map((order) => {
          this.lastOrderUpdate$$.next([order]);
          if (order.id === this.currentOrder$$.value.id) {
            this.currentOrder$$.next(order);
          }
          this.orders$$.next({
            ...this.orders$$.value,
            [order.id]: order,
          });
          this.success('Order dropoff at DC confirmed');
          return true;
        }),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  public confirmOrderDCPickup(confirmationData: OrderDCPickupConfirmation) {
    const currentOrder = this.currentOrder$$.value;
    return this.http
      .post<Order>(`${environment.api}/order/${currentOrder.id}/confirm_dc_pickup`, confirmationData)
      .pipe(
        map((order) => {
          this.lastOrderUpdate$$.next([order]);
          if (order.id === this.currentOrder$$.value.id) {
            this.currentOrder$$.next(order);
          }
          this.orders$$.next({
            ...this.orders$$.value,
            [order.id]: order,
          });
          this.success('Order pickup from DC confirmed');
          return true;
        }),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  public placeOrders$(orderToCreate: OrderCreate[]): Observable<boolean> {
    if (orderToCreate.length === 0) {
      this.errorService.showError('No orders to create, array is empty', 5000);
      return of(false);
    }
    return this.http.post<Order[]>(`${environment.api}/order`, orderToCreate).pipe(
      map((orders) => {
        this.lastOrderUpdate$$.next(orders);
        const currentOrders = { ...this.orders$$.value };
        const currentOrderId = this.currentOrder$$.value && this.currentOrder$$.value.id;
        orders.forEach((order) => {
          currentOrders[order.id] = order;
          if (order.id === currentOrderId) {
            this.currentOrder$$.next(order);
          }
        });
        this.orders$$.next(currentOrders);
        this.success('Orders successfully placed');
        return true;
      }),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public reassignVendor$(newVendorId: number, newSubcontractorId: string): Observable<boolean> {
    const currentOrder = this.currentOrder$$.value;
    return this.http
      .put<Order>(
        `${environment.api}/order/${currentOrder.id}/reassign`,
        {},
        { params: { vendorId: `${newVendorId}`, subcontractorId: newSubcontractorId } },
      )
      .pipe(
        // The response from the call is garbage
        switchMap(() => this.http.get<Order>(`${environment.api}/order/${currentOrder.id}`)),
        tap((order) => {
          this.lastOrderUpdate$$.next([order]);
          if (order.id === this.currentOrder$$.value.id) {
            this.currentOrder$$.next(order);
          }
          this.orders$$.next({
            ...this.orders$$.value,
            [order.id]: order,
          });
        }),
        tap(() => {
          this.success('Trucking Vendor Updated');
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  public rateDriver$(rating: number, customerNote: string): Observable<boolean> {
    const currentOrder = this.currentOrder$$.value;
    return this.http
      .post<Order>(`${environment.api}/order/${currentOrder.id}/submit_rating`, {
        customerNote,
        rating,
      })
      .pipe(
        tap((order) => {
          this.lastOrderUpdate$$.next([order]);
          if (order.id === this.currentOrder$$.value.id) {
            this.currentOrder$$.next(order);
          }
          this.orders$$.next({
            ...this.orders$$.value,
            [order.id]: order,
          });
        }),
        tap(() => {
          this.success('Rating Submitted');
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  private success(message: string) {
    this.snackBar.open(message, null, { duration: 5000 });
  }

  private setupAuthListener() {
    this.authService.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        filter((isLoggedIn) => !isLoggedIn),
      )
      .subscribe(this.logout);
  }

  private setupPendingAndInProgress() {
    const pendingOrderStatuses: OrderStatus[] = ['pending', 'driver_rejected', 'declined'];
    const inProgressOrderStatuses: OrderStatus[] = ['dispatched', 'driver_accepted'];
    this.orders$$.subscribe((orders) => {
      const pendingOrders: Order[] = [];
      const inProgressOrders: Order[] = [];
      const fracLoadRecord = {};
      Object.keys(orders)
        .map((key) => orders[key] as Order)
        .forEach((order) => {
          fracLoadRecord[`${order.fracId}:${order.loadNumber}`] = order.id;
          if (pendingOrderStatuses.includes(order.orderStatus)) {
            pendingOrders.push(order);
          } else if (inProgressOrderStatuses.includes(order.orderStatus)) {
            inProgressOrders.push(order);
          }
        });
      pendingOrders.sort(sortByLoadNumberAsc);
      inProgressOrders.sort(sortByLoadNumberAsc);
      this.pendingOrders$$.next(pendingOrders);
      this.inProgressOrders$$.next(inProgressOrders);
    });
  }

  private subscribeToRouterFracIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.FRAC_ID).subscribe((fracId) => {
      if (!fracId) {
        this.orders$$.next({});
        this.currentOrder$$.next(null);
        return;
      }
      this.orders$$.next({});
      this.http
        .get<Order[]>(`${environment.api}/frac/${fracId}/orders`, { params: { noCompletedOrders: 'true' } })
        .subscribe((orders: Order[]) => {
          const ordersRecord = orders.reduce((record, order) => {
            record[order.id] = order;
            return record;
          }, {});
          this.orders$$.next(ordersRecord);
        });
    });
  }

  private subscribeToRouterDistributionCenterIdChanges() {
    this.routerState
      .listenForParamChange$(fromRouterConstants.DISTRIBUTION_CENTER_ID)
      .subscribe((distributionCenterId) => {
        if (!distributionCenterId) {
          this.orders$$.next({});
          this.currentOrder$$.next(null);
          return;
        }

        this.orders$$.next({});
        this.http
          .get<Order[]>(`${environment.api}/distribution_center/${distributionCenterId}/orders`, {
            params: { noCompletedOrders: 'true' },
          })
          .subscribe((orders: Order[]) => {
            const ordersRecord = orders.reduce((record, order) => {
              record[order.id] = order;
              return record;
            }, {});
            this.orders$$.next(ordersRecord);
          });
      });
  }

  private subscribeToRouterOrderIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.ORDER_ID).subscribe((orderId) => {
      if (!orderId) {
        this.currentOrder$$.next(null);
        this.currentOrderRestrictions$$.next(null);
        return;
      }
      // See if we have a local copy of the order for quick display
      if (this.orders$$.value[orderId]) {
        this.currentOrder$$.next({
          ...this.orders$$.value[orderId],
          cannotCancel: true,
        });
      } else {
        // Clear this out so we don't display old information
        this.currentOrder$$.next(null);
      }
      this.getOrder(orderId);

      this.http
        .get<{ restrictions: RoadRestrictionApiResponse[] }>(`${environment.api}/road_restrictions/lmo/by_order`, {
          params: { orderId },
        })
        .subscribe(
          (data) => {
            if (data) {
              this.currentOrderRestrictions$$.next((data.restrictions || []).map(toLocalRestriction));
            }
          },
          (error) => {
            console.error(error);
            this.currentOrderRestrictions$$.next([]);
          },
        );
    });
  }

  public getOrder(orderId: string) {
    this.http.get(`${environment.api}/order/${orderId}`).subscribe((order: Order) => {
      this.currentOrder$$.next(order);
      // Store the updated order so we have the latest version if the click out then click back in.
      this.orders$$.next({
        ...this.orders$$.value,
        [order.id]: order,
      });
    });
  }

  public getCancelledLoadsForDeadheadLinking$(
    vendorId: number,
    searchText: string,
  ): Observable<DeadheadLinkableOrder[]> {
    return this.http
      .get<{ orders: DeadheadLinkableOrder[] }>(`${environment.api}/deadhead_linkable_orders`, {
        params: {
          searchText: searchText || '',
          vendorId: `${vendorId}`,
        },
      })
      .pipe(map((resp) => resp.orders));
  }

  private logout() {
    this.orders$$.next({});
    this.currentOrder$$.next(null);
  }

  private loadLoHiSites() {
    if (!this.userService.isShaleappsEmail()) {
      return;
    }
    this.http.get<{ sites: { id: number; name: string }[] }>(`${environment.api}/lohi_site`).subscribe(
      (resp) => {
        this.loHiSitesForBoxPickup$$.next(resp.sites);
      },
      (error) => {
        console.error(error);
      },
    );
  }
}

function sortByLoadNumberAsc(a: Order, b: Order): number {
  return a.loadNumber < b.loadNumber ? -1 : 1;
}
