<div class="slider-wrap" *ngIf="showSlider">
  <div class="selected-time">
    <span>{{ selectedWaypoint.timestamp | date: 'medium' }}</span>
    <button class="log-btn" *ngIf="!showLog" (click)="showLog = true">Show Log</button>
    <button class="log-btn" *ngIf="showLog" (click)="showLog = false">Hide Log</button>
  </div>
  <mat-slider min="0" max="100" step="1" [(ngModel)]="sliderValue" (change)="sliderChange()"></mat-slider>
  <div class="time-wrap">
    <div class="label-time">{{ receivedOrder.startTime | date: 'medium' }}</div>
    <div class="label-time">{{ receivedOrder.endTime | date: 'medium' }}</div>
  </div>
</div>

<div class="log-div" *ngIf="showLog">
  <h3>LOGS</h3>
  <h3 class="log-placeholder" *ngIf="waypointLogs.length === 0">NO LOGS FOUND</h3>
  <div class="log-card" *ngFor="let log of waypointLogs">
    <h4>{{ log.description }}</h4>
    <div class="row-wrap">
      <span class="label">OS: </span>
      <span>{{ log.os }} {{ log.osVersion }}</span>
    </div>
    <div class="row-wrap">
      <span class="label">Timestamp: </span>
      <span>{{ log.timestamp }}</span>
    </div>
  </div>
</div>

<sa-map-search
  #mapSearch
  (onItemSelected)="showItem($event.item, $event.inputType)"
  (onInputChange)="closeDetailView()"
  (onInputFocused)="closeDetailView()"
  (onMenuClick)="menuClick()"
>
</sa-map-search>

<div class="spinner-wrap" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
  <h3>Loading Data...</h3>
</div>

<div id="map" style="height: 100%;"></div>

<sa-detail-view [model]="detailView" (onDateSelected)="loadWaypoint($event)" (onSiteUpdated)="updateSite($event)">
</sa-detail-view>
