import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sa-order-search-hotkey',
  templateUrl: './order-search-hotkey.component.html',
  styleUrls: ['./order-search-hotkey.component.scss'],
})
export class OrderSearchHotkeyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
