import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { subscribeToArray } from 'rxjs/internal-compatibility';

@Component({
  selector: 'sa-subcarrier-change-confirmation-dialog',
  templateUrl: './subcarrier-change-confirmation-dialog.component.html',
  styleUrls: ['./subcarrier-change-confirmation-dialog.component.scss'],
})
export class SubcarrierChangeConfirmationDialogComponent implements OnInit {
  public subcarrierName$ = new BehaviorSubject<string>('');
  @Output() public onChangeSubcarrier = new EventEmitter<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subcarrierName: string },
    public dialogRef: MatDialogRef<SubcarrierChangeConfirmationDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.subcarrierName$.next(this.data.subcarrierName);
  }

  public changeSubcarrier(change: boolean) {
    this.onChangeSubcarrier.emit(change);
    this.dialogRef.close(true);
  }
}
