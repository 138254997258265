import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '~environments/environment';

export interface ForecastDataMonthly {
  currentDate: string;
  months: ForecastDataMonth[];
  regionTimezone: string;
}

export interface ForecastDataMonth {
  days: ForecastDataDay[];
  dynamicPricingPercent: number;
  isHistorical?: boolean;
}

export interface ForecastData {
  weeks: ForecastDataWeek[];
  currentDate: string;
}

export interface ForecastDataWeek {
  days: ForecastDataDay[];
  dynamicPricingPercent: number;
  upForGrabsLoadsThreshold?: number;
  waitingDriversThreshold?: number;
  isHistorical?: boolean;
}

export interface ForecastDataDay {
  date: string;
  pendingLoadCount?: number;
  pendingShiftCount?: number;
  totalShiftCount?: number;
  totalLoadCount?: number;
  delta?: number;
  hours?: ForecastDataHour[];
  isForecasted?: boolean;
}

export interface ForecastDataHour {
  date: string;
  pendingLoadCount?: number;
  pendingShiftCount?: number;
  totalShiftCount?: number;
  totalLoadCount?: number;
  delta?: number;
  isForecasted?: boolean;
}

export interface ForecastParameters {
  regionId: number;
  startDate: Date;
  endDate: Date;
}

export interface MonthlyForecastParameters {
  regionId: number;
  year: number;
  month: number;
}

export interface DynamicPricingThresholds {
  pendingLoadCountThreshold?: number;
  pendingDriverCountThreshold?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicPricingForecastService {
  private forecastData$$ = new BehaviorSubject<ForecastData>(null);
  public forecastData$ = this.forecastData$$.pipe(shareReplay(1));

  private monthlyForecastData$$ = new BehaviorSubject<ForecastDataMonthly>(null);
  public monthlyForecastData$ = this.monthlyForecastData$$.pipe(shareReplay(1));

  private dynamicPricingThresholds$$ = new BehaviorSubject<DynamicPricingThresholds[]>(null);
  public dynamicPricingThresholds$ = this.dynamicPricingThresholds$$.pipe(shareReplay(1));

  private forecastParameters$$ = new BehaviorSubject<ForecastParameters>(null);
  public forecastParameters$ = this.forecastParameters$$.pipe(shareReplay(1));

  private monthlyForecastParameters$$ = new BehaviorSubject<MonthlyForecastParameters>(null);
  public monthlyForecastParameters$ = this.monthlyForecastParameters$$.pipe(shareReplay(1));

  // region -> date -> thresholds
  private dynamicPricingThresholds: Map<number, Map<string, DynamicPricingThresholds>>;

  constructor(private httpClient: HttpClient) {
    this.dynamicPricingThresholds = new Map<number, Map<string, DynamicPricingThresholds>>();
  }

  public setParameters(regionId: number, startDate: Date, endDate: Date) {
    this.forecastParameters$$.next({ regionId, startDate, endDate });

    this.loadForecastData();
    this.loadThresholds();
  }

  public setMonthlyParameters(regionId: number, year: number, month: number) {
    this.monthlyForecastParameters$$.next({ regionId, year, month });

    this.loadMonthlyForecastData();
  }

  private loadMonthlyForecastData() {
    const parameters = this.monthlyForecastParameters$$.getValue();

    let params = new HttpParams();

    params = params.append('regionId', parameters.regionId.toString());
    params = params.append('year', parameters.year.toString());
    params = params.append('month', parameters.month.toString());

    this.httpClient
      .get<ForecastDataMonthly>(`${environment.api}/dynamic_pricing/pending_driver_load_monthly`, { params })
      .subscribe((data) => {
        console.log(data);

        for (const month of data.months) {
          month.isHistorical = false;
          if (month.days[0].hours?.find((hour) => !hour.isForecasted)) {
            month.isHistorical = true;
          }
        }

        this.monthlyForecastData$$.next(data);
      });
  }

  private loadForecastData() {
    this.forecastData$$.next(null);

    const parameters = this.forecastParameters$$.getValue();

    const endMoment = moment(parameters.endDate).startOf('day');
    const startMoment = moment(parameters.startDate).startOf('day');

    const daysBack = endMoment.diff(startMoment, 'days');

    let params = new HttpParams();

    params = params.append('regionId', parameters.regionId.toString());
    params = params.append('startDate', parameters.endDate.toISOString());
    params = params.append('daysBack', daysBack.toString());

    this.httpClient
      .get<ForecastData>(`${environment.api}/dynamic_pricing/pending_driver_load`, { params })
      .subscribe((data) => {
        // fill in missing data
        let currentMoment = startMoment.clone();

        if (!data.weeks) {
          data.weeks = [];
        }

        // quick hack to fix forecast data showing up outside of date range
        if (data.weeks.length > 4) {
          data.weeks = data.weeks.slice(0, 4);
        }

        for (const week of data.weeks) {
          week.isHistorical = true;
          for (const day of week.days) {
            day.isForecasted = !!day.hours.find((hour) => hour.isForecasted);

            day.date = currentMoment.format('YYYY-MM-DD');
            currentMoment = currentMoment.add(1, 'day');
          }

          if (week.days.every((day) => day.isForecasted)) {
            week.isHistorical = false;

            // if we have non-forecasted data on the first day, we're historical
            if (week.days[0].hours.find((hour) => !hour.isForecasted)) {
              week.isHistorical = true;
            }
          }

          if (week.days.length < 7) {
            const daysToAdd = 7 - week.days.length;
            for (let i = 0; i < daysToAdd; i++) {
              week.days.push({
                date: currentMoment.format('YYYY-MM-DD'),
              });
              currentMoment = currentMoment.add(1, 'day');
            }
          }
        }

        const weeksToAdd = 4 - data.weeks.length;
        for (let i = 0; i < weeksToAdd; i++) {
          const newWeek = {
            days: [],
            dynamicPricingPercent: 0,
            upForGrabsLoadsThreshold: 100,
            waitingDriversThreshold: 100,
            isHistorical: false,
          };

          for (let j = 0; j < 7; j++) {
            newWeek.days.push({
              date: currentMoment.format('YYYY-MM-DD'),
            });
            currentMoment = currentMoment.add(1, 'day');
          }

          data.weeks.push(newWeek);
        }

        this.forecastData$$.next(data);
      });
  }

  private loadThresholds() {
    this.dynamicPricingThresholds$$.next(null);

    const parameters = this.forecastParameters$$.getValue();

    let params = new HttpParams();

    params = params.append('regionId', parameters.regionId.toString());
    params = params.append('startDate', parameters.startDate.toISOString());
    params = params.append('endDate', parameters.endDate.toISOString());

    this.httpClient
      .get<DynamicPricingThresholds[]>(`${environment.api}/dynamic_pricing/thresholds`, { params })
      .subscribe((data) => {
        this.dynamicPricingThresholds$$.next(data);
      });
  }

  public async updateThresholds(
    regionID: number,
    date: string,
    pendingLoadCountThreshold: number,
    pendingDriverCountThreshold: number,
  ): Promise<boolean> {
    let regionMap = this.dynamicPricingThresholds.get(regionID);

    if (!regionMap) {
      this.dynamicPricingThresholds.set(regionID, new Map<string, DynamicPricingThresholds>());
      regionMap = this.dynamicPricingThresholds.get(regionID);
    }

    const threshold = regionMap.get(date);
    if (threshold) {
      threshold.pendingLoadCountThreshold = pendingLoadCountThreshold;
      threshold.pendingDriverCountThreshold = pendingDriverCountThreshold;
    } else {
      regionMap.set(date, {
        pendingLoadCountThreshold,
        pendingDriverCountThreshold,
      });
    }

    return true;
  }

  public getThreshold(regionId: number, date: string): DynamicPricingThresholds {
    const regionMap = this.dynamicPricingThresholds.get(regionId);
    if (regionMap) {
      return regionMap.get(date);
    } else {
      return {
        pendingLoadCountThreshold: 25,
        pendingDriverCountThreshold: 25,
      };
    }
  }
}
