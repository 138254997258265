import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Data, Mine } from '~models/index';
import { share, filter, switchMap, take } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MineService {
  private get route(): string {
    return environment.api + '/mine';
  }
  private mines$$ = new BehaviorSubject<Data<Mine[]>>({
    data: [],
    meta: {
      loaded: false,
      loading: false,
    },
  });

  public get mines$(): Observable<Data<Mine[]>> {
    return this.mines$$.asObservable().pipe(share());
  }

  constructor(private crud: CrudService) {
    this.loadingComplete = this.loadingComplete.bind(this);
    this.loadData();
  }

  public loadData() {
    this.startMinesLoading();
  }

  private loadingOn() {
    const current = this.mines$$.value;
    this.mines$$.next({
      ...current,
      meta: {
        ...current.meta,
        loading: true,
      },
    });
  }

  private loadingComplete(data: Mine[], loaded = true) {
    const current = this.mines$$.value;
    this.mines$$.next({
      ...current,
      data,
      meta: {
        ...current.meta,
        loading: false,
        loaded: true,
      },
    });
  }

  private startMinesLoading() {
    this.loadingOn();
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.get(this.route) as Observable<Mine[]>),
      )
      .subscribe(this.loadingComplete, (error) => {
        this.loadingComplete([], false);
      });
  }
}
