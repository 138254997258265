<div class="map-search-container">
  <sa-map-search-input
    #searchInputComponent
    (onFocused)="focusInput($event)"
    (onSearch)="getResults($event)"
    (onMenuClick)="menuClick()"
  >
  </sa-map-search-input>
  <sa-map-search-results
    #searchResultsComponent
    *ngIf="showResults"
    [items]="results"
    [searchText]="inputElement.value"
    (onItemSelected)="selectItem($event)"
    (onItemFocused)="onResultItemFocused($event)"
    (onEscKey)="onEscapeKey()"
    (onCharacterKey)="appendCharacter($event)"
    (onSelectAll)="selectInputText()"
  >
  </sa-map-search-results>
</div>
