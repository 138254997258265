import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserGroup } from '../../models/user';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class UserApiService {
  private canCreateUsers$$ = new BehaviorSubject<{ initialized: boolean; canCrud: boolean }>({
    initialized: false,
    canCrud: false,
  });
  public canCreateUsers$ = this.canCreateUsers$$.pipe(shareReplay(1));
  get route(): string {
    return environment.api + '/user';
  }

  constructor(private http: HttpClient) {
    // They can refresh if this changes, don't see a reason to spam this route every time
    this.http.get<{ allowed: boolean }>(this.route + '/can_crud_users').subscribe((results) => {
      this.canCreateUsers$$.next({ initialized: true, canCrud: results?.allowed || false });
    });
  }

  getDrivers(params?): Observable<User[]> {
    return this.http.get<User[]>(this.route + '/phone', { params });
  }

  searchDrivers<T = User>(params?): Observable<T[]> {
    return this.http.get<T[]>(this.route + '/search', { params });
  }

  getWebUsers(params?): Observable<User[]> {
    return this.http.get<User[]>(this.route + '/web', { params });
  }

  getUsersGroup(params?): Observable<UserGroup[]> {
    return this.http.get<UserGroup[]>(this.route + '/groups', { params });
  }

  addUserGroup(body): Observable<UserGroup> {
    return this.http.post<UserGroup>(this.route + '/groups', body);
  }

  editUserGroup(id, body): Observable<UserGroup> {
    return this.http.put<UserGroup>(this.route + `/groups/${id}`, body);
  }

  updateUser(id: number, body): Observable<User> {
    return this.http.put<User>(this.route + '/' + id, body);
  }

  updateWebUser(id: number, body): Observable<User> {
    return this.http.put<User>(this.route + '/web/' + id, body);
  }

  createDriver(body): Observable<User> {
    return this.http.post<User>(this.route + '/phone', body);
  }

  getUnassignedUsers(params?): Observable<User[]> {
    if (localStorage.getItem('no_unassigned_users')) {
      return of([]);
    }
    return this.http.get<User[]>(this.route + '/unassigned', { params });
  }

  createWebUser(body): Observable<User> {
    return this.http.post<User>(this.route + '/web', body);
  }

  removeDriver(id: number): Observable<void> {
    return this.http.post<void>(this.route + '/' + id + '/phone/remove', {});
  }

  removeWebUser(id: number): Observable<void> {
    return this.http.delete<void>(this.route + '/' + id);
  }

  endShift(fracClusterId: number, driverId: number): Observable<void> {
    return this.http.post<void>(`${environment.api}/frac_cluster/${fracClusterId}/user/${driverId}/end_shift`, {});
  }

  updateHOS(fracClusterId: number, userId: number, minutesRemaining: number): Observable<void> {
    const body = {
      userId,
      minutesRemaining,
    };
    return this.http.post<void>(`${environment.api}/frac_cluster/${fracClusterId}/hos_remaining`, body);
  }

  updateHOSv2(userId: number, minutesRemaining: number): Observable<void> {
    const body = {
      minutesRemaining,
    };
    return this.http.post<void>(`${environment.api}/user/${userId}/hos_remaining`, body);
  }

  setPermissions(permissions: string[], userId: number): Observable<User> {
    return this.http
      .post<{ user: User }>(`${environment.api}/user/${userId}/permissions`, { permissions })
      .pipe(map((results) => results.user));
  }
}
