import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, combineLatest } from 'rxjs';
import { Order, isOrder } from '~models/order.model';
import { StoreService } from '~services/store.service';
import { map } from 'rxjs/operators';
import { getFracName } from '../pipes/frac-name.pipe';

interface OrderDetails {
  loaded: boolean;
  fracName: string;
}

@Component({
  selector: 'sa-reassign-dialog',
  templateUrl: './reassign-dialog.component.html',
  styleUrls: ['./reassign-dialog.component.scss'],
})
export class ReassignDialogComponent implements OnInit {
  public driversOrderDetails$: Observable<OrderDetails>;
  data: any;
  showScreen = 1;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ReassignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private storeService: StoreService,
  ) {
    this.data = _data;
  }

  ngOnInit() {
    if (this.data.driver && this.data.driver.userStatus && this.data.driver.userStatus.currentOrderId) {
      this.driversOrderDetails$ = combineLatest(
        this.storeService.getFracByOrderId(this.data.driver.userStatus.currentOrderId),
        this.storeService.getOrderForId(this.data.driver.userStatus.currentOrderId),
      ).pipe(
        map(([frac, order]) => {
          if (isOrder(order)) {
            return {
              loaded: order.loaded,
              fracName: getFracName(frac),
            };
          }
          return null;
        }),
      );
    }
  }

  cancelAction(arg) {
    this.dialogRef.close(arg);
  }

  callAction(arg) {
    let url = '';
    switch (arg) {
      case 'location':
        url = `https://www.google.com.sa/maps/search/?api=1&query=${this.data.driver.lastLngLat[1]},${
          this.data.driver.lastLngLat[0]
        }`;
        window.open(url, '_blank');
        break;
      case 'route':
        if (this.data.driver.order.loaded) {
          url = `https://www.google.com.sa/maps/dir/?api=1&origin=${this.data.driver.lastLngLat[1]},${
            this.data.driver.lastLngLat[0]
          }&destination=${this.data.site.lngLat[1]},${this.data.site.lngLat[0]}`;
        } else {
          url = `https://www.google.com.sa/maps/dir/?api=1&origin=${this.data.driver.lastLngLat[1]},${
            this.data.driver.lastLngLat[0]
          }&destination=${this.data.driver.order.mine.site.lngLat[1]},${this.data.driver.order.mine.site.lngLat[0]}`;
        }
        window.open(url, '_blank');
        break;
    }
    this.cancelAction('cancel');
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`sms:${url}`);
  }

  continueAction(arg) {
    this.dialogRef.close(arg);
  }
}
