<div *saFeatureFlag="'dynamicPricingPage'">
  <sa-nav [visible]="displayNav$$ | async" (onMenuClose)="displayNav$$.next(false)"></sa-nav>

  <div>
    <div class="flex justify-between p-2 mb-5">
      <div>
        <img src="../../../assets/icons/menu-24.svg" (click)="displayNav$$.next(true)" />
      </div>
      <div><img class="w-3 h-3 ml-16" src="../../../assets/icons/logo.svg" /></div>
      <div>
        {{ userLabel.email || userLabel.name || 'Unknown' }}
      </div>
    </div>

    <div class="flex flex-col mx-5 mb-2">
      <div>Filter by Region</div>
      <mat-form-field>
        <mat-select [(value)]="selected">
          <mat-option *ngFor="let region of regions" [value]="region.label" (click)="selectedRegion(region)">
            {{ region.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="grid md:grid-cols-4 gap-2 mx-2">
      <div class="flex flex-col md:col-span-3 items-end mr-3 mb-1">
        <button mat-raised-button (click)="openDialog()">Edit Benchmark</button>
      </div>
    </div>

    <div class="grid md:grid-cols-4 gap-2 mx-2">
      <div class="flex flex-col md:col-span-3">
        <div class="flex flex-col md:p-4 bg-white shadow rounded mx-3 mb-3">
          <div class="mb-2 font-bold text-md">LoHi Spend</div>
          <div *ngIf="loading" class="py-1">Loading Graph Data...</div>
          <div [style.display]="loading ? 'none' : 'block'" class="w-full" [chart]="priceChart"></div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-col bg-white shadow rounded mr-3 p-2 mb-3">
          <div class="font-bold mb-2">Upcoming Rate Changes</div>
          <div class="divide-y divide-gray-600 mb-1">
            <div class="mb-1">Committed</div>
            <div>
              <div class="mt-1 flex justify-between" *ngFor="let rate of committedRates">
                <div>{{ rate.date }}</div>
                <div class="flex">
                  <div class="font-bold" style="color: #0096FF" *ngIf="rate.rateIncreasePercentageActual > 0">
                    +{{ rate.rateIncreasePercentageActual }}%&nbsp;
                  </div>
                  <div class="font-bold" style="color: #0096FF" *ngIf="rate.rateIncreasePercentageActual <= 0">
                    {{ rate.rateIncreasePercentageActual }}%&nbsp;
                  </div>
                  <div class="text-gray-700" *ngIf="rate.rateIncreasePercentageOptimal > 0">
                    (Optimal: +{{ rate.rateIncreasePercentageOptimal }}%)
                  </div>
                  <div class="text-gray-700" *ngIf="rate.rateIncreasePercentageOptimal <= 0">
                    (Optimal: {{ rate.rateIncreasePercentageOptimal }}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="divide-y divide-gray-600">
            <div class="mb-1">Forecasted</div>
            <div>
              <div class="mt-1 flex justify-between" *ngFor="let rate of forecastedRates">
                <div>{{ rate.date }}</div>
                <div class="flex">
                  <div class="font-bold" style="color: #0096FF" *ngIf="rate.rateIncreasePercentageActual > 0">
                    +{{ rate.rateIncreasePercentageActual }}%&nbsp;
                  </div>
                  <div class="font-bold" style="color: #0096FF" *ngIf="rate.rateIncreasePercentageActual <= 0">
                    {{ rate.rateIncreasePercentageActual }}%&nbsp;
                  </div>
                  <div class="text-gray-700" *ngIf="rate.rateIncreasePercentageOptimal > 0">
                    (Optimal: +{{ rate.rateIncreasePercentageOptimal }}%)
                  </div>
                  <div class="text-gray-700" *ngIf="rate.rateIncreasePercentageOptimal <= 0">
                    (Optimal: {{ rate.rateIncreasePercentageOptimal }}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col bg-white shadow rounded mr-3 p-2">
          <div class="font-bold">Rate Configuration</div>
          <div class="text-gray-700 mb-2">{{ selected }}</div>
          <div class="flex border-b-2 border-gray-400 pb-1 justify-between">
            <div>Maximum Variable Increase</div>
            <div class="text-gray-700 pr-1">{{ maxVariableIncrease }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
