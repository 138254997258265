import { Injectable } from '@angular/core';
import { Waypoint } from '../../models/waypoint';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class WaypointApiService {
  get route(): string {
    return environment.api + '/waypoint';
  }

  get way(): string {
    return environment.api + '/load_waypoint';
  }

  get log(): string {
    return environment.api + '/logs/read';
  }

  constructor(private crud: CrudService) {}

  getWaypoints(params: any): Observable<Waypoint[]> {
    return this.crud.get(this.route, params);
  }

  loadWayPoints(body: any): Observable<Waypoint[]> {
    return this.crud.post(this.way, body);
  }

  getLastWaypoints(): Observable<Waypoint[]> {
    return this.crud.get(environment.api + '/last_waypoint');
  }

  getWaypointsLog(params: any): Observable<Waypoint[]> {
    return this.crud.post(this.log, params);
  }
}
