import { Pipe, PipeTransform } from '@angular/core';

interface FracType {
  site: {
    name: string;
  };
  jobName?: string;
}

@Pipe({
  name: 'fracName',
})
export class FracNamePipe implements PipeTransform {
  transform(frac: FracType): string {
    return getFracName(frac);
  }
}

export function getFracName(frac: FracType): string {
  if (!frac) {
    return null;
  }
  if (frac.jobName && frac.jobName !== '') {
    return frac.jobName;
  }
  return frac.site ? frac.site.name : null;
}
