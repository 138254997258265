import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth-guard';

import { LoginComponent } from './login/login.component';
import { WellComponent } from './wells/well.component';
import { WellCreationComponent } from './wells/well-creation/well-creation.component';
import { MapComponent } from './map/map.component';
import { SettingsComponent } from './settings/settings.component';
import { SignupComponent } from './invite/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TruckingComponent } from './trucking/trucking.component';
import { FracListComponent } from './ui-components/jobs-view/frac-list/frac-list.component';
import { FracDetailComponent } from './ui-components/jobs-view/frac-detail/frac-detail.component';
import { OrderDetailComponent } from './ui-components/jobs-view/order-detail/order-detail.component';
import { PendingOrderDetailComponent } from './ui-components/jobs-view/pending-order-detail/pending-order-detail.component';
import { CompletedOrdersComponent } from './completed-orders/completed-orders.component';
import { OrderTableComponent } from './completed-orders/order-table/order-table.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailSettingsV2Component } from './email-settings-v2/email-settings-v2.component';
import { EmailDetailsComponent } from './email-settings/email-details/email-details.component';
import { MinesComponent } from './mines/mines.component';
import { SandiPageComponent } from './ui-components/sandi-page/sandi-page.component';
import { RunboardSummaryComponent } from './runboard-summary/runboard-summary.component';
import { RunboardDriverOptionsComponent } from './runboard-driver-options/runboard-driver-options.component';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AdminGuard } from '~services/admin-guard';
import { BulkExportComponent } from './bulk-export/bulk-export.component';
import { LogisticsRunboardDriverOptionsComponent } from './logistics-runboard-driver-options/logistics-runboard-driver-options.component';
import { LogisticsRunboardSummaryComponent } from './logistics-runboard-summary/logistics-runboard-summary.component';
import { AdminRouteGuard } from '~services/admin-route-guard';
import * as fromConstants from './app-routing.constants';
import { DispatcherVideoTrainingComponent } from './dispatcher-video-training/dispatcher-video-training.component';
import { EmailAnalyticsComponent } from './email-settings/email-analytics/email-analytics.component';
import { SigninWithCustomTokenComponent } from './signin-with-custom-token/signin-with-custom-token.component';
import { DispatcherOrderLandingComponent } from './dispatcher-order-landing/dispatcher-order-landing.component';
import { RtiDemoComponent } from './rti-demo/rti-demo.component';
import { LoaderAnalyticsDashboardComponent } from './email-settings/loader-analytics-dashboard/loader-analytics-dashboard.component';
import { BillingOrdersComponent } from './completed-orders/billing-orders/billing-orders.component';
import { AllFracOrderTableComponent } from './completed-orders/all-frac-order-table/all-frac-order-table.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { EmailDetailsV2Component } from './email-settings-v2/email-details-v2/email-details-v2.component';
import { SigninWithCustomTokenLohiComponent } from './signin-with-custom-token-lohi/signin-with-custom-token-lohi.component';
import { DynamicPricingComponent } from './dynamic-pricing/dynamic-pricing.component';
import { BulkExportRouteGuard } from '~services/bulk-export-route-guard';
import { DistributionCenterCreationComponent } from './distribution-centers/distribution-center-creation/distribution-center-creation.component';
import { LoaderAnalyticsDashboardV2Component } from './email-settings-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2.component';
import { LoadsOnDateComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loads-on-date/loads-on-date.component';
import { MatchesForLoadComponent } from './email-settings-v2/loader-analytics-dashboard-v2/matches-for-load/matches-for-load.component';
import { UnsubscribePoComponent } from './unsubscribe/unsubscribe-po/unsubscribe-po.component';
import { DynamicPricingForecastComponent } from './dynamic-pricing/dynamic-pricing-forecast/dynamic-pricing-forecast.component';
import { LoadsForPurchaseOrderComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loads-for-purchase-order/loads-for-purchase-order.component';
import { SubcarrierComponent } from './subcarrier/subcarrier.component';
import { CrewDataDashboardComponent } from './email-settings-v2/crew-data-dashboard/crew-data-dashboard.component';
import { CrewDataOnDateComponent } from './email-settings-v2/crew-data-dashboard/crew-data-on-date/crew-data-on-date.component';
import { LmoLoaderNameMappingComponent } from './email-settings-v2/lmo-loader-name-mapping/lmo-loader-name-mapping.component';
import { DcDetailComponent } from './ui-components/jobs-view/dc-detail/dc-detail.component';
import { DcOrderDetailComponent } from './ui-components/jobs-view/dc-order-detail/dc-order-detail.component';
import { DcPendingOrderDetailComponent } from './ui-components/jobs-view/dc-pending-order-detail/dc-pending-order-detail.component';
import { AdminDispatcherStandardRouteGuard } from '~services/admin-dispatcher-standard-route-guard';
import { BulkLoadUploadComponent } from './bulk-load-upload/bulk-load-upload.component';
import { ArchivedWellsComponent } from './wells/archived-wells/archived-wells.component';

const routes: Routes = [
  {
    loadChildren: () => import('src/app/auto-procurement/auto-procurement.module').then((m) => m.AutoProcurementModule),
    path: 'auto-procurement',
  },
  {
    loadChildren: () =>
      import('src/app/buyer-marketplace/buyer-marketplace.module').then((m) => m.BuyerMarketplaceModule),
    path: 'marketplace',
  },
  {
    loadChildren: () => import('src/app/restrictions/restrictions.module').then((m) => m.RestrictionsModule),
    path: 'restrictions',
    canActivate: [AuthGuard],
  },
  {
    canActivate: [AdminRouteGuard],
    loadChildren: () => import('src/app/billing-2020/billing-2020.module').then((m) => m.Billing2020Module),
    path: 'invoicing',
  },
  {
    canActivate: [AdminRouteGuard],
    loadChildren: () => import('src/app/billing/billing.module').then((m) => m.BillingModule),
    path: 'billing',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('src/app/lmo/lmo.module').then((m) => m.LmoModule),
    path: 'lmo',
  },
  {
    loadChildren: () => import('src/app/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
    path: 'admin',
  },
  {
    loadChildren: () => import('src/app/api-docs/api-docs.module').then((m) => m.ApiDocsModule),
    canActivate: [AuthGuard],
    path: 'api',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('src/app/sand-vendor/sand-vendor.module').then((m) => m.SandVendorModule),
    path: 'sand-vendor',
  },
  {
    component: SigninWithCustomTokenComponent,
    path: 'sso/:jwt',
  },
  {
    component: SigninWithCustomTokenLohiComponent,
    path: 'lohi/:jwt',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'bulk-export',
    canActivate: [AuthGuard, BulkExportRouteGuard],
    component: BulkExportComponent,
  },
  {
    path: 'bulk-load-upload',
    canActivate: [AuthGuard],
    component: BulkLoadUploadComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'wells',
    canActivate: [AuthGuard, AdminDispatcherStandardRouteGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'archived',
      },
      {
        path: 'edit/:id',
        component: WellCreationComponent,
      },
      {
        path: 'edit/new',
        component: WellCreationComponent,
      },
      {
        path: 'archived',
        component: ArchivedWellsComponent,
      },
    ],
  },
  {
    path: 'dc',
    canActivate: [AuthGuard, AdminRouteGuard],
    children: [
      // {
      //   path: '',
      //   pathMatch: 'full',
      //   component: WellComponent,
      // },
      {
        path: 'edit/:id',
        component: DistributionCenterCreationComponent,
      },
      {
        path: 'edit/new',
        component: DistributionCenterCreationComponent,
      },
    ],
  },
  {
    path: 'loaders',
    canActivate: [AuthGuard, AdminRouteGuard],
    component: MinesComponent,
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    component: NotificationPreferencesComponent,
  },
  {
    path: 'subcarriers',
    canActivate: [AuthGuard],
    component: SubcarrierComponent,
  },
  {
    path: 'fracs/:id',
    canActivate: [AuthGuard, AdminRouteGuard],
    children: [
      {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full',
      },
      {
        path: 'orders',
        component: CompletedOrdersComponent,
        children: [
          {
            path: ':tab',
            component: OrderTableComponent,
            children: [
              {
                path: ':orderId',
                component: OrderTableComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'market',
        loadChildren: () => import('src/app/marketplace/marketplace.module').then((m) => m.MarketplaceModule),
      },
    ],
  },
  {
    path: 'billing/fracs',
    component: BillingOrdersComponent,
    children: [
      {
        path: ':tab',
        component: AllFracOrderTableComponent,
        children: [
          {
            path: ':fracId/:orderId',
            component: AllFracOrderTableComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'market',
    loadChildren: () => import('src/app/marketplace/marketplace.module').then((m) => m.MarketplaceModule),
  },
  {
    path: 'map',
    component: MapComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: `logistics-runboard/:${fromConstants.LOGISTICS_CLUSTER_ID}`,
        component: LogisticsRunboardSummaryComponent,
      },
      {
        path: `logistics-runboard/:${fromConstants.LOGISTICS_CLUSTER_ID}/:${fromConstants.LOGISTICS_CLUSTER_USER_ID}`,
        component: LogisticsRunboardDriverOptionsComponent,
      },
      {
        path: 'runboard/:fracClusterId',
        component: RunboardSummaryComponent,
      },
      {
        path: 'runboard/:fracClusterId/:userId',
        component: RunboardDriverOptionsComponent,
      },
      {
        path: 'jobs',
        children: [
          {
            path: 'list',
            component: FracListComponent,
          },
          {
            path: 'detail',
            children: [
              {
                path: 'dc',
                children: [
                  {
                    path: ':id/:tab',
                    component: DcDetailComponent,
                  },
                  {
                    path: ':id/:tab/order/:load',
                    component: DcOrderDetailComponent,
                  },
                  {
                    path: ':id/:tab/:load',
                    component: DcPendingOrderDetailComponent,
                  },
                ],
              },
              {
                path: ':id/:tab',
                component: FracDetailComponent,
              },
              {
                path: ':id/:tab/order/:load',
                component: OrderDetailComponent,
              },
              {
                path: ':id/:tab/:load',
                component: PendingOrderDetailComponent,
              },
            ],
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'map/:id',
    component: MapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: SettingsComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration',
    component: EmailSettingsComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration/analytics',
    component: EmailAnalyticsComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration/detail/:id',
    component: EmailDetailsComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration-v2',
    component: EmailSettingsV2Component,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration-v2/loader-name-mapping',
    component: LmoLoaderNameMappingComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-integration-v2/detail/:id',
    component: EmailDetailsV2Component,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-analytics-dashboard',
    component: LoaderAnalyticsDashboardComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'loader-analytics-dashboard-v2',
    component: LoaderAnalyticsDashboardV2Component,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'crew-data',
    component: CrewDataDashboardComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: `crew-data/:${fromConstants.CREW_DATA_FRAC_ID}/:${fromConstants.CREW_DATA_DATE}`,
    component: CrewDataOnDateComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: `loader-analytics-dashboard-v2/email/:${fromConstants.LOADER_INTEGRATION_MINE_ID}/:${fromConstants.LOADER_INTEGRATION_DATE}`,
    component: LoadsOnDateComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: `loader-analytics-dashboard-v2/purchase-order/:${fromConstants.LOADER_INTEGRATION_PURCHASE_ORDER_ID}`,
    component: LoadsForPurchaseOrderComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: `loader-analytics-dashboard-v2/load/:${fromConstants.LOADER_INTEGRATION_LOADER_DATA_ID}`,
    component: MatchesForLoadComponent,
    canActivate: [AuthGuard, AdminRouteGuard],
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `trucking-contracts`,
    canActivate: [AuthGuard, AdminRouteGuard],
    children: [
      {
        component: TruckingComponent,
        path: `:${fromConstants.TRUCKING_CONTRACT_ID}`,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: 'variable-pricing',
    canActivate: [AuthGuard],
    component: DynamicPricingComponent,
  },
  {
    path: 'variable-pricing/forecast',
    canActivate: [AuthGuard],
    component: DynamicPricingForecastComponent,
  },
  {
    path: 'invite/:code',
    component: SignupComponent,
  },
  {
    path: 'sandi',
    component: SandiPageComponent,
  },
  {
    path: 'unsubscribe/email/:uuid',
    component: UnsubscribeComponent,
  },
  {
    path: 'unsubscribe/po/email/:uuid',
    component: UnsubscribePoComponent,
  },
  {
    path: 'dispatcher/training-videos',
    component: DispatcherVideoTrainingComponent,
  },
  {
    path: 'dispatcher/orders/:orderId',
    component: DispatcherOrderLandingComponent,
  },
  {
    path: 'RTI',
    component: RtiDemoComponent,
  },
  // {
  //   path: 'fraud',
  //   canActivate: [AuthGuard, AdminRouteGuard],
  //   component: FraudDashboardComponent,
  //   // children: [
  //   //   {
  //   //     path: '',
  //   //     pathMatch: 'full',
  //   //     component: DashboardComponent,
  //   //   },
  //   // ],
  // },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
