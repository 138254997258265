import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../models/user';
import { UserApiService } from '../services/api/user.api.service';
import { CrudService } from '../services/crud.service';
import { DetailView } from '../models/detail-view';
import { DetailViewModels } from '../constants/detail-view-models';
import { filter, map, take } from 'rxjs/operators';
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user.service';
import { FeatureFlagService } from '../services/feature-flag.service';

@Component({
  selector: 'sa-driver-settings',
  templateUrl: './driver-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PhoneNumberPipe],
})
export class DriverSettingsComponent implements OnInit {
  displayedColumns = ['userName', 'phone', 'pin', 'accountName', 'cdl', 'action'];
  dataSource: MatTableDataSource<User> = new MatTableDataSource([]);

  detailView: DetailView = {
    open: false,
    modelName: null,
    model: null,
  };

  constructor(
    private userApiService: UserApiService,
    private crudService: CrudService,
    public userService: UserService,
    public featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    if (this.userService.isBrokerageAccount()) {
      this.displayedColumns.pop();
      this.displayedColumns.pop();
      this.displayedColumns.push('subcarrier', 'cdl', 'trailerType', 'assetStatus', 'trailer', 'action');
    }
    this.loadData();
  }

  private loadData() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.userApiService
        .getDrivers()
        .pipe(
          map((data) => {
            data.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return data;
          }),
        )
        .subscribe((data) => {
          this.dataSource = new MatTableDataSource(data);
        });
    });
  }

  sortTable(event) {
    let sortedData: User[];

    switch (event.active) {
      case 'userName':
        sortedData = this.dataSource.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        break;
      case 'phone':
        sortedData = this.dataSource.data.sort((a, b) => {
          return +a.phone - +b.phone;
        });
        break;
      case 'subcarrier':
        sortedData = this.dataSource.data.sort((a, b) => {
          return this.possiblyUndefinedStringCompare(a.subcarrierName, b.subcarrierName);
        });
        break;
      case 'cdl':
        sortedData = this.dataSource.data.sort((a, b) => {
          return this.possiblyUndefinedStringCompare(a.cdl, b.cdl);
        });
        break;
      case 'trailerType':
        sortedData = this.dataSource.data.sort((a, b) => {
          return this.possiblyUndefinedStringCompare(a.trailerTypeName, b.trailerTypeName);
        });
        break;
      case 'assetStatus':
        sortedData = this.dataSource.data.sort((a, b) => {
          return this.possiblyUndefinedStringCompare(a.assetStatus, b.assetStatus);
        });
        break;
      case 'trailer':
        sortedData = this.dataSource.data.sort((a, b) => {
          return this.possiblyUndefinedStringCompare(a.trailerName, b.trailerName);
        });
        break;
    }

    if (event.direction === 'desc') {
      sortedData.reverse();
    }
    this.dataSource.data = sortedData;
  }

  possiblyUndefinedStringCompare(a: string, b: string): number {
    if (a === b) {
      return 0;
    } else if (a === null || a === undefined) {
      return 1;
    } else if (b === null || b === undefined) {
      return -1;
    } else {
      return a.localeCompare(b);
    }
  }

  openUserDetailView(user: User) {
    this.detailView.open = true;
    this.detailView.modelName = DetailViewModels.driverProfile;
    this.detailView.model = user;
  }

  closeUserDetailView() {
    this.detailView.open = false;
    this.detailView.modelName = null;
    this.detailView.model = null;
  }

  userUpdated(user: User) {
    this.loadData();
  }
}
