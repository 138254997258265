import { Directive, OnDestroy, Renderer2, ElementRef, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserService, roles } from '~services/user.service';

const rolesLowercase = Object.keys(roles).reduce((record: Record<string, number>, name: string) => {
  record[name.toLowerCase()] = roles[name];
  return record;
}, {});

@Directive({
  selector: '[saAllowedAccountRolesEnabledFor]',
})
export class AllowedAccountTypesEnabledForDirective {
  private allowedAccountTypes$$: BehaviorSubject<Record<number, boolean>> = new BehaviorSubject({});
  private subscription: Subscription;

  @Input() set saAllowedAccountRolesEnabledFor(accountTypes: (number | string)[]) {
    const allowedAccountTypes: Record<number, boolean> = {};
    accountTypes.forEach((accountType) => {
      if (typeof accountType === 'string') {
        const roleId = rolesLowercase[accountType.toLowerCase()];
        if (roleId) {
          allowedAccountTypes[roleId] = true;
        }
      } else if (typeof accountType === 'number') {
        allowedAccountTypes[accountType] = true;
      }
    });
    this.allowedAccountTypes$$.next(allowedAccountTypes);
  }

  constructor(renderer: Renderer2, hostElement: ElementRef, private userService: UserService) {
    this.subscription = this.allowedAccountTypes$$.subscribe((record) => {
      const user = this.userService.getUserContractFromCache();
      const accountRoleId = user.account && user.account.role && user.account.role.id;
      if (!accountRoleId) {
        renderer.addClass(hostElement.nativeElement, 'account-type-disabled');
        return;
      }

      if (record[accountRoleId] === true) {
        renderer.removeClass(hostElement.nativeElement, 'account-type-disabled');
      } else {
        renderer.addClass(hostElement.nativeElement, 'account-type-disabled');
      }
    });
  }
}
