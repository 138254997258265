<div mat-dialog-content>
  <h3>{{ data.context }}</h3>
  <p>{{ data.desc }}</p>
</div>
<div mat-dialog-actions>
  <ng-container *ngFor="let button of data.button; last as isLast">
    <button *ngIf="!isLast && button != 'Other'" mat-button mat-dialog-close="true" (click)="continueAction(button)">
      {{ button }}
    </button>
    <mat-form-field *ngIf="button === 'Other'">
      <input #box matInput (keyup.enter)="continueAction(box.value)" placeholder="Other" />
    </mat-form-field>
    <button
      *ngIf="isLast && button != 'Other'"
      mat-button
      color="error"
      tabindex="-1"
      cdkFocusInitial
      (click)="cancelAction()"
    >
      {{ button }}
    </button>
  </ng-container>
</div>
