import { Component, OnDestroy, OnInit } from '@angular/core';
import { endWith, filter, map, startWith, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { HelperService } from '../services/helper.service';
import {
  OrderSummary,
  UnassignedUserSummary,
  UserStatus,
} from '../ui-components/jobs-view/frac-detail/runboard-summary.model';
import { LogisticsRunboardService } from '~services/logistics-runboard.service';
import { isActionNeeded, isOnline, isUnreachable } from '~v2Models/user.model';
import * as Fuse from 'fuse.js';
import { FormControl } from '@angular/forms';
import { LogisticsRunboardSummary } from '~v2Models/logisticsCluster.model';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../app-routing.constants';
import { UserService } from '~services/user.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-logistics-runboard-summary',
  templateUrl: './logistics-runboard-summary.component.html',
  styleUrls: ['./logistics-runboard-summary.component.scss'],
})
export class LogisticsRunboardSummaryComponent implements OnInit, OnDestroy {
  private timeRecalculateInterval = 15 * 1000; // 15 seconds.
  private getOTVSStatusClassMap: Record<number, Observable<'safe' | 'almost-expired' | 'up-for-grabs'>> = {};
  private timeLeftMap: Record<number, Observable<string>> = {};
  private hosMap: Record<number, { shiftEndTimestamp: string; obs: Observable<string> }> = {};
  public searchForm = new FormControl(null);
  public summary$: Observable<LogisticsRunboardSummary>;

  public driverPool$: Observable<{ message: string; shouldShow: boolean }>;
  public needsDriver$: Observable<OrderSummary[]>;
  public notAvailableForLoads$: Observable<UnassignedUserSummary[]>;
  public availableUndispatchedDriver$: Observable<UnassignedUserSummary[]>;
  public activeDrivers$: Observable<OrderSummary[]>;
  public isOnline = isOnline;
  public isUnreachable = isUnreachable;
  public isActionNeeded = isActionNeeded;
  private destroy$$ = new Subject();
  public isShaleApps = this.userService.isShaleappsEmail();

  constructor(
    private logisticsRunboardService: LogisticsRunboardService,
    public helperService: HelperService,
    private routerStateService: RouterStateService,
    private userService: UserService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.summary$ = combineLatest([
      this.routerStateService.routerState$.pipe(takeUntil(this.destroy$$)),
      this.logisticsRunboardService.runboardSummary$.pipe(
        takeUntil(this.destroy$$),
        filter((summary) => !!summary && !!summary.logisticsClusterId),
      ),
    ]).pipe(
      takeUntil(this.destroy$$),
      map(([state, summary]) => {
        const clusterId = +state.params[fromRouterConstants.LOGISTICS_CLUSTER_ID];
        if (clusterId === summary.logisticsClusterId) {
          return summary;
        }
        return null;
      }),
      filter((summary) => !!summary),
    );

    this.driverPool$ = this.logisticsRunboardService.driverPool$;
    this.setupNeedsDriver();
    this.setupNotAvailableForLoads();
    this.setupAvailableUndispatchedDriver();
    this.setupActiveDrivers();
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  calculateHOS(shiftEndTimestamp: string) {
    const now = new Date().getTime();
    const shiftEndDate = new Date(shiftEndTimestamp).getTime();
    const secDiff = Math.max(Math.round((shiftEndDate - now) / 1000), 0);
    const minDiff = Math.floor(secDiff / 60);
    const hoursDiff = Math.floor(minDiff / 60);

    if (hoursDiff >= 1) {
      return `${hoursDiff}h ${minDiff % 60}m`;
    }
    return `${minDiff}m`;
  }

  isVorto() {
    return this.userService.isShaleappsEmail() || this.userService.isVortoUser();
  }

  getCountdown(order: OrderSummary): Observable<string> {
    if (!this.timeLeftMap[order.upForGrabsTimestamp]) {
      this.timeLeftMap[order.upForGrabsTimestamp] = timer(0, this.timeRecalculateInterval).pipe(
        map(() => this.helperService.calculateTimeUntil(order.upForGrabsTimestamp)),
      );
    }
    return this.timeLeftMap[order.upForGrabsTimestamp];
  }

  getOTVSStatusClass(order: OrderSummary): Observable<'safe' | 'almost-expired' | 'up-for-grabs' | 'urgent'> {
    if (!this.getOTVSStatusClassMap[order.upForGrabsTimestamp]) {
      this.getOTVSStatusClassMap[order.upForGrabsTimestamp] = timer(0, this.timeRecalculateInterval).pipe(
        map(() => {
          if (order.urgent) {
            return 'urgent';
          }
          const minutesAgo = getMinutesAgo(order.upForGrabsTimestamp);
          if (minutesAgo < -5) {
            return 'safe';
          }
          if (minutesAgo < 0) {
            return 'almost-expired';
          }
          return 'up-for-grabs';
        }),
        takeWhile((value) => value !== 'up-for-grabs'),
        endWith('up-for-grabs'),
      );
    }
    return this.getOTVSStatusClassMap[order.upForGrabsTimestamp];
  }

  getHos(userStatus: UserStatus): Observable<string> {
    if (
      !this.hosMap[userStatus.userId] ||
      this.hosMap[userStatus.userId].shiftEndTimestamp !== userStatus.shiftEndTimestamp
    ) {
      this.hosMap[userStatus.userId] = {
        shiftEndTimestamp: userStatus.shiftEndTimestamp,
        obs: timer(0, 30 * 1000).pipe(
          // Update every 30 seconds so we never get too out of sync with minute changes
          map(() => this.calculateHOS(userStatus.shiftEndTimestamp)),
        ),
      };
    }
    return this.hosMap[userStatus.userId].obs;
  }

  trackByOrderId(_index: number, order: OrderSummary): number {
    return order.orderId;
  }

  noContent(): Observable<boolean> {
    return this.logisticsRunboardService.clusterName$.pipe(
      switchMap(() =>
        combineLatest(
          this.logisticsRunboardService.needsDriver$,
          this.logisticsRunboardService.notAvailableForLoads$,
          this.logisticsRunboardService.activeDrivers$,
          this.logisticsRunboardService.availableUndispatchedDriver$,
        ),
      ),
      map((countables) => countables.reduce((sum, countable) => sum + countable.length, 0)),
      map((count) => count === 0),
    );
  }

  private setupNeedsDriver() {
    this.needsDriver$ = combineLatest(
      this.logisticsRunboardService.needsDriver$.pipe(map(fuseOrderSummaries)),
      this.searchForm.valueChanges.pipe(startWith(null)),
    ).pipe(
      map(([summaries, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return summaries.summaries;
        }
        // Right now we don't show any information on the card so having these come back in search would be weird
        return [];
        // return summaries.fuse.search(searchTerm);
      }),
    );
  }

  private setupNotAvailableForLoads() {
    this.notAvailableForLoads$ = combineLatest(
      this.logisticsRunboardService.notAvailableForLoads$.pipe(map(fuseUnassigned)),
      this.searchForm.valueChanges.pipe(startWith(null)),
    ).pipe(
      map(([summaries, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return summaries.summaries;
        }
        return summaries.fuse.search(searchTerm) as any;
      }),
    );
  }

  private setupAvailableUndispatchedDriver() {
    this.availableUndispatchedDriver$ = combineLatest(
      this.logisticsRunboardService.availableUndispatchedDriver$.pipe(map(fuseUnassigned)),
      this.searchForm.valueChanges.pipe(startWith(null)),
    ).pipe(
      map(([summaries, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return summaries.summaries;
        }
        return summaries.fuse.search(searchTerm) as any;
      }),
    );
  }

  private setupActiveDrivers() {
    this.activeDrivers$ = combineLatest(
      this.logisticsRunboardService.activeDrivers$.pipe(map(fuseOrderSummaries)),
      this.searchForm.valueChanges.pipe(startWith(null)),
    ).pipe(
      map(([summaries, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return summaries.summaries;
        }
        return summaries.fuse.search(searchTerm) as any;
      }),
    );
  }

  public copyDriverPhone(event: Event, unassignedUser: UnassignedUserSummary) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    if (!unassignedUser || !unassignedUser.userStatus) {
      return;
    }
    this.clipboard.copy(unassignedUser.userStatus.phoneNumber);
    this.snackBar.open(`Copied phone number to clipboard`, null, {
      duration: 1500,
    });
  }

  public formatDriverMissingData(data: string[]): string {
    let result = '';
    data.forEach((value) => {
      result += value + ', ';
    });

    return result.slice(0, -2);
  }

  public trackByFnReason(index, item) {
    return item;
  }

  public checkBolMissingDetails(order: OrderSummary): string[] {
    const reasons = [];

    if (!order.bolNumber) {
      reasons.push('Missing BOL number');
    }

    if (!order.isBolTicketUploaded) {
      reasons.push('Missing BOL ticket upload');
    }
    return reasons;
  }
}

function getMinutesAgo(timestamp: string): number {
  const now = new Date().getTime();
  const created = new Date(timestamp).getTime();
  return Math.round((now - created) / 1000 / 60);
}

const summarySearchOptions: Fuse.FuseOptions<OrderSummary> = {
  keys: ['truckName', 'userName', 'loadNumber', 'mineName', 'fracName', 'saUniqueId'],
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 16,
  minMatchCharLength: 1,
};

function fuseOrderSummaries(
  summaries: OrderSummary[],
): { summaries: OrderSummary[]; fuse: Fuse<OrderSummary, Fuse.FuseOptions<any>> } {
  return {
    fuse: new Fuse(summaries, summarySearchOptions),
    summaries,
  };
}

const userSearchOptions: Fuse.FuseOptions<UnassignedUserSummary> = {
  keys: ['userName'],
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 16,
  minMatchCharLength: 1,
};

function fuseUnassigned(
  summaries: UnassignedUserSummary[],
): { summaries: UnassignedUserSummary[]; fuse: Fuse<UnassignedUserSummary, Fuse.FuseOptions<any>> } {
  return {
    fuse: new Fuse(summaries, userSearchOptions),
    summaries,
  };
}
