import { filter, take, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmailSettingsV2Service } from 'src/app/services/email-settings-v2.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { EmailDateTime, EmailSetting, Mine } from '~models/index';
import { MineService } from '~services/mine.service';
import { FeatureFlagService } from '~services/feature-flag.service';

@Component({
  selector: 'sa-email-form-v2',
  templateUrl: './email-form-v2.component.html',
  styleUrls: ['./email-form-v2.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class EmailFormV2Component implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private emailSettingsV2Service: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    public mineService: MineService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.email = environment.email;
  }

  mines$: Observable<Mine[]>;
  emailForm: FormGroup;
  subs: Subscription[] = [];
  email;
  filterLoader: FormControl = new FormControl();
  filteredMines$: Observable<Mine[]>;

  public allowMultipleMines$: Observable<boolean>;

  @Input() selectedEmail: any;
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.goBack.emit();
      }
    }
  }

  ngOnInit() {
    this.allowMultipleMines$ = this.featureFlagService.isFlagActive('loaderIntegrationMultipleMineInSpreadsheet');

    this.mines$ = this.mineService.mines$.pipe(
      filter((data) => data?.meta?.loaded),
      map((data) => data.data),
    );

    this.buildForm();
  }

  async buildForm() {
    const allowMultipleLoaders = await this.allowMultipleMines$.pipe(take(1)).toPromise();

    console.log(this.selectedEmail);

    this.emailForm = this.fb.group({
      loader: [
        this.selectedEmail ? this.selectedEmail.loaderId : null,
        allowMultipleLoaders ? [] : [Validators.required],
      ],
      email: [this.selectedEmail ? this.selectedEmail.email.split('@')[0] : '', [Validators.required]],
      loaderEmail: [this.selectedEmail ? this.selectedEmail.loaderEmail : '', [Validators.email]],
    });

    this.filteredMines$ = combineLatest([
      this.filterLoader.valueChanges.pipe(startWith<string | any>('')),
      this.mines$,
    ]).pipe(map(([filterValue, mines]) => (filterValue ? this._filter(mines, filterValue) : mines)));
  }

  compareByValue(o1, o2) {
    return (!o1 && !o2) || (o1 && o2 && o1.id === o2.id);
  }

  private _filter(mines: Mine[], value: string) {
    const filterValue = value.toLowerCase();
    return mines.filter((option) => option.site.name.toLowerCase().indexOf(filterValue) > -1);
  }

  onSubmit(event) {
    if (this.emailForm.valid) {
      const body = {
        loaderId: this.emailForm.controls['loader'].value,
        email: this.emailForm.controls['email'].value + '@' + this.email,
        loaderEmail: this.emailForm.controls['loaderEmail'].value,
      };
      if (this.selectedEmail) {
        this.subs.push(
          this.emailSettingsV2Service.updateEmail(this.selectedEmail.id, body).subscribe(
            () => {
              this.snackBar.open('Email updated', null, {
                duration: 2000,
              });
              this.close();
            },
            (error) => {
              this.snackBar.open(error.error ? error.error.Message : error.message, null, {
                duration: 5000,
                panelClass: ['snackbar-error'],
              });
              this.close();
            },
          ),
        );
      } else {
        this.subs.push(
          this.emailSettingsV2Service.saveEmail(body).subscribe(
            () => {
              this.snackBar.open('Email Saved', null, {
                duration: 2000,
              });
              this.close();
            },
            (error) => {
              this.snackBar.open(error.error ? error.error.Message : error.message, null, {
                duration: 5000,
                panelClass: ['snackbar-error'],
              });
              this.close();
            },
          ),
        );
      }
    } else {
      this.errorHandler.showError('Please fill all the fields');
    }
  }

  close() {
    this.goBack.emit();
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }
}
