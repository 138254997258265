<div class="site-detail-view-container">
  <sa-subnav [subnavMenu]="subnavMenu"></sa-subnav>

  <div class="content">
    <div class="content-header">Properties</div>
    <div class="site-inputs-container" *ngIf="site">
      <ng-container *ngFor="let field of editableSiteFields; let i = index">
        <sa-detail-view-input
          #detailInput
          [label]="field.label"
          [type]="field.type"
          [value]="site[field.fieldName]"
          (onValueUpdated)="updateSite(id, field.fieldName, $event, i)"
        >
        </sa-detail-view-input>
      </ng-container>
    </div>

    <div class="content-header">Danger Zone</div>
    <div class="danger-zone">
      Deleting this site will reassign all Tasks that have this site as their destination to a different location
    </div>

    <button type="button" sa-danger-button (click)="deleteSite(id)">Delete Site</button>
  </div>
</div>
