import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrudService } from '../../../services/crud.service';
import { MineApiService } from '../../../services/api/mine.api.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sa-add-loader',
  templateUrl: './add-loader.component.html',
  styleUrls: ['./add-loader.component.scss'],
})
export class AddLoaderComponent implements OnInit {
  @Input() selectedFrac: any;
  @Input() selectedOrder: any;
  @Output() onSelectLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  selectLoader(loader) {
    this.onSelectLoader.emit(loader);
    this.goBack();
  }

  formatTime(mins) {
    if (mins > 59) {
      return `${Math.floor(mins / 60)}h ${mins % 60}m`;
    } else {
      return `${mins}m`;
    }
  }

  calculateMesh(loader) {
    let value = 0;
    this.selectedFrac.mineContracts.forEach((contract) => {
      if (contract.mineId === loader.id && contract.meshId === this.selectedOrder.mesh.id) {
        if (contract.totalOrdered) {
          value = contract.total - contract.totalOrdered;
        } else if (contract.total) {
          value = contract.total;
        }
      }
    });

    if (value >= 1000000) {
      return `${value / 1000000} MM`;
    } else {
      return `${value / 1000}k`;
    }
  }

  goBack() {
    this.onGoBack.emit(1);
  }
}
