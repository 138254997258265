import { Component, OnInit } from '@angular/core';
import { FeatureFlagService } from './services/feature-flag.service';
import { RouterStateService } from './services/router-state.service';
import { GoogleAnalyticsService } from '~services/google-analytics.service';
import { IntercomService } from './intercom.service';
import { UserService } from '~services/user.service';
import { AuthService } from '~services/auth.service';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import * as LogRocket from 'logrocket';
import { environment } from '~environments/environment';
import { VersioningService } from '~services/versioning.service';

@Component({
  selector: 'sa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'agnus-web';

  constructor(
    featureFlagService: FeatureFlagService,
    routerStateService: RouterStateService,
    gaService: GoogleAnalyticsService,
    private versionService: VersioningService,
    intercomService: IntercomService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {}

  public ngOnInit() {
    window['useLocal'] = (useLocalServer = true) => {
      localStorage.setItem('ENV_USE_LOCAL', JSON.stringify(useLocalServer));
    };

    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if (this.userService.isSubContractor()) {
          if (!this.router.url.includes('lohi') && !this.router.url.includes('invoicing')) {
            this.authService.logout();
          }
        }
      }
    });
    this.enableLogRocket();
  }

  private enableLogRocket() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const logRocketOptions = {
      release: this.versionService.currentVersion,
    };

    if (environment.production) {
      LogRocket.init('stkslu/sandi', logRocketOptions);
    } else {
      LogRocket.init('stkslu/sandi-dev', logRocketOptions);
    }

    this.authService.isLoggedIn$
      .pipe(
        filter((t) => t),
        take(1),
      )
      .subscribe(async (isLoggedIn) => {
        if (isLoggedIn) {
          const user = this.authService.user;
          const userContract = this.userService.getUserContractFromCache();

          LogRocket.identify(user.uid, {
            name: user.displayName,
            phone: user.phoneNumber,
            account: this.userService.accountId(),
            email: user.email,
            accountType: userContract?.account?.type?.name,
            role: userContract?.role,
          });
        }
      });
  }
}
