import { Injectable } from '@angular/core';
import { SubnavMenu } from '../models/subnav-menu';

@Injectable()
export class SubnavService {
  setActiveMenu(label: string, subnavMenu: SubnavMenu[]): SubnavMenu[] {
    subnavMenu.forEach((menuItem) => {
      menuItem.active = menuItem.label === label;
    });

    return subnavMenu;
  }
}
