import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'poundsToTons',
})
export class PoundsToTonsPipe implements PipeTransform {
  transform(pounds: number): any {
    if (!isNaN(pounds)) {
      if (pounds >= 2000) {
        const tons = pounds / 2000;
        const displayTons = tons % 1 === 0 ? tons : tons.toFixed(2);
        const suffix = tons === 1 ? 'Ton' : 'Tons';
        return `${displayTons} ${suffix}`;
      }
    }
    return null;
  }
}
