import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { LMOBrokerTrailerType, Subcarrier } from '~models/subcarrier.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchApiService } from '~services/api/search.api.service';
import { OrderApiService } from '~services/api/order.api.service';
import { UserApiService } from '~services/api/user.api.service';
import { AccountApiService } from '~services/api/account.api.service';
import { SubcarrierApiService } from '~services/api/subcarrier.api.service';
import { Location } from '@angular/common';
import { StoreService } from '~services/store.service';
import { MatDialog } from '@angular/material/dialog';
import { MapUtilService } from '~services/map-util.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { RouterStateService } from '~services/router-state.service';
import { RunboardService } from '~services/runboard.service';
import { OrderService } from '~services/order.service';
import { TrucksService } from '../../../trucks.service';
import { UserService } from '~services/user.service';
import { HelperService } from '~services/helper.service';
import { CrudService } from '~services/crud.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { debounceTime, distinctUntilChanged, filter, map, skip, switchMap, take } from 'rxjs/operators';
import { Truck } from '~models/truck.model';
import { ReassignTruckComponent } from '../../reassign-truck/reassign-truck.component';
import { ReassignDialogComponent } from '../../reassign-dialog/reassign-dialog.component';
import { SaErrorStateMatcher } from '~services/form-validation.service';
import * as moment from 'moment/moment';
import { DeclineDialogComponent } from '../../decline-dialog/decline-dialog.component';
import { SubcarrierChangeConfirmationDialogComponent } from '../pending-order-detail/subcarrier-change-confirmation-dialog/subcarrier-change-confirmation-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as HttpStatus from 'http-status-codes';
import { AssignSubcarrierDialogComponent } from '../pending-order-detail/assign-subcarrier-dialog/assign-subcarrier-dialog.component';
import { isOrder } from '~models/order.model';
import { DistributionCenterStorage } from '~lmo/models/distributionCenter.model';

interface Driver {
  lastOrderEndTime: string;
  id: number;
  name: string;
  phone: string;
  lastTimeSeen: string;
  lastLngLat: [number, number];
  activeSession: boolean;
  lastUsedTruck: {
    id: number;
    name: string;
  };
  deleted: boolean;
  userStatus: {
    userId: number;
    type: string;
    shortTitle: string;
    isException: boolean;
    exceptionTitle: string;
    exceptionDescription: string;
    exceptionURL: string;
    shiftEndTimestamp: string;
  };
}

@Component({
  selector: 'sa-dc-pending-order-detail',
  templateUrl: './dc-pending-order-detail.component.html',
  styleUrls: ['./dc-pending-order-detail.component.scss'],
})
export class DcPendingOrderDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') public className = 'flex-1 overflow-auto';
  private currentOrderSub: Subscription;
  formBuilt = false;
  addForm: FormGroup;
  matcher: ErrorStateMatcher;
  submitting = false;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  unassignedTrucks: any;
  selectedOrder: any;
  dcs: any;
  selectedDc: any;
  selectedDcId: number;
  selectedLoadNumber: number;
  selectedDriver: any;
  selectedLoader: any;
  loading = false;
  dispatchInProgress = false;
  trailersOptions = [];
  showSection = 1;
  selectedTruck: any;
  selectedTrailer: any;
  subscriptions: Subscription[] = [];
  pageInit = true;
  public selectedPurchaseOrder: any;
  public isLoHi = false;
  private subcarrierList = new BehaviorSubject<Subcarrier[]>([]);
  public trailerTypes = new BehaviorSubject<LMOBrokerTrailerType[]>([]);

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private searchApiService: SearchApiService,
    private orderApiService: OrderApiService,
    private userApiService: UserApiService,
    private accountApiService: AccountApiService,
    private subcarrierApiService: SubcarrierApiService,
    private location: Location,
    private store: StoreService,
    private dialog: MatDialog,
    private mapUtilService: MapUtilService,
    private errorHandler: ErrorHandlingService,
    private routerStateService: RouterStateService,
    private runboardService: RunboardService,
    private orderService: OrderService,
    public truckService: TrucksService,
    public userService: UserService,
    public helperService: HelperService,
    private crud: CrudService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    this.truckService.reset();
    this.store
      .select<Array<any>>('dcs')
      .pipe(take(1))
      .subscribe((dcs) => {
        this.dcs = dcs;
      });

    this.subscriptions.push(
      this.routerStateService.routerState$.subscribe((routerState) => {
        const newSelectedDcId = parseInt(routerState.params.id, 10);
        const newSelectedLoadNumber = parseInt(routerState.params.load, 10);
        if (newSelectedDcId !== this.selectedDcId || newSelectedLoadNumber !== this.selectedLoadNumber) {
          // Redraw the map
          if (this.selectedDcId !== newSelectedDcId) {
            this.pageInit = true;
          }
          this.selectedDcId = newSelectedDcId;
          this.selectedLoadNumber = newSelectedLoadNumber;
          this.loadData();
        }
      }),
    );

    this.subscriptions.push(
      this.store.select<Array<any>>('unassignedTrucks').subscribe((trucks) => {
        this.unassignedTrucks = trucks;
      }),
    );
    this.isLoHi = this.userService.isLoHi();
  }

  loadData() {
    this.loading = true;
    if (this.currentOrderSub) {
      this.currentOrderSub.unsubscribe();
    }

    this.getLMOBrokerTrailerTypes();
    this.store.loadSingleOrderForDC(this.selectedDcId, this.selectedLoadNumber);
    this.orderService.loadPrediction(this.selectedDcId, this.selectedLoadNumber);

    this.currentOrderSub = combineLatest([
      this.store.getDCById(this.selectedDcId),
      this.store.getSingleOrder().pipe(skip(1)),
    ])
      .pipe(filter(([selectedFrac, selectedOrder]) => !!selectedFrac && !!selectedOrder))
      .subscribe(([selectedFrac, selectedOrder]) => {
        this.selectedDc = selectedFrac;
        this.selectedOrder = selectedOrder;
        if (this.selectedOrder.purchaseOrder) {
          this.selectedPurchaseOrder = this.selectedOrder.purchaseOrder;
        }
        this.selectedLoader = this.selectedOrder.mine;
        this.loading = false;
        if (this.pageInit && isOrder(this.selectedOrder)) {
          this.mapUtilService.updateMap({ frac: this.selectedDc, state: 5, order: this.selectedOrder });
          this.pageInit = false;
        }
        if (!this.formBuilt && this.selectedDc && isOrder(selectedOrder)) {
          this.buildForm();
        }
      });
  }

  getDriver() {
    this.orderApiService.getRecommendedDriver(this.selectedOrder.id).subscribe((driver) => {
      if (driver) {
        this.selectedDriver = driver;
        this.addForm.controls['driver'].setValue(driver.name);
        this.addForm.controls['brokerTrailerTypeId'].setValue(driver.userTrailerTypeID || null);
        this.selectedTruck = driver.lastUsedTruck;
        if (driver && driver.lastUsedTruck) {
          this.addForm.controls['truck'].setValue(driver.lastUsedTruck.name);
        }
        this.addForm.get('reuse').setValue(this.getHoursRemainingHOS(driver));
      }
    });
  }

  ngOnDestroy() {
    if (this.currentOrderSub) {
      this.currentOrderSub.unsubscribe();
    }
    this.subscriptions.forEach((_) => {
      _.unsubscribe();
    });
  }

  getLMOBrokerTrailerTypes() {
    this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService.getLMOBrokerTrailerTypes().subscribe((data) => {
        const noneTrailerType = data.find((type) => type.trailerTypeName === 'None');
        data = data.filter((type) => type.trailerTypeName !== 'None');
        data.unshift(noneTrailerType);
        this.trailerTypes.next(data);
      });
    });
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  onTruckSelected(truck: Truck | string) {
    if (typeof truck === 'string') {
      const truckName = this.addForm.controls['truck'].value;
      this.searchApiService.createTruck(truckName).subscribe((_) => {
        this.addForm.controls['truck'].patchValue(_.name);
        this.selectedTruck = _;
      });
    } else {
      if (truck.isInUse) {
        const dialogRef = this.dialog.open(ReassignTruckComponent, {
          width: '20%',
          maxWidth: '968px',
          data: truck.orderId,
        });
        dialogRef.afterClosed().subscribe((didReassign) => {
          if (didReassign) {
            this.selectedTruck = truck;
            this.addForm.controls['truck'].setValue(truck.name);
          }
        });
      } else {
        this.selectedTruck = truck;
        this.addForm.controls['truck'].setValue(truck.name);
      }
    }
  }

  onTrailerSelected(optionID) {
    if (typeof optionID === 'string') {
      const trailerName = this.addForm.controls['trailer'].value;
      this.searchApiService.createTrailer(trailerName).subscribe((_) => {
        this.addForm.controls['trailer'].patchValue(_.name);
        delete _.inUse;
        this.selectedTrailer = _;
      });
    } else {
      const [trailer] = this.trailersOptions.filter((t) => t.id === optionID);
      if (trailer.inUse) {
        this.addForm.controls['trailer'].setValue(trailer.name);
        const dialogRef = this.dialog.open(ReassignDialogComponent, {
          width: '20%',
          maxWidth: '968px',
          data: { trailer: trailer },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'complete') {
            this.orderApiService.completeOrderById(trailer.order.id).subscribe(
              () => {
                this.unassignTrailer(trailer);
              },
              (err) => {
                this.errorHandler.showError(err, 5000);
              },
            );
          } else if (result === 'unassign') {
            this.orderApiService.unAssignOrderById(trailer.order.id).subscribe(
              () => {
                this.unassignTrailer(trailer);
              },
              (err) => {
                this.errorHandler.showError(err, 5000);
              },
            );
          }
        });
      } else {
        this.addForm.controls['trailer'].setValue(trailer.name);
        delete trailer.order;
        delete trailer.inUse;
        this.selectedTrailer = trailer;
      }
    }
  }

  unassignTrailer(trailer) {
    delete trailer.order;
    delete trailer.inUse;
    this.selectedTrailer = trailer;
    this.trailersOptions = this.trailersOptions.map((t) => {
      if (t.id === trailer.id) {
        return trailer;
      }
      return t;
    });

    this.store
      .select<Array<any>>('fracs')
      .pipe(
        filter((_) => _.length > 0),
        take(1),
        map((fracs) => {
          return fracs.map((frac) => {
            if (frac.orders) {
              frac.orders.forEach((order) => {
                if (order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted') {
                  if (order.trailer && order.trailer.id === trailer.id) {
                    delete order.trailer;
                  }
                }
              });
            }
            return frac;
          });
        }),
      )
      .subscribe((fracs) => this.store.set('fracs', fracs));
  }

  clearTruck() {
    if (
      (this.selectedTruck && this.selectedTruck.name !== this.addForm.controls['truck'].value) ||
      !this.selectedTruck
    ) {
      this.selectedTruck = null;
      this.addForm.controls['truck'].setValue(null);
    }
  }

  clearTrailer() {
    if (
      (this.selectedTrailer && this.selectedTrailer.name !== this.addForm.controls['trailer'].value) ||
      !this.selectedTrailer
    ) {
      this.selectedTrailer = null;
      this.addForm.controls['trailer'].setValue(null);
    }
  }

  buildForm() {
    if (!this.formBuilt) {
      this.addForm = this.fb.group({
        loader: [this.selectedOrder.mine.site.name as any, []],
        driver: [null as any, [Validators.required]],
        truck: [null as any, [Validators.required]],
        loaded: [false as boolean, [Validators.required]],
        trailer: [null as string, []],
        brokerTrailerTypeId: [null as any, []],
        reuse: [null, [Validators.required]],
        ticketNumber: [null as string, []],
        description: [null as string, []],
        lohiPreload: [false, []],
      });

      this.getDriver();

      if (this.selectedDc && this.selectedDc.distributionCenterStorages) {
        this.selectedDc.distributionCenterStorages.forEach((storage: DistributionCenterStorage) => {
          if (storage.storageType.name !== 'Box') {
            return;
          }
          if (
            storage.mesh.type !== this.selectedOrder.mesh.type &&
            !storage.supplementalMeshes?.some((sm) => sm.meshId === this.selectedOrder.mesh.id)
          ) {
            return;
          }
          if (this.selectedOrder.boxes?.length === 2) {
            this.addForm.addControl('box1Id', this.fb.control(null, [Validators.required]));
            this.addForm.addControl('box2Id', this.fb.control(null, [Validators.required]));
          } else if (!this.selectedOrder.boxes?.length || this.selectedOrder.boxes?.length === 1) {
            this.addForm.addControl('box1Id', this.fb.control(null, [Validators.required]));
          }
        });
      }

      this.matcher = new SaErrorStateMatcher();
      this.addForm.controls['truck'].valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((searchTerm) => this.truckService.searchTruck(searchTerm));

      this.addForm.controls['trailer'].valueChanges
        .pipe(
          debounceTime(400),
          switchMap((_) => this.searchApiService.searchTrailers(_)),
        )
        .subscribe((_) => {
          this.trailersOptions = _;
          this.trailersOptions.forEach((trailer) => {
            this.dcs.forEach((dc) => {
              if (dc.orders) {
                dc.orders.forEach((order) => {
                  if (order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted') {
                    if (order.trailer && order.trailer.id === trailer.id) {
                      trailer.inUse = true;
                      trailer.order = order;
                    }
                  }
                });
              }
            });
          });
        });

      this.addForm.controls['loader'].valueChanges.subscribe((_) => {
        this.mapUtilService.updateMine(_);
      });
      this.formBuilt = true;
    }
    this.loading = false;
  }

  checkForAddTruck$(): Observable<boolean> {
    if (!this.addForm.controls['truck'].value) {
      return of(false);
    }
    const truckName = this.addForm.controls['truck'].value;
    return this.truckService.truckList$.pipe(map((truckList) => truckList.every((truck) => truck.name !== truckName)));
  }

  checkForAddTrailer() {
    return (
      this.addForm.controls['trailer'].value &&
      this.trailersOptions.map((trailer) => trailer.name).indexOf(this.addForm.controls['trailer'].value) === -1
    );
  }

  reassignAndCompleteCurrentLoad(completeCurrentLoad: string) {
    this.selectedOrder.isReassign = true;
    if (completeCurrentLoad === 'complete') {
      this.selectedOrder.canDriverLoadComplete = true;
    } else if (completeCurrentLoad === 'unassign') {
      this.selectedOrder.canDriverLoadComplete = false;
    }
  }

  goBack() {
    this.location.back();
  }

  onBack(data) {
    this.showSection = data;
    // TODO: DC: show DCs on map
    // this.mapUtilService.updateMap({ frac: this.selectedFrac, state: 5, order: this.selectedOrder });
  }

  selectDriver() {
    this.showSection = 2;
  }

  selectLoader() {
    this.showSection = 3;
  }

  setDriver(driver) {
    this.selectedDriver = driver;
    this.addForm.controls['driver'].setValue(driver.name);
    if (driver.lastUsedTruck) {
      let found = false;
      this.dcs.forEach((dc) => {
        if (dc.orders) {
          dc.orders.forEach((order) => {
            if (order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted') {
              if (order.truck && order.truck.id === driver.lastUsedTruck.id) {
                found = true;
              }
            }
          });
        }
      });
      if (!found) {
        this.selectedTruck = driver.lastUsedTruck;
        this.addForm.controls['truck'].setValue(driver.lastUsedTruck.name);
      }
    }
    this.addForm.get('reuse').setValue(this.getHoursRemainingHOS(driver));
    this.addForm.controls['brokerTrailerTypeId'].setValue(driver.trailerTypeID || null);
  }

  getHoursRemainingHOS(driver: Driver): number {
    const now = moment();
    if (driver && driver.userStatus) {
      const shiftEndTimestamp = moment(driver.userStatus.shiftEndTimestamp);
      const duration = moment.duration(shiftEndTimestamp.diff(now));
      const hoursRemaining = duration.hours();
      if (hoursRemaining < 0) {
        return null;
      }
      return Math.min(hoursRemaining, 14);
    }
    return null;
  }

  setLoader(loader) {
    this.selectedLoader = loader;
    this.addForm.controls['loader'].setValue(loader.site.name);
  }

  declineOrder() {
    const choiceDialog = this.dialog.open(DeclineDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Decline Order',
        desc: `Please select a reason for declining Load #${this.selectedOrder.loadNumber}`,
        button: ['No longer needed', 'Do not have drivers', 'Change mesh', 'Other', 'Cancel'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        // TODO: DC: handle refreshing DC summaries
        // this.runboardService.reloadIfMatchedFracClusterId(this.selectedDc.id);
        this.orderApiService.declineOrder(this.selectedOrder.id, result.toString()).subscribe(
          (_) => {
            this.goBack();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  updateUnassignedDrivers() {
    this.userApiService.getUnassignedUsers().subscribe((trucks) => {
      this.store.set('unassignedTrucks', trucks);
      this.snackBar.open('Successfully Dispatched', null, {
        duration: 5000,
        panelClass: ['snackbar-success'],
      });
      this.goBack();
    });
  }

  async submit() {
    this.dispatchInProgress = true;

    const promptForSubcarrierChange = await this.featureFlagService
      .isFlagActive('brokerPromptForSubcarrierOnDispatch')
      .pipe(take(1))
      .toPromise();

    if (this.userService.isBrokerageAccount() && promptForSubcarrierChange) {
      this.subcarrierApiService.getSubcarriers().subscribe((data) => {
        this.subcarrierList.next(data);
        this.accountApiService.getUserAccount(this.selectedDriver.id).subscribe((account) => {
          if (account.id === this.userService.accountId()) {
            // check if driver already has subcarrier assigned
            this.subcarrierApiService.driverSubcarrier(this.selectedDriver.id).subscribe((subcarrier) => {
              // if driver has subcarrier assigned, show confirmation popup to change subcarrier or skip to dispatch order with same subcarrier
              if (subcarrier) {
                this.dispatchInProgress = false;
                const dialogRef = this.dialog.open(SubcarrierChangeConfirmationDialogComponent, {
                  data: {
                    subcarrierName: subcarrier.name,
                  },
                  width: '50%',
                  maxWidth: '50%',
                });
                const sub = dialogRef.componentInstance.onChangeSubcarrier.subscribe((change) => {
                  if (change) {
                    this.promptAssignSubcarrier();
                  } else {
                    this.dispatchOrder();
                  }
                });

                dialogRef.afterClosed().subscribe(() => {
                  sub.unsubscribe();
                });
              } else {
                // if no subcarrier assigned, show popup to assign subcarrier
                this.promptAssignSubcarrier();
              }
            });
          } else {
            // if different account even though driver belongs to a subcarrier, show popup to assign subcarrier
            this.promptAssignSubcarrier();
          }
        });
      });
    } else {
      this.accountApiService.getUserAccount(this.selectedDriver.id).subscribe((account) => {
        if (account.id !== this.userService.accountId()) {
          this.subcarrierApiService.driverSubcarrier(this.selectedDriver.id).subscribe((subcarrier) => {
            if (subcarrier) {
              this.subcarrierApiService.removeDriverFromSubcarrier(this.selectedDriver.id).subscribe(
                () => {
                  this.dispatchOrder();
                },
                (err) => {
                  this.dispatchInProgress = false;
                  this.errorHandler.showError(err);
                },
              );
            } else {
              this.dispatchOrder();
            }
          });
        } else {
          this.dispatchOrder();
        }
      });
    }
  }

  private dispatchOrder() {
    if (this.addForm.valid) {
      this.selectedOrder.mine = this.selectedLoader;
      this.selectedOrder.truck = this.selectedTruck;
      if (this.selectedTrailer) {
        this.selectedOrder.trailer = this.selectedTrailer;
      }
      if (this.addForm.controls['box1Id']) {
        this.selectedOrder.boxes = [];
        this.selectedOrder.boxes.push({
          name: this.addForm.controls['box1Id'].value,
          loadWeight: this.selectedOrder.loadWeight,
        });
      }
      if (this.addForm.controls['box2Id']) {
        this.selectedOrder.boxes.push({
          name: this.addForm.controls['box2Id'].value,
          loadWeight: this.selectedOrder.loadWeight,
        });
      }

      this.selectedOrder.user = this.selectedDriver;
      this.selectedOrder.loaded = this.addForm.controls['loaded'].value;
      this.selectedOrder.hoursOfServiceRemaining = this.addForm.controls['reuse'].value;
      this.selectedOrder.ticketNumber = this.addForm.controls['ticketNumber'].value;
      this.selectedOrder.description = this.addForm.controls['description'].value;
      this.selectedOrder.lohiPreload = this.addForm.controls['lohiPreload'].value;
      this.selectedOrder.brokerTrailerTypeId = this.addForm.controls['brokerTrailerTypeId'].value;

      this.dispatchInProgress = true;
      this.orderApiService.dispatchOrder(this.selectedOrder).subscribe(
        (res) => {
          this.store.setOrder(res);
          // Until we get sockets wired up, otherwise the dispatched will go back to an unchanged screen;
          if (this.selectedDc) {
            // TODO: DC: refresh DC summary
            // this.runboardService.reloadIfMatchedFracClusterId(this.selectedFrac.cluster.id);
          }
          this.updateUnassignedDrivers();
        },
        (err: HttpErrorResponse) => {
          this.dispatchInProgress = false;
          // We no longer have access to this order
          if (err.status && err.status === HttpStatus.GONE) {
            const custName = this.selectedDc && this.selectedDc.account && this.selectedDc.account.name;
            const message = custName
              ? `No Longer Needed - ${custName} no longer needs this load. Please refresh the page to check for new loads from ${custName}.`
              : `No Longer Needed - This load is no longer needed. Please refresh the page to check for new loads.`;
            this.errorHandler.showError(message, 5000);
            this.location.back();
          } else {
            this.errorHandler.showError(err, 5000);
          }
        },
      );
    }
  }

  private promptAssignSubcarrier() {
    this.dispatchInProgress = false;
    const dialogRef = this.dialog.open(AssignSubcarrierDialogComponent, {
      data: {
        subcarrierList: this.subcarrierList.value,
        driverId: this.selectedDriver.id,
      },
      width: '50%',
      maxWidth: '50%',
    });
    const sub = dialogRef.componentInstance.onSubcarrierAssigned.subscribe((isAssigned) => {
      if (isAssigned) {
        this.dispatchOrder();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  isOrder(): boolean {
    return isOrder(this.selectedOrder);
  }

  public openInWindow(fileUrl) {
    window.open(fileUrl, '_blank');
  }

  public isImage(fileName) {
    const imageExtensions = ['.jpeg', '.jpg', '.png'];
    return imageExtensions.some((ext) => fileName.includes(ext));
  }
}
