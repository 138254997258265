import { Component, OnInit, OnDestroy } from '@angular/core';
import { RunboardService } from '../services/runboard.service';
import { map, takeWhile, endWith, switchMap } from 'rxjs/operators';
import { Observable, timer, combineLatest } from 'rxjs';
import { HelperService } from '../services/helper.service';
import { OrderSummary, UserStatus } from '../ui-components/jobs-view/frac-detail/runboard-summary.model';
import { UserService } from '~services/user.service';

@Component({
  selector: 'sa-runboard-summary',
  templateUrl: './runboard-summary.component.html',
  styleUrls: ['./runboard-summary.component.scss'],
})
export class RunboardSummaryComponent implements OnInit, OnDestroy {
  private timeRecalculateInterval = 15 * 1000; // 15 seconds.
  private getOTVSStatusClassMap: Record<number, Observable<'safe' | 'almost-expired' | 'up-for-grabs'>> = {};
  private timeLeftMap: Record<number, Observable<string>> = {};
  private hosMap: Record<number, { shiftEndTimestamp: string; obs: Observable<string> }> = {};
  public isShaleApps = this.userService.isShaleappsEmail();

  constructor(
    public runboardService: RunboardService,
    public helperService: HelperService,
    public userService: UserService,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  isVorto() {
    return this.userService.isShaleappsEmail() || this.userService.isVortoUser();
  }

  calculateHOS(shiftEndTimestamp: string) {
    const now = new Date().getTime();
    const shiftEndDate = new Date(shiftEndTimestamp).getTime();
    const secDiff = Math.max(Math.round((shiftEndDate - now) / 1000), 0);
    const minDiff = Math.floor(secDiff / 60);
    const hoursDiff = Math.floor(minDiff / 60);

    if (hoursDiff >= 1) {
      return `${hoursDiff}h ${minDiff % 60}m`;
    }
    return `${minDiff}m`;
  }

  getCountdown(order: OrderSummary): Observable<string> {
    if (!this.timeLeftMap[order.upForGrabsTimestamp]) {
      this.timeLeftMap[order.upForGrabsTimestamp] = timer(0, this.timeRecalculateInterval).pipe(
        map(() => this.helperService.calculateTimeUntil(order.upForGrabsTimestamp)),
      );
    }
    return this.timeLeftMap[order.upForGrabsTimestamp];
  }

  getOTVSStatusClass(order: OrderSummary): Observable<'safe' | 'almost-expired' | 'up-for-grabs' | 'urgent'> {
    if (!this.getOTVSStatusClassMap[order.upForGrabsTimestamp]) {
      this.getOTVSStatusClassMap[order.upForGrabsTimestamp] = timer(0, this.timeRecalculateInterval).pipe(
        map(() => {
          if (order.urgent) {
            return 'urgent';
          }
          const minutesAgo = getMinutesAgo(order.upForGrabsTimestamp);
          if (minutesAgo < -5) {
            return 'safe';
          }
          if (minutesAgo < 0) {
            return 'almost-expired';
          }
          return 'up-for-grabs';
        }),
        takeWhile((value) => value !== 'up-for-grabs'),
        endWith('up-for-grabs'),
      );
    }
    return this.getOTVSStatusClassMap[order.upForGrabsTimestamp];
  }

  getHos(userStatus: UserStatus): Observable<string> {
    if (
      !this.hosMap[userStatus.userId] ||
      this.hosMap[userStatus.userId].shiftEndTimestamp !== userStatus.shiftEndTimestamp
    ) {
      this.hosMap[userStatus.userId] = {
        shiftEndTimestamp: userStatus.shiftEndTimestamp,
        obs: timer(0, 30 * 1000).pipe(
          // Update every 30 seconds so we never get too out of sync with minute changes
          map(() => this.calculateHOS(userStatus.shiftEndTimestamp)),
        ),
      };
    }
    return this.hosMap[userStatus.userId].obs;
  }

  trackByOrderId(_index: number, order: OrderSummary): number {
    return order.orderId;
  }

  noContent(): Observable<boolean> {
    return this.runboardService.driverPool$.pipe(
      switchMap(() =>
        combineLatest(
          this.runboardService.needsDriver$,
          this.runboardService.notAvailableForLoads$,
          this.runboardService.activeDrivers$,
          this.runboardService.availableUndispatchedDriver$,
        ),
      ),
      map((countables) => countables.reduce((sum, countable) => sum + countable.length, 0)),
      map((count) => count === 0),
    );
  }
}

function getMinutesAgo(timestamp: string): number {
  const now = new Date().getTime();
  const created = new Date(timestamp).getTime();
  return Math.round((now - created) / 1000 / 60);
}
