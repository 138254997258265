<mat-dialog-content>
  <table>
    <thead>
      <tr>
        <th>Load Number</th>
        <th>Dispatch Timestamp</th>
        <th>Accept Timestamp</th>
        <th>Complete Timestamp</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="orders.length">
        <tr *ngFor="let order of orders" (click)="selectOrder(order)">
          <td>{{ order.loadNumber }}</td>
          <td>{{ order.dispatchTimestamp | date: 'medium' }}</td>
          <td>{{ order.acceptTimestamp | date: 'medium' }}</td>
          <td>{{ (order.endTimestamp | date: 'medium') || '--' }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <h4 *ngIf="orders.length == 0">No Data Found</h4>
</mat-dialog-content>
