<div class="nav-dispatcher">
  <div class="menu-items">
    <div class="groups">
      <div class="group">
        <div class="group-header">Dispatch</div>
        <a routerLink="/map/jobs/list" routerLinkActive="true" saAutomatedSelector="menu__map">Map</a>
      </div>
      <div class="group">
        <div class="group-header">Sales</div>
        <a routerLink="/market/trucking" routerLinkActive="true" saAutomatedSelector="menu__truck-marketplace"
          >Trucking Marketplace
          <div class="icon" *ngIf="(pendingTruckingRequestCount$ | async) !== 0">
            <span class="pending">{{ pendingTruckingRequestCount$ | async }}</span>
          </div></a
        >
      </div>
      <div class="group">
        <div class="group-header">Finance</div>
        <a [routerLink]="['/billing']" routerLinkActive="true" saAutomatedSelector="menu__billing">Billing By Frac</a>
        <a
          [routerLink]="optedInToNewBillingPortal ? ['/', 'invoicing'] : ['/billing/fracs/needDataForTicketing']"
          routerLinkActive="true"
          saAutomatedSelector="menu__all_fracs_billing"
          >Billing for All Fracs</a
        >
        <span class="fake-link" saOrderSearch>Load Lookup <span class="note">(Ctrl+Enter)</span></span>
        <a routerLink="/bulk-export" routerLinkActive="true" saAutomatedSelector="menu__bulk-export">Bulk Export</a>
        <ng-container *saFeatureFlag="'reviewOnHoldOrders'">
          <a
            *saUserPermission="'review_on_hold_orders'"
            routerLink="/invoicing/orders_list/on_hold"
            routerLinkActive="true"
            saAutomatedSelector="menu__fraud"
            >Fraud Dashboard</a
          >
        </ng-container>
      </div>
      <div class="group">
        <div class="group-header">Settings</div>
        <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notifications"
          >Notifications</a
        >
        <a *ngIf="!isCrewLeader" routerLink="/users" routerLinkActive="true" saAutomatedSelector="menu__users">Users</a>
        <a *saIsBrokerage routerLink="/subcarriers">
          Manage Subcarriers
        </a>
      </div>
      <div class="group">
        <div class="group-header">Support</div>
        <span class="fake-link" (click)="showIntercom()">Contact ShaleApps</span>
        <!-- <a [href]="emailHelpLink">Contact ShaleApps</a> -->
      </div>
      <div class="group">
        <div class="group-header">GoHawk</div>
        <span class="fake-link" (click)="goToGoHawkAndroid()"
          >GoHawk Android App
          <i class="material-icons arrow-text">
            arrow_right_alt
          </i></span
        >
        <span class="fake-link" (click)="goToGoHawkIOS()"
          >GoHawk iOS App
          <i class="material-icons arrow-text">
            arrow_right_alt
          </i></span
        >
      </div>
    </div>
    <a
      class="training-videos"
      routerLink="/dispatcher/training-videos"
      routerLinkActive="true"
      saAutomatedSelector="menu__training"
      ><div class="left">
        <img src="/assets/icons/training-video-preview.png" alt="training video preview" />
      </div>
      <div class="right">
        <p><strong>2m Training Videos</strong></p>
        <p>Learn how to maximize your turn rate & improve loads / driver / day!</p>
      </div>
    </a>
  </div>
  <div class="bottom-nav">
    <div class="user">
      <div class="user-info font-medium">
        <div class="user-email">
          {{ userLabel.email || userLabel.name || 'Unknown' }}
        </div>

        <div class="user-company">
          <span *ngIf="isAdmin" class="role">Admin • </span>
          <span *ngIf="isDefault" class="role">Standard • </span>
          <span *ngIf="isViewOnly" class="role">View Only • </span>{{ userLabel.account || 'Unknown' }}
        </div>
        <ng-container *ngIf="ssoLoginUrl$ | async as ssoLoginUrl">
          <div *saFeatureFlag="'ssoSwitchAccount'" class="user-account-switch">
            <a (click)="ssoLogin()">SSO Switch Account</a>
          </div>
        </ng-container>
      </div>
      <img
        class="logout"
        src="../../../assets/icons/signout.svg"
        alt="signout"
        (click)="logout()"
        saAutomatedSelector="menu__sign_out"
      />
    </div>
  </div>
</div>
