import { Component, OnInit, Input } from '@angular/core';
import { OrderSummary } from '~models/orderSummary.model';
import { HelperService } from 'src/app/services/helper.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sa-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {
  @Input() isCompleted: boolean;
  @Input() orderSummary: OrderSummary;
  @Input() link: string[];
  public fracId: number;

  constructor(public helperService: HelperService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.fracId = +this.route.snapshot.params.id;
    if (!this.link) {
      this.link = ['./', 'order', `${this.orderSummary.loadNumber}`];
    }
  }
}
