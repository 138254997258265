import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { config } from '../../config';
import { animate, style, transition, trigger } from '@angular/animations';
import { StoreService } from '../../services/store.service';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { VersioningService } from 'src/app/services/versioning.service';
import { environment } from '~environments/environment';
import { Observable } from 'rxjs';
import { ConfigValuesService } from '~services/config-values.service';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class NavComponent {
  public ssoLoginUrl$: Observable<string>;
  @Input() visible;
  @Output() onMenuClose: EventEmitter<void> = new EventEmitter();
  featureFlagKey = environment.featureFlagKey;

  userLabel: { name: string; email: string; account: string };
  config = config;
  showWell: boolean;
  isLMO: boolean;
  isDispatcher: boolean;
  isCrewLeader: boolean;
  isSandVendor: boolean;
  isAdmin: boolean;

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        if (this.visible) {
          this.onMenuClose.emit();
        }
      }
    }
  }

  constructor(
    private authService: AuthService,
    private storeService: StoreService,
    public featureFlagService: FeatureFlagService,
    public versioningService: VersioningService,
    public userService: UserService,
    private configService: ConfigValuesService,
    private snackBar: MatSnackBar,
  ) {
    this.userLabel = userService.getLabel();
    this.showWell = userService.canShowWells();
    this.isLMO = userService.isLMOAccount();
    this.isDispatcher = userService.isDispatcherAccount();
    this.isCrewLeader = this.userService.isCrewLeader();
    this.isSandVendor = userService.isSandmanAccount();
    this.isAdmin = userService.isAdmin();
    this.ssoLoginUrl$ = this.configService.configs$.pipe(map((configs) => configs.sso_login_url));
  }

  openIntercom() {
    // (<any>window).Intercom('show');
  }

  goToGoHawkAndroid() {
    (<any>window).open('https://play.google.com/store/apps/details?id=com.shaleapps.agnus');
  }

  goToGoHawkIOS() {
    (<any>window).open('https://itunes.apple.com/US/app/id1296380634?mt=8');
  }

  close() {
    this.onMenuClose.emit();
  }

  logout() {
    this.storeService.clear();
    this.authService.logout();
  }

  public ssoLogin() {
    this.authService.ssoURL(this.authService.user.email).subscribe(
      (url) => {
        window.open(url, '_self');
      },
      (error) => {
        this.snackBar.open(error.error.Message, null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }
}
