import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-decline-order',
  templateUrl: './decline-dialog.component.html',
  styleUrls: ['./decline-dialog.component.scss'],
})
export class DeclineDialogComponent implements OnInit {
  data: any;

  constructor(public dialogRef: MatDialogRef<DeclineDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.data = _data;
  }

  ngOnInit() {}

  cancelAction() {
    this.dialogRef.close(false);
  }

  continueAction(title) {
    this.dialogRef.close(title);
  }
}
