<sa-nav [visible]="displayNav$$ | async" (onMenuClose)="displayNav$$.next(false)"></sa-nav>
<div class="top-menu">
  <div class="side-menu">
    <img class="menu-icon" src="/assets/icons/menu-24.svg" (click)="menuClick()" saAutomatedSelector="menu__open" />
  </div>
  <div class="logo-wrap"><img class="shaleapps-icon" src="/assets/icons/logo.svg" /></div>
  <div class="account-name hide-handheld">
    {{ userLabel.email || userLabel.name || 'Unknown' }}
  </div>
</div>
