<div class="add-loader-component">
  <div class="toolbar">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <h3>Select Loader</h3>
  </div>

  <div class="loader-list">
    <ng-container *ngFor="let loader of selectedFrac.fracMines; let i = index">
      <div class="loader-item" (click)="selectLoader(loader)">
        <div class="index-wrap">{{ i + 1 }}</div>
        <div class="content-wrap">
          <h3>{{ loader.site.name }}</h3>
          <h5 *ngIf="loader.travelTime">Travel Time: {{ formatTime(loader.travelTime) }}</h5>
          <h5>In & Out Time: {{ loader.waitTime || 'Not Available' }}</h5>
          <div class="mesh">
            <div>{{ selectedOrder.mesh.type }} mesh remaining:</div>
            <div>{{ calculateMesh(loader) }} lbs</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
