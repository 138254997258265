<sa-top-nav></sa-top-nav>

<div class="dispatcher-video-training">
  <div class="heading">
    Training Videos
  </div>
  <div class="videos">
    <h2>Dispatching</h2>
    <section>
      <h3>
        (1 of 7) How to onboard a driver?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357127085"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (2 of 7) How do you help a driver find their 4 digit PIN to log in?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357197121"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (3 of 7) How to accept a new well from your customer?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357129213"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (4 of 7) How to setup a cluster and create a driver run board?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357129461"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (5 of 7) How does geo-fencing work?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357130174"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (6 of 7) How do you dispatch a driver for the first time?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357130755"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (7 of 7) How do you manage a driver run board?
      </h3>
      <iframe
        src="https://player.vimeo.com/video/357130955"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
  </div>
  <div class="videos">
    <h2>Billing</h2>
    <section>
      <h3>
        (1 of 3) Needs Data for Ticketing
      </h3>
      <iframe
        src="https://player.vimeo.com/video/386833708"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (2 of 3) Ready For Invoicing
      </h3>
      <iframe
        src="https://player.vimeo.com/video/386833611"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <h3>
        (3 of 3) Accessorial Charges
      </h3>
      <iframe
        src="https://player.vimeo.com/video/387066070"
        width="640"
        height="344"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </section>
  </div>
</div>
