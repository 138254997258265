<section class="login" fxLayout="column" fxLayoutAlign="center">
  <div class="login-header">
    <img src="../../icons/shale-logo.svg" />
    <div>
      <label class="font-display">Join ShaleApps SANDi<sup style="font-size: .5em;font-weight: 600;">TM</sup></label>
    </div>
  </div>

  <div class="signupForm">
    <div>
      <form [formGroup]="loginForm" class="login-form" (ngSubmit)="register()" autocomplete="off">
        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['accountName']"
            placeholder="Account Name"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loginForm.controls['accountName'].hasError('required')">
            Account name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['userName']"
            placeholder="User Name"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loginForm.controls['userName'].hasError('required')">
            User name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['email']"
            placeholder="Email"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
            Email is required
          </mat-error>
          <mat-error
            *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')"
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['password']"
            placeholder="password"
            type="password"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['confirmPassword']"
            type="password"
            placeholder="Confirm Password"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loginForm.controls['confirmPassword'].hasError('required')">
            Password is required
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.controls['confirmPassword'].hasError('diff') &&
              !loginForm.controls['confirmPassword'].hasError('required')
            "
          >
            Both passwords are not same.
          </mat-error>
        </mat-form-field>

        <div class="login-actions">
          <div class="login-conditions">
            By clicking Sign In, you agree to our
            <a href="http://privacy.shaleapps.com" target="_blank">Terms and Conditions</a>
          </div>
          <button type="submit" sa-button>Join Now</button>
        </div>
      </form>
    </div>
  </div>
</section>
