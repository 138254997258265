<sa-nav [visible]="displayNav$$ | async" (onMenuClose)="displayNav$$.next(false)"></sa-nav>

<div class="trucking-component">
  <div class="top-menu">
    <div class="side-menu">
      <img class="menu-icon" src="../../../assets/icons/menu-24.svg" (click)="displayNav$$.next(true)" />
    </div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="../../../assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel.email || userLabel.name || 'Unknown' }}
    </div>
  </div>

  <div class="heading">
    Trucking Contracts
    <mat-form-field class="search">
      <input matInput placeholder="search" [formControl]="contractSearch" autocomplete="off" autofocus />
    </mat-form-field>
    <i class="material-icons float-plus" saAutomatedSelector="contracts__add-button" (click)="createNew$$.next({})"
      >add</i
    >
  </div>

  <mat-tab-group>
    <mat-tab label="Active">
      <ng-container *ngIf="networkActive$ | async; else activeContractTable">
        <mat-spinner [diameter]="100"></mat-spinner>
      </ng-container>
      <ng-template #activeContractTable>
        <sa-trucking-list-table [contracts]="activeContracts$"></sa-trucking-list-table>
      </ng-template>
    </mat-tab>
    <mat-tab label="Archived">
      <ng-container *ngIf="networkActive$ | async; else archivedContractTable">
        <mat-spinner [diameter]="100"></mat-spinner>
      </ng-container>
      <ng-template #archivedContractTable>
        <sa-trucking-list-table [contracts]="archivedContracts$"></sa-trucking-list-table>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<router-outlet></router-outlet>

<ng-container *ngIf="selectedContract$ | async as selectedContract">
  <div class="back-layer-component" [@fadeInOut] (click)="closeVendorForm($event)"></div>
  <sa-vendor-contract-edit
    [@slideInOut]
    [allVendors]="allVendors$ | async"
    [selectedRow]="selectedContract"
    (onCloseClicked)="closeVendorForm($event)"
  >
  </sa-vendor-contract-edit>
</ng-container>

<ng-container *ngIf="createNew$$ | async as openCreate">
  <div class="back-layer-component" [@fadeInOut] (click)="closeNewContractForm($event)"></div>
  <sa-vendor-contract-creation [@slideInOut] [clone]="openCreate" (onCloseClicked)="closeNewContractForm($event)">
  </sa-vendor-contract-creation>
</ng-container>
