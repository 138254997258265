<div class="runboard_driver_detail" *ngIf="selectedDriver$ | async as driver; else loading">
  <div class="content_sub-wrap" *ngIf="showSection == 1">
    <div class="head-wrap">
      <a class="back-wrap" routerLink="../">
        <i class="material-icons">arrow_back</i>
        Back to Runboard
      </a>
    </div>

    <h1 class="driver_name">{{ driver.userName }}</h1>
    <div class="sequence-id" *ngIf="driver.selectedOrder as selectedOrder">
      <div class="flex items-center">
        {{ selectedOrder.saUniqueId }}
        <img
          *ngIf="selectedOrder.boxPickup"
          class="w-4"
          src="/assets/icons/box_move.svg"
          alt="box move"
          matTooltip="Box Move {{ selectedOrder.boxPickup.siteName }}"
        />
      </div>
      <div>Seq. #: {{ selectedOrder.loadNumber }}</div>
    </div>
    <div class="driver_content_wrap">
      <div *ngIf="driver.selectedOrder as selectedOrder; else endShift">
        <div class="info-container">
          <h3>Order Details</h3>
          <div class="name-wrap">{{ selectedOrder?.vendor.name }}</div>
          <div class="name-detail">{{ selectedOrder?.loadWeight / 1000 }}k of {{ selectedOrder?.mesh.type }} mesh</div>
          <div class="route-desc">
            <div class="end-point" *ngIf="selectedOrder.boxPickup">
              <div class="icon-wrap flex items-center justify-center">
                <img
                  class="w-2"
                  src="/assets/icons/box_move_light.svg"
                  alt="box move"
                  matTooltip="Box Move {{ selectedOrder.boxPickup.siteName }}"
                />
              </div>
              <span>{{ selectedOrder.boxPickup.siteName }}</span>
            </div>
            <div class="end-point">
              <div class="icon-wrap" [ngClass]="{ blue: !selectedOrder?.loaded }">
                <i class="material-icons">arrow_upward</i>
              </div>
              <span>{{ selectedOrder?.mine?.site?.name }}</span>
            </div>
            <div class="end-point">
              <div class="icon-wrap" [ngClass]="{ blue: selectedOrder?.loaded }">
                <i class="material-icons">arrow_downward</i>
              </div>
              <span>{{ selectedOrder.frac | fracName }}</span>
            </div>
          </div>
          <div
            class="action-bar"
            *ngIf="selectedOrder?.orderStatus !== 'completed' && selectedOrder?.orderStatus !== 'canceled'"
          >
            <button
              class="red-btn"
              color="primary"
              mat-stroked-button
              *ngIf="!selectedOrder?.loaded"
              (click)="unassignOrder(driver.selectedOrder)"
            >
              UNASSIGN
            </button>
            <div>
              <button
                class="second-btn"
                color="primary"
                mat-stroked-button
                *ngIf="selectedOrder?.orderStatus === 'driver_accepted' && selectedOrder?.loaded === false"
                (click)="markAsLoaded(driver.selectedOrder)"
              >
                MARK AS LOADED
              </button>
              <button
                class="second-btn"
                color="primary"
                mat-stroked-button
                *ngIf="selectedOrder?.orderStatus === 'driver_accepted' && selectedOrder?.loaded === true"
                (click)="completeOrder(driver.selectedOrder)"
              >
                COMPLETE
              </button>
            </div>
            <button
              class="second-btn"
              color="primary"
              mat-stroked-button
              *ngIf="selectedOrder?.orderStatus === 'dispatched' && selectedOrder?.vendor.canAcceptLoadForDriver"
              (click)="acceptForDriver(driver.selectedOrder)"
            >
              Accept For Driver
            </button>
          </div>
          <div class="time-bar" *ngIf="selectedOrder?.orderStatus === 'completed'">
            Completed at {{ selectedOrder?.endTimestamp | date: 'H:mm LLL dd' }}
          </div>
        </div>

        <div
          class="info-container"
          *ngIf="
            selectedOrder?.orderStatus === 'canceled' &&
            selectedOrder?.purchaseOrder?.lmo?.name &&
            selectedOrder.cancelReason
          "
        >
          <h3>Canceled by {{ selectedOrder.purchaseOrder.lmo.name }}</h3>
          <div class="name-wrap">
            This order was canceled due to the following reason: "{{ selectedOrder.cancelReason }}"
          </div>
        </div>

        <div class="info-container" *ngIf="selectedOrder?.user && selectedOrder?.orderStatus !== 'completed'">
          <div class="head-wrap">
            <h3>Current Status</h3>
            <div class="timestamp">Updated {{ calculateReceivingTime(selectedOrder.user.lastTimeSeen) }} ago</div>
          </div>
          <div class="sub-content-wrap">
            <div class="main-content">
              <div class="name-wrap">{{ selectedOrder?.user?.name }}</div>
              <div class="name-detail" *ngIf="isShaleApps">({{ selectedOrder?.user?.subcontractorName }})</div>
              <div class="name-detail">Truck {{ selectedOrder?.truck?.name }}</div>
              <div class="name-detail" *ngIf="selectedOrder?.trailer">Trailer {{ selectedOrder?.trailer?.name }}</div>
              <div class="name-wrap" style="margin-top: 8px;">PO Number {{ selectedOrder?.poNumber }}</div>
            </div>
            <div class="time-details">
              <ng-container
                *ngIf="
                  selectedOrder?.user.userStatus.type === 'Online' ||
                  selectedOrder?.user.userStatus.type === 'Unreachable'
                "
              >
                <div class="timestamp">
                  <span *ngIf="selectedOrder?.etaStatus === 'arrived'">ARRIVED</span>
                  <span *ngIf="selectedOrder?.etaStatus === 'staging'">STAGING</span>
                  <span *ngIf="selectedOrder?.etaStatus === 'none' && selectedOrder?.eta">{{
                    calculateEta(selectedOrder?.eta)
                  }}</span>
                  <span
                    *ngIf="
                      selectedOrder?.etaStatus !== 'none' &&
                      selectedOrder?.etaStatus !== 'staging' &&
                      selectedOrder?.etaStatus !== 'arrived'
                    "
                    >-</span
                  >
                </div>
                <div class="driver-status">
                  <span *ngIf="!selectedOrder?.loaded">EST. PICKUP</span>
                  <ng-container *ngIf="selectedOrder?.etaStatus === 'arrived'">
                    <div *ngIf="selectedOrder?.loaded">
                      <div>
                        {{
                          selectedOrder.fracSiteVisit?.wellEntryTimestamp ||
                            selectedOrder.fracSiteVisit?.startTimestamp | ago
                        }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <div class="action-needed" *ngIf="selectedOrder?.user.userStatus.type === 'ActionNeeded'">
                Action Needed
              </div>
            </div>
          </div>
          <div class="button-row">
            <button mat-stroked-button (click)="editLoad()">EDIT</button>
            <button mat-stroked-button (click)="swapDrivers()">SWAP DRIVER</button>
          </div>
        </div>

        <div class="info-container" *ngIf="selectedOrder?.user && selectedOrder?.orderStatus === 'completed'">
          <h3>Load Details</h3>
          <div class="item-row">
            <div class="label">Driver</div>
            <div class="value-wrap">{{ selectedOrder?.user.name || '-' }}</div>
          </div>
          <div class="item-row">
            <div class="label">Truck</div>
            <div class="value-wrap">{{ selectedOrder?.truck?.name || '-' }}</div>
          </div>
          <div class="item-row">
            <div class="label">Ticket Number</div>
            <div class="value-wrap">{{ selectedOrder?.ticketNumber || '-' }}</div>
          </div>
          <div class="item-row">
            <div class="label">BOL Number</div>
            <div class="value-wrap">{{ selectedOrder?.bolNumber || '-' }}</div>
          </div>
          <div class="item-row" *ngIf="!selectedOrder?.boxes">
            <div class="label">Load Weight</div>
            <div class="value-wrap">{{ selectedOrder?.actualLoadWeight || '-' }}</div>
          </div>
          <div class="item-row" *ngIf="!selectedOrder?.boxes">
            <div class="label">Bulk Storage Number</div>
            <div class="value-wrap">{{ selectedOrder?.bulkStorageNumber || '-' }}</div>
          </div>

          <ng-container *ngIf="selectedOrder?.boxes">
            <div *ngFor="let box of selectedOrder?.boxes; let i = index">
              <div class="item-row">
                <div class="label">Box {{ i + 1 }} ID</div>
                <div class="value-wrap">{{ box.name || '-' }}</div>
              </div>

              <div class="item-row">
                <div class="label">Box {{ i + 1 }} Load Weight</div>
                <div class="value-wrap">{{ box.actualLoadWeight || '-' }}</div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="info-container" *ngIf="selectedOrder?.user?.userStatus?.isException">
          <div class="title red-text">
            <img src="../../../../assets/icons/alarm-24.svg" alt="Alert" />
            <h2>{{ selectedOrder?.user.userStatus.exceptionTitle }}</h2>
          </div>
          <div class="content-detail">
            <ng-container>
              {{ selectedOrder?.user.userStatus.exceptionDescription }}
            </ng-container>
          </div>
          <div class="button-wrap row-reverse">
            <!--<ng-container  *ngIf="!selectedOrder?.user.userStatus.exception_resolution_url">-->
            <!--<a [href]="sanitize(selectedOrder?.user.phone)"><button mat-stroked-button color="primary">Message Driver</button></a>-->
            <!--</ng-container>-->
            <a
              *ngIf="selectedOrder?.user.userStatus.exceptionUrl"
              href="{{ selectedOrder?.user.userStatus.exceptionUrl }}"
              target="_blank"
              ><button mat-stroked-button color="primary">
                View Steps <i class="material-icons">arrow_forward</i>
              </button></a
            >
            <a href="tel:{{ selectedOrder?.user.phone }}"
              ><button mat-stroked-button color="primary">Call Driver</button></a
            >
          </div>
        </div>

        <div
          class="info-container"
          *ngIf="selectedOrder?.orderStatus === 'dispatched' || selectedOrder?.orderStatus === 'driver_accepted'"
        >
          <h3>Check On Driver</h3>
          <div class="detail-wrap">
            <div class="phone-number">{{ selectedOrder?.user.phone | phone }}</div>
            <div class="icon-wrap">
              <a href="tel:{{ selectedOrder?.user.phone }}"><i class="material-icons">call</i></a>
              <a [href]="sanitize(selectedOrder?.user.phone)"><i class="material-icons">chat_bubble</i></a>
            </div>
          </div>
        </div>

        <div
          class="info-container"
          *ngIf="selectedOrder?.orderStatus === 'dispatched' || selectedOrder?.orderStatus === 'driver_accepted'"
        >
          <h3>Attachments</h3>
          <div class="item-row">
            <div class="label">Upload BOL</div>
            <div>
              <label class="attachment-label">
                <input
                  type="file"
                  (change)="onFileChange($event, selectedOrder?.id, 'bol_image')"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                />
                <i class="material-icons upload-icon" *ngIf="uploading === ''">
                  cloud_upload
                </i>
                <mat-spinner *ngIf="uploading === 'bol_image'" [diameter]="25"></mat-spinner>
              </label>
            </div>
          </div>
          <div class="attachment-row" *ngIf="(bolAttachments$ | async)?.length">
            <div
              *ngFor="let file of bolAttachments$ | async"
              class="file-preview"
              (click)="downloadFile(file)"
              [ngClass]="{ selected: selectedFileName === file.fileName }"
            >
              <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
              <img [src]="getFileIcon(file.fileName)" alt="" />
              <p>{{ smallFileName(file.fileName) }}</p>
            </div>
          </div>
          <div class="item-row">
            <div class="label">Upload Truck Ticket</div>
            <div>
              <label class="attachment-label">
                <input
                  type="file"
                  (change)="onFileChange($event, selectedOrder?.id, 'ticket_image')"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                />
                <i class="material-icons upload-icon" *ngIf="uploading === ''">
                  cloud_upload
                </i>
                <mat-spinner *ngIf="uploading === 'ticket_image'" [diameter]="25"></mat-spinner>
              </label>
            </div>
          </div>
          <div class="attachment-row" *ngIf="(truckTicketAttachments$ | async)?.length">
            <div
              *ngFor="let file of truckTicketAttachments$ | async"
              class="file-preview"
              (click)="downloadFile(file)"
              [ngClass]="{ selected: selectedFileName === file.fileName }"
            >
              <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
              <img [src]="getFileIcon(file.fileName)" alt="" />
              <p>{{ smallFileName(file.fileName) }}</p>
            </div>
          </div>
          <div class="item-row">
            <div class="label">Upload Other</div>
            <div>
              <label class="attachment-label">
                <input
                  type="file"
                  (change)="onFileChange($event, selectedOrder?.id, 'other_image')"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                />
                <i class="material-icons upload-icon" *ngIf="uploading === ''">
                  cloud_upload
                </i>
                <mat-spinner *ngIf="uploading === 'other_image'" [diameter]="25"></mat-spinner>
              </label>
            </div>
          </div>
          <div class="attachment-row" *ngIf="(otherAttachments$ | async)?.length">
            <div
              *ngFor="let file of otherAttachments$ | async"
              class="file-preview"
              (click)="downloadFile(file)"
              [ngClass]="{ selected: selectedFileName === file.fileName }"
            >
              <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
              <img [src]="getFileIcon(file.fileName)" alt="" />
              <p>{{ smallFileName(file.fileName) }}</p>
            </div>
          </div>
        </div>

        <div class="info-container" *ngIf="selectedOrder?.reroutedTo">
          <div class="title">
            <img class="reroute-img" src="../../../../assets/icons/RerouteTo.png" alt="ReroutedFrom" />
            <h3>Rerouted To</h3>
          </div>
          <h4 *ngIf="selectedOrder?.mine.site.name !== selectedOrder?.reroutedTo.mineName">
            This order was rerouted to a new loader, {{ selectedOrder?.reroutedTo?.mineName }}
          </h4>
          <h4 *ngIf="(selectedOrder?.frac | fracName) !== selectedOrder?.reroutedTo?.fracName">
            This order was rerouted to a new destination, {{ selectedOrder?.reroutedTo.fracName }}
          </h4>
        </div>
      </div>
      <ng-template #endShift>
        <div class="info-container">
          <h3>Remove from Driver Pool</h3>
          <mat-divider></mat-divider>
          <div class="info-detail">
            This will end the driver's shift and take them out of the driver pool for this cluster.
          </div>
          <div class="button-wrap row-reverse">
            <button sa-danger-button [disabled]="endingShiftInProgress" (click)="endDriverShift()">
              End Shift
              <mat-spinner [diameter]="20" *ngIf="endingShiftInProgress"></mat-spinner>
            </button>
          </div>
        </div>
      </ng-template>
      <div class="info-container">
        <h3>Hours Remaining on Shift</h3>
        <br />
        <mat-divider></mat-divider>
        <br />
        <mat-form-field id="runboard-hos-pending">
          <mat-select
            placeholder="Update Hours Remaining on Shift"
            [value]="getHoursLeftOnShift(driver.shiftEndTimestamp)"
            (selectionChange)="updateHOS($event)"
          >
            <mat-option [value]="0">
              Do Not Reuse Driver
            </mat-option>
            <mat-option *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]" [value]="number">
              {{ number }}
              <span *ngIf="number == 1">Hour</span>
              <span *ngIf="number > 1">Hours</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showSection === 2">
    <sa-update-load [selectedOrder]="driver.selectedOrder" (onGoBack)="showSection = $event"> </sa-update-load>
  </ng-container>

  <ng-container *ngIf="showSection === 3">
    <sa-add-driver
      [selectedOrder]="driver.selectedOrder"
      [selectedFrac]="driver.selectedOrder.frac"
      (onGoBack)="onBack($event)"
      (onSelectDriver)="setDriver($event, driver)"
    >
    </sa-add-driver>
  </ng-container>
</div>
<ng-template #loading>
  <div class="spin-wrap">
    <mat-spinner [diameter]="100"></mat-spinner>
    <h4>Loading Data...</h4>
  </div>
</ng-template>
