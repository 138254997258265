<div class="job-container">
  <div class="side-menu">
    <img
      class="menu-icon"
      src="../../../assets/icons/menu-24.svg"
      (click)="menuClick()"
      saAutomatedSelector="menu__open"
    />
    <img class="shaleapps-icon" src="../../../assets/icons/logo.svg" />
    <img class="right-icon" src="../../../assets/icons/logo.svg" />
    <mat-slide-toggle *ngIf="isInternal" [checked]="unassignedUsersEnabled" (change)="toggleUnassignedUsers($event)"
      ><mat-icon>person_off</mat-icon></mat-slide-toggle
    >
  </div>

  <router-outlet></router-outlet>
</div>
