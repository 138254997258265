<i *ngIf="userService.isAdmin()" class="material-icons floating-plus" (click)="openSubcarrierDetailView({})">add</i>

<mat-table #table (matSortChange)="sortTable($event)" [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="scacCode">
    <mat-header-cell *matHeaderCellDef mat-sort-header>SCAC Code</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.scacCode }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="billingCode">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Code</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.billingCode }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="driverCount">
    <mat-header-cell *matHeaderCellDef>Driver Count</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.driverCount }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row"
      ><i *ngIf="userService.isAdmin()" class="material-icons" (click)="openSubcarrierDetailView(row)"
        >edit</i
      ></mat-cell
    >
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<sa-detail-view
  [model]="detailView"
  (onSubcarrierUpdated)="subcarrierUpdated()"
  (onCloseClicked)="closeSubcarrierDetailView()"
>
</sa-detail-view>
