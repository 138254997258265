<ng-container *ngIf="data.driver">
  <div mat-dialog-content>
    <h3 *ngIf="showScreen == 1">{{ data.driver.name }}</h3>

    <ng-container *ngIf="showScreen == 2">
      <h3>Complete Current Load</h3>
      <p *ngIf="driversOrderDetails$ | async as details; else noOrderFallback">
        Do you want to complete {{ data.driver.name }}'s current load to
        <span>{{ details.fracName }} </span>

        ?
      </p>
      <ng-template #noOrderFallback>
        <p>Do you want to complete {{ data.driver.name }}'s current load</p>
      </ng-template>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngIf="showScreen == 1">
      <a href="tel:{{ data.driver.phone }}"
        ><button mat-button color="primary" (click)="cancelAction('cancel')">Call Driver</button></a
      >
      <a [href]="sanitize(data.driver.phone)"
        ><button mat-button color="primary" (click)="cancelAction('cancel')">Message Driver</button></a
      >
      <button mat-button color="primary" (click)="callAction('location')">Open Last Location</button>
      <button mat-button color="primary" (click)="callAction('route')">Open Driver Route</button>
      <button mat-button color="primary" (click)="showScreen = 2" saAutomatedSelector="reassign_dialog__reassign">
        Reassign
      </button>
    </ng-container>
    <ng-container *ngIf="showScreen == 2">
      <button
        mat-button
        color="primary"
        (click)="continueAction('complete')"
        saAutomatedSelector="reassign_dialog__complete"
      >
        Yes
      </button>
      <button mat-button color="primary" (click)="continueAction('unassign')">No</button>
    </ng-container>
    <button mat-button color="error" mat-dialog-close="true" (click)="cancelAction('cancel')">Cancel</button>
  </div>
</ng-container>

<ng-container *ngIf="data.truck">
  <div mat-dialog-content>
    <h3 *ngIf="showScreen == 1">Unassign Driver</h3>

    <p *ngIf="showScreen == 1">
      This will remove {{ data.truck.order.user.name }} from this order. This will move the order back to the pending
      list where you can decline, edit, or reassign the order.
    </p>

    <ng-container *ngIf="showScreen == 2">
      <h3>Complete Current Load</h3>

      <p>
        Do you want to complete {{ data.truck.order.user.name }}'s current load to
        {{ data.truck.order.mine.site.name }} ?
      </p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="showScreen = 2" *ngIf="showScreen == 1">Unassign Driver</button>
    <ng-container *ngIf="showScreen == 2">
      <button
        mat-button
        color="primary"
        (click)="continueAction('complete')"
        saAutomatedSelector="reassign_dialog__complete"
      >
        Yes
      </button>
      <button mat-button color="primary" (click)="continueAction('unassign')">No</button>
    </ng-container>
    <button mat-button color="error" *ngIf="showScreen == 1" mat-dialog-close="true" (click)="cancelAction('cancel')">
      Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="data.trailer">
  <div mat-dialog-content>
    <h3 *ngIf="showScreen == 1">Unassign Driver</h3>

    <p *ngIf="showScreen == 1">
      This will remove {{ data.trailer.order.user.name }} from this order. This will move the order back to the pending
      list where you can decline, edit, or reassign the order.
    </p>

    <ng-container *ngIf="showScreen == 2">
      <h3>Complete Current Load</h3>

      <p>
        Do you want to complete {{ data.trailer.order.user.name }}'s current load to
        {{ data.trailer.order.mine.site.name }} ?
      </p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="showScreen = 2" *ngIf="showScreen == 1">Unassign Driver</button>
    <ng-container *ngIf="showScreen == 2">
      <button
        mat-button
        color="primary"
        (click)="continueAction('complete')"
        saAutomatedSelector="reassign_dialog__complete"
      >
        Yes
      </button>
      <button mat-button color="primary" (click)="continueAction('unassign')">No</button>
    </ng-container>
    <button mat-button color="error" *ngIf="showScreen == 1" mat-dialog-close="true" (click)="cancelAction('cancel')">
      Cancel
    </button>
  </div>
</ng-container>
