import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sa-lohi-preload-status',
  styleUrls: ['./lohi-preload-status.component.scss'],
  templateUrl: './lohi-preload-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LohiPreloadStatusComponent {
  @Input() public order: { loHiPreloadPaused: boolean; loHiPreloadActive: boolean };
  constructor() {}
}
