import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsApiService } from '../../services/api/constants.api.service';
import { UserService } from '../../services/user.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DistributionCenterApiService } from '~services/api/distribution-center.api.service';

export interface StorageSupplementalMeshUpdateData {
  storage?: any;
}

interface FormValue {
  meshes: {
    meshId: number;
    materialNumber: string;
    salesOrderLineItem: string;
  };
}

@Component({
  selector: 'sa-dc-storage-supplemental-mesh-update',
  templateUrl: './dc-storage-supplemental-mesh-update.component.html',
  styleUrls: ['./dc-storage-supplemental-mesh-update.component.scss'],
})
export class DCStorageSupplementalMeshUpdateComponent {
  public isHalAccount = false;
  storage;
  storages;
  meshTypes;
  storageForm: FormGroup;
  submitting = false;

  boxSizes: any[] = [1, 2];
  canEdit = false;
  maxWeight: number;

  public get supplementalMeshes(): FormArray {
    return this.storageForm?.get('meshes') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<DCStorageSupplementalMeshUpdateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: StorageSupplementalMeshUpdateData,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private constantsApiService: ConstantsApiService,
    private distributionCenterApiService: DistributionCenterApiService,
    private errorHandler: ErrorHandlingService,
    private userService: UserService,
  ) {
    this.isHalAccount = this.userService.isHalAccount();
    this.canEdit = userService.canEditWell();

    this.constantsApiService.getMeshTypes().subscribe((meshTypes) => {
      this.meshTypes = meshTypes.sort((a, b) => (a.preferredOrder < b.preferredOrder ? -1 : 1));
      this.checkAndBuildForm(data);
    });
  }

  private checkAndBuildForm(data: { storage?: any }) {
    if (this.meshTypes) {
      this.buildForm();
    }
  }

  buildForm() {
    const meshFormGroups = this.getSupplementalMeshFormGroups();

    this.storageForm = this.fb.group({
      meshes: this.fb.array(meshFormGroups),
    });
  }

  getSupplementalMeshFormGroups() {
    const forms = [];

    if (this.data?.storage?.supplementalMeshes !== null) {
      for (const mesh of this.data.storage.supplementalMeshes) {
        const meshFormGroup = this.fb.group({
          meshId: [mesh.meshId, [Validators.required]],
          materialNumber: [mesh.materialNumber, [Validators.required]],
          salesOrderLineItem: [mesh.salesOrderLineItem, [Validators.required]],
        });

        forms.push(meshFormGroup);
      }
    }

    return forms;
  }

  addSupplementalMesh() {
    this.supplementalMeshes.push(
      this.fb.group({
        meshId: [null, [Validators.required]],
        materialNumber: [null, [Validators.required]],
        salesOrderLineItem: [null, [Validators.required]],
      }),
    );
  }

  deleteSupplementalMesh(i) {
    this.supplementalMeshes.removeAt(i);
  }

  submit() {
    if (this.storageForm.valid) {
      const requestBody = (this.storageForm.value as FormValue).meshes;

      this.submitting = true;
      this.distributionCenterApiService.updateDCStorageSupplementalMesh(this.data.storage.id, requestBody).subscribe(
        (resp) => {
          this.submitting = false;
          this.snackBar.open('Storage supplemental meshes successfully edited', null, {
            duration: 5000,
          });

          this.dialogRef.close(resp);
        },
        (err) => {
          this.submitting = false;
          this.errorHandler.showError(err, 5000);
        },
      );
    }
  }
}
