import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, take, map, startWith, switchMap, tap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileUploadService } from '../../services/api/file-upload.service';
import { MineService } from 'src/app/services/mine.service';
import { EmailDateTime, EmailSetting, Mine } from '~models/index';
import { EmailSettingsService } from 'src/app/services/email-settings.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { EmailDateTimeService } from '~services/email-date-time.service';

interface UIKeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'sa-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class EmailFormComponent implements OnInit, OnDestroy {
  constructor(
    public mineService: MineService,
    public emailDateTimeService: EmailDateTimeService,
    private fb: FormBuilder,
    private emailSettingsService: EmailSettingsService,
    private snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private errorHandler: ErrorHandlingService,
  ) {
    this.email = environment.email;
  }

  mines: Mine[];
  emailForm: FormGroup;
  subs: Subscription[] = [];
  email;
  timeFormats: UIKeyValue[] = [];
  dateTimeFormats: UIKeyValue[] = [];
  dateFormats: UIKeyValue[] = [];
  currentTime = new Date();
  departureToggle = '1';
  arrivalToggle = '1';
  fieldsChanged = false;
  uploading = false;
  fileToUpload: File = null;
  filterLoader: FormControl = new FormControl();
  filteredMines: Observable<Mine[]>;

  defaultValues = {
    bolNumber: ['BOL Number', 'BOL', 'BOL #'],
    vendorName: ['Truck Carrier: Vehicle Carrier Name', 'Hauler', 'Carrier', 'Service Provider'],
    truckNumber: ['Truck-Trailer Number', 'Vehicle', 'Truck', 'Truck Number', 'Truck #'],
    arrivalTime: ['Truck Arrival Date/Time', 'Entry Timestamp'],
    departureTime: ['Truck Departure Date/Time', 'Exit Timestamp'],
    poNumber: ['Purchase Order: Purchase Order Number', 'PO Number', 'Cust PO Number', 'PO Num', 'PO #'],
    netWeight: ['Net Weight', 'Qty', 'Cust Net Weight', 'Pounds'],
    ticketNumber: ['Ticket No'],
    loadNumber: ['Reference Number'],
  };

  weightUnits = ['lbs', 'kg', 'ton'];

  @Input() selectedEmail: any;
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  saveData = (function() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function(data, fileName) {
      const blob = new Blob([data.Data], { type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    };
  })();

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.goBack.emit();
      }
    }
  }

  ngOnInit() {
    of(1)
      .pipe(
        switchMap(() => {
          return combineLatest(
            this.mineService.mines$.pipe(
              filter((mines) => mines.meta.loaded),
              take(1),
            ),
            this.emailDateTimeService.emailDateTimes$.pipe(
              filter((formats) => formats.meta.loaded),
              take(1),
            ),
          );
        }),
      )
      .subscribe(([mines, formats]) => {
        this.mines = mines.data;
        this.createDateFormats(formats.data);
        this.buildForm();
      });

    this.subs.push(
      this.mineService.mines$.pipe(map((minesData) => minesData.data)).subscribe((mines) => {
        this.mines = mines;
      }),
      this.emailDateTimeService.emailDateTimes$.pipe(map((formats) => formats.data)).subscribe((formats) => {
        this.createDateFormats(formats);
      }),
    );
  }

  private createDateFormats(formats: EmailDateTime[]) {
    this.dateFormats = formats.filter((format) => format.isDateOnly).map(this.convertToUIKeyValue);
    this.dateTimeFormats = formats.filter((format) => !format.isDateOnly).map(this.convertToUIKeyValue);
  }

  private convertToUIKeyValue(dateTimeFormat: EmailDateTime): UIKeyValue {
    return {
      key: dateTimeFormat.dateFormatGolang,
      value: dateTimeFormat.dateFormatJS,
    };
  }

  getDates(type) {
    if (type === 'arrivalTime') {
      if (this.selectedEmail.arrivalTime.indexOf('####') > -1) {
        this.arrivalToggle = '2';
        return this.selectedEmail.arrivalTime.split('####')[1];
      }
    } else if (type === 'departureTime') {
      if (this.selectedEmail.departureTime.indexOf('####') > -1) {
        this.departureToggle = '2';
        return this.selectedEmail.departureTime.split('####')[1];
      }
    }
  }

  getTime(type) {
    if (type === 'arrivalTime') {
      if (this.selectedEmail.arrivalTime.indexOf('####') > -1) {
        this.arrivalToggle = '2';
        return this.selectedEmail.arrivalTime.split('####')[0];
      } else {
        return this.selectedEmail.arrivalTime;
      }
    } else if (type === 'departureTime') {
      if (this.selectedEmail.departureTime.indexOf('####') > -1) {
        this.departureToggle = '2';
        return this.selectedEmail.departureTime.split('####')[0];
      } else {
        return this.selectedEmail.departureTime;
      }
    }
  }

  buildForm() {
    this.emailForm = this.fb.group({
      loader: [this.selectedEmail ? this.selectedEmail.loader : (null as any), [Validators.required]],
      isDateOnly: [this.selectedEmail ? this.selectedEmail.isOnlyDateGiven : false, [Validators.required]],
      canOverwrite: [this.selectedEmail ? this.selectedEmail.canOverwrite : false, [Validators.required]],
      email: [this.selectedEmail ? this.selectedEmail.email.split('@')[0] : ('' as string), [Validators.required]],
      timeParser: [this.selectedEmail ? this.selectedEmail.timeParser : ('' as string), [Validators.required]],
      fileName: [this.selectedEmail ? this.selectedEmail.fileName : ('' as string), []],
      sheetName: [this.selectedEmail ? this.selectedEmail.sheetName : ('' as string), []],
      bolNumber: [this.selectedEmail ? this.selectedEmail.bolNumber : ('' as string), []],
      vendorName: [this.selectedEmail ? this.selectedEmail.vendorName : ('' as string), []],
      truckNumber: [this.selectedEmail ? this.selectedEmail.truckNumber : ('' as string), []],
      arrivalDate: [this.selectedEmail ? this.getTime('arrivalTime') : ('' as string), []],
      arrivalTime: [this.selectedEmail ? this.getDates('arrivalTime') : ('' as string), []],
      departureDate: [this.selectedEmail ? this.getTime('departureTime') : ('' as string), []],
      departureTime: [this.selectedEmail ? this.getDates('departureTime') : ('' as string), []],
      poNumber: [this.selectedEmail ? this.selectedEmail.poNumber : ('' as string), []],
      netWeight: [this.selectedEmail ? this.selectedEmail.netWeight : ('' as string), []],
      weightUnit: [this.selectedEmail ? this.selectedEmail.weightUnit : ('' as string), []],
      ticketNumber: [this.selectedEmail ? this.selectedEmail.ticketNumber : ('' as string), []],
      loadNumber: [this.selectedEmail ? this.selectedEmail.loadNumber : ('Reference Number' as string), []],
    });
    this.setTimeParser();
    this.filteredMines = this.filterLoader.valueChanges.pipe(
      startWith<string | any>(''),
      map((name) => (name ? this._filter(name) : this.mines)),
    );

    this.subs.push(
      this.emailForm.valueChanges.subscribe((data) => {
        this.fieldsChanged = true;
      }),
    );
  }

  private setTimeParser() {
    this.selectTimeDateFormat((this.selectedEmail && this.selectedEmail.isOnlyDateGiven) || false);
    this.emailForm.controls['isDateOnly'].valueChanges.subscribe((result) => {
      this.selectTimeDateFormat(result);
    });
  }

  private selectTimeDateFormat(dateOnly) {
    if (dateOnly) {
      this.arrivalToggle = '1';
      this.departureToggle = '1';
      this.timeFormats = this.dateFormats;
    } else {
      this.timeFormats = this.dateTimeFormats;
    }
  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.mines.filter((option) => option.site.name.toLowerCase().indexOf(filterValue) > -1);
  }

  getDefaultValues(fieldName) {
    let result = '';
    this.defaultValues[fieldName].forEach((value, index) => {
      result += value;
      if (index !== this.defaultValues[fieldName].length - 1) {
        result += ', ';
      }
    });
    return result;
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileToUpload = file;
      this.openUploadDialog();
    }
  }

  setFileNull(event) {
    event.target.value = null;
    this.fileToUpload = null;
  }

  openUploadDialog() {
    this.uploading = true;
    this.fileUploadService.testUpload(this.selectedEmail.id, this.fileToUpload).subscribe(
      (result) => {
        this.uploading = false;
        this.saveData(result, 'Data.csv');
        this.snackBar.open('CSV converted successfully. Please check the downloaded data.', null, {
          duration: 5000,
        });
      },
      (err) => {
        this.uploading = false;
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  onSubmit(event) {
    if (this.emailForm.valid) {
      const body = {
        loaderId: this.emailForm.controls['loader'].value.id,
        email: this.emailForm.controls['email'].value + '@' + this.email,
        timeParser: this.emailForm.controls['timeParser'].value,
        isOnlyDateGiven: this.emailForm.controls['isDateOnly'].value,
        canOverwrite: this.emailForm.controls['canOverwrite'].value,
        fileName: this.emailForm.controls['fileName'].value,
        sheetName: this.emailForm.controls['sheetName'].value,
        bolNumber: this.emailForm.controls['bolNumber'].value,
        vendorName: this.emailForm.controls['vendorName'].value,
        truckNumber: this.emailForm.controls['truckNumber'].value,
        arrivalTime: this.emailForm.controls['arrivalDate'].value,
        departureTime: this.emailForm.controls['departureDate'].value,
        poNumber: this.emailForm.controls['poNumber'].value,
        netWeight: this.emailForm.controls['netWeight'].value,
        weightUnit: this.emailForm.controls['weightUnit'].value,
        ticketNumber: this.emailForm.controls['ticketNumber'].value,
        loadNumber: this.emailForm.controls['loadNumber'].value,
      };
      if (this.emailForm.controls['arrivalTime'].value && this.arrivalToggle === '2') {
        body.arrivalTime += '####' + this.emailForm.controls['arrivalTime'].value;
      }
      if (this.emailForm.controls['departureTime'].value && this.departureToggle === '2') {
        body.departureTime += '####' + this.emailForm.controls['departureTime'].value;
      }
      if (this.selectedEmail) {
        this.subs.push(
          this.emailSettingsService.updateEmail(this.selectedEmail.id, body).subscribe(() => {
            this.snackBar.open('Email updated', null, {
              duration: 2000,
            });
            this.close();
          }),
        );
      } else {
        this.subs.push(
          this.emailSettingsService.saveEmail(body).subscribe(() => {
            this.snackBar.open('Email Saved', null, {
              duration: 2000,
            });
            this.close();
          }),
        );
      }
    } else {
      this.errorHandler.showError('Please fill all the fields');
    }
  }

  onToggle(event, type) {
    if (type === 1) {
      if (event.value === '1') {
        this.arrivalToggle = '1';
        this.emailForm.controls['arrivalDate'].setValue('');
        this.emailForm.controls['arrivalTime'].setValue('');
      } else {
        this.arrivalToggle = '2';
      }
    } else if (type === 2) {
      if (event.value === '1') {
        this.departureToggle = '1';
        this.emailForm.controls['departureDate'].setValue('');
        this.emailForm.controls['departureTime'].setValue('');
      } else {
        this.departureToggle = '2';
      }
    }
  }

  close() {
    this.goBack.emit();
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }
}
