<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <div class="heading">
      Loaders
      <i class="material-icons floating-plus" (click)="openMineForm(null)">add</i>
    </div>

    <div class="loaders">
      <div class="spinner-wrap" *ngIf="isLoading">
        <mat-spinner [diameter]="100"></mat-spinner>
      </div>

      <mat-table *ngIf="!isLoading" #table [dataSource]="mineDataSource" matSort>
        <ng-container matColumnDef="siteID">
          <mat-header-cell *matHeaderCellDef><span class="sa-only-flag">Site ID</span></mat-header-cell>
          <mat-cell class="site-id" *matCellDef="let row" (click)="copy(row.site.id)">{{ row.site.id }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let row"
            ><a> {{ row.site.name }}</a></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="edit-row" *matCellDef="let row">
            <i (click)="openMineForm(row)" *ngIf="checkEditable(row)" class="material-icons">edit</i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="delete-row" *matCellDef="let row">
            <i (click)="promptDelete(row)" class="material-icons sa-only-flag">delete_forever</i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
