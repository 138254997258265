import { Directive, Input, HostBinding, OnInit, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[saAutomatedSelector]',
})
export class AutomatedSelectorDirective implements OnInit {
  private _automatedName: string;
  @Input()
  saAutomatedSelector;

  @Input()
  set automatedName(name: string) {
    this._automatedName = name;
    this.automatedDataName = this._automatedName;
    this.cd.markForCheck();
  }

  get automatedName(): string {
    return this._automatedName;
  }

  @HostBinding('attr.data-aid') automatedTag;

  @HostBinding('attr.data-aid-name') automatedDataName;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.automatedTag = this.saAutomatedSelector;
    this.automatedDataName = this.automatedName;
  }
}
