import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as R from 'remeda';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, mapTo, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import * as fromRouterConstants from '~lmo/lmo-routing.constants';
import {
  FcpoUpdateFromPODetail,
  FracClusterPurchaseOrder,
  FracClusterPurchaseOrderRequest,
  FracClusterPurchaseOrderUpdate,
  PurchaseOrder,
  PurchaseOrderDetail,
  PurchaseOrderUpdate,
} from '~lmo/models/purchaseOrders.model';
import { ErrorHandlingService } from '~services/error-handling.service';
import { RouterStateService } from '~services/router-state.service';
import { UserService } from '~services/user.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { LmoFracsService } from './lmo-fracs.service';

const fcpoSorter = R.sort((a: FracClusterPurchaseOrder, b: FracClusterPurchaseOrder) => {
  return a.preferredOrder < b.preferredOrder ? -1 : 1;
});

@Injectable({
  providedIn: 'root',
})
export class LmoFracFcpoService {
  private currentClusterId: number;

  private purchaseOrders$$: BehaviorSubject<Record<number, PurchaseOrder>> = new BehaviorSubject<
    Record<number, PurchaseOrder>
  >({});

  private closedPurchaseOrders$$: BehaviorSubject<Record<number, PurchaseOrder>> = new BehaviorSubject<
    Record<number, PurchaseOrder>
  >({});

  private purchaseOrdersLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private closedPurchaseOrdersLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentPurchaseOrder$$: BehaviorSubject<PurchaseOrderDetail> = new BehaviorSubject<PurchaseOrderDetail>(null);

  private currentFracFcpos$$: BehaviorSubject<Record<number, FracClusterPurchaseOrder>> = new BehaviorSubject<
    Record<number, FracClusterPurchaseOrder>
  >({});

  private currentFcpo$$: BehaviorSubject<FracClusterPurchaseOrder> = new BehaviorSubject<FracClusterPurchaseOrder>(
    null,
  );

  public get currentFracFcpos$(): Observable<FracClusterPurchaseOrder[]> {
    return this.currentFracFcpos$$.asObservable().pipe(
      map((fctvsRecord) => Object.keys(fctvsRecord).map((key) => fctvsRecord[key])),
      map(fcpoSorter),
    );
  }

  public get currentFcpo$(): Observable<FracClusterPurchaseOrder> {
    return this.currentFcpo$$.asObservable();
  }

  public get currentPurchaseOrder$(): Observable<PurchaseOrderDetail> {
    return this.currentPurchaseOrder$$.asObservable();
  }

  public get purchaseOrders$(): Observable<PurchaseOrder[]> {
    return this.purchaseOrders$$.asObservable().pipe(
      map((record) => Object.keys(record).map((key) => record[key])),
      tap((pos) => {
        pos.sort((a, b) => (a.name < b.name ? -1 : 1));
      }),
    );
  }

  public get closedPurchaseOrders$(): Observable<PurchaseOrder[]> {
    return this.closedPurchaseOrders$$.asObservable().pipe(
      map((record) => Object.keys(record).map((key) => record[key])),
      tap((pos) => {
        pos.sort((a, b) => (a.name < b.name ? -1 : 1));
      }),
    );
  }

  public get purchaseOrdersLoaded$(): Observable<boolean> {
    return this.purchaseOrdersLoaded$$.pipe(distinctUntilChanged());
  }

  public get closedPurchaseOrdersLoaded$(): Observable<boolean> {
    return this.closedPurchaseOrdersLoaded$$.pipe(distinctUntilChanged());
  }

  constructor(
    private fracService: LmoFracsService,
    private http: HttpClient,
    private routerState: RouterStateService,
    private errorService: ErrorHandlingService,
    private matSnackBar: MatSnackBar,
    private userService: UserService,
  ) {
    this.setupFracListener();
    this.subscribeToRouterFcpoIdChanges();
    this.subscribeToRouterPurchaseOrderIdChanges();
    if (this.userService.isAdmin()) {
      this.loadAccountPurchaseOrders();
      this.loadClosedAccountPurchaseOrders();
    }
  }

  public createFcpo$(request: FracClusterPurchaseOrderRequest): Observable<boolean> {
    if (this.currentClusterId) {
      if (!request.fracClusterId) {
        request = {
          ...request,
          fracClusterId: this.currentClusterId,
        };
      }
      return this.http
        .post<FracClusterPurchaseOrder>(
          `${environment.api}/frac_cluster/${this.currentClusterId}/frac_cluster_purchase_order`,
          request,
        )
        .pipe(
          tap((fcpo) => {
            this.currentFracFcpos$$.next({
              ...(this.currentFracFcpos$$.value || {}),
              [fcpo.id]: fcpo,
            });
            if (this.currentClusterId) {
              this.loadFCPOs(this.currentClusterId);
              this.loadAccountPurchaseOrders();
            }
          }),
          tap(() => {
            this.matSnackBar.open('Purchase Order was created', null, { duration: 5000 });
          }),
          mapTo(true),
          catchError((error) => {
            this.errorService.showError(error);
            console.log(error);
            return of(false);
          }),
        );
    }
    return of(false);
  }

  public updateFcpo$(fcpoId: number, request: FracClusterPurchaseOrderUpdate): Observable<boolean> {
    return this.http
      .put<FracClusterPurchaseOrder>(`${environment.api}/frac_cluster_purchase_order/${fcpoId}`, request)
      .pipe(
        tap((fcpo) => {
          this.currentFracFcpos$$.next({
            ...(this.currentFracFcpos$$.value || {}),
            [fcpo.id]: fcpo,
          });
          if (this.currentClusterId) {
            this.loadFCPOs(this.currentClusterId);
          }
        }),
        tap(() => {
          this.matSnackBar.open('Purchase Order was updated', null, { duration: 5000 });
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  public addFcpo$(request: FracClusterPurchaseOrderRequest): Observable<boolean> {
    return this.http
      .post<FracClusterPurchaseOrder>(
        `${environment.api}/frac_cluster/${request.fracClusterId}/frac_cluster_purchase_order`,
        request,
      )
      .pipe(
        tap((fcpo) => {
          this.currentFracFcpos$$.next({
            ...(this.currentFracFcpos$$.value || {}),
            [fcpo.id]: fcpo,
          });
          if (this.currentClusterId) {
            this.loadFCPOs(this.currentClusterId);
            this.loadAccountPurchaseOrders();
          }
        }),
        tap(() => {
          this.matSnackBar.open('Purchase Order was created', null, { duration: 5000 });
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
      );
  }

  public updateFcpoFromPODetail$(fcpoId: number, request: FcpoUpdateFromPODetail): Observable<boolean> {
    return this.http.put(`${environment.api}/purchase_order/frac_cluster_purchase_order/${fcpoId}`, request).pipe(
      tap(() => {
        this.matSnackBar.open('Purchase Order was updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public updatePurchaseOrder$(purchaseOrderId: number, request: PurchaseOrderUpdate): Observable<boolean> {
    return this.http.put<PurchaseOrderDetail>(`${environment.api}/purchase_order/${purchaseOrderId}`, request).pipe(
      tap((purchaseOrder) => {
        if (this.currentPurchaseOrder$$.value?.id === purchaseOrderId) {
          this.currentPurchaseOrder$$.next(purchaseOrder);
        }
      }),
      tap(() => {
        this.matSnackBar.open('Purchase Order was updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public updatePurchaseOrderDetail$(purchaseOrderId: number, request: PurchaseOrderUpdate): Observable<boolean> {
    return this.http
      .put<PurchaseOrderDetail>(`${environment.api}/purchase_order/detail/${purchaseOrderId}`, request)
      .pipe(
        tap((purchaseOrder) => {
          if (this.currentPurchaseOrder$$.value?.id === purchaseOrderId) {
            this.currentPurchaseOrder$$.next(purchaseOrder);
          }
        }),
        tap(() => {
          this.matSnackBar.open('Purchase Order was updated', null, { duration: 5000 });
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          console.log(error);
          return of(false);
        }),
      );
  }

  public removeFcpo$(fcpoId: number): Observable<boolean> {
    return this.http.delete<FracClusterPurchaseOrder>(`${environment.api}/frac_cluster_purchase_order/${fcpoId}`).pipe(
      tap(() => {
        const currentFcpos = { ...this.currentFracFcpos$$.value };
        delete currentFcpos[fcpoId];
        this.currentFracFcpos$$.next(currentFcpos);
      }),
      tap(() => {
        this.matSnackBar.open('Purchase Order was removed', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        console.log(error);
        return of(false);
      }),
    );
  }

  public createPurchaseOrder$(name: string, mineId: number, meshId: number): Observable<PurchaseOrder> {
    return this.http
      .post<PurchaseOrder>(`${environment.api}/purchase_order`, { name, mineId, meshId })
      .pipe(
        tap((po) => {
          this.purchaseOrders$$.next({
            ...this.purchaseOrders$$.value,
            [po.id]: po,
          });
        }),
      );
  }

  public closePurchaseOrder$(poId: number): Observable<boolean> {
    return this.http.post(`${environment.api}/purchase_order/${poId}/close`, {}).pipe(
      tap(() => {
        this.loadAccountPurchaseOrders();
        this.loadClosedAccountPurchaseOrders();
      }),
      tap(() => this.matSnackBar.open('Purchase Order was closed', null, { duration: 5000 })),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  private setupFracListener() {
    this.fracService.currentFrac$
      .pipe(
        distinctUntilChanged((a, b) => {
          if (!a && !b) {
            return true;
          }
          if (a && !b) {
            return false;
          }
          if (b && !a) {
            return false;
          }
          return (
            a.id === b.id && (a.fracClusterTruckingVendors || []).length === (b.fracClusterTruckingVendors || []).length
          );
        }),
      )
      .subscribe((currentFrac) => {
        if (!currentFrac) {
          this.currentClusterId = null;
          this.currentFracFcpos$$.next({});
          return;
        }
        this.currentClusterId = currentFrac.cluster.id;
        this.loadFCPOs(this.currentClusterId);
      });
  }

  private subscribeToRouterFcpoIdChanges() {
    combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.FCPO_ID),
      this.currentFracFcpos$$,
    ]).subscribe(([fcpoId, fcposRecord]) => {
      if (!fcpoId) {
        this.currentFcpo$$.next(null);
        return;
      }
      const localCopyOfFcpo: FracClusterPurchaseOrder = fcposRecord[fcpoId];
      if (localCopyOfFcpo) {
        this.currentFcpo$$.next(localCopyOfFcpo);
      } else {
        this.currentFcpo$$.next(null);
      }
    });
  }

  private subscribeToRouterPurchaseOrderIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.PURCHASE_ORDER_ID).subscribe((poId) => {
      if (!poId) {
        this.currentPurchaseOrder$$.next(null);
        return;
      }

      const poIdInt = parseInt(poId, 10);
      if (!poIdInt) {
        this.currentPurchaseOrder$$.next(null);
        return;
      }

      this.loadPurchaseOrderDetails(poIdInt);
    });
  }

  private loadPurchaseOrderDetails(poId: number) {
    this.http
      .get<PurchaseOrderDetail>(`${environment.api}/purchase_order/detail/${poId}`)
      .subscribe((purchaseOrder) => {
        this.currentPurchaseOrder$$.next(purchaseOrder);
      });
  }

  public refreshCurrentPurchaseOrder(): void {
    const currentPurchaseOrder = this.currentPurchaseOrder$$.value;
    if (!currentPurchaseOrder) {
      return;
    }
    this.loadPurchaseOrderDetails(currentPurchaseOrder.id);
  }

  public loadFCPOs(clusterId: number): void {
    this.http
      .get<FracClusterPurchaseOrder[]>(`${environment.api}/frac_cluster/${clusterId}/frac_cluster_purchase_order`)
      .subscribe((results: FracClusterPurchaseOrder[]) => {
        this.currentFracFcpos$$.next(idArrayToRecord(results));
      });
  }

  public loadAccountPurchaseOrders(): void {
    this.http.get<PurchaseOrder[]>(`${environment.api}/purchase_order`).subscribe((purchaseOrders) => {
      if (!purchaseOrders) {
        this.purchaseOrders$$.next({});
        this.purchaseOrdersLoaded$$.next(true);
        return;
      }

      const filteredPurchaseOrders = purchaseOrders.filter((po) => !po.closed);
      this.purchaseOrders$$.next(idArrayToRecord(filteredPurchaseOrders));
      this.purchaseOrdersLoaded$$.next(true);
    });
  }

  public loadClosedAccountPurchaseOrders(): void {
    this.http.get<PurchaseOrder[]>(`${environment.api}/purchase_order/closed`).subscribe((purchaseOrders) => {
      this.closedPurchaseOrders$$.next(idArrayToRecord(purchaseOrders));
      this.closedPurchaseOrdersLoaded$$.next(true);
    });
  }

  public uploadPOAttachment$(poId, fileToUpload, fileName): Observable<any[]> {
    if (fileToUpload) {
      const formData = new FormData();
      formData.append('poId', poId);
      const lastDot = fileToUpload.name.lastIndexOf('.');
      const ext = fileToUpload.name.substring(lastDot + 1);
      formData.append('file', fileToUpload, fileName + '.' + ext);
      return this.http.post<any[]>(`${environment.api}/upload_purchase_order_file`, formData);
    } else {
      return throwError('File was not set, please try again');
    }
  }

  public deletePOAttachment$(fileId: number, purchaseOrderId: number): Observable<any[]> {
    if (fileId > 0 && purchaseOrderId > 0) {
      const options = {
        body: {
          fileId,
          purchaseOrderId,
        },
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.delete<any[]>(`${environment.api}/purchase_order_file`, options);
    } else {
      return throwError('File Id and purchase order Id not present.');
    }
  }

  public pausePO(id: any, reason: string) {
    return this.http
      .post(`${environment.api}/v2/purchase_order/${id}/pause`, {
        description: reason,
      })
      .toPromise();
  }

  public unpausePO(id: any) {
    return this.http.post(`${environment.api}/v2/purchase_order/${id}/resume`, {}).toPromise();
  }
}
