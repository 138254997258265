<sa-top-nav></sa-top-nav>

<div class="email-analytics-wrap">
  <a class="back-wrap" [routerLink]="['/', 'loader-integration']">
    <i class="material-icons">arrow_back</i>
    <span>Back to Loader Integration</span>
  </a>
  <h2>Loader Integration Analytics</h2>

  <form *ngIf="analyticsForm" [formGroup]="analyticsForm" autocomplete="off" (submit)="getAnalytics()">
    <div class="date-wrap">
      <mat-form-field>
        <input
          matInput
          class="datepicker-input "
          placeholder="Choose start date"
          autocomplete="off"
          [formControl]="analyticsForm.controls['startDate']"
          [max]="todayDate"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          saAutomatedSelector="bulk-export__start-Date"
        />
        <span [owlDateTimeTrigger]="dt1"><i class="material-icons">calendar_today</i></span>
        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        <mat-error *ngIf="analyticsForm.controls['startDate'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <i class="material-icons">arrow_forward</i>

      <mat-form-field>
        <input
          matInput
          class="datepicker-input "
          placeholder="Choose end date"
          autocomplete="off"
          [min]="analyticsForm.get('startDate').value || todayDate"
          [max]="todayDate"
          [formControl]="analyticsForm.controls['endDate']"
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          saAutomatedSelector="bulk-export__end-Date"
        />
        <span [owlDateTimeTrigger]="dt2"><i class="material-icons">calendar_today</i></span>
        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
        <mat-error *ngIf="analyticsForm.controls['endDate'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="button-wrap">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        saAutomatedSelector="downloadButton"
        [disabled]="isDownloading"
      >
        {{ isDownloading ? 'Getting Analytics' : 'Get Analytics' }}
      </button>
      <mat-spinner color="white" [diameter]="25" *ngIf="isDownloading"></mat-spinner>
    </div>
  </form>

  <div class="table-wrap" *ngIf="analyticsData">
    <mat-form-field>
      <input matInput [(ngModel)]="searchTerm" (ngModelChange)="applyFilter()" placeholder="Search" />
    </mat-form-field>

    <table mat-table multiTemplateDataRows [dataSource]="analyticsData">
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef>{{ columnNameMap[column] }}</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex" (click)="getStatsByEmail(element, i)">
          {{ element[column] }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="sub-content-wrap" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="loader-wrap" *ngIf="subLoading">
              <mat-spinner [diameter]="100"></mat-spinner>
            </div>
            <table mat-table *ngIf="!subLoading" [dataSource]="element.stats">
              <ng-container matColumnDef="uniqueId">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.uniqueId }}</td>
              </ng-container>
              <ng-container matColumnDef="siteName">
                <th mat-header-cell *matHeaderCellDef>Site Name</th>
                <td mat-cell *matCellDef="let row">{{ row.siteName }}</td>
              </ng-container>
              <ng-container matColumnDef="uploadTime">
                <th mat-header-cell *matHeaderCellDef>Upload Date</th>
                <td mat-cell *matCellDef="let row">{{ row.uploadTime }}</td>
              </ng-container>
              <ng-container matColumnDef="purchaseOrderName">
                <th mat-header-cell *matHeaderCellDef>Purchase Order Name</th>
                <td mat-cell *matCellDef="let row">{{ row.purchaseOrderName }}</td>
              </ng-container>
              <ng-container matColumnDef="numberOfEmails">
                <th mat-header-cell *matHeaderCellDef>Number of Emails</th>
                <td mat-cell *matCellDef="let row">{{ row.numberOfEmails }}</td>
              </ng-container>
              <ng-container matColumnDef="numberOfOrdersFound">
                <th mat-header-cell *matHeaderCellDef>Number of Orders Found</th>
                <td mat-cell *matCellDef="let row">{{ row.numberOfOrdersFound }}</td>
              </ng-container>
              <ng-container matColumnDef="vendorOrPoNotFoundInExcel">
                <th mat-header-cell *matHeaderCellDef>Vendor/PO not found in Excel</th>
                <td mat-cell *matCellDef="let row">{{ row.vendorOrPoNotFoundInExcel }}</td>
              </ng-container>
              <ng-container matColumnDef="couldNotMatch">
                <th mat-header-cell *matHeaderCellDef>Could Not Match</th>
                <td mat-cell *matCellDef="let row">{{ row.couldNotMatch }}</td>
              </ng-container>
              <ng-container matColumnDef="truckTimingMatched">
                <th mat-header-cell *matHeaderCellDef>Truck Timing Matched</th>
                <td mat-cell *matCellDef="let row">{{ row.truckTimingMatched }}</td>
              </ng-container>
              <ng-container matColumnDef="poLoadNumberMatched">
                <th mat-header-cell *matHeaderCellDef>PO and Load Number Matched</th>
                <td mat-cell *matCellDef="let row">{{ row.poLoadNumberMatched }}</td>
              </ng-container>
              <ng-container matColumnDef="dataOverwrite">
                <th mat-header-cell *matHeaderCellDef>Data Overwrite</th>
                <td mat-cell *matCellDef="let row">{{ row.dataOverwrite }}</td>
              </ng-container>
              <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef>Download</th>
                <td mat-cell *matCellDef="let row">
                  <i class="material-icons" (click)="downloadFile(row.uniqueId)">
                    arrow_downward
                  </i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
            </table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>
