import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subcarrier } from '~models/subcarrier.model';
import { DetailViewInputComponent } from '../detail-view-input/detail-view-input.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from '~services/error-handling.service';
import { SubcarrierApiService } from '~services/api/subcarrier.api.service';

interface FormValue {
  name: string;
  scacCode: string;
  billingCode: number;
}
@Component({
  selector: 'sa-carrier-detail-view',
  templateUrl: './carrier-detail-view.component.html',
  styleUrls: ['./carrier-detail-view.component.scss'],
})
export class CarrierDetailViewComponent implements OnInit {
  public formGroup: FormGroup;
  @Input() subcarrier: Subcarrier;
  @Output() onSubcarrierUpdated: EventEmitter<Subcarrier> = new EventEmitter<Subcarrier>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  buttonDisabled = false;

  @ViewChildren('detailInput') inputs: QueryList<DetailViewInputComponent>;
  constructor(
    private subcarrierApiService: SubcarrierApiService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [this.subcarrier ? this.subcarrier.name : null, [Validators.required]],
      scacCode: [this.subcarrier ? this.subcarrier.scacCode : null],
      billingCode: [this.subcarrier ? this.subcarrier.billingCode : null, [Validators.required]],
    });
  }

  updateSubcarrier(fieldName: string): void {
    const fieldControl = this.formGroup.get(fieldName);
    if (!fieldControl.dirty) {
      return;
    }
    fieldControl.markAsPristine();
    if (this.formGroup.invalid) {
      return;
    }
    const formValue: FormValue = this.formGroup.value;
    const updatedSubcarrier = Object.assign({}, this.subcarrier, formValue);
    if (this.subcarrier.id) {
      this.subcarrierApiService.updateSubcarrier(this.subcarrier.id, updatedSubcarrier).subscribe(
        (subcarrier) => {
          this.subcarrier = subcarrier;
          this.onSubcarrierUpdated.emit(subcarrier);
          this.snackBar.open('Subcarrier successfully updated', null, {
            duration: 2000,
          });
        },
        (err) => {
          this.errorHandler.showError(err);
        },
      );
    }
  }

  removeSubcarrier(id: number): void {
    if (confirm('Are you sure you want to remove this subcarrier?')) {
      this.subcarrierApiService.archiveSubcarrier(id).subscribe(
        () => {
          this.onSubcarrierUpdated.emit(null);
          this.onClose.emit();
          this.snackBar.open('Subcarrier successfully removed', null, {
            duration: 2000,
          });
        },
        (err) => {
          this.errorHandler.showError(err);
        },
      );
    }
  }

  createSubcarrier(): void {
    if (this.formGroup.invalid) {
      return;
    }
    const formValue: FormValue = this.formGroup.value;
    this.buttonDisabled = true;
    this.subcarrierApiService.createSubcarrier(formValue).subscribe(
      (subcarrier) => {
        this.subcarrier = subcarrier;
        this.onSubcarrierUpdated.emit(subcarrier);
        this.onClose.emit();
        this.buttonDisabled = false;
        this.snackBar.open('Subcarrier successfully created', null, {
          duration: 2000,
        });
      },
      (err) => {
        this.buttonDisabled = false;
        this.errorHandler.showError(err);
      },
    );
  }

  closeClicked() {
    this.onClose.emit();
  }
}
