<sa-top-nav></sa-top-nav>

<div class="loader-analytics-component">
  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="showDashboardHeaders = true" (closed)="showDashboardHeaders = false">
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-title> {{ showDashboardHeaders ? 'Hide' : 'Show' }} Panel </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="head-wrap">
        <h2>Loader Integration Analytics</h2>
      </div>
      <form *ngIf="analyticsForm" [formGroup]="analyticsForm" autocomplete="off">
        <div class="date-wrap">
          <mat-form-field>
            <input
              matInput
              class="datepicker-input "
              placeholder="Choose start date"
              autocomplete="off"
              [formControl]="analyticsForm.controls['startDate']"
              [max]="today"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              saAutomatedSelector="bulk-export__start-Date"
            />
            <span [owlDateTimeTrigger]="dt1"><i class="material-icons">calendar_today</i></span>
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            <mat-error *ngIf="analyticsForm.controls['startDate'].hasError('required')">
              Input is required
            </mat-error>
          </mat-form-field>

          <i class="material-icons">arrow_forward</i>

          <mat-form-field>
            <input
              matInput
              class="datepicker-input "
              placeholder="Choose end date"
              autocomplete="off"
              [min]="analyticsForm.get('startDate').value || today"
              [max]="today"
              [formControl]="analyticsForm.controls['endDate']"
              [owlDateTime]="dt2"
              [owlDateTimeTrigger]="dt2"
              saAutomatedSelector="bulk-export__end-Date"
            />
            <span [owlDateTimeTrigger]="dt2"><i class="material-icons">calendar_today</i></span>
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            <mat-error *ngIf="analyticsForm.controls['endDate'].hasError('required')">
              Input is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-2">
          <mat-form-field>
            <mat-select
              placeholder="All Loaders"
              [compareWith]="compareFn"
              [formControl]="analyticsForm.controls['mine']"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'All Loaders'"
                  [noEntriesFoundLabel]="'No matching loader found'"
                  [formControl]="filterLoader"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="null">
                All Loaders
              </mat-option>

              <mat-option
                *ngFor="let mine of filteredMines$ | async; trackBy: filteredMines$ | trackByKey: 'id'"
                [value]="mine"
              >
                {{ mine.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="button-wrap ">
          <button
            mat-flat-button
            color="primary"
            saAutomatedSelector="downloadButton"
            (click)="getAnalytics()"
            [disabled]="isDownloading"
          >
            {{ isDownloading ? 'Getting Analytics' : 'Get Analytics' }}
          </button>
          <mat-spinner color="primary" [diameter]="25" *ngIf="isDownloading"></mat-spinner>
          <button mat-flat-button color="primary" (click)="openForm()" [disabled]="isDownloading">
            Upload Loader Data File
          </button>
          <button
            *saFeatureFlag="'goodsReceiptGoodsIssued'"
            mat-flat-button
            color="primary"
            (click)="openGRGIForm()"
            [disabled]="isDownloading"
          >
            Upload GR/GI File
          </button>
          <button
            *saFeatureFlag="'loaderIntegrationAnalyticsExport'"
            mat-flat-button
            color="primary"
            (click)="openExportForm()"
            [disabled]="isDownloading"
          >
            Export
          </button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let dateStatistics">
          {{ dateStatistics.date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loader Name</th>
        <td mat-cell *matCellDef="let dateStatistics">
          {{ dateStatistics.loaderName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalLoads">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Loads</th>
        <td
          mat-cell
          *matCellDef="let dateStatistics"
          [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
          [queryParams]="{ filter: 'all' }"
          class="link-cell"
        >
          <a
            [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
            [queryParams]="{ filter: 'all' }"
          >
            {{ dateStatistics.totalLoads }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalMatched">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Matched</th>
        <td
          mat-cell
          *matCellDef="let dateStatistics"
          [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
          [queryParams]="{ filter: 'matched' }"
          class="link-cell"
        >
          <a
            [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
            [queryParams]="{ filter: 'matched' }"
          >
            {{ dateStatistics.totalMatched }}
          </a>
          <span *ngIf="dateStatistics.totalLoads > 0">
            ({{ dateStatistics.totalMatched / dateStatistics.totalLoads | percent }})</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="totalUnmatched">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Not Matched</th>
        <td
          mat-cell
          *matCellDef="let dateStatistics"
          [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
          [queryParams]="{ filter: 'unmatched' }"
          class="link-cell"
        >
          <a
            [routerLink]="['/', 'loader-analytics-dashboard-v2', 'email', dateStatistics.loaderId, dateStatistics.date]"
            [queryParams]="{ filter: 'unmatched' }"
          >
            {{ dateStatistics.totalUnmatched }}
          </a>
          <span *ngIf="dateStatistics.totalLoads > 0">
            ({{ dateStatistics.totalUnmatched / dateStatistics.totalLoads | percent }})</span
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let dateStatistics; columns: displayedColumns"></tr>
    </table>
  </div>

  <sa-upload-analytics-file *ngIf="displayForm" (goBack)="closeForm()"> </sa-upload-analytics-file>
  <sa-upload-gr-gi-file *ngIf="displayGRGIForm" (goBack)="closeGRGIForm()"> </sa-upload-gr-gi-file>
</div>
