export function idArrayToRecord<T extends { id: number }>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce(
    (record: Record<string, T>, t: T) => {
      record[t.id] = t;
      return record;
    },
    {} as Record<string, T>,
  );
}
export function nameArrayToRecord<T extends { name: string }>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce(
    (record: Record<string, T>, t: T) => {
      record[t.name] = t;
      return record;
    },
    {} as Record<string, T>,
  );
}
