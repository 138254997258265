import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as R from 'remeda';
import { BehaviorSubject, combineLatest, forkJoin, interval, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { getFracName } from 'src/app/ui-components/pipes/frac-name.pipe';
import { environment } from '~environments/environment';
import {
  Alarms,
  AutoDispatchDisableResponse,
  AutopilotLevel,
  DiversionInfo,
  Frac,
  FracCluster,
  FracGraphSocketUpdate,
  FracStage,
  GhostSand,
  GhostSandPartial,
  LmoFracListSummary,
  RerouteOrderRequestData,
  StageMeshVolumeUpdate,
  StorageCurrentStock,
} from '~lmo/models/frac.model';
import { InternalNotes, InternalNotesWithNetwork } from '~lmo/models/notes.model';
import { AuthService } from '~services/auth.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { RouterStateService } from '~services/router-state.service';
import { UserService } from '~services/user.service';
import * as fromRouterConstants from '../lmo-routing.constants';

const prettyNames: Record<AutopilotLevel, string> = {
  full: 'Auto Ordering and Auto Dispatch have been turned on for ',
  off: 'Autopilot is now off for ',
  semi: 'Auto Ordering has been turned on for ',
};

export interface NptReport {
  meshId: number;
  currentStock: number;
  scheduledPumpVolume: number;
}

@Injectable({
  providedIn: 'root',
})
export class LmoFracsService {
  private fracListLoading = false;
  private fracSummaryListLoading = false;
  private fracs$$: BehaviorSubject<Record<number, Frac>> = new BehaviorSubject<Record<number, Frac>>({});
  private fracSummaries$$: BehaviorSubject<LmoFracListSummary[]> = new BehaviorSubject([]);
  private currentFrac$$: BehaviorSubject<Frac> = new BehaviorSubject<Frac>(null);
  private sandUpdateAlarms$$: BehaviorSubject<Alarms> = new BehaviorSubject(null);
  private resolvedSandUpdateAlarms$$: BehaviorSubject<Alarms> = new BehaviorSubject(null);
  // Since this comes in as a separate socket update, we are going to store and consume this separately from the frac for speed.
  private currentFracGraph$$: BehaviorSubject<FracGraphSocketUpdate> = new BehaviorSubject(null);
  private currentFracStageSelected$$: BehaviorSubject<FracStage> = new BehaviorSubject(null);
  private currentFracCurrentStage$$: BehaviorSubject<FracStage> = new BehaviorSubject(null);
  private currentFracInternalNotes$$ = new BehaviorSubject<InternalNotesWithNetwork | null>(null);
  public currentFracInternalNotes$ = this.currentFracInternalNotes$$.pipe(shareReplay(1));
  public possibleDistributionCentersForFrac$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get fracs$(): Observable<Frac[]> {
    this.loadFracList();
    return this.fracs$$
      .asObservable()
      .pipe(map((fracsRecord) => Object.keys(fracsRecord).map((fracId) => fracsRecord[fracId])));
  }

  public get fracSummaries$(): Observable<LmoFracListSummary[]> {
    this.loadFracSummaryList();
    return this.fracSummaries$$.asObservable();
  }

  public get sandUpdateAlarms$(): Observable<Alarms> {
    this.loadUnresolvedSandUpdateAlarms();
    return this.sandUpdateAlarms$$.asObservable();
  }

  public get resolvedSandUpdateAlarms$(): Observable<Alarms> {
    this.loadResolvedSandUpdateAlarms();
    return this.resolvedSandUpdateAlarms$$.asObservable();
  }

  public get currentFrac$(): Observable<Frac> {
    return this.currentFrac$$.asObservable();
  }

  public get currentFracGraph$(): Observable<FracGraphSocketUpdate> {
    return this.currentFracGraph$$.asObservable();
  }

  public get currentFracCurrentStage$(): Observable<FracStage> {
    return this.currentFracCurrentStage$$.asObservable();
  }

  public get currentFracStageSelected$(): Observable<FracStage> {
    return this.currentFracStageSelected$$.asObservable();
  }

  public get possibleDistributionCentersForFrac$(): Observable<any> {
    return this.possibleDistributionCentersForFrac$$.asObservable();
  }

  public getNameByFracId$(fracId: number): Observable<string> {
    return this.fracs$$.asObservable().pipe(
      map((fracs) => {
        const frac = fracs[fracId];
        return getFracName(frac);
      }),
    );
  }

  public getFracClusterIdByFracId$(fracId: number): Observable<number> {
    return this.fracs$$.asObservable().pipe(
      map((fracs) => {
        const frac = fracs[fracId];
        return frac.cluster.id;
      }),
    );
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private routerState: RouterStateService,
    private errorService: ErrorHandlingService,
    private snackBar: MatSnackBar,
    private featureFlagService: FeatureFlagService,
  ) {
    this.logout = this.logout.bind(this);
    this.setupAuthListener();
    this.subscribeToRouterFracIdChanges();
    this.subscribeToRouterStageNumberChanges();
    this.loadFracList();
    this.loadFracSummaryList();
    this.fracListPolling();
    this.loadPossibleDistributionCentersForFrac();
  }

  public getRecordsForCurrentFrac$(): Observable<string> {
    if (this.currentFrac$$.value) {
      return this.http.get<string>(`${environment.api}/frac/${this.currentFrac$$.value.id}/csvurl`);
    }
    return of(null);
  }

  public startBolImageExportForCurrentFrac$(
    startYear: number,
    startMonth: number,
    startDay: number,
    endYear: number,
    endMonth: number,
    endDay: number,
  ): Observable<string> {
    if (this.currentFrac$$.value) {
      return this.http
        .get<{ message: string }>(`${environment.api}/frac/${this.currentFrac$$.value.id}/bol_image_export`, {
          params: {
            endDay: `${endDay}`,
            endMonth: `${endMonth}`,
            endYear: `${endYear}`,
            startDay: `${startDay}`,
            startMonth: `${startMonth}`,
            startYear: `${startYear}`,
          },
        })
        .pipe(map((resp) => resp.message));
    }
    return of(null);
  }

  public getAutoDispatchState$(): Observable<AutoDispatchDisableResponse> {
    return this.currentFrac$$.pipe(
      take(1),
      switchMap((frac) => {
        if (!frac) {
          return of(null);
        }
        return this.http.get<boolean>(`${environment.api}/frac/${frac.id}/auto_dispatch_status`);
      }),
    );
  }

  public toggleAutoDispatch$(autoDispatch: boolean): Observable<AutoDispatchDisableResponse> {
    return this.currentFrac$$.pipe(
      take(1),
      switchMap((frac) => {
        if (!frac) {
          return of(null);
        }
        return this.http.post(`${environment.api}/frac/${frac.id}/toggle_auto_dispatch`, { disable: autoDispatch });
      }),
    );
  }

  public changeAutopilotLevelForCurrentFrac$(level: AutopilotLevel): Observable<boolean> {
    if (this.currentFrac$$.value) {
      return this.http
        .patch(`${environment.api}/frac_cluster/${this.currentFrac$$.value.cluster.id}/autopilot`, {
          level,
        })
        .pipe(
          tap(() => {
            const updatedFracCluster: FracCluster = {
              ...this.currentFrac$$.value.cluster,
              autopilotLevel: level,
            };
            this.currentFrac$$.next({
              ...this.currentFrac$$.value,
              cluster: updatedFracCluster,
            });
          }),
          tap(() => {
            const baseMesasge = prettyNames[level];
            const message = `${baseMesasge}${getFracName(this.currentFrac$$.value)}`;
            this.snackBar.open(message, null, { duration: 5000 });
            this.updateFracById(this.currentFrac$$.value.id, this.currentFrac$$.value);
          }),
          map((response) => {
            return true;
          }),
          catchError((error) => {
            this.errorService.showError(error);
            return of(false);
          }),
        );
    }
    return of(false);
  }

  private updateFracById(id, updatedFrac) {
    const allFracs = this.fracs$$.getValue();
    allFracs[id] = { ...updatedFrac };
    this.fracs$$.next(allFracs);
  }

  public updateStage$(stageMeshVolumes: StageMeshVolumeUpdate[]): Observable<boolean> {
    if (!this.currentFrac$$.value || !this.currentFracStageSelected$$.value) {
      this.errorService.showError('No active Frac or Frac Stage');
      return of(false);
    }
    const fracId = this.currentFrac$$.value.id;
    const stageNumber = this.currentFracStageSelected$$.value.stageNumber;

    interface FracStageUpdateReq {
      fracId: number;
      stageNumber: number;
      stageMeshVolumes: StageMeshVolumeUpdate[];
    }

    const fracStageUpdateReq: FracStageUpdateReq = {
      fracId,
      stageMeshVolumes,
      stageNumber,
    };

    return this.http.put(`${environment.api}/update_stage`, fracStageUpdateReq).pipe(
      tap((updatedStage: FracStage) => {
        const stagesToUpdate = [...this.currentFrac$$.value.fracStages];
        const indexOfUpdatedStage = stagesToUpdate.findIndex((stage) => stage.stageNumber === updatedStage.stageNumber);
        if (indexOfUpdatedStage !== -1) {
          stagesToUpdate.splice(indexOfUpdatedStage, 1, updatedStage);
          this.currentFrac$$.next({
            ...this.currentFrac$$.value,
            fracStages: stagesToUpdate,
          });
        }
      }),
      tap(() => {
        this.snackBar.open('Stage Updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public completeStage$(
    stageMeshVolumes: StageMeshVolumeUpdate[],
    storageCurrentStocks: StorageCurrentStock[],
    nptReports: NptReport[],
  ): Observable<boolean> {
    if (!this.currentFrac$$.value) {
      this.errorService.showError('No active Frac or Frac Stage');
      return of(false);
    }
    const fracId = this.currentFrac$$.value.id;
    const currentStage = this.currentFrac$$.value.currentStage;

    interface FracStageCompleteReq {
      stageMeshVolumes: StageMeshVolumeUpdate[];
      storageCurrentStocks: StorageCurrentStock[];
      nptReports: NptReport[];
    }

    const fracStageCompleteReq: FracStageCompleteReq = {
      nptReports,
      stageMeshVolumes,
      storageCurrentStocks,
    };

    return this.http
      .post(`${environment.api}/frac/${fracId}/stage/${currentStage}/complete`, fracStageCompleteReq)
      .pipe(
        tap((updatedStage: FracStage) => {
          const stagesToUpdate = [...this.currentFrac$$.value.fracStages];
          const indexOfUpdatedStage = stagesToUpdate.findIndex(
            (stage) => stage.stageNumber === updatedStage.stageNumber,
          );
          if (indexOfUpdatedStage !== -1) {
            stagesToUpdate.splice(indexOfUpdatedStage, 1, updatedStage);
            this.currentFrac$$.next({
              ...this.currentFrac$$.value,
              currentStage: this.currentFrac$$.value.currentStage + 1,
              fracStages: stagesToUpdate,
            });
          }
        }),
        tap(() => {
          this.snackBar.open(`Stage ${currentStage} Completed`, null, { duration: 5000 });
        }),
        tap(() => {
          this.reloadCurrentFrac$().subscribe();
        }),
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
      );
  }

  public deleteStage$(): Observable<boolean> {
    if (!this.currentFrac$$.value) {
      this.errorService.showError('No active Frac or Frac Stage');
      return of(false);
    }
    const fracId = this.currentFrac$$.value.id;

    return this.http.delete(`${environment.api}/frac/${fracId}/stage/last`).pipe(
      tap((updatedFrac: Frac) => {
        this.currentFrac$$.next(updatedFrac);
      }),
      tap(() => {
        this.snackBar.open('Stage Deleted', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public updateGhostSands$(ghostSandsPartial: GhostSandPartial[]): Observable<boolean> {
    if (!this.currentFrac$$.value) {
      this.errorService.showError('No active Frac or Frac Stage');
      return of(false);
    }
    const fracId = this.currentFrac$$.value.id;
    const ghostSands: GhostSand[] = ghostSandsPartial.map((ghostSand) => ({
      ...ghostSand,
      fracId,
    }));
    const updates: Observable<null>[] = ghostSands.map((ghostSand) =>
      this.http.post<null>(`${environment.api}/sand/storage/${ghostSand.storageId}/ghost_sand`, ghostSand),
    );
    return forkJoin(updates).pipe(
      switchMap(() => this.reloadCurrentFrac$()),
      tap(() => {
        this.snackBar.open('Current Stock Updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  private setupAuthListener() {
    this.authService.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        filter((isLoggedIn) => !isLoggedIn),
      )
      .subscribe(this.logout);
  }

  private loadFracList() {
    if (!this.fracListLoading) {
      this.fracListLoading = true;
      this.http.get<Frac[]>(`${environment.api}/frac`).subscribe(
        (fracs) => {
          this.fracListLoading = false;
          const fracsRecord = fracs.reduce((record, frac) => {
            record[frac.id] = frac;
            return record;
          }, {});
          this.fracs$$.next(fracsRecord);
        },
        (error) => {
          this.fracListLoading = false;
        },
      );
    }
  }

  private loadFracSummaryList() {
    if (!this.fracSummaryListLoading) {
      this.fracSummaryListLoading = true;
      this.http.get<LmoFracListSummary[]>(`${environment.api}/lmo_frac_summaries`).subscribe(
        (fracs) => {
          this.fracSummaryListLoading = false;
          fracs.sort((a, b) =>
            (a.lmoFracSummary.name || '')
              .toLocaleLowerCase()
              .localeCompare((b.lmoFracSummary.name || '').toLocaleLowerCase()),
          );
          fracs.forEach((frac) =>
            frac.vendorDetails.sort((a, b) =>
              a.truckingVendorName.toLocaleLowerCase().localeCompare(b.truckingVendorName.toLocaleLowerCase()),
            ),
          );
          this.fracSummaries$$.next(fracs);
        },
        (error) => {
          this.fracSummaryListLoading = false;
        },
      );
    }
  }

  private subscribeToRouterFracIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.FRAC_ID).subscribe((fracId) => {
      if (!fracId) {
        this.currentFrac$$.next(null);
        this.currentFracGraph$$.next(null);
        this.currentFracInternalNotes$$.next(null);
        return;
      }
      if (this.userService.isShaleappsEmail()) {
        this.loadInternalNotesIfInternalUser(fracId);
      } else {
        this.currentFracInternalNotes$$.next({
          loaded: false,
          notes: null,
        });
      }
      const localCopyOfFrac: Frac = this.fracs$$.value[fracId];
      if (localCopyOfFrac) {
        this.currentFrac$$.next(localCopyOfFrac);

        // If this is from the frac list, it won't have any currentStocks so no point in sending this information down
        if (
          localCopyOfFrac.fracStorages.every(
            (fracStorage) => fracStorage.currentStocks && fracStorage.currentStocks.length > 0,
          )
        ) {
          const fakeSocketUpdate: FracGraphSocketUpdate = {
            fracId: localCopyOfFrac.id,
            npts: localCopyOfFrac.npt || [],
            orderRecommendations: localCopyOfFrac.recommendations || [],
            rerouteRecommendations: [],
            storages: R.sortBy(localCopyOfFrac.fracStorages || [], (fracStorage) => fracStorage.mesh.preferredOrder),
          };
          this.currentFracGraph$$.next(fakeSocketUpdate);
        } else {
          this.currentFracGraph$$.next(null);
        }
      } else {
        this.currentFrac$$.next(null);
        this.currentFracGraph$$.next(null);
      }
      this.http.get<Frac>(`${environment.api}/frac/${fracId}`).subscribe((frac) => {
        this.currentFrac$$.next(frac);
        const fakeSocketUpdate: FracGraphSocketUpdate = {
          fracId: frac.id,
          npts: frac.npt || [],
          orderRecommendations: frac.recommendations || [],
          rerouteRecommendations: [],
          storages: R.sortBy(frac.fracStorages || [], (fracStorage) => fracStorage.mesh.preferredOrder),
        };
        this.currentFracGraph$$.next(fakeSocketUpdate);
        if (frac.completed) {
          this.currentFracCurrentStage$$.next(null);
        } else {
          this.currentFracCurrentStage$$.next(getStageFromFrac(frac, frac.currentStage));
        }
      });
    });
  }

  private loadInternalNotesIfInternalUser(fracId: number | string) {
    this.http
      .get<{ note: InternalNotes | null }>(`${environment.api}/frac/${fracId}/internal_notes`)
      .subscribe((notes) => {
        this.currentFracInternalNotes$$.next({
          loaded: true,
          notes: notes.note,
        });
      });
  }

  public saveInternalNotes(notes: string): Promise<{ note: InternalNotes | null }> {
    const fracId = this.currentFrac$$.value?.id;
    if (!fracId) {
      return;
    }
    return this.http
      .post<{ note: InternalNotes | null }>(`${environment.api}/frac/${fracId}/internal_notes`, {
        notes,
      })
      .pipe(
        tap((result) => {
          this.currentFracInternalNotes$$.next({
            loaded: true,
            notes: result.note,
          });
        }),
      )
      .toPromise();
  }

  private subscribeToRouterStageNumberChanges(): void {
    combineLatest([this.currentFrac$, this.routerState.listenForParamChange$(fromRouterConstants.STAGE_NUMBER)])
      .pipe(
        filter((result) => result.every((a) => !!a)),
        map(([frac, stageNumber]) => [frac, +stageNumber] as [Frac, number]),
      )
      .subscribe(([frac, stageNumber]) => {
        this.currentFracStageSelected$$.next(getStageFromFrac(frac, stageNumber));
      });
  }

  public reloadCurrentFrac$(): Observable<boolean> {
    if (this.currentFrac$$.value) {
      return this.http.get<Frac>(`${environment.api}/frac/${this.currentFrac$$.value.id}`).pipe(
        tap((frac) => {
          this.currentFrac$$.next(frac);
        }),
        map(() => true),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
      );
    }
    return of(false);
  }

  public startFrac$(): Observable<boolean> {
    if (this.currentFrac$$.value) {
      return this.http.get(`${environment.api}/frac/${this.currentFrac$$.value.id}/start_frac`).pipe(
        tap(() => {
          this.snackBar.open('Successfully started the frac', null, { duration: 5000 });
        }),
        map(() => true),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
      );
    }
  }

  private logout() {
    this.fracSummaries$$.next([]);
    this.fracs$$.next({});
    this.currentFrac$$.next(null);
    this.currentFracGraph$$.next(null);
    this.currentFracStageSelected$$.next(null);
    this.currentFracCurrentStage$$.next(null);
  }

  private fracListPolling() {
    this.routerState.routerState$
      .pipe(
        filter((state) => !!state),
        map((state) => state.url),
        switchMap((url) => {
          if (url.endsWith('lmo/frac/list')) {
            return interval(5 * 60 * 1000).pipe(
              switchMapTo(this.featureFlagService.isFlagActive('fracListPolling').pipe(take(1))),
              tap((isFlagActive) => {
                if (isFlagActive) {
                  this.loadFracSummaryList();
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  public toggleAutoPurchaseOrderAssignment$(autoPurchaseOrderAssignment: boolean): Observable<boolean> {
    if (!this.currentFrac$$.value) {
      this.errorService.showError('No active Frac or Frac Stage');
      return of(false);
    }

    const body = { autoPurchaseOrderAssignment };
    return this.http
      .patch(
        `${environment.api}/auto_procurement/fracs/${this.currentFrac$$.value.id}/toggle_auto_purchase_order_assignment`,
        body,
      )
      .pipe(
        mapTo(true),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
        tap(() => this.reloadCurrentFrac$().subscribe()),
      );
  }

  public getPossibleDiversions$(fracId: number, meshId: number): Observable<DiversionInfo[]> {
    return this.http.get(`${environment.api}/frac/${fracId}/mesh/${meshId}/possible_diversions`) as Observable<
      DiversionInfo[]
    >;
  }

  public rerouteOrders$(rerouteOrderRequestData: RerouteOrderRequestData[]): Observable<any> {
    return this.http.post(`${environment.api}/reroute_orders`, rerouteOrderRequestData);
  }

  private loadUnresolvedSandUpdateAlarms() {
    this.http.get<Alarms>(`${environment.api}/sand_update_alarms/unresolved`).subscribe((sandUpdateAlarms) => {
      this.sandUpdateAlarms$$.next(sandUpdateAlarms);
    });
  }

  private loadResolvedSandUpdateAlarms() {
    this.http.get<Alarms>(`${environment.api}/sand_update_alarms/resolved`).subscribe((sandUpdateAlarms) => {
      this.resolvedSandUpdateAlarms$$.next(sandUpdateAlarms);
    });
  }

  public resolveSandUpdateAlarm(alarmId, payload) {
    this.http.post(`${environment.api}/sand_update_alarms/${alarmId}`, payload).subscribe(() => {
      this.loadUnresolvedSandUpdateAlarms();
      this.loadResolvedSandUpdateAlarms();
    });
  }

  private loadPossibleDistributionCentersForFrac() {
    this.routerState.listenForParamChange$(fromRouterConstants.FRAC_ID).subscribe((fracId) => {
      if (!fracId) {
        this.possibleDistributionCentersForFrac$$.next(null);
        return;
      }

      this.http
        .get<any>(`${environment.api}/frac/${fracId}/possible_distribution_centers`)
        .subscribe((distributionCenters) => {
          this.possibleDistributionCentersForFrac$$.next(distributionCenters);
        });
    });
  }
}

function getStageFromFrac(frac: Frac, stageNumber: number): FracStage {
  if (!frac.fracStages) {
    return null;
  }
  return frac.fracStages.find((stage) => stage.stageNumber === stageNumber);
}
