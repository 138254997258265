import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderApiService } from '../../../services/api/order.api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, map, tap, take } from 'rxjs/operators';
import { SearchApiService } from '../../../services/api/search.api.service';
import { Subject, of, Observable } from 'rxjs';
import { Truck, Trailer } from '~models/truck.model';
import { ReassignTruckComponent } from '../../reassign-truck/reassign-truck.component';
import { TrucksService } from 'src/app/trucks.service';
import { Order } from '~models/order.model';
import { HelperService } from '~services/helper.service';
import { AccountApiService } from '~services/api/account.api.service';
import { UserService } from '~services/user.service';
import { type } from 'remeda';

@Component({
  selector: 'sa-update-load',
  templateUrl: './update-load.component.html',
  styleUrls: ['./update-load.component.scss'],
})
export class UpdateLoadComponent implements OnInit {
  _selectedOrder: Order;
  @Input() fracs: any;
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();

  originalTruckOnOrder: Truck;
  originalTrailerOnOrder: Trailer;
  truckCustomInput: Subject<string> = new Subject();
  trailerCustomInput: Subject<string> = new Subject();
  inputChanged: boolean;
  trailerInputChanged: boolean;
  maxWeight = 111000;
  trailersOptions = [];
  public trailerSetFromProfile = false;
  trailerName = '';
  showTrailerType = true;

  @Input()
  // Don't want to allow changes to come over and over again
  set selectedOrder(s) {
    if (!this._selectedOrder) {
      this._selectedOrder = s;
    }
  }

  get selectedOrder() {
    return this._selectedOrder;
  }

  constructor(
    private orderApiService: OrderApiService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private searchApiService: SearchApiService,
    private dialog: MatDialog,
    public truckService: TrucksService,
    public helperService: HelperService,
    private accountApiService: AccountApiService,
    public userService: UserService,
  ) {}

  ngOnInit() {
    this.initializeOriginalTruckAndTrailer();
    this.setupTrailerOptions();
    this.setTruckListener();
    this.setTrailerListener();
  }

  private initializeOriginalTruckAndTrailer() {
    this.originalTruckOnOrder = this._selectedOrder && this._selectedOrder.truck;
    this.originalTrailerOnOrder = this._selectedOrder && this._selectedOrder.trailer;
  }

  truckInputValueChanged(event) {
    this.truckCustomInput.next(event);
    this.checkForAddTrailer();
  }

  private setTruckListener() {
    this.truckCustomInput
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((_) => typeof _ === 'string'),
      )
      .subscribe((searchTerm) => {
        this.inputChanged = true;
        this.truckService.searchTruck(searchTerm);
      });
  }

  trailerInputValueChanged(event: string) {
    this.trailerCustomInput.next(event);

    if (typeof event === 'string' && event.trim() === '') {
      this.trailersOptions = [];
      this.selectedOrder.trailer = null;
      this.selectedOrder.trailerType = null;
    }
  }

  private setTrailerListener() {
    this.trailerCustomInput
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((searchTerm) => typeof searchTerm === 'string' && searchTerm.trim().length > 0),
      )
      .subscribe((searchTerm) => {
        this.trailerInputChanged = true;

        this.accountApiService
          .getUserAccount(this.selectedOrder.user.id)
          .pipe(
            switchMap((account) => {
              if (
                account.id === this.userService.accountId() &&
                account.id !== 958 &&
                this.selectedOrder.user.subcarrier != null
              ) {
                if (this.selectedOrder.assetStatus === 'Rented') {
                  this.searchApiService
                    .searchTrailerBySubcarrier({ subcarrierId: this.selectedOrder.user.subcarrier }, searchTerm)
                    .subscribe((trailers) => {
                      this.trailersOptions = trailers || [];
                    });
                } else {
                  this.searchApiService.searchTrailers(searchTerm, this.trailerSetFromProfile).subscribe((trailers) => {
                    this.trailersOptions = trailers || [];
                  });
                }
              } else {
                if (this.selectedOrder.trailer) {
                  this.trailerName = this.selectedOrder.trailer.name ?? searchTerm;
                }
                this.searchApiService
                  .searchTrailers(this.trailerName, this.trailerSetFromProfile)
                  .subscribe((trailers) => {
                    this.trailersOptions = trailers || [];
                  });
                return of(null);
              }
            }),
          )
          .subscribe();
      });
  }

  setupTrailerOptions(): void {
    this.accountApiService
      .getUserAccount(this.selectedOrder.user.id)
      .pipe(
        switchMap((account) => {
          if (
            account.id === this.userService.accountId() &&
            account.id !== 958 &&
            this.selectedOrder.user.subcarrier != null
          ) {
            if (this.selectedOrder.assetStatus === 'Rented') {
              this.searchApiService
                .searchTrailerBySubcarrier({ subcarrierId: this.selectedOrder.user.subcarrier }, '')
                .subscribe((trailers) => {
                  this.trailersOptions = trailers || [];
                });
            } else {
              if (this.selectedOrder.trailer) {
                this.trailerName = this.selectedOrder.trailer.name ?? null;
              }
              this.searchApiService
                .searchTrailers(this.trailerName, this.trailerSetFromProfile)
                .subscribe((trailers) => {
                  this.trailersOptions = trailers || [];
                });
            }
          } else {
            if (this.selectedOrder.trailer) {
              this.trailerName = this.selectedOrder.trailer.name ?? null;
            }
            this.searchApiService.searchTrailers(this.trailerName, this.trailerSetFromProfile).subscribe((trailers) => {
              this.trailersOptions = trailers || [];
            });
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  updateDetail() {
    const body = {
      bolNumber: this.selectedOrder.bolNumber,
      ticketNumber: this.selectedOrder.ticketNumber,
      actualLoadWeight: null,
      BulkStorageNumber: null,
      boxes: null,
      description: this.selectedOrder.description,
      truckId: this.selectedOrder && this.selectedOrder.truck ? this.selectedOrder.truck.id : null,
      trailerId: this.selectedOrder && this.selectedOrder.trailer ? this.selectedOrder.trailer.id : null,
    };

    if (!this.selectedOrder.trailer || typeof this.selectedOrder.trailer !== 'object') {
      this.snackBar.open('Please select the correct trailer from the dropdown box', null, {
        duration: 5000,
        panelClass: ['snackbar-error'],
      });
      return false;
    }

    if (!this.selectedOrder.boxes) {
      if (this.selectedOrder.actualLoadWeight !== null && this.selectedOrder.actualLoadWeight !== undefined) {
        body.actualLoadWeight = +this.selectedOrder.actualLoadWeight;
      }
      body.BulkStorageNumber = this.selectedOrder.bulkStorageNumber;
    } else {
      body.boxes = this.selectedOrder.boxes.map((box) => {
        return {
          ...box,
          actualLoadWeight: Number(box.actualLoadWeight),
        };
      });
    }

    this.orderApiService.updateDetails(this.selectedOrder.id, body).subscribe(
      (res) => {
        this.snackBar.open('Successfully Updated', null, {
          duration: 5000,
          panelClass: ['snackbar-success'],
        });
        this.goBack();
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  goBack() {
    this.onGoBack.emit(1);
  }

  public onTruckSelected(truck: Truck | string) {
    if (typeof truck === 'string') {
      this.searchApiService.createTruck(truck).subscribe((_) => {
        this.selectedOrder.truck = _;
        this.inputChanged = false;
      });
    } else {
      if (
        this.selectedOrder.truck.id !== (this.originalTruckOnOrder || { id: null }).id &&
        truck.isInUse &&
        this.selectedOrder.orderStatus !== 'completed'
      ) {
        const dialogRef = this.dialog.open(ReassignTruckComponent, {
          width: '20%',
          maxWidth: '968px',
          data: truck.orderId,
        });
        dialogRef.afterClosed().subscribe((didReassign) => {
          if (didReassign) {
            this.selectedOrder.truck = truck;
            this.inputChanged = false;
          } else {
            delete this.selectedOrder.truck;
          }
        });
      } else {
        this.inputChanged = false;
      }
    }
  }

  public clearInput() {
    if (this.inputChanged) {
      this.selectedOrder.truck = this.originalTruckOnOrder;
    }
  }

  checkForAddTruck$(): Observable<boolean> {
    if (!this.selectedOrder || !this.selectedOrder.truck) {
      return of(false);
    }
    return this.truckService.truckList$.pipe(
      map((truckList) =>
        truckList.every(
          (truck) =>
            truck.name !== this.selectedOrder.truck.name && truck.name !== (this.selectedOrder.truck as Truck | string),
        ),
      ),
    );
  }

  public onTrailerSelected(trailer: Trailer | string) {
    if (typeof trailer === 'string') {
      this.searchApiService.createTrailer(trailer).subscribe((_) => {
        this.selectedOrder.trailer = _;
        this.inputChanged = false;
      });
    } else {
      this.selectedOrder.trailer = trailer;
      this.inputChanged = false;
    }
    this.showTrailerType = false;
  }

  checkForAddTrailer(): boolean {
    if (this.selectedOrder.trailer == null && this.userService.accountId() !== 458) {
      return false;
    }

    if (!this.trailersOptions) {
      this.trailersOptions = [];
    }
    if (this.selectedOrder.assetStatus === 'Rented' && this.userService.accountId() === 458) {
      return false;
    }

    if (this.selectedOrder.assetStatus !== 'Rented' && this.trailersOptions.length === 0) {
      return true;
    }

    if (this.selectedOrder.trailer) {
      const existTrailer = this.trailersOptions.find((t) => t.name === this.selectedOrder.trailer.name);
      if (!existTrailer) {
        return true;
      }
    }
  }

  public clearTrailerInput() {
    if (this.inputChanged) {
      this.selectedOrder.trailer = this.originalTrailerOnOrder;
    }
  }

  public displayTruckFn(truck?: any): string | undefined {
    return truck && truck.name ? (truck.trailerTypeName ? `${truck.name} | ${truck.trailerTypeName}` : truck.name) : '';
  }

  get formattedTrailerDisplay(): string {
    if (typeof this.selectedOrder.trailer === 'string') {
      return this.selectedOrder.trailer;
    }

    if (this.selectedOrder?.trailer) {
      const trailer = this.selectedOrder.trailer;
      return trailer.name
        ? this.selectedOrder.trailerType
          ? `${trailer.name} | ${this.selectedOrder.trailerType.trailerTypeName}`
          : trailer.name
        : '';
    }
    return '';
  }
}
