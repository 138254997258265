import { Directive, HostListener } from '@angular/core';
import { OrderUniqueIdSearchService } from '../order-unique-id-search.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderSearchHotkeyComponent } from './order-search-hotkey/order-search-hotkey.component';

@Directive({
  selector: '[saOrderSearch]',
})
export class OrderSearchDirective {
  constructor(private orderSearch: OrderUniqueIdSearchService, private snackbar: MatSnackBar) {}

  @HostListener('click')
  onClick() {
    this.orderSearch.open();
    this.snackbar.openFromComponent(OrderSearchHotkeyComponent, { duration: 1500 });
  }
}
