<div class="sa-subnav">
  <div
    class="tab"
    *ngFor="let tab of subnavMenu"
    [ngClass]="{ active: tab.active }"
    (click)="setActiveMenu(tab.label)"
    saAutomatedSelector="{{ 'user-group__' + tab.label }}"
  >
    <span>{{ tab.label }}</span>
  </div>
</div>
