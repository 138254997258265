<div mat-dialog-content>
  <h3>{{ data.context }}</h3>
  <p [innerHTML]="data.desc"></p>
</div>
<div mat-dialog-actions>
  <button mat-button tabindex="-1" (click)="cancelAction()" saAutomatedSelector="choice-dialog__cancel-action">
    {{ data.button[0] }}
  </button>
  <button
    mat-button
    color="error"
    mat-dialog-close="true"
    (click)="continueAction()"
    cdkFocusInitial
    saAutomatedSelector="choice-dialog__continue-action"
  >
    {{ data.button[1] }}
  </button>
</div>
