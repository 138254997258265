import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { getFracName } from 'src/app/ui-components/pipes/frac-name.pipe';
import { RunboardLogisticsCluster, RunboardSiteSummary, UnclusteredRunboardSites } from '~dispatcher/models/frac.model';
import { environment } from '~environments/environment';
import { DistributionCenter } from '~lmo/models/distributionCenter.model';
import { ErrorHandlingService } from '~services/error-handling.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { RouterStateService } from '~services/router-state.service';
import { StoreService } from '~services/store.service';
import { Frac } from '~v2Models/frac.model';
import { isInProgressGroup, isPendingGroup, Order } from '~v2Models/order.model';
import * as fromRouterConstants from '../../app-routing.constants';

export interface ClusterPatchRequest {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class DispatcherFracsService {
  private fracClusterSummaries$$: BehaviorSubject<
    (RunboardLogisticsCluster | UnclusteredRunboardSites)[]
  > = new BehaviorSubject<(RunboardLogisticsCluster | UnclusteredRunboardSites)[]>(null);

  public get fracClusterSummaries$(): Observable<(RunboardLogisticsCluster | UnclusteredRunboardSites)[]> {
    return this.fracClusterSummaries$$.asObservable();
  }

  public get curentFracClusterSummary$(): Observable<RunboardLogisticsCluster> {
    return combineLatest(
      this.routerState.listenForParamChange$(fromRouterConstants.LOGISTICS_CLUSTER_ID),
      this.fracClusterSummaries$,
    ).pipe(
      map(([clusterId, summaries]) => {
        if (!clusterId || !summaries) {
          return null;
        }
        return summaries.find(
          (summary: RunboardLogisticsCluster) => summary.id === +clusterId,
        ) as RunboardLogisticsCluster;
      }),
    );
  }

  constructor(
    private store: StoreService,
    private http: HttpClient,
    private errorService: ErrorHandlingService,
    private snackbar: MatSnackBar,
    private routerState: RouterStateService,
    private featureFlagService: FeatureFlagService,
  ) {
    combineLatest([this.store.select('fracs'), this.store.select('dcs')])
      .pipe(
        map(([f, d]) => {
          const fracs = f as Frac[];
          const dcs = d as DistributionCenter[];
          if (fracs?.length || dcs?.length) {
            return convertFracsAndDCsToClusterSummaries(fracs || [], dcs || []);
          } else {
            return [];
          }
        }),
      )
      .subscribe((summaries) => {
        this.fracClusterSummaries$$.next(summaries);
      });

    this.fracListPolling();
  }

  public addFracToCluster$(clusterId: number, fracId: number): Observable<boolean> {
    return this.http.post(`${environment.api}/logistics_cluster/${clusterId}`, { fracId }).pipe(
      tap((fracs: Frac[]) => {
        this.store.set('fracs', fracs);
        // this.loadFracList();
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public removeFromFracCluster$(clusterId: number, fracId: number): Observable<boolean> {
    return this.http.delete(`${environment.api}/logistics_cluster/${clusterId}/${fracId}`).pipe(
      tap((fracs: Frac[]) => {
        this.store.set('fracs', fracs);
        // this.loadFracList();
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public createCluster$(name: string, fracIds: number[]): Observable<boolean> {
    return this.http.post(`${environment.api}/logistics_cluster`, { name, fracIds }).pipe(
      tap((fracs: Frac[]) => {
        this.store.set('fracs', fracs);
        // this.loadFracList();
      }),
      tap(() => {
        this.snackbar.open('Cluster Created', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public removeCluster$(clusterId: number): Observable<boolean> {
    return this.http.delete(`${environment.api}/logistics_cluster/${clusterId}`).pipe(
      tap((fracs: Frac[]) => {
        this.store.set('fracs', fracs);
        // this.loadFracList();
      }),
      tap(() => {
        this.snackbar.open('Cluster Removed', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public patchCluster$(clusterId: number, request: Partial<ClusterPatchRequest>): Observable<boolean> {
    return this.http.patch(`${environment.api}/logistics_cluster/${clusterId}`, request).pipe(
      tap((fracs: Frac[]) => {
        this.store.set('fracs', fracs);
        // this.loadFracList();
      }),
      tap(() => {
        this.snackbar.open('Cluster Renamed', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  private fracListPolling() {
    this.routerState.routerState$
      .pipe(
        filter((state) => !!state),
        map((state) => state.url),
        switchMap((url) => {
          if (url.endsWith('map/jobs/list')) {
            return interval(5 * 60 * 1000).pipe(
              switchMapTo(this.featureFlagService.isFlagActive('fracListPolling').pipe(take(1))),
              tap((isFlagActive) => {
                console.log('dispatcher fracs poll');
                if (isFlagActive) {
                  this.store.reloadFracList();
                  // this.loadFracList();
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }
}

function convertFracsAndDCsToClusterSummaries(
  fracs: Frac[],
  dcs: DistributionCenter[],
): (RunboardLogisticsCluster | UnclusteredRunboardSites)[] {
  const clustersRecord: Record<number, RunboardLogisticsCluster> = {};
  const unclusteredSites: UnclusteredRunboardSites = {
    isCluster: false,
    siteSummaries: [],
  };

  fracs.forEach((frac) => {
    if (frac.logisticsCluster) {
      if (!clustersRecord[frac.logisticsCluster.id]) {
        clustersRecord[frac.logisticsCluster.id] = {
          id: frac.logisticsCluster.id,
          isCluster: true,
          name: frac.logisticsCluster.name,
          siteSummaries: [],
        };
      }
      clustersRecord[frac.logisticsCluster.id].siteSummaries.push(convertFracToRunboardSiteSummary(frac));
    } else {
      unclusteredSites.siteSummaries.push(convertFracToRunboardSiteSummary(frac));
    }
  });

  dcs.forEach((dc) => {
    // TODO: DC: handle DCs in logistics clusters
    unclusteredSites.siteSummaries.push(convertDCToRunboardSiteSummary(dc));
  });

  const clusters: RunboardLogisticsCluster[] = Object.keys(clustersRecord).map((key) => clustersRecord[key]);
  clusters.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
  return [...clusters, unclusteredSites];
}

function convertFracToRunboardSiteSummary(frac: Frac): RunboardSiteSummary {
  return {
    actionNeededCount: getActionNeededCount(frac.orders),
    autopilotLevel: frac.cluster.autopilotLevel,
    id: frac.id,
    inProgressLoadCount: getInProgressOrderCount(frac.orders),
    name: getFracName(frac),
    pendingLoadCount: getPendingOrderCount(frac.orders),
    siteType: 'frac',
    up: frac.available,
    upForGrabsLoadCount: getUpForGrabsOrderCount(frac.orders),
  };
}

function convertDCToRunboardSiteSummary(dc: DistributionCenter): RunboardSiteSummary {
  return {
    actionNeededCount: getActionNeededCount(dc.orders),
    autopilotLevel: 'off',
    id: dc.id,
    inProgressLoadCount: getInProgressOrderCount(dc.orders),
    name: dc.site.name,
    pendingLoadCount: getPendingOrderCount(dc.orders),
    siteType: 'dc',
    up: true,
    upForGrabsLoadCount: getUpForGrabsOrderCount(dc.orders),
  };
}

function getActionNeededCount(orders: Order[]): number {
  return (orders || []).filter(
    (order) => order.user && order.user.userStatus && order.user.userStatus.type === 'ActionNeeded' && !order.isPreLoad,
  ).length;
}

function getInProgressOrderCount(orders: Order[]): number {
  return (orders || []).filter((order) => isInProgressGroup(order)).length;
}

function getPendingOrderCount(orders: Order[]): number {
  return (orders || []).filter((order) => isPendingGroup(order)).length;
}

function getUpForGrabsOrderCount(orders: Order[]): number {
  return (orders || []).filter((order) => isPendingGroup(order) && order.isUpForGrabs).length;
}
