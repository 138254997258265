import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, distinctUntilChanged, pluck, switchMapTo } from 'rxjs/operators';
import {
  OrderSummary,
  UnassignedUserSummary,
  UserStatusWithExtra,
} from 'src/app/ui-components/jobs-view/frac-detail/runboard-summary.model';
import { environment } from '~environments/environment';
import { AuthService } from './auth.service';
import { FeatureFlagService } from './feature-flag.service';
import { RouterStateService } from './router-state.service';
import { StoreService } from './store.service';
import { LogisticsRunboardSummary } from '~v2Models/logisticsCluster.model';
import * as fromRouterConstants from '../app-routing.constants';

@Injectable({
  providedIn: 'root',
})
export class LogisticsRunboardService {
  private currentLogisticsClusterId: number;
  private runboardSummary$$ = new BehaviorSubject<LogisticsRunboardSummary>(null);

  // Returns the runboard summary
  public get runboardSummary$(): Observable<LogisticsRunboardSummary> {
    return this.runboardSummary$$.asObservable();
  }

  public get activeDrivers$(): Observable<OrderSummary[]> {
    return this.runboardSummary$.pipe(
      filter((summary) => !!summary),
      map((summary) =>
        summary.orderSummaries.filter(
          (order) =>
            (order.orderStatus === 'driver_accepted' || order.orderStatus === 'dispatched') && order.userStatus,
        ),
      ),
    );
  }

  public get clusterName$(): Observable<string> {
    return this.runboardSummary$.pipe(
      filter((summary) => !!summary),
      map((summary) => summary.name),
    );
  }

  public get unassignedUsers$(): Observable<UnassignedUserSummary[]> {
    return this.runboardSummary$.pipe(
      filter((summary) => !!summary),
      map((summary) => summary.unassignedUserSummaries || []),
    );
  }

  public get notAvailableForLoads$(): Observable<UnassignedUserSummary[]> {
    return this.unassignedUsers$.pipe(
      filter((unassignedUsers) => !!unassignedUsers),
      map((unassignedUsers) => unassignedUsers.filter((summary) => !summary.userStatus.isReusable)),
    );
  }

  public get availableUndispatchedDriver$(): Observable<UnassignedUserSummary[]> {
    return this.unassignedUsers$.pipe(
      filter((unassignedUsers) => !!unassignedUsers),
      map((unassignedUsers) => unassignedUsers.filter((summary) => summary.userStatus.isReusable)),
    );
  }

  public get needsDriver$(): Observable<OrderSummary[]> {
    return this.runboardSummary$.pipe(
      filter((summary) => !!summary),
      map((summary) =>
        summary.orderSummaries.filter(
          (order) => order.orderStatus === 'pending' || order.orderStatus === 'driver_rejected',
        ),
      ),
    );
  }

  public get selectedDriver$(): Observable<UserStatusWithExtra> {
    return combineLatest(this.routerState.routerState$, this.runboardSummary$).pipe(
      switchMap(([routerState, runboardSummary]) => {
        const clusterId = +routerState.params[fromRouterConstants.LOGISTICS_CLUSTER_ID];
        const userId = +routerState.params[fromRouterConstants.LOGISTICS_CLUSTER_USER_ID];
        if (runboardSummary && clusterId && clusterId === runboardSummary.logisticsClusterId && userId) {
          const orderUserIsOn = runboardSummary.orderSummaries.find(
            (order) =>
              order.userStatus &&
              +order.userStatus.userId === userId &&
              (order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted'),
          );
          // This driver is on an order, we can grab the user status from the order itself.
          if (orderUserIsOn) {
            this.store.loadSingleOrderByOrderId(orderUserIsOn.orderId);
            return combineLatest(
              this.store.getOrderForId(orderUserIsOn.orderId) as Observable<any>,
              this.store.getFracByOrderId(orderUserIsOn.orderId),
            ).pipe(
              map(([selectedOrder, frac]) => {
                const returner: any = {
                  ...orderUserIsOn.userStatus,
                  order: orderUserIsOn,
                  userId: orderUserIsOn.userId,
                  userName: orderUserIsOn.userName,
                };
                // If we have all of the information from the store, we might as well attach it so we can get information like frac name
                if (selectedOrder) {
                  if (frac) {
                    selectedOrder = {
                      ...selectedOrder,
                      frac,
                    };
                  }
                  returner.selectedOrder = selectedOrder;
                }
                return returner;
              }),
            );
          }
          const userSummary = runboardSummary.unassignedUserSummaries.find((summary) => +summary.userId === userId);
          if (userSummary) {
            // Since we are using a switchMap now to handle the store observables, need an observable here
            return of({
              ...userSummary.userStatus,
              userId: userSummary.userId,
              userName: userSummary.userName,
            });
          }
        }
        // Since we are using a switchMap now to handle the store observables, need an observable here
        return of(null);
      }),
      filter((order) => !!order),
    );
  }

  public get driverPool$(): Observable<{
    message: string;
    shouldShow: boolean;
    subMessage: string;
    isExtreme: boolean;
  }> {
    return this.runboardSummary$.pipe(
      filter((summary) => !!summary),
      map((summary) => summary.truckPoolSize),
    );
  }

  // Allows the runboard service to begin tracking a fracCluster by ID
  public set logisticsClusterId(logisticsClusterId: number) {
    // Ensure a number
    logisticsClusterId = +logisticsClusterId;
    // Make sure that we are getting a number and that it isn't the same number. If it is the same number, we don't need to refresh
    if (!isNaN(logisticsClusterId) && this.currentLogisticsClusterId !== logisticsClusterId) {
      const emptyRunboard: LogisticsRunboardSummary = {
        logisticsClusterId: this.currentLogisticsClusterId,
        name: null,
        orderSummaries: [] as OrderSummary[],
        truckPoolSize: { message: '', shouldShow: false, subMessage: '', isExtreme: false },
        unassignedUserSummaries: [] as UnassignedUserSummary[],
      };
      this.runboardSummary$$.next(emptyRunboard);
      this.currentLogisticsClusterId = logisticsClusterId;
      this.loadLogisticsRunboardSummary();
    }
  }

  constructor(
    private routerState: RouterStateService,
    private store: StoreService,
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    this.loadLogisticsRunboardSummary = this.loadLogisticsRunboardSummary.bind(this);
    // Listen to the state of the router and if there is a LOGISTICS_CLUSTER_ID we can assume we will need the
    // runboard summary for it so begin loading it.
    this.routerState
      .listenForParamChange$(fromRouterConstants.LOGISTICS_CLUSTER_ID)
      .pipe(distinctUntilChanged())
      .subscribe((clusterId) => {
        this.logisticsClusterId = +clusterId;
      });

    interval(5 * 60 * 1000)
      .pipe(
        switchMapTo(
          combineLatest(
            this.featureFlagService.isFlagActive('runboardPolling').pipe(take(1)),
            this.routerState.routerState$.pipe(
              pluck('url'),
              map((url: string) => {
                if (url.endsWith(`map/logistics-runboard/${this.currentLogisticsClusterId}`)) {
                  return true;
                }
              }),
            ),
          ),
        ),
        map(([pollingIsOn, correctUrl]) => {
          return pollingIsOn && correctUrl;
        }),
      )
      .subscribe((shouldRefresh) => {
        if (shouldRefresh) {
          this.loadLogisticsRunboardSummary();
        }
      });

    this.authService.getSocketEventListener().subscribe((event) => {
      if (event === 'logout') {
        this.currentLogisticsClusterId = null;
      }
    });
  }

  public clearRunboard() {
    this.logisticsClusterId = null;
    const emptyRunboard: LogisticsRunboardSummary = {
      logisticsClusterId: this.currentLogisticsClusterId,
      name: null,
      orderSummaries: [] as OrderSummary[],
      truckPoolSize: { message: '', shouldShow: false, subMessage: '', isExtreme: false },
      unassignedUserSummaries: [] as UnassignedUserSummary[],
    };

    this.runboardSummary$$.next(emptyRunboard);
  }

  public reloadIfMatchedFracClusterId(logisticsRunboardId: number) {
    if (logisticsRunboardId === this.currentLogisticsClusterId) {
      this.loadLogisticsRunboardSummary();
    }
  }

  public loadLogisticsRunboardSummary(): void {
    if (this.currentLogisticsClusterId) {
      this.http.get(`${environment.api}/logistics_cluster/${this.currentLogisticsClusterId}/run_board`).subscribe(
        (response) => {
          if (response) {
            const justInCaseSomethingDoesntComeThrough: LogisticsRunboardSummary = {
              logisticsClusterId: this.currentLogisticsClusterId,
              name: null,
              orderSummaries: [] as OrderSummary[],
              truckPoolSize: { message: '', shouldShow: false, subMessage: '', isExtreme: false },
              unassignedUserSummaries: [] as UnassignedUserSummary[],
            };

            this.runboardSummary$$.next(Object.assign(justInCaseSomethingDoesntComeThrough, response));
          }
        },
        (error) => console.error(error),
      );
    }
  }
}
