import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { EmailSettingsService } from 'src/app/services/email-settings.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sa-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.scss'],
})
export class EmailDetailsComponent implements OnInit {
  displayNav = false;
  userLabel: { name: string; email: string; account: string };
  activeDataSource: MatTableDataSource<any>;
  displayedColumns = ['sender', 'uploadTime', 'uploadStatus', 'csv', 'processed', 'files'];
  emails = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private emailSettingsService: EmailSettingsService,
  ) {}

  ngOnInit() {
    this.userLabel = this.userService.getLabel();
    const emailId = parseInt(this.route.snapshot.params['id'], 10);
    this.emailSettingsService
      .getEmailsDetailsById(emailId)
      .pipe(map((settings) => settings.sort(this.compareFn).reverse()))
      .subscribe((emailSettings) => {
        this.activeDataSource = new MatTableDataSource(emailSettings);
        this.activeDataSource.sort = this.sort;
      });
  }

  private compareFn = (a, b) => {
    if (new Date(a.uploadTime) < new Date(b.uploadTime)) {
      return -1;
    }
    if (new Date(a.uploadTime) > new Date(b.uploadTime)) {
      return 1;
    }
    return 0;
  };

  downloadFile(fileName: string) {
    this.emailSettingsService.downloadFile(fileName).subscribe((_) => {
      window.open(_, '_blank');
    });
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }
}
