<div class="content">
  <div class="spinner" *ngIf="!(contracts$ | async)?.length && (networkActive$ | async); else table">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <ng-template #table>
    <ng-container *ngIf="(contracts$ | async)?.length">
      <table class="contracts-table">
        <thead>
          <tr>
            <th>
              Company Name
            </th>
            <th>
              Contract Name
            </th>
            <th>
              Created Date
            </th>
            <th>
              Expiration Date
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let contract of contracts$ | async; trackBy: trackById"
            [routerLink]="['../', contract.id]"
            class="contract-row"
            saAutomatedSelector="contract__row"
            [automatedName]="contract.name"
          >
            <td>
              {{ contract.vendorName }}
            </td>
            <td>
              {{ contract.name }}
            </td>
            <td>
              {{ contract.createdTimestamp | date }}
            </td>
            <td>
              <ng-container *ngIf="contract.isExpired; else notExpired">
                <span class="expired">
                  Expired
                </span>
              </ng-container>
              <ng-template #notExpired>
                {{ contract.expirationTime ? (contract.expirationTime | date: 'shortDate') : '-' }}
              </ng-template>
            </td>
            <td>
              <mat-icon
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
                saAutomatedSelector="menu"
                >more_horiz</mat-icon
              >
              <mat-menu #menu="matMenu">
                <button saAutomatedSelector="copy_as_draft" mat-menu-item (click)="createNew$$.next(contract)">
                  Copy As Draft
                </button>
                <button
                  *saFeatureFlag="'exportVendorContractPDF'"
                  saAutomatedSelector="export_as_pdf"
                  mat-menu-item
                  (click)="downloadVendorContractPdf(contract)"
                >
                  Export as PDF
                </button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-template>
</div>

<ng-container *ngIf="createNew$$ | async as toClone">
  <div class="back-layer-component" [@fadeInOut] (click)="closeNewContractForm($event)"></div>
  <sa-vendor-contract-creation [@slideInOut] [clone]="toClone" (onCloseClicked)="closeNewContractForm($event)">
  </sa-vendor-contract-creation>
</ng-container>
