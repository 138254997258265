<sa-nav></sa-nav>

<sa-loading *ngIf="formLoading"></sa-loading>

<div class="outer-container" *ngIf="!formLoading">
  <div class="create-dc-form-container container">
    <div class="frac-form">
      <a
        *ngIf="dc && !dc.archivedAt && dc.id"
        class="back-wrap"
        [routerLink]="['/', 'lmo', 'dc', dc.id]"
        saAutomatedSelector="dc-creation__back-to-dc"
      >
        <i class="material-icons">arrow_back</i>
        Back to Distribution Center
      </a>
      <a
        class="back-wrap"
        [routerLink]="['/', 'lmo', 'dc', 'archived']"
        saAutomatedSelector="well-creation__back-to-archive"
        *ngIf="dc && dc.archivedAt"
      >
        <i class="material-icons">arrow_back</i>
        Back to Archived Distribution Centers
      </a>
      <a
        class="back-wrap"
        [routerLink]="['/', 'lmo', 'dc', 'list']"
        saAutomatedSelector="dc-creation__back-to-dcs"
        *ngIf="!dc"
      >
        <i class="material-icons">arrow_back</i>
        Back to Distribution Centers
      </a>
      <div style="display: flex;justify-content: center;align-items: center; margin-bottom: 24px;padding-left: 24px;">
        <h2 fxLayout="row" fxLayoutAlign="start stretch" style="flex: 1 1 0; padding-bottom: 0px">
          <div>
            {{ dcId ? 'Edit Distribution Center ' + dcCreationForm.get('name').value : 'Create Distribution Center' }}
            <span class="api-pill" *ngIf="dc?.createdByAPI">API</span>
          </div>
        </h2>

        <button
          mat-flat-button
          color="primary"
          *ngIf="dcId"
          color="{{ this.dc.archivedAt ? 'primary' : 'warn' }}"
          (click)="changeDcStatus()"
          style="height: fit-content;color:white;"
          saAutomatedSelector="well-creation__change-well-status"
        >
          {{ this.dc.archivedAt ? 'Reactivate' : 'Archive' }}
        </button>
      </div>
      <form autocomplete="off" *ngIf="dcCreationForm" (submit)="validateDcInput()" [formGroup]="dcCreationForm">
        <h3 class="sub-form-heading">Details</h3>
        <div class="site-name">
          <mat-form-field class="form-field">
            <input
              matInput
              placeholder="Site Name"
              type="text"
              [formControl]="dcCreationForm.controls['name']"
              saAutomatedSelector="dc-creation__name"
            />
            <mat-error *ngIf="dcCreationForm.controls['name'].hasError('required')">
              Site Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="allowedUsers">
          <mat-form-field class="form-field subscribed-users">
            <mat-select
              [formControl]="dcCreationForm.controls['allowedUsers']"
              placeholder="Subscribed Users"
              [compareWith]="compareByTypeAndId"
              multiple
              saAutomatedSelector="dc-creation__subscribed-users"
            >
              <mat-select-trigger>
                <span
                  *ngFor="let user of dcCreationForm.controls['allowedUsers'].value; trackBy: trackById; let i = index"
                >
                  <span *ngIf="user.type === 'User'">
                    {{ getName(user, i, dcCreationForm.controls['allowedUsers'].value.length) }}
                  </span>
                </span>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Subscribed Users'"
                  [noEntriesFoundLabel]="'No User found'"
                  [formControl]="filterUser"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-optgroup *ngFor="let groupType of filteredUserOptions | async" [label]="groupType.type">
                <mat-option *ngFor="let user of groupType.users" [value]="user" (click)="userSelected(user)">
                  <span>{{ user.name }}</span>
                  <span *ngIf="user.sso" class="blue-pill"> <span>(</span>SSO<span>)</span> </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-hint
              >Select which <b>Standard</b> users should have access to this dc in SANDi. <b>Admin</b> users have access
              to all dcs by default.
            </mat-hint>
          </mat-form-field>
        </div>

        <h3 class="sub-form-heading">Location</h3>
        <sa-frac-creation-map
          [site]="this.dc && this.dc.site"
          style="height:700px; display:block;"
        ></sa-frac-creation-map>

        <div class="storages">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <h3>Storage Capacity at Distribution Center</h3>
            <a
              *ngIf="canEdit && !dc?.createdByAPI"
              (click)="openStorageForm(null)"
              saAutomatedSelector="dc-creation__add-storage-button"
              style="align-self: center;"
              >Add New</a
            >
          </div>

          <mat-list>
            <mat-list-item *ngFor="let storage of dcCreationForm.controls['storages'].value; let i = index">
              {{ storage.name }} - {{ storage.mesh.type }} Mesh
              <div class="edit-delete">
                <a
                  *ngIf="canEdit"
                  saAutomatedSelector="dc-creation__edit-storage-button"
                  (click)="openStorageForm(storage)"
                  >Edit</a
                >
                <ng-container *saFeatureFlag="'manageSupplementalMeshes'">
                  <a
                    *ngIf="canEdit"
                    saAutomatedSelector="dc-creation__manage-supplemental-meshes-button"
                    (click)="openStorageSupplementalMeshForm(storage)"
                    >Manage Supplemental Meshes</a
                  >
                </ng-container>
                <a
                  *ngIf="canEdit && !dc?.createdByAPI"
                  saAutomatedSelector="dc-creation__delete-storage-button"
                  (click)="removeStorage(i)"
                  >Delete</a
                >
              </div>
            </mat-list-item>
          </mat-list>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end" class="action-buttons" style="overflow: auto">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="next-btn"
            saAutomatedSelector="dc-creation__next-button"
          >
            Save
          </button>
        </div>
        <br /><br />
      </form>
    </div>
  </div>
</div>
