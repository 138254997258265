import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { switchMap, filter, take, mapTo } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, race, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  idToken: Observable<string | null>;
  constructor(private authService: AuthService) {
    this.idToken = this.authService.afAuth.idToken;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.endsWith('sand.shaleapps.com/api/unsubscribe') ||
      request.url.includes('/api/feature_flags') ||
      request.url.includes('/admin/api-account') ||
      request.url.includes('/admin/docs')
    ) {
      return next.handle(request);
    }
    return race(this.idToken.pipe(filter((idToken) => !!idToken)), timer(5000).pipe(mapTo(null))).pipe(
      take(1),
      switchMap((idToken) => {
        if (idToken) {
          request = request.clone({
            setHeaders: {
              Authorization: idToken,
            },
          });
        }
        return next.handle(request);
      }),
    );
  }
}
