import { Directive, forwardRef, Input, HostListener, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  // tslint:disable-next-line
  selector: '[sa-number-input]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SaNumberInputDirective),
      multi: true,
    },
  ],
})
export class SaNumberInputDirective implements ControlValueAccessor {
  type: string;
  onChange: Function;
  onTouched: Function;
  value: number;

  @Input()
  step = 1;

  @Input()
  min: number;

  @Input()
  max: number;

  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    let { value } = event.target as any;
    if (!value || value === '') {
      if (this.type === 'text') {
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', '');
      }
      this.onChange(null);
      return;
    }
    value = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
    // tslint:disable-next-line
    switch (event.keyCode) {
      case 38: // up arrow
        value += this.step;
        break;
      case 40: // down arrow
        value -= this.step;
        break;
    }
    this.writeValue(value);
  }

  @Input() public set disabledValue(value: number) {
    this.value = value;
    this.writeValue(value);
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
    this.type = (_elementRef.nativeElement as HTMLInputElement).type;
    // Start with the initial value
    setTimeout(() => {
      let { value } = this._elementRef.nativeElement as any;
      if (!value || value === '') {
        if (this.type === 'text') {
          this._renderer.setProperty(this._elementRef.nativeElement, 'value', '');
        }
        return;
      }
      value = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
      this.writeValue(value);
    }, 1);
  }

  writeValue(input: number): void {
    this.value = input;
    if (+this.min && this.value < +this.min) {
      this.value = +this.min;
    }
    if (+this.max && this.value > +this.max) {
      this.value = +this.max;
    }
    // in case this input type isn't text, we don't want this to fail out
    if (this.type === 'text') {
      const formattedField = this.value === null || this.value === undefined ? '' : this.value.toLocaleString('en');
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
    } else {
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', this.value);
    }
    if (this.onChange) {
      // Do it on the next tick for rendering purposes
      setTimeout(() => this.onChange(this.value), 0);
    }
  }
  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
