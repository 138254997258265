import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class HelperService {
  constructor() {}

  calculateReceivingTime(timestamp) {
    if (!timestamp) {
      return '--';
    }
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    const secDiff = Math.round((now - created) / 1000);
    if (secDiff < 0) {
      return '--';
    }
    const minDiff = Math.round(secDiff / 60);
    const hoursDiff = Math.round(minDiff / 60);
    const daysDiff = Math.round(hoursDiff / 24);
    if (daysDiff >= 1) {
      return `${daysDiff}d`;
    }
    if (hoursDiff >= 1) {
      return `${hoursDiff}h`;
    }
    if (minDiff >= 0) {
      return `${minDiff}m`;
    }
    return `${secDiff}s`;
  }

  calculateTimeUntil(timestamp) {
    const now = new Date().getTime();
    const ts = new Date(timestamp).getTime();
    const secDiff = Math.round((ts - now) / 1000);
    if (secDiff < 0) {
      return '--';
    }
    const minDiff = Math.floor(secDiff / 60);
    const hoursDiff = Math.floor(minDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);
    if (daysDiff >= 1) {
      return `${daysDiff}d`;
    }
    if (hoursDiff >= 5) {
      return `${hoursDiff}h`;
    }
    if (hoursDiff < 5 && hoursDiff >= 1) {
      return `${hoursDiff}h ${minDiff % 60}m`;
    }
    if (minDiff >= 0) {
      return `${minDiff}m`;
    }

    return `${secDiff}s`;
  }

  convertToShaleAppsLoadNumberWithFlag(orderId) {
    return this.convertToShaleAppsLoadNumber(orderId);
  }

  convertToShaleAppsLoadNumber(orderId) {
    return convertToShaleAppsLoadNumber(orderId);
  }

  public checkIfOnOrderTooLong(dispatchTime) {
    if (!dispatchTime) {
      return false;
    }
    const now = moment();
    const momentDispatch = moment(dispatchTime);
    const duration = moment.duration(now.diff(momentDispatch));
    const asMinutes = duration.asMinutes();
    return asMinutes > 30;
  }
}

export function convertToShaleAppsLoadNumber(orderId: any): string {
  const orderIdStr = orderId.toString();
  const countZeros = 9 - orderIdStr.length;
  let zeros = '';
  for (let i = 0; i < countZeros; i++) {
    zeros += '0';
  }
  return `S${`${zeros}${orderIdStr}`}`;
}
