<div>
  <h1></h1>
  <h3>Driver currently under {{ subcarrierName$.value }}</h3>
  <p>Would you like to change the driver's subcarrier?</p>
  <form class="w-full flex justify-between mt-1">
    <button type="button" mat-flat-button color="warn" (click)="changeSubcarrier(false)">
      <span class="text-white">Skip</span>
    </button>
    <button type="submit" mat-flat-button color="primary" (click)="changeSubcarrier(true)">
      <span class="text-white">Yes</span>
    </button>
  </form>
</div>
