<div class="confirm-dialog-component">
  <div mat-dialog-content>
    <h3>{{ _data.title }}</h3>
    <h4>{{ _data.desc }}</h4>
  </div>
  <div mat-dialog-actions>
    <div class="right-button-wrap">
      <button mat-flat-button class="right-button" mat-dialog-close>{{ _data.cancelButtonText }}</button>
      <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ _data.submitButtonText }}</button>
    </div>
  </div>
</div>
