import * as Fuse from 'fuse.js';
import { Observable, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, debounceTime, startWith } from 'rxjs/operators';

type fuseReturn<T> = (tArray: T[], _index: number) => { data: T[]; fuse: Fuse<T, Fuse.FuseOptions<T>> };

export interface Fused<T> {
  data: T[];
  fuse: Fuse<T, Fuse.FuseOptions<T>>;
}

export function fuse<T>(searchOptions: Fuse.FuseOptions<T>): fuseReturn<T> {
  return (tArray: T[], _index: number) => {
    return fuseWithOptions(tArray, searchOptions);
  };
}

export function setupSearch$<T>(
  source$: Observable<T[]>,
  formControl: FormControl,
  searchOptions: Fuse.FuseOptions<T>,
  debounceMs = 100,
): Observable<T[]> {
  return combineLatest([
    source$.pipe(map(fuse(searchOptions))),
    formControl.valueChanges.pipe(debounceTime(debounceMs), startWith('')),
  ]).pipe(map(doSearch));
}

function doSearch<T>([fused, searchTerm]: [Fused<T>, string]) {
  if (!searchTerm || searchTerm === '') {
    return fused.data;
  }
  return fused.fuse.search(searchTerm) as any;
}

function fuseWithOptions<T>(tArray: T[], searchOptions: Fuse.FuseOptions<T>): Fused<T> {
  return {
    data: tArray,
    fuse: new Fuse(tArray, searchOptions),
  };
}
