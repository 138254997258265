<div class="nav-lmo">
  <div class="menu-items" *saAllowedAccountRoles="['default', 'viewOnly', 'sandCoordinator']">
    <div class="groups">
      <div class="group">
        <div class="group-header">Operations</div>
        <a routerLink="/lmo/frac/list" routerLinkActive="true" saAutomatedSelector="menu__wells">Wells</a>
      </div>
      <div class="group">
        <div class="group-header">Settings</div>
        <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notifications"
          >Notifications</a
        >
      </div>
      <div class="group">
        <div class="group-header">Support</div>
        <span class="fake-link" (click)="showIntercom()">Contact ShaleApps</span>
        <a *saFeatureFlag="'apiDocs'" routerLink="/api" routerLinkActive="true">API</a>
      </div>
    </div>
    <a
      class="training-videos"
      routerLink="/lmo/training-videos"
      routerLinkActive="true"
      saAutomatedSelector="menu__training"
      ><div class="left">
        <img src="/assets/icons/training-video-preview.png" alt="training video preview" />
      </div>
      <div class="right">
        <p><strong>2m Training Videos</strong></p>
        <p>Learn how to maximize your turn rate & improve loads / driver / day!</p>
      </div>
    </a>
  </div>
  <div class="menu-items" *saAllowedAccountRoles="['admin', 'dispatcher']">
    <div class="groups">
      <div class="group">
        <div class="group-header">Operations</div>
        <a routerLink="/lmo/frac/list" routerLinkActive="true" saAutomatedSelector="menu__wells">Wells</a>
        <a routerLink="/lmo/loaders" routerLinkActive="true" saAutomatedSelector="menu__loaders">Loaders</a>
        <a
          *saFeatureFlag="'distributionCenters'"
          routerLink="/lmo/dc/list"
          routerLinkActive="true"
          saAutomatedSelector="menu__distribution-centers"
          >Distribution Centers
        </a>
        <a
          *saFeatureFlag="'showRestrictionButton'"
          routerLink="/restrictions"
          routerLinkActive="true"
          saAutomatedSelector="menu__restrictions"
          >Restrictions</a
        >
      </div>
      <div class="group">
        <div class="group-header">Procurement</div>
        <a
          [routerLink]="['/', 'lmo', 'sand-marketplace']"
          routerLinkActive="true"
          saAutomatedSelector="menu__sand-marketplace"
          *ngIf="!(isGroupedAccountUser$ | async)"
          >Sand Marketplace</a
        >
        <a
          routerLink="/auto-procurement"
          routerLinkActive="true"
          saAutomatedSelector="menu__auto_procurement"
          *ngIf="isGroupedAccountUser$ | async"
          >AutoProcurement</a
        >
        <a routerLink="/trucking-contracts" routerLinkActive="true" saAutomatedSelector="menu__trucking-contracts"
          >Trucking Contracts</a
        >
        <a routerLink="/lmo/purchase_order" routerLinkActive="true" *saFeatureFlag="'purchaseOrderDashboard'"
          >Purchase Orders</a
        >
        <a
          routerLink="/variable-pricing"
          routerLinkActive="true"
          *ngIf="userService.isShaleappsEmail(); else variablePricingUserPermission"
          >Variable Pricing</a
        >
        <ng-template #variablePricingUserPermission>
          <a routerLink="/variable-pricing" routerLinkActive="true" *saUserPermission="'variable_pricing'"
            >Variable Pricing</a
          >
        </ng-template>
        <ng-container *ngIf="isNewLoaderIntegration$$ | async; else elseTemplate">
          <a routerLink="/loader-integration-v2" routerLinkActive="true" saAutomatedSelector="menu__loader-integration"
            >Loader Integration</a
          >
        </ng-container>
        <ng-template #elseTemplate>
          <a routerLink="/loader-integration" routerLinkActive="true" saAutomatedSelector="menu__loader-integration"
            >Loader Integration</a
          >
        </ng-template>
      </div>
      <div class="group">
        <div class="group-header">Finance</div>
        <a [routerLink]="['/billing']" routerLinkActive="true" saAutomatedSelector="menu__billing">Billing By Frac</a>
        <a
          [routerLink]="
            optedInToNewBillingPortal
              ? ['/', 'invoicing', 'orders_list', 'waiting_for_ticket_approval']
              : ['/billing/fracs/needDataForTicketing']
          "
          routerLinkActive="true"
          saAutomatedSelector="menu__all_fracs_billing"
          >Billing for All Fracs</a
        >
        <span class="fake-link" saOrderSearch><span></span>Load Lookup <span class="note">(Ctrl+Enter)</span></span>
        <a
          *saFeatureFlag="'showBulkLoadUploadMenu'"
          routerLink="/bulk-load-upload"
          routerLinkActive="true"
          saAutomatedSelector="menu__bulk-load-upload"
          >Bulk Load Upload</a
        >
        <a routerLink="/bulk-export" routerLinkActive="true" saAutomatedSelector="menu__bulk-export">Bulk Export</a>
        <ng-container *saFeatureFlag="'reviewOnHoldOrders'">
          <a
            *saUserPermission="'review_on_hold_orders'"
            routerLink="/invoicing/orders_list/on_hold"
            routerLinkActive="true"
            saAutomatedSelector="menu__fraud"
            >Fraud Dashboard</a
          >
        </ng-container>
      </div>
      <div class="group">
        <div class="group-header">Settings</div>
        <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notifications"
          >Notifications</a
        >
        <a *ngIf="!isCrewLeader" routerLink="/users" routerLinkActive="true" saAutomatedSelector="menu__users">Users</a>
        <a [routerLink]="['/billing/settings']" routerLinkActive="true" saAutomatedSelector="menu__billing_settings"
          >Billing Configuration</a
        >
      </div>
      <div class="group">
        <div class="group-header">Support</div>
        <span class="fake-link" (click)="showIntercom()">Contact ShaleApps</span>
        <!-- <a [href]="emailHelpLink">Contact ShaleApps</a> -->
        <a *saFeatureFlag="'apiDocs'" routerLink="/api" routerLinkActive="true">API</a>
      </div>
    </div>
    <a
      class="training-videos"
      routerLink="/lmo/training-videos"
      routerLinkActive="true"
      saAutomatedSelector="menu__training"
      ><div class="left">
        <img src="/assets/icons/training-video-preview.png" alt="training video preview" />
      </div>
      <div class="right">
        <p><strong>2m Training Videos</strong></p>
        <p>Learn how to maximize your turn rate & improve loads / driver / day!</p>
      </div>
    </a>
  </div>
  <div class="bottom-nav">
    <div class="user">
      <div class="user-info font-medium">
        <div class="user-email">
          {{ userLabel.email || userLabel.name || 'Unknown' }}
        </div>

        <div class="user-company">
          <span *ngIf="isAdmin" class="role">Admin • </span>
          <span *ngIf="isDefault" class="role">Standard • </span>
          <span *ngIf="isViewOnly" class="role">View Only • </span>
          <span *ngIf="isSandCoordinator" class="role">Sand Coordinator • </span>
          <span *ngIf="isDispatcher" class="role">Dispatcher • </span>{{ userLabel.account || 'Unknown' }}
        </div>
        <ng-container *ngIf="ssoLoginUrl$ | async as ssoLoginUrl">
          <div *saFeatureFlag="'ssoSwitchAccount'" class="user-account-switch">
            <a (click)="ssoLogin()">SSO Switch Account</a>
          </div>
        </ng-container>
      </div>
      <img
        class="logout"
        src="../../../assets/icons/signout.svg"
        alt="signout"
        (click)="logout()"
        saAutomatedSelector="menu__sign_out"
      />
    </div>
  </div>
</div>
