import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';

type states = 'processing' | 'success' | 'failure';

@Component({
  selector: 'sa-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  currentState: states = 'processing';
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    const guid = this.route.snapshot.paramMap.get('uuid');
    if (!guid) {
      this.currentState = 'failure';
      return;
    }
    this.http
      .post<null>(`${environment.api}/unsubscribe`, { guid })
      .subscribe(() => (this.currentState = 'success'), () => (this.currentState = 'failure'));
  }
}
