<div class="logistics-cluster-summary">
  <div class="icon-row title-row">
    <div class="icon"></div>
    <div class="title content" *ngIf="asCluster(logCluster) as cluster">
      {{ cluster.name.toLocaleLowerCase() }}
    </div>
    <div class="title content" *ngIf="!logCluster.isCluster">not clustered</div>
  </div>
  <mat-divider></mat-divider>
  <a
    *ngFor="let site of logCluster.siteSummaries; trackBy: trackById"
    [routerLink]="
      site.siteType == 'frac' ? ['../', 'detail', site.id, 'pending'] : ['../', 'detail', 'dc', site.id, 'pending']
    "
    class="frac"
    saAutomatedSelector="well__row"
    [automatedName]="site.name"
  >
    <div class="icon-row">
      <div class="icon">
        <span class="pending" *ngIf="site.pendingLoadCount !== 0">{{ site.pendingLoadCount }}</span>
      </div>
      <div class="content">
        <div class="frac-name" [class.down]="!site.up">
          {{ site.name }}
        </div>
        <div>
          <span class="action-needed" *ngIf="showActionNeeded(site)">
            {{ site.actionNeededCount | i18nPlural: actionNeededPluralMapping }}
          </span>
          <span *ngIf="showUpForGrabsAndActionNeeded(site)">, </span>
          <span class="up-for-grabs" *ngIf="showUpForGrabs(site)">{{ site.upForGrabsLoadCount }} Up For Grabs</span>
          <span class="in-progress" *ngIf="showInProgress(site)">{{ site.inProgressLoadCount }} In Progress</span>
          &nbsp;
        </div>
      </div>
      <div class="autopilot" *ngIf="isFullAuto(site)">
        <img src="/assets/icons/lmo-full-auto.svg" />
      </div>
    </div>
    <mat-divider></mat-divider>
  </a>
  <div class="action-row" *ngIf="asCluster(logCluster) as cluster">
    <button mat-button color="primary" (click)="editCluster(cluster.id)">
      edit
    </button>
    <a mat-button color="primary" [routerLink]="['/', 'map', 'logistics-runboard', cluster.id]">driver runboard</a>
  </div>
  <div class="action-row" *ngIf="!logCluster.isCluster">
    <button mat-button color="primary" (click)="newCluster()">
      create cluster
    </button>
  </div>
</div>
