<div class="cancel-confirm-dialog-component">
  <h3>{{ data.heading }}</h3>
  <h5>{{ data.subheading }}</h5>
  <div class="button-wrap">
    <button color="error" mat-button (click)="continueAction()" saAutomatedSelector="choice-dialog__continue-action">
      {{ data.buttonText }}
    </button>
    <button
      color="primary"
      mat-button
      (click)="cancelAction()"
      cdkFocusInitial
      saAutomatedSelector="choice-dialog__cancel-action"
    >
      Go Back
    </button>
  </div>
</div>
