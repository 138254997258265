import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChoiceDialogComponent } from '../ui-components/choice-dialog/choice-dialog.component';
import { EmailSettingsService } from '../services/email-settings.service';
import { EmailSettingsV2Service } from './../services/email-settings-v2.service';

@Component({
  selector: 'sa-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss'],
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  displayNav = false;
  displayForm = false;
  userLabel: { name: string; email: string; account: string };
  selectedEmail: any;
  emailDetails = [];
  mines = [];
  activeDataSource: MatTableDataSource<any>;
  displayedColumns = ['mine', 'import', 'edit'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentTime = new Date();
  timeFormats = {};
  isCrewLeader = false;
  subs: Subscription[] = [];

  constructor(
    public userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    public emailSettingsService: EmailSettingsService,
    public emailSettingsV2Service: EmailSettingsV2Service,
  ) {
    this.timeFormats = {
      '02/01/06 15:04': 'dd/MM/yy HH:mm',
      '01/02/2006 15:04PM': 'MM/dd/yy HH:mmaa',
      '1/2/06 15:04': 'M/d/yy HH:mmaa',
      '1/2/2006 3:04 PM': 'M/d/yyyy h:mm aa',
    };
  }

  ngOnInit() {
    if (this.userService.isDispatcherAccount()) {
      this.router.navigateByUrl('/map');
    }
    this.userLabel = this.userService.getLabel();
    this.isCrewLeader = this.userService.isLMOAccount() && this.userService.isCrewLeader();
    if (this.isCrewLeader) {
      this.router.navigateByUrl('/lmo');
    }
    this.currentTime = new Date();
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;
    this.subs.push(
      this.emailSettingsService.emailSettings$.subscribe((settings) => {
        this.activeDataSource.data = settings.data;
      }),
    );

    this.checkNewLoaderIntegration();
  }

  checkNewLoaderIntegration() {
    this.emailSettingsV2Service.isNewLoaderIntegration()
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['/', 'loader-integration-v2']);
        } else {
          this.router.navigate(['/', 'loader-integration']);
        }
      });
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }

  addForm(data?) {
    this.selectedEmail = data;
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }

  deleteEmailSettings(id) {
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Delete this email setting?`,
        desc: `This cannot be undone.`,
        button: ['Cancel', 'Remove Email Setting'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.emailSettingsService.deleteEmailSettings(id);
      }
    });
  }
}
