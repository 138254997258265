import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mph',
})
export class MphPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return value * 2.237;
  }
}
