import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalSearchApiService {
  get route(): string {
    return environment.api + '/map/search';
  }

  constructor(private crud: CrudService) {}

  getResults(params?): Observable<any[]> {
    return this.crud.get(this.route, params);
  }
}
