<sa-top-nav></sa-top-nav>

<div class="loader-analytics-component">
  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="showDashboardHeaders = true" (closed)="showDashboardHeaders = false">
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-title> {{ showDashboardHeaders ? 'Hide' : 'Show' }} Panel </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="head-wrap">
        <h2>Loader Integration Analytics</h2>
      </div>
      <form *ngIf="analyticsForm" [formGroup]="analyticsForm" autocomplete="off">
        <div class="date-wrap">
          <mat-form-field>
            <input
              matInput
              class="datepicker-input "
              placeholder="Choose start date"
              autocomplete="off"
              [formControl]="analyticsForm.controls['startDate']"
              [max]="todayDate"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              saAutomatedSelector="bulk-export__start-Date"
            />
            <span [owlDateTimeTrigger]="dt1"><i class="material-icons">calendar_today</i></span>
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            <mat-error *ngIf="analyticsForm.controls['startDate'].hasError('required')">
              Input is required
            </mat-error>
          </mat-form-field>

          <i class="material-icons">arrow_forward</i>

          <mat-form-field>
            <input
              matInput
              class="datepicker-input "
              placeholder="Choose end date"
              autocomplete="off"
              [min]="analyticsForm.get('startDate').value || todayDate"
              [max]="todayDate"
              [formControl]="analyticsForm.controls['endDate']"
              [owlDateTime]="dt2"
              [owlDateTimeTrigger]="dt2"
              saAutomatedSelector="bulk-export__end-Date"
            />
            <span [owlDateTimeTrigger]="dt2"><i class="material-icons">calendar_today</i></span>
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            <mat-error *ngIf="analyticsForm.controls['endDate'].hasError('required')">
              Input is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="button-wrap ">
          <button
            mat-flat-button
            color="primary"
            saAutomatedSelector="downloadButton"
            (click)="getAnalytics()"
            [disabled]="isDownloading"
          >
            {{ isDownloading ? 'Getting Analytics' : 'Get Analytics' }}
          </button>
          <mat-spinner color="white" [diameter]="25" *ngIf="isDownloading"></mat-spinner>
        </div>
      </form>
      <mat-radio-group
        aria-label="Select an option"
        *ngIf="analylticData && analylticData.length > 0 && loaderData"
        [(ngModel)]="filterType"
        (change)="checkForNoOrders()"
      >
        <mat-radio-button value="showAll">Show All</mat-radio-button>
        <mat-radio-button value="hideOrder">Hide No Orders</mat-radio-button>
        <mat-radio-button value="hideOrderEmail">Hide No Orders and No Emails</mat-radio-button>
      </mat-radio-group>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="analytics-table" *ngIf="analylticData && analylticData.length > 0 && loaderData">
    <table>
      <tr>
        <th class="not-header" colspan="1"></th>
        <th *ngFor="let date of datesMap; let i = index">{{ date }}</th>
      </tr>
      <ng-container *ngFor="let data of loaderData | keyvalue">
        <ng-container *ngIf="showAccountData[data.key]">
          <tr class="data-row">
            <td class="account-name">
              <b>{{ data.key }}</b>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let siteData of data.value">
          <ng-container *ngIf="checkForLoaderData(data.key, siteData.siteName)">
            <tr>
              <td class="site-info">
                <div class="site-name">
                  {{ siteData.siteName }}
                </div>
                <div class="site-email">
                  {{ siteData.email }}
                </div>
              </td>
              <ng-container *ngFor="let date of datesMap; let i = index">
                <td
                  [class]="getColorCoding(getSiteDisplayData(data.key, siteData.siteName, date))"
                  [tooltip]="getToolTip(data.key, siteData.siteName, date)"
                  placement="bottom"
                  [display]="getSiteDisplayData(data.key, siteData.siteName, date).includes('%')"
                  [max-width]="260"
                >
                  {{ getSiteDisplayData(data.key, siteData.siteName, date) }}
                  <div class="" *ngIf="getSiteDate(data.key, siteData.siteName, date) as siteInfo">
                    <ng-container *ngIf="siteInfo.numberOfEmails > 0">
                      ({{ siteInfo.totalOrders }}/{{ siteInfo.ordersProcessed }}/{{ siteInfo.dataMatched }})
                    </ng-container>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </table>
  </div>
  <div class="No data" *ngIf="analylticData && analylticData.length == 0">
    No Data Found
  </div>
</div>
