import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberDecimal',
})
export class NumberDecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value = Number(value);
    return value.toFixed(2);
  }
}
