import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface DynamicPricingRegion {
  label: string;
  regionId: number;
  boxTechId: number;
}

export interface DynamicPricingDay {
  date: string;
  costPerMileCents: number;
  costPerMileCentsEma10: number;
  costPerMileCentsEma30: number;
}

export interface DynamicPriceHistory {
  pricingHistory: DynamicPricingDay[];
}

export interface Regions {
  regions: DynamicPricingRegion[];
}

export interface SDHistoryDay {
  date: string;
  orderShiftRatio: number;
  rateIncreasePercentage: number;
}

export interface SDGraphData {
  sdHistory: SDHistoryDay[];
}

export interface Rate {
  date: string;
  rateIncreasePercentageActual: number;
  rateIncreasePercentageOptimal: number;
}

export interface UpcomingRates {
  maxVariableRateIncrease: number;
  committedRates: Rate[];
  forecastedRates: Rate[];
}

@Injectable({
  providedIn: 'root',
})
export class DynamicPricingService {
  get route(): string {
    return environment.api;
  }

  constructor(private http: HttpClient) {}

  public getRegions$(): Observable<Regions> {
    return this.http.get<Regions>(this.route + '/dynamic_pricing/regions');
  }

  public getConnectedRegions$(): Observable<Regions> {
    return this.http.get<Regions>(this.route + '/dynamic_pricing/connected_regions');
  }

  public getPricingGraphData$(params): Observable<DynamicPriceHistory> {
    return this.http.get<DynamicPriceHistory>(this.route + '/dynamic_pricing/pricing_history', { params });
  }

  public getRatioGraphData$(params): Observable<SDGraphData> {
    return this.http.get<SDGraphData>(this.route + '/dynamic_pricing/supply_demand_history', { params });
  }

  public getRatesData$(params): Observable<UpcomingRates> {
    return this.http.get<UpcomingRates>(this.route + '/dynamic_pricing/upcoming_rates', { params });
  }
}
