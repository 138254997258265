import { Injectable } from '@angular/core';
import { UserService } from '~services/user.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminDispatcherStandardRouteGuard {
  constructor(public userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userService.isAdmin() || this.userService.isDispatcher() || this.userService.isStandardUser()) {
      return true;
    }

    if (this.userService.isLMOAccount()) {
      this.router.navigate(['/', 'lmo', 'frac', 'list']);
    }

    return false;
  }
}
