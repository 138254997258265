import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { User } from '../../models/user';
import { UserApiService } from '../../services/api/user.api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetailViewInputComponent } from '../detail-view-input/detail-view-input.component';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { GoogleAnalyticsService } from '~services/google-analytics.service';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SubcarrierApiService } from '~services/api/subcarrier.api.service';
import { CrudService } from '~services/crud.service';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LMOBrokerTrailerType, Subcarrier } from '~models/subcarrier.model';
import { UserService } from '~services/user.service';

interface FormValue {
  name: string;
  phone: string;
  pin: number;
}

@Component({
  selector: 'sa-driver-profile-detail-view',
  templateUrl: './driver-profile-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.scss'],
})
export class DriverProfileDetailViewComponent implements OnInit {
  public formGroup: FormGroup;
  @Input() user: User;
  @Output() onUserUpdated: EventEmitter<User> = new EventEmitter<User>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  buttonDisabled = false;
  public subcarrierList = new BehaviorSubject<Subcarrier[]>([]);
  public trailerTypes = new BehaviorSubject<LMOBrokerTrailerType[]>([]);
  public assetStatuses = new BehaviorSubject<string[]>([]);
  public showAssetStatusField = new BehaviorSubject(false);
  public showTrailersField = new BehaviorSubject(false);
  public trailers = new BehaviorSubject<any[]>([]);
  public selectedTrailer = new BehaviorSubject<any>(null);

  @ViewChildren('detailInput') inputs: QueryList<DetailViewInputComponent>;

  constructor(
    private userApiService: UserApiService,
    private userService: UserService,
    private subcarrierApiService: SubcarrierApiService,
    private crudService: CrudService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private gaService: GoogleAnalyticsService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    const isCDLRequired = this.userService.isCDLRequired();
    const cdlValidator: ValidatorFn = (control: AbstractControl) => {
      if (isCDLRequired) {
        if (!control.value) {
          return { cdlInvalid: true };
        }
      }

      const cdlNumber = control?.value?.toString();
      const sameDigitPattern = /^(.)\1*$/; // matches sequence of the same digit
      if (isCDLRequired) {
        if (cdlNumber?.length >= 7 && cdlNumber !== '1234567' && !sameDigitPattern.test(cdlNumber)) {
          return null; // valid
        }
      } else {
        if (
          (cdlNumber?.length === undefined || cdlNumber?.length === 0 || cdlNumber?.length >= 7) &&
          cdlNumber !== '1234567' &&
          !sameDigitPattern.test(cdlNumber)
        ) {
          return null; // valid
        }
      }
      return { cdlInvalid: true };
    };

    this.formGroup = this.fb.group({
      name: [this.user ? this.user.name : null, [Validators.required]],
      phone: [this.user ? this.user.phone : null, [Validators.required]],
      pin: [this.user ? this.user.pin : null, Validators.required],
      cdl: [
        this.user ? this.user.cdl : null,
        [isCDLRequired ? Validators.required : null, cdlValidator].filter(Boolean),
      ],
    });
    if (this.userService.isBrokerageAccount()) {
      this.loadSubcarriers();
      this.getLMOBrokerTrailerTypes();
      this.getTrailerTypeAssetStatuses();
      this.formGroup.addControl(
        'subcarrier',
        this.fb.control(this.user ? this.user.subcarrier : null, Validators.required),
      );
      this.formGroup.addControl('trailerTypeID', this.fb.control(this.setTrailerTypeID()));
      this.formGroup.addControl('assetStatus', this.fb.control(this.user ? this.user.assetStatus : null));
      this.formGroup.addControl('trailerID', this.fb.control(this.setTrailerID()));
      this.listenToTrailerTypeChanges();
      this.setInitialShowAssetStatusField();
      this.listenToSubcarrierChanges();
      this.listenToAssetStatusChanges();
      this.listenToTrailerChanges();
      this.showHideTrailerField(this.user?.assetStatus, this.user?.subcarrier);
    }
  }

  setTrailerTypeID() {
    if (this.user) {
      if (this.user.trailerTypeID === null) {
        return 0;
      }
      return this.user.trailerTypeID;
    } else {
      return null;
    }
  }

  setTrailerID() {
    if (this.user) {
      if (this.user.trailerID === null) {
        return 0;
      }
      if (this.user.trailerID && this.trailers.value.length > 0) {
        this.setSelectedTrailer(this.user.trailerID, 'setTrailerID');
      }

      return this.user.trailerID;
    } else {
      return null;
    }
  }

  loadSubcarriers() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService.getSubcarriers().subscribe((data) => {
        this.subcarrierList.next(data);
      });
    });
  }

  getLMOBrokerTrailerTypes() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService.getLMOBrokerTrailerTypes().subscribe((data) => {
        const noneTrailerType = data.find((type) => type.trailerTypeName === 'None');
        data = data.filter((type) => type.trailerTypeName !== 'None');
        data.unshift(noneTrailerType);
        this.trailerTypes.next(data);
      });
    });
  }

  setInitialShowAssetStatusField() {
    if (Object.keys(this.user).length > 0) {
      if (this.formGroup.get('trailerTypeID').value > 0) {
        this.showAssetStatusField.next(true);
      } else {
        this.showAssetStatusField.next(false);
      }
    } else {
      this.showAssetStatusField.next(false);
    }
  }

  listenToTrailerTypeChanges() {
    this.formGroup.get('trailerTypeID').valueChanges.subscribe((trailerTypeID) => {
      if (trailerTypeID === 0) {
        this.formGroup.get('assetStatus').setValue(null);
        this.showAssetStatusField.next(false);
      } else {
        this.showAssetStatusField.next(true);
      }
    });
  }

  listenToSubcarrierChanges() {
    this.formGroup.get('subcarrier').valueChanges.subscribe((subcarrierID) => {
      const assetStatus = this.formGroup.get('assetStatus').value;
      this.showHideTrailerField(assetStatus, subcarrierID);
    });
  }

  listenToAssetStatusChanges() {
    this.formGroup.get('assetStatus').valueChanges.subscribe((assetStatus) => {
      const subcarrierID = this.formGroup.get('subcarrier').value;
      this.showHideTrailerField(assetStatus, subcarrierID);
    });
  }

  listenToTrailerChanges() {
    this.formGroup.get('trailerID').valueChanges.subscribe((trailerID) => {
      this.setSelectedTrailer(trailerID, 'listenToTrailerChanges');
    });
  }

  showHideTrailerField(assetStatus: string, subcarrierID: number) {
    if (assetStatus === 'Rented' && subcarrierID > 0) {
      this.showTrailersField.next(true);
      this.getTrailers(subcarrierID);
    } else {
      this.formGroup.get('trailerID').setValue(0);
      this.showTrailersField.next(false);
      this.trailers.next([]);
    }
  }

  getTrailerTypeAssetStatuses() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService.getTrailerTypeAssetStatuses().subscribe((data) => {
        data = data.filter((status) => status !== 'None');
        data.unshift('None');
        this.assetStatuses.next(data);
      });
    });
  }

  getTrailers(subcarrierID: number) {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService.getTrailers(subcarrierID).subscribe((trailers) => {
        this.trailers.next(trailers);

        if (this.user.trailerID) {
          const tID = this.formGroup.get('trailerID').value;
          this.setSelectedTrailer(tID, 'getTrailers');
        }
      });
    });
  }

  updateDriver(fieldName: string): void {
    const fieldControl = this.formGroup.get(fieldName);
    if (!fieldControl.dirty) {
      return;
    }
    fieldControl.markAsPristine();
    if (this.userService.isBrokerageAccount() && this.user?.id) {
      const subcarrierFieldControl = this.formGroup.get('subcarrier');
      subcarrierFieldControl.clearValidators();
      subcarrierFieldControl.updateValueAndValidity();
    }
    if (this.formGroup.invalid) {
      if (this.formGroup.get('cdl').invalid && this.user?.id) {
        this.snackBar.open('Please provide a valid CDL number', null, {
          duration: 3000,
          panelClass: ['snackbar-error'],
        });
      }
      return;
    }
    const formValue: FormValue = this.formGroup.value;
    const updatedUser = Object.assign({}, this.user, formValue);

    if (this.user.id) {
      this.userApiService.updateUser(this.user.id, updatedUser).subscribe(
        (user) => {
          this.user = user;
          this.onUserUpdated.emit(user);
          this.snackBar.open('User successfully updated', null, {
            duration: 2000,
          });
        },
        (err) => {
          err = this.checkForExistingDriver(err);
          this.errorHandler.showError(err);
        },
      );
    }
  }

  removeDriver(id: number): void {
    if (confirm('Are you sure you want to remove this driver?')) {
      this.userApiService.removeDriver(id).subscribe(
        () => {
          this.onUserUpdated.emit(null);
          this.onClose.emit();
          this.snackBar.open('Driver successfully removed', null, {
            duration: 2000,
          });
        },
        (err) => {
          this.errorHandler.showError(err);
        },
      );
    }
  }

  inviteDriver(): void {
    if (this.formGroup.invalid) {
      return;
    }
    const formValue: FormValue = this.formGroup.value;
    this.buttonDisabled = true;
    this.userApiService.createDriver(formValue).subscribe(
      (user) => {
        this.user = user;
        this.onUserUpdated.emit(user);
        this.buttonDisabled = false;
        this.snackBar.open('Driver successfully invited', null, {
          duration: 2000,
        });
        this.gaService.event('add_driver_manually', {});
      },
      (err) => {
        err = this.checkForExistingDriver(err);
        this.buttonDisabled = false;
        this.errorHandler.showError(err);
      },
    );
  }

  checkForExistingDriver(err: any) {
    return err && err.error && err.error.Message === 'User already created with same role'
      ? 'Driver exists in GoHawk. Dispatch a driver on a load top add him to your driver list'
      : err;
  }

  closeClicked() {
    this.onClose.emit();
  }

  setSelectedTrailer(trailerID: number, t: string) {
    this.selectedTrailer.next(this.trailers.value.find((trailer) => trailer.id === trailerID));
  }
}
