import { Component, OnInit } from '@angular/core';
import { StoreService } from '~services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { isOrder, Order } from '~models/order.model';
import { isCompleteGroup, isInProgressGroup } from '~v2Models/order.model';

@Component({
  selector: 'sa-dispatcher-order-landing',
  templateUrl: './dispatcher-order-landing.component.html',
  styleUrls: ['./dispatcher-order-landing.component.scss'],
})
export class DispatcherOrderLandingComponent implements OnInit {
  constructor(private store: StoreService, private activatedRoute: ActivatedRoute, private router: Router) {}

  public async ngOnInit() {
    const orderId = this.activatedRoute.snapshot.params.orderId;
    this.store.loadSingleOrderByOrderId(+orderId);
    const order = await this.store
      .getOrderForId(+orderId)
      .pipe(
        filter((o) => !!o),
        take(1),
      )
      .toPromise();
    if (isOrder(order)) {
      this.router.navigate(getDispatcherUrl(order));
    } else {
      this.router.navigate(['/', 'map', 'jobs', 'list']);
    }
  }
}

function getDispatcherUrl(order: Order): string[] {
  const urlSuffix = [];
  if (isCompleteGroup(order)) {
    urlSuffix.push('completed', 'order', order.loadNumber);
  } else if (isInProgressGroup(order)) {
    urlSuffix.push('progress', 'order', order.loadNumber);
  } else {
    urlSuffix.push('pending', order.loadNumber);
  }
  return ['/', 'map', 'jobs', 'detail', `${order.fracId}`, ...urlSuffix];
}
