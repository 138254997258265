import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

interface TrackByFunctionCache {
  [propertyName: string]: (index: number, item: any) => any;
}

const cache: TrackByFunctionCache = Object.create(null);

@Pipe({
  name: 'trackByKey',
})
export class TrackByKeyPipe implements PipeTransform {
  // tslint:disable-next-line
  public transform<T>(value: T[] | Observable<T[]>, key: keyof T): (index: number, item: T) => T {
    return getTracker<T>(key);
  }
}

export const getTracker = <T>(key: keyof T): ((index: number, item: T) => any) => {
  if (key === null) {
    return trackByMemoryReference;
  }
  // Ensure cached function exists.
  if (!cache[key as any]) {
    cache[key as any] = (index: number, item: T): any => {
      return item[key];
    };
  }

  return cache[key as any];
};

const trackByMemoryReference = <T>(index: number, item: T): T => item;
