/// <reference types="@types/googlemaps" />
// import { } from '@types/googlemaps';

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { DetailView } from '../../models/detail-view';
import { MapService } from '../../services/map.service';
import { CrudService } from '../../services/crud.service';
import { WellApiService } from '../../services/api/well.api.service';
import { UserApiService } from '../../services/api/user.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';
import { StoreService } from '../../services/store.service';
import { config } from '../../config';
import { LightTheme } from '../../map/mapStyles';
import { environment } from '../../../environments/environment';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import * as moment from 'moment';
import { MapUtilService, MapUtilState } from '../../services/map-util.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { RunboardService } from '../../services/runboard.service';
import { OrderSummary } from '../jobs-view/frac-detail/runboard-summary.model';
import { DriverReplayService } from 'src/app/driver-replay.service';
import { LogisticsRunboardService } from '~services/logistics-runboard.service';
import { DispatcherFracsService } from '~dispatcher/services/dispatcher-fracs.service';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../../app-routing.constants';
import { Frac } from '~v2Models/frac.model';
import { TruckingRequestVendorService } from 'src/app/marketplace/services/trucking-request-vendor.service';
import { Restriction } from 'src/app/restrictions/types';
import { HttpClient } from '@angular/common/http';
import { RoadRestrictionApiResponse, toLocalRestriction } from 'src/app/restrictions/toLocalRestriction';
import { equals } from 'remeda';
import InfoBox from 'google-maps-infobox/types';
import { Order } from '~v2Models/order.model';
import { BoxPickup } from '~lmo/models/order.model';
import { DistributionCenterApiService } from '~services/api/distribution-center.api.service';
import { LmoReportsService } from '~lmo/services';
import { Report } from '~lmo/models/report.model';

type MapType = 'map' | 'replay';

const MapTypes = {
  map: 'map' as MapType,
  replay: 'replay' as MapType,
};

const INITIAL_ZOOM = 3;
const fracAvailableColor = '#0000FF';
const fracUnavailableColor = '#770000';

@Component({
  selector: 'sa-map-vendor',
  templateUrl: './map-vendor.component.html',
  styleUrls: ['./map-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapVendorComponent implements OnInit, OnDestroy {
  private restrictionPolygons: Record<number, { restriction: Restriction; polygon: google.maps.Polygon }> = {};
  private mapLocationBounds$$ = new BehaviorSubject<{ x1: number; x2: number; y1: number; y2: number }>(null);
  @Output() onMenuClick: EventEmitter<any> = new EventEmitter();

  map: google.maps.Map;
  mapConfig: google.maps.MapOptions;
  mapType: MapType;
  detailView: DetailView;
  loading = true;
  selectedState = 1;
  selectedFrac: any;
  selectedOrder: any;
  fracs = [];
  infoBoxPickupBoxList = [];
  infoBoxFracList = [];
  infoBoxMineList = [];
  colors = [
    '#B6F4D5',
    '#99E8CC',
    '#82D8CB',
    '#71C6D2',
    '#62B0DF',
    '#5198F3',
    '#4980FE',
    '#4E6DF4',
    '#4C59DF',
    '#4746C2',
    '#3E359B',
    '#31246E',
  ];
  activeBoxPickupMarker = [];
  activeFracMarker = [];
  activeMineMarker = [];
  activeTruckMarker: {
    order: Order;
    marker: google.maps.Marker;
    id: number;
    tooltip: InfoBox;
    insideActiveRestriction: boolean;
  }[] = [];
  runBoardTruckMarker: {
    summary: OrderSummary;
    marker: google.maps.Marker;
    id: number;
    tooltip: InfoBox;
    insideActiveRestriction: boolean;
  }[] = [];
  unassignedTrucksMarkers: { marker: google.maps.Marker; id: number; tooltip: InfoBox }[] = [];
  runboardActiveDriverSub: Subscription;
  runboardSelectedDriverSub: Subscription;
  logisticsRunboardActiveDriverSub: Subscription;
  logisticsRunboardSelectedDriverSub: Subscription;
  public highlightedDriver: { marker: google.maps.Marker; id: number; tooltip: InfoBox };

  unassignedTrucks = [];
  reports: Report[] = [];

  subs = [];

  isMapIntialized = new BehaviorSubject(false);

  heatMap: google.maps.visualization.HeatmapLayer;
  orderDetailTruckMarker: google.maps.Marker;

  constructor(
    private mapService: MapService,
    private crud: CrudService,
    private wellApiService: WellApiService,
    private userApiService: UserApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private mapUtilService: MapUtilService,
    private errorHandler: ErrorHandlingService,
    private runboardService: RunboardService,
    private driverReplay: DriverReplayService,
    private logisticsRunboardService: LogisticsRunboardService,
    private dispatcherFracService: DispatcherFracsService,
    private routerState: RouterStateService,
    private _requestService: TruckingRequestVendorService, // Preload the trucking request service count for the MENU,
    private httpClient: HttpClient,
    private dcApiService: DistributionCenterApiService,
    private reportService: LmoReportsService,
  ) {
    this.subs.push(
      this.mapLocationBounds$$
        .pipe(
          skip(1),
          distinctUntilChanged(equals),
          debounceTime(200),
          filter((bounds) => !!bounds),
          switchMap((bounds) => {
            return this.httpClient
              .get<{ restrictions: RoadRestrictionApiResponse[] }>(
                `${environment.api}/trucking_vendor/restrictions_in_box`,
                {
                  params: {
                    x1: `${bounds.x1}`,
                    x2: `${bounds.x2}`,
                    y1: `${bounds.y1}`,
                    y2: `${bounds.y2}`,
                  },
                },
              )
              .pipe(map((results) => (results.restrictions || []).map(toLocalRestriction)));
          }),
        )
        .subscribe((value) => {
          this.handleRestrictionUpdates(value);
        }),
    );
  }

  private mapInitialized() {
    this.initMap();
  }

  menuClick() {
    this.onMenuClick.emit();
  }

  loadData() {
    this.subs.push(
      this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
        if (ready) {
          combineLatest([
            this.dcApiService.getDistributionCenters(),
            this.wellApiService.getWells(),
            this.userApiService.getUnassignedUsers(),
            this.storeService.select<Array<any>>('fracs'),
            this.reportService.reports$,
          ])
            .pipe(take(1))
            .subscribe(([dcs, wells, trucks, oldFracs, reports]) => {
              wells.forEach((well) => {
                oldFracs.forEach((oldFrac) => {
                  if (well.id === oldFrac.id && oldFrac.orders) {
                    oldFrac.orders.forEach((oldOrder) => {
                      if (
                        (oldOrder.orderStatus === 'completed' || oldOrder.orderStatus === 'canceled') &&
                        well.orders
                      ) {
                        well.orders.push(oldOrder);
                      }
                    });
                  }
                });
              });
              this.reports = reports;
              this.fracs = wells;
              this.loading = false;
              this.storeService.set('unassignedTrucks', trucks);
              this.storeService.set('fracs', [...wells]);
              this.storeService.set('dcs', dcs);
            });
        }
      }),
    );
  }

  loadScripts() {
    const node = document.createElement('script');
    node.id = 'map-script';
    node.src = config.googleMapsScriptUrl;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    window['__onGoogleLoaded'] = (ev) => {
      this.mapInitialized();
    };
    this.loadData();
    this.loadScripts();
    this.subs.push(
      combineLatest([this.isMapIntialized.pipe(filter(Boolean)), this.mapUtilService.onUpdateMap])
        .pipe(map(([_, mapData]) => mapData))
        .subscribe(({ frac, state, order, socketUpdate }) => {
          this.updateMap({ frac, state, order, socketUpdate });
        }),
    );
    this.subs.push(
      this.mapUtilService.onChangeMine.subscribe((_) => {
        this.changeFocusedMine(_);
      }),
    );

    this.subs.push(
      this.mapUtilService.loadFracs.subscribe((_) => {
        this.loadData();
      }),
    );

    this.subs.push(
      this.mapUtilService.onDriverChange.pipe(debounceTime(400)).subscribe(({ driver, status }) => {
        this.updateDriver({ driver, status });
      }),
    );
    this.subs.push(
      this.storeService.select<Array<any>>('fracs').subscribe((fracs) => {
        this.fracs = fracs;
      }),
    );
    this.subs.push(
      this.storeService.select<Array<any>>('unassignedTrucks').subscribe((unassignedTrucks) => {
        this.unassignedTrucks = unassignedTrucks;
      }),
    );

    this.subs.push(
      this.driverReplay.waypoints$.subscribe((waypoints) => {
        const data = waypoints.map((waypoint) => new google.maps.LatLng(waypoint.latitude, waypoint.longitude));
        if (this.heatMap) {
          this.heatMap.setData(data);
        }
      }),
    );

    this.subs.push(
      this.driverReplay.selectedWaypoint$.subscribe((waypoint) => {
        if (waypoint) {
          if (!this.orderDetailTruckMarker) {
            this.orderDetailTruckMarker = new google.maps.Marker({
              position: new google.maps.LatLng(waypoint.latitude, waypoint.longitude),
            });
            this.orderDetailTruckMarker.setMap(this.map);
          } else {
            this.orderDetailTruckMarker.setPosition({ lat: waypoint.latitude, lng: waypoint.longitude });
          }
        } else {
          if (this.orderDetailTruckMarker) {
            this.orderDetailTruckMarker.setMap(null);
            this.orderDetailTruckMarker = null;
          }
        }
      }),
    );
    this.setupFracListListener();
    this.setupLogisticsRunboardListener();
  }

  initMap() {
    this.mapType = MapTypes.map;
    this.mapConfig = {
      center: { lat: 39.7392, lng: -104.9903 },
      zoom: INITIAL_ZOOM,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: 3,
      },
      zoomControlOptions: {
        position: 3,
      },
      styles: <any>LightTheme,
    };

    this.map = new google.maps.Map(document.getElementById('map'), this.mapConfig);
    this.heatMap = new google.maps.visualization.HeatmapLayer({
      data: [],
      map: this.map,
      radius: 30,
    });
    google.maps.event.addListener(this.map, 'bounds_changed', () => {
      const bounds = this.map.getBounds();
      if (bounds) {
        const asJson = bounds.toJSON();
        this.mapLocationBounds$$.next({
          x1: asJson.west,
          x2: asJson.east,
          y1: asJson.south,
          y2: asJson.north,
        });
      }
    });

    this.isMapIntialized.next(true);
  }

  deleteAllMarkers() {
    this.deleteFracMarkers();
    this.deleteMineMarkers();
    this.deleteUnassignedTruckMarkers();
    this.deleteActiveTruckMarkers();
    this.deleteRunBoardTruckMarkers();
    this.deleteBoxPickupMarkers();
  }

  deleteFracMarkers() {
    this.activeFracMarker.forEach((marker) => {
      marker.marker.setMap(null);
    });
    this.activeFracMarker = [];
    this.infoBoxFracList.forEach((infoBox) => {
      infoBox.close();
    });
  }

  deleteMineMarkers() {
    this.activeMineMarker.forEach((marker) => {
      marker.marker.setMap(null);
    });
    this.activeMineMarker = [];
    this.infoBoxMineList.forEach((infoBox) => {
      infoBox.close();
    });
  }

  deleteUnassignedTruckMarkers() {
    this.unassignedTrucksMarkers.forEach((marker) => {
      marker.marker.setMap(null);
    });
    this.unassignedTrucksMarkers = [];
  }

  deleteRunBoardTruckMarkers() {
    this.runBoardTruckMarker.forEach((marker) => {
      marker.marker.setMap(null);
    });
    this.runBoardTruckMarker = [];
  }

  deleteActiveTruckMarkers() {
    this.activeTruckMarker.forEach((marker) => {
      marker.marker.setMap(null);
      marker.tooltip.close();
    });
    this.activeTruckMarker = [];
  }

  deleteBoxPickupMarkers() {
    this.activeBoxPickupMarker.forEach((marker) => {
      marker.marker.setMap(null);
    });
    this.activeBoxPickupMarker = [];
    this.infoBoxPickupBoxList.forEach((infoBox) => {
      infoBox.close();
    });
  }

  plotAllMarkers(socketUpdate) {
    this.deleteAllMarkers();
    this.fracs.forEach((frac, index) => {
      this.createFracMarkers(frac, false, false, null, this.reports);
    });
    this.generateUnassignedTruckMarkers();
    if (!socketUpdate) {
      this.getLatLongBoundsForFracs();
    }
  }

  private createFracMarkers(frac, focus, showToolTip, order, reports: Report[] = []) {
    if (frac) {
      this.generateFracAndMarker(frac, focus, showToolTip);
      if (frac.fracMines) {
        frac.fracMines.forEach((mine) => {
          this.generateLoaderAndMarker(mine, showToolTip, false, null);
        });
      }
    }
  }

  private generateUnassignedTruckMarkers(isAllCluster = false) {
    this.unassignedTrucks.forEach((truck) => {
      if (isAllCluster) {
        const halfAnHourAgo = moment()
          .subtract(30, 'minutes')
          .toDate();
        if (moment(truck.lastTimeSeen).isSameOrAfter(halfAnHourAgo)) {
          this.generateUnassignedTruckMarker(truck);
        }
      } else {
        this.generateUnassignedTruckMarker(truck);
      }
    });
  }

  getLatLongBoundsForFracs() {
    let n = Number.NEGATIVE_INFINITY;
    let e = Number.NEGATIVE_INFINITY;
    let s = Number.POSITIVE_INFINITY;
    let w = Number.POSITIVE_INFINITY;
    this.activeFracMarker.forEach((frac) => {
      try {
        const tempLatitude = frac.marker.center.lat();
        const tempLongitude = frac.marker.center.lng();
        if (tempLatitude >= n) {
          n = tempLatitude;
        }

        if (tempLatitude <= s) {
          s = tempLatitude;
        }
        if (tempLongitude >= e) {
          e = tempLongitude;
        }
        if (tempLongitude <= w) {
          w = tempLongitude;
        }
      } catch (error) {
        console.error(error, 'Unable to get marker for frac: ', frac);
        throw error;
      }
    });

    this.activeMineMarker.forEach((frac) => {
      const tempLatitude = frac.marker.center.lat();
      const tempLongitude = frac.marker.center.lng();
      if (tempLatitude >= n) {
        n = tempLatitude;
      }

      if (tempLatitude <= s) {
        s = tempLatitude;
      }
      if (tempLongitude >= e) {
        e = tempLongitude;
      }
      if (tempLongitude <= w) {
        w = tempLongitude;
      }
    });

    this.activeTruckMarker.forEach((frac) => {
      const tempLatitude = frac.marker.getPosition().lat();
      const tempLongitude = frac.marker.getPosition().lng();
      if (tempLatitude >= n) {
        n = tempLatitude;
      }

      if (tempLatitude <= s) {
        s = tempLatitude;
      }
      if (tempLongitude >= e) {
        e = tempLongitude;
      }
      if (tempLongitude <= w) {
        w = tempLongitude;
      }
    });

    this.runBoardTruckMarker.forEach((frac) => {
      const tempLatitude = frac.marker.getPosition().lat();
      const tempLongitude = frac.marker.getPosition().lng();
      if (tempLatitude >= n) {
        n = tempLatitude;
      }

      if (tempLatitude <= s) {
        s = tempLatitude;
      }
      if (tempLongitude >= e) {
        e = tempLongitude;
      }
      if (tempLongitude <= w) {
        w = tempLongitude;
      }
    });

    if (this.activeBoxPickupMarker.length !== 0) {
      this.activeBoxPickupMarker.forEach((boxpickup) => {
        const tempLatitude = boxpickup.marker.center.lat();
        const tempLongitude = boxpickup.marker.center.lng();
        if (tempLatitude >= n) {
          n = tempLatitude;
        }
        if (tempLatitude <= s) {
          s = tempLatitude;
        }
        if (tempLongitude >= e) {
          e = tempLongitude;
        }
        if (tempLongitude <= w) {
          w = tempLongitude;
        }
      });
    }

    const northeast = new google.maps.LatLng(n, e);
    const southwest = new google.maps.LatLng(s, w);
    const latLongBounds = new google.maps.LatLngBounds(southwest, northeast);
    of(latLongBounds)
      .pipe(delay(150))
      .subscribe((_) => {
        if (this.map) {
          this.map.fitBounds(_);
        }
      });
  }

  generateFracAndMarker(frac, focused, showToolTip) {
    if (Array.isArray(this.activeFracMarker) && this.activeFracMarker.some((m) => m.id === frac.id)) {
      return;
    }
    if (frac) {
      const color = fracAvailableColor;

      const fracMarker = this.mapService.createFracMarker(frac.site, color);
      const fracTooltip = this.mapService.createFracInfobox(frac.site, frac.available);
      if (showToolTip === false) {
        fracMarker.addListener('mouseover', () => {
          fracTooltip.setPosition(
            new google.maps.LatLng(
              fracMarker
                .getBounds()
                .getNorthEast()
                .lat(),
              fracMarker.getCenter().lng(),
            ),
          );
          fracTooltip.open(this.map);
        });

        fracMarker.addListener('mouseout', () => {
          fracTooltip.close();
        });
      } else {
        fracTooltip.setPosition(
          new google.maps.LatLng(
            fracMarker
              .getBounds()
              .getNorthEast()
              .lat(),
            fracMarker.getCenter().lng(),
          ),
        );
        fracTooltip.open(this.map);
        this.infoBoxFracList.push(fracTooltip);
      }

      fracMarker.setMap(this.map);

      this.activeFracMarker.push({ id: frac.id, marker: fracMarker });

      if (frac.site.outerRadius && frac.site.outerLngLat) {
        const fracStagingMarker = new google.maps.Circle({
          map: this.map,
          radius: frac.site.outerRadius,
          strokeColor: '#0000FF',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#0000FF',
          fillOpacity: 0.35,
          center: {
            lat: frac.site.outerLngLat[1],
            lng: frac.site.outerLngLat[0],
          },
          zIndex: 10,
        });
        fracStagingMarker.setMap(this.map);
        this.activeFracMarker.push({ id: `${frac.id}-outer`, marker: fracStagingMarker });
      }
    }
  }

  generateLoaderAndMarker(mine, showToolTip, order, focused = false) {
    let isMineAvailable = mine.reportAvailable;
    if (order && this.reports.length > 0) {
      const activeReport = this.reports.find(
        (r) => r.available === false && r.mesh && r.mesh.id === order.mesh.id && r.site.id === mine.site.id,
      );
      isMineAvailable = !activeReport;
    }

    if (!this.activeMineMarker.some((marker) => marker.id === mine.id)) {
      const color = '#00CC00';
      const loaderMarker = this.mapService.createLoaderMarker(mine.site, color);
      const loaderTooltip = this.mapService.createLoaderInfobox(mine.site, isMineAvailable === false);
      if (showToolTip === false) {
        loaderMarker.addListener('mouseover', () => {
          loaderTooltip.setPosition(
            new google.maps.LatLng(
              loaderMarker
                .getBounds()
                .getNorthEast()
                .lat(),
              loaderMarker.getCenter().lng(),
            ),
          );
          loaderTooltip.open(this.map);
        });

        loaderMarker.addListener('mouseout', () => {
          loaderTooltip.close();
        });
      } else {
        loaderTooltip.setPosition(
          new google.maps.LatLng(
            loaderMarker
              .getBounds()
              .getNorthEast()
              .lat(),
            loaderMarker.getCenter().lng(),
          ),
        );
        loaderTooltip.open(this.map);
        this.infoBoxMineList.push(loaderTooltip);
      }

      this.activeMineMarker.push({ id: mine.id, marker: loaderMarker });
      loaderMarker.setMap(this.map);
    }
  }

  generateUnassignedTruckMarker(truck) {
    if (truck && truck.lastLngLat && truck.lastLngLat.length === 2) {
      const truckMarker = this.mapService.createUnassignedTruckMarker(truck.lastLngLat);
      const truckTooltip = this.mapService.createUnassignedTruckInfobox(
        truck.name,
        this.calculateReceivingTime(truck.lastTimeSeen),
      );
      truckMarker.addListener('mouseover', () => {
        truckTooltip.setPosition(truckMarker.getPosition());
        truckTooltip.open(this.map);
      });

      truckMarker.addListener('mouseout', () => {
        truckTooltip.close();
      });
      this.unassignedTrucksMarkers.push({ marker: truckMarker, id: truck.id, tooltip: truckTooltip });
      truckMarker.setMap(this.map);
    }
  }

  calculateReceivingTime(timestamp) {
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    const secDiff = Math.round((now - created) / 1000);
    const minDiff = Math.round(secDiff / 60);
    const hoursDiff = Math.round(minDiff / 60);
    const daysDiff = Math.round(hoursDiff / 24);
    if (daysDiff >= 1) {
      return `${daysDiff}d ago`;
    }
    if (hoursDiff >= 1) {
      return `${hoursDiff}h ago`;
    }
    if (minDiff >= 1) {
      return `${minDiff}m ago`;
    }
    if (secDiff >= 1) {
      return `${secDiff}s ago`;
    }
    return '';
  }

  generateMapTruckMarker(order: Order) {
    if (order.user && order.user.lastLngLat && order.user.lastLngLat.length === 2) {
      const truckMarker = this.mapService.createTruckMarkerWithStatus(
        order.user.lastLngLat,
        order.loaded,
        order.loadNumber,
        order.mesh.type,
        order.onBoxMove,
        order.pendingBoxPickup,
      );
      const truckTooltip = this.mapService.createUnassignedTruckInfobox(
        order.user.name,
        this.calculateReceivingTime(order.user.lastTimeSeen),
      );
      truckMarker.addListener('mouseover', () => {
        truckTooltip.setContent(
          this.mapService.generateTruckTooltip(order.user.name, this.calculateReceivingTime(order.user.lastTimeSeen)),
        );
        truckTooltip.setPosition(truckMarker.getPosition());
        truckTooltip.open(this.map);
      });

      truckMarker.addListener('mouseout', () => {
        truckTooltip.close();
      });
      this.activeTruckMarker.push({
        order: order,
        marker: truckMarker,
        id: order.id,
        tooltip: truckTooltip,
        insideActiveRestriction: false,
      });
      truckMarker.setMap(this.map);
    }
  }

  generateBoxPickupAndMarker(boxPickup: BoxPickup) {
    const boxPickupMarker = this.mapService.createBoxPickupMarker(boxPickup);
    const boxPickupToolTip = this.mapService.createBoxPickupInfobox(boxPickup);
    boxPickupToolTip.setPosition(
      new google.maps.LatLng(
        boxPickupMarker
          .getBounds()
          .getNorthEast()
          .lat(),
        boxPickupMarker.getCenter().lng(),
      ),
    );
    boxPickupToolTip.open(this.map);
    this.infoBoxPickupBoxList.push(boxPickupToolTip);

    boxPickupMarker.setMap(this.map);

    this.activeBoxPickupMarker.push({ id: boxPickup.id, marker: boxPickupMarker });
  }

  generateRunBoardMarkers() {
    this.runboardActiveDriverSub = this.runboardService.activeDrivers$.subscribe((drivers) => {
      drivers.forEach((order) => {
        this.repaintRunboardTruckMarker(order);
      });
      this.checkRunboardOrdersInsideRestrictions();
    });
  }

  generateLogisticsRunBoardMarkers() {
    this.logisticsRunboardActiveDriverSub = this.logisticsRunboardService.activeDrivers$.subscribe((drivers) => {
      drivers.forEach((order) => {
        this.repaintRunboardTruckMarker(order);
      });
    });
  }

  repaintRunboardTruckMarker(summary: OrderSummary) {
    let alreadyCreated = false;
    for (let i = 0; i < this.runBoardTruckMarker.length; i++) {
      const { marker, id, tooltip } = this.runBoardTruckMarker[i];
      if (id === summary.orderId) {
        tooltip.setPosition(marker.getPosition());
        alreadyCreated = true;
        return;
      }
    }
    if (!alreadyCreated) {
      this.generateRunBoardTruckMarker(summary);
    }
  }

  generateRunBoardTruckMarker(summary: OrderSummary) {
    if (summary.userStatus && summary.userStatus.lastLngLat && summary.userStatus.lastLngLat.length === 2) {
      const truckMarker = this.mapService.createTruckMarkerWithStatus(
        summary.userStatus.lastLngLat,
        summary.loaded,
        summary.loadNumber,
        summary.meshType,
        false,
      );
      const truckTooltip = this.mapService.createUnassignedTruckInfobox(
        summary.userName,
        this.calculateReceivingTime(summary.userStatus.lastTimeSeen),
      );
      truckMarker.addListener('mouseover', () => {
        truckTooltip.setContent(
          this.mapService.generateTruckTooltip(
            summary.userName,
            this.calculateReceivingTime(summary.userStatus.lastTimeSeen),
          ),
        );
        truckTooltip.setPosition(truckMarker.getPosition());
        truckTooltip.open(this.map);
      });

      truckMarker.addListener('mouseout', () => {
        truckTooltip.close();
      });
      this.runBoardTruckMarker.push({
        summary,
        marker: truckMarker,
        id: summary.orderId,
        tooltip: truckTooltip,
        insideActiveRestriction: false,
      });
      truckMarker.setMap(this.map);
    }
  }

  updateMap({ frac, state, order, socketUpdate }) {
    // Just in case there is anything dangling
    this.clearRunboardSubs();
    const previousState = this.selectedState;
    this.selectedFrac = frac;
    this.selectedState = state;
    this.selectedOrder = order;
    switch (state) {
      case MapUtilState.Pending: {
        if (frac) {
          if (previousState === MapUtilState.FracList || previousState === MapUtilState.Pending) {
            this.deleteFracMarkers();
            this.deleteMineMarkers();
            this.deleteUnassignedTruckMarkers();
            this.deleteBoxPickupMarkers();
            this.createFracMarkers(frac, true, true, order, this.reports);
            this.generateUnassignedTruckMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          } else if (previousState === MapUtilState.InProgress) {
            this.deleteActiveTruckMarkers();
            this.generateUnassignedTruckMarkers();
          } else if (previousState === MapUtilState.PendingOrderDetail) {
            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order);
              });
            }
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          }
        }
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.InProgress: {
        if (frac) {
          if (previousState === MapUtilState.FracList || previousState === MapUtilState.InProgress) {
            this.deleteFracMarkers();
            this.generateFracAndMarker(frac, true, true);

            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order);
              });
            }

            this.deleteUnassignedTruckMarkers();
            this.deleteActiveTruckMarkers();
            this.deleteBoxPickupMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  this.generateMapTruckMarker(o);
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          } else if (previousState === MapUtilState.Pending || previousState === MapUtilState.Completed) {
            this.deleteUnassignedTruckMarkers();
            this.deleteActiveTruckMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  this.generateMapTruckMarker(o);
                }
              });
            }
          } else if (previousState === MapUtilState.InProgressOrderDetail) {
            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order);
              });
            }
            this.deleteActiveTruckMarkers();
            this.deleteBoxPickupMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  this.generateMapTruckMarker(o);
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          }
        }
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.Completed: {
        if (frac) {
          if (previousState === MapUtilState.FracList || previousState === MapUtilState.Completed) {
            this.deleteFracMarkers();
            this.deleteMineMarkers();
            this.deleteUnassignedTruckMarkers();
            this.createFracMarkers(frac, true, true, order, this.reports);
            this.generateUnassignedTruckMarkers();
            this.deleteBoxPickupMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          } else if (previousState === MapUtilState.InProgress) {
            this.deleteActiveTruckMarkers();
            this.generateUnassignedTruckMarkers();
          } else if (previousState === MapUtilState.CompletedOrderDetail) {
            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order);
              });
            }
            // this is a weird issue, without this, if you navigate in and out of an order with a box pickup, the previous state box pickup tooltip will not dissapear (the circle will)
            this.deleteFracMarkers();
            this.createFracMarkers(frac, true, true, order, this.reports);
            this.deleteBoxPickupMarkers();
            if (frac.orders) {
              frac.orders.forEach((o, i) => {
                if (o.orderStatus === 'driver_accepted' || o.orderStatus === 'dispatched') {
                  if (o.boxPickup) {
                    this.generateBoxPickupAndMarker(o.boxPickup);
                  }
                }
              });
            }
          }
        }
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.PendingOrderDetail: {
        if (frac) {
          this.deleteBoxPickupMarkers();
          if (previousState === MapUtilState.FracList || previousState === MapUtilState.PendingOrderDetail) {
            this.generateFracAndMarker(frac, true, true);
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order, order.mine.id === mine.id);
              });
            }
            this.generateUnassignedTruckMarkers();
          } else if (previousState === MapUtilState.Pending) {
            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                this.generateLoaderAndMarker(mine, true, order, order.mine.id === mine.id);
              });
            }
          }
        }

        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.InProgressOrderDetail: {
        if (frac) {
          this.deleteBoxPickupMarkers();
          if (
            previousState === MapUtilState.FracList ||
            previousState === MapUtilState.InProgressOrderDetail ||
            previousState === MapUtilState.CompletedOrderDetail
          ) {
            // For reroute redrawing
            this.deleteFracMarkers();

            this.generateFracAndMarker(frac, true, true);
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                if (order.mine.id === mine.id) {
                  this.generateLoaderAndMarker(mine, true, order);
                }
              });
            }
            this.deleteActiveTruckMarkers();
            if (order.user) {
              this.generateMapTruckMarker(order);
            }
          } else if (previousState === MapUtilState.AddDriver) {
            this.deleteActiveTruckMarkers();
            this.deleteUnassignedTruckMarkers();
            if (order.user) {
              this.generateMapTruckMarker(order);
            }
          } else if (previousState === MapUtilState.InProgress) {
            this.deleteMineMarkers();
            this.deleteActiveTruckMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                if (order.mine.id === mine.id) {
                  this.generateLoaderAndMarker(mine, true, order);
                }
              });
            }
            if (order.user) {
              this.generateMapTruckMarker(order);
            }
          }
          if (order.boxPickup) {
            this.generateBoxPickupAndMarker(order.boxPickup);
          }
        }
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }

      case MapUtilState.CompletedOrderDetail: {
        this.deleteBoxPickupMarkers();
        if (frac) {
          if (
            previousState === MapUtilState.FracList ||
            previousState === MapUtilState.InProgressOrderDetail ||
            previousState === MapUtilState.CompletedOrderDetail
          ) {
            // For reroute redrawing
            this.deleteFracMarkers();

            this.generateFracAndMarker(frac, true, true);
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                if (order.mine.id === mine.id) {
                  this.generateLoaderAndMarker(mine, true, order);
                }
              });
            }
            this.generateUnassignedTruckMarkers();
          } else if (previousState === MapUtilState.Completed) {
            // this is a weird issue, without this, if you navigate in and out of an order with a box pickup, the previous state box pickup tooltip will not dissapear (the circle will)
            this.deleteFracMarkers();
            this.createFracMarkers(frac, true, true, order, this.reports);
            this.deleteMineMarkers();
            if (frac.fracMines) {
              frac.fracMines.forEach((mine) => {
                if (order.mine.id === mine.id) {
                  this.generateLoaderAndMarker(mine, true, order);
                }
              });
            }
          }
          if (order.boxPickup) {
            this.generateBoxPickupAndMarker(order.boxPickup);
          }
        }
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }

      case MapUtilState.AddDriver: {
        this.deleteActiveTruckMarkers();
        this.generateUnassignedTruckMarkers();
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.RunboardSummary: {
        this.deleteBoxPickupMarkers();
        if (previousState !== MapUtilState.RunboardSummary) {
          this.deleteAllMarkers();
        } else {
          this.deleteRunBoardTruckMarkers();
        }
        if (frac && frac.length) {
          frac.forEach((f) => {
            this.createFracMarkers(f, true, true, order, this.reports);
          });
        }
        this.generateRunBoardMarkers();
        if (!socketUpdate) {
          this.getLatLongBoundsForFracs();
        }
        break;
      }
      case MapUtilState.RunboardSummaryDriver: {
        this.deleteBoxPickupMarkers();
        let isFirstResponse = true;
        let currentOrderId: number;
        this.runboardSelectedDriverSub = this.runboardService.selectedDriver$.subscribe((driver) => {
          if (driver && driver.order) {
            if (currentOrderId !== driver.order.orderId) {
              // Different order is now being painted so make sure it is the only one being painted
              this.deleteRunBoardTruckMarkers();
              currentOrderId = driver.order.orderId;
            }
            if (isFirstResponse) {
              // Only paint the fracs once.
              if (frac && frac.length) {
                frac.forEach((f) => {
                  this.createFracMarkers(f, true, true, order, this.reports);
                });
              }
              this.getLatLongBoundsForFracs();
              isFirstResponse = false;
            }
            // Is this the best way to repaint it?
            this.repaintRunboardTruckMarker(driver.order);
          }
        });
        break;
      }
    }
    this.checkOrdersInsideRestrictions();
    this.checkRunboardOrdersInsideRestrictions();
  }

  changeFocusedMine(newMine) {
    this.activeMineMarker.forEach((mine) => {
      if (newMine.id === mine.id) {
        mine.marker.setOptions({ fillColor: '#31246E' });
      } else {
        mine.marker.setOptions({ fillColor: '#92A0AD' });
      }
    });
  }

  updateDriver({ driver, status }) {
    if (this.highlightedDriver) {
      const markerImage = {
        url: '../../assets/icons/loaded-truck.svg',
        size: new google.maps.Size(30, 30),
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 15),
      };
      this.highlightedDriver.marker.setIcon(markerImage);
      this.highlightedDriver.marker.setZIndex(null);
      this.highlightedDriver.tooltip.close();
      this.highlightedDriver = null;
    }
    // These are called unassigned trucks but they are actually drivers, this is before my time
    if (status && this.unassignedTrucksMarkers) {
      for (const unassignedDriver of this.unassignedTrucksMarkers) {
        if (unassignedDriver.id === driver.id) {
          const markerImage = {
            url: '../../assets/icons/busy-truck.svg',
            size: new google.maps.Size(50, 50),
            scaledSize: new google.maps.Size(50, 50),
            anchor: new google.maps.Point(25, 25),
          };
          unassignedDriver.marker.setIcon(markerImage);
          unassignedDriver.marker.setZIndex(1000);
          unassignedDriver.tooltip.setPosition(unassignedDriver.marker.getPosition());
          unassignedDriver.tooltip.open(this.map, null);
          this.map.panTo(unassignedDriver.marker.getPosition());
          this.highlightedDriver = unassignedDriver;
          break;
        }
      }
    }
  }

  updateData(data) {
    if (data.objectType === 'frac' && data.channel === 'frac') {
      this.updateCompleteFrac(data.body);
    } else if (data.objectType === 'frac' && data.channel === 'trucksList') {
      this.updateOrder(data.body);
    } else if (data.objectType === 'fracOrders' && data.channel === 'ordersInProgress') {
      this.updateFracOrders(data.body);
    }
  }

  updateCompleteFrac(data) {
    // 0 is the default from server if ID can't be found.
    if (data && data.site && data.site.id !== 0) {
      const index = this.fracs.findIndex((frac) => frac.site.id === data.site.id);
      if (index === -1) {
        this.fracs.push(data);
      } else {
        this.fracs[index] = data;
      }
      if (this.selectedState === 1) {
        this.updateMap({ frac: null, state: this.selectedState, order: null, socketUpdate: true });
      } else if (this.selectedState > 1 && this.selectedState < 5) {
        if (data.id === this.selectedFrac.id) {
          this.updateMap({ frac: data, state: this.selectedState, order: null, socketUpdate: true });
        }
      } else {
        this.fracs.forEach((frac) => {
          if (frac.id === this.selectedFrac.id && frac.id === data.id) {
            frac.orders.forEach((order) => {
              if (order.id === this.selectedOrder.id) {
                this.updateMap({ frac: frac, state: this.selectedState, order: order, socketUpdate: true });
              }
            });
          }
        });
      }
    }
  }

  updateOrder(data) {
    this.fracs.forEach((frac) => {
      if (frac.orders) {
        frac.orders.forEach((order) => {
          if (order.fracId === data.id && data.orders && data.orders.length && order.id === data.orders[0].id) {
            order.eta = data.orders[0].eta || order.eta;
            if (order.user) {
              order.user.lastTimeSeen = data.orders[0].user.lastTimeSeen || order.user.lastTimeSeen;
              order.user.activeSession = data.orders[0].user.activeSession || order.user.activeSession;
              order.user.lastLngLat = data.orders[0].user.lastLngLat || order.user.lastLngLat;

              for (let i = 0; i < this.activeTruckMarker.length; i++) {
                if (this.activeTruckMarker[i].id === order.id) {
                  this.activeTruckMarker[i].tooltip.close();
                  this.activeTruckMarker[i].marker.setMap(null);
                  const marker = this.activeTruckMarker.splice(i, 1);
                  this.generateMapTruckMarker(order);
                }
              }
            }
          }
        });
      }
    });
    this.checkOrdersInsideRestrictions();
  }

  updateFracOrders(data) {
    this.fracs.forEach((frac) => {
      if (frac.id === data.fracId) {
        frac.orders = data.orders;
      }
    });

    if (this.selectedState > 1 && this.selectedState < 5) {
      if (data.id === this.selectedFrac.id) {
        this.fracs.forEach((frac) => {
          if (frac.id === this.selectedFrac.id && frac.id === data.id) {
            this.updateMap({ frac: data, state: this.selectedState, order: null, socketUpdate: true });
          }
        });
      }
    } else if (this.selectedState > 4) {
      this.fracs.forEach((frac) => {
        if (frac.id === this.selectedFrac.id && frac.id === data.id) {
          frac.orders.forEach((order) => {
            if (order.id === this.selectedOrder.id) {
              this.updateMap({ frac: frac, state: this.selectedState, order: order, socketUpdate: true });
            }
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((_) => _.unsubscribe());
  }

  clearRunboardSubs() {
    if (this.runboardSelectedDriverSub) {
      this.runboardSelectedDriverSub.unsubscribe();
    }

    if (this.runboardActiveDriverSub) {
      this.runboardActiveDriverSub.unsubscribe();
    }

    if (this.logisticsRunboardActiveDriverSub) {
      this.logisticsRunboardActiveDriverSub.unsubscribe();
    }

    if (this.logisticsRunboardSelectedDriverSub) {
      this.logisticsRunboardSelectedDriverSub.unsubscribe();
    }
  }

  private setupFracListListener() {
    this.subs.push(
      combineLatest([
        (this.storeService.select('fracs') as Observable<Frac[]>).pipe(
          filter((fracs) => fracs && fracs.length > 0),
          take(1),
        ),
        this.routerState.routerState$.pipe(pluck('url'), distinctUntilChanged()),
      ]).subscribe(([fracs, url]) => {
        if (url === '/map/jobs/list') {
          this.deleteAllMarkers();
          const fracIds: number[] = [];
          fracs.forEach((frac, index) => {
            fracIds.push(frac.id);
            this.createFracMarkers(frac, false, false, null, this.reports);
          });
          this.generateUnassignedTruckMarkers(true);
          this.getLatLongBoundsForFracs();
        }
      }),
    );
  }

  private setupLogisticsRunboardListener() {
    this.subs.push(
      combineLatest([
        this.routerState.listenForParamChange$(fromRouterConstants.LOGISTICS_CLUSTER_ID).pipe(distinctUntilChanged()),
        this.routerState
          .listenForParamChange$(fromRouterConstants.LOGISTICS_CLUSTER_USER_ID)
          .pipe(distinctUntilChanged()),
      ])
        .pipe(
          switchMap(([clusterId, userId]) => {
            if (clusterId) {
              this.deleteAllMarkers();
              if (userId) {
                return this.setupLogisticsRunboardUserListener();
              }
              return this.setupLogisticsRunboardClusterListener();
            }
            return of(null);
          }),
        )
        .subscribe(() => {}),
    );
  }

  private setupLogisticsRunboardClusterListener() {
    return combineLatest([
      this.dispatcherFracService.curentFracClusterSummary$,
      this.storeService.select('fracs') as Observable<Frac[]>,
    ]).pipe(
      map(([currentCluster, fracs]) => {
        if (currentCluster) {
          const fracIds = currentCluster.siteSummaries.map((summary) => summary.id);
          return fracs.filter((frac) => fracIds.includes(frac.id));
        } else {
          return null;
        }
      }),
      filter((fracs) => fracs && !!fracs.length),
      take(1),
      tap((fracs) => {
        if (fracs) {
          const fracIds: number[] = [];
          fracs.forEach((frac) => {
            this.createFracMarkers(frac, true, true, null, this.reports);
            fracIds.push(frac.id);
          });
          this.getLatLongBoundsForFracs();
        }
      }),
      switchMap(() => {
        return this.logisticsRunboardService.activeDrivers$.pipe(
          map((drivers) => {
            drivers.forEach((order) => {
              this.repaintRunboardTruckMarker(order);
            });
          }),
        );
      }),
    );
  }

  private setupLogisticsRunboardUserListener() {
    let hasDrawnFracs = false;
    return combineLatest([
      this.dispatcherFracService.curentFracClusterSummary$,
      this.logisticsRunboardService.selectedDriver$,
      this.storeService.select('fracs') as Observable<Frac[]>,
    ]).pipe(
      map(([currentCluster, driver, fracs]) => {
        if (!hasDrawnFracs && currentCluster && driver && driver.order) {
          const orderFracs = selectFrac(fracs, driver.order);
          orderFracs.forEach((frac) => {
            this.createFracMarkers(frac, true, true, null, this.reports);
          });
          if (orderFracs.length) {
            this.getLatLongBoundsForFracs();
            hasDrawnFracs = true;
          }
        }
        return driver;
      }),
      tap((driver) => {
        if (driver && driver.order) {
          this.repaintRunboardTruckMarker(driver.order);
          const markers = this.runBoardTruckMarker || [];
          const bounds = this.map.getBounds();
          if (markers.some((marker) => !bounds.contains(marker.marker.getPosition()))) {
            this.getLatLongBoundsForFracs();
          }
        }
      }),
    );
  }

  private handleRestrictionUpdates(restrictions: Restriction[]) {
    // remove the ones that are no longer there
    Object.values(this.restrictionPolygons).forEach((poly) => poly.polygon.setMap(null));
    this.restrictionPolygons = restrictions.reduce((acc, restriction) => {
      const polygon = new google.maps.Polygon({
        fillColor: restriction.applicableNow ? '#FF0000' : '#555555',
        fillOpacity: 0.25,
        map: this.map,
        paths: [restriction.polygon],
      });
      let infoWindowContent = `<div class="font-bold">${restriction.name}</div>`;
      (restriction.prettyPrintTimes || []).forEach((timeStr) => {
        infoWindowContent += `<div>${timeStr}</div>`;
      });
      if (restriction.applicableNow) {
        infoWindowContent += `<div class="text-lg font-bold flex justify-center">🚧 Active Now 🚧</div>`;
      }
      const infowindow = new google.maps.InfoWindow({
        content: infoWindowContent,
        pixelOffset: new google.maps.Size(0, -10),
      });
      polygon.addListener('mouseover', function(event: google.maps.MouseEvent) {
        infowindow.setPosition(event.latLng);
        infowindow.open((this as any).map);
      });

      polygon.addListener('mousemove', function(event: google.maps.MouseEvent) {
        infowindow.setPosition(event.latLng);
      });

      polygon.addListener('mouseout', function(event: google.maps.MouseEvent) {
        infowindow.close();
      });
      acc[restriction.id] = {
        polygon,
        restriction,
      };
      return acc;
    }, {} as Record<number, { restriction: Restriction; polygon: google.maps.Polygon }>);
    this.checkOrdersInsideRestrictions();
    this.checkRunboardOrdersInsideRestrictions();
  }

  private checkOrdersInsideRestrictions() {
    if (!this.activeTruckMarker || !this.restrictionPolygons) {
      return;
    }
    Object.values(this.activeTruckMarker).forEach((mapOrder) => {
      let insideActiveRestriction = false;
      const position = mapOrder.marker.getPosition();
      for (const polyKey in this.restrictionPolygons) {
        if (!this.restrictionPolygons.hasOwnProperty(polyKey)) {
          continue;
        }
        const poly = this.restrictionPolygons[polyKey];
        if (!poly.restriction.applicableNow) {
          continue;
        }
        if (google.maps.geometry.poly.containsLocation(position, poly.polygon)) {
          insideActiveRestriction = true;
          break;
        }
      }

      if (insideActiveRestriction !== mapOrder.insideActiveRestriction) {
        const markerColor = this.mapService.truckMarkerIconColor(mapOrder.order.loaded, mapOrder.order.onBoxMove);
        const stroke = insideActiveRestriction ? 'red' : 'white';
        const strokeWidth = insideActiveRestriction ? 4 : 2;
        const svgImage = this.mapService.getDataSvg(mapOrder.order.loadNumber, markerColor, stroke, strokeWidth);
        const icon = {
          ...(mapOrder.marker.getIcon() as google.maps.Icon),
        };
        icon.url = svgImage;
        mapOrder.marker.setIcon(icon);
        mapOrder.insideActiveRestriction = insideActiveRestriction;
      }
    });
  }

  private checkRunboardOrdersInsideRestrictions() {
    if (!this.runBoardTruckMarker || !this.restrictionPolygons) {
      return;
    }
    Object.values(this.runBoardTruckMarker).forEach((mapOrder) => {
      let insideActiveRestriction = false;
      const position = mapOrder.marker.getPosition();
      for (const polyKey in this.restrictionPolygons) {
        if (!this.restrictionPolygons.hasOwnProperty(polyKey)) {
          continue;
        }
        const poly = this.restrictionPolygons[polyKey];
        if (!poly.restriction.applicableNow) {
          continue;
        }
        if (google.maps.geometry.poly.containsLocation(position, poly.polygon)) {
          insideActiveRestriction = true;
          break;
        }
      }

      if (insideActiveRestriction !== mapOrder.insideActiveRestriction) {
        const markerColor = this.mapService.truckMarkerIconColor(mapOrder.summary.loaded, false);
        const stroke = insideActiveRestriction ? 'red' : 'white';
        const strokeWidth = insideActiveRestriction ? 4 : 2;
        const svgImage = this.mapService.getDataSvg(mapOrder.summary.loadNumber, markerColor, stroke, strokeWidth);
        const icon = {
          ...(mapOrder.marker.getIcon() as google.maps.Icon),
        };
        icon.url = svgImage;
        mapOrder.marker.setIcon(icon);
        mapOrder.insideActiveRestriction = insideActiveRestriction;
      }
    });
  }
}

function selectFrac(fracs: Frac[], orderSummary: OrderSummary): Frac[] {
  const selectedFrac = fracs.filter((frac) => frac.id === orderSummary.fracId)[0];
  if (!selectedFrac) {
    return fracs;
  }
  const selectedMine = (selectedFrac.fracMines || []).filter((mine) => mine.site.name === orderSummary.mineName);
  return [
    {
      ...selectedFrac,
      fracMines: selectedMine,
    },
  ];
}
