<div class="file-preview-wrap">
  <div class="file-wrap">
    <div class="download-buttons-wrap" *ngIf="!fileLoading">
      <div
        class="download-wrap"
        [ngStyle]="{ 'padding-bottom': uploading ? '16px' : '10px' }"
        *ngIf="selectedOrder?.billingStatus !== 'billed' && !isLmoAccount"
      >
        <label class="upload-btn">
          <input (change)="onFileChange($event)" id="img-file-upload" type="file" />
          <i *ngIf="!uploading" class="material-icons">arrow_upward</i>
          <span *ngIf="uploading">
            <mat-spinner class="white-spinner" [diameter]="25"></mat-spinner>
          </span>
        </label>
      </div>
      <div class="download-wrap" (click)="getPreviewDownloadLink()" *ngIf="previewFileUrl || isAutomatedCSVFile">
        <i class="material-icons">arrow_downward</i>
      </div>
      <div
        class="download-wrap delete-icon"
        *ngIf="checkForDeleteFile() && selectedOrder?.billingStatus !== 'billed' && !isLmoAccount"
        (click)="deleteFile()"
      >
        <i class="material-icons">delete</i>
      </div>
      <div class="download-wrap" *ngIf="checkForImageFile()" (click)="rotateImage(-90)">
        <i class="material-icons">rotate_left</i>
      </div>
      <div class="download-wrap" *ngIf="checkForImageFile()" (click)="rotateImage(90)">
        <i class="material-icons">rotate_right</i>
      </div>
    </div>
    <div class="file-preview" *ngIf="previewFileUrl && !fileLoading">
      <iframe [src]="previewFileUrl | safe: 'resourceUrl'" frameborder="0" *ngIf="!checkForImageFile()"></iframe>
      <img
        [src]="previewFileUrl"
        *ngIf="checkForImageFile()"
        [ngStyle]="{ transform: 'rotate(' + rotateDegree + 'deg)' }"
      />
      <div class="preview-placeholder" *ngIf="checkPlaceholder()">
        <h3>This attachment cannot be previewed.</h3>
      </div>
    </div>
    <div class="placeholder" *ngIf="!previewFileUrl && !fileLoading && !isAutomatedCSVFile">
      <ngx-file-drop
        dropZoneLabel="Select a file to preview or drop your file here to upload"
        (onFileDrop)="dropped($event)"
      >
      </ngx-file-drop>
    </div>
    <div class="loader-placeholder" *ngIf="fileLoading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
  <div class="file-options">
    <div
      class="file-card one"
      (click)="downloadCSVData()"
      [ngClass]="{ selected: selectedFileName === getCSVFileName() }"
    >
      <img [src]="getFileIcon('trucking-Backup.csv')" />
      <h3 *ngIf="getCSVFileName(); else loadingWell">{{ getCSVFileName() }}</h3>
      <ng-template #loadingWell>
        <mat-spinner [diameter]="25"></mat-spinner>
      </ng-template>
    </div>
    <div
      class="file-card two"
      *ngIf="selectedOrder?.dataMatched"
      (click)="downloadImportedData()"
      [ngClass]="{ selected: selectedFileName === getImportedFileName() }"
    >
      <img [src]="getFileIcon('Loader-Backup.csv')" />
      <h3>{{ getImportedFileName() }}</h3>
    </div>
    <div
      class="file-card three"
      *ngFor="let file of selectedOrder?.uploadedFiles"
      (click)="downloadFile(file)"
      [ngClass]="{ selected: selectedFileName === file.fileName }"
    >
      <img [src]="getFileIcon(file.fileName)" alt="" />
      <h3>{{ smallFileName(file.fileName) }}</h3>
    </div>
  </div>
</div>
<div class="completed-order-detail-component">
  <div class="header-wrap">
    <div class="head-details">
      <div class="head-text">
        <h1 saAutomatedSelector="billing_order__sa_unique_id">
          {{ selectedOrder?.saUniqueId }}
        </h1>
        <span *ngIf="selectedOrder?.dataMatched" class="tag">Matched</span>
        <ng-container *ngIf="checkIfReceivedByCustomer()">
          <span
            [matTooltip]="getRetreivedTooltip()"
            matTooltipPosition="below"
            class="tag blue"
            *ngIf="selectedOrder.retrievedByAPI; else noRetreived"
            >Sent By API</span
          >
          <ng-template #noRetreived>
            <span class="tag gray">Not Sent by API</span>
          </ng-template>
        </ng-container>
      </div>
      <h5 *ngIf="canApproveChanges">{{ selectedOrder?.vendor.name }}</h5>
      <span *ngIf="selectedOrder?.dataUploadTimestamp"
        >This order was matched with {{ selectedOrder?.mine.site.name }}'s data on
        {{ datePipe.transform(selectedOrder?.dataUploadTimestamp, 'short') }}</span
      >
      <h5>{{ getOrderStatus() }}</h5>
      <h5 class="order_desc" *ngIf="selectedOrder?.description">"{{ selectedOrder?.description }}"</h5>
    </div>
    <div class="presence-map-wrap" *ngIf="activeUsers$$ | async as activeUsers">
      <ng-container *ngFor="let user of activeUsers">
        <div class="user-icon" [matTooltip]="user.userName" matTooltipPosition="above">
          {{ getNameAbbreviation(user.userName) }}
        </div>
        <div class="user-icon" *ngIf="activeUsers.length > 2">+ {{ activeUsers.length - 2 }}</div>
      </ng-container>
    </div>
    <div
      class="close"
      [ngClass]="{ 'left-auto': !(activeUsers$$ | async) }"
      (click)="close()"
      saAutomatedSelector="billing_order__close"
    >
      <span>esc</span><i class="material-icons">clear</i>
    </div>
  </div>

  <div class="content-wrap">
    <div class="push-back-wrap error" *ngIf="checkForLMOPushBack(selectedOrder) && orderForm">
      <div class="logo-wrap">
        <img src="../../../assets/icons/pushed-back.svg" alt="Pushed Back" />
      </div>
      <div class="push-back-content">
        <h3 class="heading">Invoice Pushed Back by Customer</h3>
        <h3>
          This order was pushed back by the customer on
          {{ datePipe.transform(selectedOrder?.orderStatusChangeHistory.changedTimestamp, 'shortDate') }} for the
          following reason:
        </h3>
        <h3>
          <i saAutomatedSelector="billing_order__pushback_reason">"{{ selectedOrder?.pushbackReason }}"</i>
        </h3>
      </div>
    </div>

    <div
      class="push-back-wrap duplicate-bol"
      *ngIf="selectedOrder && selectedOrder.duplicatedBolOrderIds && selectedOrder.duplicatedBolOrderIds.length"
    >
      <div class="push-back-content">
        <h3>
          BOL is also used on
          <ng-container *ngFor="let id of selectedOrder.duplicatedBolOrderIds; let i = index">
            <ng-container *ngIf="i > 0">
              <span *ngIf="i < selectedOrder.duplicatedBolOrderIds.length - 1; else andSpan">,</span>
              <ng-template #andSpan>
                <span> and </span>
              </ng-template>
            </ng-container>
            <a [routerLink]="['./', id]"> S{{ id }} </a>
          </ng-container>
        </h3>
      </div>
    </div>

    <div class="push-back-wrap error" *ngIf="checkForDispatcherPushBack(selectedOrder) && orderForm">
      <div class="logo-wrap">
        <img src="../../../assets/icons/pushed-back.svg" alt="Pushed Back" />
      </div>
      <div class="push-back-content">
        <h3 class="heading">Invoice Pushed Back by Trucking Vendor</h3>
        <ng-container *ngIf="selectedOrder?.pushbackReason; else noPushbackReason">
          <h3>
            This order was pushed back as the vendor made some changes to the order details with the following comment:
          </h3>
          <h3>
            <i saAutomatedSelector="billing_order__pushback_reason">"{{ selectedOrder?.pushbackReason }}"</i>
          </h3>
        </ng-container>
        <ng-template #noPushbackReason>
          <h3 saAutomatedSelector="billing_order__pushback_reason">
            This order was pushed back as the vendor made changes to the order details.
          </h3>
        </ng-template>
      </div>
    </div>

    <div class="push-back-wrap reroute" *ngIf="selectedOrder?.reroutedFrom">
      <div class="logo-wrap">
        <img src="../../../assets/icons/RerouteFrom.png" alt="reroutedFrom" />
      </div>
      <div class="push-back-content">
        <ng-container *ngIf="selectedOrder?.mine.site.name !== selectedOrder?.reroutedFrom.mineName">
          <h3 class="heading">Rerouted From Loader</h3>
          <h3>
            This order was rerouted from a previous loader, {{ selectedOrder?.reroutedFrom.mineName }}. The previous
            order, #{{ selectedOrder?.reroutedFrom.loadNumber }}, has been cancelled and can be viewed in the export.
          </h3>
        </ng-container>

        <ng-container
          *ngIf="
            selectedOrder?.reroutedFrom &&
            selectedOrder?.reroutedTo &&
            selectedOrder.reroutedTo(wellService.billingDetails$ | async | fracName) !==
              selectedOrder?.reroutedFrom?.fracName &&
            selectedOrder?.reroutedFrom.fracName !== selectedOrder?.reroutedTo.fracName
          "
        >
          <h3 class="heading">Rerouted From Well</h3>
          This order was rerouted from a previous well, {{ selectedOrder?.reroutedFrom.fracName }}. The previous order,
          #{{ selectedOrder?.reroutedFrom.loadNumber }}, has been cancelled and can be viewed in the export for
          {{ selectedOrder?.reroutedFrom.fracName }}.
        </ng-container>
      </div>
    </div>

    <div class="loader-wrap" *ngIf="!orderForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="orderForm" [formGroup]="orderForm" autocomplete="off">
      <div class="section-wrap">
        <div class="title-row">
          <h3>Payload Details</h3>
          <div class="tag" *ngIf="isLoadoutReport()">From Loadout Report</div>
          <div class="tag orange" *ngIf="isManuallyEntered()">Manually Entered</div>
        </div>

        <div class="field-wrap">
          <div class="form-field left">
            <div class="label">
              Sequence Number
            </div>
            <mat-form-field>
              <input matInput type="number" [formControl]="(orderForm?.controls)['sequenceNumber']" />
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="!canApproveChanges">
            <div [ngClass]="updatedFields.has('loader') ? 'label updated' : 'label'">
              Loader
              <ng-template #loader1Template>
                <span [innerHTML]="getOldValueFromData('loader')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('loader')"
                placement="top"
                [tooltip]="loader1Template"
                content-type="template"
              >
                ?
              </div>
            </div>

            <mat-form-field>
              <mat-select
                [formControl]="(orderForm?.controls)['loader']"
                placeholder="Loader"
                [compareWith]="compareFn"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [placeholderLabel]="'Loader'"
                    [noEntriesFoundLabel]="'No matching loader found'"
                    [formControl]="filterLoader"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let option of filteredLoaderOptions | async" [value]="option">
                  {{ option.site.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="canApproveChanges">
            <div [ngClass]="updatedFields.has('loader') ? 'label updated' : 'label'">
              Loader
              <ng-template #loader2Template>
                <span [innerHTML]="getOldValueFromData('loader')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('loader')"
                placement="top"
                [tooltip]="loader2Template"
                content-type="template"
              >
                ?
              </div>
            </div>

            <mat-form-field>
              <input type="text" matInput disabled :value="{{ selectedOrder.mine.site.name }}" />
            </mat-form-field>
          </div>

          <div class="form-field left" *ngIf="!canApproveChanges">
            <div [ngClass]="updatedFields.has('payload') ? 'label updated' : 'label'">
              Payload
              <ng-template #payload1Template>
                <span [innerHTML]="getOldValueFromData('payload')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('payload')"
                placement="top"
                [tooltip]="payload1Template"
                content-type="template"
              >
                ?
              </div>
            </div>

            <mat-form-field>
              <mat-select
                [formControl]="(orderForm?.controls)['payload']"
                placeholder="Payload"
                [compareWith]="compareFn"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [placeholderLabel]="'Payload'"
                    [noEntriesFoundLabel]="'No matching mesh found'"
                    [formControl]="filterMesh"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let option of filteredMeshOptions | async" [value]="option">
                  {{ option.type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-field left" *ngIf="canApproveChanges">
            <div [ngClass]="updatedFields.has('payload') ? 'label updated' : 'label'">
              Payload
              <ng-template #payload2Template>
                <span [innerHTML]="getOldValueFromData('payload')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('payload')"
                placement="top"
                [tooltip]="payload2Template"
                content-type="template"
              >
                ?
              </div>
            </div>

            <mat-form-field>
              <input type="text" matInput disabled :value="{{ selectedOrder.mesh.type }}" />
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="!selectedOrder?.boxes">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('loadWeight'), required: checkForRequiredField('loadWeight') }"
            >
              Load Weight
              <ng-template #loadWeightTemplate>
                <span [innerHTML]="getOldValue('loadWeight')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('loadWeight')"
                placement="top"
                [tooltip]="loadWeightTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="number" [formControl]="(orderForm?.controls)['loadWeight']" />
              <mat-hint *ngIf="formatWeightAsTons(orderForm.get('loadWeight').value)">{{
                formatWeightAsTons(orderForm.get('loadWeight').value)
              }}</mat-hint>
              <mat-error *ngIf="orderForm?.controls['loadWeight'].hasError('max')">
                This looks very high. Are you sure this is correct?
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field" [ngClass]="selectedOrder?.boxes ? 'right' : 'left'">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('bolNumber'), required: checkForRequiredField('bolNumber') }"
              saAutomatedSelector="billing_order__bol_input_label"
            >
              BOL No.
              <ng-template #bolTemplate>
                <span [innerHTML]="getOldValue('bolNumber')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('bolNumber')"
                placement="top"
                [tooltip]="bolTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                [formControl]="(orderForm?.controls)['bolNumber']"
                saAutomatedSelector="billing_order__bol_input"
              />
            </mat-form-field>
          </div>

          <div class="form-field left" *ngIf="selectedOrder?.boxes && selectedOrder?.boxes.length >= 1">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('box1Name'), required: checkForRequiredField('box1Name') }"
            >
              Box 1 ID
              <ng-template #box1IdTemplate>
                <span [innerHTML]="getOldValue('box1Name')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('box1Name')"
                placement="top"
                [tooltip]="box1IdTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="text" [formControl]="(orderForm?.controls)['box1Name']" />
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="selectedOrder?.boxes && selectedOrder?.boxes.length >= 1">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('box1Weight'), required: checkForRequiredField('box1Weight') }"
              saAutomatedSelector="billing_order__box_weight_label"
            >
              Box 1 Load Weight
              <ng-template #box1WeightTemplate>
                <span [innerHTML]="getOldValue('box1Weight')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('box1Weight')"
                placement="top"
                [tooltip]="box1WeightTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="number"
                [formControl]="(orderForm?.controls)['box1Weight']"
                saAutomatedSelector="billing_order__box_weight_input"
              />
              <mat-hint *ngIf="formatWeightAsTons(orderForm.get('box1Weight').value)">{{
                formatWeightAsTons(orderForm.get('box1Weight').value)
              }}</mat-hint>
              <mat-error
                *ngIf="orderForm?.controls['box1Weight'].hasError('max')"
                saAutomatedSelector="billing_order__box_weight_error"
              >
                This looks very high. Are you sure this is correct?
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field left" *ngIf="selectedOrder?.boxes && selectedOrder?.boxes.length == 2">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('box2Name'), required: checkForRequiredField('box2Name') }"
            >
              Box 2 ID
              <ng-template #box2IdTemplate>
                <span [innerHTML]="getOldValue('box2Name')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('box2Name')"
                placement="top"
                [tooltip]="box2IdTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="text" [formControl]="(orderForm?.controls)['box2Name']" />
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="selectedOrder?.boxes && selectedOrder?.boxes.length == 2">
            <div
              class="label"
              [ngClass]="{ updated: updatedFields.has('box2Weight'), required: checkForRequiredField('box2Weight') }"
            >
              Box 2 Load Weight
              <ng-template #box2WeightTemplate>
                <span [innerHTML]="getOldValue('box2Weight')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('box2Weight')"
                placement="top"
                [tooltip]="box2WeightTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="number" [formControl]="(orderForm?.controls)['box2Weight']" />
              <mat-hint *ngIf="formatWeightAsTons(orderForm.get('box2Weight').value)">{{
                formatWeightAsTons(orderForm.get('box2Weight').value)
              }}</mat-hint>
              <mat-error *ngIf="orderForm?.controls['box2Weight'].hasError('max')">
                This looks very high. Are you sure this is correct?
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="section-wrap">
        <div class="title-row">
          <h3>Trip Details</h3>
          <div class="tag" *ngIf="!isTripDetails()">From GoHawk</div>
        </div>

        <div class="field-wrap">
          <div class="form-field left" *ngIf="!canApproveChanges">
            <div [ngClass]="updatedFields.has('driverId') ? 'label updated' : 'label'">
              Driver
              <ng-template #driverTemplate>
                <span [innerHTML]="getOldValueFromData('driverId')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('driverId')"
                placement="top"
                [tooltip]="driverTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>

            <mat-form-field>
              <mat-select
                [formControl]="(orderForm?.controls)['driverId']"
                placeholder="Driver"
                [compareWith]="compareFn"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [placeholderLabel]="'Driver'"
                    [noEntriesFoundLabel]="'No matching driver found'"
                    [formControl]="filterDriver"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="selectedOrder.user" *ngIf="selectedOrder.user">{{
                  selectedOrder.user.name
                }}</mat-option>
                <mat-option *ngFor="let option of filteredDriverOptions | async" [value]="option">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-field left" *ngIf="canApproveChanges">
            <div [ngClass]="updatedFields.has('driverId') ? 'label updated' : 'label'">
              Driver
            </div>
            <mat-form-field>
              <input type="text" matInput disabled :value="{{ selectedOrder.user?.name }}" />
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="!canApproveChanges">
            <div
              [ngClass]="
                updatedFields.has('truckId') || !(orderForm?.controls)['truckId'].value ? 'label updated' : 'label'
              "
            >
              Truck
              <ng-template #truckTemplate>
                <span [innerHTML]="getOldValueFromData('truckId')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('truckId')"
                placement="top"
                [tooltip]="truckTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                type="text"
                placeholder="Truck"
                matInput
                [formControl]="(orderForm?.controls)['truckId']"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (closed)="clearTruck()"
                [displayWith]="displayTruckFn"
                (optionSelected)="onTruckSelected($event.option.value)"
              >
                <mat-option *ngFor="let option of truckService.truckList$ | async" [value]="option">
                  {{ option.name }}
                </mat-option>
                <mat-option [value]="orderForm.controls['truckId'].value" *ngIf="checkForAddTruck$() | async">
                  <span class="add-truck-option">+ Add New Truck</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="orderForm?.get('truckId').hasError('required')">
                Truck Field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field right" *ngIf="canApproveChanges">
            <div [ngClass]="updatedFields.has('truckId') || !selectedOrder.truck ? 'label updated' : 'label'">
              Truck
            </div>
            <mat-form-field>
              <input type="text" matInput disabled [value]="selectedOrder?.truck?.name" />
            </mat-form-field>
          </div>

          <div class="form-field left">
            <div
              class="label"
              [ngClass]="{
                updated: updatedFields.has('deadheadMileage') || selectedOrder.anomalousStats?.deadheadMilesAnomalous,
                required: checkForRequiredField('deadheadMileage')
              }"
            >
              Deadhead Mileage (miles)
              <ng-template #deadheadMileageTemplate>
                <span
                  [innerHTML]="
                    getOldValueAndAnomalousValue(
                      'deadheadMileage',
                      selectedOrder.anomalousStats?.deadheadMilesAnomalous
                    )
                  "
                ></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('deadheadMileage') || selectedOrder.anomalousStats?.deadheadMilesAnomalous"
                placement="top"
                [tooltip]="deadheadMileageTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="text" sa-number-input [formControl]="(orderForm?.controls)['deadheadMileage']" />
            </mat-form-field>
          </div>

          <div class="form-field right">
            <div
              class="label"
              [ngClass]="{
                updated: updatedFields.has('tripMileage') || selectedOrder.anomalousStats?.lineHaulMilesAnomalous,
                required: checkForRequiredField('tripMileage')
              }"
            >
              Trip Mileage (miles)
              <ng-template #tripMileageTemplate>
                <span
                  [innerHTML]="
                    getOldValueAndAnomalousValue('tripMileage', selectedOrder.anomalousStats?.lineHaulMilesAnomalous)
                  "
                ></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('tripMileage') || selectedOrder.anomalousStats?.lineHaulMilesAnomalous"
                placement="top"
                [tooltip]="tripMileageTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput type="text" sa-number-input [formControl]="(orderForm?.controls)['tripMileage']" />
              <mat-hint class="warning" *ngIf="orderForm.controls['tripMileage'].value < 1">
                Trip Mileage Should be greater than 0
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="form-field left">
            <div
              class="label"
              [ngClass]="{
                updated:
                  updatedFields.has('waitAtLoader') ||
                  getOldValueAndAnomalousValue(
                    'waitAtLoader',
                    selectedOrder.anomalousStats?.waitAtLoaderMinutesAnomalous
                  ),
                required: checkForRequiredField('waitAtLoader')
              }"
              saAutomatedSelector="billing_order_detail__wait_at_loader_label"
            >
              Wait at Loader (mins)
              <ng-template #waitAtLoaderTemplate>
                <span
                  [innerHTML]="
                    getOldValueAndAnomalousValue(
                      'waitAtLoader',
                      selectedOrder.anomalousStats?.waitAtLoaderMinutesAnomalous
                    )
                  "
                ></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="
                  updatedFields.has('waitAtLoader') ||
                  getOldValueAndAnomalousValue(
                    'waitAtLoader',
                    selectedOrder.anomalousStats?.waitAtLoaderMinutesAnomalous
                  )
                "
                placement="top"
                [tooltip]="waitAtLoaderTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                sa-number-input
                required
                [formControl]="(orderForm?.controls)['waitAtLoader']"
                saAutomatedSelector="billing_order_detail__wait_at_loader_input"
              />
            </mat-form-field>
          </div>

          <div class="form-field right">
            <div
              class="label"
              [ngClass]="{
                updated:
                  updatedFields.has('waitAtWell') ||
                  getOldValueAndAnomalousValue('waitAtWell', selectedOrder.anomalousStats?.waitAtWellMinutesAnomalous),
                required: checkForRequiredField('waitAtWell')
              }"
              saAutomatedSelector="billing_order_detail__wait_at_well_label"
            >
              Wait at Well (mins)
              <ng-template #waitAtWellTemplate>
                <span
                  [innerHTML]="
                    getOldValueAndAnomalousValue('waitAtWell', selectedOrder.anomalousStats?.waitAtWellMinutesAnomalous)
                  "
                ></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="
                  updatedFields.has('waitAtWell') ||
                  getOldValueAndAnomalousValue('waitAtWell', selectedOrder.anomalousStats?.waitAtWellMinutesAnomalous)
                "
                placement="top"
                [tooltip]="waitAtWellTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                sa-number-input
                required
                [formControl]="(orderForm?.controls)['waitAtWell']"
                saAutomatedSelector="billing_order_detail__wait_at_well_input"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="section-wrap">
        <div class="title-row">
          <h3>Manual Trucking Details</h3>
        </div>

        <div class="field-wrap">
          <div class="form-field left">
            <div [ngClass]="updatedFields.has('ticketNumber') ? 'label updated' : 'label'">
              Ticket No.
              <ng-template #TicketNumberTemplate>
                <span [innerHTML]="getOldValue('ticketNumber')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('ticketNumber')"
                placement="top"
                [tooltip]="TicketNumberTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput [formControl]="(orderForm?.controls)['ticketNumber']" />
            </mat-form-field>
          </div>

          <div class="form-field right">
            <div [ngClass]="updatedFields.has('bulkStorageNumber') ? 'label updated' : 'label'">
              Bulk Storage No.
              <ng-template #bulkStorageTemplate>
                <span [innerHTML]="getOldValue('bulkStorageNumber')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('bulkStorageNumber')"
                placement="top"
                [tooltip]="bulkStorageTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput [formControl]="(orderForm?.controls)['bulkStorageNumber']" />
            </mat-form-field>
          </div>

          <div class="form-field left invoice-field">
            <div [ngClass]="updatedFields.has('invoiceNumber') ? 'label updated' : 'label'">
              Invoice No.
              <ng-template #invoiceNumberTemplate>
                <span [innerHTML]="getOldValue('invoiceNumber')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('invoiceNumber')"
                placement="top"
                [tooltip]="invoiceNumberTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input matInput [formControl]="(orderForm?.controls)['invoiceNumber']" type="text" />
              <span
                matPrefix
                [ngClass]="{ 'disable-invoice-prefix': (orderForm?.controls)['invoiceNumber'].disabled }"
                *ngIf="
                  (orderForm?.controls)['invoiceNumber'].value.length > 0 &&
                  !orderForm?.controls['invoiceNumber'].value.startsWith('SA')
                "
                >SA</span
              >
              <mat-error *ngFor="let validation of validation_messages.invoice">
                <mat-error
                  class="error-message"
                  *ngIf="
                    orderForm.get('invoiceNumber').hasError(validation.type) &&
                    (orderForm.get('invoiceNumber').dirty || orderForm.get('invoiceNumber').touched)
                  "
                >
                  {{ validation.message }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-field right" *ngIf="!isLmoAccount">
            <div class="label">
              Billing Identifier
            </div>
            <mat-form-field>
              <input matInput [formControl]="(orderForm?.controls)['billingIdentifier']" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="section-wrap">
        <div class="title-row">
          <h3>Pricing Contract</h3>
          <div
            class="tag"
            *ngIf="
              !(
                updatedFields.has('hourlyDetentionRate') ||
                updatedFields.has('pickupFreeTime') ||
                updatedFields.has('dropOffFreeTime') ||
                updatedFields.has('deadheadFreeMileage') ||
                updatedFields.has('deadheadCostPerMile')
              )
            "
          >
            Automated
          </div>
        </div>

        <div class="field-wrap">
          <div class="form-field left">
            <div [ngClass]="updatedFields.has('hourlyDetentionRate') ? 'label updated' : 'label'">
              Hourly Detention Rate ($/Hour)
              <ng-template #HourlyDetentionTemplate>
                <span [innerHTML]="getOldValue('hourlyDetentionRate')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('hourlyDetentionRate')"
                placement="top"
                [tooltip]="HourlyDetentionTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                saDisableFormat
                autocomplete="off"
                [value]="orderForm?.get('hourlyDetentionRate').value"
                [formControl]="(orderForm?.controls)['hourlyDetentionRate']"
              />
            </mat-form-field>
          </div>
          <div class="form-field right">
            <div [ngClass]="updatedFields.has('pickupFreeTime') ? 'label updated' : 'label'">
              Pickup Free Time (mins)
              <ng-template #pickupFreeTimeTemplate>
                <span [innerHTML]="getOldValue('pickupFreeTime')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('pickupFreeTime')"
                placement="top"
                [tooltip]="pickupFreeTimeTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                sa-number-input
                type="text"
                required
                [formControl]="(orderForm?.controls)['pickupFreeTime']"
              />
            </mat-form-field>
          </div>
          <div class="form-field left">
            <div [ngClass]="updatedFields.has('dropOffFreeTime') ? 'label updated' : 'label'">
              Dropoff Free Time (mins)
              <ng-template #DropoffFreeTimeTemplate>
                <span [innerHTML]="getOldValue('dropOffFreeTime')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('dropOffFreeTime')"
                placement="top"
                [tooltip]="DropoffFreeTimeTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                sa-number-input
                type="text"
                required
                [formControl]="(orderForm?.controls)['dropOffFreeTime']"
              />
            </mat-form-field>
          </div>

          <div class="form-field right">
            <div [ngClass]="updatedFields.has('deadheadFreeMileage') ? 'label updated' : 'label'">
              Deadhead Free Mileage (miles)
              <ng-template #DeadheadFreeMileageTemplate>
                <span [innerHTML]="getOldValue('deadheadFreeMileage')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('deadheadFreeMileage')"
                placement="top"
                [tooltip]="DeadheadFreeMileageTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                sa-number-input
                required
                [formControl]="(orderForm?.controls)['deadheadFreeMileage']"
              />
            </mat-form-field>
          </div>

          <div class="form-field left">
            <div [ngClass]="updatedFields.has('deadheadCostPerMile') ? 'label updated' : 'label'">
              Deadhead Cost Per Mile ($/miles)
              <ng-template #DeadheadCostPerMileTemplate>
                <span [innerHTML]="getOldValue('deadheadCostPerMile')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('deadheadCostPerMile')"
                placement="top"
                [tooltip]="DeadheadCostPerMileTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                saDisableFormat
                autocomplete="off"
                [value]="orderForm?.get('deadheadCostPerMile').value"
                [formControl]="(orderForm?.controls)['deadheadCostPerMile']"
              />
            </mat-form-field>
          </div>
          <div class="form-field right">
            <div class="label">
              Boost Amount (%)
            </div>
            <mat-form-field>
              <input matInput type="text" autocomplete="off" [formControl]="(orderForm?.controls)['boostPercent']" />
            </mat-form-field>
          </div>
          <div class="form-field left">
            <div [ngClass]="updatedFields.has('vendorContract') ? 'label updated' : 'label'">
              Contract
              <ng-template #contractTemplate>
                <span [innerHTML]="getOldValue('vendorContract')"></span>
              </ng-template>
              <div
                class="old_value_wrap"
                *ngIf="updatedFields.has('vendorContract')"
                placement="top"
                [tooltip]="contractTemplate"
                content-type="template"
              >
                ?
              </div>
            </div>
            <mat-form-field>
              <input
                matInput
                [disabled]="contractFieldCheck()"
                (click)="openSwitchContractDialog()"
                type="text"
                autocomplete="off"
                [value]="(orderForm?.controls)['vendorContract']?.value?.name"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="section-wrap">
        <div class="title-row">
          <h3>Other Details</h3>
        </div>

        <div class="field-wrap">
          <div class="form-field full">
            <div class="label">
              Carrier Notes
            </div>
            <mat-form-field>
              <textarea matInput row="10" [formControl]="(orderForm?.controls)['carrierNotes']"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="pricing-row-wrap" *ngIf="orderCharges && orderCharges.length">
        <div class="title-row pricing-title">
          <h3>Pricing</h3>
          <a
            *ngIf="!canApproveChanges && !checkForEditableOrder()"
            (click)="addCharge()"
            saAutomatedSelector="billing_order_detail__add_charge_button"
            >Add Accessorial Charge</a
          >
        </div>
        <div class="pricing-wrap">
          <div class="pricing-row heading">
            <div class="column-details desc-width">Description</div>
            <div class="column-details">Quantity</div>
            <div class="column-details">Unit Cost</div>
            <div class="column-details">Line Total</div>
          </div>
          <div class="pricing-row" *ngFor="let charge of orderCharges; let idx = index">
            <div class="column-details desc-width">
              <span *ngIf="!charge?.chargeType?.customerName && !charge?.chargeType?.customerEDICode; else chargeInfo"
                >--</span
              >
              <ng-template #chargeInfo>
                <span
                  >{{ charge?.chargeType?.customerEDICode }}
                  <ng-container *ngIf="charge?.chargeType?.customerEDICode">-</ng-container>
                  {{ charge?.chargeType?.customerName }}
                </span>
              </ng-template>
              <span class="grey-span">{{ LMOChargeType[charge?.chargeType?.type] }}</span>
              <span class="grey-span" *ngIf="checkForBoost(charge)">(Boosted by {{ boostPercent }}%)</span>
            </div>
            <div class="column-details">
              <span
                *ngIf="charge?.chargeType?.isDefaultForType; else accessorialChargeUnit"
                [class.warning]="charge?.numberOfUnitsPriceChanged"
                >{{ getNumberOfUnit(charge) }}</span
              >
              <ng-template #accessorialChargeUnit>
                <span [class.warning]="charge?.numberOfUnitsPriceChanged"
                  >{{ charge?.numberOfUnits }} {{ charge?.unitOnQuantity }}</span
                >
              </ng-template>
              <ng-container *ngIf="charge.chargeType?.type === 'accessorial'; else notAccessorialQuantity">
                <span class="grey-span warning"><i class="material-icons">warning</i> Manual</span>
              </ng-container>
              <ng-template #notAccessorialQuantity>
                <span class="grey-span" *ngIf="!charge?.numberOfUnitsEdited && !charge?.numberOfUnitsPriceChanged"
                  ><i class="material-icons">info</i> Automated</span
                >
                <span class="grey-span" *ngIf="charge?.numberOfUnitsEdited && !charge?.numberOfUnitsPriceChanged"
                  ><i class="material-icons">info</i> Edited</span
                >
                <span class="grey-span warning" *ngIf="charge?.numberOfUnitsPriceChanged"
                  ><i class="material-icons">info</i> Changed price</span
                >
              </ng-template>
            </div>
            <div class="column-details">
              <span
                *ngIf="charge?.chargeType?.isDefaultForType; else accessorialChargeCost"
                [class.warning]="charge?.pricePerUnitPriceChanged"
                >$ {{ getUnitCost(charge) }} / {{ charge?.unitOnCost }}</span
              >
              <ng-template #accessorialChargeCost>
                <span [class.warning]="charge?.pricePerUnitPriceChanged"
                  >{{ (charge?.pricePerUnit / 100).toFixed(2) }} / {{ charge?.unitOnCost }}</span
                >
              </ng-template>
              <ng-container *ngIf="charge.chargeType?.type === 'accessorial'; else notAccessorialUnitCost">
                <span class="grey-span warning"><i class="material-icons">warning</i> Manual</span>
              </ng-container>
              <ng-template #notAccessorialUnitCost>
                <span class="grey-span" *ngIf="!charge?.pricePerUnitEdited && !charge?.pricePerUnitPriceChanged"
                  ><i class="material-icons">info</i> From Contract</span
                >
                <span class="grey-span" *ngIf="charge?.pricePerUnitEdited && !charge.pricePerUnitPriceChanged"
                  ><i class="material-icons">info</i> Edited</span
                >
                <span class="grey-span warning" *ngIf="charge?.pricePerUnitPriceChanged"
                  ><i class="material-icons">info</i> Pricing Edited</span
                >
              </ng-template>
            </div>
            <div class="column-details line-cost-column">
              <span
                [class.warning]="charge?.numberOfUnitsPriceChanged || charge?.pricePerUnitPriceChanged"
                *ngIf="charge?.chargeType?.isDefaultForType; else accessorialChargeTotalCost"
                >$ {{ getLineTotalCost(charge) }}</span
              >
              <span
                class="grey-span warning"
                matTooltip="SANDi® thinks this is unusual"
                matTooltipClass="lmo-warning-tooltip"
                *ngIf="
                  charge.defaultShaleAppsCharge === 'deadhead' &&
                  selectedOrder.anomalousOrderCosts?.deadheadCostsAnomalous
                "
                ><i class="material-icons">warning</i></span
              >
              <span
                class="grey-span warning"
                matTooltip="SANDi® thinks this is unusual"
                matTooltipClass="lmo-warning-tooltip"
                *ngIf="
                  charge.defaultShaleAppsCharge === 'det_loading' &&
                  selectedOrder.anomalousOrderCosts?.waitAtLoaderCostsAnomalous
                "
                ><i class="material-icons">warning</i></span
              >
              <span
                class="grey-span warning"
                matTooltip="SANDi® thinks this is unusual"
                matTooltipClass="lmo-warning-tooltip"
                *ngIf="
                  charge.defaultShaleAppsCharge === 'det_unloading' &&
                  selectedOrder.anomalousOrderCosts?.waitAtWellCostsAnomalous
                "
                ><i class="material-icons">warning</i></span
              >
              <span
                class="grey-span warning"
                matTooltip="SANDi® thinks this is unusual"
                matTooltipClass="lmo-warning-tooltip"
                *ngIf="
                  charge.defaultShaleAppsCharge === 'linehaul' &&
                  selectedOrder.anomalousOrderCosts?.lineHaulCostsAnomalous
                "
                ><i class="material-icons">warning</i></span
              >
              <ng-template #accessorialChargeTotalCost>
                <span [class.warning]="charge?.numberOfUnitsPriceChanged || charge?.pricePerUnitPriceChanged"
                  >$ {{ ((charge?.numberOfUnits * charge?.pricePerUnit) / 100).toFixed(2) }}</span
                >
                <div class="action-icons" *ngIf="!isLmoAccount">
                  <i class="material-icons" (click)="addCharge(charge)">edit</i>
                  <i class="material-icons warning" (click)="deleteCharge(charge, idx)">delete</i>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="total-price">
            <div>Load Total</div>
            <div>$ {{ getTotalLoadCost() }}</div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="footer-wrap-order">
    <button
      mat-flat-button
      color="primary"
      (click)="saveOrderDetails()"
      *ngIf="!isLmoAccount && checkForSaveAndSendButton()"
      [disabled]="(!showSaveChanges && !chargeChange) || !orderForm.get('invoiceNumber').valid || savingData"
    >
      Save Changes
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="saveAndSendOrderDetails()"
      *ngIf="!isLmoAccount && checkForSaveButton()"
      [disabled]="(!showSaveChanges && !chargeChange) || !orderForm.get('invoiceNumber').valid || savingData"
    >
      Save Changes
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="saveAndSendOrderDetails()"
      *ngIf="!isLmoAccount && checkForSaveAndSendButton() && (showSaveChanges || chargeChange)"
      [disabled]="checkForDetails() || savingData"
      saAutomatedSelector="billing_order_detail__save_send"
    >
      Save and Send to Customer
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="sendOrderDetails()"
      *ngIf="!isLmoAccount && checkForSaveAndSendButton() && !showSaveChanges && !chargeChange"
      [disabled]="checkForDetails()"
    >
      Send to Customer
    </button>

    <button
      mat-flat-button
      color="primary"
      (click)="saveContract()"
      [disabled]="updatingContract"
      *ngIf="canApproveChanges && isContractChanged()"
    >
      Switch Contract
    </button>

    <ng-container *saUserPermission="'can_approve_ticket'">
      <button
        mat-flat-button
        color="primary"
        (click)="approveTicketDetails()"
        *ngIf="canApproveChanges && checkForTicketActionButton()"
        saAutomatedSelector="billing_order_detail__approve_for_invoicing"
      >
        Approve for Invoicing
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="rejectTicketDetails()"
        *ngIf="canApproveChanges && checkForTicketActionButton()"
        saAutomatedSelector="billing_order_detail__push_back"
      >
        Push Back to Vendor
      </button>
    </ng-container>
  </div>
</div>
