import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';
import { filter, share, map, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { NoAuthSocketService } from './no-auth-sockets.service';
import { CrudService } from '~services/crud.service';

const PROD_SNAPSHOT_2020_01_17 = {
  LMOSandMarketplaceInternal: true,
  deleteTruckVendor: true,
  dispatcherTrainingVideos: true,
  drawOrderMapInternal: true,
  driverPermissions: false,
  driverPoolSize: true,
  driverPoolSizeInternal: true,
  dynamicPricing: true,
  fracDetailPolling: true,
  fracListPolling: true,
  lmoMarketSell: false,
  lmoPhaseThree: true,
  lmoPhaseThreeInternal: false,
  lmoSandMarketplace: false,
  lmoSandMarketplaceInternal: true,
  logRunboardAddDriverDetails: false,
  markAsLoaded: true,
  overflowVendors: true,
  perTonCostToggle: true,
  runboardPolling: true,
  runboardRouteDisabled: false,
  showAddLoaderButtonInternal: true,
  showAutopilotUi: true,
  showAutopilotUiDetmar: true,
  showAutopilotUiInternal: true,
  showBillingSettings: true,
  showBulkExport: true,
  showBulkExportInternal: true,
  showDeleteEmailSettingsButton: true,
  showEditRFQ: true,
  showLmoWebInternal: true,
  showMarketplace: true,
  showNotificationPreferences: true,
  showNotificationPreferencesInternal: true,
  showOuterSite: true,
  showOuterSiteInternal: true,
  showRFQMaxCLAW: true,
  showWrongVersion: true,
  trailer: true,
  userAnalytics: true,
};

interface SocketMessage {
  data: {
    flags: Record<string, boolean>;
  };
  messageType: 'upsert';
  modelName: 'featureFlag';
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  // This is only used in dev to override feature flags for development
  private overrides: Record<string, boolean> = {};
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private flagPoll$$ = new Subject<null>();
  private usedFlagsPolling$$ = new Subject<null>();

  get flags$(): Observable<Record<string, boolean>> {
    return this.flags$$.asObservable().pipe(
      filter((flags) => !!flags),
      map((flags) => Object.assign({}, flags, this.overrides)),
      share(),
    );
  }

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private crud: CrudService,
    private noAuthSocketService: NoAuthSocketService,
  ) {
    this.setupWindowFunctions();
    this.noAuthSocketService.getMessagesOfModelType('featureFlag').subscribe((response: SocketMessage) => {
      this.flags$$.next(response.data.flags);
      console.log(response, this.flags$$.value);
    });
    // Initial Load
    this.crud.userAuthenticated.subscribe((called) => {
      if (called) {
        this.loadFlags();
      }
    });
    this.pollFlags();
  }

  private loadFlags() {
    if (!this.crud.authedUser) {
      this.http.get<{ flags: Record<string, boolean> }>(`${environment.api}/feature_flags`).subscribe(
        (response) => {
          this.flags$$.next(response.flags);
        },
        (error) => {
          console.error(error);
          this.flags$$.next(PROD_SNAPSHOT_2020_01_17);
        },
      );
    } else {
      this.crud.get(`${environment.api}/feature_flags/withauth`).subscribe(
        (response) => {
          this.flags$$.next(response.flags);
        },
        (error) => {
          console.error(error);
          this.flags$$.next(PROD_SNAPSHOT_2020_01_17);
        },
      );
    }
  }

  private pollFlags() {
    this.flagPoll$$.pipe(throttleTime(30 * 1000)).subscribe(() => {
      this.loadFlags();
    });
    interval(60 * 1000).subscribe(() => {
      this.flagPoll$$.next(null);
    });
  }
  private setupWindowFunctions() {
    window['listFeatureFlags'] = () => ({
      environment: environment.featureFlagKey,
      flags: this.flags$$.value,
    });
    if (environment.featureFlagKey === 'dev') {
      window['setFeatureFlagOverrides'] = (overrides: Record<string, boolean>) => {
        this.overrides = overrides;
        // Send out same flags so observable pushes out again
        this.flags$$.next(this.flags$$.value);
        if (Object.keys(overrides).length === 0 && overrides.constructor === Object) {
          console.log('Feature flags are now in sync with firebase');
        } else {
          console.log(
            'Feature flags will be out of sync with firebase until you refresh or call setFeatureFlagOverrides({})',
          );
        }
      };
    }
  }

  public isFlagActive(flag: string): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        if (this.userService.isShaleappsEmail()) {
          return processFlag(flags, flag) || processFlag(flags, `${flag}Internal`);
        }
        return processFlag(flags, flag);
      }),
    );
  }

  public isSAOnlyFlag(flag: keyof Record<string, boolean>): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        const regularFlag = processFlag(flags, flag);
        const internalFlag = processFlag(flags, `${flag}Internal`);
        return !regularFlag && internalFlag;
      }),
    );
  }
}

export function processFlag(flags: Record<string, boolean>, flag: string): boolean {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false;
}
