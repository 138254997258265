import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChoiceDialogComponent } from '../ui-components/choice-dialog/choice-dialog.component';
import { EmailSettingsV2Service } from '../services/email-settings-v2.service';
import { CrewDataService } from '~services/crew-data.service';

@Component({
  selector: 'sa-email-settings-v2',
  templateUrl: './email-settings-v2.component.html',
  styleUrls: ['./email-settings-v2.component.scss'],
})
export class EmailSettingsV2Component implements OnInit, OnDestroy {
  displayNav = false;
  displayForm = false;
  userLabel: { name: string; email: string; account: string };
  selectedEmail: any;
  emailDetails = [];
  activeDataSource: MatTableDataSource<any>;
  displayedColumns = ['loaderName', 'email', 'import', 'edit'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isCrewLeader = false;
  subs: Subscription[] = [];

  public crewDataEnabled$: Observable<boolean>;

  constructor(
    public userService: UserService,
    public router: Router,
    private dialog: MatDialog,
    public emailSettingsV2Service: EmailSettingsV2Service,
    private crewData: CrewDataService,
  ) {
    this.crewDataEnabled$ = this.crewData.crewDataEnabled$;
  }

  ngOnInit() {
    this.userLabel = this.userService.getLabel();
    this.isCrewLeader = this.userService.isLMOAccount() && this.userService.isCrewLeader();
    if (this.isCrewLeader) {
      this.router.navigateByUrl('/lmo');
    }
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;
    this.subs.push(
      this.emailSettingsV2Service.emailSettings$.subscribe((settings) => {
        this.activeDataSource.data = settings.data;
      }),
    );

    this.checkNewLoaderIntegration();
  }

  checkNewLoaderIntegration() {
    this.emailSettingsV2Service.isNewLoaderIntegration().subscribe((value) => {
      if (value) {
        this.router.navigate(['/', 'loader-integration-v2']);
      } else {
        this.router.navigate(['/', 'loader-integration']);
      }
    });
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }

  addForm(data?) {
    this.selectedEmail = data;
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }

  deleteEmailSettings(id) {
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Delete this email setting?`,
        desc: `This cannot be undone.`,
        button: ['Cancel', 'Remove Email Setting'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.emailSettingsV2Service.deleteEmailSettings(id);
      }
    });
  }
}
