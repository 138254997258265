<i
  *ngIf="isAdmin"
  class="material-icons floating-plus"
  saAutomatedSelector="user-group__add-button"
  (click)="addUserGroup({})"
  >add</i
>

<mat-table
  #table
  (matSortChange)="sortTable($event)"
  [dataSource]="dataSource"
  matSort
  *ngIf="userGroups && userGroups.length; else placeholder"
>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row" saAutomatedSelector="userGroup__name">{{ row.name || 'No Name' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="users">
    <mat-header-cell class="user-list" *matHeaderCellDef mat-sort-header>Users</mat-header-cell>
    <mat-cell class="user-list" *matCellDef="let row">{{ getUserGroupName(row.users) || 'No Users' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row"
      ><i class="material-icons" saAutomatedSelector="userGroup__edit" (click)="addUserGroup(row)">edit</i></mat-cell
    >
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    saAutomatedSelector="userGroup__row"
    [automatedName]="row.name"
  ></mat-row>
</mat-table>

<ng-template #placeholder>
  <h3 class="placeholder-text">No User Group Found</h3>
</ng-template>
