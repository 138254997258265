import { Component, OnInit, Input } from '@angular/core';
import { FeatureFlagService } from '~services/feature-flag.service';
import { DriverReplayService, Waypoint } from 'src/app/driver-replay.service';
import { Observable } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';
import { map, filter } from 'rxjs/operators';

export interface WaypointStats {
  totalWaypoints: number;
  goodWaypoints: number;
}

@Component({
  selector: 'sa-order-map-controls',
  templateUrl: './order-map-controls.component.html',
  styleUrls: ['./order-map-controls.component.scss'],
})
export class OrderMapControlsComponent implements OnInit {
  @Input() fracId: number;
  @Input() loadNumber: number;

  waypoints$: Observable<Waypoint[]>;
  waypointStats$: Observable<WaypointStats>;
  selectedWaypoint$: Observable<Waypoint>;
  constructor(public featureFlagService: FeatureFlagService, public driverReplay: DriverReplayService) {}

  ngOnInit() {
    this.waypoints$ = this.driverReplay.waypoints$;
    this.selectedWaypoint$ = this.driverReplay.selectedWaypoint$;
    this.waypointStats$ = this.waypoints$.pipe(
      map((waypoints) => {
        if (!waypoints || waypoints.length === 0) {
          return null;
        }
        const stats: WaypointStats = {
          totalWaypoints: 0,
          goodWaypoints: 0,
        };
        waypoints.forEach((waypoint) => {
          stats.totalWaypoints++;
          if (waypoint.accuracy < 100) {
            stats.goodWaypoints++;
          }
        });
        return stats;
      }),
      filter((stats) => !!stats),
    );
  }

  sliderChange(event: any) {
    this.driverReplay.setActiveWaypoint(+event.target.value);
  }

  speedChange(event: MatSliderChange) {
    this.driverReplay.setSpeed(event.value);
  }

  speedFormatter(value: number | null) {
    if (!value) {
      return 0;
    }
    return `${value}x`;
  }
}
