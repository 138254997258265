import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sPrefix',
})
export class SPrefixPipe implements PipeTransform {
  transform(value: any): string {
    const strValue = `${value}`;
    return `S${strValue.padStart(9, '0')}`;
  }
}
