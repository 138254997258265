import { Component, OnInit } from '@angular/core';
import { environment } from '~environments/environment';

@Component({
  selector: 'sa-stage-url-indicator',
  templateUrl: './stage-url-indicator.component.html',
  styleUrls: ['./stage-url-indicator.component.scss'],
})
export class StageUrlIndicatorComponent implements OnInit {
  // This is only for use on stage to allow the rangers to use prod urls on staging so we can test as we go
  public environment = environment;
  constructor() {}

  ngOnInit() {}

  isProd(): boolean {
    return environment.api === 'https://sand.shaleapps.com/api';
  }

  getEnvName(): string {
    switch (environment.api) {
      case 'https://sand.shaleapps.com/api':
        return 'Prod';
      case 'https://stage.sand.shaleapps.com/api':
        return 'Stage';
      case 'https://dev.sand.shaleapps.com/api':
        return 'Dev';
      default:
        return '????';
    }
  }
}
