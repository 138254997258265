import { Injectable } from '@angular/core';
import { CrudService } from '~services/crud.service';
import { environment } from '~environments/environment';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DistributionCenter } from '~lmo/models/distributionCenter.model';
import { Order } from '~models/order.model';

@Injectable({
  providedIn: 'root',
})
export class DistributionCenterApiService {
  get distributionCenterRoute(): string {
    return environment.api + '/distribution_center';
  }

  // only for endpoints from well api service that is reusable here
  get storageRoute(): string {
    return environment.api + '/sand/storage';
  }

  get distributionCenterStorageRoute(): string {
    return environment.api + '/distribution_center/sand/storage';
  }

  constructor(private crud: CrudService) {}

  getDistributionCenters(): Observable<DistributionCenter[]> {
    return this.crud.get(this.distributionCenterRoute);
  }

  getDistributionCenter(id): Observable<DistributionCenter> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(this.distributionCenterRoute + '/' + id)),
    );
  }

  createDistributionCenter(distributionCenter) {
    return this.crud.post(this.distributionCenterRoute, distributionCenter);
  }

  updateDistributionCenter(id, distributionCenter): Observable<DistributionCenter> {
    return this.crud.put(this.distributionCenterRoute + '/' + id, distributionCenter);
  }

  createDistributionCenterStorage(body) {
    return this.crud.post(this.storageRoute, body);
  }

  updateDistributionCenterStorage(id, body) {
    return this.crud.put(this.distributionCenterStorageRoute + '/' + id, body);
  }

  updateDCStorageSupplementalMesh(id, body) {
    return this.crud.put(this.distributionCenterStorageRoute + '/' + id + '/supplemental_mesh', body);
  }

  getDistributionCenterBoxTechnologies(): Observable<{ id: number; name: string }[]> {
    return this.crud.get(this.storageRoute + '/box_technologies').pipe(map((value) => value.technologies));
  }

  activateDistributionCenter(id) {
    return this.crud.post(this.distributionCenterRoute + '/' + id + '/activate');
  }

  archiveDistributionCenter(id) {
    return this.crud.post(this.distributionCenterRoute + '/' + id + '/archive');
  }

  loadSingleOrder(dcId: number, loadNumber: number): Observable<Order> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.distributionCenterRoute}/${dcId}/load/${loadNumber}`)),
    );
  }
}
