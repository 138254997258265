<sa-top-nav></sa-top-nav>

<div class="loader-analytics-component">
  <div *ngIf="purchaseOrderData$ | async; let po">
    <h2 class="py-1">
      {{ po.purchaseOrderName }}
    </h2>
    <h3 class="py-1">
      {{ po.loaderName }}
    </h3>
    <div class="text-sm text-gray-700">{{ po.meshType }} Mesh</div>
  </div>

  <mat-radio-group aria-label="Select an option" [(ngModel)]="filter" (change)="filterTypeChange()">
    <mat-radio-button value="all">All</mat-radio-button>
    <mat-radio-button value="matched">Matched Only</mat-radio-button>
    <mat-radio-button value="unmatched">Unmatched Only</mat-radio-button>
  </mat-radio-group>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="uploadTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload Time</th>
        <td mat-cell *matCellDef="let load">
          {{ load.uploadTime | inTimezone: loaderTimezone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bolNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>BOL Number</th>
        <td mat-cell *matCellDef="let load">
          {{ load.bolNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loadWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loadout Report Weight</th>
        <td mat-cell *matCellDef="let load">
          {{ load.loadWeight | poLoaderPoundTonConversion: false | poLoaderPoundTonUnit }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pickupTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pickup Time</th>
        <td mat-cell *matCellDef="let load">
          {{ load.pickupTime | inTimezone: loaderTimezone | dashIfNothing }}
        </td>
      </ng-container>

      <ng-container matColumnDef="truckNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Truck Number</th>
        <td mat-cell *matCellDef="let load">
          {{ load.truckNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="potentialMatchCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Potential Load Matches</th>
        <td
          mat-cell
          *matCellDef="let load"
          [routerLink]="['/loader-analytics-dashboard-v2', 'load', load.integrationDataId]"
          class="link-cell"
        >
          <a [routerLink]="['/loader-analytics-dashboard-v2', 'load', load.integrationDataId]">
            {{ load.potentialMatchCount }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matched Order ID</th>
        <td mat-cell *matCellDef="let load">
          <a [routerLink]="['/', 'lmo', 'frac', load.orderFracId, 'orders', load.orderId]">{{ load.orderId }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="matchReasons">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Match Reasons</th>
        <td mat-cell *matCellDef="let load">
          <div class="match-reasons">
            <ng-container *ngFor="let reason of load.matchReasons">
              <div *ngIf="toMatchReasonText(reason, load); let reasonText" class="match-reason">
                {{ reasonText }}
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fracBillingIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PLO #</th>
        <td mat-cell *matCellDef="let load">
          {{ load.fracBillingIdentifier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="goodsReceipt">
        <th mat-header-cell *matHeaderCellDef>GR</th>
        <td mat-cell *matCellDef="let load">
          <ng-container *ngIf="load !== goodsReceiptEditingRow; else editName">
            {{ load.goodsReceipt }}
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsReceiptEditingRow !== null"
              (click)="startEditGoodsReceipt(load)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </ng-container>
          <ng-template #editName>
            <mat-form-field appearance="fill">
              <input matInput [(ngModel)]="load.goodsReceipt" [disabled]="goodsReceiptEditingRow !== load" />
            </mat-form-field>
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsReceiptEditingRow !== load"
              (click)="updateGoodsReceipt(load)"
            >
              <mat-icon>done</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsReceiptEditingRow !== load"
              (click)="cancelEditGoodsReceipt()"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="goodsIssued">
        <th mat-header-cell *matHeaderCellDef>GI</th>
        <td mat-cell *matCellDef="let load">
          <ng-container *ngIf="load !== goodsIssuedEditingRow; else editName">
            {{ load.goodsIssued }}
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsIssuedEditingRow !== null"
              (click)="startEditGoodsIssued(load)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </ng-container>
          <ng-template #editName>
            <mat-form-field appearance="fill">
              <input matInput [(ngModel)]="load.goodsIssued" [disabled]="goodsIssuedEditingRow !== load" />
            </mat-form-field>
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsIssuedEditingRow !== load"
              (click)="updateGoodsIssued(load)"
            >
              <mat-icon>done</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              [disabled]="goodsIssuedEditingRow !== load"
              (click)="cancelEditGoodsIssued()"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let load; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-spinner color="primary" [diameter]="25" *ngIf="!(purchaseOrderData$ | async)"></mat-spinner>
</div>
