import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, tap, throttleTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandlingService } from '~services/error-handling.service';
import { UserApiService } from '~services/api/user.api.service';
import { User } from '~models/user';

export interface Crew {
  id: number;
  name: string;
  homeRegionId?: number;
  homeRegionName?: string;
  leaders: {
    id: number;
    name: string;
  }[];
}

export interface GroupedCrew {
  accountId: number;
  accountName: string;
  crews: Crew[];
}

export interface CrewResponse {
  crews: Crew[];
  groupedCrews: GroupedCrew[];
}

@Injectable({
  providedIn: 'root',
})
export class CrewsService {
  public possibleLeaders$: Observable<User[]>;
  private crews$$ = new BehaviorSubject<GroupedCrew[]>([]);
  private crewShared$ = this.crews$$.pipe(shareReplay(1));
  private crewThrottle$ = new Subject();

  public get crews$(): Observable<GroupedCrew[]> {
    this.crewThrottle$.next();
    return this.crewShared$;
  }

  constructor(
    private httpClient: HttpClient,
    private errorHandler: ErrorHandlingService,
    private userApiService: UserApiService,
  ) {
    this.crewThrottle$.pipe(throttleTime(1000)).subscribe(() => {
      this.loadAndSetCrews();
    });
    this.possibleLeaders$ = this.userApiService.getWebUsers();
  }

  public createCrew$(name: string, userIds: number[] = []): Observable<Crew> {
    return this.httpClient
      .post<{ crew: Crew }>(`${environment.api}/crew`, { name, userIds })
      .pipe(
        map((resp) => resp.crew),
        tap(this.updateList.bind(this)),
        catchError((err) => {
          this.errorHandler.showError(err, 2000);
          return of(null as Crew);
        }),
      );
  }

  public updateCrew$(id: number, name: string): Observable<Crew> {
    return this.httpClient
      .patch<{ crew: Crew }>(`${environment.api}/crew/${id}`, { name })
      .pipe(
        map((resp) => resp.crew),
        tap(this.updateList.bind(this)),
        catchError((err) => {
          this.errorHandler.showError(err, 2000);
          return of(null as Crew);
        }),
      );
  }

  public addLeaders(id: number, userIds: number[]) {
    return this.httpClient
      .post<{ crew: Crew }>(`${environment.api}/crew/${id}/add_leader`, { userIds })
      .pipe(
        map((resp) => resp.crew),
        tap(this.updateList.bind(this)),
        catchError((err) => {
          this.errorHandler.showError(err, 2000);
          return of(null as Crew);
        }),
      );
  }

  public removeLeaders(id: number, userIds: number[]) {
    return this.httpClient
      .post<{ crew: Crew }>(`${environment.api}/crew/${id}/remove_leader`, { userIds })
      .pipe(
        map((resp) => resp.crew),
        tap(this.updateList.bind(this)),
        catchError((err) => {
          this.errorHandler.showError(err, 2000);
          return of(null as Crew);
        }),
      );
  }

  private updateList() {
    this.loadAndSetCrews();
  }

  private loadAndSetCrews() {
    this.loadCrews().subscribe(
      (crewsObj) => {
        this.crews$$.next(crewsObj.groupedCrews);
      },
      (err) => {
        this.errorHandler.showError(err, 2000);
      },
    );
  }

  private loadCrews(): Observable<{ groupedCrews: GroupedCrew[] }> {
    return this.httpClient.get<{ groupedCrews: GroupedCrew[] }>(`${environment.api}/crew`);
  }
}
