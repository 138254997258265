<sa-top-nav></sa-top-nav>

<div class="bulk-load-upload-wrap">
  <div class="flex flex-row">
    <div class="flex-auto">
      <div class="flex-initial">
        <h2>Bulk Load Upload</h2>
      </div>
      <div class="flex-initial">
        <h3>You can bulk upload the load based on the template provided.</h3>
      </div>
    </div>
    <div class="flex-initial justify-items-end content-center">
      <csvbox-button [licenseKey]="licenseKey" [user]="user" [onImport]="onData.bind(this)" class="mx-2">
        <button mat-flat-button color="primary"><mat-icon>upload</mat-icon> Import</button>
      </csvbox-button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource?.bulkLoadUploadLogs" class="my-3">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef>Filename</th>
      <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.created_at | date }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell class="cursor-pointer" *matCellDef="let element" (click)="showRemarks(element)">
        <a>{{ element.status }}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
