import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { ResponseTrailerTypeContract, TrailerTypeContract } from '~lmo/models/trailerTypeContract.model';
import { ErrorHandlingService } from '~services/error-handling.service';
import { TrailerType } from '~v2Models/well.model';

@Injectable({
  providedIn: 'root',
})
export class LmoTrailerTypeContractService {
  private trailerTypeContracts$$: BehaviorSubject<Record<number, ResponseTrailerTypeContract>> = new BehaviorSubject(
    {},
  );
  private selectedVendorId$ = new BehaviorSubject<number>(null);

  private trailerTypes$$: BehaviorSubject<TrailerType[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient, private errorService: ErrorHandlingService, private snackBar: MatSnackBar) {}

  public get trailerTypeContracts$(): Observable<ResponseTrailerTypeContract[]> {
    return this.trailerTypeContracts$$
      .asObservable()
      .pipe(map((record) => Object.keys(record).map((key) => record[key]) as ResponseTrailerTypeContract[]));
  }
  public loadTrailerTypeContractsFor(fctvId: number, vendorId: number): void {
    this.selectedVendorId$.next(vendorId);
    this.http
      .get<ResponseTrailerTypeContract[]>(
        `${environment.api}/frac_cluster_trucking_vendor/${fctvId}/vendor/${vendorId}/trailer_type/contracts`,
      )
      .subscribe((result) => {
        if (result != null) {
          this.trailerTypeContracts$$.next(result);
        } else {
          this.trailerTypeContracts$$.next({});
        }
      });
  }
  public createTrailerTypeContract$(request: TrailerTypeContract): Observable<TrailerTypeContract> {
    return this.http.post<TrailerTypeContract>(`${environment.api}/trailer_type/contract`, request).pipe(
      tap((trailerTypeContract) => {
        this.snackBar.open(`Successfully created trailer type contract`, null, { duration: 5000 });
        this.loadTrailerTypeContractsFor(request.fracClusterTruckingVendorId, this.selectedVendorId$.value);
      }),
      catchError((error) => {
        this.errorService.showError(error);
        return of(null);
      }),
    );
  }

  public updateTrailerTypeContract$(request: ResponseTrailerTypeContract): Observable<any> {
    return this.http
      .patch<ResponseTrailerTypeContract>(`${environment.api}/trailer_type/contract/${request.id}`, request)
      .pipe(
        tap(() => {
          this.snackBar.open('Trailer Type Contract successfully updated', null, { duration: 2000 });
          this.loadTrailerTypeContractsFor(request.fracClusterTruckingVendorId, this.selectedVendorId$.value);
        }),
        catchError((error) => {
          this.errorService.showError(error);
          return of(false);
        }),
      );
  }

  public removeTrailerTypeContract$(data: ResponseTrailerTypeContract): Observable<any> {
    return this.http.delete<null>(`${environment.api}/trailer_type/contract/${data.id}`).pipe(
      tap(() => {
        this.snackBar.open('Trailer Type Contract successfully removed', null, { duration: 2000 });
        this.loadTrailerTypeContractsFor(data.fracClusterTruckingVendorId, this.selectedVendorId$.value);
      }),
      catchError((error) => {
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public get trailerTypes$(): Observable<TrailerType[]> {
    this.loadTrailerTypes();
    return this.trailerTypes$$.asObservable();
  }

  private loadTrailerTypes() {
    this.http.get<TrailerType[]>(`${environment.api}/lohi/trailer_types`).subscribe((trailerTypes) => {
      this.trailerTypes$$.next(trailerTypes);
    });
  }
}
