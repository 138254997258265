import { Injectable } from '@angular/core';
import { Session } from '../models/session';
import { Waypoint } from '../models/waypoint';
import * as moment from 'moment';

@Injectable()
export class SessionService {
  // Return array of unix timestamps (at 12:00 AM) for days where a session was recorded
  findDaysWithSessions(sessions: Session[]): number[] {
    const dates = [];
    let startDay, endDay;

    sessions.forEach((session) => {
      startDay = moment(session.startTimestamp)
        .startOf('day')
        .valueOf();
      if (session.endTimestamp) {
        endDay = moment(session.endTimestamp)
          .startOf('day')
          .valueOf();
      } else {
        endDay = moment()
          .startOf('day')
          .valueOf();
      }

      let i = 1;
      while (
        moment(session.startTimestamp)
          .add(i, 'day')
          .startOf('day')
          .valueOf() < (session.endTimestamp ? moment(session.endTimestamp).valueOf() : moment().valueOf())
      ) {
        const day = moment(session.startTimestamp)
          .add(i, 'day')
          .startOf('day')
          .valueOf();
        if (dates.indexOf(day) < 0) {
          dates.push(day);
        }

        i++;
      }

      if (dates.indexOf(startDay) < 0) {
        dates.push(startDay);
      }

      if (dates.indexOf(endDay) < 0) {
        dates.push(endDay);
      }
    });

    return dates;
  }

  findAllSessionsForDay(date: string, sessions: Session[]): Session[] {
    return sessions.filter((session: Session) => {
      if (
        moment(date).valueOf() >=
          moment(session.startTimestamp)
            .startOf('day')
            .valueOf() &&
        moment(date).valueOf() <=
          (session.endTimestamp
            ? moment(session.endTimestamp)
                .endOf('day')
                .valueOf()
            : moment()
                .endOf('day')
                .valueOf())
      ) {
        return session;
      }
    });
  }

  findSessionForWaypoint(waypoint: Waypoint, sessions: Session[]): Session {
    return sessions.find((session) => {
      if (session.id === waypoint.ownerId) {
      }
      return session.id === waypoint.ownerId;
    });
  }
}
