import { Component, OnInit } from '@angular/core';
import { UserService } from '~services/user.service';
import { environment } from '~environments/environment';
import {
  BulkLoadUploadPayload,
  BulkLoadUploadRowData,
  ImportLog,
  ImportLogsResponse,
  UserPayload,
} from './bulk-load-upload.model';
import { BulkLoadUploadService } from '~services/bulk-load-upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RemarksComponent } from './remarks/remarks.component';

@Component({
  selector: 'sa-bulk-load-upload',
  templateUrl: './bulk-load-upload.component.html',
  styleUrls: ['./bulk-load-upload.component.scss'],
})
export class BulkLoadUploadComponent implements OnInit {
  licenseKey: string = environment.cvsBox.licenceKey;
  displayedColumns: string[] = ['id', 'filename', 'date', 'status'];
  dataSource: ImportLogsResponse;
  user: UserPayload;
  customField: UserPayload;

  constructor(
    private bulkLoadUploadService: BulkLoadUploadService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.bulkLoadUploadService.getLogs$.subscribe((logs) => {
      this.dataSource = logs;
    });
    this.user = {
      user_id: this.userService.userId(),
      account_id: this.userService.accountId(),
    };
  }

  onData(result: boolean, data: any) {
    if (result) {
      const payload: BulkLoadUploadPayload[] = [];
      const customField: UserPayload = {
        user_id: Number(data.custom_fields.user_id),
        account_id: Number(data.custom_fields.account_id),
      };

      data.rows.forEach(function(row, index) {
        let sheetRow: BulkLoadUploadRowData;
        sheetRow = {
          status: row.status,
          truck_number: row.truck_number,
          trailer: row.trailer,
          driver_phone_number: row.driver_phone_number,
          date: row.date,
          load_weight: row.load_weight,
          load: row.load,
          sand_type: row.sand_type,
          loaded_time: row.loaded_time,
          eta_txtime: row.eta_txtime,
          time_arrival: row.time_arrival,
          stage: row.stage,
          start_date: row.start_date,
          end_date: row.end_date,
          bol_ticket: row.bol_ticket,
          box_ids: row.box_ids,
          facility: row.facility,
          frac_name: row.frac_name,
        };

        let rowData: BulkLoadUploadPayload;
        rowData = {
          import_id: 0,
          sheet_id: Number(data.sheet_id),
          sheet_name: data.sheet_name,
          row_number: index + 1,
          total_rows: data.row_count,
          env_name: data.env_name,
          original_filename: data.original_filename,
          row_data: sheetRow,
          custom_fields: customField,
        };
        payload.push(rowData);
      });
      console.log(payload);
      this.bulkLoadUploadService.bulkLoadUpload(payload).subscribe((response) => {
        this.snackBar.open(response, null, {
          duration: 5000,
        });
      });
    } else {
      this.snackBar.open('There was some problem uploading the sheet', null, {
        duration: 5000,
      });
    }
  }

  showRemarks(log: ImportLog) {
    this.matDialog.open(RemarksComponent, this.getRemarkConfig(log));
  }

  getRemarkConfig(log: ImportLog) {
    let data: string[];
    if (log.remarks) {
      data = log.remarks.split('\n');
    } else {
      data = ['Processing'];
    }

    return {
      data: data,
      maxHeight: '80vh',
      maxWidth: '968px',
      width: '80%',
    };
  }
}
