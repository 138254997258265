import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minZero',
})
export class MinZeroPipe implements PipeTransform {
  transform(value: number): number {
    return Math.max(0, value);
  }
}
