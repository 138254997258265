import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sa-sandi-page',
  templateUrl: './sandi-page.component.html',
  styleUrls: ['./sandi-page.component.scss'],
})
export class SandiPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigateByUrl('/');
  }
}
