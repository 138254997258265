import { Pipe, PipeTransform } from '@angular/core';
import { joinWithAnd } from '~utilities/joinWithAnd';

@Pipe({
  name: 'joinWithAnd',
})
export class JoinWithAndPipe implements PipeTransform {
  public transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (!value.length) {
      return null;
    }

    return joinWithAnd(value);
  }
}
