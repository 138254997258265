<ng-container *ngIf="!addToCluster">
  <h1 mat-dialog-title>Create New Cluster</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        [(ngModel)]="clusterName"
        placeholder="Cluster Name"
        saAutomatedSelector="cluster-choice__cluster-name"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="makeCluster()" cdkFocusInitial saAutomatedSelector="cluster-choice__create-button">
      Create cluster
    </button>
  </div>
</ng-container>

<ng-container *ngIf="addToCluster">
  <h1 mat-dialog-title>Pick a cluster to add to</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select [(ngModel)]="selectedCluster" saAutomatedSelector="cluster-choice__cluster-dropdown">
        <mat-option *ngFor="let cluster of uniqueClusters; let i = index" [value]="cluster">
          {{ cluster }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button
      mat-button
      (click)="addToExistingCluster()"
      cdkFocusInitial
      saAutomatedSelector="cluster-choice__add-to-cluster-button"
    >
      Add to cluster
    </button>
  </div>
</ng-container>
