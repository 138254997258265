import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VersioningService } from '../services/versioning.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '~environments/environment';
import { AuthService } from '~services/auth.service';
import { BehaviorSubject } from 'rxjs';

const errorMessages = {
  'auth/user-not-found': 'We were unable to find your account, please check the spelling of your email and try again.',
  'auth/too-many-requests': 'Too many requests, please wait a few minutes and try again',
};
@Component({
  selector: 'sa-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  matcher: ErrorStateMatcher;
  resetForm: FormGroup;
  status: 'awaitingUserInput' | 'submitting' | 'success' | 'error' = 'error';
  public serverResponse$$ = new BehaviorSubject<string | null>(null);
  errorMessage: string = null;

  constructor(
    private fb: FormBuilder,
    public versionService: VersioningService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.resetForm = this.fb.group({ email: [email ? email : '', [Validators.email, Validators.required]] });
  }

  public async sendResetEmail() {
    this.serverResponse$$.next(null);
    if (this.resetForm.valid) {
      this.status = 'submitting';
      try {
        const response = await this.authService.resetPassword(this.resetForm.get('email').value);
        this.serverResponse$$.next(response);
        this.status = 'success';
      } catch (err) {
        this.status = 'error';
      }
    }
  }
}

function getContinueUrl() {
  if (window.location.hostname === 'localhost') {
    return `http://localhost:${window.location.port}/sandi`;
  }
  switch (environment.featureFlagKey) {
    case 'dev':
      return 'https://dev.sand.shaleapps.com/sandi';
    case 'stage':
      return 'https://stage.sand.shaleapps.com/sandi';
    default:
      return 'https://sand.shaleapps.com/sandi';
  }
}
