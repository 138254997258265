import { Component, OnInit } from '@angular/core';
import { RunboardService } from '../services/runboard.service';
import { take, switchMap, filter } from 'rxjs/operators';
import { UserApiService } from '../services/api/user.api.service';
import { RouterStateService } from '../services/router-state.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from '../services/error-handling.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MapUtilService } from '../services/map-util.service';
import { OrderApiService } from '../services/api/order.api.service';
import { StoreService } from '../services/store.service';
import { DatePipe, Location } from '@angular/common';
import { ChoiceDialogComponent } from '../ui-components/choice-dialog/choice-dialog.component';
import { Order } from '~models/order.model';
import { AccountFeatureFlagsService } from '~services/account-feature-flags.service';

@Component({
  selector: 'sa-runboard-driver-options',
  templateUrl: './runboard-driver-options.component.html',
  styleUrls: ['./runboard-driver-options.component.scss'],
})
export class RunboardDriverOptionsComponent implements OnInit {
  endingShiftInProgress = false;

  showSection = 1;

  currentHOSCalculation: {
    input: string;
    result: number;
  };

  constructor(
    public runboardService: RunboardService,
    private userApiService: UserApiService,
    private routerStateService: RouterStateService,
    private snackBar: MatSnackBar,
    private errorService: ErrorHandlingService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private mapUtilService: MapUtilService,
    private orderApiService: OrderApiService,
    private store: StoreService,
    private errorHandler: ErrorHandlingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private location: Location,
    public accountFeatureFlagService: AccountFeatureFlagsService,
  ) {}

  ngOnInit() {}

  getHoursLeftOnShift(shiftEndTimestamp: string): number {
    if (!this.currentHOSCalculation || this.currentHOSCalculation.input !== shiftEndTimestamp) {
      if (shiftEndTimestamp) {
        const now = new Date().getTime();
        const created = new Date(shiftEndTimestamp).getTime();
        const result = Math.max(Math.round((created - now) / 1000 / 60 / 60), 0);
        this.currentHOSCalculation = {
          input: shiftEndTimestamp,
          result,
        };
      } else {
        this.currentHOSCalculation = {
          input: shiftEndTimestamp,
          result: 0,
        };
      }
    }
    return this.currentHOSCalculation.result;
  }

  endDriverShift() {
    this.endingShiftInProgress = true;
    this.routerStateService.routerState$
      .pipe(
        take(1),
        switchMap((routerState) => {
          const { fracClusterId } = routerState.params;
          const { userId } = routerState.params;
          return this.userApiService.endShift(fracClusterId, userId);
        }),
      )
      .subscribe(
        () => {
          this.snackBar.open(`Removed from Driver Pool`, null, { duration: 5000 });
          // Probably not needed since we are closing
          this.endingShiftInProgress = false;
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        (error) => {
          this.endingShiftInProgress = false;
          this.errorService.showError(error);
        },
      );
  }

  updateHOS(matSelectResult: MatSelectChange) {
    this.routerStateService.routerState$
      .pipe(
        take(1),
        switchMap((routerState) => {
          const { fracClusterId } = routerState.params;
          const { userId } = routerState.params;
          return this.userApiService.updateHOS(fracClusterId, +userId, matSelectResult.value * 60);
        }),
      )
      .subscribe(
        () => {
          // Needed until we get sockets working for runboard service
          this.runboardService.loadRunboardSummary();
          this.snackBar.open(`Hours Updated`, null, { duration: 5000 });
        },
        (error) => {
          this.errorService.showError(error);
        },
      );
  }

  calculateReceivingTime(timestamp) {
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    const secDiff = Math.round((now - created) / 1000);
    if (secDiff < 0) {
      return '--';
    }
    const minDiff = Math.round(secDiff / 60);
    const hoursDiff = Math.round(minDiff / 60);
    const daysDiff = Math.round(hoursDiff / 24);
    if (daysDiff >= 1) {
      if (daysDiff > 1) {
        return `${daysDiff} days`;
      } else {
        return `${daysDiff} day`;
      }
    }
    if (hoursDiff >= 1) {
      if (hoursDiff > 1) {
        return `${hoursDiff} hours`;
      } else {
        return `${hoursDiff} hour`;
      }
    }
    if (minDiff >= 0) {
      if (minDiff > 1) {
        return `${minDiff} minutes`;
      } else {
        return `${minDiff} minute`;
      }
    }
    if (secDiff > 1) {
      return `${secDiff} seconds`;
    } else {
      return `${secDiff} second`;
    }
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`sms:${url}`);
  }

  editLoad() {
    this.showSection = 2;
  }

  swapDrivers() {
    this.showSection = 3;
    // this.mapUtilService.updateMap({ frac: selectedFrac, state: 10, order: selectedOrder });
  }

  unassignOrder(selectedOrder) {
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Unassign Driver',
        desc: `This will remove ${
          selectedOrder.user.name
        } from this order. This will move the order back to the Pending list where you can decline, edit, or reassign the order.`,
        button: ['Cancel', 'Unassign Driver'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.unAssignOrderById(selectedOrder.id).subscribe(
          (res) => {
            this.runboardService.loadRunboardSummary();
            this.snackBar.open('Successfully Unassigned', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
            this.goBack();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  completeOrder(selectedOrder) {
    let mainText = '';
    let secondaryText = '';
    if (selectedOrder.etaStatus !== 'arrived') {
      mainText = 'Wait for Driver to Arrive at the Well Geofence?';
      secondaryText = `
        This driver's current ETA to the well is
        ${this.calculateEta(selectedOrder.eta)}.
          If you complete this order, you will lose their in & out time at the well.
          Please wait for the driver to depart the well and SANDi will automatically complete the load for you.`;
    } else {
      mainText = 'Wait for Driver to Leave the Well Geofence?';
      secondaryText = `
      This driver's is currently on location.
        If you complete this order, you will lose their in & out time.
        Please wait for the driver to depart the well and SANDi will automatically complete the load for you once they are 1 mile away.`;
    }
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '50%',
      maxWidth: '968px',
      data: {
        context: mainText,
        desc: secondaryText,
        button: ['Wait for Driver', 'Complete Anyway'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.completeOrderById(selectedOrder.id).subscribe(
          (res) => {
            // Needed until we get sockets working for runboard service
            this.runboardService.loadRunboardSummary();
            this.snackBar.open('Successfully Completed', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
            this.goBack();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  acceptForDriver(selectedOrder) {
    const choiceDialog = this.dialog.open(ChoiceDialogComponent, {
      width: '25%',
      maxWidth: '968px',
      data: {
        context: 'Accept Dispatch for Driver',
        desc: 'Are you sure?',
        button: ['Cancel', 'Accept'],
      },
    });
    choiceDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.orderApiService.acceptForDriver(selectedOrder).subscribe(
          (res) => {
            this.store.setOrder(res);
            // Needed until we get sockets working for runboard service
            this.runboardService.loadRunboardSummary();
            this.snackBar.open('Successfully Accepted', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    });
  }

  public markAsLoaded(selectedOrder: Order) {
    const payload = { loaded: true };
    this.store.patchOrder(selectedOrder.id, payload).subscribe();
  }

  goBack() {
    this.location.back();
  }

  onBack() {
    this.showSection = 1;
  }

  setDriver(driver, driverDetails) {
    const body = {
      userId: driver.id,
    };
    this.orderApiService.swapDrivers(driverDetails.selectedOrder.id, body).subscribe(
      (res) => {
        this.snackBar.open('Driver Swapped Successfully', null, {
          duration: 5000,
          panelClass: ['snackbar-success'],
        });
        let totalFracs: any;
        this.store
          .select<Array<any>>('fracs')
          .pipe(
            filter((_) => _.length > 0),
            take(1),
          )
          .subscribe((fracs) => {
            totalFracs = fracs;
          }),
          totalFracs.forEach((frac) => {
            if (frac.id === driverDetails.selectedOrder.fracId) {
              for (let i = 0; i < frac.orders.length; i++) {
                if (frac.orders[i].id === driverDetails.selectedOrder.id) {
                  frac.orders[i] = res;
                }
              }
            }
          });
        this.store.set('fracs', totalFracs);
        this.router.navigate(['../' + driver.id], { relativeTo: this.route, replaceUrl: true });
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  calculateEta(minutes) {
    if (!minutes) {
      minutes = 0;
    }
    return this.datePipe.transform(new Date(new Date().getTime() + minutes * 60000), 'shortTime');
  }
}
