import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from '~services/user.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'sa-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobsComponent implements OnInit {
  selectedFrac: any;
  selectedOrder: any;
  public isInternal: boolean;
  public unassignedUsersEnabled: boolean;
  @Output() onMenuClick: EventEmitter<void> = new EventEmitter();

  constructor(private userService: UserService) {
    this.isInternal = this.userService.isShaleappsEmail();
    this.unassignedUsersEnabled = !!localStorage.getItem('no_unassigned_users');
  }

  ngOnInit() {}

  menuClick() {
    this.onMenuClick.emit();
  }

  public toggleUnassignedUsers(on: MatSlideToggleChange) {
    if (on.checked) {
      this.unassignedUsersEnabled = true;
      localStorage.setItem('no_unassigned_users', 'true');
    } else {
      this.unassignedUsersEnabled = false;
      localStorage.removeItem('no_unassigned_users');
    }
  }
}
