import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { UserContract } from '../models/user';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ErrorHandlingService } from './error-handling.service';
import { B_ACCOUNTS } from '../constants/b-accounts';
import { filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { AccountFeatureFlagsService } from '~services/account-feature-flags.service';
import { Title } from '@angular/platform-browser';

interface ClaimsWithFirebaseStuff {
  accountSession: number;
  accountName: string;
  analytics?: { segment: number; isBuyer: boolean };
  name: string;
  ssoEnabled: boolean;
  auth: {
    roles: number[];
    account: {
      id: number;
      name: string;
      type: number;
    };
  }[];
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: boolean;
  firebase: {
    identities: {
      email: string[];
    };
    sign_in_provider: string;
  };
}

@Injectable()
export class AuthService {
  private isGroupedAccountUser$$ = new BehaviorSubject<boolean>(false);
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isFirstTimeUser$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(null);

  public get isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn$$.asObservable();
  }

  public authStateUnsubscribeSubject = new Subject();
  private _user: firebase.User;
  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private socketListener: EventEmitter<string> = new EventEmitter();

  public get isGroupedAccountUser$(): Observable<boolean> {
    return this.isGroupedAccountUser$$.asObservable();
  }

  public get isFirstTimeUser$(): Observable<Boolean> {
    if (this.isFirstTimeUser$$.value == null) {
      this.loadUserContract();
    }
    return this.isFirstTimeUser$$.asObservable().pipe(
      filter((value) => value != null),
      share(),
    );
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private httpService: HttpClient,
    public afAuth: AngularFireAuth,
    private errorHandler: ErrorHandlingService,
    private accountFeatureFlags: AccountFeatureFlagsService,
    private titleService: Title,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    this.afAuth.authState.subscribe((user) => {
      const userContract = userService.getUserContractFromCache();
      this._user = user;

      if (user && userContract) {
        this.isLoggedIn$$.next(true);
        this.setTitle();
      }
    });

    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.isGroupedAccountUser$$.next(false);
      } else {
        this.loadIsGroupedAccountUser();
      }
    });
  }

  get authenticated(): boolean {
    return this._user !== null && this._user !== undefined;
  }

  get user(): firebase.User {
    return this._user;
  }

  set user(user: firebase.User) {
    this._user = user;
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  public async login(email: string, password: string) {
    this.isLoading$$.next(true);
    try {
      const tokenResponse = await this.http
        .post<{ customToken: string }>(environment.api + '/web_login', {
          email,
          password,
        })
        .toPromise();
      this.loginWithCustomToken(tokenResponse.customToken);
    } catch (err) {
      this.snackBar.open(
        'Invalid email or password. If your account is configured for SSO, please login via your provider',
        null,
        {
          panelClass: ['z-100', 'snackbar-error'],
          duration: 7500,
        },
      );
    } finally {
      this.isLoading$$.next(false);
    }
  }

  public async resetPassword(email: string): Promise<string> {
    try {
      const resetResponse = await this.http
        .post<{ message: string }>(environment.api + '/user/password_reset', {
          email,
        })
        .toPromise();
      return resetResponse.message;
    } catch (err) {
      this.snackBar.open('Failed to reset password, please contact support', null, {
        panelClass: ['z-100', 'snackbar-error'],
        duration: 5000,
      });
    }
  }

  public ssoURL(email: string): Observable<string> {
    return (this.http.post(environment.api + '/sso-route', { email: email }) as Observable<string>).pipe(
      tap(console.log),
    );
  }

  loginWithCustomToken(token: string): Promise<void> {
    return this.afAuth.signInWithCustomToken(token).then((result) => {
      this.userService.storeUserInCache({
        name: result.user.displayName,
        email: result.user.email,
      });

      return result.user.getIdToken().then((jwtToken) => {
        return this.getUserContract(jwtToken).then((contract: UserContract) => {
          if (!contract) {
            this.ssoSigninFail();
            throw new Error('this account does not exist in this environment.');
          }
          if (
            contract.account &&
            B_ACCOUNTS.includes(contract.account.id) &&
            !result.user.email.endsWith('shaleapps.com')
          ) {
            this.ssoSigninFail();
            throw new Error('Invalid Token');
          }
          if (!contract.account.role.id || contract.account.role.id === 0) {
            this.logout();
            throw new Error('Invalid Token');
          } else {
            this.userService.storeUserContractInCache(contract);
            this.socketListener.emit('login');
            this.isLoggedIn$$.next(true);
            this.redirectIfLoggedIn();
          }
          this.setTitle();
        });
      });
    });
  }

  logout(): void {
    this.authStateUnsubscribeSubject.next();
    this.afAuth.signOut();
    this.userService.removeUserFromCache();
    this.userService.removeUserContractFromCache();
    this.isLoggedIn$$.next(false);
    this.isFirstTimeUser$$.next(false);
    this.accountFeatureFlags.clearFlags();
    this.socketListener.emit('logout');
    this.router.navigateByUrl('/login');
    this.setTitle();
  }

  private ssoSigninFail(): void {
    this.authStateUnsubscribeSubject.next();
    this.afAuth.signOut();
    this.userService.removeUserFromCache();
    this.userService.removeUserContractFromCache();
    this.isLoggedIn$$.next(false);
    this.socketListener.emit('logout');
  }

  private setTitle() {
    const { account } = this.userService.getUserContractFromCache();
    if (account && account.name) {
      this.titleService.setTitle(`${account.name} - SANDi`);
    } else {
      this.titleService.setTitle('SANDi');
    }
  }

  forgotPassword(email: string) {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        const confirmation = 'Password reset request has been sent to ' + email;
        this.snackBar.open(confirmation, null, {
          duration: 5000,
        });
      })
      .catch((error) => {
        this.errorHandler.showError(error);
      });
  }

  redirectIfLoggedIn() {
    if (this.userService.isSubContractor()) {
      const loadId = this.route.snapshot.queryParamMap.get('loadId');
      if (loadId) {
        this.router.navigate(['/invoicing/order', loadId]);
      } else {
        this.router.navigate(['/invoicing']);
      }
    } else if (this.userService.isLMOAccount()) {
      this.router.navigate(['/', 'lmo']);
    } else if (this.userService.isDispatcherAccount()) {
      this.router.navigate(['/', 'map']);
    } else if (this.userService.isSandmanAccount()) {
      this.router.navigate(['/', 'sand-vendor']);
    } else if (this.userService.isShaleAccount()) {
      this.router.navigate(['/', 'admin', 'home']);
    }
  }

  getUserContract(jwtToken): Promise<UserContract> {
    return this.httpService
      .get(environment.api + '/user/contract', {
        headers: {
          Authorization: jwtToken,
        },
      })
      .toPromise() as Promise<UserContract>;
  }

  loadUserContract() {
    (this.httpService.get(environment.api + '/user/contract') as Observable<UserContract>).subscribe((contract) => {
      this.isFirstTimeUser$$.next(contract.isFirstTimeLogin);
    });
  }

  public getSocketEventListener() {
    return this.socketListener;
  }

  public hasScope(allowedScopes: string | string[]): Observable<boolean> {
    return this.afAuth.user.pipe(
      switchMap((user) => user.getIdTokenResult()),
      map((token: firebase.auth.IdTokenResult) => hasScope(allowedScopes, token.claims.scopes)),
    );
  }

  private async getAccountTypePrettyName(): Promise<'lmo' | 'trucking-vendor' | null> {
    if (this.userService.isLMOAccount()) {
      return 'lmo';
    } else if (this.userService.isDispatcherAccount()) {
      return 'trucking-vendor';
    }
  }

  private async loadIsGroupedAccountUser() {
    this.isGroupedAccountUser$$.next(false);
    try {
      const response = await this.http
        .get<{ isGroupedAccountUser: boolean }>(`${environment.api}/auto_procurement/is_grouped_account_user`)
        .toPromise();
      this.isGroupedAccountUser$$.next(response.isGroupedAccountUser);
    } catch (error) {
      console.error(error);
      this.isGroupedAccountUser$$.next(false);
    }
  }
}

function hasScope(allowedScopes: string | string[], userScopes: string[]): boolean {
  if (!userScopes || !Array.isArray(userScopes) || userScopes.length === 0) {
    return false;
  }
  if (Array.isArray(allowedScopes)) {
    return allowedScopes.some((scope) => userScopes.includes(scope));
  } else if (typeof allowedScopes === 'string') {
    return userScopes.includes(allowedScopes);
  }
  return false;
}
