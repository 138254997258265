import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-confirm-update-stage-dialog',
  templateUrl: './confirm-update-stage-dialog.component.html',
  styleUrls: ['./confirm-update-stage-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmUpdateStageDialogComponent {
  issues = [];
  constructor(
    public dialogRef: MatDialogRef<ConfirmUpdateStageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.issues = this.data;
  }

  trackBy(index, item): string {
    return item;
  }

  cancelValidation() {
    this.dialogRef.close(false);
  }

  continueValidation() {
    this.dialogRef.close(true);
  }
}
