<div *ngIf="order$ | async as order">
  <div *ngIf="isOrder(order)" class="order-container">
    <h3>Truck {{ order.truck?.name }} Is Already In Use</h3>
    <div class="line">
      {{ order.user?.name }} is already using this truck on
      <a target="_blank" *ngIf="existingOrderLink$ | async as link; else noLink" [routerLink]="link"
        >Load #{{ order.loadNumber }}</a
      >
      <ng-template #noLink>Load #{{ order.loadNumber }}</ng-template>
      for {{ (frac$ | async)?.site?.name }}.
    </div>
    <div class="line">
      Please {{ order.loaded ? 'complete' : 'unassign' }} #{{ order.loadNumber }} to use Truck
      {{ order.truck?.name }} for this load, or select a different truck.
    </div>
    <div class="button-wrap">
      <button color="primary" [disabled]="isLoading" (click)="cancel()">
        Cancel
      </button>
    </div>
    <div class="button-wrap" *ngIf="order.loaded; else unassignButton">
      <button color="primary" [disabled]="isLoading" (click)="complete()">
        Manually Complete Load
        <mat-spinner class="button-loader" [diameter]="20" *ngIf="isLoading"></mat-spinner>
      </button>
    </div>
    <ng-template #unassignButton>
      <div class="button-wrap">
        <button color="primary" [disabled]="isLoading" (click)="unassign()">
          Unassign Driver from Load
          <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
        </button>
      </div>
    </ng-template>
  </div>
</div>
