// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const useLocal = false;
const useBen = false;
const useBenMac = false;

export const apiKey = 'environment.api';
export const emailKey = 'environment.email';

const baseApiUrl = 'https://dev.sand.shaleapps.com/api';
const baseEmailUrl = 'devimport.shaleapps.com';

export const environment = {
  production: false,
  get api(): string {
    const useLocalServerFromStorage = JSON.parse(localStorage.getItem('ENV_USE_LOCAL') || 'false');
    if (useLocal || useLocalServerFromStorage) {
      return 'http://localhost:8080/api';
    }
    if (useBen) {
      return 'http://192.168.1.66:8080/api';
    }
    if (useBenMac) {
      return 'http://192.168.1.3:8080/api';
    }
    const sessionApiUrl = sessionStorage.getItem(apiKey);
    if (sessionApiUrl) {
      return sessionApiUrl;
    }
    sessionStorage.setItem(apiKey, baseApiUrl);
    return sessionStorage.getItem(apiKey);
  },
  set api(url: string) {
    sessionStorage.setItem(apiKey, url);
  },
  get email(): string {
    const sessionEmailUrl = sessionStorage.getItem(emailKey);
    if (sessionEmailUrl) {
      return sessionEmailUrl;
    }
    sessionStorage.setItem(emailKey, baseEmailUrl);
    return sessionStorage.getItem(emailKey);
  },
  set email(url: string) {
    sessionStorage.setItem(emailKey, url);
  },
  featureFlagKey: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyD7X5KQtp-W5y1OaF48mz9l2ckn281Cj1w',
    authDomain: 'agnus-2fff8.firebaseapp.com',
    databaseURL: 'https://agnus-2fff8.firebaseio.com',
    projectId: 'agnus-2fff8',
    storageBucket: 'agnus-2fff8.appspot.com',
    messagingSenderId: '889599006301',
  },
  gaTrackingId: 'UA-147153244-3',
  saApi: {
    fqdn: 'https://dev.api.shaleapps.com',
    jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.Nghi5qvX-Eb2hUgnU3zROcld1ec2WUWhUjxuYht_Go4',
  },
  cvsBox: {
    licenceKey: 'aeXdw07LrICLJGKEccvHkikxdN9fGH',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
