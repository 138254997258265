import { DispatcherFracListSummaryComponent } from './dispatcher-frac-list-summary/dispatcher-frac-list-summary.component';
import { DispatcherFracListComponent } from './dispatcher-frac-list/dispatcher-frac-list.component';
import { DispatcherLogisticsClusterAddComponent } from './dispatcher-logistics-cluster-add/dispatcher-logistics-cluster-add.component';
import { DispatcherLogisticsClusterEditComponent } from './dispatcher-logistics-cluster-edit/dispatcher-logistics-cluster-edit.component';
import { DispatcherLogisticsClusterSummaryComponent } from './dispatcher-logistics-cluster-summary/dispatcher-logistics-cluster-summary.component';

export const components = [
  DispatcherFracListComponent,
  DispatcherFracListSummaryComponent,
  DispatcherLogisticsClusterSummaryComponent,
  DispatcherLogisticsClusterEditComponent,
  DispatcherLogisticsClusterAddComponent,
];

export const entryComponents = [DispatcherLogisticsClusterEditComponent, DispatcherLogisticsClusterAddComponent];

export {
  DispatcherFracListSummaryComponent,
} from './dispatcher-frac-list-summary/dispatcher-frac-list-summary.component';
export { DispatcherFracListComponent } from './dispatcher-frac-list/dispatcher-frac-list.component';
export {
  DispatcherLogisticsClusterSummaryComponent,
} from './dispatcher-logistics-cluster-summary/dispatcher-logistics-cluster-summary.component';
export {
  DispatcherLogisticsClusterEditComponent,
} from './dispatcher-logistics-cluster-edit/dispatcher-logistics-cluster-edit.component';
export {
  DispatcherLogisticsClusterAddComponent,
} from './dispatcher-logistics-cluster-add/dispatcher-logistics-cluster-add.component';
