<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <div class="heading">
      <div>Subcarriers</div>
    </div>

    <sa-subnav [subnavMenu]="subnavMenu" (onTabSwitch)="activeMenu = $event"></sa-subnav>

    <div class="content">
      <div *ngIf="activeMenu === subnavLabels.carriers">
        <sa-carrier></sa-carrier>
      </div>
    </div>
  </div>
</div>
