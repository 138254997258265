import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { Router } from '@angular/router';
import { MapUtilService } from '../../../services/map-util.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { RunboardService } from 'src/app/services/runboard.service';
import { AccountFeatureFlagsService } from '~services/account-feature-flags.service';

@Component({
  selector: 'sa-frac-list',
  templateUrl: './frac-list.component.html',
  styleUrls: ['./frac-list.component.scss'],
})
export class FracListComponent implements OnInit {
  fracs: Observable<any[]>;
  groupedClusters: Observable<{ id: string; name: string; fracs: any[]; autopilotLevel: string }[]>;
  loading: Boolean;

  constructor(
    private storeService: StoreService,
    private router: Router,
    private mapUtilService: MapUtilService,
    private runboardService: RunboardService,
    public userService: UserService,
    public featureFlagService: FeatureFlagService,
    public accountFeatureFlagService: AccountFeatureFlagsService,
  ) {}

  ngOnInit() {
    this.runboardService.clearRunboard();
    this.loading = true;
    this.fracs = this.storeService.select<Array<any>>('fracs').pipe(
      filter((fracs) => fracs.length > 0),
      distinctUntilChanged(),
      tap(() => {
        if (this.loading) {
          this.loading = false;
        }
      }),
    );

    this.groupedClusters = this.fracs.pipe(
      map((fracs) => {
        interface ClusterSummary {
          [key: number]: { name: string; wells: any[]; autopilotLevel: boolean };
        }
        const clusters: ClusterSummary = fracs.reduce((acc: ClusterSummary, well) => {
          if (acc[well.cluster.id]) {
            acc[well.cluster.id].wells.push(well);
          } else {
            acc[well.cluster.id] = {
              autopilotLevel: well.cluster.autopilotLevel,
              name: well.cluster.name,
              wells: [well],
            };
          }
          return acc;
        }, {});

        return Object.keys(clusters)
          .map((key) => ({
            id: key,
            name: clusters[key].name,
            fracs: clusters[key].wells,
            autopilotLevel: clusters[key].autopilotLevel,
          }))
          .sort((a, b) => (a.name < b.name ? -1 : 1));
      }),
    );

    setTimeout(() => {
      this.loading = false;
    }, 5000);
  }

  trackFrac(index, item) {
    return item;
  }

  calculatePendingOrders(frac) {
    return (
      frac.orders &&
      frac.orders.filter((order) => order.orderStatus === 'pending' || order.orderStatus === 'driver_rejected').length
    );
  }

  calculateProgressOrders(frac) {
    return (
      frac.orders &&
      frac.orders.filter((order) => order.orderStatus === 'driver_accepted' || order.orderStatus === 'dispatched')
        .length
    );
  }

  selectFrac(frac) {
    this.router.navigate(['map/jobs/detail/' + frac.id + '/pending']);
  }
}
