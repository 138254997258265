import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SubnavMenu } from '../models/subnav-menu';
import { InviteComponent } from './invite/invite.component';
import { UserService } from '../services/user.service';

type SubnavLabel = 'Drivers' | 'Users' | 'Tasks' | 'User Groups' | 'Crews';

const SubnavLabels = {
  drivers: 'Drivers' as SubnavLabel,
  users: 'Users' as SubnavLabel,
  tasks: 'Tasks' as SubnavLabel,
  userGroups: 'User Groups' as SubnavLabel,
  crews: 'Crews' as SubnavLabel,
};

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit {
  subnavLabels = SubnavLabels;
  canInvite: boolean;
  subnavMenu: SubnavMenu[] = [
    { label: this.subnavLabels.drivers, active: true },
    { label: this.subnavLabels.users, active: false },
  ];
  activeMenu = this.subnavLabels.drivers;

  constructor(private dialog: MatDialog, private userService: UserService, private router: Router) {}

  ngOnInit() {
    this.canInvite = this.userService.isShaleAccount() && this.userService.isAdmin();
    if (this.userService.isLMOAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('/lmo');
    } else if (this.userService.isDispatcherAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('/map');
    }
    if (this.userService.isLMOAccount()) {
      this.subnavMenu = [
        { label: this.subnavLabels.users, active: true },
        { label: this.subnavLabels.userGroups, active: false },
        { label: this.subnavLabels.crews, active: false },
      ];
      this.activeMenu = this.subnavLabels.users;
    } else if (this.userService.isSandmanAccount()) {
      this.subnavMenu = [{ label: this.subnavLabels.users, active: true }];
      this.activeMenu = this.subnavLabels.users;
    }
  }

  inviteVendor(accountType) {
    this.dialog.open(InviteComponent, {
      width: '50%',
      minWidth: '968px',
      data: accountType,
    });
  }
}
