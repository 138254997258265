<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="settings-container">
    <a style="display: flex;" [routerLink]="['/', 'lmo']" saAutomatedSelector="well-creation__back-to-wells">
      <i class="material-icons">arrow_back</i>
      Back to Wells
    </a>
    <div class="heading">
      <div saAutomatedSelector="wells__header">Archived Wells</div>
      <div class="spinner" *ngIf="isLoading">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </div>

    <div>
      <mat-table
        #archiveTable
        (matSortChange)="sortClusterTable($event, 'archive')"
        [dataSource]="archiveDataSource"
        matSort
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row" saAutomatedSelector="well__name"
            ><a (click)="openWellForm(row.id)"> {{ row | fracName }}</a></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="prefill">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Prefill Date</mat-header-cell>
          <mat-cell *matCellDef="let row" saAutomatedSelector="well__prefill-date">{{
            row.prefillDate | date
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="frac">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Frac Date</mat-header-cell>
          <mat-cell *matCellDef="let row" saAutomatedSelector="well__frac-date">{{ row.fracDate | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoice">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="link-row" *matCellDef="let row">
            <button mat-stroked-button color="primary" (click)="openInvoicing(row)" saAutomatedSelector="well__invoice">
              Invoicing
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedArchivedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedArchivedColumns"
          saAutomatedSelector="well__row"
          [automatedName]="row | fracName"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
