import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Mine } from '~models/mine.model';
import { EmailSettingsV2Service } from '~services/email-settings-v2.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from '~services/error-handling.service';
import { MineService } from '~services/mine.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sa-upload-gr-gi-file',
  templateUrl: './upload-gr-gi-file.component.html',
  styleUrls: ['./upload-gr-gi-file.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class UploadGrGiFileComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private emailSettingsV2Service: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    public mineService: MineService,
  ) {}

  mines$: Observable<Mine[]>;
  fileForm: FormGroup;
  subs: Subscription[] = [];
  filterLoader: FormControl = new FormControl();
  filteredMines$: Observable<Mine[]>;

  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.goBack.emit();
      }
    }
  }
  ngOnInit(): void {
    this.mines$ = this.mineService.mines$.pipe(map((mines) => mines.data || []));

    this.buildForm();
  }

  buildForm() {
    this.fileForm = this.fb.group({
      uploadFile: [null, [Validators.required]],
    });

    this.filteredMines$ = combineLatest([this.mines$, this.filterLoader.valueChanges.pipe(startWith(''))]).pipe(
      map(([mines, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          return mines.filter((option) => option.site.name.toLowerCase().indexOf(filterValue) > -1);
        } else {
          return mines;
        }
      }),
    );
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  onSubmit(event) {
    if (this.fileForm.valid) {
      this.emailSettingsV2Service.doManualGRGIFileUpload(this.fileForm.controls['uploadFile'].value).subscribe(
        (res) => {
          this.snackBar.open('File uploaded successfully', null, {
            duration: 5000,
          });
          this.goBack.emit();
        },
        (err) => {
          this.snackBar.open('Failed to upload file.', null, {
            duration: 5000,
            panelClass: ['snackbar-error'],
          });
        },
      );
    } else {
      this.errorHandler.showError('Please fill all the fields');
    }
  }

  close() {
    this.goBack.emit();
  }

  fileChanged(files: File[]) {
    if (files && files.length > 0) {
      this.fileForm.controls['uploadFile'].setValue(files[0]);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }
}
