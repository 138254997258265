import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '~services/auth.service';
import { StoreService } from '~services/store.service';

@Component({
  selector: 'sa-nav-sand-vendor',
  templateUrl: './nav-sand-vendor.component.html',
  styleUrls: ['./nav-sand-vendor.component.scss'],
})
export class NavSandVendorComponent implements OnInit {
  @Input() userLabel: { name: string; email: string; account: string };
  @Input() isAdmin: boolean;
  emailHelpLink = 'mailto:help@shaleapps.com';

  constructor(private authService: AuthService, private storeService: StoreService) {}

  ngOnInit() {}

  goToGoHawkAndroid() {
    (<any>window).open('https://play.google.com/store/apps/details?id=com.shaleapps.agnus');
  }

  goToGoHawkIOS() {
    (<any>window).open('https://itunes.apple.com/US/app/id1296380634?mt=8');
  }

  goToSandi() {
    (<any>window).open('https://itunes.apple.com/US/app/id1360876904?mt=8');
  }

  logout() {
    this.storeService.clear();
    this.authService.logout();
  }
}
