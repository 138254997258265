import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { User, UserGroup } from '~models/user';
import { UserApiService } from '~services/api/user.api.service';
import { CrudService } from '~services/crud.service';
import { UserService } from '~services/user.service';
import { filter, map, take } from 'rxjs/operators';
import { AddUserGroupComponent } from '../add-user-group/add-user-group.component';

@Component({
  selector: 'sa-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
})
export class UserGroupComponent implements OnInit {
  displayedColumns = ['name', 'users'];
  dataSource: MatTableDataSource<UserGroup> = new MatTableDataSource();

  isAdmin = false;
  userGroups: UserGroup[] = [];
  users: User[] = [];
  constructor(
    private userApiService: UserApiService,
    private crudService: CrudService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    this.isAdmin = userService.isAdmin();
    if (this.isAdmin) {
      this.displayedColumns = [...this.displayedColumns, 'action'];
    }
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
      if (ready) {
        this.userApiService
          .getUsersGroup()
          .pipe(
            map((data) => {
              data.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              return data;
            }),
          )
          .subscribe((data) => {
            this.userGroups = data;
            this.dataSource = new MatTableDataSource(data);
          });
        this.userApiService.getWebUsers().subscribe((data) => {
          this.users = data;
        });
      }
    });
  }

  private addUserGroup(userGroup) {
    const dialogRef = this.dialog.open(AddUserGroupComponent, {
      width: '50%',
      minWidth: '968px',
      data: { userGroup: userGroup, users: this.users },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
    });
  }

  public getUserGroupName(users) {
    return users.map((e) => e.name).join(',');
  }

  sortTable(event) {
    switch (event.active) {
      case 'name':
        const sortedName = this.dataSource.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        if (event.direction === 'desc') {
          sortedName.reverse();
        }
        this.dataSource.data = sortedName;
        break;
    }
  }
}
