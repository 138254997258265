import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { EmailDetails, EmailSetting, Data } from '~models/index';
import { filter, share, switchMap, take, map, tap } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { environment } from '~environments/environment';
import { MineService } from './mine.service';

interface EmailSettingsBody {
  loaderId: string;
  email: string;
  timeParser: string;
  fileName: string;
  sheetName: string;
  bolNumber: string;
  vendorName: string;
  truckNumber: string;
  arrivalTime: string;
  departureTime: string;
  poNumber: string;
  netWeight: string;
  ticketNumber: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailSettingsService {
  get route(): string {
    return environment.api + '/email_parser';
  }
  private emailSettings$$ = new BehaviorSubject<Data<EmailSetting[]>>({
    meta: {
      loaded: false,
      loading: false,
    },
    data: [],
  });

  public get emailSettings$(): Observable<Data<EmailSetting[]>> {
    return this.emailSettings$$.asObservable().pipe(
      filter((settings) => !!settings),
      share(),
    );
  }

  constructor(private crud: CrudService, private mineService: MineService) {
    this.loadingComplete = this.loadingComplete.bind(this);
    this.loadData();
  }

  public loadData() {
    this.startEmailSummariesLoading();
  }

  public getEmailsDetailsById(id: number): Observable<EmailDetails[]> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(`${this.route}/${id}`)),
    );
  }

  public getAnalytics(body: any): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(`${this.route}/statistics`, body)),
    );
  }
  public getLoaderAnalytics(body: any): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(`${this.route}/analytics`, body)),
    );
  }

  public getStatsByEmail(body: any): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(`${this.route}/statistics_by_email`, body)),
    );
  }

  public downloadFileByUniqueId(id: string): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(`${this.route}/download_file/${id}`)),
    );
  }

  public downloadFile(fileName: string): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.get(this.route + '/link', { fileName })),
    );
  }

  public saveEmail(body: EmailSettingsBody): Observable<any> {
    this.loadingOn();
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.post(this.route, body)),
      tap(() => this.loadData()),
    );
  }
  public updateEmail(id: number, body: EmailSettingsBody): Observable<any> {
    this.loadingOn();
    return this.crud.httpClientReady.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.crud.put(`${this.route}/${id}`, body)),
      tap(() => this.loadData()),
    );
  }

  public deleteEmailSettings(id: number): void {
    this.loadingOn();
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crud.delete(`${this.route}/${id}`)),
      )
      .subscribe(() => {
        this.loadData();
      });
  }

  private loadingOn() {
    const current = this.emailSettings$$.value;
    this.emailSettings$$.next({
      ...current,
      meta: {
        ...current.meta,
        loading: true,
      },
    });
  }

  private loadingComplete(data: EmailSetting[], loaded = true) {
    const current = this.emailSettings$$.value;
    this.emailSettings$$.next({
      ...current,
      data,
      meta: {
        ...current.meta,
        loading: false,
        loaded: true,
      },
    });
  }

  private startEmailSummariesLoading() {
    this.loadingOn();
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          combineLatest(
            this.mineService.mines$.pipe(filter((mines) => mines.meta.loaded)),
            this.crud.get(this.route) as Observable<EmailSetting[]>,
          ),
        ),
        map(([mines, settings]) => {
          const mineMap: Record<number, string> = mines.data.reduce(
            (acc, mine) => ({
              ...acc,
              [mine.id]: mine.site.name,
            }),
            {},
          );
          return settings.map((setting) => ({
            ...setting,
            loader: {
              id: setting.loaderId,
              name: mineMap[setting.loaderId],
            },
          })) as EmailSetting[];
        }),
      )
      .subscribe(this.loadingComplete, (error) => {
        this.loadingComplete([], false);
      });
  }
}
