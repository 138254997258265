import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '~environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  private errorReplacements: Record<string, string> = {};
  private regexStrings: Record<string, RegExp> = {};

  constructor(private db: AngularFirestore, private snackBar: MatSnackBar) {
    this.db
      .doc<Record<string, string>>(`error-replacement/${environment.featureFlagKey}`)
      .valueChanges()
      .pipe(filter((replacements) => !!replacements))
      .subscribe((replacements) => {
        const errorReplacements = {};
        const regexStrings = {};
        Object.keys(replacements).forEach((key) => {
          errorReplacements[key] = replacements[key];
          regexStrings[key] = new RegExp(key);
        });
        this.errorReplacements = errorReplacements;
        this.regexStrings = regexStrings;
      });
  }

  public showError(error: string | HttpErrorResponse | Error, duration: number = 2000) {
    let errorString: string;
    if (typeof error === 'string') {
      errorString = this.getErrorStringFromString(error);
    } else if (error instanceof HttpErrorResponse) {
      errorString = this.getErrorStringFromHttpError(error);
    } else if (error instanceof Error) {
      errorString = this.getErrorStringFromError(error);
    } else {
      // Log the unexpected error
      this.db.collection('undefined-errors').add({
        errorAsJson: JSON.stringify(error),
      });
      errorString = this.getErrorStringFromString('Something went wrong');
    }
    // Log what is giving us a blank error
    if (errorString === '' || typeof errorString === 'undefined') {
      this.db.collection('undefined-errors').add({
        errorAsJson: JSON.stringify(error),
      });
      errorString = this.getErrorStringFromString('Something went wrong');
    }
    this.snackBar.open(`Error: ${errorString}`, null, {
      duration,
      panelClass: ['snackbar-error'],
    });
  }

  private getErrorStringFromString(error: string): string {
    for (const key in this.errorReplacements) {
      if (this.regexStrings[key].test(error)) {
        return this.errorReplacements[key];
      }
    }
    return error;
  }

  private getErrorStringFromHttpError(error: HttpErrorResponse): string {
    if (error.error) {
      if (typeof error.error === 'string') {
        return this.getErrorStringFromString(error.error);
      }
      if (error.error.Message) {
        return this.getErrorStringFromString(error.error.Message);
      }
      return this.getErrorStringFromString(error.error.Error);
    }
    return error.statusText;
  }

  private getErrorStringFromError(error: Error): string {
    return this.getErrorStringFromString(error.message);
  }
}
