import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { EditableUserFields } from '../../constants/editable-user-fields';
import { User } from '../../models/user';
import { UserApiService } from '../../services/api/user.api.service';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetailViewInputComponent } from '../detail-view-input/detail-view-input.component';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { filter, take, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserSegmentsService, IDName } from '~services/user-segments.service';

@Component({
  selector: 'sa-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailViewComponent implements OnInit, AfterViewInit {
  public permissions: string[] = [];
  public segments$: Observable<IDName[]>;
  public permissionsFormControl = new FormControl([]);
  buttonDisabled = false;
  roles = [
    { id: 1, value: 'Admin', server: 'admin' },
    { id: 2, value: 'Standard', server: 'default' },
    { id: 5, value: 'Crew-Leader', server: 'crew-leader', featureFlag: 'crewLeaderEnabled' },
    { id: 4, value: 'View-Only', server: 'view-only' },
  ];
  _user: User;
  isTruckingVendor: Boolean;
  isNextierAccount: boolean;

  test = new BehaviorSubject<any[]>([]);

  editableUserFields = [
    { label: 'Name', fieldName: EditableUserFields.name, type: 'string' },
    { label: 'Email', fieldName: EditableUserFields.email, type: 'email' },
    { label: 'Phone', fieldName: EditableUserFields.phone, type: 'phone', optional: true },
  ];

  @ViewChildren('detailInput') inputs: QueryList<DetailViewInputComponent>;
  @Output() onUserUpdated: EventEmitter<User> = new EventEmitter<User>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  @Input('user')
  set user(user: User) {
    this._user = user;
    this.permissionsFormControl.setValue(this._user.permissions);
    this.setRole();
  }

  constructor(
    private userApiService: UserApiService,
    private snackBar: MatSnackBar,
    public userService: UserService,
    private errorHandler: ErrorHandlingService,
    private cdr: ChangeDetectorRef,
    private userSegmentService: UserSegmentsService,
  ) {
    this.isTruckingVendor = this.userService.isDispatcherAccount();
    this.isNextierAccount = this.userService.isNexTierAccount();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public ngOnInit() {
    if (this.isNextierAccount) {
      this.roles.push(
        { id: 9, value: 'Sand Coordinator', server: 'sand-coordinator' },
        { id: 10, value: 'Dispatcher', server: 'dispatcher' },
      );
    }
    if (!this.userService.isDispatcherAccount()) {
      this.permissions = this.userService.permissions() || [];
    }
    this.permissions.sort();
    this.segments$ = this.userSegmentService.segments$;

    this.permissionsFormControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.updateOtherPermissions();
    });
  }

  private setRole() {
    if (this._user && this._user.accountRoles && this._user.accountRoles.length > 0) {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].id === this._user.accountRoles[0].id) {
          this._user.role = this.roles[i].server;
        }
      }
    } else {
      this._user.role = this.roles[0].server;
    }
    this.setUserPermission();
  }

  isFormValid(): boolean {
    let valid = true;

    if (this.inputs) {
      this.inputs.toArray().forEach((formInput: DetailViewInputComponent) => {
        if (!formInput.editableValue && !formInput.optional) {
          valid = false;
        }

        if (formInput.error) {
          valid = false;
        }
      });
    }

    if (this._user && this._user.password && this._user.password.length < 6) {
      valid = false;
    }

    return valid;
  }

  showPasswordError(): boolean {
    if (this._user && this._user.password) {
      return this._user.password.length < 6 && this._user.password.length !== 0;
    }
    return false;
  }

  validateForm(): void {
    this.inputs.toArray().forEach((formInput: DetailViewInputComponent) => {
      formInput.touched = true;
      formInput.validate();
    });
  }

  changePermission() {
    if (this._user.role === 'dispatcher' || this._user.role === 'sand-coordinator') {
      const permissionToRemove = 'allow_data_lock';
      this.permissions = this.permissions.filter((permission) => permission !== permissionToRemove);
      const userUpdatedPermission = this.permissionsFormControl.value?.filter(
        (permission) => permission !== permissionToRemove,
      );
      this.permissionsFormControl.setValue(userUpdatedPermission);
    } else {
      this.permissions = this.userService.permissions() || [];
    }
    this.updateUser('role', this._user.role, -1);
    this.setUserPermission();
  }

  private setUserPermission() {
    if (this.userService.isDispatcherAccount()) {
      if (this._user.role === 'view-only') {
        this.permissions = ['allow_bulk_export'];
      } else {
        this.permissions = [];
      }
    }
  }

  changeSegment() {
    this.updateUser('segment', this._user.segment, -1);
  }

  validatePassword() {
    if (!this._user.password) {
      return;
    }
    this._user.password = this._user.password.trim();
  }

  updateUser(fieldName: string, value: any, index: number): void {
    const updatedUser = Object.assign({}, this._user);
    value = value === '' ? undefined : value;
    updatedUser[fieldName] = value;

    if (this._user.id) {
      this.userApiService.updateWebUser(this._user.id, updatedUser).subscribe(
        (user) => {
          this._user = user;
          this.setRole();
          this.onUserUpdated.emit(user);
          if (fieldName === 'role') {
            this.snackBar.open("New permissions will be reflected on this user's next login.", null, {
              duration: 2000,
            });
          } else {
            this.snackBar.open("User successfully updated'", null, {
              duration: 2000,
            });
          }
        },
        (err) => {
          if (index >= 0) {
            this.inputs.toArray()[index].revert();
          }
          this.errorHandler.showError(err);
        },
      );
    } else {
      this._user[fieldName] = value;
    }
  }

  updateOtherPermissions(): void {
    if (!this._user.id) {
      return;
    }
    this.userApiService.setPermissions(this.permissionsFormControl.value, this._user.id).subscribe((user) => {
      this._user = user;
      this.snackBar.open("New permissions will be reflected on this user's next login.", null, {
        duration: 2000,
      });
    });
  }

  removeUser(id: number): void {
    this.buttonDisabled = true;
    if (confirm('Are you sure you want to remove this user?')) {
      this.userApiService.removeWebUser(id).subscribe(
        () => {
          this.onUserUpdated.emit(null);
          this.buttonDisabled = false;
          this.snackBar.open('User successfully removed', null, {
            duration: 2000,
          });
        },
        (err) => {
          this.buttonDisabled = false;
          this.errorHandler.showError(err);
        },
      );
    }
  }

  inviteUser(): void {
    this.validateForm();

    if (this.isFormValid()) {
      this.buttonDisabled = true;
      this.validatePassword();
      this.userApiService
        .createWebUser(this._user)
        .pipe(switchMap((u: any) => this.userApiService.setPermissions(this.permissionsFormControl.value, u.id)))
        .subscribe(
          (user) => {
            this._user = user;
            this.setRole();
            this.snackBar.open('User successfully invited', null, {
              duration: 2000,
            });
            this.onUserUpdated.emit(user);
          },
          (err) => {
            this.buttonDisabled = false;
            this.errorHandler.showError(err);
          },
        );
    } else {
      this.errorHandler.showError('Error processing form. Please ensure all required values are present.');
    }
  }

  closeClicked() {
    this.onClose.emit();
  }

  public getPermissionPrettyName(name: string = ''): string {
    if (!name) {
      return '';
    }
    if (permissionPrettyNameCache[name]) {
      return permissionPrettyNameCache[name];
    }
    let returnValue = name;
    if (name === 'can_crud_users') {
      returnValue = 'Can Update Users';
    } else {
      returnValue = name
        .split('_')
        .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
        .join(' ');
    }
    permissionPrettyNameCache[name] = returnValue;
    return returnValue;
  }
}
const permissionPrettyNameCache: Record<string, string> = {};
