import { Injectable } from '@angular/core';
import { environment } from '~environments/environment';
import { CrudService } from '~services/crud.service';
import { Observable } from 'rxjs';
import { LMOBrokerTrailerType, Subcarrier } from '~models/subcarrier.model';
import { Trailer } from '~models/trailer.model';

@Injectable()
export class SubcarrierApiService {
  get route(): string {
    return environment.api + '/subcarriers';
  }

  constructor(private crud: CrudService) {}

  getSubcarriers(): Observable<Subcarrier[]> {
    return this.crud.get(this.route);
  }

  createSubcarrier(body) {
    return this.crud.post(this.route, body);
  }

  updateSubcarrier(id: number, body): Observable<Subcarrier> {
    return this.crud.patch(this.route + '/' + id, body);
  }

  archiveSubcarrier(id: number): Observable<void> {
    return this.crud.post(this.route + '/' + id);
  }

  driverSubcarrier(id: number): Observable<Subcarrier> {
    return this.crud.get(this.route + '/driver/' + id);
  }

  assignDriverToSubcarrier(body) {
    return this.crud.post(this.route + '/driver', body);
  }

  removeDriverFromSubcarrier(id: number) {
    return this.crud.post(this.route + '/driver/' + id);
  }

  getLMOBrokerTrailerTypes(): Observable<LMOBrokerTrailerType[]> {
    return this.crud.get(this.route + '/brokerage_trailer_type');
  }

  getTrailerTypeAssetStatuses(): Observable<string[]> {
    return this.crud.get(this.route + '/trailer_type_asset_statuses');
  }

  public getTrailers(subcarrierId: number): Observable<Trailer[]> {
    return this.crud.get(this.route + '/' + subcarrierId + '/trailers');
  }
}
