import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud.service';

@Injectable()
export class ConstantsApiService {
  get equipmentTypeRoute(): string {
    return environment.api + '/equipment_type';
  }
  get meshTypeRoute(): string {
    return environment.api + '/sand/mesh';
  }
  get materialNumbersRoute(): string {
    return environment.api + '/sand/material_number';
  }

  get storageTypeRoute(): string {
    return environment.api + '/sand/storage_type';
  }

  constructor(private crud: CrudService) {}

  getEquipmentTypes() {
    return this.crud.get(this.equipmentTypeRoute);
  }

  getMeshTypes() {
    return this.crud.get(this.meshTypeRoute);
  }

  getMaterialNumbers() {
    return this.crud.get(this.materialNumbersRoute);
  }

  getStorageTypes() {
    return this.crud.get(this.storageTypeRoute);
  }
}
