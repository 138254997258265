import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderApiService } from '~services/api/order.api.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { UserService } from '~services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-bulk-export',
  templateUrl: './bulk-export.component.html',
  styleUrls: ['./bulk-export.component.scss'],
})
export class BulkExportComponent implements OnInit {
  public downloadDataForm: FormGroup;
  public todayDate;
  public isDownloading = false;

  constructor(
    private fb: FormBuilder,
    private orderApiService: OrderApiService,
    private errorHandler: ErrorHandlingService,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.todayDate = new Date();
    this.setupForm();
  }

  private setupForm() {
    this.downloadDataForm = this.fb.group({
      startDate: [this.todayDate, [Validators.required]],
      endDate: [this.todayDate, [Validators.required]],
      email: [false],
      byApprovedTime: [false, [Validators.required]],
    });
  }

  public downloadData() {
    const startTime = this.downloadDataForm.controls['startDate'].value.setHours(0, 0, 0);
    const endTime = this.downloadDataForm.controls['endDate'].value.setHours(23, 59, 59);
    const email = this.downloadDataForm.get('email').value;
    if (!email) {
      this.isDownloading = true;
    }
    const body = {
      startTime: new Date(startTime),
      endTime: new Date(endTime),
      email: email,
      byApprovedTime: this.downloadDataForm.get('byApprovedTime').value,
    };
    const endpoint = this.userService.isLMOAccount()
      ? this.orderApiService.downloadAllLmoData(body)
      : this.orderApiService.downloadAllVendorData(body);
    endpoint.subscribe(
      (data) => {
        if (!email) {
          window.open(data, '_blank');
          this.isDownloading = false;
          this.snackBar.open('successfully downloaded the data', null, {
            duration: 5000,
          });
        } else {
          this.snackBar.open('your bulk export will be emailed to you shortly', null, {
            duration: 5000,
          });
        }
      },
      (err) => {
        this.isDownloading = false;
        this.errorHandler.showError(err);
      },
    );
  }
}
