import { Component, EventEmitter, Injectable, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
@Component({
  selector: 'sa-map-search-input',
  templateUrl: './map-search-input.component.html',
  styleUrls: ['./map-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapSearchInputComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onFocused: EventEmitter<boolean> = new EventEmitter();
  @Output() onMenuClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('input', { static: true }) public input;

  inputString = new Subject<string>();
  _focused: boolean;

  clear() {
    this.input.nativeElement.value = '';
    this.inputString.next('');
    this.onSearch.emit('');
  }

  ngOnInit() {
    this.inputString.pipe(debounceTime(400)).subscribe((searchTerm) => {
      this.onSearch.emit(searchTerm);
    });
  }

  get focused(): boolean {
    return this._focused;
  }

  set focused(value: boolean) {
    this._focused = value;
    this.onFocused.emit(value);
  }

  onInput(searchTerm: string) {
    this.inputString.next(searchTerm);
  }

  menuClick() {
    this.onMenuClick.emit();
  }
}
