<div
  class="dispatcher-frac-list"
  *ngIf="fracLogClusters$ | async as fracLogClusters; else loading"
  (scroll)="handleScroll($event)"
>
  <h2>All Clusters</h2>

  <div class="shadow-box summary-container">
    <sa-dispatcher-frac-list-summary
      [class.shadow-box]="isScrolled$ | async"
      [class.fixed]="isScrolled$ | async"
    ></sa-dispatcher-frac-list-summary>
  </div>

  <div class="shadow-box" *ngFor="let cluster of fracLogClusters$ | async; trackBy: trackById">
    <sa-dispatcher-logistics-cluster-summary [logCluster]="cluster"></sa-dispatcher-logistics-cluster-summary>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    Not on any current jobs
  </div>
</ng-template>
