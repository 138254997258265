import * as R from 'remeda';

function _pluck(record: Record<string, any>, keys: string[]): Record<string, any> {
  return keys.reduce((partialRecord, key) => {
    partialRecord[key] = record[key];
    return partialRecord;
  }, {});
}

export function pluck<T extends Record<string, any>>(record: T, keys: string[]): Partial<T>;
export function pluck<T extends Record<string, any>>(keys: string[]): (t: T) => Partial<T>;
export function pluck() {
  return R.purry(_pluck, arguments);
}
