import { Injectable } from '@angular/core';
import { environment } from '~environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, tap } from 'rxjs/operators';
import { VERSION } from '~environments/version';
import { Observable, combineLatest, of } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  public isWrongVersion$: Observable<boolean>;

  public get currentVersion(): string {
    if (VERSION && VERSION.version) {
      if (VERSION.hash) {
        return `${VERSION.version}.${VERSION.hash}`;
      }
      return VERSION.version;
    }
  }

  constructor(private db: AngularFirestore, private swUpdate: SwUpdate) {
    if (window.location.hostname === 'localhost') {
      this.isWrongVersion$ = of(false);
    } else {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
      this.isWrongVersion$ = combineLatest(
        this.db.doc<{ version: string }>(`version/${environment.featureFlagKey}`).valueChanges(),
      ).pipe(
        map(([version]) => version && version.version !== this.currentVersion),
        tap((isWrongVersion) => {
          if (isWrongVersion) {
            if (swUpdate.isEnabled) {
              this.swUpdate.checkForUpdate();
            }
          }
        }),
      );
    }
  }
}
