import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { environment } from '~environments/environment';
import { B_ACCOUNTS } from '../constants/b-accounts';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    combineLatest([
      this.authService.afAuth.authState.pipe(takeUntil(this.authService.authStateUnsubscribeSubject)),
      this.authService.isFirstTimeUser$,
    ]).subscribe(([user, isFirstTimeUser]) => {
      const userContract = this.userService.getUserContractFromCache();
      if (userContract && userContract.account && B_ACCOUNTS.includes(userContract.account.id)) {
        if (environment.featureFlagKey !== 'stage' || !this.userService.isShaleappsEmail()) {
          this.authService.logout();
          return false;
        }
      }
      if (!user || !userContract) {
        this.authService.logout();
        return false;
      }
      if (isFirstTimeUser) {
        this.router.navigate(['/', 'update-password']);
      }
      return true;
    });
    return true;
  }
}
