import { sort } from 'remeda';
import { RestrictionTime } from './types';

const numberToDayName = {
  0: 'Su',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'Sa',
};

export function prettyPrintTimes(times: RestrictionTime[]): string[] {
  const groupings = times.reduce(
    (acc, time) => {
      const timeBlock = `${time.startMinuteOfDay}-${time.endMinuteOfDay}`;
      if (!acc[timeBlock]) {
        acc[timeBlock] = {
          start: time.startMinuteOfDay,
          end: time.endMinuteOfDay,
          days: [],
        };
      }
      acc[timeBlock].days.push(time.daysSinceSunday);
      return acc;
    },
    {} as Record<string, { start: number; end: number; days: number[] }>,
  );

  const asArray = Object.values(groupings);
  const sortedArray = sort(asArray, (a, b) => {
    if (a.days[0] === b.days[0]) {
      return a.start - b.start;
    }
    return a.days[0] - b.days[0];
  });
  return sortedArray.map((entry) => {
    return `${minutesOfDayToHrMin(entry.start)} - ${minutesOfDayToHrMin(entry.end)} ${entry.days
      .map((d) => numberToDayName[d])
      .join(', ')}`;
  });
}

function minutesOfDayToHrMin(minutesOfDay: number): string {
  if (minutesOfDay % 1440 === 0) {
    return '12:00am';
  }
  const amPm = minutesOfDay >= 720 ? 'pm' : 'am';
  const hour = Math.floor(minutesOfDay / 60);
  const minutes = `${Math.round(minutesOfDay % 60)}`.padStart(2, '0');
  return `${hour > 12 ? hour - 12 : hour}:${minutes}${amPm}`;
}
