import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrudService } from '~services/crud.service';
import { WellApiService } from '~services/api/well.api.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { ErrorHandlingService } from '~services/error-handling.service';
import { Router } from '@angular/router';

interface ArchivedWell {
  wellName: string;
  crewName: string;
  prefillDate: string;
  fracDate: string;
  archivedDate: string;
  id: number;
}

@Component({
  selector: 'sa-archived-wells',
  templateUrl: './archived-wells.component.html',
  styleUrls: ['./archived-wells.component.scss'],
})
export class ArchivedWellsComponent implements OnInit {
  displayedColumns: string[] = ['wellName', 'crewName', 'prefillDate', 'fracDate', 'archivedDate', 'actions'];
  dataSource = new MatTableDataSource<ArchivedWell>([]);

  totalElements = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50];

  searchValue = '';
  searchType: 'well' | 'crew' = 'well';
  selectedStartDate: string | null = '';
  selectedEndDate: string | null = '';

  isLoading = false;

  constructor(
    private router: Router,
    private crud: CrudService,
    private wellApiService: WellApiService,
    private errorHandler: ErrorHandlingService,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    let params = new HttpParams().set('page', this.pageIndex.toString()).set('size', this.pageSize.toString());

    if (this.searchValue) {
      params = params.set('search', this.searchValue);
      params = params.set('searchBy', this.searchType);
    }

    if (this.selectedStartDate) {
      params = params.set('archivedDate', this.selectedStartDate.toString());
    }

    this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.getArchivedWells();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  onSearch() {
    this.pageIndex = 0; // Reset to first page when searching
    this.loadData();
  }

  onDateSearch() {
    if (this.selectedStartDate !== '' && this.selectedEndDate !== '') {
      this.pageIndex = 0;
      this.loadData();
    }
  }

  clearDates() {
    if (this.selectedStartDate !== '' && this.selectedEndDate !== '') {
      this.selectedStartDate = '';
      this.selectedEndDate = '';
      this.pageIndex = 0;
      this.loadData();
    }

    if (this.selectedStartDate !== '') {
      this.selectedStartDate = '';
    }

    if (this.selectedEndDate !== '') {
      this.selectedEndDate = '';
    }
  }

  private getArchivedWells() {
    this.isLoading = true;

    let archivedStartDate = '';
    let archivedEndDate = '';
    if (
      this.selectedStartDate !== null &&
      this.selectedEndDate !== null &&
      this.selectedStartDate !== '' &&
      this.selectedEndDate !== ''
    ) {
      const startDate = new Date(this.selectedStartDate);
      startDate.setHours(0, 0, 0);
      const utcStartDate = new Date(startDate.toUTCString());
      archivedStartDate = utcStartDate.toISOString();

      const endDate = new Date(this.selectedEndDate);
      endDate.setHours(23, 59, 59);
      const utcEndDate = new Date(endDate.toUTCString());
      archivedEndDate = utcEndDate.toISOString();
    }

    const pageNumber = this.pageIndex + 1;

    const params = {
      page: pageNumber.toString(),
      size: this.pageSize.toString(),
      search: this.searchValue,
      searchBy: this.searchType,
      archivedStartDate: archivedStartDate,
      archivedEndDate: archivedEndDate,
    };

    this.wellApiService.getPaginatedWells(params).subscribe(
      (wells) => {
        this.dataSource.data = wells.Fracs;
        this.totalElements = wells.TotalElements;
        this.isLoading = false;
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
        this.isLoading = false;
      },
    );
  }

  public openWellForm(id) {
    if (id) {
      this.router.navigate(['/wells/edit/' + id]);
    } else {
      this.router.navigate(['/wells/edit/new']);
    }
  }
}
