import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-cancel-confirm-dialog',
  templateUrl: './cancel-confirm-dialog.component.html',
  styleUrls: ['./cancel-confirm-dialog.component.scss'],
})
export class CancelConfirmDialogComponent implements OnInit {
  data: any;

  constructor(
    public dialogRef: MatDialogRef<CancelConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this.data = _data;
  }

  ngOnInit() {}

  cancelAction() {
    this.dialogRef.close(false);
  }

  continueAction() {
    this.dialogRef.close(true);
  }
}
