import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TruckingApiService } from 'src/app/services/api/trucking.api.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorHandlingService } from '../services/error-handling.service';
import { LmoTruckingVendorsService } from '~lmo/services';
import { CancelConfirmDialogComponent } from '../marketplace/cancel-confirm-dialog/cancel-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'sa-vendor-contract-edit',
  templateUrl: './vendor-contract-edit.component.html',
  styleUrls: ['./vendor-contract-edit.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class VendorContractEditComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private truckingApiService: TruckingApiService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    private truckingContractService: LmoTruckingVendorsService,
  ) {}
  vendorForm: FormGroup;

  cpl: FormArray;
  canAddNewLoad = false;
  filterTrucking: FormControl = new FormControl();
  filteredTruckingCompany: Observable<string[]>;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  costType = 'fixed';

  @Input() allVendors: any;
  @Input() selectedRow: any;
  @Input() editForm: boolean;
  @Input() isArchived: boolean;
  @Input() lmoId: any;
  @Output() onCloseClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.onCloseClicked.emit(false);
      }
    }
  }

  ngOnInit() {
    this.allVendors.sort(this.compare).filter(function(item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
    if (this.selectedRow && this.selectedRow.costsPerLoad) {
      this.selectedRow.costsPerLoad.sort(this.compareMileage);
      this.costType = this.selectedRow.costType && this.selectedRow.costType;
    }
    this.buildform();
  }

  compare(a, b) {
    if (a.vendorName < b.vendorName) {
      return -1;
    }
    if (a.vendorName > b.vendorName) {
      return 1;
    }
    return 0;
  }

  compareMileage(a, b) {
    if (a.minimumMileage < b.minimumMileage) {
      return -1;
    }
    if (a.minimumMileage > b.minimumMileage) {
      return 1;
    }
    return 0;
  }

  buildform() {
    this.vendorForm = this.fb.group({
      name: [this.selectedRow ? this.selectedRow.name : (null as string), [Validators.required]],
      expirationTime: [this.selectedRow ? this.selectedRow.expirationTime : null, []],
      lmoId: this.lmoId,
      vendorName: [
        { value: this.selectedRow ? this.selectedRow.vendorName : (null as string), disabled: this.selectedRow },
        [Validators.required],
      ],
      vendorId: [null as number, []],
      hourlyDetentionRate: [
        this.selectedRow ? this.selectedRow.hourlyDetentionRate / 100 : (null as number),
        [Validators.required, Validators.min(0)],
      ],
      maxDetentionPerLoad: [
        this.selectedRow ? this.selectedRow.maxDetentionPerLoad / 100 : (null as number),
        [Validators.min(0)],
      ],
      pickupFreeTime: [
        this.selectedRow ? this.selectedRow.pickupFreeTime : (null as number),
        [Validators.required, Validators.min(0)],
      ],
      dropoffFreeTime: [
        this.selectedRow ? this.selectedRow.dropoffFreeTime : (null as number),
        [Validators.required, Validators.min(0)],
      ],
      deadheadFreeMileage: [
        this.selectedRow ? this.selectedRow.deadheadFreeMileage : (null as number),
        [Validators.required, Validators.min(0)],
      ],
      deadheadCostPerMile: [
        this.selectedRow ? this.selectedRow.deadheadCostPerMile / 100 : (null as number),
        [Validators.required, Validators.min(0)],
      ],
      allowDeadheadLinking: [this.selectedRow ? this.selectedRow.allowDeadheadLinking : false],
      costsPerLoad: this.fb.array(this.selectedRow ? this.getExistingCostsPerLoad() : [this.initCostPerLoad()]),
    });

    this.filteredTruckingCompany = this.filterTrucking.valueChanges.pipe(
      startWith<string | any>(''),
      map((value) => (typeof value === 'string' ? value : value.vendorName)),
      map((type) => (type ? this._filterTrucks(type) : this.allVendors)),
    );
  }

  private _filterTrucks(value) {
    const filterValue = value.toLowerCase();
    return this.allVendors.filter((option) => option.vendorName.toLowerCase().indexOf(filterValue) > -1);
  }

  getExistingCostsPerLoad(): FormGroup[] {
    if (this.selectedRow.costsPerLoad === null) {
      return [this.initCostPerLoad()];
    }

    if (this.selectedRow.costsPerLoad != null) {
      const costsPerLoad = this.selectedRow.costsPerLoad;
      costsPerLoad.sort(function(a, b) {
        return a.minimumMileage - b.minimumMileage;
      });

      const newCostsPerLoad = costsPerLoad.map((load) => {
        return this.fb.group({
          vendorContractId: [null, []],
          minimumMileage: [load.minimumMileage, [Validators.required, Validators.min(0)]],
          maximumMileage: [load.maximumMileage, [Validators.required]],
          cost: [load.cost / 100, [Validators.required, Validators.min(0)]],
        });
      });

      return newCostsPerLoad;
    }
  }

  initCostPerLoad() {
    return this.fb.group({
      vendorContractId: [null, []],
      minimumMileage: [0 as number, [Validators.required, Validators.min(0)]],
      maximumMileage: [0 as number, [Validators.required]],
      cost: [0 as number, [Validators.required, Validators.min(0)]],
    });
  }

  addCostPerLoad() {
    this.canAddNewLoad = true;
    this.cpl = this.vendorForm.get('costsPerLoad') as FormArray;
    this.cpl.push(this.initCostPerLoad());
  }

  removeLoad(position) {
    this.cpl = this.vendorForm.get('costsPerLoad') as FormArray;
    this.cpl.removeAt(position);
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  public async deleteTruckVendorContract() {
    const modalText = {
      heading: 'Delete Trucking Contract',
      subheading: 'Are you sure you want to delete the contract?',
      buttonText: 'Confirm',
    };
    const dialogRef = this.dialog.open(CancelConfirmDialogComponent, {
      width: '30%',
      minWidth: '300px',
      maxWidth: '968px',
      data: modalText,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const success = this.truckingContractService.removeTruckingContract(this.selectedRow.id);
        if (success) {
          this.snackBar.open('Successfully deleted contract', null, {
            duration: 5000,
            panelClass: ['snackbar-success'],
          });
          this.closeClicked(true);
        }
      }
    });
  }

  onSubmit() {
    if (this.vendorForm.valid) {
      const body = {
        name: this.vendorForm.controls['name'].value,
        vendorName: this.vendorForm.controls['vendorName'].value,
        vendorId: this.selectedRow.vendorId,
        hourlyDetentionRate: Math.round(this.vendorForm.value.hourlyDetentionRate * 100),
        maxDetentionPerLoad: Math.round(this.vendorForm.value.maxDetentionPerLoad * 100),
        deadheadCostPerMile: Math.round(this.vendorForm.value.deadheadCostPerMile * 100),
        pickupFreeTime: Math.round(this.vendorForm.value.pickupFreeTime),
        dropoffFreeTime: Math.round(this.vendorForm.value.dropoffFreeTime),
        deadheadFreeMileage: Math.round(this.vendorForm.value.deadheadFreeMileage),
        costsPerLoad: this.vendorForm.value.costsPerLoad.map((item) => ({ ...item })),
        costType: this.costType,
        id: this.selectedRow.id,
        expirationTime: this.vendorForm.controls['expirationTime'].value,
        allowDeadheadLinking: this.vendorForm.controls['allowDeadheadLinking'].value || false,
      };

      body.costsPerLoad.forEach((load) => {
        load.cost = Math.round(load.cost * 100);
      });

      this.truckingContractService.updateTruckingContract$(body).subscribe(
        (contract) => {
          if (contract) {
            this.closeClicked(true);
            this.snackBar.open('Successfully edited contract', null, {
              duration: 5000,
              panelClass: ['snackbar-success'],
            });
          }
        },
        (err) => {
          this.errorHandler.showError(err, 5000);
        },
      );
    }
  }

  closeClicked(close) {
    this.onCloseClicked.emit(close);
  }

  public clearExpirationDate() {
    this.vendorForm.get('expirationTime').setValue(null);
  }

  public today() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }
}
