<div class="w-full p-2">
  <h2 mat-dialog-title>
    Edit Storage Supplemental Meshes
  </h2>

  <form *ngIf="storageForm" [formGroup]="storageForm" (submit)="submit()">
    <div *ngIf="supplementalMeshes?.length === 0; else meshes">
      No supplemental meshes for storage
    </div>

    <ng-template #meshes>
      <ng-container formArrayName="meshes" *ngFor="let mesh of supplementalMeshes.controls; index as i">
        <div [formGroupName]="i" class="grid grid-cols-4 gap-1">
          <mat-form-field>
            <mat-label>Mesh</mat-label>
            <mat-select formControlName="meshId" required>
              <mat-option *ngFor="let meshType of meshTypes" [value]="meshType.id">
                {{ meshType.type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Material Number</mat-label>
            <input matInput formControlName="materialNumber" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Sales Order Line Item</mat-label>
            <input matInput formControlName="salesOrderLineItem" required />
          </mat-form-field>
          <button (click)="deleteSupplementalMesh(i)" mat-flat-button color="warn" class="h-5">Delete</button>
        </div>
      </ng-container>
    </ng-template>

    <button (click)="addSupplementalMesh()" mat-flat-button color="primary">Add New</button>

    <div matDialogActions fxLayout="row" fxLayoutAlign="flex-end" class="actions">
      <button mat-flat-button mat-dialog-close color="accent">
        Cancel
      </button>
      <button *ngIf="canEdit" mat-flat-button color="primary" type="submit" [disabled]="submitting">
        {{ submitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </form>
</div>
