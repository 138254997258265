import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sa-rti-demo',
  templateUrl: './rti-demo.component.html',
  styleUrls: ['./rti-demo.component.scss'],
})
export class RtiDemoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
