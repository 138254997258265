import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from '~services/crud.service';
import { environment } from '~environments/environment';
import { filter, take, switchMap } from 'rxjs/operators';
import { Site } from '~models/site';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  private sites$$: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>([]);

  public get sites$(): Observable<Site[]> {
    return this.sites$$.asObservable();
  }

  constructor(private crudService: CrudService) {}

  getSites(north: number, south: number, east: number, west: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/site`, { north, south, east, west })),
      )
      .subscribe((sites: Site[]) => {
        this.sites$$.next(sites);
      });
  }
}
