<div class="sa-flex-container">
  <div class="reset-password">
    <img class="shale-logo" src="/assets/icons/shale-logo.svg" />
    <div class="back-wrap">
      <i class="material-icons">arrow_back</i><a [routerLink]="['/', 'login']">Back to login</a>
    </div>
    <h1>Request Password Reset</h1>
    <form
      (ngSubmit)="sendResetEmail()"
      [formGroup]="resetForm"
      *ngIf="status === 'awaitingUserInput' || status === 'error'"
    >
      <div class="instructions">
        Enter your email address and we will send you a link that will allow you to reset your password.
      </div>
      <mat-form-field>
        <input
          matInput
          formControlName="email"
          placeholder="Email"
          [errorStateMatcher]="matcher"
          saAutomatedSelector="reset__email"
        />
        <mat-error *ngIf="resetForm.get('email').hasError('required')">
          Email is required
        </mat-error>
        <mat-error *ngIf="resetForm.get('email').hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <div class="form-buttons">
        <button type="submit" mat-raised-button color="primary">Get Email</button>
      </div>
    </form>

    <mat-spinner mode="indeterminate" *ngIf="status === 'submitting'"></mat-spinner>
    <div *ngIf="serverResponse$$ | async as response">
      {{ response }}
    </div>
    <div class="error" *ngIf="status === 'error'">{{ errorMessage }}</div>
  </div>
</div>
