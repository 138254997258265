import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Site } from '../../models/site';
import { SubnavMenu } from '../../models/subnav-menu';
import { CrudService } from '../../services/crud.service';
import { SiteApiService } from '../../services/api/site.api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditableSiteFields } from '../../constants/editable-site-fields';
import { DetailViewInputComponent } from '../detail-view-input/detail-view-input.component';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sa-site-detail-view',
  templateUrl: './site-detail-view.component.html',
  styleUrls: ['./site-detail-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteDetailViewComponent implements OnInit {
  @Input() id: number;
  @Output() onSiteUpdated: EventEmitter<{ id: number; site: Site }> = new EventEmitter<{ id: number; site: Site }>();

  @ViewChildren('detailInput') inputs: QueryList<DetailViewInputComponent>;

  site: Site;
  subnavMenu: SubnavMenu[] = [{ label: 'Details', active: true }];
  activeMenu: 'Details';

  editableSiteFields = [
    { label: 'Name', fieldName: EditableSiteFields.name, type: 'string' },
    { label: 'Owner', fieldName: EditableSiteFields.owner, type: 'string' },
    { label: 'Type', fieldName: EditableSiteFields.type, type: 'string' },
    { label: 'API Number', fieldName: EditableSiteFields.api, type: 'integer' },
    { label: 'Lat, Lng', fieldName: EditableSiteFields.lngLat, type: 'coordinates' },
    { label: 'Radius', fieldName: EditableSiteFields.radius, type: 'integer' },
  ];

  constructor(
    private crud: CrudService,
    private siteApiService: SiteApiService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
  ) {}

  ngOnInit() {
    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe((ready) => {
        if (ready) {
          this.getSite(this.id);
        }
      });
  }

  getSite(id: number) {
    this.siteApiService.getSite(id).subscribe((site: Site) => {
      this.site = site;
    });
  }

  deleteSite(id: number) {
    if (confirm('Are you sure you want to delete this site?')) {
      this.siteApiService.delete(id).subscribe(
        (result) => {
          this.onSiteUpdated.emit({ id: this.id, site: null });
          this.snackBar.open(this.site.name + ' successfully deleted', null, {
            duration: 2000,
          });
        },
        (err) => {
          this.errorHandler.showError(err);
        },
      );
    }
  }

  updateSite(id: number, fieldName: string, value: any, inputIndex: number) {
    if (this.site[fieldName].toString() === value.toString()) {
      return;
    }

    const updatedSite = Object.assign({}, this.site);

    updatedSite[fieldName] = value;

    this.siteApiService.update(id, updatedSite).subscribe(
      (result) => {
        this.site = updatedSite;
        this.onSiteUpdated.emit({ id: this.id, site: updatedSite });
        this.snackBar.open('Site successfully updated', null, {
          duration: 2000,
        });
      },
      (err) => {
        this.inputs.toArray()[inputIndex].revert();
        this.errorHandler.showError(err);
      },
    );
  }
}
