import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { OrderUniqueIdSearchService, SearchResult } from '../order-unique-id-search.service';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '~services/user.service';
import { isCompleteGroup } from '~v2Models/order.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';

export const fadeInAndOut = trigger('fadeInAndOut', [
  state('in', style({ opacity: 1 })),
  transition(':enter', [style({ opacity: 0 }), animate(600)]),
  transition(':leave', animate(600, style({ opacity: 0 }))),
]);

@Component({
  animations: [fadeInAndOut],
  selector: 'sa-order-unique-id-search',
  templateUrl: './order-unique-id-search.component.html',
  styleUrls: ['./order-unique-id-search.component.scss'],
})
export class OrderUniqueIdSearchComponent implements OnInit, OnDestroy {
  public optedInToNewBillingPortal = true;
  public isCompleteGroup = isCompleteGroup;
  private destroy$$ = new Subject<null>();
  private dialogRef: MatDialogRef<any>;
  @ViewChild('modal', { static: true }) private modal: TemplateRef<any>;
  private _searchTerm: string = null;
  public searchResults$: Observable<SearchResult[]>;
  public loading$: Observable<boolean>;

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public set searchTerm(term: string) {
    this._searchTerm = term;
    this.search.search(this._searchTerm);
  }

  constructor(
    private search: OrderUniqueIdSearchService,
    private matDialog: MatDialog,
    public userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.searchResults$ = this.search.searchResults$;
    this.loading$ = this.search.loading$;
    this.search.open$.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      if (this.dialogRef) {
        return;
      }

      this.dialogRef = this.matDialog.open(this.modal);
      this.dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.searchTerm = null;
          this.dialogRef = null;
        });
    });
  }

  ngOnDestroy() {
    this.search.search(null);
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public trackBy(_index: number, item: SearchResult): number {
    return item.id;
  }

  public close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  public onKeyDown() {
    if (this.userService.isLMOAccount() || this.userService.isDispatcherAccount()) {
      this.search.open();
    }
  }

  public async goToFirstEntry() {
    const results = await this.searchResults$.pipe(take(1)).toPromise();
    if (results.length) {
      const first = results[0];
      if (this.optedInToNewBillingPortal && first.orderStatus === 'completed') {
        this.router.navigate(['/', 'invoicing', 'order', first.id]);
      } else {
        this.router.navigate(first.routerLink);
      }
      this.close();
    }
  }
}
