import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, UserContract } from '../models/user';
import { CacheKeys } from '../constants/cache-keys';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';

export const roles = Object.freeze({
  admin: 1,
  default: 2,
  phone: 3,
  viewOnly: 4,
  crewLeader: 7,
  sandCoordinator: 9,
  dispatcher: 10,
});

export const accountTypes = Object.freeze({
  shaleapps: 1,
  lmo: 2,
  truckingVendor: 3,
  nonTruckingVendor: 4,
  sandVendor: 8,
});

export type accountTypesFriendlyStrings = keyof typeof accountTypes;

const HAL_IDS = [574, 59, 598, 466, 431, 567, 570];
const API_USERS = [574, 59, 598, 466, 431, 567, 570];
const LOHI_ACCOUNT = 'LoHi Logistics';

@Injectable()
export class UserService {
  constructor(private localStorage: LocalStorageService, private http: HttpClient) {}

  getUserFromCache(): User {
    return this.localStorage.getItem(CacheKeys.user);
  }

  storeUserInCache(user: Partial<User>): void {
    this.localStorage.setItem(CacheKeys.user, user);
  }

  storeSSOEmailInCache(user: Partial<User>): void {
    this.localStorage.setItem(CacheKeys.ssoEmail, user.email);
  }

  getSSOEmailFromCache(): User {
    return this.localStorage.getItem(CacheKeys.ssoEmail);
  }

  removeUserFromCache(): void {
    this.localStorage.removeItem(CacheKeys.user);
  }

  getUserContractFromCache(): UserContract {
    return this.localStorage.getItem(CacheKeys.userContract) || { account: null };
  }

  storeUserContractInCache(userContract): void {
    this.localStorage.setItem(CacheKeys.userContract, userContract);
  }

  canEditWell(): boolean {
    const { account } = this.getUserContractFromCache();
    const allowedToEdit = [roles.admin, roles.default, roles.dispatcher];
    return account && account.role && allowedToEdit.includes(account.role.id);
  }

  hasPermission(permission): boolean {
    const { permissions } = this.getUserContractFromCache();
    return permissions && permissions.length && permissions.indexOf(permission) > -1;
  }

  permissions(): string[] {
    const { permissions } = this.getUserContractFromCache();
    return permissions;
  }

  isHalAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && HAL_IDS.includes(account.id);
  }

  isAPIUser() {
    const { account } = this.getUserContractFromCache();
    return account && API_USERS.includes(account.id);
  }

  isShaleAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.type && account.type.id === accountTypes.shaleapps;
  }

  isLMOAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.type && account.type.id === accountTypes.lmo;
  }

  isDispatcherAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.type && account.type.id === accountTypes.truckingVendor;
  }

  isSandmanAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.type && account.type.id === accountTypes.sandVendor;
  }

  isStandardUser(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.default;
  }

  isBrokerageAccount(): boolean {
    const { account } = this.getUserContractFromCache();
    return account?.isBrokerage;
  }

  isCDLRequired(): boolean {
    const { account } = this.getUserContractFromCache();
    return account?.isCDLRequired;
  }

  canShowWells(): boolean {
    const { account } = this.getUserContractFromCache();
    const canSeeWells = [accountTypes.lmo, accountTypes.nonTruckingVendor];
    if (account && account.type) {
      return canSeeWells.includes(account.type.id);
    }
    return false;
  }

  userId(): number {
    const user = this.getUserContractFromCache();
    return user && user.id;
  }

  accountId(): number {
    const user = this.getUserContractFromCache();
    if (!user) {
      return null;
    }
    const { account } = user;
    if (!account) {
      return null;
    }
    return account.id;
  }

  isNexTierAccount(): boolean {
    const accountId = this.accountId();
    if (accountId) {
      return accountId === 44;
    } else {
      return false;
    }
  }

  isAPLogistics(): boolean {
    const accountId = this.accountId();
    if (accountId) {
      return accountId === 458;
    } else {
      return false;
    }
  }

  isSandRev(): boolean {
    const accountId = this.accountId();
    if (accountId) {
      return accountId === 714;
    } else {
      return false;
    }
  }

  isOmmaLMO(): boolean {
    const user = this.getUserContractFromCache();
    if (!user) {
      return null;
    }
    const { account } = user;
    if (!account) {
      return null;
    }
    return account.name === 'Omma LMO';
  }

  isAdmin(): boolean {
    const { account } = this.getUserContractFromCache();
    return (account && account.role && account.role.id === roles.admin) || this.isDispatcher();
  }

  isDefault(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.default;
  }

  isViewOnly(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.viewOnly;
  }

  isCrewLeader(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.crewLeader;
  }

  isDispatcher(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.dispatcher;
  }

  isSandCoordinator(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.role && account.role.id === roles.sandCoordinator;
  }

  isLoHi(): boolean {
    const { account } = this.getUserContractFromCache();
    return account && account.name && account.name.toLocaleLowerCase() === LOHI_ACCOUNT.toLocaleLowerCase();
  }

  removeUserContractFromCache(): void {
    this.localStorage.removeItem(CacheKeys.userContract);
  }

  getLabel(): { name: string; email: string; account: string } {
    const user: User = this.getUserContractFromCache();
    const label = {
      name: undefined,
      email: undefined,
      account: undefined,
    };

    if (user) {
      if (user.email) {
        label.email = user.email;
      }

      if (user.name) {
        label.name = user.name;
      }

      if (user.account) {
        label.account = user.account.name;
      }
    }

    return label;
  }

  isShaleappsEmail(): boolean {
    const email = this.getLabel().email;
    return (
      email &&
      (email.endsWith('@shaleapps.com') ||
        email.endsWith('@shaleapps.com_stage') ||
        email.endsWith('@shaleapps.com_dev') ||
        email.endsWith('@vorto.ai') ||
        email.endsWith('@vorto.ai_stage') ||
        email.endsWith('@vorto.ai_dev'))
    );
  }

  isVortoUser(): boolean {
    const email = this.getLabel().email;
    return (
      email && (email.endsWith('@vorto.ai') || email.endsWith('@vorto.ai_stage') || email.endsWith('@vorto.ai_dev'))
    );
  }

  isSubContractor(): boolean {
    return (
      this.getUserContractFromCache() &&
      this.getUserContractFromCache().account &&
      this.getUserContractFromCache().account.role &&
      (this.getUserContractFromCache().account.role.name === 'subcontractor-admin' ||
        this.getUserContractFromCache().account.role.id === 3)
    );
  }

  isDetmarEmail(): boolean {
    const email = this.getLabel().email;
    return (
      email.endsWith('@detmarlogistics.com') ||
      email.endsWith('@detmarlogistics.com_stage') ||
      email.endsWith('@detmarlogistics.com_dev')
    );
  }

  isFullAuto(): boolean {
    const user: User = this.getUserContractFromCache();
    return user.account && user.account.maxAutopilotLevel === 'full';
  }

  isSemiAuto(): boolean {
    const user: User = this.getUserContractFromCache();
    return user.account && user.account.maxAutopilotLevel === 'semi';
  }

  canSelectBrokerSubcontractors(): boolean {
    const user: User = this.getUserContractFromCache();
    return user.account && user.account.canSelectBrokerSubcontractors;
  }

  updatePassword$(password: string): Observable<any> {
    const user: User = this.getUserContractFromCache();
    return this.http.post(`${environment.api}/user/first_login_reset_password`, {
      email: user.email,
      password: password,
    });
  }

  dataLockEnabled(): boolean {
    const user: User = this.getUserContractFromCache();
    return user.account && user.account.dataLockEnabled;
  }
}
