<div class="user-detail-view-container">
  <div class="header">
    <ng-container>
      <span *ngIf="!_user?.id">New User</span>
      <span *ngIf="_user?.id">Edit User</span>
    </ng-container>
    <i class="material-icons" saAutomatedSelector="user-group__close-button" (click)="closeClicked()">close</i>
  </div>
  <div class="detail-content">
    <div class="content-header">User Info</div>

    <div class="user-inputs-container">
      <ng-container *ngFor="let field of editableUserFields; let i = index">
        <sa-detail-view-input
          #detailInput
          [label]="field.label"
          [type]="field.type"
          [value]="_user[field.fieldName]"
          [optional]="field.optional"
          (onValueUpdated)="updateUser(field.fieldName, $event, i)"
        >
        </sa-detail-view-input>
      </ng-container>

      <div class="detail-view-input-container">
        <div class="detail-view-input">
          <label>Role</label>
          <select [(ngModel)]="_user.role" placeholder="Select Role" (ngModelChange)="changePermission()">
            <option *ngFor="let role of roles | featureFlag | async" [ngValue]="role.server">{{ role.value }}</option>
          </select>
        </div>
        <ng-container *ngIf="!userService.isSandmanAccount()">
          <div class="help-text" *ngIf="isTruckingVendor">
            Admin users have access to all wells, can create wells, and can invite new users. Standard and View Only
            users only have access to wells where they are added to the “Subscribed Users” list.
          </div>
          <div class="help-text" *ngIf="!isTruckingVendor">
            <strong>Admin</strong> users can login to sand.shaleapps.com to invite new users, remove users, and view
            your list of drivers. Admin users can also dispatch and edit orders via the SANDi mobile app.<br />
            <strong>Standard</strong> users can login to the SANDi mobile app to dispatch drivers and edit orders.<br />
            <strong>View Only</strong> users can login to the SANDi app, but cannot dispatch or edit orders.
          </div>
        </ng-container>
      </div>
      <ng-container>
        <div class="spacer">
          <mat-divider></mat-divider>
        </div>
        <div class="detail-view-input-container">
          <div class="detail-view-input">
            <label>Segment</label>
            <select [(ngModel)]="_user.segment" placeholder="Select Segment" (ngModelChange)="changeSegment()">
              <option *ngFor="let segment of segments$ | async" [ngValue]="segment.id">{{
                segment.name | titlecase
              }}</option>
            </select>
          </div>
        </div>
      </ng-container>
      <div class="spacer">
        <mat-divider></mat-divider>
      </div>
      <ng-container *ngIf="permissions?.length > 0">
        <mat-form-field class="other-permissions">
          <mat-label>Additional User Permissions</mat-label>
          <mat-select [formControl]="permissionsFormControl" multiple>
            <mat-option *ngFor="let permission of permissions" [value]="permission">{{
              getPermissionPrettyName(permission)
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <div class="detail-view-input-container" *ngIf="!_user?.id" style="margin-top: 24px;">
        <div class="detail-view-input">
          <label>Password</label>
          <input
            type="text"
            saAutomatedSelector="user-creation__password"
            #password
            placeholder="Password"
            [(ngModel)]="_user.password"
            minlength="6"
          />
        </div>
        <span class="detail-input-help" [class.detail-input-error]="showPasswordError()">
          minimum 6 characters
        </span>
      </div>
    </div>

    <ng-container *ngIf="_user?.id">
      <div class="content-header">Danger Zone</div>
      <div class="danger-zone">
        Removing this user will prevent them from having access to the {{ _user?.account.name || 'current' }} account.
      </div>

      <button
        type="button"
        sa-danger-button
        saAutomatedSelector="user-creation__remove-button"
        (click)="removeUser(_user?.id)"
      >
        Remove User
      </button>
    </ng-container>

    <ng-container *ngIf="!_user?.id">
      <button
        type="button"
        saAutomatedSelector="user-creation__invite-button"
        sa-button
        (click)="inviteUser()"
        [disabled]="buttonDisabled || !isFormValid()"
      >
        Invite User
      </button>
    </ng-container>
  </div>
</div>
