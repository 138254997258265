<sa-nav [visible]="displayNav$$ | async" (onMenuClose)="displayNav$$.next(false)"></sa-nav>
<div class="h-screen flex flex-col">
  <div class="flex justify-between p-2 mb-5">
    <div>
      <img src="../../../assets/icons/menu-24.svg" (click)="displayNav$$.next(true)" />
    </div>
    <div><img class="w-3 h-3 ml-16" src="../../../assets/icons/logo.svg" /></div>
    <div>
      {{ userLabel.email || userLabel.name || 'Unknown' }}
    </div>
  </div>

  <div class="flex flex-row justify-between items-center px-2">
    <h2>Up For Grabs Guardrails</h2>
    <mat-slide-toggle [formControl]="showWaitingDriversControl" color="primary"
      >Show Waiting Driver Counts</mat-slide-toggle
    >
  </div>

  <div *ngIf="regions$ | async; let regions" class="w-full flex flex-row justify-between px-2">
    <div class="flex flex-row gap-1 cursor-pointer text-blue-700" (click)="adjustTimeRangeMonths(-1)">
      <i class="material-icons">arrow_back</i>
      <span>Back One Month</span>
    </div>
    <div class="region-select">
      <mat-form-field>
        <mat-select [formControl]="regionControl">
          <mat-option *ngFor="let region of regions" [value]="region.regionId">{{ region.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex flex-row gap-1 cursor-pointer text-blue-700" (click)="adjustTimeRangeMonths(1)">
      <span>Forward One Month</span>
      <i class="material-icons">arrow_forward</i>
    </div>
  </div>

  <div *ngIf="regions$ | async" class="flex flex-row flex-1">
    <div class="axis-label-container relative h-full">
      <div
        class="axis-label overflow-visible"
        [class.up-for-grabs-label]="showWaitingDrivers$ | async"
        [class.center-label]="!(showWaitingDrivers$ | async)"
      >
        Pending Load Percent
      </div>
      <div *ngIf="showWaitingDrivers$ | async" class="axis-label waiting-drivers-label overflow-visible">
        Waiting Driver Percent
      </div>
    </div>
    <div class="relative h-full flex-1" (mousemove)="checkDrag($event)" (mouseup)="endDrag($event)">
      <div
        *ngFor="let line of chartLimitLines$$ | async"
        class="absolute bg-blue-700 w-full z-50"
        [class.bg-red-500]="line.dragging"
        [style.width.px]="line.widthPixels"
        [style.left.px]="line.xPixels"
        [style.top.px]="line.yPixels - 2"
        [style.height.px]="4"
      >
        <div
          class="absolute rounded-full bg-blue-700 z-50 flex flex-col items-center justify-center align-middle"
          [class.bg-red-500]="line.dragging"
          [class.draggable]="line.draggable"
          [style.left.px]="0"
          [style.top.px]="-16"
          [style.width.px]="32"
          [style.height.px]="32"
          (mousedown)="startDrag($event, line)"
        >
          <i *ngIf="!line.draggable" class="material-icons text-white select-none">lock</i>
          <i *ngIf="line.draggable" class="material-icons text-white select-none">drag_handle</i>
        </div>
        <div
          class="absolute flex flex-row justify-center"
          [style.left.px]="0"
          [style.top.px]="-20"
          [style.width.px]="line.widthPixels"
          [style.height.px]="18"
        >
          <div>
            {{ line.value > 0 ? line.value : -line.value }}
          </div>
        </div>
      </div>
      <div *ngIf="chart$$ | async; let chart" class="h-full z-0" [chart]="chart"></div>
    </div>
  </div>
</div>
