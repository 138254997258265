import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';

export interface IDName {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserSegmentsService {
  private segments$$ = new BehaviorSubject<IDName[]>([]);

  public get segments$(): Observable<IDName[]> {
    return this.segments$$.asObservable();
  }

  constructor(private http: HttpClient) {
    this.loadSegments();
  }

  private loadSegments() {
    this.http.get<IDName[]>(`${environment.api}/user/segments`).subscribe((segments) => {
      this.segments$$.next(segments);
    });
  }
}
