import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module/material.module';
import { AutomatedSelectorDirective } from './automated-selector.directive';
import { components, entryComponents } from './components';
import { DispatcherRoutingModule } from './dispatcher-routing.module';

@NgModule({
  declarations: [...components, AutomatedSelectorDirective],
  entryComponents,
  exports: [...components, AutomatedSelectorDirective],
  imports: [CommonModule, DispatcherRoutingModule, MaterialModule, DragDropModule, FormsModule, ReactiveFormsModule],
})
export class DispatcherModule {}
