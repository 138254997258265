import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud.service';

@Injectable()
export class AccountApiService {
  get truckingVendorAccountRoute(): string {
    return environment.api + '/account/trucking-vendor';
  }
  get createAccountRoute(): string {
    return environment.api + '/account';
  }
  get inviteAccountRoute(): string {
    return environment.api + '/account/invite';
  }
  get validateInviteRoute(): string {
    return environment.api + '/account/validate_invite';
  }
  get resetPasswordRoute(): string {
    return environment.api + '/user/reset_password';
  }
  get validateResetPasswordRoute(): string {
    return environment.api + '/user/validate_reset_password';
  }
  get changePasswordRoute(): string {
    return environment.api + '/user/change_password';
  }

  get userAccountRoute(): string {
    return environment.api + '/user';
  }

  constructor(private crud: CrudService) {}

  getTruckingVendorAccounts() {
    return this.crud.get(this.truckingVendorAccountRoute);
  }

  registerAccount(accountForm) {
    return this.crud.postWithoutAuth(this.createAccountRoute, accountForm);
  }

  inviteAccount(inviteForm) {
    return this.crud.post(this.inviteAccountRoute, inviteForm);
  }

  createAccount(createForm) {
    return this.crud.post(this.createAccountRoute, createForm);
  }

  validateInvite(validationForm) {
    return this.crud.postWithoutAuth(this.validateInviteRoute, validationForm);
  }

  resetPassword(resetPasswordForm) {
    return this.crud.postWithoutAuth(this.resetPasswordRoute, resetPasswordForm);
  }

  validateResetPassword(validationForm) {
    return this.crud.postWithoutAuth(this.validateResetPasswordRoute, validationForm);
  }

  changePassword(form) {
    return this.crud.postWithoutAuth(this.changePasswordRoute, form);
  }

  getVendorProfile(id) {
    return this.crud.get(this.createAccountRoute + '/' + id + '/profile');
  }

  updateVendorProfile(body) {
    return this.crud.put(this.createAccountRoute + '/profile', body);
  }

  getUserAccount(id) {
    return this.crud.get(this.userAccountRoute + '/' + id + '/account');
  }
}
