import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, share, switchMap, take } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { WellApiService } from './api/well.api.service';
import { NavigationEnd, Router } from '@angular/router';

interface IdAndName {
  id: number;
  name: string;
}

export interface Well extends IdAndName {
  fracMines: IdAndName[];
}

@Injectable()
export class WellService {
  private requestIdInProgress: number = null;
  private billingDetails$$ = new BehaviorSubject<any>(null);
  private allFracs$$ = new BehaviorSubject<any>([]);
  private orderedAfterFilter$$ = new BehaviorSubject<any>(null);
  private orderCounts$$ = new BehaviorSubject<any>([]);
  private selectedFracs$$ = new BehaviorSubject<any>([]);
  private countAPISub: Subscription;

  public get billingDetails$(): Observable<any> {
    return this.billingDetails$$.asObservable().pipe(
      filter((well) => !!well),
      distinctUntilChanged(),
      share(),
    );
  }

  public get orderedAfterFilter$(): Observable<any> {
    return this.orderedAfterFilter$$.asObservable().pipe(
      distinctUntilChanged(),
      share(),
    );
  }

  public get allFracs$(): Observable<any> {
    return this.allFracs$$.asObservable().pipe(
      filter((well) => !!well),
      distinctUntilChanged(),
      share(),
    );
  }

  public get orderCounts$(): Observable<any> {
    return this.orderCounts$$.asObservable().pipe(
      distinctUntilChanged(),
      share(),
    );
  }

  public get selectedFracs$(): Observable<any> {
    return this.selectedFracs$$.asObservable().pipe(
      filter((well) => !!well),
      distinctUntilChanged(),
      share(),
    );
  }

  constructor(private crudService: CrudService, private wellApiService: WellApiService, private router: Router) {}

  subscribeToRoute() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.getOrderCounts();
    });
  }

  setValue(well: any): void {
    this.billingDetails$$.next(well);
  }

  getOrderCounts() {
    this.orderCounts$$.next(null);
    if (this.countAPISub) {
      this.countAPISub.unsubscribe();
    }
    this.countAPISub = this.wellApiService
      .getAllOrderCounts({
        fracIds: this.selectedFracs$$.value.map((frac) => frac.id).join(),
        orderedAfter:
          (this.orderedAfterFilter$$.value && new Date(this.orderedAfterFilter$$.value).toISOString()) || '',
      })
      .subscribe((counts) => {
        this.orderCounts$$.next(counts);
      });
  }

  setAllFracs(well: any): void {
    const fracs = [];
    const vendors = [];
    (well || []).forEach((selectedWell) => {
      const index = fracs.findIndex((frac) => frac.id === selectedWell.id);
      if (index > -1) {
        if (fracs[index].vendors && fracs[index].vendors.length) {
          fracs[index].vendors.push({ vendorId: selectedWell.vendorId, vendorName: selectedWell.vendorName });
        } else {
          fracs[index].vendors = [{ vendorId: selectedWell.vendorId, vendorName: selectedWell.vendorName }];
        }
      } else {
        fracs.push({
          id: selectedWell.fracId,
          name: selectedWell.fracName,
          vendors: [{ vendorId: selectedWell.vendorId, vendorName: selectedWell.vendorName }],
        });
      }
    });

    this.allFracs$$.next(fracs);
  }

  setSelectedFracs(fracs: any): void {
    this.selectedFracs$$.next(fracs);
    this.getOrderCounts();
  }

  setOrderedAfterFilter(dateTime: any): void {
    this.orderedAfterFilter$$.next(dateTime);
  }

  // loadForBilling(id: number): void {
  //   // Don't request it twice
  //   if (this.requestIdInProgress === id) {
  //     return;
  //   }
  //   if (!this.billingDetails$$.value || this.billingDetails$$.value.id !== id) {
  //     // Set it so we don't repeat the requst multiple times
  //     this.requestIdInProgress = id;
  //     this.crudService.httpClientReady.pipe(
  //       filter(Boolean),
  //       take(1),
  //       switchMap(() => this.wellApiService.getWellForBilling(id))
  //     ).subscribe((well) => {
  //       this.billingDetails$$.next(well);
  //     }, () => undefined, () => this.requestIdInProgress = null);
  //   }
  // }
}
