import { Component, OnInit } from '@angular/core';
import { UserService } from '~services/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sa-top-nav-with-icon',
  templateUrl: './top-nav-with-icon.component.html',
  styleUrls: ['./top-nav-with-icon.component.scss'],
})
export class TopNavWithIconComponent implements OnInit {
  displayNav$$ = new BehaviorSubject<boolean>(false);
  public userLabel: { name: string; email: string; account: string };

  constructor(private userService: UserService) {
    this.userLabel = this.userService.getLabel();
  }

  ngOnInit() {}

  public menuClick() {
    this.displayNav$$.next(true);
  }
}
