<div (scroll)="onScroll($event)" class="scroll-container">
  <mat-form-field class="search-completed-orders">
    <input matInput placeholder="Search" autocomplete="off" [formControl]="completedOrdersSearch" />
  </mat-form-field>
  <h3
    class="no-order-placeholder"
    *ngIf="completedOrdersService.totalCompletedOrders === 0; else listAllCompletedOrders"
  >
    No Orders
  </h3>
  <ng-template #listAllCompletedOrders>
    <ng-container *ngFor="let orderSummary of virtualScroll | async; trackBy: trackByFn">
      <h3 class="sub-header" *ngIf="orderSummary.isHeaderItem; else individualOrderSummary">
        {{ orderSummary.name }}
      </h3>
      <ng-template #individualOrderSummary>
        <sa-order-summary [isCompleted]="orderSummary.isCompleted" [orderSummary]="orderSummary"></sa-order-summary>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
