<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Enter Benchmark Price ($/mile)</mat-label>
    <input
      matInput
      type="number"
      onkeyup="if(this.value < 0) {this.value = this.value * -1}"
      [(ngModel)]="data.benchmark"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex items-center">
  <button mat-button [mat-dialog-close]="data.benchmark" class="w-full">Set Price</button>
</div>
