import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { UserService } from '~services/user.service';
import { Chart } from 'angular-highcharts';
import { debounceTime, map, shareReplay, startWith, take } from 'rxjs/operators';
import * as moment from 'moment';
import { AnnotationsLabelOptions, AnnotationsOptions, SeriesOptionsType } from 'highcharts';
import {
  DynamicPricingForecastService,
  DynamicPricingThresholds,
  ForecastData,
  ForecastDataMonth,
  ForecastDataMonthly,
  ForecastDataWeek,
  ForecastParameters,
  MonthlyForecastParameters,
} from './dynamic-pricing-forecast.service';
import { DynamicPricingRegion, DynamicPricingService, Regions } from '~services/api/dynamic-pricing.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

interface ChartLimitLine {
  value: number;
  xPixels: number;
  yPixels: number;
  widthPixels: number;
  dragging: boolean;
  draggable: boolean;
  dataMonth: ForecastDataMonth;
  isUpForGrabsLoads: boolean;
  minValue: number;
  maxValue: number;
  originalThresholds: DynamicPricingThresholds;
}

@Component({
  selector: 'sa-dynamic-pricing-forecast',
  templateUrl: './dynamic-pricing-forecast.component.html',
  styleUrls: ['./dynamic-pricing-forecast.component.scss'],
})
export class DynamicPricingForecastComponent implements OnInit, OnDestroy {
  private chartData$: Observable<ForecastDataMonthly>;
  private thresholds$: Observable<DynamicPricingThresholds[]>;
  public regions$: Observable<DynamicPricingRegion[]>;
  public parameters$: Observable<ForecastParameters>;
  public monthlyParameters$: Observable<MonthlyForecastParameters>;

  public displayNav$$ = new BehaviorSubject<boolean>(false);
  public userLabel: { name: string; email: string; account: string };

  private loading$$ = new BehaviorSubject<boolean>(true);
  public loading$ = this.loading$$.pipe(shareReplay(1));

  private chart: Chart;
  public chart$$ = new BehaviorSubject<Chart>(null);

  private chartElements: Highcharts.SVGElement[] = [];
  public chartLimitLines$$ = new BehaviorSubject<ChartLimitLine[]>([]);

  private draggingLine: ChartLimitLine = null;
  private dragStartScreenYPixels: number;
  private dragStartPlotYPixels: number;
  private dragStartValue: number;

  public regionControl: FormControl;
  public showWaitingDriversControl: FormControl;

  public showWaitingDrivers$: Observable<boolean>;

  private subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private forecastService: DynamicPricingForecastService,
    private dynamicPricingService: DynamicPricingService,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
  ) {
    this.chartData$ = this.forecastService.monthlyForecastData$;
    this.thresholds$ = this.forecastService.dynamicPricingThresholds$;
    this.regions$ = this.dynamicPricingService.getConnectedRegions$().pipe(map((regions) => regions.regions));
    this.parameters$ = this.forecastService.forecastParameters$;
    this.monthlyParameters$ = this.forecastService.monthlyForecastParameters$;
    this.userLabel = this.userService.getLabel();

    this.regionControl = new FormControl();
    this.showWaitingDriversControl = new FormControl(false);

    this.showWaitingDrivers$ = this.showWaitingDriversControl.valueChanges.pipe(startWith(false));

    this.parameters$.pipe(take(1)).subscribe((parameters) => {
      if (!parameters) {
        this.regions$.pipe(take(1)).subscribe((regions) => {
          const today = moment().startOf('day');
          const startOfWeek = today.clone().startOf('isoWeek');
          const previousWeek = startOfWeek.clone().subtract(1, 'week');
          const endMoment = previousWeek.clone().add(4, 'week');

          this.forecastService.setParameters(regions[0].regionId, previousWeek.toDate(), endMoment.toDate());
          this.forecastService.setMonthlyParameters(regions[0].regionId, today.year(), today.month() + 1);
          this.regionControl.setValue(regions[0].regionId);
        });
      } else {
        this.regionControl.setValue(parameters.regionId);
      }
    });

    this.subscriptions.push(
      combineLatest([this.regionControl.valueChanges, this.parameters$]).subscribe(([regionId, parameters]) => {
        if (parameters && parameters.regionId !== regionId) {
          this.forecastService.setParameters(regionId, parameters.startDate, parameters.endDate);
        }
      }),
    );

    this.subscriptions.push(
      combineLatest([this.regionControl.valueChanges, this.monthlyParameters$]).subscribe(([regionId, parameters]) => {
        if (parameters && parameters.regionId !== regionId) {
          this.forecastService.setMonthlyParameters(regionId, parameters.year, parameters.month);
        }
      }),
    );

    // hack to force the chart to redraw on resize, which is not happening automatically for some reason
    this.subscriptions.push(
      fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => {
          this.chart$$.next(null);
          setTimeout(() => {
            this.chart$$.next(this.chart);
          });
        }),
    );
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.chartData$, this.thresholds$, this.showWaitingDrivers$, this.regions$]).subscribe(
        ([data, thresholds, showWaitingDrivers, regions]) => {
          if (data && regions && thresholds) {
            this.loadChartData(data, thresholds, showWaitingDrivers);
            this.loading$$.next(false);
          }
        },
      ),
    );
  }

  public adjustTimeRangeMonths(months: number) {
    if (this.loading$$.getValue()) {
      return;
    }

    this.monthlyParameters$.pipe(take(1)).subscribe((parameters) => {
      if (parameters) {
        let newStartMonth = parameters.month + months;
        let newStartYear = parameters.year;

        if (newStartMonth < 1) {
          newStartMonth = 12;
          newStartYear -= 1;
        } else if (newStartMonth > 12) {
          newStartMonth = 1;
          newStartYear += 1;
        }

        this.loading$$.next(true);
        this.forecastService.setMonthlyParameters(parameters.regionId, newStartYear, newStartMonth);
      }
    });
  }

  public adjustTimeRangeWeeks(weeks: number) {
    if (this.loading$$.getValue()) {
      return;
    }

    this.parameters$.pipe(take(1)).subscribe((parameters) => {
      if (parameters) {
        const newStartDate = moment(parameters.startDate)
          .add(weeks, 'week')
          .toDate();
        const newEndDate = moment(parameters.endDate)
          .add(weeks, 'week')
          .toDate();
        this.loading$$.next(true);
        this.forecastService.setParameters(parameters.regionId, newStartDate, newEndDate);
      }
    });
  }

  private loadChartData(
    data: ForecastDataMonthly,
    thresholds: DynamicPricingThresholds[],
    showWaitingDriverCounts: boolean,
  ) {
    let monthStartIndex = 0;
    const xAxisLabels = [];
    const pendingLoadsData = [];
    const waitingDriversData = [];
    const plotLines = [];
    const monthLabels = [];

    for (const month of data.months) {
      for (const day of month.days) {
        xAxisLabels.push(day.date);

        let pendingLoadPercent = day.pendingLoadCount / day.totalLoadCount;
        if (day.totalLoadCount === 0) {
          if (day.pendingLoadCount > 0) {
            pendingLoadPercent = 1.0;
          } else {
            pendingLoadPercent = 0.0;
          }
        }
        pendingLoadPercent = Math.max(0.0, Math.min(1.0, pendingLoadPercent));

        let pendingShiftPercent = day.pendingShiftCount / day.totalShiftCount;
        if (day.totalShiftCount === 0) {
          if (day.pendingShiftCount > 0) {
            pendingShiftPercent = 1.0;
          } else {
            pendingShiftPercent = 0.0;
          }
        }
        pendingShiftPercent = Math.max(0.0, Math.min(1.0, pendingShiftPercent));

        pendingLoadPercent = Math.round(pendingLoadPercent * 1000) / 10;
        pendingShiftPercent = Math.round(pendingShiftPercent * 1000) / 10;

        if (day.isForecasted) {
          pendingLoadsData.push({
            y: pendingLoadPercent,
            color: '#ffcccb',
          });
          waitingDriversData.push({
            y: -pendingShiftPercent,
            color: '#dcdcdc',
          });
        } else {
          pendingLoadsData.push({
            y: pendingLoadPercent,
            color: '#f1807e',
          });
          waitingDriversData.push({
            y: -pendingShiftPercent,
            color: '#c0c0c0',
          });
        }
      }

      plotLines.push({
        value: monthStartIndex - 0.5,
        color: '#000000',
      });

      const monthString = moment(month.days[1].date).format('MMMM YYYY');
      let monthLabel = `<span style="font-size: 16px;">${monthString}</span>`;

      if (month.dynamicPricingPercent !== undefined && month.dynamicPricingPercent !== 0) {
        const dynamicPricing = Math.round(month.dynamicPricingPercent);

        let color = 'black';
        let dpString = `${dynamicPricing}%`;
        if (dynamicPricing < 0) {
          color = 'green';
        } else if (dynamicPricing > 0) {
          color = 'red';
          dpString = `+${dpString}`;
        }

        monthLabel += `<br/><span style="font-size: 16px; color: ${color};">${dpString}</span>`;
      }

      monthLabels.push({
        text: monthLabel,
      });

      monthStartIndex += month.days.length;
    }

    plotLines.push({
      value: monthStartIndex - 0.5,
      color: '#000000',
    });

    const max = 100;

    const min = showWaitingDriverCounts ? -max : 0;

    const chartSeries: SeriesOptionsType[] = [
      {
        name: 'Pending Load %',
        type: 'column',
        yAxis: 0,
        data: pendingLoadsData,
        showInLegend: false,
        tooltip: {
          pointFormatter: function() {
            let prefix = '';
            if (this.color === '#ffcccb') {
              prefix = 'Forecasted ';
            }
            return `${prefix}${this.series.name}: <b>${this.y}</b>`;
          },
        },
        animation: false,
      },
    ];

    if (showWaitingDriverCounts) {
      chartSeries.push({
        name: 'Waiting Driver %',
        type: 'column',
        yAxis: 0,
        data: waitingDriversData,
        showInLegend: false,
        tooltip: {
          pointFormatter: function() {
            let prefix = '';
            if (this.color === '#dcdcdc') {
              prefix = 'Forecasted ';
            }
            return `${prefix}${this.series.name}: <b>${-this.y}</b>`;
          },
        },
        animation: false,
      });
    }

    this.chart = new Chart({
      chart: {
        type: 'scatter',
        events: {
          render: async () => {
            for (const element of this.chartElements) {
              element.destroy();
            }

            this.chartElements = [];

            let plotWidthFactor = 1 / 6;
            const c = this.chart.ref;

            for (const monthLabel of monthLabels) {
              const attr = {
                zIndex: 10,
                x: c.plotLeft + c.plotWidth * plotWidthFactor,
                y: c.plotTop + 20,
              };

              const el = c.renderer
                .text(monthLabel.text)
                .attr(attr)
                .css({ 'text-anchor': 'middle' })
                .add();

              this.chartElements.push(el);

              plotWidthFactor += 1 / 3;
            }

            // set up limits
            const chartLimitLines: ChartLimitLine[] = [];
            monthStartIndex = 0;

            const parameters = await this.monthlyParameters$.pipe(take(1)).toPromise();

            for (let i = 0; i < data.months.length; ++i) {
              const month = data.months[i];
              const startDate = moment(month.days[0].date, 'YYYY-MM-DD');

              const monthThresholds = this.forecastService.getThreshold(
                parameters.regionId,
                startDate.format('YYYY-MM-DD'),
              );

              if (
                (monthThresholds?.pendingLoadCountThreshold === null ||
                  monthThresholds?.pendingDriverCountThreshold === null) &&
                month.isHistorical
              ) {
                monthStartIndex += month.days.length;
                continue;
              }

              const xPixels = c.xAxis[0].toPixels(monthStartIndex - 0.5, false);
              const widthPixels =
                c.xAxis[0].toPixels(monthStartIndex + month.days.length - 0.5, false) -
                c.xAxis[0].toPixels(monthStartIndex - 0.5, false);

              const upForGrabsThreshold = monthThresholds?.pendingLoadCountThreshold ?? 100;
              const waitingDriversThreshold = monthThresholds?.pendingDriverCountThreshold ?? 100;

              if (!month.isHistorical) {
                chartLimitLines.push({
                  value: upForGrabsThreshold,
                  xPixels,
                  yPixels: c.yAxis[0].toPixels(Math.min(upForGrabsThreshold, max), false),
                  widthPixels,
                  dragging: false,
                  draggable: !month.isHistorical,
                  dataMonth: month,
                  minValue: 0,
                  maxValue: max,
                  isUpForGrabsLoads: true,
                  originalThresholds: monthThresholds,
                });

                if (showWaitingDriverCounts) {
                  chartLimitLines.push({
                    value: Math.abs(waitingDriversThreshold),
                    xPixels,
                    yPixels: c.yAxis[0].toPixels(-Math.abs(Math.min(waitingDriversThreshold, max)), false),
                    widthPixels,
                    dragging: false,
                    draggable: !month.isHistorical,
                    dataMonth: month,
                    minValue: min,
                    maxValue: 0,
                    isUpForGrabsLoads: false,
                    originalThresholds: monthThresholds,
                  });
                }
              }

              monthStartIndex += month.days.length;
            }

            setTimeout(() => {
              this.chartLimitLines$$.next(chartLimitLines);
            }, 1);
          },
        },
        animation: false,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      yAxis: [
        {
          title: {
            text: ' ',
            style: {
              fontWeight: 'bold',
            },
          },
          max: max,
          min: min,
          labels: {
            formatter: function() {
              if (typeof this.value === 'string') {
                return Math.abs(parseInt(this.value, 10)).toString();
              } else {
                return Math.abs(this.value).toString();
              }
            },
          },
          plotLines: [
            {
              value: 0,
              color: '#000000',
              width: 2,
            },
          ],
        },
      ],
      xAxis: {
        crosshair: true,
        labels: {
          enabled: false,
        },
        plotLines: plotLines,
        categories: xAxisLabels,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
      },
      series: chartSeries,
    });
    this.chart$$.next(this.chart);
  }

  public startDrag(event: MouseEvent, limitLine: ChartLimitLine) {
    if (!limitLine.draggable) {
      return;
    }

    this.draggingLine = limitLine;
    limitLine.dragging = true;
    this.dragStartScreenYPixels = event.clientY;
    this.dragStartPlotYPixels = limitLine.yPixels;
    this.dragStartValue = limitLine.value;
  }

  public checkDrag(event: MouseEvent) {
    if (!this.draggingLine) {
      return;
    }

    const deltaY = event.clientY - this.dragStartScreenYPixels;
    let newPlotY = this.dragStartPlotYPixels + deltaY;
    let newValue = this.chart.ref.yAxis[0].toValue(newPlotY, false);

    if (newValue > this.draggingLine.maxValue) {
      newValue = this.draggingLine.maxValue;
      newPlotY = this.chart.ref.yAxis[0].toPixels(newValue, false);
    } else if (newValue < this.draggingLine.minValue) {
      newValue = this.draggingLine.minValue;
      newPlotY = this.chart.ref.yAxis[0].toPixels(newValue, false);
    }

    this.draggingLine.yPixels = newPlotY;
    this.draggingLine.value = Math.round(newValue);

    this.cd.markForCheck();
  }

  public async endDrag(event: MouseEvent) {
    if (!this.draggingLine) {
      return;
    }

    this.draggingLine.dragging = false;

    this.draggingLine.yPixels = this.chart.ref.yAxis[0].toPixels(this.draggingLine.value, false);

    const line = this.draggingLine;

    this.draggingLine = null;

    this.cd.markForCheck();

    const parameters = await this.parameters$.pipe(take(1)).toPromise();

    const startDate = moment(line.dataMonth.days[0].date, 'YYYY-MM-DD');

    let upForGrabsThreshold = line.originalThresholds?.pendingLoadCountThreshold ?? 25;
    let waitingDriversThreshold = line.originalThresholds?.pendingDriverCountThreshold ?? 25;

    if (line.isUpForGrabsLoads) {
      upForGrabsThreshold = line.value;
    } else {
      waitingDriversThreshold = line.value;
    }

    const result = await this.forecastService.updateThresholds(
      parameters.regionId,
      startDate.format('YYYY-MM-DD'),
      upForGrabsThreshold,
      waitingDriversThreshold,
    );
    if (result) {
      if (line.isUpForGrabsLoads) {
        line.originalThresholds.pendingLoadCountThreshold = upForGrabsThreshold;
      } else {
        line.originalThresholds.pendingDriverCountThreshold = waitingDriversThreshold;
      }
      this.snackbar.open('Threshold updated successfully', null, {
        duration: 5000,
      });
    } else {
      this.snackbar.open('Failed to update threshold.', null, {
        duration: 5000,
        panelClass: ['snackbar-error'],
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
