import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagService } from '~services/feature-flag.service';

/*
 * Convert weights on the PO and loader analytics dashboards to the appropriate unit,
 * based on the shortTonsOnPOAndLoaderDashboards feature flag.
 */
@Pipe({
  name: 'poLoaderPoundTonConversion',
})
export class PoLoaderPoundTonConversionPipe implements PipeTransform {
  private isTons = false;

  constructor(private featureFlagService: FeatureFlagService) {
    this.featureFlagService.flags$.subscribe((flags) => {
      this.isTons = flags.shortTonsOnPOAndLoaderDashboards;
    });
  }

  transform(value: any, roundTons: boolean = true): number {
    if (this.isTons) {
      if (roundTons) {
        return Math.round(value / 2000);
      }

      return value / 2000;
    }

    return value;
  }
}
