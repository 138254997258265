import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  constructor() {}

  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    const re = new RegExp(args, 'gi');
    const match = value.match(re);

    if (!match) {
      return value;
    }

    return value.replace(re, '<strong>' + match[0] + '</strong>');
  }
}
