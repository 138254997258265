<div *ngIf="isSubcarrierListLoaded; else loading">
  <h1>Please assign a subcarrier to the driver</h1>
  <form [formGroup]="assignSubcarrierFormGroup" (ngSubmit)="assignSubcarrier()">
    <mat-form-field class="form-field w-full">
      <mat-label>Subcarrier</mat-label>
      <mat-select formControlName="subcarrier" placeholder="Select subcarrier">
        <mat-option
          *ngFor="let subcarrier of subcarriers$ | async; trackBy: subcarriers$ | trackByKey: 'id'"
          [value]="subcarrier.id"
        >
          {{ subcarrier.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="w-full flex justify-between">
      <button type="button" mat-flat-button color="warn" (click)="closeDialog()">
        <span class="text-white">Cancel</span>
      </button>
      <button type="submit" mat-flat-button color="primary">
        <span class="text-white">Save</span>
      </button>
    </div>
  </form>
</div>

<ng-template #loading>
  <div class="w-full flex justify-center">
    <mat-spinner diameter="32"></mat-spinner>
  </div>
</ng-template>
