import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WellApiService } from '~services/api/well.api.service';
import { ConstantsApiService } from '~services/api/constants.api.service';
import { UserApiService } from '~services/api/user.api.service';
import { SearchApiService } from '~services/api/search.api.service';
import { CrudService } from '~services/crud.service';
import { OrderApiService } from '~services/api/order.api.service';
import { UserService } from '~services/user.service';
import { WellService } from '~services/well.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { HelperService } from '~services/helper.service';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { TicketDialogComponent } from '../ticket-dialog/ticket-dialog.component';
import { ReasonDialogComponent } from '../reason-dialog/reason-dialog.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LOCAL_STORAGE_CONST } from '../../constants/local-storage-constants';
import { FormControl } from '@angular/forms';
import { ConfirmActionDialogComponent } from '../confirm-action-dialog/confirm-action-dialog.component';

const PAGE_SIZE = 100;
const PAGE_SIZE_INVOICE = 100;
const BILLING_STATUS = {
  notBilled: 'not_billed',
  approvedByDispatcher: 'approved_by_dispatcher',
  approvedByLMO: 'approved_by_lmo',
  billed: 'billed',
  approved: 'approved',
};
const TABS = {
  NEED_DATA_FOR_TICKETING: 'needDataForTicketing',
  WAITING_FOR_TICKET_APPROVAL: 'waitingForTicketApproval',
  READY_FOR_INVOICING: 'readyForInvoicing',
  WAITING_FOR_INVOICE_APPROVAL: 'waitingForInvoiceApproval',
  INVOICE_APPROVED: 'invoiceApproved',
};

interface Filters {
  isAutomated: boolean;
  isManual: boolean;
  pushback: boolean;
  attachmentFilter: boolean;
  dataMatchedFilter: boolean;
  fastTrackedFilter: boolean;
  needsManualApprovalPricing: boolean;
  needsManualApprovalTrip: boolean;
  needsManualApprovalBOL: boolean;
  needData: boolean;
  manuallyEdited: boolean;
  pageLimit: string;
  pageOffset: number;
  pageColumn: number;
  pageIndex: number;
  pageOrderBy: string;
  searchData: string;
  pushedBackInvoiceNumber: string;
  olderThanTwoDays: boolean;
}

interface StoredFilter {
  needDataForTicketing: Filters;
  waitingForTicketApproval: Filters;
  readyForInvoicing: Filters;
}

@Component({
  selector: 'sa-all-frac-order-table',
  templateUrl: './all-frac-order-table.component.html',
  styleUrls: ['./all-frac-order-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('125ms ease-in', style({ transform: 'translateX(-0%)' })),
      ]),
      transition(':leave', [animate('125ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('125ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('125ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AllFracOrderTableComponent implements OnInit, OnDestroy {
  completedOrders: any;
  ordersTooltips: any[] = [];
  completeInvoices: any;
  urlRoute = '';
  urlOrderId: number;
  detailView = false;
  selectedFracId = 0;
  openedFracId = 0;

  isAutomated = true;
  isManual = true;
  pushback = true;
  attachmentFilter = false;
  pushedBackInvoiceNumber = '';
  dataMatchedFilter = false;
  receivedByCustomer = false;
  olderThanTwoDays = false;
  fastTrackedFilter = false;
  needsManualApprovalPricing = true;
  needsManualApprovalTrip = true;
  needsManualApprovalBOL = true;
  orderedAfterFilter: any;

  needData = true;
  manuallyEdited = true;

  driverList: any;
  truckList: any;
  payloadList: any;

  allFracs: any[] = [];
  selectedFracs: any[] = [];

  isLmoAccount = false;
  pageLimit = 100;
  pageOffset = 0;
  pageIndex = 0;
  pageOffsetInvoice = 0;
  pageIndexInvoice = 0;
  pageLimitInvoice = 100;
  pageColumn = 'dispatchTimestamp';
  pageOrderBy = 'asc';
  orderCount = 0;
  invoiceCount = 0;
  searchData = '';
  invoices: any[];
  storedFilter: StoredFilter = {
    needDataForTicketing: {} as Filters,
    waitingForTicketApproval: {} as Filters,
    readyForInvoicing: {} as Filters,
  };
  isLoading = true;
  isSubLoading = false;
  exporting: boolean[] = [];
  customInput: Subject<string> = new Subject();
  activeDataSource = new MatTableDataSource([]);

  searchNumber = 0;
  displayedColumns = ['order', 'mine', 'dispatchTimestamp', 'mesh', 'loadWeight', 'cost', 'setting'];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('paginatorInvoice', { static: false }) paginatorInvoice: MatPaginator;
  completedOrderAPISub: Subscription;
  invoiceAPISub: Subscription;

  invoiceDataSource = new MatTableDataSource([]);
  columnsToDisplay = [];
  subTableColumns = [];
  truckingVendorsList = [];
  expandedElement: any;

  useShaleAppsInvoiceNumber = false;
  singleOrderPerInvoice = false;
  private destroy$ = new Subject();
  groupByInvoiceFilter = new FormControl(true);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.activeDataSource.data.length > 100 ? 100 : this.activeDataSource.data.length;
    return numSelected === numRows;
  }

  isAllInvoiceSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (this.invoiceDataSource.data) {
      numRows = this.invoiceDataSource.data.length > 100 ? 100 : this.invoiceDataSource.data.length;
    }
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.activeDataSource.data.forEach((row) => {
          if (row.loadNumber) {
            this.selection.select(row);
          }
        });
  }

  invoiceMasterToggle() {
    this.isAllInvoiceSelected()
      ? this.selection.clear()
      : this.invoiceDataSource.data.forEach((row) => {
          if (row && row.InvoiceNumber) {
            this.selection.select(row);
          }
        });
  }

  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private wellApiService: WellApiService,
    private constantsApiService: ConstantsApiService,
    private userAPIService: UserApiService,
    private searchAPIService: SearchApiService,
    private crud: CrudService,
    private orderApiService: OrderApiService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private userService: UserService,
    public wellService: WellService,
    private errorHandler: ErrorHandlingService,
    public helperService: HelperService,
  ) {}

  ngOnInit() {
    this.selectedFracId = this.route.firstChild && this.route.firstChild.snapshot.params['fracId'];
    this.olderThanTwoDays = this.route.snapshot.queryParams['older'] || this.olderThanTwoDays;
    this.urlOrderId = this.route.firstChild && this.route.firstChild.snapshot.params['orderId'];
    this.openedFracId = this.route.firstChild && this.route.firstChild.snapshot.params['fracId'];
    this.loadBaseData();
    this.subscribeToRoute();
    this.isLmoAccount = this.userService.isLMOAccount();
    this.searchListener();
  }

  private subscribeToRoute() {
    this.router.events.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.urlRoute = this.route.snapshot.params['tab'];
        const urlParam = this.urlRoute && this.urlRoute.split('?')[0];
        const olderFilter = this.route.snapshot.queryParams['older'];
        this.olderThanTwoDays = olderFilter || false;
        if (urlParam) {
          this.urlRoute = urlParam;
        }
        this.pageLimitInvoice = 100;
        this.pageIndexInvoice = 0;
        this.pageOffsetInvoice = 0;
        this.urlOrderId = this.route.firstChild && this.route.firstChild.snapshot.params['orderId'];
        this.openedFracId = this.route.firstChild && this.route.firstChild.snapshot.params['fracId'];
        if (this.urlOrderId && this.openedFracId) {
          this.openDetailView();
        }
        this.fetchData();
      }
    });
  }

  openDetailView() {
    this.detailView = true;
  }

  searchListener() {
    this.customInput.pipe(takeUntil(this.destroy$), debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      value = value.toLowerCase();
      this.searchData = value;
      this.loadData();
    });

    const localGroupFilter = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONST.GROUP_BY_INVOICE_FILTER));
    if (localGroupFilter != null) {
      this.groupByInvoiceFilter.setValue(localGroupFilter);
    }
    this.groupByInvoiceFilter.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      localStorage.setItem(LOCAL_STORAGE_CONST.GROUP_BY_INVOICE_FILTER, value);
      this.fetchData();
    });
  }

  private unsubscribeToCompletedAPI() {
    if (this.completedOrderAPISub) {
      this.completedOrderAPISub.unsubscribe();
    }
  }

  private unsubscribeToInvoiceAPI() {
    if (this.invoiceAPISub) {
      this.invoiceAPISub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeToInvoiceAPI();
    this.unsubscribeToCompletedAPI();
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  loadBaseData() {
    this.crud.httpClientReady.pipe(filter(Boolean)).subscribe((_) => {
      combineLatest([
        this.wellService.allFracs$,
        this.wellService.selectedFracs$.pipe(startWith([])),
        this.wellService.orderedAfterFilter$.pipe(startWith('')),
        this.userAPIService.getDrivers(),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([allFracs, selectedFracs, dateTime, drivers]) => {
          this.allFracs = [...allFracs];
          this.selectedFracs = [...selectedFracs];
          this.sortVendors();
          this.orderedAfterFilter = dateTime;
          this.driverList = drivers;
          if (this.allFracs.length) {
            this.fetchData();
          }
        });
    });
  }

  sortVendors() {
    let fracsList = [];
    this.truckingVendorsList = [];
    if (this.selectedFracs.length) {
      fracsList = [...this.selectedFracs];
    } else {
      fracsList = [...this.allFracs];
    }
    fracsList.forEach((frac) => {
      if (frac.vendors) {
        frac.vendors.forEach((vendor) => {
          if (!this.truckingVendorsList.find((filterVendor) => filterVendor.vendorId === vendor.vendorId)) {
            this.truckingVendorsList.push({ ...vendor, filter: true });
          }
        });
      }
    });
  }

  inputValueChanged(event) {
    this.customInput.next(event);
  }

  getLoadWeight(order) {
    if (order.boxes) {
      let sum = 0;
      order.boxes.forEach((box) => {
        if (box.actualLoadWeight) {
          sum += box.actualLoadWeight;
        }
      });
      return sum + ' lbs';
    } else if (order.actualLoadWeight) {
      return order.actualLoadWeight + ' lbs';
    } else {
      return '-';
    }
  }

  sortListener(sortData) {
    this.pageColumn = sortData.active;
    this.pageOrderBy = sortData.direction;
    this.loadData();
  }

  fetchData() {
    this.selection.clear();
    this.urlRoute = this.route.snapshot.params['tab'];
    const urlParam = this.urlRoute.split('?')[0];
    if (urlParam) {
      this.urlRoute = urlParam;
    }
    switch (this.urlRoute) {
      case TABS.READY_FOR_INVOICING: {
        if (this.isLmoAccount) {
          this.displayedColumns = [
            'loadNumber',
            'vendor',
            'jobName',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        } else {
          this.displayedColumns = [
            'select',
            'loadNumber',
            'jobName',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        }
        break;
      }
      case TABS.NEED_DATA_FOR_TICKETING: {
        if (this.isLmoAccount) {
          this.displayedColumns = [
            'status',
            'loadNumber',
            'jobName',
            'vendor',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        } else {
          this.displayedColumns = [
            'status',
            'loadNumber',
            'jobName',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        }
        break;
      }
      case TABS.WAITING_FOR_TICKET_APPROVAL: {
        if (this.isLmoAccount) {
          this.displayedColumns = [
            'status',
            'loadNumber',
            'jobName',
            'vendor',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
          if (this.userService.hasPermission('can_approve_ticket')) {
            this.displayedColumns.unshift('select');
          }
        } else {
          this.displayedColumns = [
            'status',
            'loadNumber',
            'jobName',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        }
        break;
      }
      case TABS.WAITING_FOR_INVOICE_APPROVAL: {
        if (!this.groupByInvoiceFilter.value) {
          if (this.isLmoAccount) {
            this.displayedColumns = [
              'status',
              'loadNumber',
              'invoiceNumber',
              'vendor',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
            if (this.userService.hasPermission('can_approve_ticket')) {
              this.displayedColumns.unshift('select');
            }
          } else {
            this.displayedColumns = [
              'status',
              'loadNumber',
              'invoiceNumber',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
          }
        } else {
          if (this.isLmoAccount) {
            this.columnsToDisplay = ['InvoiceNumber', 'vendorName', 'statusChangedTime', 'TotalCost', 'OrderCount'];
            this.subTableColumns = [
              'status',
              'loadNumber',
              'vendor',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
            if (this.userService.hasPermission('can_approve_invoice')) {
              this.columnsToDisplay.unshift('select');
            }
          } else {
            this.columnsToDisplay = ['InvoiceNumber', 'TotalCost', 'statusChangedTime', 'OrderCount'];
            this.subTableColumns = [
              'status',
              'loadNumber',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
          }
        }
        break;
      }
      case TABS.INVOICE_APPROVED: {
        if (!this.groupByInvoiceFilter.value) {
          if (this.isLmoAccount) {
            this.displayedColumns = [
              'status',
              'loadNumber',
              'invoiceNumber',
              'vendor',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
              'Export',
            ];
            if (this.userService.hasPermission('can_approve_invoice')) {
              this.displayedColumns.push('Actions');
            }
          } else {
            this.displayedColumns = [
              'status',
              'loadNumber',
              'invoiceNumber',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
              'Export',
            ];
          }
        } else {
          if (this.isLmoAccount) {
            this.columnsToDisplay = [
              'InvoiceNumber',
              'TotalCost',
              'OrderCount',
              'statusChangedTime',
              'vendorName',
              'ApprovedByName',
              'Export',
            ];
            this.subTableColumns = [
              'status',
              'loadNumber',
              'vendor',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
            if (this.userService.hasPermission('can_approve_invoice')) {
              this.columnsToDisplay.push('Actions');
            }
          } else {
            this.columnsToDisplay = ['InvoiceNumber', 'TotalCost', 'statusChangedTime', 'OrderCount', 'Export'];
            this.subTableColumns = [
              'status',
              'loadNumber',
              'name',
              'dispatchTimestamp',
              'mesh',
              'actualLoadWeight',
              'cost',
            ];
          }
        }
        break;
      }
      default: {
        if (this.isLmoAccount) {
          this.displayedColumns = [
            'loadNumber',
            'vendor',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        } else {
          this.displayedColumns = [
            'loadNumber',
            'name',
            'dispatchTimestamp',
            'mesh',
            'actualLoadWeight',
            'cost',
            'setting',
          ];
        }
      }
    }
    if (this.urlOrderId && this.openedFracId) {
      this.openDetailView();
    }
    this.loadData();
  }

  loadData() {
    let vendorIds = '';
    if (this.userService.isDispatcherAccount()) {
      vendorIds = `${this.userService.accountId()}`;
    }
    vendorIds = this.truckingVendorsList
      .filter((vendor) => vendor.filter)
      .map((vendor) => vendor.vendorId)
      .join();
    this.searchNumber++;
    this.isLoading = true;
    if (this.showOrderTable()) {
      const params = {
        state: this.urlRoute,
        autoApprove: this.isAutomated,
        manualApprove: this.isManual,
        pushback: this.pushback,
        fracIds: this.selectedFracs.map((frac) => frac.id).join(),
        attachment: this.attachmentFilter,
        pushedBackInvoiceNumber: this.pushedBackInvoiceNumber,
        dataMatched: this.dataMatchedFilter,
        receivedByCustomer: this.receivedByCustomer,
        fastTracked: this.fastTrackedFilter,
        needsManualApprovalPricing: this.needsManualApprovalPricing,
        needsManualApprovalTrip: this.needsManualApprovalTrip,
        needsManualApprovalBOL: this.needsManualApprovalBOL,
        olderThanTwoDays: this.olderThanTwoDays,
        orderedAfter: (this.orderedAfterFilter && new Date(this.orderedAfterFilter).toISOString()) || '',

        needData: this.needData,
        manuallyEdited: this.manuallyEdited,
        vendorId: vendorIds,

        limit: this.pageLimit,
        offset: this.pageOffset,
        orderByColumn: this.pageColumn,
        orderBy: this.pageOrderBy,
        searchQuery: this.searchData,
      };
      this.searchOrders(params, this.searchNumber);
    } else {
      this.loadInvoices();
    }
    this.wellService.getOrderCounts();
  }

  private loadInvoices() {
    const body = {
      approved: false,
      limit: this.pageLimitInvoice,
      offset: this.pageOffsetInvoice,
      searchTerm: this.searchData,
      orderedAfter: (this.orderedAfterFilter && new Date(this.orderedAfterFilter).toISOString()) || '',
      fracIds: this.selectedFracs.map((frac) => frac.id).join(),
    };
    if (this.urlRoute === 'invoiceApproved') {
      body.approved = true;
    }
    this.unsubscribeToInvoiceAPI();
    this.invoiceAPISub = this.wellApiService.getInvoicesForAllFrac(body).subscribe((invoices) => {
      this.completeInvoices = (invoices && invoices.bills) || [];
      this.invoiceCount = (invoices && invoices.billsCount) || 0;
      this.initializeTable();
      this.invoices = (invoices && invoices.bills) || [];
      this.isLoading = false;
      if (invoices.bills) {
        invoices.bills.forEach((_) => {
          this.exporting.push(false);
        });
      }
    });
  }

  public showOrderTable() {
    return !this.groupByInvoiceFilter.value || (this.groupByInvoiceFilter.value && !this.checkForInvoiceTabs());
  }

  checkForInvoiceTabs() {
    return this.urlRoute === TABS.WAITING_FOR_INVOICE_APPROVAL || this.urlRoute === TABS.INVOICE_APPROVED;
  }

  private loadInvoiceOrders(fracId, params, invoice) {
    this.isSubLoading = true;
    this.wellApiService.getInvoicedOrders(fracId, params).subscribe((orders) => {
      for (let i = 0; i < this.invoices.length; i++) {
        if (
          this.invoices[i].InvoiceNumber === invoice.InvoiceNumber &&
          this.invoices[i].vendorId === invoice.vendorId
        ) {
          this.invoices[i].orders = new MatTableDataSource(orders);
          this.isSubLoading = false;
        }
      }
    });
  }

  checkIfReceivedByCustomer(order) {
    return this.urlRoute === TABS.INVOICE_APPROVED && this.userService.isAPIUser() && !order.retrievedByAPI;
  }

  private searchOrders(params, searchNumber) {
    this.unsubscribeToCompletedAPI();
    this.completedOrderAPISub = this.wellApiService.getAllCompletedFrac(params).subscribe((data) => {
      if (this.searchNumber === searchNumber) {
        if (data.Orders) {
          this.completedOrders = data.Orders;
          localStorage.removeItem(LOCAL_STORAGE_CONST.BILLING_ORDER_IDS);
          localStorage.setItem(
            LOCAL_STORAGE_CONST.BILLING_ORDER_IDS,
            JSON.stringify(this.completedOrders.map((order) => order.id)),
          );
        }
        this.getOrderForwardStatus();
        this.orderCount = data.Count;
        this.initializeTable();
      }
    });
  }

  private getOrderForwardStatus() {
    if (this.completedOrders && this.completedOrders.length) {
      this.wellApiService
        .getOrdersForwardStatus({ orderIds: this.completedOrders.map((order) => order.id) })
        .subscribe((data) => {
          this.ordersTooltips = [...data];
        });
    }
  }

  private getTooltipData(order) {
    const selectedOrder = this.ordersTooltips.find((orderData) => orderData.orderId === order.id);
    if (
      selectedOrder &&
      (selectedOrder.missingTimeStamp ||
        selectedOrder.missingBolDetails ||
        selectedOrder.manualPricingChange ||
        selectedOrder.missingAttachment)
    ) {
      const reasonArray = [];
      if (selectedOrder.missingTimeStamp) {
        reasonArray.push('Missing GoHawk Timestamp');
      }
      if (selectedOrder.missingBolDetails) {
        reasonArray.push('Missing BOL Details');
      }
      if (selectedOrder.manualPricingChange) {
        reasonArray.push('Changed Pricing Details');
      }
      if (selectedOrder.missingAttachment) {
        reasonArray.push('Missing Attachment');
      }
      return reasonArray.join();
    } else {
      return null;
    }
  }

  updateSubOrders(element) {
    const selectedInvoice = this.invoices.find((invoice) => {
      return invoice.InvoiceNumber === element.InvoiceNumber && invoice.vendorId === element.vendorId;
    });
    if (!selectedInvoice.orders) {
      const params = {
        invoiceNumber: element.InvoiceNumber.trim(),
        vendorId: element.vendorId,
      };
      this.loadInvoiceOrders(element.fracId, params, element);
    }
  }

  public calculateBoostCharge(order) {
    return (order.lineHaul * (order.boostPercent || 0)) / 100;
  }

  fetchPageData(page) {
    if (this.showOrderTable()) {
      this.pageOffset = page.pageIndex * PAGE_SIZE;
      this.pageIndex = page.pageIndex;
    } else {
      this.pageOffsetInvoice = page.pageIndex * PAGE_SIZE_INVOICE;
      this.pageIndexInvoice = page.pageIndex;
    }
    this.selection.clear();
    this.loadData();
  }

  checkForMissing(order) {
    if (order.bolNumber === '' || !order.bolNumber) {
      return 'BOL Needed';
    }
  }

  checkForPushBack(order) {
    switch (this.urlRoute) {
      case 'needDataForTicketing':
        return (
          order.orderStatusChangeHistory &&
          order.orderStatusChangeHistory.oldValue === BILLING_STATUS.approvedByDispatcher &&
          order.orderStatusChangeHistory.newValue === BILLING_STATUS.notBilled
        );
      case 'readyForInvoicing':
        return (
          order.orderStatusChangeHistory &&
          order.orderStatusChangeHistory.oldValue === BILLING_STATUS.billed &&
          order.orderStatusChangeHistory.newValue === BILLING_STATUS.approvedByLMO
        );
      case 'waitingForTicketApproval':
        return (
          order.orderStatusChangeHistory &&
          order.orderStatusChangeHistory.oldValue === BILLING_STATUS.approvedByLMO &&
          order.orderStatusChangeHistory.newValue === BILLING_STATUS.approvedByDispatcher
        );
      default:
        return false;
    }
  }

  checkForApprove(order) {
    if (order.orderApproveStatus) {
      let error = '';
      let first = true;
      if (!order.orderApproveStatus.pricingApproved) {
        error += 'Pricing Changed';
        first = false;
      }
      if (!order.orderApproveStatus.tripApproved) {
        if (!first) {
          error += ', ';
        }
        error += 'Trip Changed';
        first = false;
      }
      if (!order.orderApproveStatus.bolApproved) {
        if (!first) {
          error += ', ';
        }
        error += 'Edited Payload Details';
      }
      return error;
    }
  }

  checkForEditableOrder(order) {
    switch (this.urlRoute) {
      case 'waitingForTicketApproval':
        return 'This load cannot be edited while waiting for ticket approval.';
      case 'waitingForInvoiceApproval':
        return 'This load cannot be edited while waiting for invoice approval';
      case 'invoiceApproved':
        return 'This load cannot be edited as it has already been billed.';
      default:
        return '';
    }
  }

  initializeTable() {
    if (!this.showOrderTable()) {
      const totalArray = Array(this.invoiceCount).fill({});
      this.invoiceDataSource.data = totalArray.map((_, idx) => {
        if (idx >= this.pageOffsetInvoice && idx < this.pageOffsetInvoice + PAGE_SIZE_INVOICE) {
          return this.completeInvoices[idx % PAGE_SIZE_INVOICE];
        }
        return _;
      });
      this.paginatorInvoice.pageIndex = this.pageIndexInvoice;
      this.invoiceDataSource.paginator = this.paginatorInvoice;
    } else {
      const totalArray = Array(this.orderCount).fill({});
      this.activeDataSource.data = totalArray.map((_, idx) => {
        if (idx >= this.pageOffset && idx < this.pageOffset + PAGE_SIZE) {
          return this.completedOrders[idx % PAGE_SIZE];
        }
        return _;
      });
      this.paginator.pageIndex = this.pageIndex;
      this.activeDataSource.paginator = this.paginator;
      this.activeDataSource.sort = this.sort;
    }
    this.isLoading = false;
  }

  selectRow(order) {
    this.openedFracId = order.fracId;
    this.router.navigate([`./${order.fracId}/${order.id}`], { relativeTo: this.route });
  }

  openTicketModal() {
    if (this.checkIfSameFracAndLMO()) {
      if (!this.useShaleAppsInvoiceNumber) {
        let selectedInvoice = this.selection.selected[0].invoiceNumber || null;
        selectedInvoice = this.selection.selected.every((o) => o.invoiceNumber === selectedInvoice)
          ? selectedInvoice
          : '';
        const dialogRef = this.dialog.open(TicketDialogComponent, {
          width: '300px',
          maxWidth: '968px',
          data: selectedInvoice,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.batchOrdersForInvoicing(result);
          }
        });
      } else {
        this.batchOrdersForInvoicing();
      }
    } else {
      this.snackbar.open('You can only batch orders from same LMO and Frac', null, {
        duration: 3000,
        panelClass: ['snackbar-error'],
      });
    }
  }

  private checkIfSameFracAndLMO() {
    const selectedFracId = this.selection.selected[0].fracId || null;
    const selectedLmoId = this.selection.selected[0].lmo.id || null;
    return this.selection.selected.every((o) => o.fracId === selectedFracId && o.lmo.id === selectedLmoId);
  }

  batchOrdersForInvoicing(invoiceNumber?: string) {
    let body: any = {
      orderIds: this.selection.selected.map((order) => order.id),
      fracId: +this.selection.selected[0].fracId,
    };
    if (invoiceNumber) {
      body = { ...body, invoiceNumber: invoiceNumber.trim() };
    }
    this.orderApiService.billOrders(body).subscribe(
      (resp) => {
        this.fetchData();
        let failedOrderIds = [];
        if (resp.failedOrderIds && resp.failedOrderIds.length) {
          failedOrderIds = resp.failedOrderIds.filter((order) => order !== '');
        }
        if (failedOrderIds.length) {
          this.snackbar.open(
            this.selection.selected.length === failedOrderIds.length
              ? `Failed to Batch Orders ${failedOrderIds.join()}`
              : `Batched all orders except for ${failedOrderIds.join()}.`,
            null,
            {
              duration: 5000,
              panelClass: ['snackbar-error'],
            },
          );
        } else {
          if (this.singleOrderPerInvoice) {
            this.snackbar.open(`${body.orderIds.length} order(s) billed successfully`, null, {
              duration: 5000,
            });
          } else {
            const snackbarRef = this.snackbar.open(
              `Successfully batched SA${resp.invoiceNumber}`,
              'Copy to Clipboard',
              {
                duration: 5000,
              },
            );
            snackbarRef.onAction().subscribe((_) => {
              this.textToClipboard(`SA${resp.invoiceNumber}`);
              this.snackbar.open(`SA${resp.invoiceNumber} Copied to Clipboard`, null, {
                duration: 2000,
              });
            });
          }
        }
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
      },
    );
  }

  textToClipboard(text) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    dummy.setSelectionRange(0, 99999); // For Mobile Devices
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  approveOrders() {
    const orderIds = this.selection.selected.map((order) => {
      return order.id;
    });
    if (this.urlRoute === 'waitingForTicketApproval') {
      this.orderApiService.approveOrders(orderIds).subscribe(
        (_) => {
          this.snackbar.open('Order approved for invoicing Successfully', null, {
            duration: 5000,
          });
          this.selection.clear();
          this.loadData();
        },
        (err) => {
          this.errorHandler.showError(`Update Failed`, 5000);
        },
      );
    } else if (this.urlRoute === 'waitingForInvoiceApproval') {
      this.orderApiService.approveInvoices(orderIds).subscribe(
        (_) => {
          this.snackbar.open('Invoice Approved Successfully', null, {
            duration: 5000,
          });
          this.selection.clear();
          this.loadData();
        },
        (err) => {
          this.errorHandler.showError(`Update Failed`, 5000);
        },
      );
    }
  }

  approveBulkInvoices(event) {
    event.stopPropagation();
    const isOtherOrders = this.selection.selected.find((order) => order.isBatchedWithOtherOrders);
    if (!this.groupByInvoiceFilter.value && isOtherOrders) {
      const confirmDialogRef = this.dialog.open(ConfirmActionDialogComponent, {
        data: {
          title: 'Approve All Loads for this Invoice?',
          desc:
            'There are multiple loads billed on this invoice. Would you like to approve all loads for this invoice?',
          cancelButtonText: 'Cancel',
          submitButtonText: 'Approve',
        },
      });
      confirmDialogRef
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe((_) => {
          this.bulkApprove();
        });
    } else {
      this.bulkApprove();
    }
  }

  private bulkApprove() {
    const body = [
      ...this.selection.selected.map((selected) => {
        return {
          vendorId: !this.groupByInvoiceFilter.value ? selected.vendor.id : selected.vendorId,
          invoiceNumber: !this.groupByInvoiceFilter.value
            ? selected.invoiceNumber.trim()
            : selected.InvoiceNumber.trim(),
          isOneClickApproved: false,
          fracId: selected.fracId,
        };
      }),
    ];
    this.wellApiService.approveInvoiceChangesForAllFrac(this.selectedFracId, body).subscribe(
      (_) => {
        this.snackbar.open('Invoiced Orders Approved Successfully', null, {
          duration: 5000,
        });
        this.selection.clear();
        this.loadData();
      },
      (err) => {
        this.errorHandler.showError(`Unable to approve invoiced orders.`, 5000);
      },
    );
  }

  exportInvoiceDetails(event, element, index) {
    event.stopPropagation();
    this.exporting[index] = true;
    const body = {
      invoiceNumber: !this.groupByInvoiceFilter.value ? element.invoiceNumber : element.InvoiceNumber,
      vendorId: !this.groupByInvoiceFilter.value ? element.vendor.id : element.vendorId,
    };
    this.wellApiService.exportInvoices(element.fracId, body).subscribe(
      (data) => {
        this.exporting[index] = false;
        window.open(data, '_blank');
      },
      (error) => {
        console.error(error);
        this.exporting[index] = false;
        this.errorHandler.showError('Error downloading the file.');
      },
    );
  }

  rejectBulkInvoices(event) {
    event.stopPropagation();
    const isOtherOrders = this.selection.selected.find((order) => order.isBatchedWithOtherOrders);
    if (!this.groupByInvoiceFilter.value && isOtherOrders) {
      const confirmDialogRef = this.dialog.open(ConfirmActionDialogComponent, {
        data: {
          title: 'Reject All Loads for this Invoice?',
          desc: 'There are multiple loads billed on this invoice. Would you like to reject all loads for this invoice?',
          cancelButtonText: 'Cancel',
          submitButtonText: 'Reject',
        },
      });
      confirmDialogRef
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe((_) => {
          this.bulkReject();
        });
    } else {
      this.bulkReject();
    }
  }
  private bulkReject() {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: 'approved_by_lmo',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        const body = {
          invoices: [
            ...this.selection.selected.map((selected) => {
              return {
                vendorId: !this.groupByInvoiceFilter.value ? selected.vendor.id : selected.vendorId,
                invoiceNumber: !this.groupByInvoiceFilter.value
                  ? selected.invoiceNumber.trim()
                  : selected.InvoiceNumber.trim(),
                fracId: selected.fracId,
              };
            }),
          ],
          pushbackReason: result.reason,
        };
        this.wellApiService.rejectInvoiceChangesForAllFrac(this.selectedFracId, body).subscribe(
          (_) => {
            this.snackbar.open('Invoiced Orders Rejected Successfully', null, {
              duration: 5000,
            });
            this.selection.clear();
            this.loadData();
          },
          (err) => {
            this.errorHandler.showError(`Unable to reject invoiced orders.`, 5000);
          },
        );
      }
    });
  }

  rejectApprovedInvoice(event, invoice) {
    event.stopPropagation();
    if (!this.groupByInvoiceFilter.value && invoice.isOtherOrder) {
      const confirmDialogRef = this.dialog.open(ConfirmActionDialogComponent, {
        data: {
          title: 'Undo Approve All Loads for this Invoice?',
          desc:
            'There are multiple loads billed on this invoice. Would you like to undo approve all loads for this invoice?',
          cancelButtonText: 'Cancel',
          submitButtonText: 'Reject',
        },
      });
      confirmDialogRef
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe((_) => {
          this.undoApproval(invoice);
        });
    } else {
      this.undoApproval(invoice);
    }
  }

  private undoApproval(invoice) {
    this.wellApiService
      .rejectApprovedInvoicesForAllFrac({
        vendorId: !this.groupByInvoiceFilter.value ? invoice.vendor.id : invoice.vendorId,
        invoiceNumber: !this.groupByInvoiceFilter.value ? invoice.invoiceNumber.trim() : invoice.InvoiceNumber.trim(),
        fracId: invoice.fracId,
      })
      .subscribe(
        (_) => {
          this.snackbar.open(
            `Invoice "${
              !this.groupByInvoiceFilter.value ? invoice.invoiceNumber : invoice.InvoiceNumber
            }" moved back for approval`,
            null,
            {
              duration: 5000,
            },
          );
          this.loadData();
        },
        (err) => {
          this.errorHandler.showError(err, 5000);
        },
      );
  }

  close(changed) {
    this.selectedFracId = this.route.parent.snapshot.params['id'];
    this.selection.clear();
    this.detailView = false;
    this.urlOrderId = null;
    this.loadSelectedFracs();
  }

  public checkForNonFinancialImpact(order) {
    return order.orderBillingHistory && order.orderBillingHistory.length;
  }

  loadSelectedFracs() {
    const extras: any = {
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    };
    if (this.selectedFracs.length) {
      extras.queryParams = { ...extras.queryParams, fracIds: this.selectedFracs.map((frac) => frac.id).join() };
    }
    this.router.navigate(['./'], extras);
    this.wellService.setSelectedFracs(this.selectedFracs);
  }

  loadRecent(event) {
    this.olderThanTwoDays = event.checked;
    const extras: any = {
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    };
    extras.queryParams = { ...extras.queryParams, older: this.olderThanTwoDays };
    this.router.navigate([], extras);
  }
}
