import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck',
})
export class PluckPipe implements PipeTransform {
  public transform<T>(value: T[], key: keyof T): any {
    return value.map((v) => v[key]);
  }
}
