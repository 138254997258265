<div class="order-detail-content">
  <h3>Shale Apps Only</h3>
  <ng-container *ngIf="currentPrediction$ | async as prediction">
    <div class="details" *ngIf="prediction.hasPrediction; else noPrediction">
      The latest prediction was calculated at
      <h4>{{ prediction.predictionCreationTime | date: 'medium' }}</h4>
      and estimates this order will complete around
      <h4>{{ prediction.predictedEndTime | date: 'medium' }}</h4>
    </div>
  </ng-container>
</div>
<ng-template #noPrediction>
  <div class="details">
    <h4>No Predictions currently available</h4>
  </div>
</ng-template>
