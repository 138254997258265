import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injectable,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MapSearchInputComponent } from './map-search-input.component';
import { MapSearchResultsComponent } from './map-search-results.component';
import { User } from '../../models/user';
import { Site } from '../../models/site';
import { GlobalSearchApiService } from '../../services/api/global-search.api.service';
import { Subscription } from 'rxjs';

@Injectable()
@Component({
  selector: 'sa-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapSearchComponent implements AfterViewInit {
  @Output() onItemSelected: EventEmitter<{ item: User | Site; inputType: string }> = new EventEmitter();
  @Output() onItemFocused: EventEmitter<any> = new EventEmitter();
  @Output() onInputChange: EventEmitter<void> = new EventEmitter();
  @Output() onMenuClick: EventEmitter<void> = new EventEmitter();
  @Output() onInputFocused: EventEmitter<void> = new EventEmitter();
  @ViewChild('searchInputComponent', { static: true }) inputComponent: MapSearchInputComponent;
  @ViewChild('searchResultsComponent', { static: false }) resultsComponent: MapSearchResultsComponent;

  showResults: boolean;
  inputFocused: boolean;
  results = null;
  inputElement: any;
  searchRequest: Subscription;

  constructor(private searchApiService: GlobalSearchApiService) {}

  ngAfterViewInit() {
    this.inputElement = this.inputComponent.input.nativeElement;
  }

  getResults(searchTerm) {
    this.onInputChange.emit();
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }

    if (searchTerm && searchTerm.length > 2) {
      this.searchRequest = this.searchApiService.getResults({ 'like-name': searchTerm }).subscribe((results: any) => {
        this.results = results;
        this.showResults = true;
      });
    } else {
      this.results = null;
      this.showResults = true;
    }
  }

  selectItem(item) {
    this.showResults = false;
    this.inputElement.value = item.name;
    this.onItemSelected.emit({ item: item, inputType: item.model });
  }

  focusInput(focused) {
    if (focused) {
      this.inputFocused = true;
      this.onInputFocused.emit();
      this.showResults = true;
    }
  }

  appendCharacter(char: string) {
    if (!this.inputFocused) {
      this.inputElement.focus();

      if (char === 'Backspace') {
        this.inputElement.value = this.inputElement.value.substr(0, this.inputElement.value.length - 1);
      } else {
        this.inputElement.value = this.inputElement.value + char;
      }

      if (this.inputElement.value && this.inputElement.value.length > 0) {
        this.getResults(this.inputElement.value);
      } else {
        this.showResults = false;
      }
    }
  }

  selectInputText() {
    if (!this.inputFocused) {
      this.inputComponent.input.nativeElement.focus();
      this.inputComponent.input.nativeElement.select();
    }
  }

  onResultItemFocused(focus: { resultNotEmpty: boolean; item: any }) {
    if (focus.resultNotEmpty) {
      this.inputComponent.input.nativeElement.blur();
      this.inputFocused = false;
      this.onItemFocused.emit(focus.item);
    }
  }

  onEscapeKey() {
    this.inputComponent.input.nativeElement.focus();
    this.inputComponent.input.nativeElement.value = '';
    this.showResults = false;
  }

  menuClick() {
    this.onMenuClick.emit();
  }
}
