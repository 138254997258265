import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ReassignDialogComponent } from '../../reassign-dialog/reassign-dialog.component';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  switchMap,
  startWith,
  tap,
  debounceTime,
  catchError,
  skip,
} from 'rxjs/operators';
import { StoreService } from '../../../services/store.service';
import { UserApiService } from '../../../services/api/user.api.service';
import { Observable, of, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { MapUtilService } from '../../../services/map-util.service';
import { RouterStateService } from 'src/app/services/router-state.service';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { isOrder } from '~models/order.model';
import { UserService } from '~services/user.service';
import { FormControl } from '@angular/forms';
import { Frac } from '~v2Models/frac.model';
import { User } from '~v2Models/user.model';
import { equals, sort } from 'remeda';
import { fuse } from '~utilities/fuse';
import { trackById } from '~utilities/trackById';

const searchOptions: Fuse.FuseOptions<UserWithExtras> = {
  keys: ['name', 'phone'],
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 9,
  minMatchCharLength: 1,
};

const onlyNumbers = new RegExp(/\D/g);

export const filterStatuses = ['available', 'assigned', 'all'] as const;
export type FilterStatus = typeof filterStatuses[number];

export const sortTypes = ['miles', 'time'] as const;
export type SortType = typeof sortTypes[number];

export interface PrettyStatus {
  prettyStatus: 'Unreachable' | 'Offline' | 'Online';
  prettyStatusTime?: string;
}
export interface UserWithExtras extends User, PrettyStatus {
  distance: number;
  order: any;
}

export interface UnassignedUser extends UserWithExtras {
  lastOrderEndTime: string;
}

@Component({
  selector: 'sa-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDriverComponent implements OnInit, OnDestroy {
  public trackById = trackById;
  public searchForm = new FormControl(null);
  public filterStatus$$ = new BehaviorSubject<FilterStatus>('available');
  public sortType$$ = new BehaviorSubject<SortType>('miles');
  public is5f$$ = new BehaviorSubject<boolean>(false);
  public elligibleForBoost$: Observable<boolean>;
  public driversToShow$: Observable<UserWithExtras[]>;
  public assignedDrivers$: Observable<UserWithExtras[]>;
  public unassignedDrivers$: Observable<UnassignedUser[]>;
  public filteredDrivers$: Observable<UserWithExtras[]>;
  public sortedFilteredDrivers$: Observable<UserWithExtras[]>;
  public tryFull$$ = new BehaviorSubject<boolean>(false);
  public loading$$ = new BehaviorSubject<boolean>(false);
  public doesNotExists$$ = new BehaviorSubject<boolean>(false);
  private hoveredDriver$$ = new Subject<{ user: User; hovered: boolean }>();

  @Input() selectedOrder: any;
  @Input() selectedFrac: any;
  @Output() onGoBack: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDriver: EventEmitter<any> = new EventEmitter();
  @Output() reassignAndCompleteCurrentLoad: EventEmitter<any> = new EventEmitter();
  @ViewChild('hauliRunboardWarning', { static: true }) private hauliRunboardWarning: TemplateRef<any>;
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @ViewChild('searchInput', { static: true }) inputEl: ElementRef;

  constructor(
    private dialog: MatDialog,
    private userApiService: UserApiService,
    private store: StoreService,
    private mapUtilService: MapUtilService,
    private routerState: RouterStateService,
    private featureFlagService: FeatureFlagService,
    public userService: UserService,
  ) {
    this.hoveredDriver$$.pipe(debounceTime(200), distinctUntilChanged(equals), skip(1)).subscribe((info) => {
      this.mapUtilService.updateDriver(info.user, info.hovered);
    });
  }

  private get pickupSiteLngLat() {
    return this.selectedOrder?.mine?.site?.lngLat || this.selectedOrder?.distributionCenter?.site?.lngLat;
  }

  ngOnInit() {
    this.elligibleForBoostSetup();
    this.setupAssignedDrivers();
    this.unassignedDrivers$ = this.store.select<UnassignedUser[]>('unassignedTrucks').pipe(
      map((users) => {
        let filteredUsers = (users || []) as any[];
        if (this.selectedOrder.orderLoHiSubcontractorName) {
          filteredUsers = filteredUsers.filter((user) => user.subcontractorId === this.selectedOrder.subcontractorId);
        }
        return filteredUsers.map((user) => ({
          ...user,
          ...getPrettyStatus(user),
          distance: distance(this.pickupSiteLngLat, user.lastLngLat),
        }));
      }),
    ); // For weird legacy reasons, unassignedTrucks is actually unassignedDrivers
    this.setupFilteredDrivers();
    this.setupSortedDrivers();
    this.setupSearchBox();
    setTimeout(() => this.inputEl.nativeElement.focus());

    const savedValue = localStorage.getItem('filterStatus') as FilterStatus;
    if (savedValue && filterStatuses.includes(savedValue)) {
      this.filterStatus$$.next(savedValue);
    }
  }

  private setupFilteredDrivers() {
    this.filteredDrivers$ = combineLatest([
      this.assignedDrivers$,
      this.unassignedDrivers$,
      this.filterStatus$$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([assigned, unassigned, filterStatus]) => {
        if (filterStatus === 'assigned') {
          return assigned;
        }
        if (filterStatus === 'available') {
          return unassigned;
        }
        return [...assigned, ...unassigned];
      }),
    );
  }

  private setupSortedDrivers() {
    this.sortedFilteredDrivers$ = combineLatest([
      this.filteredDrivers$,
      this.sortType$$.pipe(distinctUntilChanged()),
      this.is5f$$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([drivers, sortType, is5F]) => {
        if (is5F) {
          drivers = drivers.filter((driver) => driver.is5f);
        }
        if (sortType === 'miles') {
          return sort(
            drivers,
            (a, b) => distance(this.pickupSiteLngLat, a.lastLngLat) - distance(this.pickupSiteLngLat, b.lastLngLat),
          );
        } else if (sortType === 'time') {
          return sort(
            drivers,
            (a: UnassignedUser, b: UnassignedUser) =>
              new Date(a.lastOrderEndTime).getTime() - new Date(b.lastOrderEndTime).getTime(),
          );
        }
        return drivers;
      }),
    );
  }

  private setupSearchBox() {
    this.driversToShow$ = combineLatest([
      this.searchForm.valueChanges.pipe(startWith(null as any), distinctUntilChanged()),
      this.sortedFilteredDrivers$.pipe(map(fuse(searchOptions))),
    ]).pipe(
      tap(() => {
        this.tryFull$$.next(false);
        this.doesNotExists$$.next(false);
      }),
      map(([searchValue, sortedFilteredDrivers]) => {
        if (!searchValue) {
          return sortedFilteredDrivers.data;
        } else {
          const asPhoneNumber = (searchValue as string).replace(onlyNumbers, '');
          // Use the phone number without any punctuation if there are numbers in the string
          return sortedFilteredDrivers.fuse.search(asPhoneNumber.length > 1 ? asPhoneNumber : searchValue);
        }
      }),
      switchMap((results) => {
        if (results && results.length) {
          return of(results);
        }
        const asPhoneNumber = ((this.searchForm.value as string) || '').replace(onlyNumbers, '');
        if (asPhoneNumber.length === 10) {
          const body = {
            'phone-number': asPhoneNumber,
            orderId: this.selectedOrder.id,
          };
          this.loading$$.next(true);
          return this.userApiService.searchDrivers<User>(body).pipe(
            tap(() => this.loading$$.next(false)),
            map((searchDriverResults) => {
              if (searchDriverResults.length) {
                const response = searchDriverResults.map((driver) => {
                  return {
                    ...driver,
                    ...getPrettyStatus(driver),
                    order: this.store.getOrderForDriver(driver),
                  };
                });
                return response;
              } else {
                this.doesNotExists$$.next(true);
                return [];
              }
            }),
            catchError((error) => {
              console.error(error);
              return of([]);
            }),
          );
        }
        this.tryFull$$.next(true);
        return of([]);
      }),
    );
  }

  updateUnassignedDrivers() {
    this.userApiService.getUnassignedUsers({ orderId: this.selectedOrder.id }).subscribe((trucks) => {
      this.store.set('unassignedTrucks', trucks);
    });
  }

  setupAssignedDrivers() {
    this.assignedDrivers$ = this.store.select<Array<any>>('fracs').pipe(
      filter((_) => _.length > 0),
      map((fracs: Frac[]) => {
        const result: UserWithExtras[] = [];
        fracs.forEach((frac) => {
          if (frac.orders) {
            const orders = frac.orders;
            orders.forEach((order) => {
              if (order.orderStatus === 'dispatched' || order.orderStatus === 'driver_accepted') {
                if (this.selectedOrder.orderLoHiSubcontractorName) {
                  if (order.user && order.user.subcontractorName === this.selectedOrder.orderLoHiSubcontractorName) {
                    result.push({
                      ...order.user,
                      ...getPrettyStatus(order.user),
                      distance: distance(this.pickupSiteLngLat, order.user.lastLngLat),
                    } as UserWithExtras);
                  }
                } else {
                  if (order.user) {
                    result.push({
                      ...order.user,
                      ...getPrettyStatus(order.user),
                      distance: distance(this.pickupSiteLngLat, order.user.lastLngLat),
                    } as UserWithExtras);
                  }
                }
              }
            });
          }
        });
        return result;
      }),
    );
  }

  goBack() {
    this.onGoBack.emit(1);
  }

  async selectDriver(driver) {
    this.mapUtilService.updateDriver(driver, false);
    if (
      driver.isOnHauliRunboard &&
      (await this.featureFlagService
        .isFlagActive('hauliRunboardWarning')
        .pipe(take(1))
        .toPromise())
    ) {
      this.dialog.open(this.hauliRunboardWarning);
      return;
    }
    if (!driver.userStatus || !driver.userStatus.currentOrderId) {
      this.onSelectDriver.emit(driver);
      this.goBack();
    } else {
      const dialogRef = this.dialog.open(ReassignDialogComponent, {
        width: '20%',
        maxWidth: '968px',
        data: { driver: driver, site: this.selectedFrac.site },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'complete' || result === 'unassign') {
          this.onSelectDriver.emit(driver);
          this.reassignAndCompleteCurrentLoad.emit(result);
          this.goBack();
        }
      });
    }
  }

  hoverDriver(user: User, hovered: boolean) {
    this.hoveredDriver$$.next({ user, hovered });
  }

  ngOnDestroy() {
    localStorage.setItem('filterStatus', this.filterStatus$$.value);
  }

  private elligibleForBoostSetup() {
    this.elligibleForBoost$ = this.routerState.routerState$.pipe(
      switchMap((routerState) => {
        const fracId = +routerState.params.id;
        const loadNumber = +routerState.params.load;
        if (!fracId || !loadNumber) {
          return of(false);
        }
        return this.store.getOrderByFracAndLoadNumber(fracId, loadNumber).pipe(
          map((order) => {
            if (!order || !isOrder(order)) {
              return false;
            }
            return order.boostPercent > 0;
          }),
        );
      }),
    );
  }
}

// Essentially https://en.wikipedia.org/wiki/Haversine_formula
function distance(p1: [number, number], p2: [number, number]): number {
  if (p2) {
    const lon1 = p1[0];
    const lat1 = p1[1];

    const lon2 = p2[0];
    const lat2 = p2[1];

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515; // Staute Miles per Nautical Mile – https://stackoverflow.com/a/389251
    return Number(dist.toFixed(2));
  } else {
    return 0;
  }
}

function getPrettyStatus(driver: User): PrettyStatus {
  if (driver && driver.logs) {
    return { prettyStatus: 'Unreachable' };
  }
  if (driver && driver.activeSession) {
    const now = new Date().getTime();
    const lastSeen = new Date(driver.lastTimeSeen).getTime();
    if ((now - lastSeen) / (1000 * 60) > 30) {
      let minDiff = Math.round((now - lastSeen) / (1000 * 60));
      const hoursDiff = Math.round(minDiff / 60);
      minDiff = minDiff % 60;
      return { prettyStatus: `Unreachable`, prettyStatusTime: `${hoursDiff}h ${minDiff}m` };
    } else {
      return { prettyStatus: 'Online' };
    }
  } else {
    return { prettyStatus: 'Offline' };
  }
}
