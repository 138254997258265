import { EventEmitter, Injectable, Output } from '@angular/core';

export enum MapUtilState {
  FracList = 1,
  Pending,
  InProgress,
  Completed,
  PendingOrderDetail,
  InProgressOrderDetail,
  CompletedOrderDetail,
  AddDriver,
  RunboardSummary,
  RunboardSummaryDriver,
}

@Injectable()
export class MapUtilService {
  constructor() {}

  @Output() onUpdateMap: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeMine: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadFracs: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDriverChange: EventEmitter<any> = new EventEmitter<any>();

  updateMap({ frac, state, order }) {
    this.onUpdateMap.emit({ frac, state, order });
  }

  updateMine(newMine) {
    this.onChangeMine.emit(newMine);
  }

  reloadFracs() {
    this.loadFracs.emit();
  }

  updateDriver(driver, status) {
    this.onDriverChange.emit({ driver, status });
  }
}
