<sa-nav [visible]="displayNav" (onMenuClose)="displayNav = false"></sa-nav>

<div class="lmo-loader-name-mapping-component">
  <div class="top-menu">
    <div class="side-menu"><img class="menu-icon" src="../../../assets/icons/menu-24.svg" (click)="menuClick()" /></div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="../../../assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel.email || userLabel.name || 'Unknown' }}
    </div>
  </div>

  <div class="head-wrap">
    <h1>Loader Name Mapping</h1>
    <div class="button-wrap">
      <div class="add-button" (click)="addForm()">
        <i class="material-icons" saAutomatedSelector="loader-integration__add-button">add</i>
      </div>
    </div>
  </div>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="nameInFile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Value in Spreadsheet</th>
        <td mat-cell *matCellDef="let loaderMapping">
          {{ loaderMapping.nameInFile }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loader</th>
        <td mat-cell *matCellDef="let loaderMapping">
          {{ loaderMapping.loaderName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let loaderMapping">
          <i class="material-icons" (click)="$event.stopPropagation(); addForm(loaderMapping)">edit</i>
          <i class="material-icons delete-icon" (click)="deleteLoaderNameMapping(loaderMapping.id)">delete</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let loaderMapping; columns: displayedColumns"></tr>
    </table>
  </div>

  <sa-lmo-loader-name-mapping-form *ngIf="displayForm" [selectedMapping]="selectedMapping" (goBack)="closeForm()">
  </sa-lmo-loader-name-mapping-form>
</div>
