import { Component, OnInit, Input, ChangeDetectionStrategy, ContentChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatButton } from '@angular/material/button';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-network-active-button',
  styleUrls: ['./network-active-button.component.scss'],
  templateUrl: './network-active-button.component.html',
})
export class NetworkActiveButtonComponent implements OnInit {
  private _active = false;
  private wasDisabled: boolean;
  @ContentChild(MatButton, { static: true }) private button: MatButton;
  @Input() public set active(active: boolean) {
    this._active = active;
    this.toggleButtonDisabled();
  }

  public get active() {
    return this._active;
  }

  constructor() {}

  public ngOnInit() {}

  private toggleButtonDisabled() {
    if (!this.button) {
      return;
    }
    if (this.active) {
      this.wasDisabled = this.button.disabled;
      this.button.disabled = true;
    } else if (!this.wasDisabled) {
      this.wasDisabled = null;
      this.button.disabled = false;
    }
  }
}
