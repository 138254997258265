import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Truck, Trailer } from '~models/truck.model';
import { share, filter, take, switchMap } from 'rxjs/operators';
import { CrudService } from './services/crud.service';
import { environment } from '~environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TrucksService {
  private truckName: string;
  private trailerName: string;
  private truckList$$: BehaviorSubject<Truck[]> = new BehaviorSubject([]);
  private trailerList$$: BehaviorSubject<Trailer[]> = new BehaviorSubject([]);

  public get truckList$(): Observable<Truck[]> {
    return this.truckList$$.asObservable();
  }

  public get trailerList$(): Observable<Trailer[]> {
    return this.trailerList$$.asObservable();
  }

  constructor(private crudService: CrudService, private http: HttpClient) {}

  searchTruck(name: string): void {
    this.truckName = name;
    this.reloadTruckResults();
  }

  reloadTruckResults(clear = true): void {
    if (this.truckName === null) {
      return;
    }
    if (clear) {
      this.truckList$$.next([]);
    }
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/truck/search`, { 'like-name': this.truckName })),
      )
      .subscribe((trucks: Truck[]) => {
        this.truckList$$.next(trucks);
      });
  }

  searchTrailer(name: string): void {
    this.trailerName = name;
    this.reloadTrailerResults();
  }

  reloadTrailerResults(clear = true): void {
    if (this.trailerName === null) {
      return;
    }
    if (clear) {
      this.trailerList$$.next([]);
    }
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/trailer/search`, { 'like-name': this.trailerName })),
      )
      .subscribe((trailers: any[]) => {
        this.trailerList$$.next(trailers);
      });
  }

  reset(): void {
    this.truckList$$.next([]);
    this.trailerList$$.next([]);
  }

  public addTruck(name: string): Observable<Truck> {
    return this.http.post<Truck>(`${environment.api}/truck`, { name });
  }
}
