/// <reference types="@types/googlemaps" />
// import { } from '@types/googlemaps';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import * as moment from 'moment';
import { DatePipe } from '@angular/common';

import { MapSearchComponent } from '../map-search/map-search.component';
import { WaypointApiService } from '../../services/api/waypoint.api.service';
import { CrudService } from '../../services/crud.service';
import { UserService } from '../../services/user.service';
import { SiteApiService } from '../../services/api/site.api.service';
import { MapService } from '../../services/map.service';
import { User } from '../../models/user';
import { Site } from '../../models/site';
import { Waypoint } from '../../models/waypoint';
import { Session } from '../../models/session';
import { DetailView } from '../../models/detail-view';
import { InputTypes } from '../../constants/global-search-models';
import { DetailViewModels } from '../../constants/detail-view-models';
import { config } from '../../config';
import { LightTheme } from './mapStyles';

import { MarkerClusterer } from '../google-maps-utility/markerclusterer';
import { InfoBox } from '../google-maps-utility/infobox';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { take, filter } from 'rxjs/operators';

type MapType = 'map' | 'replay';

const MapTypes = {
  map: 'map' as MapType,
  replay: 'replay' as MapType,
};

const INITIAL_ZOOM = 8;

@Component({
  selector: 'sa-map-general',
  templateUrl: './map-general.component.html',
  styleUrls: ['./map-general.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapGeneralComponent implements OnInit {
  map: google.maps.Map;
  mapConfig: google.maps.MapOptions;
  mapType: MapType;
  mapTypes = MapTypes;
  activeTooltip: { id: number; tooltip: any; model: string };
  userMarkerMap: Map<number, google.maps.Marker> = new Map();
  siteMarkerMap: Map<number, google.maps.Marker> = new Map();
  siteTooltipMap: Map<number, any> = new Map();
  sitesLoadedSubject: Subject<void>;
  sliderValue = 0;
  searchInputTypes = InputTypes;
  userWaypoints: Waypoint[];
  sites: Site[];
  selectedWaypoint;
  truckMarker;
  detailView: DetailView;
  navOpen = false;
  showSpinner = false;
  loadId = 0;
  showLog = false;
  wayRoute = [];
  receivedOrder;
  showSlider = false;
  waypointLogs = [];

  activeReplayTooltip: any;
  polylines: google.maps.Polyline[] = [];
  replayMarkers: google.maps.Marker[] = [];

  @Output() onMenuClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('mapSearch', { static: true }) mapSearch: MapSearchComponent;

  constructor(
    private mapService: MapService,
    private waypointService: WaypointApiService,
    private siteService: SiteApiService,
    private crud: CrudService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorHandler: ErrorHandlingService,
  ) {
    window['__onGoogleLoaded'] = (ev) => {
      this.initMap();
    };

    this.loadScripts();

    this.crud.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
      if (ready) {
        this.getLastKnownLocations();
      }
    });
  }

  loadScripts() {
    const node = document.createElement('script');
    node.src = config.googleMapsScriptUrl;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    this.detailView = {
      open: false,
      id: null,
      modelName: null,
    };
    if (this.userService.isSandmanAccount()) {
      this.router.navigate(['/', 'sand-vendor']);
      return;
    }
    if (!this.userService.isShaleAccount()) {
      this.router.navigateByUrl('/wells');
    }
  }

  initMap() {
    this.mapType = MapTypes.map;
    this.mapConfig = {
      center: { lat: 39.7392, lng: -104.9903 },
      zoom: INITIAL_ZOOM,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: 3,
      },
      zoomControlOptions: {
        position: 3,
      },
      styles: <any>LightTheme,
    };

    this.map = new google.maps.Map(document.getElementById('map'), this.mapConfig);

    google.maps.event.addListener(this.map, 'click', () => {
      this.mapSearch.showResults = false;

      this.changeDetectorRef.detectChanges();

      if (this.activeTooltip && this.activeTooltip.tooltip) {
        this.activeTooltip.tooltip.close();
        this.activeTooltip = null;
      }

      if (this.activeReplayTooltip) {
        this.activeReplayTooltip.close();
        this.activeReplayTooltip = null;
      }
    });
  }

  menuClick() {
    this.onMenuClick.emit();
  }

  getLastKnownLocations() {
    this.waypointService.getLastWaypoints().subscribe((waypoints: Waypoint[]) => {
      this.userWaypoints = waypoints;

      for (const waypoint of waypoints) {
        this.generateUserWaypointMarker(waypoint);
      }
    });
  }

  generateUserWaypointMarker(waypoint: Waypoint) {
    const marker = this.mapService.createUserWaypointMarker(waypoint);
    const tooltip = this.mapService.createUserInfobox(waypoint);

    marker.addListener('click', () => {
      if (this.activeTooltip && this.activeTooltip.tooltip) {
        this.activeTooltip.tooltip.close();
      }
      tooltip.open(this.map, marker);
      this.activeTooltip = { id: waypoint.userId, tooltip: tooltip, model: this.searchInputTypes.user };
    });

    marker.setMap(this.map);
    this.userMarkerMap.set(waypoint.userId, marker);
  }

  showItem(item: any, inputType: string) {
    if (inputType === this.searchInputTypes.user) {
      this.openDetailView(item.id, inputType);
      this.showUser(item);
    }
  }

  showUser(user: User) {
    const marker = this.userMarkerMap.get(user.id);

    if (marker) {
      this.mapConfig.zoom = 16;
      this.mapConfig.center = marker.getPosition();
      this.map.setOptions(this.mapConfig);
      google.maps.event.trigger(marker, 'click');
    } else {
      this.errorHandler.showError('Could not find location on map');
    }
  }

  showSite(site: Site) {
    this.sitesLoadedSubject = new Subject<void>();
    this.sitesLoadedSubject.subscribe(() => {
      google.maps.event.trigger(this.siteMarkerMap.get(site.id), 'click');
      this.activeTooltip = {
        id: site.id,
        tooltip: this.siteTooltipMap.get(site.id),
        model: this.searchInputTypes.site,
      };
      this.sitesLoadedSubject.complete();
      this.sitesLoadedSubject = null;
    });

    this.mapConfig.zoom = 16;
    this.mapConfig.center = new google.maps.LatLng(site.lngLat[1], site.lngLat[0]);
    this.map.setOptions(this.mapConfig);
  }

  updateSite(updatedSite: { id: number; site: Site }) {
    if (!updatedSite.site) {
      this.onSiteDeleted(updatedSite.id);
    } else {
      this.onSiteUpdated(updatedSite.site);
    }
  }

  onSiteUpdated(updatedSite: Site) {
    this.sites = this.sites.map((site) => {
      if (site.id === updatedSite.id) {
        if (site.lngLat.toString() !== updatedSite.lngLat.toString()) {
          const location = new google.maps.LatLng(updatedSite.lngLat[1], updatedSite.lngLat[0]);
          this.map.setCenter(location);
        }

        if (site.ownerName !== updatedSite.ownerName || site.name !== updatedSite.name) {
          this.siteTooltipMap.get(updatedSite.id).setContent(this.mapService.generateSiteTooltip(updatedSite));
        }

        site = updatedSite;
      }
      return site;
    });
  }

  onSiteDeleted(id: number) {
    this.siteMarkerMap.get(id).setMap(null);
    this.siteMarkerMap.delete(id);

    this.sites = this.sites.filter((site) => {
      return site.id !== id;
    });
    this.mapSearch.inputElement.value = '';
    this.mapSearch.results = null;
    this.closeDetailView();
  }

  // onDateSelectedForUser(order: any) {

  // if (!sessionsForDate.sessions || !sessionsForDate.sessions.length) {
  // this.errorHandler.showError('No valid sessions found for selected date');
  //   return;
  // }

  //   if (this.mapType === this.mapTypes.map) {
  //     this.mapType = this.mapTypes.replay;
  //   }
  //
  //   if (sessionsForDate !== this.sessionsForReplay) {
  //     this.sessionsForReplay = sessionsForDate;
  //
  //     if (this.siteCluster) {
  //       this.siteCluster.clearMarkers();
  //     }
  //
  //     this.userMarkerMap.forEach(userMarker => {
  //       userMarker.setMap(null);
  //     });
  //
  //     this.clearReplayArtifacts();
  //     this.getWaypointsForSessions(order);
  //   }
  // }

  sliderChange() {
    const desiredTime =
      (moment(this.receivedOrder.endTime).diff(moment(this.receivedOrder.startTime)) / 1000) * (this.sliderValue / 100);
    const selectedWaypointTime = moment(this.receivedOrder.startTime)
      .add(desiredTime, 'seconds')
      .format();
    let diffTime = 0;
    for (const waypoint of this.wayRoute) {
      diffTime = moment(waypoint.timestamp).diff(moment(selectedWaypointTime)) / 1000;
      if (diffTime < 30 && diffTime >= 0) {
        this.selectedWaypoint = waypoint;
        break;
      }
    }
    this.truckMarker.setPosition(
      new google.maps.LatLng(this.selectedWaypoint.lngLat[1], this.selectedWaypoint.lngLat[0]),
    );
    this.map.setCenter(new google.maps.LatLng(this.selectedWaypoint.lngLat[1], this.selectedWaypoint.lngLat[0]));
  }

  loadWaypoint(event) {
    // const date = sessionsForReplay.date;
    // const sessions = sessionsForReplay.sessions;
    this.showSpinner = true;
    this.loadId = event.loadId;
    this.receivedOrder = event;
    const routes = [];
    // let loadedIndex = 0;)
    this.waypointService
      .loadWayPoints({
        UserId: event.id,
        StartTime: event.startTime,
        EndTime: event.endTime,
      })
      .subscribe(
        (route: Waypoint[]) => {
          if (route && route.length) {
            routes.push(route);
          }
          this.generatePolylinesForRoutes(routes);
          this.wayRoute = routes[0];
          this.selectedWaypoint = this.wayRoute[0];
          if (moment().diff(moment(event.endTime)) / 1000 >= 20) {
            this.showSlider = true;
          }
          this.sliderValue = 0;
          if (typeof this.truckMarker !== 'undefined') {
            this.truckMarker.setMap(null);
          }
          this.generateTruckMarker(this.wayRoute);
        },
        () => {
          this.errorHandler.showError('Error Loading Waypoints');
        },
      );
    this.loadWaypointsLog(event);
  }

  loadWaypointsLog(event) {
    this.waypointService
      .getWaypointsLog({
        UserId: event.id,
        StartTime: event.startTime,
        EndTime: event.endTime,
      })
      .subscribe(
        (data) => {
          this.waypointLogs = data;
        },
        () => {
          this.errorHandler.showError('Error Loading Logs');
        },
      );
  }

  generateTruckMarker(waypoint) {
    this.truckMarker = this.mapService.createTruckWaypointMarker(waypoint[0]);
    this.truckMarker.setMap(this.map);
  }

  generatePolylinesForRoutes(routes) {
    if (routes && routes.length) {
      let allWaypoints: Waypoint[] = [];

      routes.forEach((route) => {
        const polyline = new google.maps.Polyline({
          path: route.map((point) => {
            return { lat: point.lngLat[1], lng: point.lngLat[0] };
          }),
          strokeColor: '#448aff',
        });

        this.generatePolylineMarker(route[0], true);
        this.generatePolylineMarker(route[route.length - 1], false);

        polyline.setMap(this.map);

        this.polylines.push(polyline);

        allWaypoints = allWaypoints.concat(route);
      });

      // this.heatmap = new google.maps.visualization.HeatmapLayer(
      //   {
      //     data: this.mapService.convertWaypointsToLatLng(allWaypoints)
      //   }
      // );

      // this.heatmap.setMap(this.map);

      this.map.setCenter({ lat: allWaypoints[0].lngLat[1], lng: allWaypoints[0].lngLat[0] });
      this.map.setZoom(12);
    } else {
      this.errorHandler.showError('No waypoints found for selected day');
    }
  }

  generatePolylineMarker(waypoint: Waypoint, first: boolean) {
    const marker = first
      ? this.mapService.createRouteStartMarker(waypoint)
      : this.mapService.createRouteEndMarker(waypoint);
    const tooltip = this.mapService.createPolylineInfobox(waypoint, this.loadId, first);

    marker.addListener('click', () => {
      if (this.activeReplayTooltip) {
        this.activeReplayTooltip.close();
      }
      tooltip.open(this.map, marker);
      this.activeReplayTooltip = tooltip;
    });

    marker.setMap(this.map);
    this.replayMarkers.push(marker);
    this.showSpinner = false;
  }

  switchToMapMode() {
    this.mapType = this.mapTypes.map;
    this.clearReplayArtifacts();

    this.userMarkerMap.forEach((userMarker) => {
      userMarker.setMap(this.map);
    });
  }

  clearReplayArtifacts() {
    // if (this.heatmap) {
    //   this.heatmap.setMap(null);
    //   this.heatmap = null;
    // }

    this.polylines.forEach((polyline) => {
      polyline.setMap(null);
    });

    this.replayMarkers.forEach((marker) => {
      marker.setMap(null);
    });

    this.polylines = [];
    this.replayMarkers = [];
  }

  openDetailView(id: number, model: string) {
    this.detailView.open = true;
    this.detailView.id = id;

    if (model === this.searchInputTypes.site) {
      this.detailView.modelName = DetailViewModels.site;
    } else if (model === this.searchInputTypes.user) {
      this.detailView.modelName = DetailViewModels.driver;
    }
  }

  closeDetailView() {
    if (this.activeTooltip && this.activeTooltip.tooltip) {
      this.activeTooltip.tooltip.close();
      this.activeTooltip = null;
    }

    if (this.mapType === this.mapTypes.replay) {
      this.switchToMapMode();
    }

    this.detailView.open = false;
    this.detailView.id = null;
    this.detailView.modelName = null;
  }
}
