import { LmoCompletedOrdersService } from './lmo-completed-orders.service';
import { LmoConstantsService } from './lmo-constants.service';
import { LmoFracsService } from './lmo-fracs.service';
import { LmoOrdersService } from './lmo-orders.service';
import { LmoReportsService } from './lmo-reports.service';
import { LmoTrailerTypeContractService } from './lmo-trailer-type-contract.service';
import { LmoTruckingVendorsService } from './lmo-trucking-vendors.service';

export const services = [
  LmoOrdersService,
  LmoCompletedOrdersService,
  LmoFracsService,
  LmoReportsService,
  LmoTruckingVendorsService,
  LmoConstantsService,
  LmoTrailerTypeContractService,
];

export { LmoCompletedOrdersService as LmoCompletedService } from './lmo-completed-orders.service';
export { LmoOrdersService } from './lmo-orders.service';
export { LmoFracsService } from './lmo-fracs.service';
export { LmoTruckingVendorsService } from './lmo-trucking-vendors.service';
export { LmoReportsService } from './lmo-reports.service';
export { LmoConstantsService } from './lmo-constants.service';
export { LmoTrailerTypeContractService } from './lmo-trailer-type-contract.service';
