import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CrewDataService, CrewDataStatsForDate, FracForFilter } from '~services/crew-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'sa-crew-data-dashboard',
  templateUrl: './crew-data-dashboard.component.html',
  styleUrls: ['./crew-data-dashboard.component.scss'],
})
export class CrewDataDashboardComponent implements OnInit {
  public analyticsForm: FormGroup;
  displayNav = false;
  public isDownloading = false;

  activeDataSource: MatTableDataSource<CrewDataStatsForDate>;
  displayedColumns = ['date', 'fracBillingIdentifier', 'fracCrewName', 'totalLoads', 'totalMatched', 'totalUnmatched'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  showDashboardHeaders = true;
  filterLoader: FormControl;
  filteredFracs$: Observable<FracForFilter[]>;

  subscriptions: Subscription[] = [];

  public fracs$: Observable<FracForFilter[]>;

  public displayForm = false;

  public today: Date;

  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareById;

  constructor(private fb: FormBuilder, private crewData: CrewDataService) {
    this.today = new Date();
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

    this.crewData.loadFracsForFilter();

    this.fracs$ = this.crewData.fracsForFilter$;
  }

  ngOnInit(): void {
    this.setupForm();

    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;
  }

  private setupForm() {
    const startDate = new Date();
    const endDate = new Date();

    this.analyticsForm = this.fb.group({
      startDate: [startDate, [Validators.required]],
      endDate: [endDate, [Validators.required]],
      frac: [null],
    });

    this.filterLoader = this.fb.control('');

    this.filteredFracs$ = combineLatest([this.fracs$, this.filterLoader.valueChanges.pipe(startWith(''))]).pipe(
      map(([mines, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          const val = mines.filter(
            (option) =>
              option.crewName.toLowerCase().indexOf(filterValue) > -1 ||
              option.billingIdentifier.toLowerCase().indexOf(filterValue) > -1 ||
              option.fracId === this.analyticsForm.controls['frac'].value?.fracId,
          );
          return val;
        } else {
          return mines;
        }
      }),
    );
  }

  getAnalytics() {
    this.isDownloading = true;

    const startTime = this.analyticsForm.controls['startDate'].value;
    startTime.setHours(0, 0, 0);

    const endTime = this.analyticsForm.controls['endDate'].value;
    endTime.setHours(23, 59, 59);

    const fracId = this.analyticsForm.controls['frac'].value?.fracId;

    this.crewData.getCrewDataStatistics(startTime, endTime, fracId).subscribe((data) => {
      if (data) {
        this.activeDataSource.data = data;
      } else {
        this.activeDataSource.data = [];
      }
      this.isDownloading = false;
    });
  }

  openForm() {
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  compareById(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }
}
