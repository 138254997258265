import { Injectable } from '@angular/core';
import { environment } from '~environments/environment';
import { CrudService } from '~services/crud.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BulkLoadUploadPayload, ImportLogsResponse } from '../bulk-load-upload/bulk-load-upload.model';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BulkLoadUploadService {
  private logs$$: BehaviorSubject<ImportLogsResponse> = new BehaviorSubject<ImportLogsResponse>(null);

  public get getLogs$(): Observable<ImportLogsResponse> {
    return this.logs$$.asObservable().pipe(share());
  }

  constructor(private crudService: CrudService) {
    this.crudService.userAuthenticated.subscribe((called: boolean): void => {
      if (called) {
        this.getImportLogs();
      }
    });
  }

  public getImportLogs(): void {
    this.crudService.get(`${environment.api}/bulk_load_upload_logs`).subscribe((logs) => {
      this.logs$$.next(logs);
    });
  }

  public bulkLoadUpload(payload: BulkLoadUploadPayload[]): Observable<any> {
    return this.crudService.post(`${environment.api}/orders/bulk`, payload);
  }
}
