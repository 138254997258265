export const LOGISTICS_CLUSTER_ID = 'logistics_runboard_id';
export const LOGISTICS_CLUSTER_USER_ID = 'logistics_runboard_user_id';
export const TRUCKING_CONTRACT_ID = 'trucking_contract_id';

export const LOADER_INTEGRATION_MINE_ID = 'loader_integration_mine_id';
export const LOADER_INTEGRATION_DATE = 'loader_integration_date';
export const LOADER_INTEGRATION_LOADER_DATA_ID = 'loader_integration_loader_data_id';
export const LOADER_INTEGRATION_PURCHASE_ORDER_ID = 'loader_integration_purchase_order_id';

export const CREW_DATA_FRAC_ID = 'crew_data_frac_id';
export const CREW_DATA_DATE = 'crew_data_date';
