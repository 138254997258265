import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../services/user.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { LmoTruckingVendorsService } from '~lmo/services';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { TruckingVendorContract, TruckingVendor } from '~lmo/models/trucking-vendor.model';
import { fuse } from '~utilities/fuse';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, map } from 'rxjs/operators';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../app-routing.constants';
import { Router } from '@angular/router';
import { trackById } from '~utilities/trackById';

const searchOptions: Fuse.FuseOptions<TruckingVendorContract> = {
  distance: 100,
  keys: ['vendorName', 'name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1,
};

@Component({
  selector: 'sa-trucking',
  templateUrl: './trucking.component.html',
  styleUrls: ['./trucking.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(-0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TruckingComponent implements OnInit {
  public networkActive$: Observable<boolean>;
  public activeContracts$: Observable<TruckingVendorContract[]>;
  public archivedContracts$: Observable<TruckingVendorContract[]>;
  public displayNav$$ = new BehaviorSubject<boolean>(false);
  public contractSearch: FormControl = new FormControl();
  public selectedContract$: Observable<TruckingVendorContract>;
  public allVendors$: Observable<TruckingVendor[]>;
  public createNew$$ = new BehaviorSubject<TruckingVendorContract | {}>(null);
  public trackById = trackById;
  public userLabel: { name: string; email: string; account: string };

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private userService: UserService,
    private lmoTruckingVendorService: LmoTruckingVendorsService,
    private routerService: RouterStateService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.networkActive$ = this.lmoTruckingVendorService.networkActive$;
    this.userLabel = this.userService.getLabel();
    this.activeContracts$ = combineLatest(
      this.lmoTruckingVendorService.allContracts$.pipe(
        map((contracts) => {
          return contracts.filter((contract) => !contract.archived);
        }),
        map(fuse<TruckingVendorContract>(searchOptions)),
      ),
      this.contractSearch.valueChanges.pipe(debounceTime(150), startWith('')),
    ).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.data;
        }
        return fused.fuse.search(searchTerm) as any;
      }),
    );
    this.archivedContracts$ = combineLatest(
      this.lmoTruckingVendorService.allContracts$.pipe(
        map((contracts) => {
          return contracts.filter((contract) => contract.archived);
        }),
        map(fuse<TruckingVendorContract>(searchOptions)),
      ),
      this.contractSearch.valueChanges.pipe(debounceTime(150), startWith('')),
    ).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.data;
        }
        return fused.fuse.search(searchTerm) as any;
      }),
    );
    this.allVendors$ = this.lmoTruckingVendorService.vendors$;
    this.selectedContract$ = combineLatest(
      this.routerService.listenForParamChange$(fromRouterConstants.TRUCKING_CONTRACT_ID),
      this.activeContracts$,
      this.allVendors$,
    ).pipe(
      map(([id, contracts]) => {
        if (!id || id === 'list' || !contracts) {
          return null;
        }
        return contracts.find((contract) => contract.id === +id);
      }),
    );
  }

  public closeVendorForm(event: MouseEvent) {
    this.router.navigate(['/', 'trucking-contracts']);
  }

  public closeNewContractForm() {
    this.createNew$$.next(null);
  }
}
