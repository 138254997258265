import { Pipe, PipeTransform } from '@angular/core';
import { Observable, interval, Subject, of, isObservable } from 'rxjs';
import { switchMap, map, takeUntil, skip, startWith } from 'rxjs/operators';

@Pipe({
  name: 'progressiveRender',
  pure: false,
})
export class ProgressiveRenderPipe implements PipeTransform {
  private currentObj: any;
  private currentObservable: Observable<any>;
  private happenedAlready = false;
  transform<T>(value: T | Observable<T>, renderDelay = 20, numberToRenderAtATime = 20): Observable<T> {
    if (isObservable(value)) {
      if (!this.currentObj || value !== this.currentObj) {
        this.currentObj = value;
        this.currentObservable = this.setupObservable(value, renderDelay, numberToRenderAtATime);
      }
      return this.currentObservable;
    } else if (this.happenedAlready) {
      return of(value);
    } else {
      return this.setupObservable(value, renderDelay, numberToRenderAtATime);
    }
  }

  private setupObservable<T>(value: T | Observable<T>, renderDelay = 10, numberToRenderAtATime = 10): Observable<T> {
    return (isObservable(value) ? value : of(value)).pipe(
      switchMap((t: T) => {
        if (Array.isArray(t) && t.length > 0 && !this.happenedAlready) {
          this.happenedAlready = true;
          const stop = new Subject();
          const arrayLength = t.length;
          return interval(renderDelay).pipe(
            // Skip one so that we render the last x percent since stop will kill the interval
            takeUntil(stop.pipe(skip(1))),
            startWith(-1), // Start rendering immediately
            map((count) => {
              const limit = Math.min(Math.ceil(numberToRenderAtATime * (count + 2)), arrayLength);
              if (limit === arrayLength) {
                stop.next();
              }
              return (t.slice(0, limit) as any) as T;
            }),
          );
        }
        return of(t);
      }),
    );
  }
}
