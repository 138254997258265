import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isLogisticsCluster, RunboardSiteSummary, UnclusteredRunboardSites } from '~dispatcher/models/frac.model';
import { DispatcherFracsService } from '~dispatcher/services/dispatcher-fracs.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-dispatcher-logistics-cluster-add',
  styleUrls: ['./dispatcher-logistics-cluster-add.component.scss'],
  templateUrl: './dispatcher-logistics-cluster-add.component.html',
})
export class DispatcherLogisticsClusterAddComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<null>();
  public submitPressed$ = new BehaviorSubject(false);
  public nameControl: FormControl = new FormControl(null, [Validators.required]);
  public loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public clusterId: number;
  public unclusteredFracs: UnclusteredRunboardSites;
  public selectedFracs: RunboardSiteSummary[];

  constructor(
    private fracService: DispatcherFracsService,
    private dialogRef: MatDialogRef<DispatcherLogisticsClusterAddComponent, any>,
  ) {}

  public ngOnInit() {
    const clusters$ = this.fracService.fracClusterSummaries$;

    clusters$.pipe(takeUntil(this.destroy$)).subscribe((allClusters) => {
      const unclustered = allClusters.filter((cluster) => !isLogisticsCluster(cluster));
      if (unclustered && unclustered.length) {
        this.unclusteredFracs = unclustered[0] as UnclusteredRunboardSites;
      }
    });

    this.selectedFracs = [];
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  public drop(event: CdkDragDrop<RunboardSiteSummary[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  public create() {
    this.submitPressed$.next(true);
    if (this.nameControl.invalid || this.selectedFracs.length === 0) {
      return;
    }
    this.loading$$.next(true);
    this.fracService
      .createCluster$(
        this.nameControl.value,
        this.selectedFracs.map((fracSummary) => fracSummary.id),
      )
      .subscribe((successful) => {
        if (successful) {
          this.dialogRef.close();
        }
        this.loading$$.next(false);
      });
  }

  public isSemiAuto(frac: RunboardSiteSummary) {
    return frac.autopilotLevel === 'semi';
  }

  public isFullAuto(frac: RunboardSiteSummary) {
    return frac.autopilotLevel === 'full';
  }
}
