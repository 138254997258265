import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';
import { Session } from '../../models/session';
import { Observable } from 'rxjs';

@Injectable()
export class SessionApiService {
  get route(): string {
    return environment.api + '/session';
  }

  constructor(private crud: CrudService) {}

  getSessions(params?): Observable<Session[]> {
    return this.crud.get(this.route, params);
  }
}
