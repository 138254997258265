import {
  Component,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { InputTypes } from '../../constants/global-search-models';

@Injectable()
@Component({
  selector: 'sa-map-search-results',
  templateUrl: './map-search-results.component.html',
  styleUrls: ['./map-search-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapSearchResultsComponent implements OnChanges {
  @Input() items: any[];
  @Input() searchText: string;
  @Output() onItemSelected: EventEmitter<any> = new EventEmitter();
  @Output() onItemFocused: EventEmitter<any> = new EventEmitter();
  @Output() onEscKey: EventEmitter<void> = new EventEmitter();
  @Output() onCharacterKey: EventEmitter<string> = new EventEmitter();
  @Output() onSelectAll: EventEmitter<void> = new EventEmitter();
  @ViewChildren('resultsList') results: QueryList<any>;

  focusedIndex = -1;
  searchInputTypes = InputTypes;

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      this.onSelectAll.emit();
    }

    switch (event.key) {
      case 'ArrowDown': {
        if (this.focusedIndex === this.items.length - 1) {
          this.focusedIndex = 0;
        } else {
          this.focusedIndex++;
        }
        this.results.toArray()[this.focusedIndex].nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.onItemFocused.emit({ resultNotEmpty: !!this.items.length, item: this.items[this.focusedIndex] });
        break;
      }
      case 'ArrowUp': {
        if (this.focusedIndex === 0) {
          this.focusedIndex = this.items.length - 1;
        } else {
          this.focusedIndex--;
        }
        this.results.toArray()[this.focusedIndex].nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.onItemFocused.emit({ resultNotEmpty: !!this.items.length, item: this.items[this.focusedIndex] });
        break;
      }
      case 'Enter': {
        if (this.results.toArray().length) {
          this.selectItem(this.items[this.focusedIndex]);
        }
        break;
      }
      case 'Escape': {
        this.onEscKey.emit();
        break;
      }
      default: {
        if (event.key.length === 1 || event.key === 'Backspace') {
          this.onCharacterKey.emit(event.key);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.focusedIndex = -1;
    }
  }

  selectItem(item) {
    this.onItemSelected.emit(item);
  }
}
