import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AccountFeatureFlagsService } from '~services/account-feature-flags.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-video-training',
  styleUrls: ['./dispatcher-video-training.component.scss'],
  templateUrl: './dispatcher-video-training.component.html',
})
export class DispatcherVideoTrainingComponent implements OnInit {
  constructor(public accountFeatureFlags: AccountFeatureFlagsService) {}

  public ngOnInit() {}
}
