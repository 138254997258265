import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sa-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  navOpen$$ = new BehaviorSubject<boolean>(false);

  isTruckVendor = false;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {
    if (this.userService.isDispatcherAccount()) {
      this.isTruckVendor = true;
      if (!this.isOkayRoute()) {
        this.router.navigate(['/', 'map', 'jobs'], { replaceUrl: true });
      }
    } else if (this.userService.isLMOAccount()) {
      this.router.navigate(['/', 'wells'], { replaceUrl: true });
    } else if (this.userService.isSandmanAccount()) {
      this.router.navigate(['/', 'sand-vendor'], { replaceUrl: true });
    }
  }

  isOkayRoute() {
    // Any first paths after /map/ that are in acceptablePaths will not automatically redirect
    const acceptablePaths = ['jobs', 'runboard', 'logistics-runboard'];
    const snapshot = this.route.snapshot;
    if (!snapshot.firstChild) {
      return false;
    }

    return snapshot.firstChild.url[0] && acceptablePaths.includes(snapshot.firstChild.url[0].path);
  }

  openMenu() {
    this.navOpen$$.next(true);
  }

  closeMenu() {
    this.navOpen$$.next(false);
  }

  ngOnInit(): void {}
}
