<div class="back-layer" (click)="closeClicked(false)"></div>
<div class="add-vendor-form">
  <div class="vendors">
    <div class="vendor-header">
      <span *ngIf="!selectedRow">New Contract</span>
      <span *ngIf="selectedRow">Contract Details</span>
      <div
        class="go-back"
        saAutomatedSelector="truck-contract__close-contract"
        (click)="closeClicked(false)"
        (longPressed)="closeClicked(false)"
        saLongEscape
        [showTooltip]="false"
      >
        esc <i class="material-icons close">close</i>
      </div>
    </div>

    <div class="content">
      <div class="user-inputs-container">
        <div class="content-header">Contract Details</div>
        <form [formGroup]="vendorForm" (ngSubmit)="onSubmit()">
          <div class="form-field">
            <label>Trucking Company</label>
            <div>
              {{ (vendorForm?.controls)['vendorName'].value }}
            </div>
          </div>
          <div class="form-field">
            <label>Contract Name</label>
            <mat-form-field>
              <input
                matInput
                [formControl]="vendorForm.controls['name']"
                type="text"
                autocomplete="off"
                placeholder=""
                saAutomatedSelector="truck-contract__contract-name"
              />
            </mat-form-field>
          </div>
          <div class="form-field" style="position: relative">
            <label>Expiration Date</label>
            <mat-form-field>
              <input
                [min]="today()"
                matInput
                autocomplete="off"
                [formControl]="vendorForm.controls['expirationTime']"
                [owlDateTime]="expirationTimeDatePicker"
                [owlDateTimeTrigger]="expirationTimeDatePicker"
                readonly
              />
              <owl-date-time [pickerType]="'calendar'" #expirationTimeDatePicker></owl-date-time>
            </mat-form-field>
            <label style="position: absolute; right: 0; pointer-events: auto; margin-bottom: 8px;">
              <ng-container>
                <a class="clear-exp-date" (click)="clearExpirationDate()">
                  Clear
                </a>
              </ng-container>
            </label>
          </div>

          <div class="content-header">Detention</div>

          <div class="form-field">
            <label>Hourly Detention ($)</label>
            <mat-form-field>
              <span class="dollar-span"
                >$&nbsp;
                <input
                  matInput
                  [formControl]="vendorForm.controls['hourlyDetentionRate']"
                  type="number"
                  autocomplete="off"
                  placeholder=""
                  saAutomatedSelector="truck-contract__hourly-detention"
                />
              </span>
            </mat-form-field>
          </div>
          <div class="form-field">
            <label>Max Detention ($)</label
            ><mat-form-field>
              <span class="dollar-span"
                >$&nbsp;
                <input
                  matInput
                  [formControl]="vendorForm.controls['maxDetentionPerLoad']"
                  type="number"
                  autocomplete="off"
                  placeholder=""
                  saAutomatedSelector="truck-contract__max-detention"
                />
              </span>
            </mat-form-field>
          </div>
          <div class="help-text">The maximum detention that a single load can incur.<br /></div>
          <div class="form-field">
            <label>Pickup Free Time (minutes)</label>
            <mat-form-field>
              <input
                matInput
                [formControl]="vendorForm.controls['pickupFreeTime']"
                type="number"
                autocomplete="off"
                placeholder=""
                saAutomatedSelector="truck-contract__pickup-time"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <label>Dropoff Free Time (minutes)</label>
            <mat-form-field>
              <input
                matInput
                [formControl]="vendorForm.controls['dropoffFreeTime']"
                type="number"
                autocomplete="off"
                placeholder=""
                saAutomatedSelector="truck-contract__dropoff-time"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <label>Deadhead Free Distances (Miles)</label>
            <mat-form-field>
              <input
                matInput
                [formControl]="vendorForm.controls['deadheadFreeMileage']"
                type="number"
                autocomplete="off"
                placeholder=""
                saAutomatedSelector="truck-contract__dead-head-distance"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <label>Deadhead Cost ($/mile)</label>
            <mat-form-field>
              <span class="dollar-span"
                >$&nbsp;
                <input
                  matInput
                  [formControl]="vendorForm.controls['deadheadCostPerMile']"
                  type="number"
                  autocomplete="off"
                  placeholder=""
                  saAutomatedSelector="truck-contract__dead-head-cost"
                />
              </span>
            </mat-form-field>
          </div>
          <div class="form-field" *saFeatureFlag="'deadheadLinkFrom'">
            <label>Allow Deadhead Linking</label>
            <mat-checkbox formControlName="allowDeadheadLinking"></mat-checkbox>
          </div>

          <div class="line-haul-cost">
            <div class="content-header">Line Haul Cost</div>
          </div>

          <div class="bracket_type">
            <mat-button-toggle-group
              #costGroup="matButtonToggleGroup"
              [(ngModel)]="costType"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-button-toggle value="fixed">Fixed Cost</mat-button-toggle>
              <mat-button-toggle value="per_ton">Per Ton Cost</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div
            class="mileage-bracket-2"
            fxLayout="column"
            fxLayoutAlign="space-between"
            formArrayName="costsPerLoad"
            *ngFor="let load of vendorForm['controls'].costsPerLoad['controls']; let i = index"
          >
            <h3>Mileage Bracket {{ i + 1 }}</h3>
            <div [formGroupName]="i" class="mileage-input">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  saAutomatedSelector="truck-contract-new-mileage__min-mileage"
                  placeholder="Min. miles"
                  formControlName="minimumMileage"
                />
                <mat-error *ngIf="load.controls['minimumMileage'].hasError('min')">
                  Input should be greater than 0
                </mat-error>
              </mat-form-field>
              <span>to</span>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  saAutomatedSelector="truck-contract-new-mileage__max-mileage"
                  placeholder="Max miles"
                  formControlName="maximumMileage"
                />
              </mat-form-field>
              <mat-form-field>
                <span class="dollar-span"
                  >$&nbsp;
                  <input
                    matInput
                    type="number"
                    saAutomatedSelector="truck-contract-new-mileage__cost"
                    placeholder="Cost"
                    formControlName="cost"
                  />
                </span>
                <mat-error *ngIf="load.controls['cost'].hasError('min')">
                  Cost should be greater than 0
                </mat-error>
              </mat-form-field>
              <div class="remove-line-haul-cost">
                <mat-icon
                  (click)="removeLoad(i)"
                  *ngIf="i > 0"
                  class="line-haul-button"
                  saAutomatedSelector="truck-contract__remove-line-haul"
                  >remove_circle</mat-icon
                >
              </div>
            </div>
          </div>
          <div class="line-haul-actions">
            <mat-icon
              (click)="addCostPerLoad()"
              saAutomatedSelector="truck-contract__add-line-haul"
              class="line-haul-button"
              >add_circle</mat-icon
            >
          </div>

          <div class="button-wrap">
            <ng-container *ngIf="selectedRow">
              <button sa-button saAutomatedSelector="contracts__edit-button">
                Edit Contract
              </button>
              <button
                type="button"
                sa-danger-button
                (click)="deleteTruckVendorContract()"
                saAutomatedSelector="contracts__delete-button"
              >
                Delete Contract
              </button>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
