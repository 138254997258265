import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { WellApiService } from '~services/api/well.api.service';
import { OrderApiService } from '~services/api/order.api.service';
import { StoreService } from '~services/store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MapUtilService } from '~services/map-util.service';
import { UserService } from '~services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlingService } from '~services/error-handling.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { RunboardService } from '~services/runboard.service';
import { HelperService } from '~services/helper.service';
import { CompletedOrdersService } from '../../../completed-orders.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { BulkDeclineDialogComponent } from '../../bulk-decline-dialog/bulk-decline-dialog.component';
import { Order } from '~models/order.model';
import { DistributionCenter } from '~lmo/models/distributionCenter.model';

@Component({
  selector: 'sa-dc-detail',
  templateUrl: './dc-detail.component.html',
  styleUrls: ['./dc-detail.component.scss'],
})
export class DcDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') public className = 'flex-1 overflow-auto';
  public optedInToNewBillingPortal = true;

  get tabs(): string[] {
    return ['Pending', 'In Progress', 'Completed'];
  }

  selectedTab = 'Pending';
  orders = [];
  loadingCompletedDc = true;
  error = '';
  driverStatus = '';
  selectedState = 0;
  downloadProgress = false;
  isLmoAccount = false;
  isDispatcherCrewAccount = false;
  selectedDC: DistributionCenter;
  pendingOrders = [];
  inProgressOrders = [[], [], []];
  allProgressOrders = [[], [], []];
  pageInit = false;
  subscriptions: Subscription[] = [];
  selectedPendingOrders: any[] = [];
  declining = false;
  selectedId: number;
  public is5f$$ = new BehaviorSubject<boolean>(false);

  constructor(
    public datePipe: DatePipe,
    private wellApiService: WellApiService,
    private orderApiService: OrderApiService,
    private store: StoreService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private mapUtilService: MapUtilService,
    private userService: UserService,
    private dialog: MatDialog,
    private errorHandler: ErrorHandlingService,
    private featureFlagService: FeatureFlagService,
    private runboardService: RunboardService,
    public helperService: HelperService,
    public completedOrdersService: CompletedOrdersService,
  ) {}

  ngOnInit() {
    this.loadingCompletedDc = true;
    this.pageInit = true;
    this.selectedId = Number(this.route.snapshot.params['id']);
    this.completedOrdersService.loadOrdersForDistributionCenter(this.selectedId);
    let tab = this.route.snapshot.params['tab'].toLowerCase();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        tab = val.url.split('/')[6];
        this.setTab(tab);
      }
    });
    this.setTab(tab);
    this.isLmoAccount = this.userService.isLMOAccount();
    this.isDispatcherCrewAccount = this.userService.isDispatcherAccount() && this.userService.isCrewLeader();
    this.loadData();

    this.subscriptions.push(
      interval(5 * 60 * 1000)
        .pipe(switchMap(() => this.featureFlagService.isFlagActive('fracDetailPolling').pipe(take(1))))
        .subscribe((isFlagActive) => {
          if (isFlagActive && this.selectedId) {
            this.store.loadSingleDistributionCenter(this.selectedId);
          }
        }),
    );
  }

  isVorto() {
    return this.userService.isShaleappsEmail() || this.userService.isVortoUser();
  }

  loadData() {
    this.store.loadSingleDistributionCenter(this.selectedId);
    this.subscriptions.push(
      combineLatest([this.store.getDCById(this.selectedId), this.is5f$$.asObservable()]).subscribe(
        ([selectedDC, is5F]) => {
          this.selectedDC = selectedDC;
          this.loadingCompletedDc = false;
          this.calculateViews(this.selectedDC, is5F);
          // TODO: DC: handle DCs on map
          if (this.pageInit) {
            // this.mapUtilService.updateMap({ frac: this.selectedDc, state: this.selectedState, order: null });
            this.pageInit = false;
          }
        },
      ),
    );
  }

  goToInvoicing() {
    // TODO: DC: DC billing pages
    // this.router.navigateByUrl(
    //   '/fracs/' + parseInt(this.route.snapshot.params['id'], 10) + '/orders/needDataForTicketing',
    // );
  }

  setTab(tab) {
    if (tab === 'pending') {
      this.selectedTab = 'Pending';
      this.selectedState = 2;
    } else if (tab === 'progress') {
      this.selectedTab = 'In Progress';
      this.selectedState = 3;
    } else if (tab === 'completed') {
      this.selectedTab = 'Completed';
      this.selectedState = 4;
    } else if (tab === 'runboard') {
      this.selectedTab = 'Run Board';
    }
  }

  checkForEditableOrder(order) {
    switch (order.billingStatus) {
      case 'approved_by_dispatcher':
        return 'This load cannot be edited while waiting for ticket approval.';
      case 'billed':
        return 'This load cannot be edited while waiting for invoice approval';
      case 'approved':
        return 'This load cannot be edited as it has already been billed.';
      default:
        return '';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((_) => _.unsubscribe());
  }

  calculateViews(selectedDC: DistributionCenter, is5F) {
    const pendingOrders = [];

    const inPrgDispatch = [];
    let inPrgLoaded = [],
      inPrgNotLoaded = [];

    if (selectedDC.orders) {
      selectedDC.orders.forEach((order) => {
        if (order.orderStatus === 'pending' || order.orderStatus === 'driver_rejected') {
          pendingOrders.push(order);
        } else if (order.orderStatus === 'dispatched') {
          inPrgDispatch.push(order);
        } else if (order.orderStatus === 'driver_accepted') {
          if (order.loaded) {
            inPrgLoaded.push(order);
          } else {
            inPrgNotLoaded.push(order);
          }
        }
      });
    }
    inPrgNotLoaded = inPrgNotLoaded.sort((val1, val2) => {
      val1.eta = val1.eta ? val1.eta : 0;
      val2.eta = val2.eta ? val2.eta : 0;
      return val2.eta - val1.eta;
    });

    inPrgLoaded = inPrgLoaded.sort((val1, val2) => {
      val1.eta = val1.eta ? val1.eta : 0;
      val2.eta = val2.eta ? val2.eta : 0;
      return val2.eta - val1.eta;
    });

    this.pendingOrders = [...pendingOrders];

    this.allProgressOrders = [[...inPrgDispatch], [...inPrgNotLoaded], [...inPrgLoaded]];
    if (is5F) {
      const inPrgDispatchFiltered = this.allProgressOrders[0].filter((order) => order.is5f);
      const inPrgNotLoadedFiltered = this.allProgressOrders[1].filter((order) => order.is5f);
      const inPrgLoadedFiltered = this.allProgressOrders[2].filter((order) => order.is5f);
      this.inProgressOrders = [[...inPrgDispatchFiltered], [...inPrgNotLoadedFiltered], [...inPrgLoadedFiltered]];
    } else {
      this.inProgressOrders = this.allProgressOrders;
    }
  }

  calculateTime(timestamp) {
    const now = new Date().getTime();
    const created = new Date(timestamp).getTime();
    return Math.round(now - created);
  }

  downloadCSV() {
    // TODO: DC: implement for DCs
    // if (!this.downloadProgress) {
    //   this.downloadProgress = true;
    //   this.wellApiService.downloadVendorRecordsUrl(this.selectedDC.id).subscribe(
    //     (data) => {
    //       window.open(data, '_blank');
    //       this.downloadProgress = false;
    //     },
    //     (error) => {
    //       this.downloadProgress = false;
    //       this.errorHandler.showError('Error downloading the file.', 5000);
    //     },
    //   );
    // }
  }

  checkForMissing(order): string {
    this.error = 'Missing ';
    const missing = [];
    let miss = false;
    if (order.ticketNumber === '') {
      missing.push('Ticket Number');
    }
    if (order.bolNumber === '') {
      missing.push('BOL');
    }
    if (order.boxes) {
      if (!miss) {
        order.boxes.forEach((box) => {
          if (!box.id) {
            missing.push('Box ID');
            miss = true;
          }
          if (!box.loadWeight) {
            miss = true;
            missing.push('Box Load Weight');
          }
        });
      }
    }
    missing.forEach((item, index) => {
      if (index === 0) {
        this.error = this.error + item;
      } else {
        this.error = this.error + ', ' + item;
      }
    });
    return this.error;
  }

  missingItem(order): boolean {
    if (order.ticketNumber === '') {
      return true;
    }
    if (order.bolNumber === '') {
      return true;
    }
    if (!order.boxes) {
      return true;
    }
  }

  calculateReceivedTime(time) {
    if (time) {
      const sec = Math.round((new Date().getTime() - new Date(time).getTime()) / 1000);
      const mins = Math.round(sec / 60);
      const hrs = Math.round(mins / 60);
      const days = Math.round(hrs / 24);
      if (days > 0) {
        return `${days}d ago`;
      } else if (hrs > 0) {
        if (mins > 0) {
          return `${hrs}h ${mins % 60}m ago`;
        } else {
          return `${hrs}h`;
        }
      } else if (mins > 0) {
        if (sec > 0) {
          return `${mins}m ${sec % 60}s ago`;
        } else {
          return `${mins}m ago`;
        }
      } else {
        return `${sec}s ago`;
      }
    } else {
      return '';
    }
  }

  calculateEta(minutes) {
    return this.datePipe.transform(new Date(new Date().getTime() + minutes * 60000), 'shortTime');
  }

  checkForDriver(order): Boolean {
    if (!order || !order.user) {
      return false;
    }
    if (order.user.logs) {
      this.driverStatus = 'Call Driver to have them Enable Location Services';
      return true;
    }
    if (order.user.activeSession) {
      const now = new Date().getTime();
      const lastSeen = new Date(order.user.lastTimeSeen).getTime();
      if ((now - lastSeen) / (1000 * 60) > 30) {
        this.driverStatus = 'Driver Unreachable';
        return true;
      }
    } else {
      this.driverStatus = 'Call Driver to login again';
      return true;
    }
    return false;
  }

  selectTab(tab) {
    this.selectedTab = tab;
    if (tab !== 'Pending') {
      this.selectedPendingOrders = [];
    }
    let param = 'pending';
    if (tab === 'In Progress') {
      param = 'progress';
    } else if (tab === 'Completed') {
      param = 'completed';
    } else if (tab === 'Run Board') {
      param = 'runBoard';
    }
    this.router.navigate(['../' + param], { relativeTo: this.route });
    this.selectedState = 2;
    if (tab === 'In Progress') {
      // state = 3;
      this.selectedState = 3;
    } else if (tab === 'Completed') {
      this.selectedState = 4;
    }
    this.mapUtilService.updateMap({ frac: this.selectedDC, state: this.selectedState, order: null });
    // this.onTabChange.emit(tab);
  }

  onSelection(e, v) {
    this.selectedPendingOrders = [];
    for (const a of v) {
      this.selectedPendingOrders.push(a.value);
    }
  }

  bulkDeclineOrders() {
    this.declining = true;
    const dialogRef = this.dialog.open(BulkDeclineDialogComponent, {
      width: '25%',
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe((declineReason: string) => {
      if (declineReason) {
        const orderIDs = this.selectedPendingOrders.map((order: Order) => order.id);
        this.orderApiService.declineBulkOrders(orderIDs, declineReason).subscribe(
          (res) => {
            this.snackBar.open('Orders successfully declined', null, {
              duration: 2000,
            });
            this.declining = false;
            this.loadData();
            this.selectedPendingOrders = [];
          },
          (err) => {
            this.declining = false;
            this.errorHandler.showError('Orders declining Failed');
          },
        );
      } else {
        this.declining = false;
      }
    });
  }

  addDriver(order) {
    this.router.navigate(['./' + order.loadNumber], { relativeTo: this.route });
  }

  selectOrder(order) {
    this.router.navigate(['./order/' + order.loadNumber], { relativeTo: this.route });
  }

  goBack() {
    this.router.navigate(['/map/jobs']);
  }

  trackByFn(index, item) {
    return item.id;
  }

  recommendedPool(): Observable<string> {
    return this.runboardService.driverPool$.pipe(
      filter((summary) => !!summary && summary.shouldShow),
      map((summary) => summary.message),
    );
  }
}
