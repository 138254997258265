<h1 class="title" mat-dialog-title>Confirm Stage Information</h1>
<h3 class="sub-title">It looks like you may have mistyped some stock information. Please review:</h3>

<mat-dialog-content>
  <ul class="list-wrap" *ngFor="let issue of issues">
    <h3>{{ issue.name }}</h3>
    <li class="sub-list" *ngFor="let field of issue.fields; trackBy: trackBy" [innerHTML]="field"></li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="continue-btn" mat-button [mat-dialog-close]="true" (click)="continueValidation()">
    Continue Anyway
  </button>
  <button mat-flat-button color="primary" mat-dialog-close tabindex="-1" (click)="cancelValidation()">Fix Data</button>
</mat-dialog-actions>
