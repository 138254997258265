import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { RunboardLogisticsCluster, UnclusteredRunboardSites } from '~dispatcher/models/frac.model';
import { DispatcherFracsService } from '~dispatcher/services/dispatcher-fracs.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-dispatcher-frac-list',
  styleUrls: ['./dispatcher-frac-list.component.scss'],
  templateUrl: './dispatcher-frac-list.component.html',
})
export class DispatcherFracListComponent implements OnInit {
  public isScrolled$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fracLogClusters$: Observable<(RunboardLogisticsCluster | UnclusteredRunboardSites)[]>;
  constructor(private fracService: DispatcherFracsService) {}

  public get isScrolled$(): Observable<boolean> {
    return this.isScrolled$$.asObservable().pipe(distinctUntilChanged());
  }

  public ngOnInit() {
    this.fracLogClusters$ = this.fracService.fracClusterSummaries$.pipe();
  }

  public trackById(cluster: RunboardLogisticsCluster) {
    if (cluster && cluster.isCluster) {
      return cluster.id;
    }
    return 'unclustered';
  }

  public handleScroll(event) {
    if (event.target.scrollTop >= 72) {
      this.isScrolled$$.next(true);
    } else {
      this.isScrolled$$.next(false);
    }
  }
}
