import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export function LatitudeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const valid = control.value >= -90 && control.value <= 90;
    return valid ? null : { latitude: { value: control.value } };
  };
}

export function LongitudeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const valid = control.value >= -180 && control.value <= 180;
    return valid ? null : { longitude: { value: control.value } };
  };
}

export function StorageMeshValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return;
    }

    const storageGroups = nestedGroupBy(control.value, 'mesh.type');

    let valid = true;

    for (const group in storageGroups) {
      if (storageGroups[group].length > 1) {
        valid = false;
      }
    }

    return valid ? null : { storageMesh: { value: control.value } };
  };
}

export function StorageCurrentStockValidator(capacityKey: string, currentStockKey: string) {
  return (group: FormGroup) => {
    const capacityControl = group.get(capacityKey);
    const currentStockControl = group.get(currentStockKey);

    if (!capacityControl || !currentStockControl) {
      return;
    }

    if (!capacityControl.value) {
      return;
    }

    return currentStockControl.value <= capacityControl.value
      ? currentStockControl.setErrors(null)
      : currentStockControl.setErrors({ capacity: true });
  };
}

export function StorageSafetyStockValidator(capacityKey: string, safetyStockKey: string) {
  return (group: FormGroup) => {
    const capacityControl = group.get(capacityKey);
    const safetyStockControl = group.get(safetyStockKey);

    if (!capacityControl || !safetyStockControl) {
      return;
    }

    if (!capacityControl.value) {
      return;
    }

    let error = null;

    if (safetyStockControl.hasError('min')) {
      error = {};
      error.min = safetyStockControl.getError('min');
    }

    if (safetyStockControl.value <= capacityControl.value) {
    } else {
      if (error == null) {
        error = {};
      }
      error.capacity = true;
    }
    safetyStockControl.setErrors(error);

    return;
  };
}

export function DateValidator(oldDateControlName: string, newDateControlName: string) {
  return (group: FormGroup) => {
    const oldDateControl = group.get(oldDateControlName);
    const newDateControl = group.get(newDateControlName);

    if (!oldDateControl || !newDateControl) {
      return;
    }

    if (!oldDateControl.value) {
      return;
    }

    return oldDateControl.value <= newDateControl.value
      ? newDateControl.setErrors(null)
      : newDateControl.setErrors({ older: true });
  };
}

export function ScheduleValidator(emailControlName: string, scheduleControlName: string) {
  return (group: FormGroup) => {
    const emailControl = group.get(emailControlName);
    const scheduleControl = group.get(scheduleControlName);

    if (!emailControl || !scheduleControl) {
      return;
    }

    if (!emailControl.value) {
      return;
    }

    return scheduleControl.value ? scheduleControl.setErrors(null) : scheduleControl.setErrors({ required: true });
  };
}

export function PasswordValidator(oldDateControlName: string, newDateControlName: string) {
  return (group: FormGroup) => {
    const oldDateControl = group.get(oldDateControlName);
    const newDateControl = group.get(newDateControlName);

    if (!oldDateControl || !newDateControl) {
      return;
    }

    return oldDateControl.value === newDateControl.value
      ? newDateControl.setErrors(null)
      : newDateControl.setErrors({ diff: true });
  };
}

export function EmailListValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return;
    }

    let valid = true;

    const separatedEmails = control.value.split(',').map((email) => email.trim());

    separatedEmails.forEach((email) => {
      if (
        // tslint:disable-next-line
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        valid = false;
      }
      if (email.lastIndexOf('.con') + 4 === email.length) {
        valid = false;
      }
    });

    return valid ? null : { email: { value: control.value } };
  };
}

function nestedGroupBy(array, property) {
  const hash = {};
  const props = property.split('.');
  for (let i = 0; i < array.length; i++) {
    const key = props.reduce(function(acc, prop) {
      return acc && acc[prop];
    }, array[i]);
    if (!hash[key]) {
      hash[key] = [];
    }
    hash[key].push(array[i]);
  }
  return hash;
}

export function BillingIdentificationValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return;
    }

    return control.value.trim().length === 9 ? null : { billingIdentifierLengthError: true };
  };
}
