import { Component, OnInit, HostBinding } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { VersioningService } from '../services/versioning.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'sa-versioning',
  templateUrl: './versioning.component.html',
  styleUrls: ['./versioning.component.scss'],
})
export class VersioningComponent implements OnInit {
  @HostBinding('class') classes = 'version-warning';
  @HostBinding('class.wrong-version') isWrongVersion = false;

  constructor(
    private swUpdate: SwUpdate,
    private versioningService: VersioningService,
    public deviceService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.swUpdate.available.pipe().subscribe(() => {
      this.isWrongVersion = true;
    });

    this.versioningService.isWrongVersion$.subscribe((isWrongVersion) => {
      if (this.deviceService.browser === 'IE') {
        this.isWrongVersion = isWrongVersion;
      }
    });
  }
}
