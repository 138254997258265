import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { IntercomService } from 'src/app/intercom.service';

export const OPT_IN_NEW_BILLING_PORTAL = 'opt_in_to_new_billing_portal';

@Component({
  selector: 'sa-new-billing-portal-opt-in',
  templateUrl: './new-billing-portal-opt-in.component.html',
  styleUrls: ['./new-billing-portal-opt-in.component.scss'],
})
export class NewBillingPortalOptInComponent implements OnInit {
  public isOptedIn = false;

  constructor(
    private router: Router,
    private matDialogRef: MatDialogRef<NewBillingPortalOptInComponent>,
    private intercomService: IntercomService,
  ) {}

  ngOnInit() {
    this.isOptedIn = JSON.parse(localStorage.getItem(OPT_IN_NEW_BILLING_PORTAL) || 'false');
  }

  async optInOrOutOfNewBillingPortal(optIn: boolean) {
    localStorage.setItem(OPT_IN_NEW_BILLING_PORTAL, JSON.stringify(optIn));
    this.intercomService.setIsUsingNewBillingPortal(optIn);
    if (optIn) {
      try {
        const success = await this.router.navigate(['/', 'invoicing']);
        if (!success) {
          window.location.href = './invoicing';
        }
      } catch (error) {
        console.error(error);
        window.location.href = './invoicing';
      }
    } else {
      await this.router.navigate(['/', 'billing']);
    }
    this.matDialogRef.close();
  }
}
