<div class="back-layer"></div>

<div class="email-form-component">
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span>Upload Crew Data File</span>
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!fileForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="fileForm" [formGroup]="fileForm" autocomplete>
      <div class="form-field">
        <label>Frac</label>

        <mat-form-field>
          <mat-select placeholder="Frac" [compareWith]="compareFn" [formControl]="fileForm.controls['frac']">
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'Frac'"
                [noEntriesFoundLabel]="'No matching frac found'"
                [formControl]="filterFrac"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let frac of filteredFracs$ | async" [value]="frac">
              {{ frac.billingIdentifier }} - {{ frac.jobName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fileForm.controls['frac'].hasError('required')">
            Input is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="w-full">
        <input
          class="w-full"
          type="file"
          placeholder="Upload File"
          (change)="fileChanged($event.target.files)"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
    </form>

    <div class="button-wrap">
      <button
        *ngIf="fileForm"
        mat-flat-button
        color="primary"
        [disabled]="!fileForm.controls['uploadFile'].valid || !fileForm.controls['frac'].valid"
        (click)="onSubmit($event)"
      >
        <span>Upload</span>
      </button>
    </div>
  </div>
</div>
