import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagService } from '~services/feature-flag.service';

/*
 * Append the appropriate unit to a weight on the PO and loader analytics dashboards,
 * based on the shortTonsOnPOAndLoaderDashboards feature flag.
 */
@Pipe({
  name: 'poLoaderPoundTonUnit',
})
export class PoLoaderPoundTonUnitPipe implements PipeTransform {
  private isTons = false;

  constructor(private featureFlagService: FeatureFlagService) {
    this.featureFlagService.flags$.subscribe((flags) => {
      this.isTons = flags.shortTonsOnPOAndLoaderDashboards;
    });
  }

  transform(value: number | string): string {
    if (this.isTons) {
      return value + ' tons';
    }

    return value + ' lbs';
  }
}
