import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService, accountTypesFriendlyStrings, accountTypes } from '~services/user.service';

/*
  Checks if the user is the correct account type (LMO, trucking vendor, sand vendor, etc) and allows for optional user permissions to be passed in too
  matching requires ANY account type and ALL user permissions
*/

@Directive({
  selector: '[saAccountType]',
})
export class AccountTypeDirective implements OnInit {
  private requiredAccountTypes: number[];
  private requiredPermissions: string[];
  private hasView = false;
  private elseRef: TemplateRef<any>;
  private hasElseView = false;
  private initialized = false;

  constructor(
    private userService: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.initialized = true;
    this.recheckView();
  }

  @Input() set saAccountType(requiredAccountTypesString: accountTypesFriendlyStrings[]) {
    const requiredAccountTypes = [];
    if (requiredAccountTypesString && requiredAccountTypesString.length) {
      requiredAccountTypesString.forEach((accountType) => {
        if (accountType in accountTypes) {
          requiredAccountTypes.push(accountTypes[accountType]);
        }
      });
    }
    this.requiredAccountTypes = requiredAccountTypes;

    if (this.initialized) {
      this.recheckView();
    }
  }

  @Input() set saAccountTypeWithPermissions(requiredPermissions: string[]) {
    this.requiredPermissions = requiredPermissions;

    if (this.initialized) {
      this.recheckView();
    }
  }

  @Input() set saAccountTypeElse(elseView: TemplateRef<any>) {
    this.elseRef = elseView;

    if (this.initialized) {
      this.recheckView();
    }
  }

  private recheckView() {
    if (!this.requiredAccountTypes || !this.requiredAccountTypes.length) {
      this.showView();
      return;
    }
    const userContract = this.userService.getUserContractFromCache();
    if (!userContract) {
      this.clearView();
      return;
    }

    const { account } = userContract;
    if (!account) {
      this.clearView();
      return;
    }

    const hasCorrectAccountType = this.requiredAccountTypes.includes(account.type.id);
    if (!hasCorrectAccountType) {
      this.clearView();
      return;
    }

    if (this.requiredPermissions && this.requiredPermissions.length) {
      const { permissions = [] } = userContract;
      const hasEveryPermission = this.requiredPermissions.every((requiredPermission) =>
        permissions.includes(requiredPermission),
      );
      if (hasEveryPermission) {
        this.showView();
      } else {
        this.clearView();
      }
    } else {
      this.showView();
    }
  }

  private showView() {
    if (this.hasElseView) {
      this.viewContainer.clear();
      this.hasElseView = false;
    }
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    this.cd.markForCheck();
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
    if (this.elseRef) {
      this.viewContainer.createEmbeddedView(this.elseRef);
      this.hasElseView = true;
    }
    this.cd.markForCheck();
  }
}
