<sa-nav [visible]="displayNav" (onMenuClose)="displayNav = false"></sa-nav>

<div class="completed-order-component">
  <div class="top-menu">
    <div class="side-menu">
      <img
        class="menu-icon"
        src="../../../assets/icons/menu-24.svg"
        (click)="menuClick()"
        saAutomatedSelector="menu__open"
      />
      <div class="new-billing-portal">
        We are working on a new billing portal - <span class="opt-in" (click)="optIn()">try it out</span>
      </div>
    </div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="../../../assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel?.email || userLabel?.name || 'Unknown' }}
    </div>
  </div>

  <div class="head-wrap">
    <a class="back-wrap" [routerLink]="backToWellsUrl()">
      <i class="material-icons">keyboard_arrow_left</i>
    </a>
    <h1>{{ wellName }}</h1>
    <a class="export-btn" *ngIf="!downloadProgress" (click)="exportAll()">Export All</a>
    <mat-spinner *ngIf="downloadProgress" [diameter]="20"></mat-spinner>
  </div>

  <div class="ordered-after-filter">
    <h3>Ordered After:</h3>
    <mat-form-field>
      <input
        matInput
        class="datepicker-input"
        placeholder="Ordered After"
        autocomplete="off"
        [formControl]="orderedAfter"
        [max]="todayDate"
        [owlDateTime]="orderedAfterFilter"
        [owlDateTimeTrigger]="orderedAfterFilter"
      />
      <owl-date-time #orderedAfterFilter></owl-date-time>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="clearOrderedAfterFilter()">Clear</button>
  </div>

  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="[link.path]"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      [saAutomatedSelector]="link.selector"
    >
      {{ link.label }}
      <span class="count-wrap" *ngIf="checkCountForTab(link.path)">{{ counts[linkMap[link.path]] }}</span>
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
