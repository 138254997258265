<h2 mat-dialog-title>New Billing Portal</h2>
<mat-dialog-content>
  <p>
    Great News! We have enhanced the billing portal for you to improve the software speed, and your overall experience.
  </p>
  <p>During this transition, you will be able to switch back to the old billing portal at any time.</p>

  <div class="actions" *ngIf="isOptedIn; else optedOut">
    <button mat-raised-button color="primary" [mat-dialog-close] color="primary">
      Yes, lets use the new billing portal!
    </button>
    <button mat-raised-button (click)="optInOrOutOfNewBillingPortal(false)" color="accent">
      No, I would like to continue using the old billing portal.
    </button>
  </div>
  <ng-template #optedOut>
    <div class="actions">
      <button mat-raised-button color="primary" color="primary" (click)="optInOrOutOfNewBillingPortal(true)">
        Yes, lets use the new billing portal!
      </button>
      <button mat-raised-button [mat-dialog-close] color="accent">
        No, I would like to continue using the old billing portal.
      </button>
    </div>
  </ng-template>
</mat-dialog-content>
