import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilteredAutocompleteComponent),
      multi: true,
    },
  ],
  selector: 'sa-filtered-autocomplete',
  templateUrl: './filtered-autocomplete.component.html',
  styleUrls: ['./filtered-autocomplete.component.scss'],
})
export class FilteredAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Output() public selected = new EventEmitter();
  @Output() public onSelectedEvent = new EventEmitter<string>();
  public selectOptions$$ = new BehaviorSubject<any[]>([]);
  public filteredOptions$: Observable<any[]>;
  public groupedSelectOptions$$ = new BehaviorSubject<{ name: string; data: any[] }[]>([]);
  public groupedFilteredOptions$: Observable<{ name: string; data: any[] }[]>;
  public isSelecting$$ = new BehaviorSubject<boolean>(false);
  public searchBox = new FormControl();
  public formControl = new FormControl();
  public get isDisabled$(): Observable<boolean> {
    if (this.disableButton) {
      return of(true);
    }
    return combineLatest(this.selectOptions$$, this.groupedSelectOptions$$).pipe(
      map(([selected, grouped]) => {
        if (!this.disabledOnEmpty) {
          return false;
        }
        if (!selected && !grouped) {
          return true;
        }
        return selected.length + grouped.length === 0;
      }),
    );
  }
  @ViewChild('autoTrigger', { static: true }) private triggerInput: ElementRef;
  @ViewChild('auto', { static: true }) private auto: MatAutocomplete;

  @Input() disableButton = false;
  @Input() disabledOnEmpty = true;
  @Input() emptyPlaceholderText;
  @Input() placeholderText = 'Select';

  @Input() set selectOptions(selectOptions: any[]) {
    this.selectOptions$$.next(selectOptions || []);
  }

  @Input() set groupedSelectOptions(groupedSelectOptions: { name: string; data: any[] }[]) {
    this.groupedSelectOptions$$.next(groupedSelectOptions || []);
  }

  @Input() public displayFn: (any) => string = (value: any) => {
    return `${value}`;
  };

  get buttonText(): string {
    return this.formControl.value && this.formControl.value !== ''
      ? this.displayFn(this.formControl.value)
      : this.placeholderText;
  }

  get selector(): string {
    return this.buttonText.toLocaleLowerCase().replace(' ', '_');
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.filteredOptions$ = combineLatest(
      this.searchBox.valueChanges.pipe(
        startWith(''),
        map((value: string | any) => (typeof value === 'string' ? value : this.displayFn(value))),
      ),
      this.selectOptions$$,
    ).pipe(
      map(([searchText, options]) => {
        const filterValue = searchText.toLowerCase();
        return options.filter((option) =>
          this.displayFn(option)
            .toLowerCase()
            .includes(filterValue),
        );
      }),
    );

    this.groupedFilteredOptions$ = combineLatest(
      this.searchBox.valueChanges.pipe(
        startWith(''),
        map((value: string | any) => (typeof value === 'string' ? value : this.displayFn(value))),
      ),
      this.groupedSelectOptions$$,
    ).pipe(
      map(([searchText, options]) => {
        const filterValue = searchText.toLocaleLowerCase();
        return options
          .map((option) => ({
            name: option.name,
            data: option.data.filter((datum) =>
              this.displayFn(datum)
                .toLocaleLowerCase()
                .includes(filterValue),
            ),
          }))
          .filter((option) => option.data.length > 0);
      }),
    );

    this.auto.optionSelected.subscribe((selected: MatAutocompleteSelectedEvent) => {
      this.formControl.setValue(selected.option.value);
    });

    this.auto.closed.subscribe(() => {
      this.isSelecting$$.next(false);
      this.searchBox.setValue('');
    });

    this.formControl.valueChanges.subscribe((value) => {
      this.selected.emit(value);
    });
  }

  public startSelection() {
    this.isSelecting$$.next(true);
    this.searchBox.setValue('');
    // Let everything settle then focus element for easy search
    new Promise((resolve) => setTimeout(resolve, 10)).then(() => {
      this.triggerInput.nativeElement.focus();
    });
  }

  public onSelection(event: string, option: any) {
    this.onSelectedEvent.emit(option);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.formControl.setValue(obj, { emitEvent: false });
    } else {
      this.formControl.setValue(null, { emitEvent: false });
    }
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {}
}
