import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService } from '~services/error-handling.service';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '~services/user.service';
import { EmailSettingsV2Service } from '~services/email-settings-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import {
  EmailData,
  LoaderIntegrationDataForLoad,
  LoaderIntegrationLoad,
  LoaderIntegrationStatsForDate,
  PotentialMatch,
} from '~models/emailSettings.model';

@Component({
  selector: 'sa-matches-for-load',
  templateUrl: './matches-for-load.component.html',
  styleUrls: ['./matches-for-load.component.scss'],
})
export class MatchesForLoadComponent implements OnInit, OnDestroy {
  activeDataSource: MatTableDataSource<PotentialMatch>;
  displayedColumns = [
    'orderId',
    'matchReasons',
    'siteVisitStart',
    'siteVisitEnd',
    'poNumber',
    'bolNumber',
    'truckNumber',
    'orderLoadWeight',
    'boxNames',
    'setMatch',
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  subscriptions: Subscription[] = [];

  public loadData$: Observable<LoaderIntegrationDataForLoad>;

  public loaderTimezone: string;

  constructor(
    private fb: FormBuilder,
    public emailSettingsService: EmailSettingsV2Service,
    private errorHandler: ErrorHandlingService,
    public userService: UserService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;

    this.loadData$ = this.emailSettingsService.loadDataForMatches$;

    this.subscriptions.push(
      this.emailSettingsService.potentialMatchesForLoad$.subscribe((matches) => {
        if (matches) {
          this.activeDataSource.data = matches;
        } else {
          this.activeDataSource.data = [];
        }
      }),
      this.loadData$.subscribe((data) => {
        if (data) {
          this.loaderTimezone = data.loaderTimezone;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  public toMatchReasonText(matchReason: string, match: PotentialMatch): string {
    const matchReasonMap = {
      site_visit: 'Mine Site Visit Time',
      bol_number: 'BOL Number',
      po_number: 'PO Number',
      truck_number: 'Truck Number',
    };

    if (matchReason === 'partial_truck_number') {
      return `Partial Truck Number (${match.partialTruckNumberMatchLength} chars)`;
    }

    return matchReasonMap[matchReason];
  }

  public isSiteVisitMatch(match: PotentialMatch): boolean {
    return !!match?.matchReasons?.find((reason) => reason === 'site_visit');
  }

  public isPONumberMatch(match: PotentialMatch): boolean {
    return !!match?.matchReasons?.find((reason) => reason === 'po_number');
  }

  public isBOLNumberMatch(match: PotentialMatch): boolean {
    return !!match?.matchReasons?.find((reason) => reason === 'bol_number');
  }

  public isTruckNumberMatch(match: PotentialMatch): boolean {
    return !!match?.matchReasons?.find((reason) => reason === 'truck_number');
  }

  public isPartialTruckNumberMatch(match: PotentialMatch): boolean {
    return !!match?.matchReasons?.find((reason) => reason === 'partial_truck_number');
  }

  public truckNumberMatchTooltip(match: PotentialMatch): string {
    if (match?.matchReasons?.find((reason) => reason === 'partial_truck_number')) {
      return `Matches first ${match.partialTruckNumberMatchLength} characters of truck number`;
    } else {
      return 'Matches truck number';
    }
  }

  public markGoodMatch(match: PotentialMatch): void {
    this.emailSettingsService
      .setLoaderIntegrationDataMatch(match.potentialMatchId, match.orderId, match.loaderIntegrationDataId)
      .subscribe(
        (res) => {
          this.emailSettingsService.refreshPotentialMatchesForLoad();
        },
        (err) => {
          this.errorHandler.showError('Failed to set loader integration data match');
        },
      );
  }

  public unmatchOrder(match: PotentialMatch): void {
    this.emailSettingsService
      .unsetLoaderIntegrationDataMatch(match.potentialMatchId, match.orderId, match.loaderIntegrationDataId)
      .subscribe(
        (res) => {
          this.emailSettingsService.refreshPotentialMatchesForLoad();
        },
        (err) => {
          this.errorHandler.showError('Failed to set loader integration data match');
        },
      );
  }
}
