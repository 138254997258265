<div class="logistics-cluster-add">
  <form (ngSubmit)="create()">
    <mat-form-field>
      <input
        matInput
        placeholder="Cluster Name"
        [formControl]="nameControl"
        autocomplete="off"
        saAutomatedSelector="cluster_name"
      />
    </mat-form-field>
    <div class="dragable">
      <div class="example-container">
        <h2>Non Clustered</h2>
        <div
          cdkDropList
          id="unclustered"
          #unclusteredList="cdkDropList"
          [cdkDropListData]="unclusteredFracs.siteSummaries"
          [cdkDropListConnectedTo]="[selectedClusterList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="example-box"
            *ngFor="let item of unclusteredFracs.siteSummaries"
            cdkDrag
            saAutomatedSelector="unclustered_well__row"
            [automatedName]="item.name"
          >
            {{ item.name }}
            <div class="semi-auto" *ngIf="isSemiAuto(item)" matTooltip="Auto Ordering" matTooltipPosition="left">
              <img class="autopilot-icon" src="/assets/icons/autopilot.svg" />
            </div>
            <div
              class="full-auto"
              *ngIf="isFullAuto(item)"
              matTooltip="Auto Ordering + Auto Dispatching"
              matTooltipPosition="left"
            >
              <img class="autopilot-icon" src="/assets/icons/lmo-full-auto.svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="example-container">
        <h2>{{ nameControl.value || '(Enter Name)' }}</h2>

        <div
          cdkDropList
          #selectedClusterList="cdkDropList"
          id="newCluster"
          [cdkDropListData]="selectedFracs"
          [cdkDropListConnectedTo]="[unclusteredList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"
          [class.warn]="(submitPressed$ | async) && selectedFracs.length === 0"
          saAutomatedSelector="unclustered_well_drop"
        >
          <div class="example-box" *ngFor="let item of selectedFracs" cdkDrag>
            {{ item.name }}
            <div class="semi-auto" *ngIf="isSemiAuto(item)" matTooltip="Auto Ordering" matTooltipPosition="left">
              <img class="autopilot-icon" src="/assets/icons/autopilot.svg" />
            </div>
            <div
              class="full-auto"
              *ngIf="isFullAuto(item)"
              matTooltip="Auto Ordering + Auto Dispatching"
              matTooltipPosition="left"
            >
              <img class="autopilot-icon" src="/assets/icons/lmo-full-auto.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-row">
      <button mat-stroked-button color="primary" saAutomatedSelector="unclustered_well_create-cluster">
        Create Cluster
      </button>
    </div>
  </form>
</div>

<div class="loading" *ngIf="loading$$ | async">
  <mat-spinner></mat-spinner>
</div>
