import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, merge, interval, of } from 'rxjs';
import { CrudService } from '~services/crud.service';
import { map, filter, take, switchMap, share, takeWhile, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { RouterStateService } from '~services/router-state.service';
import { ErrorHandlingService } from '~services/error-handling.service';

export interface Waypoint {
  id: number;
  longitude: number;
  latitude: number;
  timestamp: string;
  accuracy: number;
  speed: number;
  createTimestamp: string;
}

interface MapInfo {
  waypoints: Waypoint[];
}

@Injectable({
  providedIn: 'root',
})
export class DriverReplayService {
  private heatmapActive = false;
  private waypoints$$: BehaviorSubject<Waypoint[]> = new BehaviorSubject([]);
  private selectedWaypoint$$: BehaviorSubject<Waypoint> = new BehaviorSubject(null);
  private selectedIndex$$: BehaviorSubject<number> = new BehaviorSubject(0);
  private loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private playing$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private speed$$: BehaviorSubject<number> = new BehaviorSubject(1);
  private player$: Observable<number> = merge(this.playing$, this.speed$).pipe(
    switchMap(() => {
      if (this.playing$$.value) {
        return interval(1000 / this.speed$$.value).pipe(takeUntil(this.playing$.pipe(filter((playing) => !playing))));
      }
      return of(null);
    }),
  );

  public get waypoints$(): Observable<Waypoint[]> {
    return this.waypoints$$.asObservable().pipe(share());
  }

  public get selectedIndex$(): Observable<number> {
    return this.selectedIndex$$.asObservable().pipe(share());
  }

  public get selectedWaypoint$(): Observable<Waypoint> {
    return combineLatest(this.waypoints$, this.selectedIndex$)
      .pipe(
        map(([waypoints, index]) => {
          if (waypoints.length) {
            return waypoints[index];
          }
          return null;
        }),
      )
      .pipe(share());
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$.asObservable().pipe(share());
  }

  public get playing$(): Observable<boolean> {
    return this.playing$$.asObservable().pipe(
      distinctUntilChanged(),
      share(),
    );
  }

  public get speed$(): Observable<number> {
    return this.speed$$.asObservable().pipe(share());
  }

  constructor(
    private crudService: CrudService,
    private routerState: RouterStateService,
    private errorHandler: ErrorHandlingService,
  ) {
    this.routerState.routerState$.subscribe((state) => {
      if (this.heatmapActive && (state.params.tab !== 'completed' || !state.params.load)) {
        this.clear();
      }
    });

    this.player$.subscribe(() => {
      const waypoints = this.waypoints$$.value;
      const currentIndex = this.selectedIndex$$.value;
      if (this.playing$$.value && currentIndex + 1 < waypoints.length) {
        this.selectedIndex$$.next(currentIndex + 1);
      } else {
        this.playing$$.next(false);
      }
    });
  }

  private clear() {
    this.waypoints$$.next([]);
    this.selectedWaypoint$$.next(null);
    this.heatmapActive = false;
  }

  public loadOrderMapInfo(fracId: number, loadNumber: number): void {
    this.loading$$.next(true);
    this.clear();
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/v2/order/map_info`, { fracId, loadNumber })),
      )
      .subscribe(
        (response: MapInfo) => {
          this.waypoints$$.next(response.waypoints);
          this.setActiveWaypoint(0);
          this.heatmapActive = true;
          this.loading$$.next(false);
        },
        (error) => {
          this.errorHandler.showError(error);
          this.loading$$.next(false);
        },
      );
  }

  public setActiveWaypoint(index: number) {
    const length = this.waypoints$$.value.length;
    if (index >= 0 && index < length) {
      this.selectedIndex$$.next(index);
    }
  }

  public setSpeed(speed: number) {
    if (speed > 0) {
      this.speed$$.next(speed);
    }
  }

  public goToEnd() {
    const waypoints = this.waypoints$$.value;
    if (waypoints.length > 0) {
      this.selectedIndex$$.next(waypoints.length - 1);
    }
  }

  public goToBeginning() {
    this.selectedIndex$$.next(0);
  }

  public play() {
    this.playing$$.next(true);
  }

  public pause() {
    this.playing$$.next(false);
  }
}
