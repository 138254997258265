import { Directive, forwardRef, Renderer2, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { pluck, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';

const onlyNumbers = /\D/g;

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputDirective),
      multi: true,
    },
    CurrencyPipe,
  ],
  selector: '[saCurrencyInput]',
})
export class CurrencyInputDirective implements ControlValueAccessor {
  @Input() public set disabledValue(value: number) {
    this.value = value;
    this.writeValue(value);
  }
  type: string;
  onChange: Function;
  onTouched: Function;
  value: number;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private currencyPipe: CurrencyPipe) {
    this.type = (_elementRef.nativeElement as HTMLInputElement).type;
    if (this.type !== 'text') {
      console.warn('currency pipe without text input');
    }
    // Wait for next tick so we can grab the initial value
    setTimeout(() => {
      fromEvent(this._elementRef.nativeElement, 'input')
        .pipe(
          tap((event: Event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
          }),
          pluck('target', 'value'),
          startWith(this._elementRef.nativeElement.value),
          distinctUntilChanged(),
        )
        .subscribe((value) => {
          const minusSignCount = `${value}`.split('-');
          const inputOnlyNumberString = (value as string).replace(onlyNumbers, '');
          const inputOnlyNumber = parseInt(inputOnlyNumberString, 10) || 0;
          this.writeValue(inputOnlyNumber * Math.pow(-1, minusSignCount.length - 1));
        });
    }, 0);
  }

  writeValue(input: number): void {
    const inDollars = input / 100;
    if (this.type === 'text') {
      const formattedField = this.currencyPipe.transform(inDollars);
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
    } else {
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', inDollars);
    }
    if (this.onChange) {
      // Do it on the next tick for rendering purposes
      setTimeout(() => this.onChange(input), 0);
    }
  }
  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
