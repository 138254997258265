import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../services/notifications.service';
import { Notification } from '~models/notifications.model';

@Component({
  selector: 'sa-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss'],
})
export class NotificationPreferencesComponent implements OnInit {
  constructor(public notificationService: NotificationsService) {}

  ngOnInit() {}

  notificationTrackBy(_index, notification: Notification) {
    return notification.id;
  }
}
