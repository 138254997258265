import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { TruckingVendorContract } from '~lmo/models/trucking-vendor.model';
import { trackById } from '~utilities/trackById';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LmoTruckingVendorsService } from '~lmo/services';

@Component({
  selector: 'sa-trucking-list-table',
  templateUrl: './trucking-list-table.component.html',
  styleUrls: ['../trucking.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TruckingListTableComponent implements OnInit {
  public networkActive$: Observable<boolean>;
  public contracts$: Observable<TruckingVendorContract[]>;
  public createNew$$ = new BehaviorSubject<TruckingVendorContract | {}>(null);
  public userLabel: { name: string; email: string; account: string };
  public trackById = trackById;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private lmoTruckingVendorService: LmoTruckingVendorsService, private snackBar: MatSnackBar) {}

  @Input() set contracts(contracts: Observable<TruckingVendorContract[]>) {
    this.contracts$ = contracts;
  }

  ngOnInit() {}

  public closeNewContractForm() {
    this.createNew$$.next(null);
  }

  public downloadVendorContractPdf(contract: TruckingVendorContract) {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.lmoTruckingVendorService.downloadVendorContractPdf$(contract.id, currentTimezone).subscribe(
      (data) => {
        window.open(data, '_blank');
        this.snackBar.open('Successfully downloaded the PDF', null, {
          duration: 5000,
        });
      },
      (error) => {
        this.snackBar.open('Failed to download the PDF', null, {
          duration: 5000,
        });
      },
    );
  }
}
