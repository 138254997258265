import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailSettingsService } from '~services/email-settings.service';
import { ErrorHandlingService } from '~services/error-handling.service';
import { interval, Subscription } from 'rxjs';
import { UserService } from '~services/user.service';

interface SiteData {
  date: string;
  numberOfEmails: number;
  totalOrders: number;
  ordersProcessed: number;
  dataMatched: number;
}
interface LoaderIntegration {
  accountName: string;
  siteName: string;
  email: string;
}
@Component({
  selector: 'sa-loader-analytics-dashboard',
  templateUrl: './loader-analytics-dashboard.component.html',
  styleUrls: ['./loader-analytics-dashboard.component.scss'],
})
export class LoaderAnalyticsDashboardComponent implements OnInit, OnDestroy {
  public analyticsForm: FormGroup;
  displayNav = false;
  public todayDate;
  public isDownloading = false;
  public analylticData;
  public loaderIntegration: LoaderIntegration[];
  datesMap = [];
  userLabel: { name: string; email: string; account: string };
  analytics: {
    [accoutName: string]: {
      [siteName: string]: SiteData[];
    };
  };
  showAccountData: { [id: string]: boolean };
  showAccountDataKeys: string[];
  filterType = 'showAll';
  loaderData: Map<string, LoaderIntegration[]> = new Map(null);
  SiteDateMap: {
    accountName: string;
    siteName: string;
    data: string;
  }[] = [];
  showDashboardHeaders = true;
  subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    public emailSettingsService: EmailSettingsService,
    private errorHandler: ErrorHandlingService,
    public userService: UserService,
  ) {}

  ngOnInit() {
    this.todayDate = new Date();
    this.setupForm();
    this.userLabel = this.userService.getLabel();
    this.subscriptions.push(interval(300000).subscribe(() => this.getAnalytics()));
  }

  private setupForm() {
    this.analyticsForm = this.fb.group({
      startDate: [this.todayDate, [Validators.required]],
      endDate: [this.todayDate, [Validators.required]],
    });
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }
  getAnalytics() {
    this.isDownloading = true;
    const startTime = this.analyticsForm.controls['startDate'].value.setHours(0, 0, 0);
    const endTime = this.analyticsForm.controls['endDate'].value.setHours(23, 59, 59);

    this.analytics = null;
    this.SiteDateMap = [];
    const body = {
      startDate: new Date(startTime),
      endDate: new Date(endTime + 86400000),
    };
    this.emailSettingsService.getLoaderAnalytics(body).subscribe(
      (data) => {
        const dates = {
          startDate: new Date(startTime),
          endDate: new Date(endTime),
        };
        this.datesMap = [];
        while (dates.startDate.getTime() < endTime) {
          this.datesMap.push(dates.startDate.toLocaleDateString());
          dates.startDate.setDate(dates.startDate.getDate() + 1);
        }
        this.analylticData = data.statistics;
        this.loaderIntegration = data.loaderIntegrations;
        this.loaderData = new Map(null);
        if (this.analylticData.length > 0) {
          this.loaderIntegration.forEach((loader) => {
            this.showAccountData = {
              ...this.showAccountData,
              [loader.accountName]: true,
            };
            const LoaderInfo: LoaderIntegration = {
              accountName: loader.accountName,
              siteName: loader.siteName,
              email: loader.email,
            };
            if (this.loaderData && this.loaderData.has(loader.accountName)) {
              this.loaderData.get(loader.accountName).length > 0
                ? this.loaderData.set(loader.accountName, [...this.loaderData.get(loader.accountName), LoaderInfo])
                : this.loaderData.set(loader.accountName, [LoaderInfo]);
            } else {
              this.loaderData.set(loader.accountName, [LoaderInfo]);
            }
          });
          this.analylticData.forEach((stats) => {
            const siteMapData = {
              date: new Date(stats.date).toLocaleDateString(),
              numberOfEmails: stats.numberOfEmails,
              totalOrders: stats.totalOrders,
              ordersProcessed: stats.ordersProcessed,
              dataMatched: stats.dataMatched,
            };
            if (!this.analytics) {
              this.analytics = {
                [stats.accountName]: {
                  [stats.siteName]: [siteMapData],
                },
              };
            } else {
              if (!this.analytics[stats.accountName]) {
                this.analytics = {
                  ...this.analytics,
                  [stats.accountName]: {
                    [stats.siteName]: [siteMapData],
                  },
                };
              } else {
                const checkIndex = Object.keys(this.analytics[stats.accountName]).findIndex(
                  (site) => site === stats.siteName,
                );
                if (checkIndex >= 0) {
                  this.analytics[stats.accountName][stats.siteName].push(siteMapData);
                } else {
                  this.analytics[stats.accountName] = {
                    ...this.analytics[stats.accountName],
                    [stats.siteName]: [siteMapData],
                  };
                }
              }
            }
          });
          this.showAccountDataKeys = Object.keys(this.showAccountData);
          this.checkForNoOrders();
        }
        this.isDownloading = false;
      },
      (err) => {
        this.errorHandler.showError(err);
        this.isDownloading = false;
      },
    );
  }
  getDateInfo(siteMapData: SiteData) {
    if (siteMapData.numberOfEmails === 0 && siteMapData.dataMatched === 0) {
      return 'No Emails Recieved';
    } else {
      return `${((siteMapData.dataMatched / siteMapData.totalOrders) * 100).toFixed(2)}%`;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }
  getColorCoding(site: string) {
    if (site.includes('No Orders')) {
      return 'gray';
    } else if (site.includes('No Emails Recieved')) {
      return 'light-blue';
    } else {
      const accuracy = +site.replace('%', '');
      if (accuracy < 60) {
        return 'error';
      } else if (60 <= accuracy && accuracy < 85) {
        return 'medium';
      } else if (accuracy >= 85) {
        return 'green';
      }
    }
  }
  getSiteDisplayData(accountName: string, site: string, date: string) {
    if (this.analytics && this.analytics[accountName] && this.analytics[accountName][site]) {
      const analytics = this.analytics[accountName][site];
      const index = this.getOrderIndex(analytics, date);
      if (index >= 0) {
        return this.getDateInfo(analytics[index]);
      } else {
        return 'No Orders';
      }
    } else {
      return 'No Orders';
    }
  }
  getSiteDate(accountName: string, site: string, date: string) {
    if (this.analytics && this.analytics[accountName] && this.analytics[accountName][site]) {
      const analytics = this.analytics[accountName][site];
      const index = this.getOrderIndex(analytics, date);
      if (index >= 0) {
        return analytics[index];
      }
    }
  }
  getOrderIndex(analytics, date) {
    return analytics.findIndex((analytic) => analytic.date === date);
  }
  checkForNoOrders() {
    if (this.filterType === 'showAll') {
      // this.checkShowAll = true;
      this.showAccountDataKeys.forEach((accountName) => {
        this.showAccountData[accountName] = true;
      });
    } else {
      this.showAccountDataKeys.forEach((accountName) => {
        this.showAccountData[accountName] = false;
      });
      Object.keys(this.analytics).forEach((accountName) => {
        Object.keys(this.analytics[accountName]).forEach((site) => {
          if (this.filterType === 'hideOrderEmail') {
            if (this.analytics[accountName][site]) {
              this.analytics[accountName][site].forEach((data) => {
                if (data.numberOfEmails > 0) {
                  this.showAccountData[accountName] = true;
                }
              });
            }
          } else {
            this.showAccountData[accountName] = true;
          }
        });
      });
    }
  }
  checkForLoaderData(accountName: string, site: string) {
    if (this.filterType === 'showAll') {
      return true;
    } else {
      if (this.analytics && this.analytics[accountName] && this.analytics[accountName][site]) {
        const analytics = this.analytics[accountName][site];
        if (this.filterType === 'hideOrderEmail') {
          const index = analytics.findIndex((data) => data.numberOfEmails > 0);
          if (index >= 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }
  getToolTip(accountName: string, site: string, date: string) {
    if (this.analytics && this.analytics[accountName] && this.analytics[accountName][site]) {
      const analytics = this.analytics[accountName][site];
      const index = this.getOrderIndex(analytics, date);
      if (index >= 0) {
        return `
                    Orders Picked Up in ShaleApps: ${analytics[index].totalOrders}<br>
                    Loads Parsed from Spreadsheet: ${analytics[index].ordersProcessed}<br>
                    Orders Matched with Spreadsheet: ${analytics[index].dataMatched}
          `;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
