import { Injectable } from '@angular/core';
import { RouterStateService } from '~services/router-state.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '~services/user.service';
import { AuthService } from '~services/auth.service';
import { environment } from '~environments/environment';

declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private routerState: RouterStateService,
    private userService: UserService,
    private authService: AuthService,
  ) {
    this.listenForLoggedIn();
    this.listenToRouterStateChanges();
  }

  public show() {
    if (hasIntercom()) {
      Intercom('show');
    }
  }

  public showMessages() {
    if (hasIntercom()) {
      Intercom('showMessages');
    }
  }

  public showNewMessage() {
    if (hasIntercom()) {
      Intercom('showNewMessage');
    }
  }

  public setIsUsingNewBillingPortal(isUsing: boolean) {
    if (hasIntercom()) {
      Intercom('update', { using_new_billing: isUsing });
    }
  }

  private async boot() {
    let user = this.userService.getUserContractFromCache();
    for (let i = 0; i < 10 && !(user && user.id); i++) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      user = this.userService.getUserContractFromCache();
    }
    if (user && user.id && hasIntercom()) {
      const isUsingNewBillingPortal = JSON.parse(localStorage.getItem('opt_in_to_new_billing_portal') || 'false');
      const intercomSettings = {
        app_id: environment.featureFlagKey === 'prod' ? 'dj4s6wb5' : 'v2sebl2g',
        name: user.name,
        email: user.email,
        company: null as any,
        user_id: `${user.id}`,
        user_role: user.account && user.account.role && user.account.role.name,
        user_scopes: user.permissions,
        user_segment: user.userSegmentString,
        created_at: new Date(user.userCreationTimestamp).getTime() / 1000,
        product: 'SANDi',
        using_new_billing: isUsingNewBillingPortal,
      };
      if (user.account) {
        intercomSettings.company = {
          id: user.account.id,
          name: user.account.name,
          type: user.account.type && user.account.type.name,
        };
      }
      Intercom('boot', intercomSettings);
    }
  }

  private shutdown() {
    if (hasIntercom()) {
      Intercom('shutdown');
    }
  }

  private listenToRouterStateChanges() {
    this.routerState.routerState$
      .pipe(
        map((state) => state.url),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        if (hasIntercom()) {
          Intercom('update');
        }
      });
  }

  private listenForLoggedIn() {
    this.authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.boot();
      } else {
        this.shutdown();
      }
    });
  }
}

function hasIntercom() {
  return typeof Intercom !== 'undefined';
}
