import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { UserService } from './user.service';

// declare ga as a function to set and sent the events
declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private userService: UserService) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (gtag && environment.gaTrackingId) {
        const user = this.userService.getUserContractFromCache();
        const params: { [key: string]: any } = {
          page_path: event.urlAfterRedirects,
        };
        if (user && user.id) {
          params.user_id = user.id.toString();
          params.custom_map = {
            dimension1: 'duserID',
          };
          params.duserID = user.id;
        }
        this.config(params);
      }
    });
  }

  public config(params: { [key: string]: any }) {
    if (gtag && environment.gaTrackingId) {
      gtag('config', environment.gaTrackingId, params);
    }
  }

  public event(eventName: string, params: { [key: string]: string | number }) {
    if (gtag && environment.gaTrackingId) {
      const user = this.userService.getUserContractFromCache();
      if (user && user.id) {
        params.duserID = user.id;
      }
      gtag('event', eventName, params);
    }
  }
}
