import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { VersioningService } from './versioning.service';

@Injectable({
  providedIn: 'root',
})
export class VersionHeaderInterceptorService implements HttpInterceptor {
  constructor(private versioningService: VersioningService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.versioningService) {
      const httpRequest = req.clone({
        headers: req.headers.set('X-Client-Version', this.versioningService.currentVersion),
      });
      return next.handle(httpRequest);
    }
    return next.handle(req);
  }
}
