<div class="update-load-component">
  <div class="head-wrap">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <h1>Update {{ selectedOrder?.saUniqueId }}</h1>
    <h4>{{ selectedOrder.user.name }}, {{ selectedOrder.truck?.name }}</h4>
  </div>

  <mat-form-field id="progress-ticket-number" id="runboard-edit-ticket">
    <input
      matInput
      placeholder="Ticket Number"
      type="text"
      autocomplete="off"
      [(ngModel)]="selectedOrder.ticketNumber"
    />
  </mat-form-field>

  <mat-form-field id="progress-bol-number" id="runboard-edit-bol">
    <input
      matInput
      type="text"
      [readonly]="selectedOrder.loaderDataLocked"
      [matTooltip]="selectedOrder.loaderDataLocked ? 'LMO updated this field' : ''"
      placeholder="BOL Number"
      [(ngModel)]="selectedOrder.bolNumber"
    />
  </mat-form-field>

  <mat-form-field id="progress-description" id="runboard-edit-description">
    <textarea
      matInput
      placeholder="Description"
      type="text"
      autocomplete="off"
      [(ngModel)]="selectedOrder.description"
    ></textarea>
  </mat-form-field>

  <mat-form-field id="progress-truck">
    <input
      type="text"
      placeholder="Truck"
      matInput
      [(ngModel)]="selectedOrder.truck"
      (ngModelChange)="truckInputValueChanged($event)"
      [matAutocomplete]="auto"
      required
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayTruckFn"
      (optionSelected)="onTruckSelected($event.option.value)"
      (closed)="clearInput()"
    >
      <mat-option *ngFor="let option of truckService.truckList$ | async" [value]="option">
        {{ option.name }} <span *ngIf="option.isInUse">(In Use)</span>
      </mat-option>
      <mat-option [value]="selectedOrder.truck" *ngIf="checkForAddTruck$() | async">
        <span class="add-truck-option">+ Add New Truck</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field id="progress-trailer">
    <input
      type="text"
      placeholder="Trailer"
      matInput
      [value]="formattedTrailerDisplay"
      [(ngModel)]="selectedOrder.trailer"
      (ngModelChange)="trailerInputValueChanged($event)"
      [matAutocomplete]="trailerAutocomplete"
      (blur)="clearTrailerInput()"
      required
    />
    <span
      matSuffix
      *ngIf="showTrailerType && selectedOrder.trailer && selectedOrder.trailerType?.trailerTypeName"
      class="trailer-type-selected"
      id="trailer-type-selected"
    >
      | {{ selectedOrder.trailerType.trailerTypeName }}
    </span>
    <mat-autocomplete
      #trailerAutocomplete="matAutocomplete"
      [displayWith]="displayTruckFn"
      (optionSelected)="onTrailerSelected($event.option.value)"
      (closed)="clearTrailerInput()"
    >
      <ng-container *ngIf="trailersOptions?.length > 0">
        <mat-option *ngFor="let option of trailersOptions" [value]="option">
          <span>{{ option.name }}<span *ngIf="option?.inUse">(In Use)</span></span>
          <span class="trailer-type-option" *ngIf="option.trailerTypeName">| {{ option.trailerTypeName }}</span>
        </mat-option>
      </ng-container>
      <mat-option [value]="selectedOrder.trailer" *ngIf="checkForAddTrailer()">
        + Add New Trailer
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <ng-container *ngIf="selectedOrder.boxes">
    <div class="box-wrap" *ngFor="let box of selectedOrder.boxes">
      <mat-form-field id="progress-box1-name">
        <input matInput type="text" placeholder="Box ID" [(ngModel)]="box.name" />
      </mat-form-field>
      <mat-form-field id="progress-box1-weight">
        <input
          matInput
          sa-number-input
          min="0"
          [max]="maxWeight || 111000"
          [readonly]="selectedOrder.loaderDataLocked"
          [matTooltip]="selectedOrder.loaderDataLocked ? 'LMO updated this field' : ''"
          type="text"
          placeholder="Box Load Weight (lbs)"
          [(ngModel)]="box.actualLoadWeight"
        />
        <mat-hint>Max {{ maxWeight.toLocaleString() }}</mat-hint>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngIf="!selectedOrder.boxes" id="progress-weight">
    <mat-form-field>
      <input
        matInput
        sa-number-input
        min="0"
        [max]="maxWeight || 111000"
        type="text"
        [readonly]="selectedOrder.loaderDataLocked"
        [matTooltip]="selectedOrder.loaderDataLocked ? 'LMO updated this field' : ''"
        placeholder="Final Load Weight"
        [(ngModel)]="selectedOrder.actualLoadWeight"
      />
      <mat-hint>Max {{ maxWeight.toLocaleString() }}</mat-hint>
    </mat-form-field>

    <mat-form-field id="progress-storage-number">
      <input matInput type="text" placeholder="Bulk Storage Number" [(ngModel)]="selectedOrder.bulkStorageNumber" />
    </mat-form-field>
  </ng-container>

  <div class="button-wrap">
    <button mat-flat-button color="primary" [disabled]="!selectedOrder.truck" (click)="updateDetail()">Submit</button>
  </div>
</div>
