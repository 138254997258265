import { DatePipe, formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isDate, isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }
    return super.transform(value, standardDateFormat(value));
  }
}

export function standardDateFormat(value: any): string {
  const asDate = new Date(value);
  let formattingText = `h:mmaaaaa'm' M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Tomorrow'`;
  }
  return formattingText;
}

export function formatDateStandard(value: any): string {
  if (!isDate(value)) {
    value = new Date(value);
  }
  const formatStr = standardDateFormat(value);
  return formatDate(value, formatStr, 'en-US');
}
