import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent implements OnInit {
  invoiceNumber = '';

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.dialogRef.close(false);
      }
    }
  }

  constructor(public dialogRef: MatDialogRef<TicketDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.invoiceNumber = _data;
  }

  ngOnInit() {}

  cancelAction() {
    this.dialogRef.close(false);
  }

  continueAction() {
    this.dialogRef.close(this.invoiceNumber);
  }
}
