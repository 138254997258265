<div class="frac-detail-container">
  <div class="head-wrap">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
      Back to Jobs
    </div>
    <div class="download-wrap">
      <a
        class="download-btn"
        *ngIf="optedInToNewBillingPortal; else optedOut"
        [routerLink]="['/', 'invoicing', 'orders_list', 'waiting_for_ticket_approval']"
        [queryParams]="{ fracIds: selectedDC?.id }"
        >Invoicing</a
      >
      <ng-template #optedOut>
        <a class="download-btn" *ngIf="!isDispatcherCrewAccount" (click)="goToInvoicing()">Invoicing </a>
      </ng-template>
      <button class="download-btn" (click)="downloadCSV()" [disabled]="loadingCompletedDc">
        <span *ngIf="!downloadProgress"><img src="../../../../assets/icons/csv-24.svg" alt="Download CSV"/></span>
        <span *ngIf="!downloadProgress">Download</span>
        <mat-spinner *ngIf="downloadProgress" [diameter]="20"></mat-spinner>
      </button>
    </div>
  </div>

  <div class="spin-wrap">
    <div class="frac-name-container">
      <div class="flex flex-col">
        <h1>{{ selectedDC?.site?.name }}</h1>
        <div class="pl-2">Distribution Center</div>
      </div>
      <ng-container *ngIf="isVorto() && selectedTab === 'In Progress'">
        <mat-checkbox [checked]="is5f$$.value" (change)="is5f$$.next($event.checked)">5F</mat-checkbox>
      </ng-container>
    </div>
    <div class="spinner-div">
      <mat-spinner *ngIf="loadingCompletedDc" [diameter]="25"></mat-spinner>
    </div>
  </div>

  <div class="tab-wrap">
    <div
      class="tab"
      *ngFor="let tab of tabs"
      (click)="selectTab(tab)"
      [ngClass]="{ active: selectedTab === tab }"
      saAutomatedSelector="frac_order_details_dispatcher__{{ tab.toLowerCase().replace(' ', '_') }}_tab"
    >
      {{ tab }}
      <span *ngIf="tab === 'Pending'" class="pending-tab">{{ pendingOrders.length }}</span>
      <span *ngIf="tab === 'In Progress'"
        >({{ inProgressOrders[0].length + inProgressOrders[1].length + inProgressOrders[2].length }})</span
      >
      <span *ngIf="tab === 'Completed'">({{ completedOrdersService.totalCompletedOrders }})</span>
    </div>
  </div>

  <ng-container *ngIf="selectedTab === 'Pending'">
    <h3 class="no-order-placeholder" *ngIf="pendingOrders.length === 0">No Orders</h3>
    <div class="flex-1 overflow-y-auto">
      <mat-selection-list
        [disableRipple]="true"
        class="frac-list"
        (selectionChange)="onSelection($event, pendingList.selectedOptions.selected)"
        #pendingList
      >
        <mat-list-option
          [checkboxPosition]="'before'"
          *ngFor="let order of pendingOrders; trackBy: trackByFn"
          [value]="order"
          saAutomatedSelector="frac_order_details_dispatcher__order_row"
        >
          <div
            class="frac-item pending-item"
            (click)="addDriver(order)"
            saAutomatedSelector="frac_order_details_dispatcher__order_item"
          >
            <span class="flex flex-row-reverse">
              <sa-dc-order-direction-pill [order]="order"></sa-dc-order-direction-pill>
            </span>
            <div class="header">
              <div class="frac-id">
                <div class="flex items-center">
                  {{ order?.saUniqueId }}
                  <img
                    *ngIf="order.boxPickup"
                    class="w-4"
                    src="/assets/icons/box_move.svg"
                    alt="box move"
                    matTooltip="Box Move {{ order.boxPickup.siteName }}"
                  />
                </div>
                <div>Seq. #: {{ order.loadNumber }}</div>
              </div>
              <div class="timestamp">
                <ng-container *ngIf="order.orderStatus === 'pending'">
                  <span *ngIf="!order?.user?.name && order.lastDispatchedToDriverName; else notMissed" class="red-text">
                    Missed by {{ order.lastDispatchedToDriverName }}
                  </span>
                  <ng-template #notMissed>
                    <span> Received {{ helperService.calculateReceivingTime(order.createTimestamp) }} ago </span>
                  </ng-template>
                </ng-container>
                <span *ngIf="order.orderStatus === 'driver_rejected'" class="red-text"
                  >Rejected by {{ order.user.name }}</span
                >
              </div>
            </div>
            <div class="order-pending">
              <div class="order-pending-content">
                <ng-container *ngIf="isVorto()">
                  <div
                    class="text-red-500"
                    *ngIf="(order.autoDispatchPaused && order.autoDispatchPauseEndsAt) || order.doNotAutoDispatch"
                  >
                    <div>AutoDispatch Paused</div>
                    <div>Resuming in {{ order.autoDispatchPauseEndsAt | until: false }}</div>
                  </div>
                </ng-container>
                <div class="add-driver"><span>Add Driver</span></div>
                <div class="desc">
                  {{ order.mesh.type }} Mesh from {{ order | orderPickupSiteName }} at
                  {{ datePipe.transform(order.startTimestamp, 'shortTime') }}
                  <div *saFeatureFlag="'orderTrailerType'">
                    <div *ngIf="order.trailerType">Trailer Type: {{ order.trailerType?.trailerTypeDisplayName }}</div>
                  </div>
                </div>
              </div>
              <div class="order-pending-notes">
                <ng-container>
                  <div class="urgent" *ngIf="order.boostPercent > 0">{{ order.boostPercent }}% Boost!</div>
                  <div class="urgent" *ngIf="order.urgent && !order.boostPercent">Urgent</div>
                </ng-container>
                <div class="up-for-grabs" *ngIf="order.isUpForGrabs && !order.urgent && !order.boostPercent">
                  Up For Grabs
                </div>
              </div>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="decline-btn-wrap" *ngIf="selectedPendingOrders.length">
      <div>
        {{ selectedPendingOrders.length }} Order<span *ngIf="selectedPendingOrders.length > 1">s</span> Selected
      </div>
      <div>
        <mat-spinner *ngIf="declining" color="warn" [diameter]="25"></mat-spinner>
        <button *ngIf="!declining" mat-stroked-button color="warn" (click)="bulkDeclineOrders()">DECLINE</button>
      </div>
    </div>
  </ng-container>

  <div class="frac-list" *ngIf="selectedTab === 'Completed'" (scroll)="completedOrders.onScroll($event)">
    <sa-frac-detail-completed-orders #completedOrders></sa-frac-detail-completed-orders>
  </div>

  <div class="frac-list" *ngIf="selectedTab === 'In Progress'">
    <h3
      class="no-order-placeholder"
      *ngIf="inProgressOrders[0].length === 0 && inProgressOrders[1].length === 0 && inProgressOrders[2].length === 0"
    >
      No Orders
    </h3>
    <h3 class="sub-header" *ngIf="inProgressOrders[0].length">Sent to Driver</h3>
    <ng-container *ngFor="let order of inProgressOrders[0]; trackBy: trackByFn">
      <div
        class="frac-item"
        (click)="selectOrder(order)"
        saAutomatedSelector="frac_order_details_dispatcher__order_item"
      >
        <div class="header">
          <div class="frac-id">
            <div class="flex">
              {{ order?.saUniqueId }} <sa-lohi-preload-status [order]="order"></sa-lohi-preload-status>
            </div>
            <div>Seq. #: {{ order.loadNumber }}</div>
          </div>
          <div
            class="timestamp"
            [ngClass]="{
              'red-text': helperService.checkIfOnOrderTooLong(order) || order.user.userStatus.type === 'Unreachable'
            }"
          >
            <span>Sent</span>
            {{ helperService.calculateReceivingTime(order.dispatchTimestamp) }} ago
          </div>
        </div>

        <div class="frac-content-wrap">
          <div class="frac-body">
            <div class="driver-details">
              {{ order?.user?.name }}
              <ng-container *ngIf="isVorto() && order.is5f"> (5F) </ng-container>
              <span *ngIf="order.truck">,</span> {{ order?.truck?.name }} <span *ngIf="order.trailer">,</span
              >{{ order?.trailer?.name }}
              <div class="trailer-type-name" *ngIf="order?.user?.trailerTypeName">
                {{ order?.user?.trailerTypeName }}
              </div>
              <div class="subcarrier-name" *ngIf="order?.subcarrierName">
                {{ order?.subcarrierName }}
              </div>
            </div>
            <div class="desc">
              {{ order.mesh.type }} from {{ order | orderPickupSiteName }} at
              {{ datePipe.transform(order.startTimestamp, 'shortTime') }}
              <div *saFeatureFlag="'orderTrailerType'">
                <div *ngIf="order.trailerType">Trailer Type: {{ order.trailerType?.trailerTypeDisplayName }}</div>
              </div>
            </div>
          </div>
          <div class="detail-wrap">
            <div class="action-needed" *ngIf="order.user && order.user.userStatus.type === 'ActionNeeded'">
              Action Needed
            </div>
          </div>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedFrom">
          <img class="reroute-img low-height" src="../../../../assets/icons/RerouteFrom.png" alt="reroutedFrom" />
          <h3>
            FROM
            <span *ngIf="order.mine.site.name !== order.reroutedFrom.mineName">{{ order.reroutedFrom.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedFrom.fracName &&
                order.mine.site.name === order.reroutedFrom.mineName
              "
              >{{ order.reroutedFrom.fracName }}</span
            >
          </h3>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedTo && !order.reroutedFrom">
          <img class="reroute-img" src="../../../../assets/icons/RerouteTo.png" alt="reroutedTo" />
          <h3>
            To
            <span *ngIf="order.mine.site.name !== order.reroutedTo.mineName">{{ order.reroutedTo.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedTo.fracName &&
                order.mine.site.name === order.reroutedTo.mineName
              "
              >{{ order.reroutedTo.fracName }}</span
            >
          </h3>
        </div>
        <div class="alert-wrap" *ngIf="order.user && order.user.userStatus.isException">
          <div><img src="../../../../assets/icons/alarm-24.svg" alt="Alert" /></div>
          <span>{{ order.user.userStatus.exceptionTitle }}</span>
        </div>
      </div>
    </ng-container>

    <h3 class="sub-header" *ngIf="inProgressOrders[1].length">To Loader</h3>
    <ng-container *ngFor="let order of inProgressOrders[1]; trackBy: trackByFn">
      <div
        class="frac-item"
        (click)="selectOrder(order)"
        saAutomatedSelector="frac_order_details_dispatcher__order_item"
      >
        <div class="header">
          <div class="frac-id">
            <div class="flex items-center">
              {{ order.saUniqueId }} <sa-lohi-preload-status [order]="order"></sa-lohi-preload-status>
              <img
                *ngIf="order.boxPickup"
                class="w-4"
                src="/assets/icons/box_move.svg"
                alt="box move"
                matTooltip="Box Move {{ order.boxPickup.siteName }}"
              />
            </div>
            <div>Seq. #: {{ order.loadNumber }}</div>
          </div>
          <ng-container *saFeatureFlag="'onOrderTooLong'; else onlyUpdatedToLoaders">
            <div class="timestamp red-text" *ngIf="order.dispatchTimestamp | onOrderTooLong; else updatedAtToLoaders">
              On Load for {{ order.dispatchTimestamp | ago: false }}
              <br />
              <span class="preload" *ngIf="order.isPreLoad">PreLoad</span>
            </div>
            <ng-template #updatedAtToLoaders>
              <div
                class="timestamp timestamp-updated"
                [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }"
              >
                <span>Updated</span>
                {{ helperService.calculateReceivingTime(order.user.lastTimeSeen) }} ago
                <br />
                <span class="preload" *ngIf="order.isPreLoad">PreLoad</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #onlyUpdatedToLoaders>
            <div
              class="timestamp timestamp-updated"
              [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }"
            >
              <span>Updated</span>
              {{ helperService.calculateReceivingTime(order.user.lastTimeSeen) }} ago
            </div>
          </ng-template>
        </div>

        <div class="frac-content-wrap">
          <div class="frac-body">
            <div class="driver-details">
              {{ order?.user?.name }}
              <ng-container *ngIf="isVorto() && order.is5f"> (5f) </ng-container>
              <span *ngIf="order.truck">,</span> {{ order?.truck?.name }} <span *ngIf="order.trailer">,</span
              >{{ order?.trailer?.name }}
              <div class="trailer-type-name" *ngIf="order?.user?.trailerTypeName">
                {{ order?.user?.trailerTypeName }}
              </div>
              <div class="subcarrier-name" *ngIf="order?.subcarrierName">
                {{ order?.subcarrierName }}
              </div>
            </div>
            <div class="desc">
              {{ order.mesh.type }} from {{ order | orderPickupSiteName }} at
              {{ datePipe.transform(order.startTimestamp, 'shortTime') }}
              <div *saFeatureFlag="'orderTrailerType'">
                <div *ngIf="order.trailerType">Trailer Type: {{ order.trailerType?.trailerTypeDisplayName }}</div>
              </div>
            </div>
          </div>
          <div class="detail-wrap">
            <ng-container
              *ngIf="order.user.userStatus.type === 'Online' || order.user.userStatus.type === 'Unreachable'"
            >
              <div class="timestamp">
                <span *ngIf="order.etaStatus === 'arrived'">ARRIVED</span>
                <span *ngIf="order.etaStatus === 'staging'">STAGING</span>
                <span *ngIf="order.etaStatus === 'none' && order.eta">{{ calculateEta(order.eta) }}</span>
                <span *ngIf="order.etaStatus === 'none' && !order.eta">--</span>
              </div>
              <div class="driver-status">
                <ng-container *ngIf="order.etaStatus !== 'arrived' && order.etaStatus !== 'staging'">
                  <span *ngIf="!order.loaded">PICKUP</span>
                  <span *ngIf="order.loaded">DROPOFF</span>
                </ng-container>
                <ng-container *ngIf="order.etaStatus === 'arrived'">
                  <span *ngIf="order.loaded">{{
                    order.fracSiteVisit?.wellEntryTimestamp || order.fracSiteVisit?.startTimestamp | ago
                  }}</span>
                  <span *ngIf="!order.loaded">{{ calculateReceivedTime(order.mineSiteVisit?.startTimestamp) }}</span>
                </ng-container>
                <ng-container *ngIf="order.etaStatus === 'staging'">
                  <span>{{ order.fracSiteVisit?.startTimestamp | ago }}</span>
                </ng-container>
              </div>
            </ng-container>
            <div class="action-needed" *ngIf="order.user.userStatus.type === 'ActionNeeded' && !order.isPreLoad">
              Action Needed
            </div>
          </div>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedFrom">
          <img class="reroute-img low-height" src="../../../../assets/icons/RerouteFrom.png" alt="reroutedFrom" />
          <h3>
            FROM
            <span *ngIf="order.mine.site.name !== order.reroutedFrom.mineName">{{ order.reroutedFrom.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedFrom.fracName &&
                order.mine.site.name === order.reroutedFrom.mineName
              "
              >{{ order.reroutedFrom.fracName }}</span
            >
          </h3>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedTo && !order.reroutedFrom">
          <img class="reroute-img" src="../../../../assets/icons/RerouteTo.png" alt="reroutedTo" />
          <h3>
            To
            <span *ngIf="order.mine.site.name !== order.reroutedTo.mineName">{{ order.reroutedTo.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedTo.fracName &&
                order.mine.site.name === order.reroutedTo.mineName
              "
              >{{ order.reroutedTo.fracName }}</span
            >
          </h3>
        </div>
        <div class="alert-wrap" *ngIf="order.user.userStatus.isException">
          <div><img src="../../../../assets/icons/alarm-24.svg" alt="Alert" /></div>
          <span>{{ order.user.userStatus.exceptionTitle }}</span>
        </div>
      </div>
    </ng-container>

    <h3 class="sub-header" *ngIf="inProgressOrders[2].length">To Well</h3>
    <ng-container *ngFor="let order of inProgressOrders[2]; trackBy: trackByFn">
      <div class="frac-item" (click)="selectOrder(order)">
        <div class="header">
          <div class="frac-id">
            <div class="flex items-center">
              {{ order.saUniqueId }}
              <sa-lohi-preload-status [order]="order"></sa-lohi-preload-status>
              <img
                *ngIf="order.boxPickup"
                class="w-4"
                src="/assets/icons/box_move.svg"
                alt="box move"
                matTooltip="Box Move {{ order.boxPickup.siteName }}"
              />
            </div>
            <div>Seq. #: {{ order?.loadNumber }}</div>
          </div>
          <ng-container *saFeatureFlag="'onOrderTooLong'; else onlyUpdatedToWells">
            <div class="timestamp red-text" *ngIf="order.dispatchTimestamp | onOrderTooLong; else updatedAtToWells">
              On Load for {{ order.dispatchTimestamp | ago: false }}
              <br />
              <span class="preload" *ngIf="order.isPreLoad">PreLoad</span>
            </div>
            <ng-template #updatedAtToWells>
              <div class="timestamp" [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }">
                <span>Updated</span>
                {{ helperService.calculateReceivingTime(order.user.lastTimeSeen) }} ago
                <br />
                <span class="preload" *ngIf="order.isPreLoad">PreLoad</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #onlyUpdatedToWells>
            <div class="timestamp" [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }">
              <span>Updated</span>
              {{ helperService.calculateReceivingTime(order.user.lastTimeSeen) }} ago
            </div>
          </ng-template>
        </div>

        <div class="frac-content-wrap">
          <div class="frac-body">
            <div class="driver-details">
              {{ order?.user?.name }}
              <ng-container *ngIf="isVorto() && order.is5f"> (5f) </ng-container>
              <span *ngIf="order.truck">,</span> {{ order?.truck?.name }} <span *ngIf="order.trailer">,</span
              >{{ order?.trailer?.name }}
              <div class="trailer-type-name" *ngIf="order?.user?.trailerTypeName">
                {{ order?.user?.trailerTypeName }}
              </div>
              <div class="subcarrier-name" *ngIf="order?.subcarrierName">
                {{ order?.subcarrierName }}
              </div>
            </div>
            <div class="desc">
              {{ order.mesh.type }} from {{ order | orderPickupSiteName }} at
              {{ datePipe.transform(order.startTimestamp, 'shortTime') }}
            </div>
          </div>
          <div class="detail-wrap">
            <ng-container
              *ngIf="order.user.userStatus.type === 'Online' || order.user.userStatus.type === 'Unreachable'"
            >
              <div class="timestamp">
                <span *ngIf="order.etaStatus === 'arrived'">ARRIVED</span>
                <span *ngIf="order.etaStatus === 'staging'">STAGING</span>
                <span *ngIf="order.etaStatus === 'none' && order.eta">{{ calculateEta(order.eta) }}</span>
                <span *ngIf="order.etaStatus === 'none' && !order.eta">--</span>
              </div>
              <div class="driver-status">
                <ng-container *ngIf="order.etaStatus !== 'arrived' && order.etaStatus !== 'staging'">
                  <span *ngIf="!order.loaded">PICKUP</span>
                  <span *ngIf="order.loaded">DROPOFF</span>
                </ng-container>
                <ng-container *ngIf="order.etaStatus === 'arrived'">
                  <span *ngIf="order.loaded">{{
                    order.fracSiteVisit?.wellEntryTimestamp || order.fracSiteVisit?.startTimestamp | ago
                  }}</span>
                  <span *ngIf="!order.loaded">{{ calculateReceivedTime(order.mineSiteVisit?.startTimestamp) }}</span>
                </ng-container>
                <ng-container *ngIf="order.etaStatus === 'staging'">
                  <span>{{ order.fracSiteVisit?.startTimestamp | ago }}</span>
                </ng-container>
              </div>
            </ng-container>
            <div class="action-needed" *ngIf="order.user.userStatus.type === 'ActionNeeded' && !order.isPreLoad">
              Action Needed
            </div>
          </div>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedFrom">
          <img class="reroute-img low-height" src="../../../../assets/icons/RerouteFrom.png" alt="reroutedFrom" />
          <h3>
            FROM
            <span *ngIf="order.mine.site.name !== order.reroutedFrom.mineName">{{ order.reroutedFrom.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedFrom.fracName &&
                order.mine.site.name === order.reroutedFrom.mineName
              "
              >{{ order.reroutedFrom.fracName }}</span
            >
          </h3>
        </div>
        <div class="reroute-wrap" *ngIf="order.reroutedTo && !order.reroutedFrom">
          <img class="reroute-img" src="../../../../assets/icons/RerouteTo.png" alt="reroutedTo" />
          <h3>
            To
            <span *ngIf="order.mine.site.name !== order.reroutedTo.mineName">{{ order.reroutedTo.mineName }}</span>
            <span
              *ngIf="
                selectedDC?.site?.name !== order.reroutedTo.fracName &&
                order.mine.site.name === order.reroutedTo.mineName
              "
              >{{ order.reroutedTo.fracName }}</span
            >
          </h3>
        </div>
        <div class="alert-wrap" *ngIf="order.user.userStatus.isException">
          <div><img src="../../../../assets/icons/alarm-24.svg" alt="Alert" /></div>
          <span>{{ order.user.userStatus.exceptionTitle }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
