import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss'],
})
export class ReasonDialogComponent implements OnInit {
  data: any;
  pushBackReason = '';

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.dialogRef.close({ status: false, reason: '' });
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private snackbar: MatSnackBar,
  ) {
    this.data = _data;
  }

  ngOnInit() {}

  cancelAction() {
    this.dialogRef.close({ status: false, reason: '' });
  }

  continueAction() {
    if (this.pushBackReason) {
      this.dialogRef.close({ status: true, reason: this.pushBackReason });
    } else {
      this.snackbar.open('Pushback Reason cannot be empty', null, {
        duration: 3000,
        panelClass: ['snackbar-error'],
      });
    }
  }
}
