<div
  class="detail-view-container"
  [@slideInOut]
  *ngIf="model.open"
  [ngClass]="{
    mirror:
      model.modelName === detailViewModels.user ||
      model.modelName === detailViewModels.driverProfile ||
      model.modelName === detailViewModels.carrier
  }"
>
  <sa-driver-detail-view
    *ngIf="model.modelName === detailViewModels.driver"
    [id]="model.id"
    (onDateSelected)="dateSelected($event)"
  >
  </sa-driver-detail-view>
  <sa-site-detail-view
    *ngIf="model.modelName === detailViewModels.site"
    [id]="model.id"
    (onSiteUpdated)="siteUpdated($event)"
  >
  </sa-site-detail-view>
  <sa-driver-profile-detail-view
    *ngIf="model.modelName === detailViewModels.driverProfile"
    [user]="model.model"
    (onUserUpdated)="userUpdated($event)"
    (onClose)="closeClicked()"
  >
  </sa-driver-profile-detail-view>
  <sa-user-detail-view
    *ngIf="model.modelName === detailViewModels.user"
    [user]="model.model"
    (onUserUpdated)="userUpdated($event)"
    (onClose)="closeClicked()"
  >
  </sa-user-detail-view>
  <sa-carrier-detail-view
    *ngIf="model.modelName === detailViewModels.carrier"
    [subcarrier]="model.model"
    (onSubcarrierUpdated)="subcarrierUpdated($event)"
    (onClose)="closeClicked()"
  ></sa-carrier-detail-view>
</div>
