import { Injectable, EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

const RETRY_TIME = 30 * 1000;
const ORDER_MSG_TYPE = 'UsersOnOrders';

@Injectable({
  providedIn: 'root',
})
export class BillingSocketService {
  private billingSocket: WebSocket;
  private billingSocketListener: EventEmitter<any> = new EventEmitter();
  private presenceMap$$ = new BehaviorSubject(null);

  public get presenceMap$() {
    return this.presenceMap$$.asObservable();
  }

  get apiUrl(): URL {
    return new URL(environment.api);
  }
  get billingSocketUrl(): string {
    return 'wss://' + this.apiUrl.hostname + this.apiUrl.pathname + '/ws/billing';
  }

  private get checkIfSocketIsOpen(): boolean {
    return this.billingSocket && this.billingSocket.readyState === WebSocket.OPEN;
  }

  billingSocketStatus = false;

  failCount = 0;

  public constructor(private authService: AuthService) {}

  initSocket() {
    this.authService.afAuth.authState.subscribe((user) => {
      if (user) {
        user.getIdToken().then((jwtToken) => {
          this.createBillingSocket(jwtToken);
        });
      }
    });
  }

  createBillingSocket(jwtToken) {
    if (this.billingSocket == null) {
      this.billingSocket = new WebSocket(this.billingSocketUrl + '?jwt=' + jwtToken);
      this.billingSocket.onopen = (event) => {
        this.billingSocketStatus = true;
      };
      this.billingSocket.onclose = (event) => {
        this.billingSocketStatus = false;
        this.retrySocket(jwtToken);
      };
      this.billingSocket.onmessage = (event) => {
        const dataFromSocket = JSON.parse(event.data);
        if (dataFromSocket.type === ORDER_MSG_TYPE) {
          this.presenceMap$$.next(dataFromSocket.data);
        }
      };
    }
  }

  retrySocket(jwtToken) {
    const socketTimer = setInterval((_) => {
      if (!this.billingSocketStatus) {
        this.failCount++;
        if (this.failCount > 2) {
          this.billingSocketListener.emit({ type: 'alert' });
          this.failCount = 0;
        }
        this.createBillingSocket(jwtToken);
        clearInterval(socketTimer);
      } else {
        this.failCount = 0;
        clearInterval(socketTimer);
      }
    }, RETRY_TIME);
  }

  public sendMessage(data: string) {
    if (this.checkIfSocketIsOpen) {
      try {
        this.billingSocket.send(data);
      } catch (error) {
        console.error(error);
      }
    }
  }

  public close() {
    if (this.checkIfSocketIsOpen) {
      this.billingSocket.close();
    }
    this.billingSocket = null;
    this.billingSocketStatus = true; // Need to set it to true so that the service do not retry to connect.
  }
}
