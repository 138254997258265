<!--<div class="order-summary" *ngIf="orderSummary.isLocked">-->
<!--<ng-container *ngTemplateOutlet="orderSummaryBody"></ng-container>-->
<!--</div>-->

<a class="order-summary" [routerLink]="link">
  <ng-container *ngTemplateOutlet="orderSummaryBody"></ng-container>
</a>

<ng-template #orderSummaryBody>
  <div class="header">
    <div class="order-id">
      <div>
        <div>{{ orderSummary.saUniqueId }}</div>
        <div>Seq. #: {{ orderSummary.loadNumber }}</div>
      </div>
      <div class="lock-wrap" *ngIf="orderSummary.isLocked" matTooltip="{{ orderSummary.lockReason }}">
        <i class="material-icons error-icon-gray">lock</i>
      </div>
    </div>
    <div class="timestamp">
      <span>{{ orderSummary.status }}</span>
      {{ helperService.calculateReceivingTime(orderSummary.time) }} ago
    </div>
  </div>

  <div class="order-body">
    <div class="driver-details" *ngIf="orderSummary.driverId !== 0">
      <span class="driver-name">{{ orderSummary.driverName }}</span>
      <span *ngIf="orderSummary.truckName">,</span> {{ orderSummary.truckName }}
      <span *ngIf="orderSummary.trailerName">,</span>{{ orderSummary.trailerName }}
    </div>
    <div class="desc" *ngIf="orderSummary.subcarrierName">
      {{ orderSummary.subcarrierName }}
    </div>
    <div class="desc">{{ orderSummary.meshName }} from {{ orderSummary.mineName }}</div>
    <div *saFeatureFlag="'orderTrailerType'">
      <div class="desc" *ngIf="orderSummary.trailerTypeDisplayName">
        Trailer Type: {{ orderSummary.trailerTypeDisplayName }}
      </div>
    </div>
  </div>
  <div class="reroute-wrap" *ngIf="orderSummary.reroutedFromSiteName">
    <img class="reroute-img low-height" src="/assets/icons/RerouteFrom.png" alt="reroutedFrom" />
    <h3>
      FROM
      <span>{{ orderSummary.reroutedFromSiteName }}</span>
    </h3>
  </div>
  <div class="reroute-wrap" *ngIf="orderSummary.reroutedFromSiteName && orderSummary.reroutedToSiteName">
    <img class="reroute-img" src="/assets/icons/RerouteTo.png" alt="reroutedTo" />
    <h3>
      TO
      <span>{{ orderSummary.reroutedToSiteName }}</span>
    </h3>
  </div>

  <ng-container *ngIf="isCompleted">
    <a
      class="billing-link"
      [routerLink]="['/', 'invoicing', 'order', orderSummary.id]"
      *saAllowedAccountRoles="['admin', 'dispatcher']"
      (click)="$event.stopPropagation()"
      >Open in Billing Portal
    </a>
  </ng-container>

  <div class="alert-wrap" *ngIf="orderSummary.loadDetailsIncomplete">
    <div>
      <img src="/assets/icons/alarm-24.svg" alt="Alert" />
    </div>
    Missing {{ orderSummary.missingLoadDetails.join(', ') }}
  </div>

  <ng-container *saAllowedAccountRoles="[1]">
    <ng-container *saFeatureFlag="'orderVerificationByAdmin'">
      <div *ngIf="orderSummary.verificationAction === 'verify'" class="flex flex-row items-baseline pt-1 verified">
        <i class="material-icons">check_circle</i><span class="italic">Verified</span>
      </div>
      <div *ngIf="orderSummary.verificationAction === 'reject'" class="flex flex-row items-baseline pt-1 rejected">
        <i class="material-icons">cancel</i><span class="italic">Rejected</span>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
