import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountApiService } from '../../services/api/account.api.service';
import { SaErrorStateMatcher } from '../../services/form-validation.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

@Component({
  selector: 'sa-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent {
  inviteForm: FormGroup;
  matcher: ErrorStateMatcher;
  submitting = false;
  accountType: number;

  constructor(
    public dialogRef: MatDialogRef<InviteComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private accountApiService: AccountApiService,
    private errorHandler: ErrorHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.accountType = this.data;
    this.buildForm();
  }

  buildForm() {
    this.inviteForm = this.fb.group({
      name: [null as string, [Validators.required]],
      username: [null as string, [Validators.required]],
      email: [null as string, [Validators.required, Validators.email]],
      password: [null as string, [Validators.required]],
      confirmPassword: [null as string, [Validators.required]],
      scac: [null as string, this.accountType === 3 ? [Validators.required] : []],
      isSandiLite: [false as boolean, this.accountType === 2 ? [Validators.required] : []],
      isBrokerage: [false as boolean, this.accountType === 3 ? [Validators.required] : []],
    });

    this.matcher = new SaErrorStateMatcher();
  }

  submit() {
    if (this.inviteForm.valid) {
      if (this.inviteForm.controls['password'].value === this.inviteForm.controls['confirmPassword'].value) {
        const requestBody = {
          AccountName: this.inviteForm.controls['name'].value.trim(),
          AccountType: { ID: this.accountType },
          UserName: this.inviteForm.controls['username'].value.trim(),
          Email: this.inviteForm.controls['email'].value.trim(),
          Password: this.inviteForm.controls['password'].value.trim(),
          scac: this.inviteForm.get('scac').value ? this.inviteForm.get('scac').value.trim() : null,
          isSandiLite: this.inviteForm.get('isSandiLite').value,
          isBrokerage: this.inviteForm.get('isBrokerage').value,
        };
        this.accountApiService.createAccount(requestBody).subscribe(
          (resp) => {
            this.snackBar.open(this.inviteForm.controls['name'].value + ' is successfully invited', null, {
              duration: 5000,
            });
            this.dialogRef.close();
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
            this.dialogRef.close();
          },
        );
      } else {
        this.inviteForm.controls['confirmPassword'].setErrors({ match: true });
        this.submitting = false;
        this.errorHandler.showError('Passwords do not match', 3000);
      }
    }
  }
}
