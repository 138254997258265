import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '~services/auth.service';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (!environment.production) {
          console.error('logout interceptor', err);
        }
        if ((err as HttpErrorResponse).headers.has('force_sign_out') || (err as HttpErrorResponse)?.status === 423) {
          this.authService.logout();
        }
        return throwError(err);
      }),
    );
  }
}
