import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EmailSettingsV2Service, LoaderNameMapping } from '~services/email-settings-v2.service';
import { UserService } from '~services/user.service';
import { ChoiceDialogComponent } from '../../ui-components/choice-dialog/choice-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sa-lmo-loader-name-mapping',
  templateUrl: './lmo-loader-name-mapping.component.html',
  styleUrls: ['./lmo-loader-name-mapping.component.scss'],
})
export class LmoLoaderNameMappingComponent implements OnInit, OnDestroy {
  displayNav = false;
  displayForm = false;
  selectedMapping: LoaderNameMapping;

  userLabel: { name: string; email: string; account: string };

  activeDataSource: MatTableDataSource<LoaderNameMapping>;
  displayedColumns = ['nameInFile', 'loaderName', 'edit'];

  subs: Subscription[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private emailSettingsV2Service: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.userLabel = this.userService.getLabel();

    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;

    this.subs.push(
      this.emailSettingsV2Service.loaderNameMappings$.subscribe((settings) => {
        this.activeDataSource.data = settings;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }

  addForm(data?: LoaderNameMapping) {
    this.selectedMapping = data;
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  deleteLoaderNameMapping(id) {
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Delete this loader mapping?`,
        desc: `This cannot be undone.`,
        button: ['Cancel', 'Remove Loader Mapping'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.emailSettingsV2Service.deleteLoaderNameMapping(id).subscribe(
          () => {
            this.snackBar.open('Loader name mapping deleted', null, {
              duration: 2000,
            });
          },
          (error) => {
            this.snackBar.open(error.error ? error.error.Message : error.message, null, {
              duration: 5000,
              panelClass: ['snackbar-error'],
            });
          },
        );
      }
    });
  }
}
