<div class="runboard-summary-container">
  <div class="head-wrap">
    <a class="back-wrap" [routerLink]="['/', 'map', 'jobs']">
      <i class="material-icons">arrow_back</i>
      Back to Wells
    </a>
  </div>

  <div class="spin-wrap">
    <h1 *ngIf="runboardService.clusterInfo$ | async as clusterInfo; else loading">
      <div *ngIf="clusterInfo?.name; else nameNotFound">
        {{ clusterInfo.name }}
      </div>
    </h1>
    <ng-template #loading>
      <div class="spinner-div">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    </ng-template>
    <ng-template #nameNotFound>
      <div class="sub-table-text">
        Cluster name not available
      </div>
    </ng-template>

    <div class="truck-recommendation" *ngIf="(runboardService.driverPool$ | async)?.shouldShow">
      {{ (runboardService.driverPool$ | async)?.message }}
    </div>
  </div>

  <div class="runboard-wrap">
    <ng-container *ngIf="noContent() | async">
      <h3 class="sub-header">Your runboard is clear!</h3>
    </ng-container>

    <h3 class="sub-header" *ngIf="(runboardService.needsDriver$ | async)?.length as needsDriverCount">
      Needs Driver ({{ needsDriverCount }})
    </h3>
    <ng-container *ngFor="let order of runboardService.needsDriver$ | async; trackBy: trackByOrderId">
      <a class="line-item" [routerLink]="['/', 'map', 'jobs', 'detail', order.fracId, 'pending', order.loadNumber]">
        <div class="header">
          <ng-container *ngIf="isVorto()">
            <div
              class="text-red-500"
              *ngIf="(order.autoDispatchPaused && order.autoDispatchPauseEndsAt) || order.doNotAutoDispatch"
            >
              <div>AutoDispatch Paused</div>
              <div>Resuming in {{ order.autoDispatchPauseEndsAt | until: false }}</div>
            </div>
          </ng-container>
          <div class="status blue-text">
            Add Driver
          </div>
          <div class="timestamp" [ngClass]="getOTVSStatusClass(order) | async" *ngIf="order.upForGrabsTimestamp">
            <span> {{ getCountdown(order) | async }} Remaining </span>
          </div>
        </div>
      </a>
    </ng-container>
    <h3 class="sub-header" *ngIf="(runboardService.notAvailableForLoads$ | async)?.length as unassignedUsersCount">
      Not Available for Loads ({{ unassignedUsersCount }})
    </h3>
    <ng-container *ngFor="let driver of runboardService.notAvailableForLoads$ | async">
      <a class="line-item" routerLink="{{ driver.userId }}">
        <div class="header">
          <div class="status red-text">
            OFFLINE
          </div>
          <div class="timestamp italic" [ngClass]="{ 'red-text': driver.userStatus.type === 'Unreachable' }">
            <span>Updated</span>
            {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
          </div>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">{{ driver.userName }}</div>
            <div class="desc">-</div>
          </div>
          <div class="time-wrap">
            <h3>{{ getHos(driver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
        </div>
      </a>
    </ng-container>
    <h3 class="sub-header" *ngIf="(runboardService.activeDrivers$ | async)?.length as activeDriverCount">
      Active Drivers ({{ activeDriverCount }})
    </h3>
    <ng-container *ngFor="let driver of runboardService.activeDrivers$ | async">
      <a class="line-item" routerLink="{{ driver.userId }}">
        <div class="header">
          <div class="status blue-text">
            <span *ngIf="driver.loaded">TO WELL</span>
            <span *ngIf="!driver.loaded">TO MINE</span>
            #{{ driver.loadNumber }}
          </div>
          <ng-container *saFeatureFlag="'onOrderTooLong'; else onlyUpdatedToLoaders">
            <div
              class="timestamp italic red-text"
              *ngIf="driver.dispatchTimestamp | onOrderTooLong; else updatedAtToLoaders"
            >
              On Load for {{ driver.dispatchTimestamp | ago }}
            </div>
            <ng-template #updatedAtToLoaders>
              <div class="timestamp italic" [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }">
                <span>Updated</span>
                {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
              </div>
            </ng-template>
          </ng-container>
          <ng-template #onlyUpdatedToLoaders>
            <div class="timestamp italic" [ngClass]="{ 'red-text': order.user.userStatus.type === 'Unreachable' }">
              <span>Updated</span>
              {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
            </div>
          </ng-template>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">
              <span class="driver-name">{{ driver.userName }}</span
              >, {{ driver.truckName }}
            </div>
            <div class="item-details" *ngIf="isShaleApps">
              {{ driver.subcontractorName }}
            </div>
            <div class="desc">
              <span *ngIf="driver.loaded">{{ driver.fracName }}</span>
              <span *ngIf="!driver.loaded">{{ driver.mineName }}</span>
              - {{ driver.meshType }} Mesh
            </div>
          </div>
          <div
            class="time-wrap"
            *ngIf="driver.userStatus.type === 'Online' || driver.userStatus.type === 'Unreachable'"
          >
            <h3>{{ getHos(driver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
          <div class="action-needed" *ngIf="driver.userStatus.type === 'ActionNeeded'">
            Action Needed
          </div>
        </div>
      </a>
    </ng-container>
    <ng-container *ngFor="let availableDriver of runboardService.availableUndispatchedDriver$ | async">
      <a class="line-item" routerLink="{{ availableDriver.userId }}">
        <div class="header">
          <div class="status">
            ONLINE
          </div>
          <div class="timestamp italic" [ngClass]="{ 'red-text': availableDriver.userStatus.type === 'Unreachable' }">
            <span>Updated</span>
            {{ helperService.calculateReceivingTime(availableDriver.userStatus.lastTimeSeen) }} ago
          </div>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">{{ availableDriver.userName }}, {{ availableDriver.truckName }}</div>
            <div class="desc">
              -
            </div>
          </div>
          <div class="time-wrap">
            <h3>{{ getHos(availableDriver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
        </div>
      </a>
    </ng-container>
  </div>
</div>
