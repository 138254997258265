import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud.service';
import { filter, take, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface UploadedFile {
  bucketName: string;
  fileName: string;
  id: number;
  path: string;
  purchaseOrderId: number;
  uploadedTimestamp: string;
  url: string;
}

@Injectable()
export class MineApiService {
  get route(): string {
    return environment.api + '/mine';
  }

  constructor(private crud: CrudService, private http: HttpClient) {}

  getMines() {
    return this.crud.get(this.route);
  }

  getMine(id) {
    return this.crud.get(this.route + '/' + id);
  }

  softDeleteMine(id: number) {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.delete(`${this.route}/${id}`)),
    );
  }

  createMine(mine) {
    return this.crud.post(this.route, mine);
  }

  updateMine(id, mine) {
    return this.crud.put(this.route + '/' + id, mine);
  }

  public getAttachments$(mineId): Promise<UploadedFile[]> {
    return this.crud.get(`${environment.api}/mine/${mineId}/attachments`).toPromise();
  }

  public uploadMineAttachment$$(mineId, fileToUpload, fileName): Observable<any[]> {
    if (fileToUpload) {
      const formData = new FormData();
      formData.append('mineId', mineId);
      const lastDot = fileToUpload.name.lastIndexOf('.');
      const ext = fileToUpload.name.substring(lastDot + 1);
      formData.append('file', fileToUpload, fileName + '.' + ext);
      return this.crud.post(`${environment.api}/mine/attachment`, formData);
    } else {
      return throwError('File was not set, please try again');
    }
  }

  public deletePOAttachment$(fileId: number, mineId: number): Observable<any[]> {
    if (fileId > 0 && mineId > 0) {
      const options = {
        body: {
          fileId,
          mineId,
        },
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.delete<any[]>(`${environment.api}/mine/attachment`, options);
    } else {
      return throwError('File Id and loader Id not present.');
    }
  }
}
