import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '~services/user.service';

@Directive({
  selector: '[saIsBrokerage]',
})
export class IsBrokerageDirective implements OnInit {
  private hasView = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
  ) {}

  private show() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  ngOnInit(): void {
    if (this.userService.isBrokerageAccount()) {
      this.show();
    }
  }
}
