<div class="switch-contract">
  <div class="contract-dropdown">
    <mat-select [formControl]="selectedContract" placeholder="Contract" [compareWith]="compareFn">
      <mat-option *ngFor="let contract of _data.vendorContracts" [value]="contract">
        {{ contract.name }}
        <span class="blue-capsule" *ngIf="contract.live">(Contract Applied on Order)</span>
        <span *ngIf="checkCurrentContract(contract)">(Current Vendor Contract)</span>
      </mat-option>
    </mat-select>
  </div>
  <form [formGroup]="contractForm" *ngIf="contractForm; else loadingForm">
    <div class="details col">
      <mat-form-field class="form-field">
        <input matInput placeholder="Contract Name" formControlName="name" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Hourly Detention Rate ($)"
          [formControl]="contractForm.controls['hourlyDetentionRate']"
          autocomplete="off"
        />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Max Detention per Load ($)"
          [formControl]="contractForm.controls['maxDetentionPerLoad']"
          autocomplete="off"
        />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Pickup Free Time (minutes)"
          [formControl]="contractForm.controls['pickupFreeTime']"
          autocomplete="off"
          type="number"
        />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Dropoff Free Time (minutes)"
          [formControl]="contractForm.controls['dropoffFreeTime']"
          autocomplete="off"
          type="number"
        />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Deadhead Free Mileage"
          [formControl]="contractForm.controls['deadheadFreeMileage']"
          autocomplete="off"
          type="number"
        />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Deadhead Cost per Mile ($)"
          [formControl]="contractForm.controls['deadheadCostPerMile']"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div class="distribution col" *ngIf="costsPerLoad && costsPerLoad.length">
      <h4>Cost Per Load</h4>
      <div class="distributions">
        <ng-container *ngFor="let costPerLoad of costsPerLoad.controls; let i = index" formArrayName="costsPerLoad">
          <div class="costs-per-load" [formGroupName]="i">
            <mat-form-field class="costs-per-load-input">
              <input
                matInput
                type="number"
                placeholder="Min Miles"
                formControlName="minimumMileage"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-form-field class="costs-per-load-input">
              <input
                matInput
                type="number"
                placeholder="Max Miles"
                formControlName="maximumMileage"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-form-field class="costs-per-load-input">
              <input matInput placeholder="Cost ($)" formControlName="cost" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
  <ng-template #loadingForm>
    <div class="spinner-wrap">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
  <div class="button-wrap">
    <button class="cancel" mat-raised-button mat-dialog-close>
      Close
    </button>
    <button class="submit" mat-raised-button color="primary" [mat-dialog-close]="selectedContract.value">
      Use {{ this.selectedContract.value.name }} Contract
    </button>
  </div>
</div>
