<div class="card-container">
  <div class="card-content" fxLayout="column" fxLayoutAlign="center">
    <div class="label">
      {{ label }}
    </div>

    <div class="sublabel">
      {{ sublabel }}
    </div>
  </div>
</div>
