<div class="detail-view-input-container">
  <div class="detail-view-input">
    <label>{{ label }}</label>
    <input
      #detailInput
      [ngClass]="{ error: error }"
      placeholder="{{ placeholder }}"
      [(ngModel)]="editableValue"
      (ngModelChange)="validate()"
      (keyup.enter)="this.blur()"
      (keyup.esc)="this.revert()"
      saAutomatedSelector="{{ 'user-creation__' + label }}"
      (blur)="onBlur()"
    />
  </div>
  <div *ngIf="error" class="detail-input-error">{{ error }}</div>
</div>
