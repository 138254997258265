import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
require('moment-precise-range-plugin');

@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  public transform(timeString: string, suffix = true): string {
    return preciseDiffToHuman(timeString, suffix);
  }
}

interface PreciseDiff {
  days: number;
  firstDateWasLater: boolean;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}

const dateOrder: (keyof PreciseDiff)[] = ['years', 'months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = { months: 'mo' };

function preciseDiffToHuman(dateAsString: string, suffix = true): string {
  const asMoment = moment(dateAsString);
  if (asMoment.isValid) {
    const preciseDiff: PreciseDiff = (moment as any).preciseDiff(moment(), asMoment, true);
    if (!preciseDiff.firstDateWasLater) {
      return '-';
    }
    const result = [];
    let dateOrderIndex = 0;
    while (dateOrderIndex < dateOrder.length && result.length < 2) {
      const key = dateOrder[dateOrderIndex];
      if (preciseDiff[key] > 0) {
        const keyName = substitutes[key] ? substitutes[key] : key.substring(0, 1).toLocaleLowerCase();
        result.push(`${preciseDiff[key]}${keyName}`);
      }
      dateOrderIndex++;
    }
    if (result.length) {
      if (suffix) {
        return `${result.join(' ')} ago`;
      }
      return result.join(' ');
    }
    return 'just now';
  }
  return '-';
}
