<div class="runboard-summary-container">
  <div class="head-wrap">
    <a class="back-wrap" [routerLink]="['/', 'map', 'jobs']">
      <i class="material-icons">arrow_back</i>
      Back to Wells
    </a>
  </div>

  <div class="spin-wrap">
    <h1 *ngIf="summary$ | async as summary; else loading">{{ summary.name }}</h1>
    <ng-container *ngIf="driverPool$ | async as driverPool">
      <ng-container *ngIf="driverPool.shouldShow">
        <div class="truck-recommendation">
          {{ driverPool.message }}
        </div>
        <div class="sub-message" [class.warning]="driverPool.isExtreme" *ngIf="driverPool.subMessage !== ''">
          {{ driverPool.subMessage }}
        </div>
      </ng-container>
    </ng-container>
    <ng-template #loading>
      <div class="spinner-div">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    </ng-template>
  </div>

  <div class="runboard-wrap">
    <mat-form-field class="search-form">
      <input matInput autocomplete="off" [formControl]="searchForm" placeholder="Search" />
    </mat-form-field>
    <ng-container *ngIf="noContent() | async">
      <h3 class="sub-header">Your runboard is clear!</h3>
    </ng-container>

    <h3 class="sub-header" *ngIf="(needsDriver$ | async)?.length as needsDriverCount">
      Needs Driver ({{ needsDriverCount }})
    </h3>
    <ng-container *ngFor="let order of needsDriver$ | async; trackBy: trackByOrderId">
      <a class="line-item" [routerLink]="['/', 'map', 'jobs', 'detail', order.fracId, 'pending', order.loadNumber]">
        <div class="header">
          <ng-container *ngIf="isVorto()">
            <div
              class="text-red-500"
              *ngIf="(order.autoDispatchPaused && order.autoDispatchPauseEndsAt) || order.doNotAutoDispatch"
            >
              <div>AutoDispatch Paused</div>
              <div>Resuming in {{ order.autoDispatchPauseEndsAt | until: false }}</div>
            </div>
          </ng-container>
          <div class="status blue-text">
            Add Driver
          </div>
          <ng-container>
            <div class="timestamp red-text" *ngIf="order.boostPercent > 0; else urgent">
              {{ order.boostPercent }}% Boost!
            </div>
            <ng-template #urgent>
              <ng-container *ngIf="order.urgent; else upForGrabs">
                <div class="timestamp red-text">Urgent</div>
              </ng-container>
            </ng-template>
            <ng-template #upForGrabs>
              <div class="timestamp" [ngClass]="getOTVSStatusClass(order) | async" *ngIf="order.upForGrabsTimestamp">
                <span> {{ getCountdown(order) | async }} Remaining </span>
              </div>
            </ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="order.mineName && order.fracName">
          <div class="item-body" *saFeatureFlag="'logRunboardAddDriverDetails'">
            <div class="desc">{{ order.mineName }} →</div>
            <div class="desc">{{ order.fracName }}</div>
          </div>
        </ng-container>
      </a>
    </ng-container>
    <h3 class="sub-header" *ngIf="(notAvailableForLoads$ | async)?.length as unassignedUsersCount">
      Not Available for Loads ({{ unassignedUsersCount }})
    </h3>
    <ng-container *ngFor="let driver of notAvailableForLoads$ | async">
      <a class="line-item" routerLink="{{ driver.userId }}">
        <div class="header">
          <div class="status red-text">
            OFFLINE
          </div>
          <div class="timestamp italic" [ngClass]="{ 'red-text': driver.userStatus.type === 'Unreachable' }">
            <span>Updated</span>
            {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
          </div>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">{{ driver.userName }}</div>
            <div class="item-details flex items-center">
              {{ driver.userStatus.phoneNumber | phone }}
              <i class="cursor-pointer material-icons" (click)="copyDriverPhone($event, driver)">content_copy</i>
            </div>
            <div class="item-details" *ngIf="driver.subcarrierName">{{ driver.subcarrierName }}</div>
            <div class="desc" *ngIf="isShaleApps">({{ driver.subcontractorName }})</div>
            <div class="desc">-</div>
          </div>
          <div class="time-wrap">
            <h3>{{ getHos(driver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
        </div>
      </a>
    </ng-container>
    <h3 class="sub-header" *ngIf="(availableUndispatchedDriver$ | async)?.length as availableUndispatchedDriversCount">
      Available For Loads ({{ availableUndispatchedDriversCount }})
    </h3>
    <ng-container *ngFor="let availableDriver of availableUndispatchedDriver$ | async">
      <a class="line-item" routerLink="{{ availableDriver.userId }}">
        <div class="header">
          <div class="status">
            ONLINE
          </div>
          <div class="timestamp italic" [ngClass]="{ 'red-text': availableDriver.userStatus.type === 'Unreachable' }">
            <span>Updated</span>
            {{ helperService.calculateReceivingTime(availableDriver.userStatus.lastTimeSeen) }} ago
          </div>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">{{ availableDriver.userName }}</div>
            <div class="item-details flex items-center">
              {{ availableDriver.userStatus.phoneNumber | phone }}
              <i class="cursor-pointer material-icons" (click)="copyDriverPhone($event, availableDriver)"
                >content_copy</i
              >
            </div>
            <div class="item-details" *ngIf="availableDriver.subcarrierName">{{ availableDriver.subcarrierName }}</div>
            <div class="trailer-type-name" *ngIf="isShaleApps && availableDriver.trailerTypeName">
              {{ availableDriver.trailerTypeName }}
            </div>
            <div class="trailer-type-name" *ngIf="availableDriver.userTrailerTypeName">
              {{ availableDriver.userTrailerTypeName }}
            </div>
            <div class="desc" *ngIf="isShaleApps">({{ availableDriver.subcontractorName }})</div>
            <div
              class="desc warning"
              *ngIf="availableDriver.driverPauseMinutesRemaining && availableDriver.driverPauseMinutesRemaining > 0"
            >
              Autodispatch has been paused for this driver ({{ availableDriver.driverPauseMinutesRemaining }}m left)
            </div>
            <div class="desc" *ngIf="!availableDriver.driverPauseMinutesRemaining">
              -
            </div>
            <div *saFeatureFlag="'showRunboardMissingDataNotification'">
              <div
                class="warning"
                *ngIf="availableDriver.podNeeded?.podNeededFor?.length > 0"
                [matTooltip]="formatDriverMissingData(availableDriver.podNeeded?.podNeededFor)"
              >
                Auto dispatch paused - missing previous load details or BOL
              </div>
            </div>
          </div>
          <div class="time-wrap">
            <h3>{{ getHos(availableDriver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
        </div>
      </a>
    </ng-container>
    <h3 class="sub-header" *ngIf="(activeDrivers$ | async)?.length as activeDriverCount">
      Active Drivers ({{ activeDriverCount }})
    </h3>
    <ng-container *ngFor="let driver of activeDrivers$ | async">
      <a class="line-item" routerLink="{{ driver.userId }}">
        <div class="header">
          <div class="status blue-text">
            <span *ngIf="driver.loaded">TO WELL</span>
            <span *ngIf="!driver.loaded">TO MINE</span>
          </div>
          <ng-container *saFeatureFlag="'onOrderTooLong'; else onlyUpdatedToLoaders">
            <div
              class="timestamp italic red-text"
              *ngIf="driver.dispatchTimestamp | onOrderTooLong; else updatedAtToLoaders"
            >
              On Load for {{ driver.dispatchTimestamp | ago: false }}
            </div>
            <ng-template #updatedAtToLoaders>
              <ng-container *ngIf="driver.orderStatus === 'dispatched'; else acceptedOrder">
                <div
                  class="timestamp timestamp-updated italic"
                  [ngClass]="{ 'red-text': helperService.checkIfOnOrderTooLong(driver.dispatchTimestamp) }"
                >
                  <span>Sent</span>
                  {{ helperService.calculateReceivingTime(driver.dispatchTimestamp) }} ago
                </div>
              </ng-container>
              <ng-template #acceptedOrder>
                <div
                  class="timestamp timestamp-updated italic"
                  [ngClass]="{ 'red-text': driver?.userStatus?.type === 'Unreachable' }"
                >
                  <span>Updated</span>
                  {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
                </div>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-template #onlyUpdatedToLoaders>
            <ng-container *ngIf="driver.orderStatus === 'dispatched'; else acceptedWellOrder">
              <div
                class="timestamp timestamp-updated italic"
                [ngClass]="{ 'red-text': helperService.checkIfOnOrderTooLong(driver.dispatchTimestamp) }"
              >
                <span>Sent</span>
                {{ helperService.calculateReceivingTime(driver.dispatchTimestamp) }} ago
              </div>
            </ng-container>
            <ng-template #acceptedWellOrder>
              <div
                class="timestamp timestamp-updated italic"
                [ngClass]="{ 'red-text': driver?.userStatus?.type === 'Unreachable' }"
              >
                <span>Updated</span>
                {{ helperService.calculateReceivingTime(driver.userStatus.lastTimeSeen) }} ago
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div class="sequence-id">
          <div class="flex items-center">
            {{ driver.saUniqueId }}
            <sa-lohi-preload-status [order]="driver"></sa-lohi-preload-status>
            <img
              *ngIf="driver.boxPickup"
              class="w-4"
              src="/assets/icons/box_move.svg"
              alt="box move"
              matTooltip="Box Move {{ driver.boxPickup.siteName }}"
            />
          </div>
          <div>Seq. #: {{ driver.loadNumber }}</div>
        </div>

        <div class="item-content">
          <div class="item-body">
            <div class="item-details">
              <span class="driver-name">{{ driver.userName }}</span
              >, {{ driver.truckName }}
            </div>
            <div class="item-details" *ngIf="driver.subcarrierName">
              <span class="driver-name">{{ driver.subcarrierName }}</span>
            </div>
            <div class="trailer-type-name" *ngIf="isShaleApps && driver.trailerTypeName">
              {{ driver.trailerTypeName }}
            </div>
            <div class="trailer-type-name" *ngIf="driver.userTrailerTypeName">
              {{ driver.userTrailerTypeName }}
            </div>
            <div class="desc" *ngIf="isShaleApps">({{ driver.subcontractorName }})</div>
            <div class="desc">
              <span *ngIf="driver.loaded">{{ driver.fracName }}</span>
              <span *ngIf="!driver.loaded">{{ driver.mineName }}</span>
              - {{ driver.meshType }} Mesh
            </div>
            <div class="alert-wrap" *ngFor="let reason of checkBolMissingDetails(driver); trackBy: trackByFnReason">
              <div>
                <img src="/assets/icons/alarm-24.svg" alt="Alert" />
              </div>
              {{ reason }}
            </div>
          </div>
          <div class="time-wrap" *ngIf="isOnline(driver?.userStatus) || isUnreachable(driver?.userStatus)">
            <h3>{{ getHos(driver.userStatus) | async }}</h3>
            <h5>EST. HOS</h5>
          </div>
          <div class="action-needed" *ngIf="isActionNeeded(driver?.userStatus)">
            Action Needed
          </div>
        </div>
      </a>
    </ng-container>
  </div>
</div>
