import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FracCreationMapComponent, SiteLocation } from '../../wells/frac-creation-map/frac-creation-map.component';
import { trackById } from '~lmo/utilities';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudService } from '~services/crud.service';
import { UserApiService } from '~services/api/user.api.service';
import { ConstantsApiService } from '~services/api/constants.api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from '~services/user.service';
import { Location } from '@angular/common';
import { ErrorHandlingService } from '~services/error-handling.service';
import { User } from '~models/user';
import { LightTheme } from '../../map/mapStyles';
import { debounceTime, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { FracStorage } from '~lmo/models/frac.model';
import { ChoiceDialogComponent } from '../../ui-components/choice-dialog/choice-dialog.component';
import { ScheduleValidator, StorageMeshValidator } from '~services/custom-validators';
import { DistributionCenterApiService } from '~services/api/distribution-center.api.service';
import {
  DCStorageSupplementalMeshUpdateComponent,
  StorageSupplementalMeshUpdateData,
} from '../../wells/dc-storage-supplemental-mesh-update/dc-storage-supplemental-mesh-update.component';
import {
  DistributionCenterStorageCreationComponent,
  StorageCreationComponentData,
} from '../../wells/dc-storage-creation/dc-storage-creation.component';
import { getDistributionCenterName } from '../../ui-components/pipes/distribution-center-name.pipe';

export interface UserAndUserGroup {
  id: number;
  type: string;
  name: string;
  sso: boolean;
  userIds: number[];
}

export interface TypeBasedGroup {
  type: string;
  users: UserAndUserGroup[];
}

const USER = 'User';
const GROUP = 'Group';

@Component({
  selector: 'sa-distribution-center-creation',
  templateUrl: './distribution-center-creation.component.html',
  styleUrls: ['./distribution-center-creation.component.scss'],
})
export class DistributionCenterCreationComponent implements OnInit, OnDestroy {
  map: google.maps.Map;
  mapConfig: google.maps.MapOptions;
  private destroy$$ = new Subject();
  locationResults: SiteLocation;
  mapDragEndListener: Subject<any>;
  @ViewChild(FracCreationMapComponent, { static: false }) mapComponent: FracCreationMapComponent;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  dcCreationForm: FormGroup;
  dcId;
  dc;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private crudService: CrudService,
    private distributionCenterApiService: DistributionCenterApiService,
    private userApiService: UserApiService,
    private constantsApiService: ConstantsApiService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private errorHandler: ErrorHandlingService,
  ) {
    this.canEdit = userService.canEditWell();
    this.mapDragEndListener = new Subject<any>();
  }
  formLoading = false;
  formSubmitted = false;
  submitting = false;
  storages: any[];
  storageGroups: any[];
  mines: any[];
  meshTypes: any[];
  loggedUserId: number;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  filterUser: FormControl = new FormControl();
  filteredUserOptions: Observable<TypeBasedGroup[]>;
  canEdit: boolean;
  visibility = true;
  users: UserAndUserGroup[] = [];
  allUsers: User[];
  shape: any;
  public trackById = trackById;

  @HostListener('document:keydown', ['$event'])
  preventGoingBack(evt) {
    if (evt.which === 8 && evt.target.nodeName !== 'INPUT' && evt.target.nodeName !== 'TEXTAREA') {
      evt.preventDefault();
    }
  }

  initMap() {
    setTimeout(() => {
      if (this.map == null) {
        let center = { lat: 39.7392, lng: -104.9903 };
        if (this.dc != null && this.dc.gpsDirectionLocation) {
          center = { lat: +this.dc.gpsDirectionLocation[1], lng: +this.dc.gpsDirectionLocation[0] };
        }
        this.mapConfig = {
          center: center,
          zoom: 12,
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: 3,
          },
          zoomControlOptions: {
            position: 3,
          },
          mapTypeId: google.maps.MapTypeId.HYBRID,
          styles: <any>LightTheme,
        };

        const element = document.getElementById('map-gps-direction-location');
        if (element != null) {
          this.map = new google.maps.Map(element, this.mapConfig);
          this.mapDragEndListener.next(2);
          google.maps.event.addListener(this.map, 'center_changed', () => {
            this.mapDragEndListener.next(2);
          });
        }
      }
    }, 500);
  }

  ngOnInit() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe((ready) => {
      if (ready) {
        this.formLoading = true;

        this.constantsApiService.getMeshTypes().subscribe((meshTypes) => {
          this.meshTypes = meshTypes;
          this.getStoragesByMesh(this.storages);
        });

        this.userApiService.getWebUsers().subscribe((data) => {
          this.allUsers = data.sort((a, b) => a.name.localeCompare(b.name));

          const mappedUsers = data
            .filter((user) => user.accountRoles[0].id !== 1)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((user) => {
              return <UserAndUserGroup>{
                id: user.id,
                name: user.name,
                type: USER,
                sso: user.ssoEnabled,
              };
            });
          this.users.push(...mappedUsers);
          this.addAllowedUserListener();

          // somehow if we exclude this outside of getWebUsers subscription, the dc form won't be able to read the user list and breaks the edit form
          if (this.route.snapshot.params['id'] && this.route.snapshot.params['id'] !== 'new') {
            this.distributionCenterApiService
              .getDistributionCenter(this.route.snapshot.params['id'])
              .subscribe((storages) => {
                this.storages = storages.distributionCenterStorages;
                this.getStoragesByMesh(this.storages);
              });

            this.route.paramMap
              .pipe(
                switchMap((params: ParamMap) => {
                  this.dcId = +params.get('id');

                  if (this.dcId > 0) {
                    return this.distributionCenterApiService.getDistributionCenter(this.dcId);
                  } else {
                    return;
                  }
                }),
              )
              .subscribe((dc) => {
                this.dc = dc;
                this.createDistributionCenterForm();
                this.formLoading = false;
              });
          } else {
            this.createDistributionCenterForm();
            this.formLoading = false;
          }
        });
      }
    });

    this.loggedUserId = this.userService.getUserContractFromCache().account.id;
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  onDisctributionCenterChanges() {
    this.dcCreationForm
      .get('storages')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        if (this.dc && this.dc.distributionCenterStorages) {
          this.dc.distributionCenterStorages.forEach((storage) => {
            this.dcCreationForm.removeControl(storage.id + '_' + storage.mesh.type + ' loadWeight');
          });
        }
      });
  }

  openStorageForm(storage) {
    const createdByAPI = !!this.dc && !!this.dc.createdByAPI;
    const data: StorageCreationComponentData = {
      createdByAPI,
      storage,
      constraint: this.getStorageConstraint(),
    };
    const dialogRef = this.dialog.open(DistributionCenterStorageCreationComponent, {
      width: '80%',
      maxWidth: '968px',
      data,
    });

    function updateStorages(storages: any[], result) {
      for (let i = 0; i < storages.length; i++) {
        if (storages[i].id === result.id) {
          storages[i] = result;
        }
      }
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (storage != null) {
          updateStorages(this.storages, result);
          updateStorages(this.dcCreationForm.get('storages').value, result);
        } else {
          this.dcCreationForm.get('storages').setValue([...this.dcCreationForm.get('storages').value, result]);
        }

        this.getStoragesByMesh(this.storages);
      }
    });
  }

  openStorageSupplementalMeshForm(storage) {
    const data: StorageSupplementalMeshUpdateData = {
      storage,
    };

    const dialogRef = this.dialog.open(DCStorageSupplementalMeshUpdateComponent, {
      width: '80%',
      maxWidth: '968px',
      data,
    });

    function updateStorages(storages: any[], result) {
      for (let i = 0; i < storages.length; i++) {
        if (storages[i].id === result.id) {
          storages[i] = result;
        }
      }
    }

    dialogRef.afterClosed().subscribe((result: { storages: FracStorage[] }) => {
      if (result) {
        const resultStorage = result.storages.find((s) => s.id === storage.id);
        if (resultStorage) {
          updateStorages(this.storages, resultStorage);
          updateStorages(this.dcCreationForm.get('storages').value, resultStorage);
        }
      }
    });
  }

  getStorageConstraint() {
    if (this.storages && this.storages.length) {
      return this.storages[0].storageType;
    }
    const formStorages = this.dcCreationForm.get('storages').value;
    if (formStorages && formStorages.length) {
      return formStorages[0].storageType;
    }
    return null;
  }

  removeStorage(index) {
    const value = this.dcCreationForm.get('storages').value;
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Delete Stage Pumped Volumes for ${value[index].mesh.type} Mesh?`,
        desc: `Removing this storage unit will also delete any pumped volumes entered for ${value[index].mesh.type} Mesh. This cannot be undone.`,
        button: ['Cancel', 'Remove and Delete Pumped Volumes'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        value.splice(index, 1);
        this.dcCreationForm.get('storages').setValue(value);
      }
    });
  }

  createDistributionCenterForm() {
    const allowedUsers = [];
    if (this.dc) {
      if (this.dc.allowedUsers) {
        allowedUsers.push(
          ...this.dc.allowedUsers
            .filter((user) => {
              const filteredUser = this.allUsers.find((allUser) => allUser.id === user.id);
              return (
                filteredUser != null &&
                filteredUser.accountRoles != null &&
                filteredUser.accountRoles.length > 0 &&
                filteredUser.accountRoles[0].id !== 1
              );
            })
            .map((user) => {
              return <UserAndUserGroup>{
                id: user.id,
                name: user.name,
                type: USER,
                sso: user.ssoEnabled,
              };
            }),
        );
      }
    }
    this.dcCreationForm = this.fb.group(
      {
        name: [(this.dc ? this.dc.site.name : null) as string, [Validators.required]],
        storages: [
          (this.dc ? this.dc.distributionCenterStorages.sort((a, b) => (a.name < b.name ? -1 : 1)) : []) as any[],
          [Validators.required, StorageMeshValidator()],
        ],
        allowedUsers: [allowedUsers as UserAndUserGroup[], []],
      },
      {
        validator: [ScheduleValidator('emails', 'schedule')],
      },
    );

    this.initMap();

    this.onDisctributionCenterChanges();
  }

  getStoragesByMesh(storages) {
    if (storages && this.meshTypes) {
      this.storageGroups = [];

      this.meshTypes.forEach((meshType) => {
        const storagesForMesh = storages.filter((storage) => {
          return storage.mesh.type === meshType.type;
        });

        this.storageGroups.push({
          name: meshType.type,
          storages: storagesForMesh,
        });
      });
    }
  }

  closeForm(dcId?: number) {
    if (dcId) {
      this.router.navigate(['/', 'lmo', 'dc', dcId, 'home']);
    } else {
      this.router.navigate(['/', 'lmo', 'dc', 'list']);
    }
  }

  validateDcInput(): boolean {
    if (this.dcCreationForm.controls['storages'].invalid) {
      if (this.dcCreationForm.controls['storages'].getError('storageMesh')) {
        this.errorHandler.showError('Multiple storage of same Mesh are not allowed.', 5000);
        return false;
      } else {
        this.errorHandler.showError('Please have atleast 1 storage', 5000);
        return false;
      }
    } else if (this.dcCreationForm.valid) {
      // For whatever reason, I can't get the custom reactive forms to work on this form, this is a stop-gap until I figure out why.
      this.locationResults = this.mapComponent.asSiteLocation();
      this.formSubmitted = false;
      this.submit();
      return true;
    } else {
      this.errorHandler.showError('Please update all fields.', 5000);
      return false;
    }
  }

  submit() {
    // No repeated wells
    if (this.submitting) {
      return;
    }

    this.formSubmitted = false;

    const dcStorages = [];

    this.dcCreationForm.controls['storages'].value.forEach((storage) => {
      dcStorages.push(storage);
    });
    const requestBody: any = {
      site: {
        name: this.dcCreationForm.controls['name'].value.trim(),
        ...this.locationResults,
      },
      storages: this.dcCreationForm.controls['storages'].value,
      allowedUsers: this.dcCreationForm.controls['allowedUsers'].value.filter((user) => user.type === USER),
      distributionCenterStorages: dcStorages,
    };

    if (this.dcCreationForm.valid) {
      this.submitting = true;
      if (this.dc && this.dc.id) {
        this.distributionCenterApiService.updateDistributionCenter(this.dc.id, requestBody).subscribe(
          (resp) => {
            this.formSubmitted = false;
            this.submitting = false;
            this.snackBar.open(this.dcCreationForm.controls['name'].value + ' successfully updated.', null, {
              duration: 5000,
            });
            this.closeForm(resp.id);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      } else {
        this.distributionCenterApiService.createDistributionCenter(requestBody).subscribe(
          (resp) => {
            this.formSubmitted = false;
            this.submitting = false;
            this.snackBar.open(this.dcCreationForm.controls['name'].value + ' successfully created.', null, {
              duration: 5000,
            });
            this.closeForm(resp.id);
          },
          (err) => {
            this.formSubmitted = false;
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  compareByTypeAndId(o1: UserAndUserGroup, o2: UserAndUserGroup) {
    return o1 && o2 && o1.id === o2.id && o1.type === o2.type;
  }

  public getName(user, position, length) {
    return user.name + (user.sso ? ' (sso) ' : '') + (position < length - 1 ? ', ' : '');
  }

  public userSelected(groupUser) {
    if (groupUser.type === GROUP) {
      const selectedGroup = this.dcCreationForm
        .get('allowedUsers')
        .value.filter((user) => user.type === GROUP)
        .find((user) => user.id === groupUser.id);
      if (selectedGroup != null) {
        const userIds = groupUser.userIds;
        const selectedUsers = this.dcCreationForm.get('allowedUsers').value;
        const newNeedToSelectUserIds = userIds.filter((userId) => {
          const selectedUser = selectedUsers.find((user) => user.type === USER && user.id === userId);
          return selectedUser == null;
        });
        this.filteredUserOptions.subscribe((groups) => {
          groups.forEach((group) => {
            if (group.type === USER) {
              const newUsers = group.users.filter((user) => newNeedToSelectUserIds.indexOf(user.id) >= 0);
              selectedUsers.push(...newUsers);
            }
          });
        });
        this.dcCreationForm.get('allowedUsers').setValue(selectedUsers);
      } else {
        const userIds = groupUser.userIds;
        const selectedUsers = this.dcCreationForm.get('allowedUsers').value;
        const newSelectedUsers = selectedUsers.filter((user) => user.type === GROUP || userIds.indexOf(user.id) < 0);
        this.dcCreationForm.get('allowedUsers').setValue(newSelectedUsers);
      }
    }
  }

  private addAllowedUserListener() {
    this.filteredUserOptions = this.filterUser.valueChanges.pipe(
      startWith<string | any>(''),
      // map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.users)),
      map((userAndUserGroups) => {
        const user = <TypeBasedGroup>{ type: USER, users: [] };
        userAndUserGroups.forEach((value) => {
          user.users.push(value);
        });
        const groups: TypeBasedGroup[] = [];
        groups.push(user);
        return groups;
      }),
    );
  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.users.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  changeDcStatus() {
    if (this.dc.archivedAt) {
      if (confirm('Are you sure you want to unarchive this distribution center?')) {
        this.distributionCenterApiService.activateDistributionCenter(this.dc.id).subscribe(
          (resp) => {
            this.snackBar.open(this.dc.site.name + ' successfully activated.', null, {
              duration: 5000,
            });
            this.ngOnInit();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    } else {
      const dialogData = {
        context: ``,
        desc: '',
        button: [],
      };

      dialogData.context = `Archive ${getDistributionCenterName(this.dc)}?`;
      dialogData.desc = `Are you sure? You will not be able to view, dispatch, or order loads for ${getDistributionCenterName(
        this.dc,
      )} while it is archived.`;
      dialogData.button = ['Back', 'Archive'];

      const dialogRef = this.dialog.open(ChoiceDialogComponent, {
        width: '30%',
        maxWidth: '968px',
        data: dialogData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.distributionCenterApiService.archiveDistributionCenter(this.dc.id).subscribe(
            (resp) => {
              this.snackBar.open(this.dcCreationForm.controls['name'].value + ' successfully archived.', null, {
                duration: 5000,
              });
              this.ngOnInit();
            },
            (err) => {
              this.errorHandler.showError(err, 5000);
            },
          );
        }
      });
    }
  }
}
