import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserService } from '~services/user.service';
import { EmailSettingsV2Service } from '~services/email-settings-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { EmailData, LoaderIntegrationLoad } from '~models/emailSettings.model';
import { FeatureFlagService } from '~services/feature-flag.service';

@Component({
  selector: 'sa-loads-on-date',
  templateUrl: './loads-on-date.component.html',
  styleUrls: ['./loads-on-date.component.scss'],
})
export class LoadsOnDateComponent implements OnInit, OnDestroy {
  activeDataSource: MatTableDataSource<LoaderIntegrationLoad>;

  baseColumns = [
    'uploadTime',
    'poNumber',
    'bolNumber',
    'loadWeight',
    'pickupTime',
    'truckNumber',
    'potentialMatchCount',
    'orderId',
    'matchReasons',
  ];

  displayedColumns = this.baseColumns;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  subscriptions: Subscription[] = [];

  public emailData$: Observable<EmailData>;

  public goodsReceiptEditingRow: LoaderIntegrationLoad | null = null;

  public goodsIssuedEditingRow: LoaderIntegrationLoad | null = null;

  public filter = 'all';

  private filter$$ = new BehaviorSubject<string>('all');

  public loaderTimezone: string;

  private goodsReceiptGoodsIssuedEnabled = false;

  private ploNumberEnabled = false;

  constructor(
    private route: ActivatedRoute,
    public emailSettingsService: EmailSettingsV2Service,
    public userService: UserService,
    public router: Router,
    private featureFlagService: FeatureFlagService,
  ) {}

  public async ngOnInit() {
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;

    this.emailData$ = this.emailSettingsService.emailData$;

    this.filter = this.route.snapshot.queryParams['filter'];
    if (this.filter !== 'matched' && this.filter !== 'unmatched' && this.filter !== 'all') {
      this.router.navigate([], { queryParams: { filter: 'all' } });
      this.filter = 'all';
    }

    this.filter$$.next(this.filter);

    const featureFlags = await this.featureFlagService.flags$
      .pipe(
        filter((flags) => flags !== null),
        take(1),
      )
      .toPromise();

    if (featureFlags.loaderIntegrationShowPLONumber) {
      this.ploNumberEnabled = true;
    }

    if (featureFlags.goodsReceiptGoodsIssued) {
      this.goodsReceiptGoodsIssuedEnabled = true;
    }

    this.subscriptions.push(
      combineLatest([this.emailSettingsService.loadsForDate$, this.filter$$]).subscribe(([loads, filterString]) => {
        if (loads) {
          loads.forEach((load) => {
            if (load.goodsReceipt === null && load.tempGoodsReceipt !== null) {
              load.goodsReceipt = load.tempGoodsReceipt;
            }
            if (load.goodsIssued === null && load.tempGoodsIssued !== null) {
              load.goodsIssued = load.tempGoodsIssued;
            }
          });
          this.activeDataSource.data = this.filterLoads(loads, filterString);
        } else {
          this.activeDataSource.data = [];
        }
      }),
      this.emailData$.subscribe((emailData) => {
        if (emailData) {
          this.loaderTimezone = emailData.loaderTimezone;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  public toMatchReasonText(matchReason: string, loadData: LoaderIntegrationLoad): string {
    const matchReasonMap = {
      site_visit: 'Mine Site Visit Time',
      bol_number: 'BOL Number',
      po_number: 'PO Number',
      truck_number: 'Truck Number',
      exact_load_weight: 'Exact Load Weight',
    };

    if (matchReason === 'partial_truck_number') {
      return `Partial Truck Number (${loadData.partialTruckNumberMatchLength} chars)`;
    }

    return matchReasonMap[matchReason];
  }

  public filterTypeChange() {
    this.router.navigate([], { queryParams: { filter: this.filter }, skipLocationChange: true });
    this.filter$$.next(this.filter);
  }

  private filterLoads(loads: LoaderIntegrationLoad[], filterString: string): LoaderIntegrationLoad[] {
    if (
      !this.displayedColumns.includes('fracBillingIdentifier') &&
      this.filter !== 'unmatched' &&
      this.ploNumberEnabled
    ) {
      this.displayedColumns.push('fracBillingIdentifier');
    }

    if (
      !this.displayedColumns.includes('goodsReceipt') &&
      !this.displayedColumns.includes('goodsIssued') &&
      this.filter !== 'unmatched' &&
      this.goodsReceiptGoodsIssuedEnabled
    ) {
      this.displayedColumns.push('goodsReceipt', 'goodsIssued');
    }

    if (filterString === 'matched') {
      return loads.filter((load) => load.matched);
    }

    if (filterString === 'unmatched') {
      const itemsToRemove: string[] = ['goodsReceipt', 'goodsIssued', 'fracBillingIdentifier'];
      this.displayedColumns = this.displayedColumns.filter((item) => !itemsToRemove.includes(item));
      return loads.filter((load) => !load.matched);
    }

    return loads;
  }

  public startEditGoodsReceipt(row: LoaderIntegrationLoad) {
    if (this.goodsReceiptEditingRow === null) {
      this.goodsReceiptEditingRow = row;
    }
  }

  public startEditGoodsIssued(row: LoaderIntegrationLoad) {
    if (this.goodsIssuedEditingRow === null) {
      this.goodsIssuedEditingRow = row;
    }
  }
  public updateGoodsReceipt(load: LoaderIntegrationLoad) {
    if (this.goodsReceiptEditingRow) {
      this.emailSettingsService.updateGoodsReceipt(load, null);
      this.goodsReceiptEditingRow = null;
    }
  }

  public updateGoodsIssued(load: LoaderIntegrationLoad) {
    if (this.goodsIssuedEditingRow) {
      this.emailSettingsService.updateGoodsIssued(load, null);
      this.goodsIssuedEditingRow = null;
    }
  }

  public cancelEditGoodsReceipt() {
    this.goodsReceiptEditingRow = null;
  }

  public cancelEditGoodsIssued() {
    this.goodsIssuedEditingRow = null;
  }
}
