import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService } from '~services/error-handling.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailSettingsService } from '~services/email-settings.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sa-email-analytics',
  templateUrl: './email-analytics.component.html',
  styleUrls: ['./email-analytics.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmailAnalyticsComponent implements OnInit {
  public analyticsForm: FormGroup;
  public todayDate;
  public isDownloading = false;
  public subLoading = false;
  public searchTerm: string;
  public analyticsData: MatTableDataSource<any>;
  public columnNameMap = {
    siteName: 'Site Name',
    email: 'Email',
    uploadTime: 'Upload Time',
    date: 'Upload Date',
    purchaseOrderName: 'Purchase Order Name',
    numberOfEmails: 'Number of Emails',
    numberOfOrdersFound: 'Number of Orders Found',
    vendorOrPoNotFoundInExcel: 'Vendor/PO Not Found in Excel',
    couldNotMatch: 'Could Not Match',
    truckTimingMatched: 'Truck Timing Matched',
    poLoadNumberMatched: 'PO and Load Number Matched',
    dataOverwrite: 'Data Overwrite',
    uniqueId: 'Unique ID',
    download: 'Download',
  };
  public displayedColumns = [
    'siteName',
    'email',
    'date',
    'purchaseOrderName',
    'numberOfEmails',
    'numberOfOrdersFound',
    'vendorOrPoNotFoundInExcel',
    'couldNotMatch',
    'truckTimingMatched',
    'poLoadNumberMatched',
    'dataOverwrite',
  ];
  public columnsToDisplay = [
    'siteName',
    'uploadTime',
    'purchaseOrderName',
    'uniqueId',
    'numberOfEmails',
    'numberOfOrdersFound',
    'vendorOrPoNotFoundInExcel',
    'couldNotMatch',
    'truckTimingMatched',
    'poLoadNumberMatched',
    'dataOverwrite',
    'download',
  ];
  expandedElement: any | null;
  stats: any[];

  constructor(
    private fb: FormBuilder,
    private errorHandler: ErrorHandlingService,
    private snackBar: MatSnackBar,
    public emailSettingsService: EmailSettingsService,
  ) {}

  ngOnInit() {
    this.todayDate = new Date();
    this.setupForm();
  }

  private setupForm() {
    this.analyticsForm = this.fb.group({
      startDate: [this.todayDate, [Validators.required]],
      endDate: [this.todayDate, [Validators.required]],
    });
  }

  public getAnalytics() {
    this.isDownloading = true;
    const startTime = this.analyticsForm.controls['startDate'].value.setHours(0, 0, 0);
    const endTime = this.analyticsForm.controls['endDate'].value.setHours(23, 59, 59);
    const body = {
      startDate: new Date(startTime),
      endDate: new Date(endTime),
    };
    this.emailSettingsService.getAnalytics(body).subscribe(
      (data) => {
        this.analyticsData = new MatTableDataSource<any>(data);
        this.stats = [...data];
        this.isDownloading = false;
      },
      (err) => {
        this.errorHandler.showError(err);
        this.isDownloading = false;
      },
    );
  }

  public applyFilter() {
    this.analyticsData.filter = this.searchTerm.trim().toLowerCase();
  }

  public getStatsByEmail(element, index) {
    this.subLoading = true;
    this.emailSettingsService.getStatsByEmail({ email: element.email, uploadDate: element.date }).subscribe(
      (data) => {
        this.stats[index].stats = new MatTableDataSource(data);
        this.subLoading = false;
      },
      (err) => {
        this.errorHandler.showError(err);
        this.subLoading = false;
      },
    );
  }

  public downloadFile(uniqueId) {
    this.emailSettingsService.downloadFileByUniqueId(uniqueId).subscribe(
      (link) => {
        window.open(link, '_blank');
      },
      (err) => {
        this.errorHandler.showError(err);
      },
    );
  }
}
