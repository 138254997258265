import { Component, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  Validator,
  ControlValueAccessor,
  FormControl,
} from '@angular/forms';
import { LightTheme } from 'src/app/map/mapStyles';
import { LatitudeValidator, LongitudeValidator } from '~services/custom-validators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SitesService } from '~services/sites.service';
import { Site } from '~models/site';

const metersInAMile = 1609;

export interface SiteLocation {
  lngLat: [number, number];
  radius: number;
  outerLngLat?: [number, number];
  outerRadius?: number;
  stagingRoadIsOneWay?: boolean;
  minutesFromStagingPadToFrac?: number;
}

interface FormValue {
  longitude: number;
  latitude: number;
  radius: number;
  outerLatitude?: number;
  outerLongitude?: number;
  outerRadius?: number;
  stagingRoadIsOneWay: boolean;
  minutesFromStagingPadToFrac?: number;
}

@Component({
  selector: 'sa-frac-creation-map',
  templateUrl: './frac-creation-map.component.html',
  styleUrls: ['./frac-creation-map.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FracCreationMapComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FracCreationMapComponent),
      multi: true,
    },
  ],
})
export class FracCreationMapComponent implements OnInit, OnDestroy, Validator, ControlValueAccessor {
  @Input() site: Site;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  private outerSitePosition: google.maps.LatLng;

  lastValidCoords: Partial<FormValue> = {};

  locationForm: FormGroup;
  sites: Record<number, { circle: google.maps.Circle; marker?: google.maps.Marker }> = {};
  subscriptions: Subscription[] = [];
  _showOtherSites = false;
  _showOuterSite = false;

  map: google.maps.Map;
  mapBoundsChanged: BehaviorSubject<null> = new BehaviorSubject<null>(null);

  geofence: google.maps.Circle;
  outerGeofence: google.maps.Circle;
  fracMarker: google.maps.Marker;
  outerFracMarker: google.maps.Marker;

  public get radius(): FormControl {
    return this.locationForm.get('radius') as FormControl;
  }

  public get latitude(): FormControl {
    return this.locationForm.get('latitude') as FormControl;
  }

  public get longitude(): FormControl {
    return this.locationForm.get('longitude') as FormControl;
  }

  public get outerRadius(): FormControl {
    return this.locationForm.get('outerRadius') as FormControl;
  }

  public get outerLatitude(): FormControl {
    return this.locationForm.get('outerLatitude') as FormControl;
  }

  public get outerLongitude(): FormControl {
    return this.locationForm.get('outerLongitude') as FormControl;
  }

  public get stagingRoadIsOneWay(): FormControl {
    return this.locationForm.get('stagingRoadIsOneWay') as FormControl;
  }

  public get minutesFromStagingPadToFrac(): FormControl {
    return this.locationForm.get('minutesFromStagingPadToFrac') as FormControl;
  }

  public get showOtherSites(): boolean {
    return this._showOtherSites;
  }

  public set showOtherSites(show: boolean) {
    this._showOtherSites = show;
    if (show) {
      this.loadSitesForCurrentMapPosition();
    } else {
      Object.keys(this.sites).forEach((key) => {
        this.clearSite(this.sites[key]);
      });
      this.sites = {};
    }
  }

  public get showOuterSite(): boolean {
    return this._showOuterSite;
  }

  public set showOuterSite(show: boolean) {
    this._showOuterSite = show;
    if (show) {
      this.drawOuterSite();
    } else {
      this.hideOuterSite();
    }
  }

  public onTouched: () => void = () => {};

  constructor(private fb: FormBuilder, private siteSerivce: SitesService) {}

  ngOnInit() {
    const locationInit = {
      longitude: this.site && this.site.lngLat ? this.site.lngLat[0] : -104.9903,
      latitude: this.site && this.site.lngLat ? this.site.lngLat[1] : 39.7392,
      radius:
        this.site && this.site.radius !== null && this.site.radius !== undefined
          ? +(this.site.radius / metersInAMile).toFixed(1)
          : 1,
    };
    this.lastValidCoords = {
      ...this.lastValidCoords,
      ...locationInit,
    };
    this.locationForm = this.fb.group({
      latitude: [locationInit.latitude, [Validators.required, LatitudeValidator()]],
      longitude: [locationInit.longitude, [Validators.required, LongitudeValidator()]],
      radius: [locationInit.radius, [Validators.required, Validators.max(10), Validators.min(0.01)]],
    });
    this.initMap();
    this.subscriptions.push(
      this.radius.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(this.handleRadiusInputChanges.bind(this)),
      this.siteSerivce.sites$.subscribe(this.handleNewSites.bind(this)),
      this.mapBoundsChanged
        .asObservable()
        .pipe(debounceTime(500))
        .subscribe(this.loadSitesForCurrentMapPosition.bind(this)),
    );
    if (this.site && this.site.outerRadius) {
      this.showOuterSite = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  writeValue(val: { latitude: number; longitude: number; radius: number }): void {
    if (val) {
      this.locationForm.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.locationForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.locationForm.disable() : this.locationForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.locationForm.valid ? null : { invalidForm: { valid: false, message: 'Well details are invalid' } };
  }

  initMap() {
    if (this.map == null) {
      const center = {
        lat: this.latitude.value,
        lng: this.longitude.value,
      };
      const mapConfig = {
        center: center,
        zoom: 12,
        streetViewControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 3,
        },
        zoomControlOptions: {
          position: 3,
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        styles: <any>LightTheme,
      };

      if (this.mapElement != null) {
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
        this.fracMarker = new google.maps.Marker({
          map: this.map,
          draggable: true,
          animation: google.maps.Animation.DROP,
          position: this.map.getCenter(),
        });
        this.geofence = new google.maps.Circle({
          map: this.map,
          radius: this.radius.value * metersInAMile,
          strokeColor: '#00FF00',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#00FF00',
          fillOpacity: 0.35,
          editable: true,
          center: this.map.getCenter(),
          zIndex: 10,
        });
        this.geofence.bindTo('center', this.fracMarker, 'position');
        google.maps.event.addListener(this.fracMarker, 'position_changed', this.handleFracMarkerMove.bind(this));
        google.maps.event.addListener(this.geofence, 'radius_changed', this.handleGeofenceRadiusChange.bind(this));
        google.maps.event.addListener(this.map, 'bounds_changed', () => this.mapBoundsChanged.next(null));
      }
    }
  }

  public centerMarkerOnMap() {
    const currentMapCenter = this.map.getCenter();
    this.latitude.setValue(currentMapCenter.lat());
    this.longitude.setValue(currentMapCenter.lng());
    if (!this.isInnerCircleInsideOuterCircle()) {
      this.outerFracMarker.setPosition(currentMapCenter);
    }
  }

  public centerMapOnMarker() {
    const currentMarkerPosition = this.fracMarker.getPosition();
    this.map.setCenter(currentMarkerPosition);
  }

  public asSiteLocation(): SiteLocation {
    const value: FormValue = this.locationForm.value;
    if (!value) {
      return {} as any;
    }
    const location: SiteLocation = {
      radius: Math.round(value.radius * metersInAMile),
      lngLat: [value.longitude, value.latitude],
    };
    if (value.outerRadius) {
      location.outerRadius = Math.round(value.outerRadius * metersInAMile);
    }
    if (value.outerLatitude && value.outerLongitude) {
      location.outerLngLat = [value.outerLongitude, value.outerLatitude];
    }
    if (value.stagingRoadIsOneWay !== null && value.stagingRoadIsOneWay !== undefined) {
      location.stagingRoadIsOneWay = value.stagingRoadIsOneWay;
    }
    if (value.minutesFromStagingPadToFrac !== null && value.minutesFromStagingPadToFrac !== undefined) {
      location.minutesFromStagingPadToFrac = Math.ceil(value.minutesFromStagingPadToFrac);
    }
    return location;
  }

  private drawOuterSite() {
    const locationInit = {
      outerLongitude:
        this.site && this.site.outerLngLat ? this.site.outerLngLat[0] : this.fracMarker.getPosition().lng(),
      outerLatitude:
        this.site && this.site.outerLngLat ? this.site.outerLngLat[1] : this.fracMarker.getPosition().lat(),
      outerRadius:
        this.site && this.site.outerRadius !== null && this.site.outerRadius !== undefined
          ? +(this.site.outerRadius / metersInAMile).toFixed(1)
          : (this.geofence.getRadius() * 2) / metersInAMile,
      stagingRoadIsOneWay:
        this.site && this.site.stagingRoadIsOneWay !== null && this.site.stagingRoadIsOneWay !== undefined
          ? this.site.stagingRoadIsOneWay
          : false,
      minutesFromStagingPadToFrac:
        this.site &&
        this.site.minutesFromStagingPadToFrac !== null &&
        this.site.minutesFromStagingPadToFrac !== undefined
          ? this.site.minutesFromStagingPadToFrac
          : 10,
    };
    this.lastValidCoords = {
      ...this.lastValidCoords,
      ...locationInit,
    };
    this.locationForm.addControl(
      'outerRadius',
      this.fb.control(locationInit.outerRadius, [Validators.required, Validators.max(20), Validators.min(0.01)]),
    );
    this.locationForm.addControl(
      'outerLatitude',
      this.fb.control(locationInit.outerLatitude, [Validators.required, LatitudeValidator()]),
    );

    this.locationForm.addControl(
      'outerLongitude',
      this.fb.control(locationInit.outerLongitude, [Validators.required, LongitudeValidator()]),
    );
    this.locationForm.addControl('stagingRoadIsOneWay', this.fb.control(locationInit.stagingRoadIsOneWay));
    this.locationForm.addControl(
      'minutesFromStagingPadToFrac',
      this.fb.control(locationInit.minutesFromStagingPadToFrac),
    );

    this.outerFracMarker = new google.maps.Marker({
      icon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      },
      map: this.map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: {
        lat: this.outerLatitude.value,
        lng: this.outerLongitude.value,
      },
    });
    this.outerGeofence = new google.maps.Circle({
      map: this.map,
      radius: this.outerRadius.value * metersInAMile,
      strokeColor: '#0000FF',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#0000FF',
      fillOpacity: 0.35,
      editable: true,
      center: {
        lat: this.outerLatitude.value,
        lng: this.outerLongitude.value,
      },
      zIndex: 10,
    });
    this.outerGeofence.bindTo('center', this.outerFracMarker, 'position');
    google.maps.event.addListener(this.outerFracMarker, 'position_changed', this.handleOuterSiteMarkerMove.bind(this));
    google.maps.event.addListener(
      this.outerGeofence,
      'radius_changed',
      this.handleOuterSiteGeofenceRadiusChange.bind(this),
    );

    this.subscriptions.push(
      this.outerRadius.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(this.handleOuterRadiusInputChanges.bind(this)),
      this.outerLatitude.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(this.handleOuterLatitudeInputChange.bind(this)),
      this.outerLongitude.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(this.handleOuterLongitudeInputChange.bind(this)),
    );
  }

  private hideOuterSite() {
    this.locationForm.removeControl('outerRadius');
    this.locationForm.removeControl('outerLatitude');
    this.locationForm.removeControl('outerLongitude');
    this.locationForm.removeControl('stagingRoadIsOneWay');
    this.locationForm.removeControl('minutesFromStagingPadToFrac');
    if (this.outerFracMarker) {
      this.outerFracMarker.setMap(null);
      this.outerFracMarker = null;
    }

    if (this.outerGeofence) {
      this.outerGeofence.setMap(null);
      this.outerGeofence = null;
    }
  }

  private handleFracMarkerMove() {
    const newPosition = this.fracMarker.getPosition();
    this.latitude.setValue(newPosition.lat(), { emitEvent: false });
    this.longitude.setValue(newPosition.lng(), { emitEvent: false });
    if (!this.isInnerCircleInsideOuterCircle()) {
      this.outerLatitude.setValue(this.latitude.value);
      this.outerLongitude.setValue(this.longitude.value);
    }
  }

  private handleOuterSiteMarkerMove() {
    const newPosition = this.outerFracMarker.getPosition();
    const newOuterCircle = new google.maps.Circle({
      center: newPosition,
      radius: this.outerGeofence.getRadius(),
    });
    if (wouldInnerCircleBeInsideOuterCircle(this.geofence, newOuterCircle)) {
      this.outerLatitude.setValue(newPosition.lat(), { emitEvent: false });
      this.outerLongitude.setValue(newPosition.lng(), { emitEvent: false });
      this.outerSitePosition = newPosition;
    } else {
      setTimeout(() => {
        this.outerFracMarker.setPosition(this.outerSitePosition);
      });
    }
  }

  private handleGeofenceRadiusChange() {
    const newRadius = this.geofence.getRadius() / metersInAMile;
    if (newRadius <= 10) {
      this.radius.setValue(newRadius, { emitEvent: false });
      if (!this.isInnerCircleInsideOuterCircle()) {
        const radiusChange = newRadius - this.lastValidCoords.radius;
        const newOuterRadius = this.lastValidCoords.outerRadius + radiusChange;
        this.outerRadius.setValue(newOuterRadius);
        this.lastValidCoords = {
          ...this.lastValidCoords,
          radius: newRadius,
          outerRadius: newOuterRadius,
        };
      }
    } else {
      this.geofence.setRadius(10 * metersInAMile);
    }
  }

  private handleOuterSiteGeofenceRadiusChange() {
    const newRadius = this.outerGeofence.getRadius() / metersInAMile;
    if (newRadius <= 20) {
      this.outerRadius.setValue(newRadius, { emitEvent: false });
      if (!this.isInnerCircleInsideOuterCircle()) {
        const outerCircle = new google.maps.Circle({
          center: this.outerGeofence.getCenter(),
          radius: this.geofence.getRadius(),
        });
        while (!wouldInnerCircleBeInsideOuterCircle(this.geofence, outerCircle)) {
          // Increments of 0.05 mile until we encompass the inner circle
          outerCircle.setRadius(outerCircle.getRadius() + metersInAMile * 0.05);
        }
        this.outerGeofence.setRadius(outerCircle.getRadius());
      }
    } else {
      this.outerRadius.setValue(20 * metersInAMile);
    }
  }

  public handleRadiusInputChanges(radius: string) {
    this.geofence.setRadius(+radius * metersInAMile);
  }

  public handleLatitudeInputChange(latitude: string) {
    const currentPosition = this.fracMarker.getPosition();
    this.fracMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
    this.centerMapOnMarker();
  }

  public handleLongitudeInputChange(longitude: string) {
    const currentPosition = this.fracMarker.getPosition();
    this.fracMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
    this.centerMapOnMarker();
  }

  private handleOuterRadiusInputChanges(radius: string) {
    this.outerGeofence.setRadius(+radius * metersInAMile);
  }

  private handleOuterLatitudeInputChange(latitude: string) {
    const currentPosition = this.outerFracMarker.getPosition();
    this.outerFracMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
  }

  private handleOuterLongitudeInputChange(longitude: string) {
    const currentPosition = this.outerFracMarker.getPosition();
    this.outerFracMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
  }

  private loadSitesForCurrentMapPosition() {
    if (this.showOtherSites) {
      const bounds = this.map.getBounds();
      if (bounds) {
        const { lat: north, lng: east } = bounds.getNorthEast().toJSON();
        const { lat: south, lng: west } = bounds.getSouthWest().toJSON();
        this.siteSerivce.getSites(north, south, east, west);
      }
    }
  }

  private handleNewSites(sites: Site[]): void {
    const newSiteIdsMap: Record<number, Site> = sites.reduce((record, site) => ({ ...record, [site.id]: site }), {});
    // Remove existing sites that no longer match
    Object.keys(this.sites).forEach((id) => {
      if (!newSiteIdsMap[id]) {
        this.clearSite(this.sites[id]);
      }
    });

    // Add new sites as needed
    sites
      .filter((site) => !this.sites[site.id])
      .forEach((site) => {
        const lngLat = new google.maps.LatLng(site.lngLat[1], site.lngLat[0]);
        const circle = new google.maps.Circle({
          map: this.map,
          radius: site.radius,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          center: lngLat,
        });
        this.sites[site.id] = { circle };
        if ((site.siteTypeName = 'Frac Well')) {
          const markerImage = {
            url: '/assets/icons/Rig.svg',
            size: new google.maps.Size(30, 30),
            anchor: new google.maps.Point(7, 11),
          };
          const marker = new google.maps.Marker({
            map: this.map,
            position: lngLat,
            icon: markerImage,
          });
          this.sites[site.id].marker = marker;
        }
      });
  }

  private clearSite(site: { circle: google.maps.Circle; marker?: google.maps.Marker }) {
    if (site.circle) {
      site.circle.setMap(null);
    }
    if (site.marker) {
      site.marker.setMap(null);
    }
  }

  private isInnerCircleInsideOuterCircle(): boolean {
    // If there is no outer site, then there is nothing to check.
    if (!this.showOuterSite) {
      return true;
    }

    return wouldInnerCircleBeInsideOuterCircle(this.geofence, this.outerGeofence);
  }
}

function wouldInnerCircleBeInsideOuterCircle(inner: google.maps.Circle, outer: google.maps.Circle): boolean {
  const north = inner
    .getBounds()
    .getNorthEast()
    .lat();
  const south = inner
    .getBounds()
    .getSouthWest()
    .lat();
  const east = inner
    .getBounds()
    .getNorthEast()
    .lng();
  const west = inner
    .getBounds()
    .getSouthWest()
    .lng();
  const eastRadius = Math.abs(east - inner.getCenter().lng());
  const westRadius = Math.abs(east - inner.getCenter().lng());

  const points: google.maps.LatLngLiteral[] = [
    {
      // north
      lat: north,
      lng: inner.getCenter().lng(),
    },
    {
      // northwest
      lat: inner.getCenter().lat() + (westRadius * Math.SQRT2) / 2,
      lng: inner.getCenter().lng() - (westRadius * Math.SQRT2) / 2,
    },
    {
      // west
      lat: inner.getCenter().lat(),
      lng: west,
    },
    {
      // southwest
      lat: inner.getCenter().lat() - (westRadius * Math.SQRT2) / 2,
      lng: inner.getCenter().lng() - (westRadius * Math.SQRT2) / 2,
    },
    {
      // south
      lat: south,
      lng: inner.getCenter().lng(),
    },
    {
      // southeast
      lat: inner.getCenter().lat() - (eastRadius * Math.SQRT2) / 2,
      lng: inner.getCenter().lng() + (eastRadius * Math.SQRT2) / 2,
    },
    {
      // east
      lat: inner.getCenter().lat(),
      lng: east,
    },
    {
      // northeast
      lat: inner.getCenter().lat() - (eastRadius * Math.SQRT2) / 2,
      lng: inner.getCenter().lng() + (eastRadius * Math.SQRT2) / 2,
    },
  ];

  return points.every((point) => isInsideCircle(point, outer.getCenter().toJSON(), outer.getRadius() / 1000 /** km */));
}

// Adapted from https://stackoverflow.com/a/24680708/4937673
function isInsideCircle(point: google.maps.LatLngLiteral, center: google.maps.LatLngLiteral, radius: number): boolean {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * center.lat) / 180.0) * ky;
  const dx = Math.abs(center.lng - point.lng) * kx;
  const dy = Math.abs(center.lat - point.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= radius;
}
