<div class="back-layer" [@slideInOut]></div>

<div class="email-form-component" [@slideInOut]>
  <div class="header-wrap">
    <div class="head-details">
      <h1>
        <span>Upload File</span>
      </h1>
    </div>
    <div class="close" (click)="close()"><span>esc</span><i class="material-icons">clear</i></div>
  </div>

  <div class="content-wrap">
    <div class="loader-wrap" *ngIf="!fileForm">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <form *ngIf="fileForm" [formGroup]="fileForm" autocomplete>
      <div class="form-field">
        <label>Loader</label>

        <mat-form-field>
          <mat-select
            placeholder="{{
              (allowMultipleMines$ | async) && !fileForm.controls['loader'].value
                ? 'Loader defined in file data'
                : 'Loader'
            }}"
            [compareWith]="compareFn"
            [formControl]="fileForm.controls['loader']"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'Loader'"
                [noEntriesFoundLabel]="'No matching loader found'"
                [formControl]="filterLoader"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'" [value]="null">
              Loader defined in file data
            </mat-option>

            <mat-option *ngFor="let mine of filteredMines$ | async" [value]="mine">
              {{ mine.site.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fileForm.controls['loader'].hasError('required')">
            Input is required
          </mat-error>
          <mat-hint *ngIf="fileForm.controls['loader'].value">
            <ng-container *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'">
              Data in files sent to this email will be associated with this loader
            </ng-container>
          </mat-hint>
          <mat-hint *ngIf="!fileForm.controls['loader'].value">
            <ng-container *saFeatureFlag="'loaderIntegrationMultipleMineInSpreadsheet'">
              Loader will be determined based on data in files sent to this email
            </ng-container>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="w-full">
        <input
          class="w-full"
          type="file"
          placeholder="Upload File"
          (change)="fileChanged($event.target.files)"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
    </form>

    <div class="button-wrap">
      <sa-network-active-button class="w-full" [active]="uploading$$ | async">
        <button
          *ngIf="fileForm"
          mat-flat-button
          color="primary"
          [disabled]="
            !fileForm.controls['uploadFile'].valid || !fileForm.controls['loader'].valid || (uploading$$ | async)
          "
          (click)="onSubmit($event)"
        >
          <span>Upload</span>
        </button>
      </sa-network-active-button>
    </div>
  </div>
</div>
