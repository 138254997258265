<div class="user-detail-view-container">
  <div class="header">
    <ng-container>
      <span *ngIf="!user?.id">New Driver</span>
      <span *ngIf="user?.id">Edit Driver</span>
    </ng-container>
    <i class="material-icons" (click)="closeClicked()">close</i>
  </div>
  <div class="detail-content">
    <div class="content-header">Driver Info</div>
    <form [formGroup]="formGroup">
      <mat-form-field style="width: 100%;">
        <input matInput formControlName="name" placeholder="Name" autocomplete="off" (blur)="updateDriver('name')" />
      </mat-form-field>
      <div class="phone-input" style="margin: 0 0 16px 0;">
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'ar']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="phone"
          formControlName="phone"
          (blur)="updateDriver('phone')"
        ></ngx-mat-intl-tel-input>
      </div>
      <mat-form-field style="width: 100%;">
        <input matInput formControlName="pin" placeholder="Pin" autocomplete="off" (blur)="updateDriver('pin')" />
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <input matInput formControlName="cdl" placeholder="CDL" autocomplete="off" (blur)="updateDriver('cdl')" />
      </mat-form-field>
      <mat-form-field *saIsBrokerage style="width: 100%;">
        <mat-label>Subcarrier</mat-label>
        <mat-select formControlName="subcarrier" (blur)="updateDriver('subcarrier')">
          <mat-option *ngFor="let subcarrier of subcarrierList.value" [value]="subcarrier.id">
            {{ subcarrier.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *saIsBrokerage style="width: 100%;">
        <mat-label>Trailer Type</mat-label>
        <mat-select formControlName="trailerTypeID" (blur)="updateDriver('trailerTypeID')">
          <mat-option *ngFor="let trailerType of trailerTypes.value" [value]="trailerType.id">
            {{ trailerType.trailerTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="showAssetStatusField.value">
        <mat-form-field *saIsBrokerage style="width: 100%;">
          <mat-label>Asset Status</mat-label>
          <mat-select formControlName="assetStatus" (blur)="updateDriver('assetStatus')">
            <mat-option *ngFor="let assetStatus of assetStatuses.value" [value]="assetStatus">
              {{ assetStatus }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="showTrailersField.value">
        <mat-form-field *saIsBrokerage style="width: 100%;">
          <mat-label>Trailer Number</mat-label>
          <mat-select formControlName="trailerID" (blur)="updateDriver('trailerID')">
            <mat-select-trigger>
              <ng-container *ngIf="selectedTrailer | async as trailer">
                {{ trailer.name }}
                <span *ngIf="trailer.trailerTypeName" class="trailer-type-selected"
                  >| {{ trailer.trailerTypeName }}</span
                >
              </ng-container>
            </mat-select-trigger>

            <mat-option [value]="0">None</mat-option>
            <mat-option *ngFor="let trailer of trailers.value" [value]="trailer.id">
              <span>{{ trailer.name }}</span>
              <span class="trailer-type-option" *ngIf="trailer.trailerTypeName">| {{ trailer.trailerTypeName }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <ng-container *ngIf="user?.id">
      <div class="content-header">Danger Zone</div>
      <div class="danger-zone">
        Removing this driver will delete any scheduled or in-progress tasks assigned to this driver, and prevent them
        from receiving new tasks. This cannot be undone.
      </div>

      <button type="button" sa-danger-button (click)="removeDriver(user?.id)">
        Remove Driver
      </button>
    </ng-container>

    <ng-container *ngIf="!user?.id">
      <button type="button" [disabled]="buttonDisabled || formGroup.invalid" sa-button (click)="inviteDriver()">
        Invite Driver
      </button>
    </ng-container>
  </div>
</div>
