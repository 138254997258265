import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  debounceTime,
  filter,
  map,
  skipWhile,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageCreationComponent, StorageCreationComponentData } from '../storage-creation/storage-creation.component';
import { MineCreationComponent } from '../mine-creation/mine-creation.component';
import { CrudService } from '../../services/crud.service';
import { MineApiService } from '../../services/api/mine.api.service';
import { UserApiService } from '../../services/api/user.api.service';
import { WellApiService } from '../../services/api/well.api.service';
import { ConstantsApiService } from '../../services/api/constants.api.service';
import {
  BillingIdentificationValidator,
  EmailListValidator,
  LatitudeValidator,
  LongitudeValidator,
  ScheduleValidator,
  StorageMeshValidator,
} from '../../services/custom-validators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AccountApiService } from '../../services/api/account.api.service';
import { TruckingApiService } from 'src/app/services/api/trucking.api.service';
import { UserService } from '../../services/user.service';
import { MineSelectionComponent } from '../mine-selection/mine-selection.component';
import { ConfirmDialogComponent } from '../../ui-components/confirm-dialog/confirm-dialog.component';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { User } from '../../models/user';
import { Location } from '@angular/common';
import { ChoiceDialogComponent } from '../../ui-components/choice-dialog/choice-dialog.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { FracCreationMapComponent, SiteLocation } from '../frac-creation-map/frac-creation-map.component';
import { getFracName } from 'src/app/ui-components/pipes/frac-name.pipe';
import { trackById } from '~lmo/utilities';
import { LightTheme } from '../../map/mapStyles';
import { DriverCertificate, TrailerType } from '~v2Models/well.model';
import { Crew, CrewsService, GroupedCrew } from '../../crews.service';
import {
  StorageSupplementalMeshUpdateComponent,
  StorageSupplementalMeshUpdateData,
} from '../storage-supplemental-mesh-update/storage-supplemental-mesh-update.component';
import { FracStorage } from '~lmo/models/frac.model';
import { SettingsService } from '~billing/services/settings.service';
import { BillingSettings } from '~billing/models';

export interface UserAndUserGroup {
  id: number;
  type: string;
  name: string;
  sso: boolean;
  userIds: number[];
}

export interface TypeBasedGroup {
  type: string;
  users: UserAndUserGroup[];
}

const USER = 'User';

const GROUP = 'Group';

@Component({
  selector: 'sa-well-creation',
  templateUrl: './well-creation.component.html',
  styleUrls: ['./well-creation.component.scss'],
})
export class WellCreationComponent implements OnInit, OnDestroy {
  public crews$: Observable<GroupedCrew[]>;
  public isEligibleToArchive$: Observable<boolean>;
  map: google.maps.Map;
  mapConfig: google.maps.MapOptions;
  public isHalAccount = false;
  public isMaterialManager = false;
  private destroy$$ = new Subject();
  private isShaleAppsUser = false;
  locationResults: SiteLocation;
  mapDragEndListner: Subject<any>;
  mapDragEndSubscription: Subscription;
  wellDirectionLocationManuallyChanged = false;
  @ViewChild(FracCreationMapComponent, { static: false }) mapComponent: FracCreationMapComponent;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  public driverCertifications: DriverCertificate[];
  public trailerTypes: TrailerType[];

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private crudService: CrudService,
    private accountApiService: AccountApiService,
    private wellApiService: WellApiService,
    private mineApiService: MineApiService,
    private userApiService: UserApiService,
    private truckingApiService: TruckingApiService,
    private constantsApiService: ConstantsApiService,
    private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private userService: UserService,
    private location: Location,
    private localStorageService: LocalStorageService,
    private errorHandler: ErrorHandlingService,
    private crewService: CrewsService,
  ) {
    this.isShaleAppsAccount = userService.isShaleAccount();
    this.isLMO = userService.isLMOAccount();
    this.canEdit = userService.canEditWell();
    this.isShaleAppsUser = userService.isShaleappsEmail() || userService.isVortoUser();
    this.isStandardUser = userService.isStandardUser();

    const id = this.route.snapshot.params['id'];
    this.firstPageUrl = '/wells/edit/' + id;
    this.secondPageUrl = '/wells/edit/' + id + '?page=frac';
    this.thirdPageUrl = '/wells/edit/' + id + '?page=mine';
    this.isHalAccount = this.userService.isHalAccount();
    this.mapDragEndListner = new Subject<any>();
  }

  wellId;
  well;
  wellCreationForm: FormGroup;
  wellScheduleForm: FormGroup;
  wellContractsForm: FormGroup;
  displayFracCreation = true;
  displayFracSchedule = false;
  displayFracContracts = false;
  formLoading = false;
  formSubmitted = false;
  submitting = false;
  stages: any[];
  vendors: any[];
  billingSettings: BillingSettings;
  storages: any[];
  storageGroups: any[];
  mines: any[];
  meshTypes: any[];
  loggedUserId: number;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  totalVolumesByMesh;
  totalVolume: number;
  isShaleAppsAccount: boolean;
  isStandardUser: boolean;
  filterUser: FormControl = new FormControl();
  lineupFilterUser: FormControl = new FormControl();
  fracDownFilterUser: FormControl = new FormControl();
  stageUpdateFilterUser: FormControl = new FormControl();
  filteredUserOptions: Observable<TypeBasedGroup[]>;
  lineupFilteredUserOptions: Observable<any[]>;
  fracDownFilteredUserOptions: Observable<any[]>;
  stageUpdateFilterOptions: Observable<any[]>;
  isLMO: boolean;
  canEdit: boolean;
  canEditMines: boolean;
  currentState = 1;
  firstPageUrl: string;
  secondPageUrl: string;
  thirdPageUrl: string;
  selectedIssues: any[] = [];
  visibility = true;

  // this is used for fracUsers
  users: UserAndUserGroup[] = [];

  // this is used for other options
  allUsers: User[];

  private allUsersLoaded$$ = new BehaviorSubject<boolean>(false);
  private userGroupsLoaded$$ = new BehaviorSubject<boolean>(false);

  firstChange = true;
  firstPageSubmit = false;
  downloadProgress = false;
  districtList: { id: number; name: string }[] = [];

  shape: any;

  public trackById = trackById;

  schedules: any[] = [
    { key: 'twice_a_day', value: 'Twice a Day - 6am and 6pm' },
    { key: 'daily', value: 'Daily' },
    { key: 'weekly', value: 'Weekly' },
  ];
  defaultSchedule = 'twice_a_day';
  centerLat: number;
  centerLng: number;
  displayedMarkers = [];
  displayedShapes = [];
  pendingOrders = [];
  latLngManuallyChanged = false;
  copyNRowsBelowEdit = false;
  copyNRowsAboveEdit = false;

  saveData = (function() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function(data, fileName) {
      const blob = new Blob([data.Data], { type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    };
  })();

  calculateStateByUrl(url: string): number {
    if (url.indexOf(this.thirdPageUrl) === 0) {
      return 3;
    } else if (url.indexOf(this.secondPageUrl) === 0) {
      return 2;
    }
    return 1;
  }

  @HostListener('document:keydown', ['$event'])
  preventGoingBack(evt) {
    if (evt.which === 8 && evt.target.nodeName !== 'INPUT' && evt.target.nodeName !== 'TEXTAREA') {
      evt.preventDefault();
    }
  }

  initMap() {
    setTimeout(() => {
      if (this.map == null) {
        let center = { lat: 39.7392, lng: -104.9903 };
        if (this.well != null && this.well.gpsDirectionLocation) {
          center = { lat: +this.well.gpsDirectionLocation[1], lng: +this.well.gpsDirectionLocation[0] };
        }
        this.mapConfig = {
          center: center,
          zoom: 12,
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: 3,
          },
          zoomControlOptions: {
            position: 3,
          },
          mapTypeId: google.maps.MapTypeId.HYBRID,
          styles: <any>LightTheme,
        };

        const element = document.getElementById('map-gps-direction-location');
        if (element != null) {
          this.map = new google.maps.Map(element, this.mapConfig);
          this.mapDragEndListner.next(2);
          google.maps.event.addListener(this.map, 'center_changed', () => {
            this.mapDragEndListner.next(2);
          });
        }
      }
    }, 500);
  }

  ngOnInit() {
    this.crews$ = this.crewService.crews$;
    this.isMaterialManager = this.userService.hasPermission('material_manager');
    if (this.calculateStateByUrl(this.location.path()) > 1) {
      this.location.go(this.firstPageUrl);
    }

    this.location.subscribe((event) => {
      const newState = this.calculateStateByUrl(event.url);
      if (newState === 2 && this.currentState === 1) {
        this.validateFracInput(false);
      } else if (newState === 1 && this.currentState === 2) {
        this.moveBackFromFracSchedule();
      } else if (newState === 3 && this.currentState === 2) {
        this.validateScheduleInput(false);
      } else if (newState === 2 && this.currentState === 3) {
        this.moveBackFromContractsSchedule();
      } else if (newState === 3 && this.currentState === 1) {
        if (this.validateFracInput(false)) {
          this.validateScheduleInput(false);
        }
      } else if (newState === 1 && this.currentState === 3) {
        this.moveBackFromContractsSchedule();
        this.moveBackFromFracSchedule();
      }
    });

    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(async (ready) => {
      if (ready) {
        if (this.isStandardUser) {
          this.isEligibleToArchive$ = this.wellApiService.getArchiveEligibility(this.route.snapshot.params['id']);
        }

        this.settingService.loadSettings(this.userService.accountId()).subscribe((settings) => {
          this.billingSettings = settings;
        });

        this.accountApiService.getTruckingVendorAccounts().subscribe((vendors) => {
          this.vendors = vendors;
        });

        this.wellApiService.getDriverCertifications().subscribe((certifications) => {
          this.driverCertifications = certifications;
        });

        this.wellApiService.getTrailerTypes().subscribe((trailerTypes) => {
          this.trailerTypes = trailerTypes;
        });

        this.mineApiService.getMines().subscribe((mines) => {
          if (mines) {
            this.mines = mines.sort(function(a, b) {
              const nameA = a.site.name.toUpperCase();
              const nameB = b.site.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          } else {
            this.mines = [];
          }
          this.canEditMines = this.getEditableMines().length > 0;
        });

        this.constantsApiService.getMeshTypes().subscribe((meshTypes) => {
          this.meshTypes = meshTypes;
          this.getStoragesByMesh(this.storages);
        });

        this.userApiService.getUsersGroup().subscribe((groups) => {
          if (groups && groups.length > 0) {
            const mappedGroups = groups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((group) => {
                return <UserAndUserGroup>{
                  id: group.id,
                  name: group.name,
                  type: GROUP,
                  userIds: group.users.map((user) => user.id),
                };
              });
            this.users.push(...mappedGroups);
            this.userGroupsLoaded$$.next(true);
          }
        });

        this.userApiService.getWebUsers().subscribe((data) => {
          this.allUsers = data.sort((a, b) => a.name.localeCompare(b.name));
          const mappedUsers = data
            .filter((user) => user.accountRoles[0].id !== 1)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((user) => {
              return <UserAndUserGroup>{
                id: user.id,
                name: user.name,
                type: USER,
                sso: user.ssoEnabled,
              };
            });
          this.users.push(...mappedUsers);
          this.addAllowedUserListener();
          this.allUsersLoaded$$.next(true);
        });

        this.wellApiService
          .getDistricts()
          .pipe(filter((districts) => !!districts))
          .subscribe((data) => {
            this.districtList = [...data];
            this.addDistrictValidators();
          });

        if (this.route.snapshot.params['id'] && this.route.snapshot.params['id'] !== 'new') {
          this.wellApiService.getWell(this.route.snapshot.params['id']).subscribe((storages) => {
            this.storages = storages.fracStorages;
            this.getStoragesByMesh(this.storages);
          });

          this.wellApiService.getFrac(this.route.snapshot.params['id']).subscribe((orders) => {
            this.pendingOrders = orders.filter(
              (order) =>
                order.orderStatus === 'pending' ||
                order.orderStatus === 'driver_accepted' ||
                order.orderStatus === 'dispatched',
            );
          });

          this.route.paramMap
            .pipe(
              switchMap((params: ParamMap) => {
                this.wellId = +params.get('id');

                if (this.wellId > 0) {
                  this.formLoading = true;
                  return this.wellApiService.getWell(this.wellId);
                } else {
                  return;
                }
              }),
            )
            .subscribe(async (well) => {
              this.well = well;
              await this.createWellForm();
              this.formLoading = false;
              if (well.fracStages) {
                this.createScheduleForm(this.sortStages(well.fracStages));
              }
              if (well.mineContracts) {
                this.createContractsForm(well.mineContracts);
              } else {
                this.createContractsForm();
              }
            });
        } else {
          await this.createWellForm();
        }
      }
    });

    this.mapDragEndSubscription = this.mapDragEndListner
      .asObservable()
      .pipe(debounceTime(200))
      .subscribe(() => this.changeLatLng());
    this.loggedUserId = this.userService.getUserContractFromCache().account.id;
  }

  changeLatLng() {
    if (!this.wellDirectionLocationManuallyChanged) {
      this.wellCreationForm.controls['gpsDirectionLocationLat'].setValue(this.map.getCenter().lat());
      this.wellCreationForm.controls['gpsDirectionLocationLng'].setValue(this.map.getCenter().lng());
    }
    this.wellDirectionLocationManuallyChanged = false;
  }

  ngOnDestroy() {
    this.mapDragEndSubscription.unsubscribe();
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  isLMOCrewLeader() {
    return this.userService.isLMOAccount() && this.userService.isCrewLeader();
  }

  sortStages(fracSchedule) {
    return fracSchedule.sort(compare);

    function compare(stage1, stage2) {
      if (stage1.stageNumber < stage2.stageNumber) {
        return -1;
      }

      if (stage1.stageNumber > stage2.stageNumber) {
        return 1;
      }

      return 0;
    }
  }

  onWellChanges() {
    this.wellCreationForm
      .get('storages')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        if (this.wellCreationForm.controls['numOfStages'].value) {
          this.createScheduleForm();
        }

        this.createContractsForm();

        if (this.well && this.well.fracStorages) {
          this.well.fracStorages.forEach((storage) => {
            this.wellCreationForm.removeControl(storage.id + '_' + storage.mesh.type + ' loadWeight');
          });
        }
      });

    this.wellCreationForm
      .get('mines')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.createContractsForm();
      });

    this.wellCreationForm.get('prefillDate').valueChanges.subscribe(() => {
      this.dateChanged();
    });

    this.wellCreationForm.get('fracDate').valueChanges.subscribe(() => {
      this.dateChanged();
    });

    this.wellCreationForm
      .get('gpsDirectionLocationLat')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.wellDirectionLocationManuallyChanged = true;
        this.setMapPosition();
      });

    this.wellCreationForm
      .get('gpsDirectionLocationLng')
      .valueChanges.pipe(debounceTime(400))
      .subscribe(() => {
        this.wellDirectionLocationManuallyChanged = true;
        this.setMapPosition();
      });
  }

  setMapPosition() {
    if (
      this.map &&
      this.wellCreationForm.get('gpsDirectionLocationLat').value &&
      this.wellCreationForm.get('gpsDirectionLocationLng').value
    ) {
      if (
        this.centerLat !== this.wellCreationForm.get('gpsDirectionLocationLat').value ||
        this.centerLng !== this.wellCreationForm.get('gpsDirectionLocationLng').value
      ) {
        this.map.setCenter({
          lat: +this.wellCreationForm.get('gpsDirectionLocationLat').value,
          lng: +this.wellCreationForm.get('gpsDirectionLocationLng').value,
        });
        this.firstChange = true;
      }
    }
  }

  onStageChange() {
    if (this.wellCreationForm.controls['storages'].value) {
      this.createScheduleForm();
      this.createContractsForm();
    }
  }

  onMineChange() {
    this.wellCreationForm.get('mines').valueChanges.subscribe(() => {
      if (this.wellCreationForm.controls['mines'].value) {
        this.wellCreationForm.controls['mines'].value.forEach((mine) => {
          this.wellCreationForm.addControl(
            'mine_' + mine.id + '_direction',
            this.fb.control(mine.direction ? mine.direction : '', []),
          );
          this.wellCreationForm.addControl(
            'mine_' + mine.id + '_mileage',
            this.fb.control(mine.loadMileage ? mine.loadMileage : null, [Validators.min(0)]),
          );
        });
      }
    });
  }

  onScheduleChanges() {
    this.wellScheduleForm.valueChanges.pipe(debounceTime(400)).subscribe(() => {
      this.calculateTotal();
    });
  }

  onContractsChanges() {}

  calculateTotal() {
    this.totalVolumesByMesh = {
      totalVolume: 0,
    };

    this.wellCreationForm.controls['storages'].value.forEach((storage) => {
      this.totalVolumesByMesh[storage.mesh.type] = 0;
    });

    for (let i = 0; i < this.wellCreationForm.controls['numOfStages'].value; i++) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.totalVolumesByMesh[storage.mesh.type] += this.wellScheduleForm.get(i + ' ' + storage.mesh.type).value;
        this.totalVolumesByMesh['totalVolume'] += this.wellScheduleForm.get(i + ' ' + storage.mesh.type).value;
      });
    }
  }

  getEditableMines() {
    let mines = this.mines;
    if (this.isLMO && mines && mines.length > 0) {
      mines = mines.filter((mine) => {
        return mine.site.ownerId === this.userService.accountId();
      });
    }
    return mines;
  }

  openMineSelectionForm() {
    const mines = this.getEditableMines();
    const dialogRef = this.dialog.open(MineSelectionComponent, {
      width: '50%',
      maxWidth: '968px',
      data: mines,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        for (let i = 0; i < this.mines.length; i++) {
          if (this.mines[i].id === result) {
            this.openMineForm(this.mines[i]);
          }
        }
      }
    });
  }

  openStorageForm(storage) {
    const createdByAPI = !!this.well && !!this.well.createdByAPI;
    const data: StorageCreationComponentData = {
      createdByAPI,
      storage,
      constraint: this.getStorageConstraint(),
    };
    const dialogRef = this.dialog.open(StorageCreationComponent, {
      width: '80%',
      maxWidth: '968px',
      data,
    });

    function updateStorages(storages: any[], result) {
      for (let i = 0; i < storages.length; i++) {
        if (storages[i].id === result.id) {
          storages[i] = result;
        }
      }
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (storage != null) {
          updateStorages(this.storages, result);
          updateStorages(this.wellCreationForm.get('storages').value, result);
        } else {
          this.wellCreationForm.get('storages').setValue([...this.wellCreationForm.get('storages').value, result]);
        }

        this.getStoragesByMesh(this.storages);

        if (this.wellCreationForm.controls['numOfStages'].value) {
          this.createScheduleForm();
        }

        this.createContractsForm();
      }
    });
  }

  openStorageSupplementalMeshForm(storage) {
    const data: StorageSupplementalMeshUpdateData = {
      storage,
    };

    const dialogRef = this.dialog.open(StorageSupplementalMeshUpdateComponent, {
      width: '80%',
      maxWidth: '968px',
      data,
    });

    function updateStorages(storages: any[], result) {
      for (let i = 0; i < storages.length; i++) {
        if (storages[i].id === result.id) {
          storages[i] = result;
        }
      }
    }

    dialogRef.afterClosed().subscribe((result: { storages: FracStorage[] }) => {
      if (result) {
        const resultStorage = result.storages?.find((s) => s.id === storage.id);
        if (resultStorage) {
          updateStorages(this.storages, resultStorage);
          updateStorages(this.wellCreationForm.get('storages').value, resultStorage);
        }
      }
    });
  }

  getStorageConstraint() {
    if (this.storages && this.storages.length) {
      return this.storages[0].storageType;
    }
    const formStorages = this.wellCreationForm.get('storages').value;
    if (formStorages && formStorages.length) {
      return formStorages[0].storageType;
    }
    return null;
  }

  removeStorage(index) {
    const value = this.wellCreationForm.get('storages').value;
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '30%',
      maxWidth: '968px',
      data: {
        context: `Delete Stage Pumped Volumes for ${value[index].mesh.type} Mesh?`,
        desc: `Removing this storage unit will also delete any pumped volumes entered for ${value[index].mesh.type} Mesh. This cannot be undone.`,
        button: ['Cancel', 'Remove and Delete Pumped Volumes'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        value.splice(index, 1);
        this.wellCreationForm.get('storages').setValue(value);
      }
    });
  }

  openMineForm(mine) {
    const dialogRef = this.dialog.open(MineCreationComponent, {
      width: '80%',
      maxWidth: '968px',
      data: mine,
    });

    function updateMines(mines: any[], result) {
      for (let i = 0; i < mines.length; i++) {
        if (mines[i].id === result.id) {
          mines[i] = result;
        }
      }
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (mine != null) {
          updateMines(this.mines, result);
          updateMines(this.wellCreationForm.get('mines').value, result);
        } else {
          const currentValue = this.wellCreationForm.get('mines').value;

          this.mines.push(result);
          this.wellCreationForm.get('mines').setValue([...currentValue, result]);
        }
      }
    });
  }

  async createWellForm() {
    await this.allUsersLoaded$$
      .asObservable()
      .pipe(
        skipWhile((loaded) => !loaded),
        take(1),
      )
      .toPromise();

    const allowedUsers = [];
    if (this.well) {
      if (this.well.allowedUsers) {
        allowedUsers.push(
          ...this.well.allowedUsers
            .filter((user) => {
              const filteredUser = this.allUsers?.find((allUser) => allUser.id === user.id);
              return (
                filteredUser != null &&
                filteredUser.accountRoles != null &&
                filteredUser.accountRoles.length > 0 &&
                filteredUser.accountRoles[0].id !== 1
              );
            })
            .map((user) => {
              return <UserAndUserGroup>{
                id: user.id,
                name: user.name,
                type: USER,
                sso: user.ssoEnabled,
              };
            }),
        );
      }
      if (this.well.selectedGroups) {
        allowedUsers.push(
          ...this.well.selectedGroups.map((group) => {
            return <UserAndUserGroup>{
              id: group.id,
              name: group.name,
              type: GROUP,
            };
          }),
        );
      }
    }

    this.wellCreationForm = this.fb.group(
      {
        name: [(this.well ? this.well.site.name : null) as string, [Validators.required]],
        entranceSpeed: [(this.well ? this.well.site.entranceSpeed : null) as number],
        crewName: [(this.well ? this.well.crewName : null) as string, []],
        crewId: [this.well && this.well.crew ? this.well.crew.id : null],
        billingIdentifier: [(this.well ? this.well.billingIdentifier : null) as string],
        prefillDate: [(this.well ? this.well.prefillDate : null) as string, [Validators.required]],
        fracDate: [(this.well ? this.well.fracDate : null) as string, [Validators.required]],
        numOfStages: [(this.well ? this.well.totalStages : null) as number, [Validators.required, Validators.min(1)]],
        maxTrucks: [(this.well ? this.well.truckQuantity : 1) as number, [Validators.required, Validators.min(1)]],
        storages: [
          (this.well ? this.well.fracStorages.sort((a, b) => (a.name < b.name ? -1 : 1)) : []) as any[],
          [Validators.required, StorageMeshValidator()],
        ],
        mines: [(this.well ? this.well.fracMines : []) as any[], []],
        emails: [(this.well ? this.well.notifyEmails : null) as string, [EmailListValidator()]],
        schedule: [
          (this.well ? this.well.schedule : this.defaultSchedule) as string,
          [ScheduleValidator('emails', 'schedule')],
        ],
        direction: [(this.well ? this.well.direction : null) as string, []],
        gpsDirectionLocationLat: [
          this.well && this.well.gpsDirectionLocation ? this.well.gpsDirectionLocation[1] : 51,
          [Validators.required, LatitudeValidator()],
        ],
        gpsDirectionLocationLng: [
          this.well && this.well.gpsDirectionLocation ? this.well.gpsDirectionLocation[0] : 100,
          [Validators.required, LongitudeValidator()],
        ],
        allowedUsers: [allowedUsers as UserAndUserGroup[], []],
        driverCertifications: [this.well && this.well.ffDriverCertifications ? this.well.ffDriverCertifications : []],
        trailerTypes: [this.well && this.well.trailerTypes ? this.well.trailerTypes : []],
        lineUpUsers: [
          (this.well && this.well.smsUsers && this.well.smsUsers['lineUp']
            ? this.well.smsUsers['lineUp']
            : []) as User[],
          [],
        ],
        fracDownUsers: [
          (this.well && this.well.smsUsers && this.well.smsUsers['fracDown']
            ? this.well.smsUsers['fracDown']
            : []) as User[],
          [],
        ],
        stageUpdateReminderUsers: [
          (this.well && this.well.smsUsers && this.well.smsUsers['stage_update_reminder']
            ? this.well.smsUsers['stage_update_reminder']
            : []) as User[],
          [],
        ],
        vendorExport: [true as boolean, []],
        jobName: [this.well ? this.well.jobName : null, [Validators.required]],
        customJobId: [(this.well ? this.well.customJobId : null) as string, []],
        autoStorageUpdate: [this.well ? this.well.autoStorageUpdate || false : false],
        boxMove: [this.well ? this.well.boxMove || false : false],
        district: [
          this.well && this.well.districtId && this.well.districtName
            ? { id: this.well.districtId, name: this.well.districtName }
            : null,
          [],
        ],
        hseRequirements: [(this.well ? this.well.hseRequirements : null) as string, []],
        percentBasedAutoOrdering: [this.well ? this.well.percentBasedAutoOrdering : null, []],
        enableFuelSurcharge: [this.well ? this.well.enableFuelSurcharge : (false as boolean), []],
      },
      {
        validator: [ScheduleValidator('emails', 'schedule')],
      },
    );

    if (this.isStandardUser) {
      this.wellCreationForm.disable();
    }

    this.initMap();

    if (this.isHalAccount) {
      const biFormControl = this.wellCreationForm.get('billingIdentifier');
      if (this.isShaleAppsUser) {
        biFormControl.setValidators([Validators.required, BillingIdentificationValidator()]);
      } else {
        biFormControl.setValidators([Validators.required]);
      }
    }
    this.addDistrictValidators();
    const { clusterId } = this.route.snapshot.queryParams;
    if (clusterId) {
      this.wellCreationForm.addControl('clusterId', this.fb.control(clusterId));
    }

    this.onWellChanges();
    this.onMineChange();
    this.jobNameListener();
    this.crewListener();
  }

  private addDistrictValidators() {
    if (this.districtList.length) {
      const districtControl = this.wellCreationForm?.get('district');
      if (districtControl) {
        districtControl.setValidators([Validators.required]);
      }
    }
  }

  private addAllowedUserListener() {
    this.filteredUserOptions = this.filterUser.valueChanges.pipe(
      startWith<string | any>(''),
      // map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.users)),
      map((userAndUserGroups) => {
        const group = <TypeBasedGroup>{ type: GROUP, users: [] };
        const user = <TypeBasedGroup>{ type: USER, users: [] };
        userAndUserGroups.forEach((value) => {
          if (value.type === GROUP) {
            group.users.push(value);
          } else {
            user.users.push(value);
          }
        });
        const groups: TypeBasedGroup[] = [];
        if (group.users.length > 0) {
          groups.push(group);
        }
        groups.push(user);
        return groups;
      }),
    );

    this.lineupFilteredUserOptions = combineLatest([
      this.lineupFilterUser.valueChanges.pipe(startWith<string | any>('')),
      this.allUsersLoaded$$,
    ]).pipe(map(([name, loaded]) => (name ? this._filterAll(name) : this.allUsers)));

    this.fracDownFilteredUserOptions = combineLatest([
      this.fracDownFilterUser.valueChanges.pipe(startWith<string | any>('')),
      this.allUsersLoaded$$,
    ]).pipe(map(([name, loaded]) => (name ? this._filterAll(name) : this.allUsers)));

    this.stageUpdateFilterOptions = combineLatest([
      this.stageUpdateFilterUser.valueChanges.pipe(startWith<string | any>('')),
      this.allUsersLoaded$$,
    ]).pipe(map(([name, loaded]) => (name ? this._filterAll(name) : this.allUsers)));
  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.users.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAll(value) {
    const filterValue = value.toLowerCase();
    return this.allUsers.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  setStartTime() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0);
    return startTime;
  }

  getOldValueFromKey(oldForm, key) {
    return oldForm ? (oldForm.controls[key] ? oldForm.controls[key].value : null) : null;
  }

  createScheduleForm(stages?) {
    const oldForm = this.wellScheduleForm;
    this.wellScheduleForm = this.fb.group({});

    if (this.isStandardUser) {
      this.wellScheduleForm.disable();
    }

    this.stages = new Array(this.wellCreationForm.controls['numOfStages'].value);

    for (let i = 0; i < this.wellCreationForm.controls['numOfStages'].value; i++) {
      this.wellScheduleForm.addControl(
        i + ' pumpTime',
        this.fb.control(stages ? stages[i].pumpTime : this.getOldValueFromKey(oldForm, i + ' pumpTime'), [
          Validators.required,
          Validators.min(1),
        ]),
      );
      this.wellScheduleForm.addControl(
        i + ' downTime',
        this.fb.control(stages ? stages[i].downTime : this.getOldValueFromKey(oldForm, i + ' downTime'), [
          Validators.required,
          Validators.min(0),
        ]),
      );

      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.addControl(
          i + ' ' + storage.mesh.type,
          this.fb.control(
            stages
              ? this.getMeshVolumeForStage(storage.mesh, stages[i].meshes)
              : this.getOldValueFromKey(oldForm, i + ' ' + storage.mesh.type),
            [Validators.required, Validators.min(0), Validators.max(100000000)],
          ),
        );
      });
    }

    this.calculateTotal();
    this.onScheduleChanges();
  }

  createContractsForm(contracts?) {
    const oldForm = this.wellContractsForm;
    this.wellContractsForm = this.fb.group({});

    if (this.isStandardUser) {
      this.wellContractsForm.disable();
    }

    if (this.wellCreationForm.controls['mines'].value) {
      this.wellCreationForm.controls['mines'].value.forEach((mine, i) => {
        this.wellCreationForm.controls['storages'].value.forEach((storage) => {
          this.wellContractsForm.addControl(
            mine.id + ' ' + storage.mesh.type,
            this.fb.control(
              contracts
                ? this.getContractTotalForMine(mine.id, storage.mesh.id)
                : this.getOldValueFromKey(oldForm, mine.id + ' ' + storage.mesh.type),
              [Validators.required, Validators.min(0), Validators.max(1000000)],
            ),
          );
          this.wellContractsForm.addControl(
            mine.id + ' ' + storage.mesh.type + '_totalCost',
            this.fb.control(
              contracts
                ? this.getCostPerTonForMine(mine.id, storage.mesh.id)
                : this.getOldValueFromKey(oldForm, mine.id + ' ' + storage.mesh.type + '_totalCost'),
              [Validators.required, Validators.min(0), Validators.max(1000000)],
            ),
          );
          this.wellContractsForm.addControl(
            mine.id + ' ' + storage.mesh.type + '_loaderPreference',
            this.fb.control(
              contracts
                ? this.getLoaderPreferenceForMine(mine.id, storage.mesh.id)
                : this.getOldValueFromKey(oldForm, mine.id + ' ' + storage.mesh.type + '_loaderPreference'),
              [Validators.min(0), Validators.max(this.wellCreationForm.controls['mines'].value.length)],
            ),
          );
        });
      });
    }

    this.onContractsChanges();
  }

  getStoragesByMesh(storages) {
    if (storages && this.meshTypes) {
      this.storageGroups = [];

      this.meshTypes.forEach((meshType) => {
        const storagesForMesh = storages.filter((storage) => {
          return storage.mesh.type === meshType.type;
        });

        this.storageGroups.push({
          name: meshType.type,
          storages: storagesForMesh,
        });
      });
    }
  }

  getMeshVolumeForStage(meshType, meshes) {
    return (
      meshes?.find((mesh) => {
        return mesh.id === meshType.id;
      }) || { volume: 0 }
    ).volume;
  }

  getContractTotalForMine(mineId, meshId) {
    const contract = this.well.mineContracts?.find((c) => {
      return c.mineId === mineId && c.meshId === meshId;
    });
    if (contract) {
      return contract.total / 2000;
    } else {
      return 0;
    }
  }

  getCostPerTonForMine(mineId, meshId) {
    const contract = this.well.mineContracts?.find((c) => {
      return c.mineId === mineId && c.meshId === meshId;
    });
    if (contract) {
      return contract.costPerTon;
    } else {
      return 0;
    }
  }

  getLoaderPreferenceForMine(mineId, meshId) {
    const contract = this.well.mineContracts?.find((c) => {
      return c.mineId === mineId && c.meshId === meshId;
    });
    if (contract) {
      return contract.preferredOrder;
    } else {
      return 0;
    }
  }

  closeForm(wellId?: number) {
    if (wellId) {
      this.router.navigate(['/', 'lmo', 'frac', wellId, 'home']);
    } else {
      this.router.navigate(['/', 'lmo', 'frac', 'list']);
    }
  }

  backFromFracCreation() {
    this.closeForm();
  }

  dateChanged = () => {
    this.wellCreationForm.controls['fracDate'].setErrors(null);
  };

  backFromFracSchedule() {
    history.back();
  }

  moveBackFromFracSchedule() {
    this.currentState = 1;
    this.displayFracCreation = true;
    this.firstChange = true;
    this.displayFracSchedule = false;
    this.displayFracContracts = false;
  }

  backFromContractsSchedule() {
    history.back();
  }

  moveBackFromContractsSchedule() {
    this.currentState = 2;
    this.displayFracSchedule = true;
    this.visibility = false;
  }

  validateFracInput(changeLocation: boolean): boolean {
    this.firstPageSubmit = true;
    const oldDateControl = this.wellCreationForm.controls['prefillDate'];
    const newDateControl = this.wellCreationForm.controls['fracDate'];

    if (!oldDateControl || !newDateControl) {
      return false;
    }

    if (!oldDateControl.value) {
      return false;
    }

    new Date(oldDateControl.value) <= new Date(newDateControl.value)
      ? newDateControl.setErrors(null)
      : newDateControl.setErrors({ older: true });
    if (this.wellCreationForm.controls['storages'].invalid) {
      if (this.wellCreationForm.controls['storages'].getError('storageMesh')) {
        this.errorHandler.showError('Multiple storage of same Mesh are not allowed.', 5000);
        return false;
      } else {
        this.errorHandler.showError('Please have atleast 1 storage', 5000);
        return false;
      }
    } else if (this.wellCreationForm.valid && new Date(oldDateControl.value) <= new Date(newDateControl.value)) {
      this.displayFracCreation = false;
      this.displayFracSchedule = true;
      // For whatever reason, I can't get the custom reactive forms to work on this form, this is a stop-gap until I figure out why.
      this.locationResults = this.mapComponent.asSiteLocation();
      if (changeLocation) {
        this.location.go(this.secondPageUrl);
      }
      this.currentState = 2;
      return true;
    } else {
      this.errorHandler.showError('Please update all fields.', 5000);
      return false;
    }
  }

  confirmScheduleInput(changeLocation: boolean): boolean {
    this.selectedIssues = [];
    let returnValue: boolean;
    let loadValue = 0;
    let errorCounter = false;
    for (let index = 0; index < this.stages.length; index++) {
      const issue = {
        name: `Stage ${index + 1}`,
        fields: [],
      };
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        errorCounter = false;
        loadValue = this.wellScheduleForm.controls[index + ' ' + storage.mesh.type].value;
        if (loadValue < 10000 || loadValue > 10000000) {
          issue.fields.push(`${storage.mesh.type} Mesh Volumne:  <span class="error-text">${loadValue} lbs</span>`);
          errorCounter = true;
        } else {
          issue.fields.push(`${storage.mesh.type} Mesh Volumne:  ${loadValue} lbs`);
        }
      });
      loadValue = this.wellScheduleForm.controls[index + ' pumpTime'].value;
      if (loadValue < 15 || loadValue > 300) {
        issue.fields.push(`Pump Time:  <span class="error-text">${loadValue} minutes</strong>`);
        errorCounter = true;
      } else {
        issue.fields.push(`Pump Time:  ${loadValue} minutes`);
      }

      loadValue = this.wellScheduleForm.controls[index + ' downTime'].value;
      if (loadValue < 0 || loadValue > 300) {
        issue.fields.push(`Time Between Stages: <span class="error-text">${loadValue} minutes</span>`);
        errorCounter = true;
      } else {
        issue.fields.push(`Time Between Stages: ${loadValue} minutes`);
      }
      if (errorCounter) {
        this.selectedIssues.push(issue);
      }
    }
    if (this.selectedIssues.length) {
      const scheduleConfirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '50%',
        maxWidth: '968px',
        data: this.selectedIssues,
      });
      scheduleConfirmDialog.afterClosed().subscribe((result) => {
        returnValue = result;
        if (returnValue) {
          this.formSubmitted = false;
          this.submit();
        }
      });
    } else {
      this.formSubmitted = false;
      this.submit();
      returnValue = true;
    }

    return returnValue;
  }

  validateScheduleInput(changeLocation: boolean): boolean {
    this.formSubmitted = true;
    if (this.wellScheduleForm.valid) {
      return this.confirmScheduleInput(changeLocation);
    } else {
      this.errorHandler.showError('Please update all fields.', 5000);
      return false;
    }
  }

  copyIntoPreviousRow(i) {
    this.wellCreationForm.controls['storages'].value.forEach((storage) => {
      this.wellScheduleForm.controls[i - 1 + ' ' + storage.mesh.type].patchValue(
        this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
      );
    });

    this.wellScheduleForm.controls[i - 1 + ' pumpTime'].patchValue(
      this.wellScheduleForm.controls[i + ' pumpTime'].value,
    );
    this.wellScheduleForm.controls[i - 1 + ' downTime'].patchValue(
      this.wellScheduleForm.controls[i + ' downTime'].value,
    );
  }

  copyIntoAllPreviousRows(i) {
    for (let j = 0; j < i; j++) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
  }

  copyIntoNextRow(i) {
    this.wellCreationForm.controls['storages'].value.forEach((storage) => {
      this.wellScheduleForm.controls[i + 1 + ' ' + storage.mesh.type].patchValue(
        this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
      );
    });

    this.wellScheduleForm.controls[i + 1 + ' pumpTime'].patchValue(
      this.wellScheduleForm.controls[i + ' pumpTime'].value,
    );
    this.wellScheduleForm.controls[i + 1 + ' downTime'].patchValue(
      this.wellScheduleForm.controls[i + ' downTime'].value,
    );
  }

  copyIntoAllNextRows(i) {
    for (let j = i + 1; j < this.stages.length; j++) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
  }

  copyIntoEveryOtherRows(i) {
    for (let j = i; j < this.stages.length; j = j + 2) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
  }

  copyPumpTimeIntoAllRowsBelow(i) {
    for (let j = i; j < this.stages.length; j++) {
      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
    }
  }

  copyDownTimeIntoAllRowsBelow(i) {
    for (let j = i; j < this.stages.length; j++) {
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
  }

  copyIntoNRowsBelow(event) {
    event.stopPropagation();
    this.copyNRowsBelowEdit = true;
  }

  copyIntoNRowsAbove(event) {
    event.stopPropagation();
    this.copyNRowsAboveEdit = true;
  }

  enterCopyNRowsBelow(n: string, i: number) {
    const num = Number(n);
    for (let j = i; j < this.stages.length; j = j + num) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
    this.copyNRowsBelowEdit = false;
    this.trigger.closeMenu();
  }

  enterCopyNRowsAbove(n: string, i: number) {
    const num = Number(n);
    for (let j = i; j >= 0; j = j - num) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
    this.copyNRowsAboveEdit = false;
    this.trigger.closeMenu();
  }

  copyIntoAllRows(i) {
    for (let j = 0; j < this.stages.length; j++) {
      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        this.wellScheduleForm.controls[j + ' ' + storage.mesh.type].patchValue(
          this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        );
      });

      this.wellScheduleForm.controls[j + ' pumpTime'].patchValue(this.wellScheduleForm.controls[i + ' pumpTime'].value);
      this.wellScheduleForm.controls[j + ' downTime'].patchValue(this.wellScheduleForm.controls[i + ' downTime'].value);
    }
  }

  checkLoaderPreference() {
    let arr = [];
    let counter = false;
    this.wellCreationForm.controls['storages'].value.forEach((storage) => {
      this.wellCreationForm.controls['mines'].value.forEach((mine, index) => {
        const value = this.wellContractsForm.controls[mine.id + ' ' + storage.mesh.type + '_loaderPreference'].value;
        if (value !== null) {
          if (index === 0) {
            arr.push(value);
          } else {
            arr.forEach((item) => {
              if (item === value && value !== 0) {
                this.wellContractsForm.controls[mine.id + ' ' + storage.mesh.type + '_loaderPreference'].setErrors({
                  invalid: true,
                });
                counter = true;
              } else {
                arr.push(value);
              }
            });
          }
        }
      });
      arr = [];
    });
    return !counter;
  }

  submit() {
    // No repeated wells
    if (this.submitting) {
      return;
    }

    this.formSubmitted = false;

    const scheduleBody = [];

    for (let i = 0; i < this.wellCreationForm.controls['numOfStages'].value; i++) {
      const meshes = [];

      this.wellCreationForm.controls['storages'].value.forEach((storage) => {
        const storageValue = {
          id: storage.mesh.id,
          volume: this.wellScheduleForm.controls[i + ' ' + storage.mesh.type].value,
        };

        meshes.push(storageValue);
      });

      scheduleBody.push({
        stageNumber: i + 1,
        meshes: meshes,
        pumpTime: this.wellScheduleForm.controls[i + ' pumpTime'].value,
        downTime: this.wellScheduleForm.controls[i + ' downTime'].value,
      });
    }

    const mineContractsBody = [];

    if (this.wellCreationForm.controls['mines'].value) {
      this.wellCreationForm.controls['mines'].value.forEach((mine, i) => {
        this.wellCreationForm.controls['storages'].value.forEach((storage) => {
          mineContractsBody.push({
            fracId: (this.well && this.well.id) || -1,
            mineId: mine.id,
            meshId: storage.mesh.id,
            total: this.wellContractsForm.controls[mine.id + ' ' + storage.mesh.type].value * 2000,
            costPerTon: this.wellContractsForm.controls[mine.id + ' ' + storage.mesh.type + '_totalCost'].value,
            preferredOrder: this.wellContractsForm.controls[mine.id + ' ' + storage.mesh.type + '_loaderPreference']
              .value,
          });
        });
      });
    }

    const fracStorages = [];

    this.wellCreationForm.controls['storages'].value.forEach((storage) => {
      fracStorages.push(storage);
    });
    const requestBody: any = {
      site: {
        name: this.wellCreationForm.controls['name'].value.trim(),
        entranceSpeed: this.wellCreationForm.controls['entranceSpeed'].value,
        ...this.locationResults,
      },
      billingIdentifier: this.wellCreationForm.controls['billingIdentifier'].value,
      prefillDate: this.wellCreationForm.controls['prefillDate'].value,
      crewName: this.wellCreationForm.controls['crewName'].value,
      fracDate: this.wellCreationForm.controls['fracDate'].value,
      totalStages: this.wellCreationForm.controls['numOfStages'].value,
      truckQuantity: this.wellCreationForm.controls['maxTrucks'].value,
      storages: this.wellCreationForm.controls['storages'].value,
      notifyEmails: this.wellCreationForm.controls['emails'].value,
      schedule: this.wellCreationForm.controls['schedule'].value,
      fracMines: this.wellCreationForm.controls['mines'].value,
      direction: this.wellCreationForm.controls['direction'].value,
      allowedUsers: this.wellCreationForm.controls['allowedUsers'].value.filter((user) => user.type === USER),
      selectedGroups: this.wellCreationForm.controls['allowedUsers'].value.filter((user) => user.type === GROUP),
      vendorExport: this.wellCreationForm.controls['vendorExport'].value,
      fracStorages: fracStorages,
      fracStages: scheduleBody,
      mineContracts: mineContractsBody,
      jobName: this.wellCreationForm.get('jobName').value,
      customJobId: this.wellCreationForm.get('customJobId').value,
      autoStorageUpdate: this.wellCreationForm.get('autoStorageUpdate').value,
      boxMove: this.wellCreationForm.get('boxMove').value,
      hseRequirements: this.wellCreationForm.controls['hseRequirements'].value,
      ffDriverCertifications: this.wellCreationForm.controls['driverCertifications'].value,
      trailerTypes: this.wellCreationForm.controls['trailerTypes'].value,
      gpsDirectionLocation: [
        this.wellCreationForm.controls['gpsDirectionLocationLng'].value,
        this.wellCreationForm.controls['gpsDirectionLocationLat'].value,
      ],
      percentBasedAutoOrdering: this.wellCreationForm.controls['percentBasedAutoOrdering'].value,
      enableFuelSurcharge: this.wellCreationForm.controls['enableFuelSurcharge'].value,
    };
    const crewId = this.wellCreationForm.controls['crewId'].value;
    if (crewId) {
      requestBody['crew'] = {
        id: crewId,
      };
    }

    const smsMap = new Map<string, number[]>();
    smsMap['lineUp'] = this.wellCreationForm.controls['lineUpUsers'].value;
    smsMap['fracDown'] = this.wellCreationForm.controls['fracDownUsers'].value;
    smsMap['stage_update_reminder'] = this.wellCreationForm.controls['stageUpdateReminderUsers'].value;
    requestBody['smsUsers'] = smsMap;

    if (this.wellCreationForm.get('clusterId')) {
      requestBody['cluster'] = {
        id: +this.wellCreationForm.get('clusterId').value,
      };
    }

    if (this.districtList.length) {
      requestBody['districtId'] = this.wellCreationForm.get('district').value.id;
    }

    if (this.wellScheduleForm.valid) {
      this.submitting = true;
      if (this.well && this.well.id) {
        this.wellApiService.updateWell(this.well.id, requestBody).subscribe(
          (resp) => {
            this.submitting = false;
            this.snackBar.open(this.wellCreationForm.controls['name'].value + ' successfully updated.', null, {
              duration: 5000,
            });
            this.closeForm(this.well.id);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      } else {
        this.wellApiService.createWell(requestBody).subscribe(
          (resp) => {
            this.formSubmitted = false;
            this.submitting = false;
            this.snackBar.open(this.wellCreationForm.controls['name'].value + ' successfully created.', null, {
              duration: 5000,
            });
            this.closeForm(resp.id);
          },
          (err) => {
            this.formSubmitted = false;
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    }
  }

  numberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  compareByTypeAndId(o1: UserAndUserGroup, o2: UserAndUserGroup) {
    return o1 && o2 && o1.id === o2.id && o1.type === o2.type;
  }

  compareByIntValue(o1, o2) {
    return o1 === o2;
  }

  downloadRecords() {
    if (!this.downloadProgress) {
      this.downloadProgress = true;
      this.wellApiService.downloadRecordUrl(this.well.id).subscribe(
        (data) => {
          window.open(data, '_blank');
          this.downloadProgress = false;
        },
        (error) => {
          console.log('Error downloading the file.', error);
          this.downloadProgress = false;
        },
      );
    }
  }

  downloadReconcileRecords() {
    this.wellApiService.downloadReconcileRecrods(this.well.id).subscribe(
      (data) => {
        this.saveData(data, 'Well ' + getFracName(this.well) + ' Reconciliation Data.csv');
      },
      (error) => {
        console.log('Error downloading the file.', error);
      },
    );
  }

  changeWellStatus() {
    if (this.well.archive) {
      if (confirm('Are you sure you want to activate this well?')) {
        this.wellApiService.activateWell(this.well.id).subscribe(
          (resp) => {
            this.snackBar.open(this.wellCreationForm.controls['name'].value + ' successfully activated.', null, {
              duration: 5000,
            });
            this.ngOnInit();
          },
          (err) => {
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    } else {
      const dialogData = {
        context: ``,
        desc: '',
        button: [],
      };

      if (this.pendingOrders.length) {
        dialogData.context = `${getFracName(this.well)} still has Pending or In Progress Orders`;
        dialogData.desc = `Are you sure? <strong>Archiving this will cancel ${this.pendingOrders.length} pending and in-progress orders.</strong> This cannot be undone.`;
        dialogData.button = ['Back', 'Archive and Cancel Orders'];
      } else {
        dialogData.context = `Archive ${getFracName(this.well)}?`;
        dialogData.desc = `Are you sure? You will not be able to view, dispatch, or order loads for ${getFracName(
          this.well,
        )} while it is archived.`;
        dialogData.button = ['Back', 'Archive'];
      }

      const dialogRef = this.dialog.open(ChoiceDialogComponent, {
        width: '30%',
        maxWidth: '968px',
        data: dialogData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.wellApiService.archiveWell(this.well.id).subscribe(
            (resp) => {
              this.snackBar.open(this.wellCreationForm.controls['name'].value + ' successfully archived.', null, {
                duration: 5000,
              });
              this.ngOnInit();
            },
            (err) => {
              this.errorHandler.showError(err, 5000);
            },
          );
        }
      });
      // if (confirm('Are you sure you want to archive this well?')) {
      //   this.wellApiService.archiveWell(this.well.id).subscribe(resp => {
      //     this.snackBar.open(this.wellCreationForm.controls['name'].value + ' successfully archived.', null, {
      //       duration: 5000
      //     });
      //     this.ngOnInit();
      //   }, err => {
      //     this.errorHandler.showError(err, 5000);
      //   });
      // }
    }
  }

  public hasClusterIdAndName() {
    return this.wellCreationForm.get('clusterId') && this.getClusterName();
  }

  public getClusterName(): string {
    return this.localStorageService.getItem(`cluster-${this.wellCreationForm.get('clusterId').value}`);
  }

  private jobNameListener() {
    const localDestroy$$ = new Subject();
    combineLatest([
      this.wellCreationForm.get('name').valueChanges.pipe(startWith(this.wellCreationForm.get('name').value)),
      this.wellCreationForm.get('crewName').valueChanges.pipe(startWith(this.wellCreationForm.get('crewName').value)),
    ])
      .pipe(takeUntil(this.destroy$$), takeUntil(localDestroy$$))
      .subscribe(([siteName, crewName]) => {
        // If the user has typed a job name in or we have are editing an exisitng well, don't automatically populate anymore
        if (this.well || this.wellCreationForm.get('jobName').dirty) {
          localDestroy$$.next();
          return;
        }
        let jobName = siteName;
        if (crewName && crewName !== '') {
          jobName = `${crewName} - ${siteName}`;
        }
        this.wellCreationForm.get('jobName').setValue(jobName);
      });
  }

  private crewListener() {
    this.wellCreationForm
      .get('crewId')
      .valueChanges.pipe(startWith(this.wellCreationForm.get('crewId').value))
      .pipe(takeUntil(this.destroy$$), withLatestFrom(this.crews$))
      .subscribe(([crewId, groupedCrew]: [number, GroupedCrew[]]) => {
        if (!crewId || !groupedCrew) {
          return;
        }
        for (let i = 0; i < groupedCrew.length; i++) {
          const crew = groupedCrew[i].crews?.find((c) => c.id === crewId);
          if (crew) {
            this.wellCreationForm.get('crewName').setValue(crew.name);
            break;
          }
        }
      });
  }

  public getName(user, position, length) {
    return user.name + (user.sso ? ' (sso) ' : '') + (position < length - 1 ? ', ' : '');
  }

  public userSelected(groupUser) {
    if (groupUser.type === GROUP) {
      const selectedGroup = this.wellCreationForm
        .get('allowedUsers')
        .value.filter((user) => user.type === GROUP)
        ?.find((user) => user.id === groupUser.id);
      if (selectedGroup != null) {
        const userIds = groupUser.userIds;
        const selectedUsers = this.wellCreationForm.get('allowedUsers').value;
        const newNeedToSelectUserIds = userIds.filter((userId) => {
          const selectedUser = selectedUsers?.find((user) => user.type === USER && user.id === userId);
          return selectedUser == null;
        });
        this.filteredUserOptions.subscribe((groups) => {
          groups.forEach((group) => {
            if (group.type === USER) {
              const newUsers = group.users.filter((user) => newNeedToSelectUserIds.indexOf(user.id) >= 0);
              selectedUsers.push(...newUsers);
            }
          });
        });
        this.wellCreationForm.get('allowedUsers').setValue(selectedUsers);
      } else {
        const userIds = groupUser.userIds;
        const selectedUsers = this.wellCreationForm.get('allowedUsers').value;
        const newSelectedUsers = selectedUsers.filter((user) => user.type === GROUP || userIds.indexOf(user.id) < 0);
        this.wellCreationForm.get('allowedUsers').setValue(newSelectedUsers);
      }
    }
  }
}
