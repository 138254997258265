import { UserService } from '~services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordValidator } from '../services/custom-validators';
import { Router } from '@angular/router';
import { AuthService } from '~services/auth.service';
import { StoreService } from '~services/store.service';

interface FormValue {
  confirmPassword: string;
  password: string;
}

@Component({
  selector: 'sa-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  public updatePasswordForm: FormGroup;
  public password: string;
  public hide = true;
  public hide1 = true;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private storeService: StoreService,
  ) { }

  private get formValue(): FormValue {
    return this.updatePasswordForm.value;
  }

  public ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.updatePasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, PasswordValidator('password', 'confirmPassword')]],
    },
      {
        validator: [PasswordValidator('password', 'confirmPassword')]
      });
  }

  updatePassword() {
    const formValue = this.formValue;
    if (this.updatePasswordForm.invalid) {
      return;
    }
    if (formValue.password === formValue.confirmPassword) {
      this.userService.updatePassword$(formValue.password)
        .subscribe(
          (response) => {
            this.snackBar.open('Password updated successfully', null, { duration: 2000 });
            this.logout();
          },
          (error) => {
            console.error(error);
            this.snackBar.open('Failed to update password', null, { duration: 2000, panelClass: ['snackbar-error'] });
          },
        );
    }
  }

  logout() {
    this.storeService.clear();
    this.authService.logout();
  }
}
