import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Charge, ChargeType } from '~billing/models';

@Component({
  selector: 'sa-add-charge-dialog',
  templateUrl: './add-charge-dialog.component.html',
  styleUrls: ['./add-charge-dialog.component.scss'],
})
export class AddChargeDialogComponent implements OnInit {
  chargeForm: FormGroup;
  chargeTypes: ChargeType[] = [];
  charge: Charge;
  existingCharges: number[];
  duplicateError = false;
  submitting = false;
  compareId: ((o1: any, o2: any) => boolean) | null = this.compareById;
  LMOChargeType = {
    deadhead: 'Deadhead',
    det_loading: 'LoadingDetention',
    linehaul: 'Linehaul',
    accessorial: 'Accessorial',
    det_unloading: 'UnLoadingDetention',
  };
  units = [
    {
      name: 'Miles',
      value: 'miles',
    },
    {
      name: 'Minutes',
      value: 'm',
    },
    {
      name: 'Hour',
      value: 'hr',
    },
    {
      name: 'Ton',
      value: 'ton',
    },
    {
      name: 'Load',
      value: 'load',
    },
    {
      name: 'Gallons',
      value: 'gallons',
    },
    {
      name: 'Each',
      value: 'each',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<AddChargeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: { chargeTypes: ChargeType[]; charge: Charge; existingCharges: Charge[] },
  ) {
    this.chargeTypes = _data.chargeTypes;
    this.charge = _data.charge;
    this.existingCharges = _data.existingCharges.map((existingCharge: Charge) => existingCharge.chargeTypeId);
  }

  private compareById(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.chargeForm = this.fb.group({
      quantity: [this.charge ? this.charge.numberOfUnits : '', [Validators.required]],
      unitCost: [this.charge ? (this.charge.pricePerUnit / 100).toFixed(2) : '', [Validators.required]],
      quantityUnit: [this.charge ? this.charge.unitOnQuantity : '', [Validators.required]],
      type: [{ value: this.charge ? this.charge.chargeType : null, disabled: this.charge }, [Validators.required]],
    });
  }

  submitForm() {
    this.submitting = true;
    this.duplicateError = false;
    const chargeValue = this.charge ? this.charge.chargeType.id : this.chargeForm.value.type.id;
    if (this.chargeForm.invalid) {
      this.chargeForm.markAsTouched();
      this.submitting = false;
    } else if (this.existingCharges.includes(chargeValue)) {
      this.duplicateError = true;
      this.submitting = false;
    } else {
      this.submitting = false;
      this.matDialogRef.close(this.chargeForm.value);
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
