import { Injectable } from '@angular/core';
import { CrudService } from './services/crud.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take, map, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { OrderSummary } from '~models/orderSummary.model';
import * as Fuse from 'fuse.js';

const searchOptions: Fuse.FuseOptions<OrderSummary> = {
  keys: ['truckName', 'driverName', 'loadNumber', 'mineName', 'bolNumber', 'ticketNumber', 'boxNames', 'saUniqueId'],
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 16,
  minMatchCharLength: 1,
};

@Injectable({
  providedIn: 'root',
})
export class CompletedOrdersService {
  private _searchTerm: string = null;
  private currentFracId: number;
  private currentDistributionCenterId: number;
  private completedOrders: OrderSummary[] = [];
  private cancelledOrders: OrderSummary[] = [];
  private fuseCompletedSearch: Fuse<OrderSummary, {}>;
  private fuseCancelledSearch: Fuse<OrderSummary, {}>;
  private completedOrders$$: BehaviorSubject<OrderSummary[]> = new BehaviorSubject<OrderSummary[]>([]);
  private cancelledOrders$$: BehaviorSubject<OrderSummary[]> = new BehaviorSubject<OrderSummary[]>([]);
  private loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get completedOrders$() {
    return this.completedOrders$$.asObservable();
  }

  public get cancelledOrders$() {
    return this.cancelledOrders$$.asObservable();
  }

  public get totalCompletedOrders(): number {
    return this.completedOrders.length + this.cancelledOrders.length;
  }

  public get totalFilterMatchedOrders(): number {
    return this.completedOrders$$.value.length + this.cancelledOrders$$.value.length;
  }

  public get loading$() {
    return this.loading$$.asObservable();
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.filter();
  }

  constructor(private crudService: CrudService) {}

  public loadOrdersForFrac(fracId: number): void {
    if (this.currentFracId !== fracId) {
      this.completedOrders = [];
      this.cancelledOrders = [];
      this.fuseCompletedSearch = null;
      this.fuseCancelledSearch = null;
      this.completedOrders$$.next([]);
      this.cancelledOrders$$.next([]);
    }
    this.currentFracId = fracId;
    this.currentDistributionCenterId = 0;
    this.loading$$.next(true);
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/v2/trucking_vendor/frac/${fracId}/completed_orders`)),
      )
      .subscribe((orders: { cancelledOrderSummaries: OrderSummary[]; completedOrderSummaries: OrderSummary[] }) => {
        this.completedOrders = orders.completedOrderSummaries || [];
        this.cancelledOrders = orders.cancelledOrderSummaries || [];
        this.fuseCompletedSearch = new Fuse(this.completedOrders, searchOptions);
        this.fuseCancelledSearch = new Fuse(this.cancelledOrders, searchOptions);
        this.filter();
        this.loading$$.next(false);
      });
  }

  public loadOrdersForDistributionCenter(dcId: number): void {
    if (this.currentDistributionCenterId !== dcId) {
      this.completedOrders = [];
      this.cancelledOrders = [];
      this.fuseCompletedSearch = null;
      this.fuseCancelledSearch = null;
      this.completedOrders$$.next([]);
      this.cancelledOrders$$.next([]);
    }
    this.currentDistributionCenterId = dcId;
    this.currentFracId = 0;
    this.loading$$.next(true);
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.crudService.get(`${environment.api}/trucking_vendor/distribution_center/${dcId}/completed_orders`),
        ),
      )
      .subscribe((orders: { cancelledOrderSummaries: OrderSummary[]; completedOrderSummaries: OrderSummary[] }) => {
        this.completedOrders = orders.completedOrderSummaries || [];
        this.cancelledOrders = orders.cancelledOrderSummaries || [];
        this.fuseCompletedSearch = new Fuse(this.completedOrders, searchOptions);
        this.fuseCancelledSearch = new Fuse(this.cancelledOrders, searchOptions);
        this.filter();
        this.loading$$.next(false);
      });
  }

  private filter(): void {
    if (this.searchTerm === null || this.searchTerm === '') {
      this.completedOrders$$.next(this.completedOrders);
      this.cancelledOrders$$.next(this.cancelledOrders);
      return;
    }

    if (this.fuseCompletedSearch) {
      this.completedOrders$$.next(this.fuseCompletedSearch.search(this.searchTerm));
    }

    if (this.fuseCancelledSearch) {
      this.cancelledOrders$$.next(this.fuseCancelledSearch.search(this.searchTerm));
    }
  }
}
