import { filter, take, map, startWith } from 'rxjs/operators';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { EmailSettingsV2Service } from 'src/app/services/email-settings-v2.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { Mine } from '~models/index';
import { MineService } from '~services/mine.service';
import { FeatureFlagService } from '~services/feature-flag.service';

@Component({
  selector: 'sa-upload-analytics-file',
  templateUrl: './upload-analytics-file.component.html',
  styleUrls: ['./upload-analytics-file.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class UploadAnalyticsFileComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private emailSettingsV2Service: EmailSettingsV2Service,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlingService,
    public mineService: MineService,
    private featureFlagService: FeatureFlagService,
  ) {}

  mines$: Observable<Mine[]>;
  fileForm: FormGroup;
  subs: Subscription[] = [];
  filterLoader: FormControl = new FormControl();
  filteredMines$: Observable<Mine[]>;

  public uploading$$ = new BehaviorSubject<boolean>(false);

  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  public allowMultipleMines$: Observable<boolean>;

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.goBack.emit();
      }
    }
  }

  ngOnInit() {
    this.allowMultipleMines$ = this.featureFlagService.isFlagActive('loaderIntegrationMultipleMineInSpreadsheet');

    this.mines$ = this.mineService.mines$.pipe(map((mines) => mines.data || []));

    this.buildForm();
  }

  async buildForm() {
    const allowMultipleLoaders = await this.allowMultipleMines$.pipe(take(1)).toPromise();

    this.fileForm = this.fb.group({
      loader: [null, allowMultipleLoaders ? [] : [Validators.required]],
      uploadFile: [null, [Validators.required]],
    });

    this.filteredMines$ = combineLatest([this.mines$, this.filterLoader.valueChanges.pipe(startWith(''))]).pipe(
      map(([mines, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          return mines.filter((option) => option.site.name.toLowerCase().indexOf(filterValue) > -1);
        } else {
          return mines;
        }
      }),
    );
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  async onSubmit() {
    if (this.uploading$$.value) {
      return;
    }

    try {
      if (this.fileForm.valid) {
        this.uploading$$.next(true);
        await this.emailSettingsV2Service
          .doManualFileUpload(this.fileForm.controls['uploadFile'].value, this.fileForm.controls['loader'].value?.id)
          .toPromise();

        this.snackBar.open('File uploaded successfully', null, {
          duration: 5000,
        });
        this.goBack.emit();
      } else {
        this.errorHandler.showError('Please fill all the fields');
      }
    } catch {
      this.snackBar.open('Failed to upload file.', null, {
        duration: 5000,
        panelClass: ['snackbar-error'],
      });
    } finally {
      this.uploading$$.next(false);
    }
  }

  close() {
    this.goBack.emit();
  }

  fileChanged(files: File[]) {
    if (files && files.length > 0) {
      this.fileForm.controls['uploadFile'].setValue(files[0]);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((_) => {
      _.unsubscribe();
    });
  }
}
