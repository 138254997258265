<ng-container *ngIf="showSection == 1">
  <div class="order-detail-component" *ngIf="!loading && isOrder()">
    <a class="back-wrap" [routerLink]="['../../']">
      <i class="material-icons">arrow_back</i>
    </a>
    <div class="heading">
      <h1>
        <div class="flex items-center">
          {{ selectedOrder.saUniqueId }}
          <sa-lohi-preload-status [order]="selectedOrder"></sa-lohi-preload-status>
          <img
            *ngIf="selectedOrder.boxPickup"
            class="w-4"
            src="/assets/icons/box_move.svg"
            alt="box move"
            matTooltip="Box Move {{ selectedOrder.boxPickup.siteName }}"
          />
        </div>
        <br />
        <span class="sub-header">Sequence Number: {{ selectedOrder?.loadNumber }}</span>
      </h1>
      <mat-spinner [diameter]="25" *ngIf="changingTruck"></mat-spinner>
    </div>
    <div class="order-detail-content-wrap">
      <div class="order-detail-content">
        <h3>Order Details</h3>
        <div class="name-wrap">{{ selectedOrder?.vendor?.name }}</div>
        <div class="name-detail">{{ selectedOrder?.loadWeight / 1000 }}k of {{ selectedOrder?.mesh.type }} mesh</div>
        <div *saFeatureFlag="'orderTrailerType'">
          <div *ngIf="selectedOrder?.trailerType" class="name-detail">
            Trailer Type: {{ selectedOrder?.trailerType?.trailerTypeDisplayName }}
          </div>
        </div>
        <div class="route-desc">
          <div class="end-point">
            <div *ngIf="selectedOrder.boxPickup && selectedOrder.boxPickup?.beforeOrder; then boxMove"></div>
          </div>
          <div class="end-point" *ngIf="selectedOrder.mine?.id">
            <div
              class="icon-wrap"
              [ngClass]="{ blue: !isFinished() && !selectedOrder.loaded && !selectedOrder.pendingBoxPickup }"
            >
              <i class="material-icons">arrow_upward</i>
            </div>
            <span>{{ selectedOrder.mine?.site.name }}</span>
          </div>
          <div class="end-point" *ngIf="selectedOrder.distributionCenter?.id">
            <div
              class="icon-wrap"
              [ngClass]="{ blue: !isFinished() && !selectedOrder.loaded && !selectedOrder.pendingBoxPickup }"
            >
              <i class="material-icons">arrow_upward</i>
            </div>
            <span>{{ selectedOrder.distributionCenter?.site.name }}</span>
          </div>
          <div class="end-point">
            <div
              class="icon-wrap"
              [ngClass]="{
                blue: !isFinished() && selectedOrder.loaded && !selectedOrder.pendingBoxPickup
              }"
            >
              <i class="material-icons">arrow_downward</i>
            </div>
            <span>{{ selectedFrac | fracName }}</span>
          </div>
          <div class="end-point">
            <div *ngIf="selectedOrder.boxPickup && !selectedOrder.boxPickup?.beforeOrder; then boxMove"></div>
          </div>
          <ng-template #boxMove>
            <div
              class="icon-wrap flex items-center justify-center"
              [ngClass]="{
                blue: !isFinished() && !selectedOrder.boxPickup?.completed && selectedOrder.pendingBoxPickup
              }"
            >
              <img
                class="w-2"
                src="/assets/icons/box_move_light.svg"
                alt="box move"
                matTooltip="Box Move {{ selectedOrder.boxPickup?.siteName }}"
              />
            </div>
            <span>(BOX PICKUP) {{ selectedOrder.boxPickup?.siteName }}</span>
          </ng-template>
        </div>
        <div class="action-bar" *ngIf="!isFinished()">
          <button
            id="button-unassign"
            class="red-btn"
            color="primary"
            mat-stroked-button
            *ngIf="!selectedOrder?.loaded"
            (click)="unassignOrder()"
          >
            UNASSIGN
          </button>
          <div>
            <button
              id="button-mark-loaded"
              class="second-btn"
              color="primary"
              mat-stroked-button
              *ngIf="selectedOrder?.orderStatus === 'driver_accepted' && selectedOrder?.loaded === false"
              (click)="markAsLoaded()"
              saAutomatedSelector="dispatcher_in_progress__mark_loaded"
            >
              MARK AS LOADED
            </button>
            <button
              id="button-mark-preload"
              class="second-btn"
              color="primary"
              mat-stroked-button
              *ngIf="
                isLoHi &&
                selectedOrder?.orderStatus === 'driver_accepted' &&
                !selectedOrder.loHiPreloadActive &&
                !selectedOrder.loHiPreloadPaused
              "
              (click)="startMarkAsPreload()"
              saAutomatedSelector="dispatcher_in_progress__mark_preload"
            >
              MARK AS PRELOAD
            </button>
            <button
              id="button-mark-complete"
              class="second-btn"
              color="primary"
              mat-stroked-button
              *ngIf="selectedOrder?.orderStatus === 'driver_accepted' && selectedOrder?.loaded === true"
              (click)="completeOrder()"
              saAutomatedSelector="dispatcher_in_progress__complete"
            >
              COMPLETE
            </button>
          </div>
          <button
            id="button-accept-driver"
            class="second-btn"
            color="primary"
            mat-stroked-button
            *ngIf="selectedOrder?.orderStatus === 'dispatched' && selectedOrder?.vendor.canAcceptLoadForDriver"
            (click)="acceptForDriver()"
            saAutomatedSelector="dispatcher_in_progress__accept_for_driver"
          >
            Accept For Driver
          </button>
        </div>
        <div class="time-bar" *ngIf="selectedOrder?.orderStatus === 'completed'">
          Completed at {{ datePipe.transform(selectedOrder?.endTimestamp, 'H:mm LLL dd') }}
        </div>
      </div>

      <div
        class="order-detail-content"
        *ngIf="selectedOrder?.orderStatus === 'canceled' && selectedFrac?.account?.name && selectedOrder.cancelReason"
      >
        <h3>Canceled by {{ selectedFrac?.account?.name }}</h3>
        <div class="name-wrap">
          This order was canceled due to the following reason: "{{ selectedOrder.cancelReason }}"
        </div>
      </div>

      <div
        class="order-detail-content"
        *ngIf="
          selectedOrder?.user && selectedOrder?.orderStatus !== 'completed' && selectedOrder?.orderStatus !== 'canceled'
        "
      >
        <div class="head-wrap">
          <h3>Current Status</h3>
          <div class="timestamp">
            Updated {{ calculateReceivingTime(selectedOrder.user.lastTimeSeen) }} ago
            <br />
            <span class="preload" *ngIf="selectedOrder.isPreLoad">
              PreLoad
            </span>
          </div>
        </div>
        <div class="sub-content-wrap">
          <div class="main-content">
            <div class="name-wrap">{{ selectedOrder?.user.name }}</div>
            <div class="name-detail">Truck {{ selectedOrder?.truck?.name }}</div>
            <div class="name-detail" *ngIf="selectedOrder?.trailer">Trailer {{ selectedOrder?.trailer.name }}</div>
            <div class="name-wrap" style="margin-top: 8px;">PO Number {{ selectedOrder?.poNumber }}</div>
          </div>
          <div class="time-details">
            <ng-container
              *ngIf="
                selectedOrder?.user.userStatus.type === 'Online' ||
                selectedOrder?.user.userStatus.type === 'Unreachable'
              "
            >
              <div class="timestamp">
                <span *ngIf="selectedOrder?.etaStatus === 'arrived'">ARRIVED</span>
                <span *ngIf="selectedOrder?.etaStatus === 'staging'">STAGING</span>
                <span *ngIf="selectedOrder?.etaStatus === 'none' && selectedOrder?.eta">{{
                  calculateEta(selectedOrder?.eta)
                }}</span>
                <span
                  *ngIf="
                    selectedOrder?.etaStatus !== 'none' &&
                    selectedOrder?.etaStatus !== 'staging' &&
                    selectedOrder?.etaStatus !== 'arrived'
                  "
                  >-</span
                >
              </div>
              <div class="driver-status">
                <span *ngIf="!selectedOrder?.loaded">EST. PICKUP</span>
                <span *ngIf="selectedOrder?.loaded">EST. DROPOFF</span>
              </div>
            </ng-container>
            <div
              class="action-needed"
              *ngIf="selectedOrder?.user.userStatus.type === 'ActionNeeded' && !selectedOrder?.isPreLoad"
            >
              Action Needed
            </div>
          </div>
        </div>
        <div class="button-row">
          <button mat-stroked-button (click)="editLoad()" id="button-edit-progress">EDIT</button>
          <button mat-stroked-button (click)="swapDrivers()" id="button-swap-driver">SWAP DRIVER</button>
        </div>
      </div>
      <div class="order-detail-content" *ngIf="selectedOrder?.user && selectedOrder?.orderStatus === 'completed'">
        <h3>Load Details</h3>
        <div class="item-row">
          <div class="label">Driver</div>
          <div class="value-wrap">{{ selectedOrder?.user?.name || '-' }}</div>
        </div>
        <div class="item-row">
          <div class="label">Truck</div>
          <div class="value-wrap">{{ selectedOrder?.truck?.name || '-' }}</div>
        </div>
        <div class="item-row">
          <div class="label">Ticket Number</div>
          <div class="value-wrap">{{ selectedOrder?.ticketNumber || '-' }}</div>
        </div>
        <div class="item-row">
          <div class="label">BOL Number</div>
          <div class="value-wrap">{{ selectedOrder?.bolNumber || '-' }}</div>
        </div>
        <div class="item-row">
          <div class="label">Description</div>
          <div class="value-wrap">{{ selectedOrder?.description || '' }}</div>
        </div>
        <div class="item-row" *ngIf="!selectedOrder?.boxes">
          <div class="label">Load Weight</div>
          <div class="value-wrap">{{ selectedOrder?.actualLoadWeight || '-' }}</div>
        </div>
        <div class="item-row" *ngIf="!selectedOrder?.boxes">
          <div class="label">Bulk Storage Number</div>
          <div class="value-wrap">{{ selectedOrder?.bulkStorageNumber || '-' }}</div>
        </div>

        <ng-container *ngIf="selectedOrder?.boxes">
          <div *ngFor="let box of selectedOrder?.boxes; let i = index">
            <div class="item-row">
              <div class="label">Box {{ i + 1 }} ID</div>
              <div class="value-wrap">{{ box.name || '-' }}</div>
            </div>

            <div class="item-row">
              <div class="label">Box {{ i + 1 }} Load Weight</div>
              <div class="value-wrap">{{ box.actualLoadWeight || '-' }}</div>
            </div>
          </div>
        </ng-container>

        <div class="button-row">
          <button mat-stroked-button *ngIf="checkForEditableOrder()" (click)="editLoad()">EDIT</button>
        </div>
      </div>

      <sa-order-map-controls
        [fracId]="selectedFracId"
        [loadNumber]="selectedLoadNumber"
        *saFeatureFlag="'drawOrderMap'"
      ></sa-order-map-controls>

      <div class="order-detail-content" *ngIf="!isFinished() && selectedOrder?.user?.userStatus.isException">
        <div class="title red-text">
          <img src="../../../../assets/icons/alarm-24.svg" alt="Alert" />
          <h2>{{ selectedOrder?.user.userStatus.exceptionTitle }}</h2>
        </div>
        <div class="content-detail">
          <ng-container>
            {{ selectedOrder?.user.userStatus.exceptionDescription }}
          </ng-container>
        </div>
        <div class="button-wrap row-reverse">
          <!--<ng-container  *ngIf="!selectedOrder?.user.userStatus.exception_resolution_url">-->
          <!--<a [href]="sanitize(selectedOrder?.user.phone)"><button mat-stroked-button color="primary">Message Driver</button></a>-->
          <!--</ng-container>-->
          <a
            *ngIf="selectedOrder?.user.userStatus.exceptionUrl"
            href="{{ selectedOrder?.user.userStatus.exceptionUrl }}"
            target="_blank"
          >
            <button mat-stroked-button color="primary">View Steps <i class="material-icons">arrow_forward</i></button>
          </a>
          <a href="tel:{{ selectedOrder?.user.phone }}">
            <button mat-stroked-button color="primary">Call Driver</button>
          </a>
        </div>
      </div>

      <div
        class="order-detail-content"
        *ngIf="selectedOrder?.orderStatus === 'dispatched' || selectedOrder?.orderStatus === 'driver_accepted'"
      >
        <h3>Check On Driver</h3>
        <div class="detail-wrap">
          <div class="phone-number">{{ selectedOrder?.user.phone | phone }}</div>
          <div class="icon-wrap">
            <a href="tel:{{ selectedOrder?.user.phone }}"><i class="material-icons">call</i></a>
            <a [href]="sanitize(selectedOrder?.user.phone)"><i class="material-icons">chat_bubble</i></a>
          </div>
        </div>
      </div>

      <div class="order-detail-content flex-div">
        <h3>Attachments</h3>
        <div class="item-row">
          <div class="label">Upload BOL</div>
          <div>
            <label class="attachment-label">
              <input
                type="file"
                (change)="onFileChange($event, selectedOrder?.id, 'bol_image')"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
              />
              <i class="material-icons upload-icon" *ngIf="uploading === ''">
                cloud_upload
              </i>
              <mat-spinner *ngIf="uploading === 'bol_image'" [diameter]="25"></mat-spinner>
            </label>
          </div>
        </div>
        <div class="attachment-row" *ngIf="bolAttachments.length">
          <div
            *ngFor="let file of bolAttachments"
            class="file-preview"
            (click)="downloadFile(file)"
            [ngClass]="{ selected: selectedFileName === file.fileName }"
          >
            <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
            <img [src]="getFileIcon(file.fileName)" alt="" />
            <p>{{ smallFileName(file.fileName) }}</p>
          </div>
        </div>
        <div class="item-row">
          <div class="label">Upload Truck Ticket</div>
          <div>
            <label class="attachment-label">
              <input
                type="file"
                (change)="onFileChange($event, selectedOrder?.id, 'ticket_image')"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
              />
              <i class="material-icons upload-icon" *ngIf="uploading === ''">
                cloud_upload
              </i>
              <mat-spinner *ngIf="uploading === 'ticket_image'" [diameter]="25"></mat-spinner>
            </label>
          </div>
        </div>
        <div class="attachment-row" *ngIf="truckTicketAttachments.length">
          <div
            *ngFor="let file of truckTicketAttachments"
            class="file-preview"
            (click)="downloadFile(file)"
            [ngClass]="{ selected: selectedFileName === file.fileName }"
          >
            <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
            <img [src]="getFileIcon(file.fileName)" alt="" />
            <p>{{ smallFileName(file.fileName) }}</p>
          </div>
        </div>
        <div class="item-row">
          <div class="label">Upload Other</div>
          <div>
            <label class="attachment-label">
              <input
                type="file"
                (change)="onFileChange($event, selectedOrder?.id, 'other_image')"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
              />
              <i class="material-icons upload-icon" *ngIf="uploading === ''">
                cloud_upload
              </i>
              <mat-spinner *ngIf="uploading === 'other_image'" [diameter]="25"></mat-spinner>
            </label>
          </div>
        </div>
        <div class="attachment-row" *ngIf="otherAttachments.length">
          <div
            *ngFor="let file of otherAttachments"
            class="file-preview"
            (click)="downloadFile(file)"
            [ngClass]="{ selected: selectedFileName === file.fileName }"
          >
            <i class="material-icons delete-icon" (click)="deleteFile(file, $event)">close</i>
            <img [src]="getFileIcon(file.fileName)" alt="" />
            <p>{{ smallFileName(file.fileName) }}</p>
          </div>
        </div>
      </div>

      <div class="order-detail-content flex-div" *ngIf="selectedOrder.reroutedFrom">
        <div class="title">
          <img class="reroute-img low-height" src="../../../../assets/icons/RerouteFrom.png" alt="ReroutedFrom" />
          <h3>Rerouted From</h3>
        </div>
        <h4 *ngIf="selectedOrder.mine.site.name !== selectedOrder.reroutedFrom.mineName">
          This order was rerouted from a previous loader, {{ selectedOrder?.reroutedFrom.mineName }}
        </h4>
        <h4 *ngIf="(selectedFrac | fracName) !== selectedOrder.reroutedFrom.fracName">
          This order was rerouted from a previous destination, {{ selectedOrder?.reroutedFrom.fracName }}
        </h4>
        <a
          class="redirect-btn"
          color="primary"
          mat-flat-button
          *ngIf="getOrderURL(selectedOrder.reroutedFrom) as link"
          [routerLink]="link"
          >Open Previous Order</a
        >
      </div>

      <div class="order-detail-content flex-div" *ngIf="selectedOrder?.reroutedTo">
        <div class="title">
          <img class="reroute-img" src="../../../../assets/icons/RerouteTo.png" alt="ReroutedFrom" />
          <h3>Rerouted To</h3>
        </div>
        <h4 *ngIf="selectedOrder?.mine.site.name !== selectedOrder?.reroutedTo.mineName">
          This order was rerouted to a new loader, {{ selectedOrder?.reroutedTo.mineName }}
        </h4>
        <h4 *ngIf="selectedDc?.site?.name !== selectedOrder?.reroutedTo.fracName">
          This order was rerouted to a new destination, {{ selectedOrder?.reroutedTo.fracName }}
        </h4>
        <a
          class="redirect-btn"
          color="primary"
          mat-flat-button
          *ngIf="getOrderURL(selectedOrder.reroutedTo) as link"
          [routerLink]="link"
          >Open New Order</a
        >
      </div>
      <sa-order-prediction *ngIf="userService.isShaleappsEmail() && !isFinished()"></sa-order-prediction>

      <div
        class="order-detail-content flex-div"
        *ngIf="
          selectedPurchaseOrder && selectedPurchaseOrder.uploadedFiles && selectedPurchaseOrder.uploadedFiles.length > 0
        "
      >
        <h3>PO Attachments</h3>
        <mat-grid-list cols="2" rowHeight="3:2">
          <mat-grid-tile *ngFor="let file of selectedPurchaseOrder.uploadedFiles; trackBy: trackById">
            <div class="image-preview">
              <img [src]="file.url" *ngIf="isImage(file.fileName); else noPreview" (click)="openInWindow(file.url)" />
              <ng-template #noPreview>
                <div class="preview-placeholder" (click)="openInWindow(file.url)">
                  <h3>{{ file.fileName }}</h3>
                </div>
              </ng-template>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
  <div class="pending-order-detail-component" *ngIf="!loading && !isOrder()">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <div class="header-wrap">
      <div class="name-details">
        <h1>Order Unavailable</h1>
        <h2 *ngIf="selectedOrder?.isGone">This order is no longer available</h2>
      </div>
    </div>
  </div>

  <div class="spin-wrap" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
    <h3>Loading Data...</h3>
  </div>
</ng-container>

<ng-container *ngIf="showSection === 2">
  <sa-update-load [selectedOrder]="selectedOrder" [fracs]="dcs" (onGoBack)="backFromEdit($event)"> </sa-update-load>
</ng-container>

<ng-container *ngIf="showSection === 3">
  <sa-add-driver
    [selectedOrder]="selectedOrder"
    [selectedFrac]="selectedFrac"
    (onGoBack)="onBack($event)"
    (onSelectDriver)="setDriver($event)"
  >
  </sa-add-driver>
</ng-container>

<ng-template #confirmPreload>
  <div mat-dialog-title>Are you sure you want to mark {{ selectedOrder.saUniqueId }} as preload?</div>
  <div mat-dialog-content>
    <mat-form-field class="w-full">
      <mat-label>Why are you making this a preload?</mat-label>
      <input matInput #reason />
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="flex justify-between">
    <button mat-button matDialogClose>Nevermind</button>
    <button mat-stroked-button color="primary" matDialogClose (click)="markPreload(reason.value)">Preload!</button>
  </div>
</ng-template>
