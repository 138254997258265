import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderPickupSiteName',
})
export class OrderPickupSiteNamePipe implements PipeTransform {
  transform(order: any): string {
    return order?.mine?.site?.name || order?.distributionCenter?.site?.name;
  }
}
