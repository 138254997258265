import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { MineApiService } from '../services/api/mine.api.service';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MineCreationComponent } from '../wells/mine-creation/mine-creation.component';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sa-mines',
  templateUrl: './mines.component.html',
  styleUrls: ['./mines.component.scss'],
})
export class MinesComponent implements OnInit, OnDestroy {
  mines: any[] = [];

  subs: Subscription[] = [];

  displayedColumns = ['name', 'edit'];

  isLoading = false;

  mineDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private crud: CrudService,
    private mineApiService: MineApiService,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.userService.isDispatcherAccount()) {
      this.router.navigateByUrl('/map');
    }

    this.crud.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe((_) => {
        this.getMines();
      });

    if (this.userService.isShaleappsEmail()) {
      this.displayedColumns = ['siteID', ...this.displayedColumns];
      if (this.userService.isSandmanAccount()) {
        this.displayedColumns.push('delete');
      }
    }
  }

  getMines() {
    this.isLoading = true;
    this.mineApiService.getMines().subscribe((_mines) => {
      this.mines = _mines.sort(function(a, b) {
        const nameA = a.site.name.toUpperCase();
        const nameB = b.site.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setTableData();
    });
  }

  checkEditable(loader) {
    if (loader.site.ownerId === this.userService.accountId()) {
      return true;
    }
    return false;
  }

  openMineForm(mine) {
    const dialogRef = this.dialog.open(MineCreationComponent, {
      width: '80%',
      maxWidth: '968px',
      maxHeight: '80vh',
      data: mine,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getMines();
      }
    });
  }

  setTableData() {
    this.mineDataSource.data = this.mines;
    this.mineDataSource.sort = this.sort;
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  promptDelete(row) {
    const confirmed = confirm('Ranger: are you sure you want to delete this mine?');
    if (confirmed) {
      this.mineApiService.softDeleteMine(row.id).subscribe(() => {
        this.getMines();
      });
    }
  }

  copy(anything: string) {
    const temp: HTMLInputElement = document.createElement('input');
    document.body.appendChild(temp);
    temp.value = anything;
    temp.select();
    document.execCommand('copy');
    temp.remove();
  }
}
