import { Component } from '@angular/core';

@Component({
  selector: 'sa-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  displayNav = false;

  toggleNav() {
    this.displayNav = !this.displayNav;
  }
}
