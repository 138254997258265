import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { take } from 'rxjs/operators';
import {
  DynamicPriceHistory,
  DynamicPricingService,
  Rate,
  Regions,
  SDGraphData,
  UpcomingRates,
} from '~services/api/dynamic-pricing.service';
import { MatDialog } from '@angular/material/dialog';
import { DynamicPricingDialogComponent } from './dynamic-pricing-dialog/dynamic-pricing-dialog.component';

export interface DialogData {
  benchmark: number;
}

@Component({
  selector: 'sa-dynamic-pricing',
  templateUrl: './dynamic-pricing.component.html',
  styles: [],
})
export class DynamicPricingComponent implements OnInit {
  public regions$: Observable<Regions>;
  public priceData$: Observable<DynamicPriceHistory>;
  public ratioData$: Observable<SDGraphData>;
  public rateData$: Observable<UpcomingRates>;
  public displayNav$$ = new BehaviorSubject<boolean>(false);
  public userLabel: { name: string; email: string; account: string };
  public priceChart: Chart;
  public ratioChart: Chart;
  public selected;
  public benchmark = 7;
  public dataLength = 0;
  public params = {};
  public regions = [];
  public committedRates: Rate[];
  public forecastedRates: Rate[];
  public maxVariableIncrease = 0;
  public loading = true;

  constructor(
    private userService: UserService,
    private dynamicPricingService: DynamicPricingService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.regions$ = this.dynamicPricingService.getRegions$();
    this.regions$.subscribe((data) => {
      this.regions = data.regions;
      this.selected = this.regions[0].label;
    });
    this.loadData();
    this.userLabel = this.userService.getLabel();
    this.priceChart = new Chart({
      chart: {
        type: 'scatter',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      yAxis: [
        {
          title: {
            text: 'Rates ($/mile)',
            style: {
              fontWeight: 'bold',
            },
          },
        },
        {
          title: {
            text: 'Loads / Drivers',
            style: {
              fontWeight: 'bold',
            },
          },
          opposite: true,
        },
      ],
      xAxis: {
        title: {
          text: 'Dates',
          style: {
            fontWeight: 'bold',
          },
        },
        crosshair: true,
        labels: {
          step: 7,
        },
      },
      plotOptions: {
        series: {},
      },
      tooltip: {
        valuePrefix: '$',
        valueSuffix: '/mile',
        split: true,
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
      },
      series: [
        {
          name: 'Price Per Mile',
          type: 'column',
          color: '#D0D0D0',
          yAxis: 0,
        },
        {
          name: 'EMA 10 Day',
          type: 'spline',
          color: '#0096FF',
          yAxis: 0,
        },
        {
          name: 'EMA 30 Day',
          type: 'spline',
          color: '#088F8F',
          yAxis: 0,
        },
        {
          name: 'Benchmark',
          type: 'line',
          marker: {
            enabled: false,
          },
          color: '#EE4B2B',
          yAxis: 0,
        },
        {
          name: 'Loads/Drivers Ratio',
          type: 'spline',
          color: '#fdb0c0',
          lineWidth: 0,
          tooltip: {
            pointFormat: '{point.y}',
            valueSuffix: '',
            valuePrefix: 'Ratio: ',
          },
          marker: {
            symbol: 'circle',
          },
          yAxis: 1,
        },
        {
          name: '5 Day Moving Average Ratio',
          type: 'spline',
          tooltip: {
            pointFormat: '{point.y}',
            valueSuffix: '',
            valuePrefix: '5 Day Moving Average Ratio: ',
          },
          marker: {
            symbol: 'circle',
          },
          yAxis: 1,
        },
      ],
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DynamicPricingDialogComponent, {
      width: '250px',
      data: { benchmark: this.benchmark },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.benchmark = parseFloat(result);
      this.setBenchmark();
    });
  }

  private loadPriceGraphData(chart: Highcharts.Chart) {
    this.priceData$.subscribe((data) => {
      const seriesPrice = [];
      const seriesEMA10 = [];
      const seriesEMA30 = [];
      data.pricingHistory.forEach((datum) => {
        seriesPrice.push({
          y: datum.costPerMileCents / 100,
        });
        seriesEMA10.push({
          y: datum.costPerMileCentsEma10 / 100,
        });
        seriesEMA30.push({
          y: datum.costPerMileCentsEma30 / 100,
        });
      });

      const price = this.priceChart.ref.series[0];
      const EMA10 = this.priceChart.ref.series[1];
      const EMA30 = this.priceChart.ref.series[2];

      this.dataLength = seriesPrice.length;
      const seriesBenchmark = new Array<number>(this.dataLength);
      seriesBenchmark.fill(this.benchmark);
      const benchmark = this.priceChart.ref.series[3];

      price.setData(seriesPrice);
      EMA10.setData(seriesEMA10);
      EMA30.setData(seriesEMA30);
      benchmark.setData(seriesBenchmark);
    });

    this.ratioData$.subscribe((data) => {
      const labels: string[] = [];
      const ma = [];
      const ratioArray = [];
      let counter = 0;
      const colorFn = colorFunction('#fdb0c0', '#880808', data.sdHistory.map((d) => d.rateIncreasePercentage));
      const seriesRatioData = [];
      data.sdHistory.forEach((datum) => {
        labels.push(datum.date);
        seriesRatioData.push({
          y: Math.round(datum.orderShiftRatio * 100) / 100,
          color: colorFn(datum.rateIncreasePercentage),
        });
        ratioArray.push(datum.orderShiftRatio);
      });

      ratioArray.forEach((datum) => {
        if (ma.length < 4) {
          ma.push(Math.round(datum * 100) / 100);
        } else if (ma.length < 90) {
          let runningSum = 0;
          for (let i = 0; i < 4; i++) {
            runningSum += ratioArray[counter + i];
          }
          counter += 1;
          ma.push(Math.round(((runningSum + datum) / 5) * 100) / 100);
          if (ma.length === 90) {
            this.loading = false;
          }
        }
      });

      const seriesRatio = this.priceChart.ref.series[4];
      const maRatio = this.priceChart.ref.series[5];
      seriesRatio.setData(seriesRatioData);
      maRatio.setData(ma);
      chart.xAxis[0].setCategories(labels);
    });
  }

  private setBenchmark() {
    const seriesBenchmark = new Array<number>(this.dataLength);
    seriesBenchmark.fill(this.benchmark);
    const benchmarks = this.priceChart.ref.series[3];
    benchmarks.setData(seriesBenchmark);
  }

  private loadData() {
    this.regions$.pipe(take(1)).subscribe((data) => {
      this.params = { region: data.regions[0].regionId, box_technology: data.regions[0].boxTechId };
      this.priceData$ = this.dynamicPricingService.getPricingGraphData$(this.params);
      this.ratioData$ = this.dynamicPricingService.getRatioGraphData$(this.params);
      this.rateData$ = this.dynamicPricingService.getRatesData$(this.params);
      this.rateData$.subscribe((d) => {
        this.maxVariableIncrease = d.maxVariableRateIncrease;
        this.committedRates = d.committedRates;
        this.forecastedRates = d.forecastedRates;
      });
      this.priceChart.ref$.pipe(take(1)).subscribe((chart) => {
        this.loadPriceGraphData(chart);
      });
    });
  }

  private selectedRegion(region) {
    this.loading = true;
    this.selected = region.label;
    this.params = { region: region.regionId, box_technology: region.boxTechId };
    this.priceData$ = this.dynamicPricingService.getPricingGraphData$(this.params);
    this.ratioData$ = this.dynamicPricingService.getRatioGraphData$(this.params);
    this.rateData$ = this.dynamicPricingService.getRatesData$(this.params);
    this.rateData$.subscribe((d) => {
      this.maxVariableIncrease = d.maxVariableRateIncrease;
      this.committedRates = d.committedRates;
      this.forecastedRates = d.forecastedRates;
    });
    this.priceChart.ref$.pipe(take(1)).subscribe((chart) => {
      this.loadPriceGraphData(chart);
    });
  }
}
function hexToRgb(hex): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function colorFunction(startColor: string, endColor: string, values: number[]): (value: number) => string {
  const { min, max } = getMinMax(values);
  if (min === max) {
    return (value: number) => startColor;
  }
  const startRGB = hexToRgb(startColor);
  const endRGB = hexToRgb(endColor);
  const diffRed = endRGB.r - startRGB.r;
  const diffGreen = endRGB.g - startRGB.g;
  const diffBlue = endRGB.b - startRGB.b;

  return (value: number) => {
    const percent = (value - min) / (max - min);
    const valueDiffRed = diffRed * percent + startRGB.r;
    const valueDiffGreen = diffGreen * percent + startRGB.g;
    const valueDiffBlue = diffBlue * percent + startRGB.b;
    return `rgb(${Math.round(valueDiffRed)},${Math.round(valueDiffGreen)},${Math.round(valueDiffBlue)})`;
  };
}

function getMinMax(values: number[]): { min: number; max: number } {
  let min: number;
  let max: number;
  if (values.length === 0) {
    return { min: null, max: null };
  }
  values.forEach((value) => {
    if (min === undefined || value < min) {
      min = value;
    }
    if (max === undefined || value > max) {
      max = value;
    }
  });
  return {
    min,
    max,
  };
}
