<div class="mine-form-container">
  <h2 mat-dialog-title>
    {{ data ? 'Edit Mine' : 'Create New Mine' }}
  </h2>

  <form *ngIf="mineForm" [formGroup]="mineForm" (submit)="submit()" autocomplete="off">
    <div>
      <mat-form-field>
        <input matInput placeholder="Name" [formControl]="mineForm.controls['name']" />
        <mat-error *ngIf="mineForm.controls['name'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="radius (miles)" type="number" [formControl]="mineForm.controls['radius']" />
        <mat-error *ngIf="mineForm.controls['radius'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error *ngIf="mineForm.controls['radius'].hasError('max')">
          Radius cannot be more than 10
        </mat-error>
        <mat-error *ngIf="mineForm.controls['radius'].hasError('min')">
          Radius cannot be less than 0
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <input matInput placeholder="Latitude" type="number" [formControl]="mineForm.controls['lat']" />
        <mat-error *ngIf="mineForm.controls['lat'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error
          *ngIf="mineForm.controls['lat'].hasError('latitude') && !mineForm.controls['lat'].hasError('required')"
        >
          Latitude value should be between -90 and 90
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Longitude" type="number" [formControl]="mineForm.controls['lng']" />
        <mat-error *ngIf="mineForm.controls['lng'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error
          *ngIf="mineForm.controls['lng'].hasError('longitude') && !mineForm.controls['lng'].hasError('required')"
        >
          Longitude value should be between -180 and 180
        </mat-error>
      </mat-form-field>
    </div>

    <!--<div>-->
    <!--<mat-form-field>-->
    <!--<mat-select-->
    <!--placeholder="Outer Shape"-->
    <!--[formControl]="mineForm.controls['outerShapeName']">-->
    <!--<mat-option *ngFor="let shape of shapeNames" [value]="shape">{{shape}}</mat-option>-->
    <!--</mat-select>-->
    <!--<mat-error *ngIf="mineForm.controls['outerShapeName'].hasError('required')">-->
    <!--Input is required-->
    <!--</mat-error>-->
    <!--</mat-form-field>-->

    <!--<mat-form-field>-->
    <!--<mat-select-->
    <!--placeholder="Inner Shape"-->
    <!--[formControl]="mineForm.controls['innerShapeName']">-->
    <!--<mat-option *ngFor="let shape of shapeNames" [value]="shape">{{shape}}</mat-option>-->
    <!--</mat-select>-->
    <!--<mat-error *ngIf="mineForm.controls['innerShapeName'].hasError('required')">-->
    <!--Input is required-->
    <!--</mat-error>-->
    <!--</mat-form-field>-->
    <!--</div>-->

    <div id="map-mine"></div>

    <!--<div>-->
    <!--<ng-container *ngFor="let mesh of mineForm.controls['meshes'].value">-->
    <!--<mat-form-field *ngIf="mineForm.controls[mesh.type + ' amount']">-->
    <!--<input matInput placeholder="Daily Contracted {{mesh.type}} (lbs)" type="number"-->
    <!--[formControl]="mineForm.controls[mesh.type + ' amount']">-->
    <!--<mat-error *ngIf="mineForm.controls[mesh.type + ' amount'].hasError('required')">-->
    <!--Input is required-->
    <!--</mat-error>-->
    <!--<mat-error *ngIf="mineForm.controls[mesh.type + ' amount'].invalid && !mineForm.controls[mesh.type + ' amount'].hasError('required')">-->
    <!--Value cannot be negative-->
    <!--</mat-error>-->
    <!--</mat-form-field>-->
    <!--</ng-container>-->
    <!--</div>-->

    <!--<div>-->
    <!--<mat-form-field>-->
    <!--<input matInput placeholder="Number of Lanes" type="number" [formControl]="mineForm.controls['numOfLanes']">-->
    <!--<mat-error *ngIf="mineForm.controls['numOfLanes'].hasError('required')">-->
    <!--Input is required-->
    <!--</mat-error>-->
    <!--</mat-form-field>-->

    <!--<mat-form-field>-->
    <!--<input matInput placeholder="Load Time (min)" type="number" [formControl]="mineForm.controls['loadTime']">-->
    <!--<mat-error *ngIf="mineForm.controls['loadTime'].hasError('required')">-->
    <!--Input is required-->
    <!--</mat-error>-->
    <!--<mat-error *ngIf="mineForm.controls['loadTime'].invalid && !mineForm.controls['loadTime'].hasError('required')">-->
    <!--Value cannot be negative-->
    <!--</mat-error>-->
    <!--</mat-form-field>-->
    <!--</div>-->

    <div class="mine-direction-location-heading">
      <h3 class="sub-form-heading">Mine Direction Location</h3>
      <mat-slide-toggle [formControl]="mineForm.controls['showMineDirectionLocation']"></mat-slide-toggle>
    </div>
    <div class="mine-direction-location-help">
      Use where the entrance to the facility is. This location is provided to drivers for their use in getting
      directions from 3rd party applications.
    </div>
    <div id="map-mine-direction-location" [hidden]="!mineForm.controls['showMineDirectionLocation'].value"></div>
    <div *ngIf="mineForm.controls['showMineDirectionLocation'].value">
      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Latitude"
          type="number"
          [formControl]="mineForm.controls['mineDirectionLocationLat']"
        />
        <mat-error *ngIf="mineForm.controls['mineDirectionLocationLat'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error
          *ngIf="
            mineForm.controls['mineDirectionLocationLat'].hasError('latitude') &&
            !mineForm.controls['mineDirectionLocationLat'].hasError('required')
          "
        >
          Latitude value should be between -90 and 90
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Longitude"
          type="number"
          [formControl]="mineForm.controls['mineDirectionLocationLng']"
        />
        <mat-error *ngIf="mineForm.controls['mineDirectionLocationLng'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error
          *ngIf="
            mineForm.controls['mineDirectionLocationLng'].hasError('longitude') &&
            !mineForm.controls['mineDirectionLocationLng'].hasError('required')
          "
        >
          Longitude value should be between -180 and 180
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field style="margin-top: 24px;">
      <mat-label>
        Directions
      </mat-label>
      <textarea matInput formControlName="siteDirections"></textarea>
    </mat-form-field>
    <div class="details-row">
      <label>
        <input
          class="upload-button"
          [disabled]="uploadingPOAttachment$$.asObservable() | async"
          (change)="onFileChange($event)"
          type="file"
          accept="application/pdf, image/*"
        />
        <span class="material-icons">cloud_upload</span>
        <span *ngIf="!(uploadingPOAttachment$$.asObservable() | async)">Upload Attachments</span>
        <span *ngIf="uploadingPOAttachment$$.asObservable() | async">Uploading...</span>
      </label>
    </div>
    <mat-grid-list *ngIf="(uploadedFiles$$ | async) && (uploadedFiles$$ | async).length > 0" cols="2" rowHeight="3:2">
      <mat-grid-tile *ngFor="let file of uploadedFiles$$ | async">
        <div class="flex flex-row items-center">
          <mat-icon class="material-icons" [matTooltip]="'Delete'" (click)="deleteAttachment(file.id, data.id)"
            >close</mat-icon
          >
          <img [src]="file.url" *ngIf="isImage(file.fileName); else noPreview" (click)="openInWindow(file.url)" />
          <ng-template #noPreview>
            <div class="preview-placeholder" (click)="openInWindow(file.url)">
              <h3>{{ file.fileName }}</h3>
            </div>
          </ng-template>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <div class="actions" matDialogActions fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-flat-button color="primary" mat-dialog-close color="accent">Cancel</button>
      <button *ngIf="canEdit" mat-flat-button color="primary" type="submit" [disabled]="submitting">
        {{ submitting ? 'Submitting...' : ' Submit' }}
      </button>
    </div>
  </form>
</div>
