import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { UserService } from '~services/user.service';
import { EmailSettingsV2Service, MineForFilter } from '~services/email-settings-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { LoaderIntegrationStatsForDate } from '~models/emailSettings.model';
import { map, startWith, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ExportLoaderAnalyticsDataComponent } from './export-loader-analytics-data/export-loader-analytics-data.component';

@Component({
  selector: 'sa-loader-analytics-dashboard-v2',
  templateUrl: './loader-analytics-dashboard-v2.component.html',
  styleUrls: ['./loader-analytics-dashboard-v2.component.scss'],
})
export class LoaderAnalyticsDashboardV2Component implements OnInit, OnDestroy {
  public analyticsForm: FormGroup;
  displayNav = false;
  public isDownloading = false;

  activeDataSource: MatTableDataSource<LoaderIntegrationStatsForDate>;
  displayedColumns = ['date', 'loaderName', 'totalLoads', 'totalMatched', 'totalUnmatched'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;

  showDashboardHeaders = true;
  filterLoader: FormControl;
  filteredMines$: Observable<MineForFilter[]>;

  subscriptions: Subscription[] = [];

  public mines$: Observable<MineForFilter[]>;

  public analyticsForDates$: Observable<LoaderIntegrationStatsForDate[]>;

  public displayForm = false;
  public displayGRGIForm = false;

  public today: Date;

  constructor(
    private fb: FormBuilder,
    private emailSettingsService: EmailSettingsV2Service,
    private matDialog: MatDialog,
  ) {
    this.analyticsForDates$ = this.emailSettingsService.loaderAnalyticsData$;

    this.today = new Date();
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  }

  ngOnInit() {
    this.mines$ = this.emailSettingsService.minesForFilter$;
    this.emailSettingsService.loadMinesForFilter();

    this.subscriptions.push(
      this.mines$.pipe(take(1)).subscribe((mines) => {
        this.setupForm(mines);
      }),
    );
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;

    this.subscriptions.push(
      this.analyticsForDates$.subscribe((data) => {
        if (data) {
          this.activeDataSource.data = data;
        } else {
          this.activeDataSource.data = [];
        }

        this.isDownloading = false;
      }),
    );
  }

  private setupForm(initialMines: MineForFilter[]) {
    let startDate = new Date();
    let endDate = new Date();
    let mine = null;

    const currParameters = this.emailSettingsService.getEmailStatisticsDateRange();

    if (currParameters) {
      startDate = currParameters.startDate || startDate;
      endDate = currParameters.endDate || endDate;
      mine = initialMines.find((m) => m.id === currParameters.mineId) || null;
    }

    this.analyticsForm = this.fb.group({
      startDate: [startDate, [Validators.required]],
      endDate: [endDate, [Validators.required]],
      mine: [mine],
    });

    this.filterLoader = this.fb.control('');

    this.filteredMines$ = combineLatest([this.mines$, this.filterLoader.valueChanges.pipe(startWith(''))]).pipe(
      map(([mines, filterString]) => {
        if (filterString) {
          const filterValue = filterString.toLowerCase();
          const val = mines.filter(
            (option) =>
              option.name.toLowerCase().indexOf(filterValue) > -1 ||
              option.id === this.analyticsForm.controls['mine'].value?.id,
          );
          return val;
        } else {
          return mines;
        }
      }),
    );
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }

  getAnalytics() {
    this.isDownloading = true;

    const startTime = this.analyticsForm.controls['startDate'].value;
    startTime.setHours(0, 0, 0);

    const endTime = this.analyticsForm.controls['endDate'].value;
    endTime.setHours(23, 59, 59);

    const mineId = this.analyticsForm.controls['mine'].value?.id;

    this.emailSettingsService.setEmailStatisticsDateRange(startTime, endTime, mineId);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  openForm() {
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  openGRGIForm() {
    this.displayGRGIForm = true;
  }

  closeGRGIForm() {
    this.displayGRGIForm = false;
  }

  public async openExportForm() {
    this.matDialog.open(ExportLoaderAnalyticsDataComponent, { width: '800px' });
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }
}
