import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TruckingApiService {
  get vendorRoute(): string {
    return environment.api + '/vendor';
  }

  constructor(private crud: CrudService) {}

  getAllVendorContracts() {
    return this.crud.get(this.vendorRoute + '/contracts');
  }

  addVendorContract(body) {
    return this.crud.post(this.vendorRoute + '/contract', body);
  }

  getVendorContracts(id: number) {
    return this.crud.get(this.vendorRoute + '/' + id + '/contracts');
  }

  editVendorContract(id, body) {
    return this.crud.patch(this.vendorRoute + '/contract/' + id, body);
  }

  deleteVendorContract(id) {
    return this.crud.delete(this.vendorRoute + '/contract/' + id);
  }
}
