import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Fuse from 'fuse.js';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { Order, OrderSummary } from '~lmo/models/order.model';
import { AuthService } from '~services/auth.service';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../lmo-routing.constants';
import { LmoOrdersService } from './lmo-orders.service';

const searchOptions: Fuse.FuseOptions<OrderSummary> = {
  distance: 100,
  keys: [
    'truckName',
    'driverName',
    'loadNumber',
    'mineName',
    'bolNumber',
    'ticketNumber',
    'saUniqueId',
    'boxIds',
    'trailerId',
  ],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Injectable({
  providedIn: 'root',
})
export class LmoCompletedOrdersService {
  private currentFracId: number;
  private subscriptions: Subscription[] = [];
  private completedOrders$$: BehaviorSubject<Record<number, OrderSummary>> = new BehaviorSubject<
    Record<number, OrderSummary>
  >({});
  private cancelledOrders$$: BehaviorSubject<Record<number, OrderSummary>> = new BehaviorSubject<
    Record<number, OrderSummary>
  >({});
  private searchTerm$$: BehaviorSubject<string> = new BehaviorSubject('');

  private get searchTerm$(): Observable<string> {
    return this.searchTerm$$.asObservable().pipe(debounceTime(100), distinctUntilChanged());
  }

  private get completedOrdersArray$(): Observable<OrderSummary[]> {
    return this.completedOrders$$.asObservable().pipe(
      map((orders) => Object.keys(orders).map((id) => orders[id])),
      tap((arr) => arr.sort(sortByLoadNumberAsc)),
    );
  }

  private get cancelledOrdersArray$(): Observable<OrderSummary[]> {
    return this.cancelledOrders$$.asObservable().pipe(
      map((orders) => Object.keys(orders).map((id) => orders[id])),
      tap((arr) => arr.sort(sortByLoadNumberAsc)),
    );
  }

  public get completedOrders$(): Observable<OrderSummary[]> {
    return combineLatest(this.completedOrdersArray$.pipe(map(fuse)), this.searchTerm$).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.summaries;
        }
        return fused.fuse.search(searchTerm) as any;
      }),
    );
  }

  public get cancelledOrders$(): Observable<OrderSummary[]> {
    return combineLatest(this.cancelledOrdersArray$.pipe(map(fuse)), this.searchTerm$).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.summaries;
        }
        return fused.fuse.search(searchTerm) as any;
      }),
    );
  }

  public searchBy(filterTerm: string) {
    this.searchTerm$$.next(filterTerm);
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private routerState: RouterStateService,
    private ordersService: LmoOrdersService,
  ) {
    this.logout = this.logout.bind(this);
    this.setupAuthListener();
    this.subscribeToRouterFracIdChanges();
    this.subscribeToRouterDistributionCenterIdChanges();
    this.subscribeToOrderUpdates();
  }

  private setupAuthListener() {
    this.authService.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        filter((isLoggedIn) => !isLoggedIn),
      )
      .subscribe(this.logout);
  }

  private subscribeToRouterFracIdChanges() {
    combineLatest(
      this.routerState.listenForParamChange$(fromRouterConstants.FRAC_ID),
      this.routerState.listenForParamChange$(fromRouterConstants.ORDER_ID),
    ).subscribe(([fracId, orderId]) => {
      this.completedOrders$$.next({});
      this.cancelledOrders$$.next({});
      if (!fracId) {
        return;
      }
      this.currentFracId = +fracId;
      this.http
        .get<{ cancelledOrderSummaries: OrderSummary[]; completedOrderSummaries: OrderSummary[] }>(
          `${environment.api}/v2/lmo/frac/${fracId}/completed_orders`,
        )
        .subscribe((orders) => {
          const completedOrders = orders.completedOrderSummaries.reduce((record, orderSummary) => {
            record[orderSummary.id] = orderSummary;
            return record;
          }, {});
          const cancelledOrders = orders.cancelledOrderSummaries.reduce((record, orderSummary) => {
            record[orderSummary.id] = orderSummary;
            return record;
          }, {});

          this.completedOrders$$.next(completedOrders);
          this.cancelledOrders$$.next(cancelledOrders);
        });
    });
  }

  private subscribeToRouterDistributionCenterIdChanges() {
    combineLatest(
      this.routerState.listenForParamChange$(fromRouterConstants.DISTRIBUTION_CENTER_ID),
      this.routerState.listenForParamChange$(fromRouterConstants.ORDER_ID),
    ).subscribe(([dcID, orderId]) => {
      this.completedOrders$$.next({});
      this.cancelledOrders$$.next({});
      if (!dcID) {
        return;
      }
      this.currentFracId = +dcID;
      this.http
        .get<{ cancelledOrderSummaries: OrderSummary[]; completedOrderSummaries: OrderSummary[] }>(
          `${environment.api}/lmo/distribution_center/${dcID}/completed_orders`,
        )
        .subscribe((orders) => {
          const completedOrders = orders.completedOrderSummaries.reduce((record, orderSummary) => {
            record[orderSummary.id] = orderSummary;
            return record;
          }, {});
          const cancelledOrders = orders.cancelledOrderSummaries.reduce((record, orderSummary) => {
            record[orderSummary.id] = orderSummary;
            return record;
          }, {});

          this.completedOrders$$.next(completedOrders);
          this.cancelledOrders$$.next(cancelledOrders);
        });
    });
  }

  private subscribeToOrderUpdates() {
    this.ordersService.lastOrderUpdate$.subscribe((orders) => {
      // Update completed orders
      const newCompletedOrders = orders.filter(
        (order) => order.orderStatus === 'completed' && order.fracId === this.currentFracId,
      );
      const updatedCompletedOrders = { ...this.completedOrders$$.value };
      newCompletedOrders.forEach((order) => {
        updatedCompletedOrders[order.id] = convertOrderToOrderSummary(order);
      });
      this.completedOrders$$.next(updatedCompletedOrders);

      const newCancelledOrders = orders.filter(
        (order) => order.orderStatus === 'canceled' && order.fracId === this.currentFracId,
      );

      const updatedCancelledOrders = { ...this.cancelledOrders$$.value };
      newCancelledOrders.forEach((order) => {
        updatedCancelledOrders[order.id] = convertOrderToOrderSummary(order);
      });
      this.cancelledOrders$$.next(updatedCancelledOrders);
    });
  }

  private logout() {
    this.subscriptions.forEach((sub) => sub.unsubscribe);
    this.completedOrders$$.next({});
    this.cancelledOrders$$.next({});
  }
}

function sortByLoadNumberAsc(a: Order, b: Order): number {
  return a.loadNumber < b.loadNumber ? 1 : -1;
}

function fuse(
  summaries: OrderSummary[],
): { summaries: OrderSummary[]; fuse: Fuse<OrderSummary, Fuse.FuseOptions<any>> } {
  if (summaries && summaries.length > 0) {
    summaries.map((summary) => (summary.boxIds = summary.boxIds.toString()));
  }
  return {
    fuse: new Fuse(summaries, searchOptions),
    summaries,
  };
}

function convertOrderToOrderSummary(order: Order): OrderSummary {
  const boxNames = order.boxes ? order.boxes.map((box) => box.name) : [];
  const lockStatus = getLockStatus(order);
  const loadDetailsIncomplete = getLoadDetailsCompleteStatus(order);
  return {
    bolNumber: order.bolNumber,
    boxNames,
    destinationEta: '',
    destinationType: 'N/A',
    driverId: order.user ? order.user.id : null,
    driverName: order.user ? order.user.name : null,
    id: order.id,
    isLocked: lockStatus.isLocked,
    loadDetailsIncomplete: loadDetailsIncomplete.loadDetailsIncomplete,
    loadNumber: order.loadNumber,
    lockReason: lockStatus.lockReason,
    meshId: order.mesh ? order.mesh.id : null,
    meshName: order.mesh ? order.mesh.type : null,
    mineId: order.mine ? order.mine.id : null,
    mineName: order.mine && order.mine.site ? order.mine.site.name : null,
    missingLoadDetails: loadDetailsIncomplete.missingLoadDetails,
    // Need to go back and figure out this reroute logic.
    reroutedFromSiteName: null,
    reroutedToSiteName: null,
    saUniqueId: order.saUniqueId,
    status: order.orderStatus,
    ticketNumber: order.ticketNumber,
    time: order.endTimestamp,
    trailerName: order.trailer ? order.trailer.name : null,
    truckName: order.truck ? order.truck.name : null,
    truckingVendorId: order.vendor ? order.vendor.id : null,
    truckingVendorName: order.vendor ? order.vendor.name : null,
  };
}

const lockReasons = {
  approved: 'This load cannot be edited as it has already been billed.',
  approved_by_dispatcher: 'This load cannot be edited while waiting for ticket approval.',
  billed: 'This load cannot be edited while waiting for invoice approval.',
};

// Not a huge fan of copying server code to the client, might need to put this on the order.
function getLockStatus(order: Order): { isLocked: boolean; lockReason: string } {
  const lockReason = lockReasons[order.billingStatus];
  if (lockReason) {
    return {
      isLocked: true,
      lockReason,
    };
  }
  return {
    isLocked: false,
    lockReason: null,
  };
}

function getLoadDetailsCompleteStatus(order: Order): { loadDetailsIncomplete: boolean; missingLoadDetails: string[] } {
  const status = {
    loadDetailsIncomplete: false,
    missingLoadDetails: [],
  };
  if (order.orderStatus !== 'completed') {
    return status;
  }

  if (order.ticketNumber === '') {
    status.loadDetailsIncomplete = true;
    status.missingLoadDetails.push('Ticket Number');
  }

  if (order.bolNumber === '') {
    status.loadDetailsIncomplete = true;
    status.missingLoadDetails.push('BOL');
  }

  if (order.boxes && order.boxes.some((box) => !box.actualLoadWeight)) {
    status.loadDetailsIncomplete = true;
    status.missingLoadDetails.push('Box Weight');
  }

  if (order.boxes && order.boxes.some((box) => !box.actualLoadWeight)) {
    status.loadDetailsIncomplete = true;
    status.missingLoadDetails.push('Box Weight');
  }

  if (order.boxes && order.boxes.some((box) => !box.name || box.name === '')) {
    status.loadDetailsIncomplete = true;
    status.missingLoadDetails.push('Box ID');
  }
  return status;
}
