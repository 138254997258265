import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mapTo, pluck, switchMap, take, tap } from 'rxjs/operators';
import { isOrder, Order, OrderError } from '~models/order.model';
import { WellApiService } from './api/well.api.service';
import { AuthService } from './auth.service';
import { CrudService } from './crud.service';
import { environment } from '~environments/environment';
import { OrderApiService } from './api/order.api.service';
import { Frac } from '~models/frac.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlingService } from './error-handling.service';
import { LocalStorageService } from '~services/local-storage.service';
import { DistributionCenterApiService } from '~services/api/distribution-center.api.service';

interface State {
  dcs: Array<any>;
  fracs: Array<any>;
  unassignedTrucks: Array<any>;
  vendorRequests: Array<any>;
  truckRequests: Array<any>;
}

const state: State = {
  dcs: [],
  fracs: [],
  unassignedTrucks: [],
  vendorRequests: [],
  truckRequests: [],
};

@Injectable()
export class StoreService {
  private frac404s = {};
  private dc404s = {};
  private orders: BehaviorSubject<Record<number, Order | OrderError>> = new BehaviorSubject<Record<number, Order>>({});
  private singleOrder: BehaviorSubject<Order | OrderError> = new BehaviorSubject<Order | OrderError>(null);
  private fracAndLoadToOrderId: Record<number, Record<number, number>> = {};
  private dcAndLoadToOrderId: Record<number, Record<number, number>> = {};
  private subject = new BehaviorSubject<State>(state);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  set(name: string, stateObj: any) {
    this.subject.next({
      ...this.value,
      [name]: stateObj,
    });
    if (name === 'fracs') {
      // Can't think of a faster way to clear/merge this. Come back to this when time.
      const tempOrderBuilder = { ...this.orders.value };
      this.orders.next({});
      const fracs: { id: number; orders?: Order[] }[] = stateObj;
      fracs.forEach((frac) => {
        if (frac.orders) {
          frac.orders.forEach((order) => {
            // Add to orders
            tempOrderBuilder[order.id] = order;

            // Add to map so we can look it up quickly. Overwrites are ok as long as it exists.
            if (this.fracAndLoadToOrderId[frac.id]) {
              this.fracAndLoadToOrderId[frac.id][order.loadNumber] = order.id;
            } else {
              this.fracAndLoadToOrderId[frac.id] = {
                [order.loadNumber]: order.id,
              };
            }
          });
        }
      });
      this.orders.next(tempOrderBuilder);
    } else if (name === 'dcs') {
      const tempOrderBuilder = { ...this.orders.value };
      this.orders.next({});
      const dcs: { id: number; orders?: Order[] }[] = stateObj;
      dcs.forEach((dc) => {
        if (dc.orders) {
          dc.orders.forEach((order) => {
            // Add to orders
            tempOrderBuilder[order.id] = order;

            // Add to map so we can look it up quickly. Overwrites are ok as long as it exists.
            if (this.dcAndLoadToOrderId[dc.id]) {
              this.dcAndLoadToOrderId[dc.id][order.loadNumber] = order.id;
            } else {
              this.dcAndLoadToOrderId[dc.id] = {
                [order.loadNumber]: order.id,
              };
            }
          });
        }
      });
      this.orders.next(tempOrderBuilder);
    }
  }

  constructor(
    private wellApiService: WellApiService,
    private dcApiService: DistributionCenterApiService,
    private orderApiService: OrderApiService,
    private authService: AuthService,
    private crudService: CrudService,
    private snackBar: MatSnackBar,
    private errorService: ErrorHandlingService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
    // If the user logs out, clear out the entire store service.
    this.authService.getSocketEventListener().subscribe((event) => {
      if (event === 'logout') {
        this.clear();
      }
    });
  }

  select<T>(name: 'fracs' | 'unassignedTrucks' | 'vendorRequests' | 'truckRequests' | 'dcs'): Observable<T> {
    return this.store.pipe(pluck(name)) as any;
  }

  clear() {
    this.subject.next(state);
    this.fracAndLoadToOrderId = {};
    this.orders.next({});
  }

  getOrderForDriver(driver: { userStatus: { currentOrderId?: number } }): Order | OrderError | null {
    if (driver.userStatus.currentOrderId) {
      return this.orders.value[driver.userStatus.currentOrderId];
    }
    return null;
  }

  getOrderForId(orderId: number): Observable<Order | OrderError | null> {
    return this.orders.asObservable().pipe(
      map((record) => {
        if (record) {
          return record[orderId];
        }
        return null;
      }),
    );
  }

  getFracByOrderId(orderId: number): Observable<Frac | null> {
    return combineLatest(this.orders.asObservable(), this.select<Array<Frac>>('fracs')).pipe(
      filter(([orders, fracs]) => !!orders && !!fracs),
      map(([orders, fracs]) => {
        const order = orders[orderId];
        if (isOrder(order)) {
          if (fracs.length === 0) {
            return { id: order.fracId, site: { name: '' } };
          }
          return fracs.find((frac) => frac.id === order.fracId);
        }
        return null;
      }),
    );
  }

  getOrderByFracAndLoadNumber(fracId: number, loadNumber: number): Observable<Order | OrderError> {
    return this.orders.asObservable().pipe(
      map((orders) => {
        const orderId = (this.fracAndLoadToOrderId[fracId] && this.fracAndLoadToOrderId[fracId][loadNumber]) || null;
        if (orderId) {
          return orders[orderId];
        }
        return null;
      }),
      filter((order) => !!order),
    );
  }

  getOrderByDCAndLoadNumber(dcId: number, loadNumber: number): Observable<Order | OrderError> {
    return this.orders.asObservable().pipe(
      map((orders) => {
        const orderId = (this.dcAndLoadToOrderId[dcId] && this.dcAndLoadToOrderId[dcId][loadNumber]) || null;
        if (orderId) {
          return orders[orderId];
        }
        return null;
      }),
      filter((order) => !!order),
    );
  }

  getFracById(fracId: number): Observable<any> {
    return this.select<Array<any>>('fracs').pipe(
      map((fracs) => {
        if (fracs) {
          return fracs.find((frac) => frac.id === fracId);
        }
      }),
      filter((frac) => !!frac),
      distinctUntilChanged(),
    );
  }

  getDCById(dcId: number): Observable<any> {
    return this.select<Array<any>>('dcs').pipe(
      map((dcs) => {
        if (dcs) {
          return dcs.find((dc) => dc.id === dcId);
        }
      }),
      filter((dc) => !!dc),
      distinctUntilChanged(),
    );
  }

  loadSingleOrderForFrac(fracId: number, loadNumber: number): void {
    this.wellApiService.loadSingleOrder(fracId, loadNumber).subscribe(
      (order) => {
        // Add to the frac/loadnumber map
        if (this.fracAndLoadToOrderId[order.fracId]) {
          this.fracAndLoadToOrderId[order.fracId][order.loadNumber] = order.id;
        } else {
          this.fracAndLoadToOrderId[order.fracId] = {
            [order.loadNumber]: order.id,
          };
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [order.id]: order,
        });
        this.singleOrder.next(order);
      },
      (error) => {
        const orderError = new OrderError();
        if (error && error.status === 410) {
          orderError.isGone = true;
        }
        // We just need a unique number to store this error since it probably didn't come back with a real order id
        const fakeOrderId = -1 * new Date().getTime();
        if (this.fracAndLoadToOrderId[fracId]) {
          this.fracAndLoadToOrderId[fracId][loadNumber] = fakeOrderId;
        } else {
          this.fracAndLoadToOrderId[fracId] = {
            [loadNumber]: fakeOrderId,
          };
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [fakeOrderId]: orderError,
        });
        this.singleOrder.next(orderError);
      },
    );
  }

  loadSingleOrderForDC(dcId: number, loadNumber: number): void {
    this.dcApiService.loadSingleOrder(dcId, loadNumber).subscribe(
      (order) => {
        // Add to the frac/loadnumber map
        if (this.dcAndLoadToOrderId[order.distributionCenter.id]) {
          this.dcAndLoadToOrderId[order.distributionCenter.id][order.loadNumber] = order.id;
        } else {
          this.dcAndLoadToOrderId[order.distributionCenter.id] = {
            [order.loadNumber]: order.id,
          };
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [order.id]: order,
        });
        this.singleOrder.next(order);
      },
      (error) => {
        const orderError = new OrderError();
        if (error && error.status === 410) {
          orderError.isGone = true;
        }
        // We just need a unique number to store this error since it probably didn't come back with a real order id
        const fakeOrderId = -1 * new Date().getTime();
        if (this.dcAndLoadToOrderId[dcId]) {
          this.fracAndLoadToOrderId[dcId][loadNumber] = fakeOrderId;
        } else {
          this.dcAndLoadToOrderId[dcId] = {
            [loadNumber]: fakeOrderId,
          };
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [fakeOrderId]: orderError,
        });
        this.singleOrder.next(orderError);
      },
    );
  }

  loadSingleOrderByOrderId(orderId: number): void {
    this.orderApiService.getOrderById(orderId).subscribe(
      (order) => {
        // Add to the frac/loadnumber map
        if (this.fracAndLoadToOrderId[order.fracId]) {
          this.fracAndLoadToOrderId[order.fracId][order.loadNumber] = order.id;
        } else {
          this.fracAndLoadToOrderId[order.fracId] = {
            [order.loadNumber]: order.id,
          };
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [order.id]: order,
        });
      },
      (error) => {
        const orderError = new OrderError();
        if (error && error.status === 410) {
          orderError.isGone = true;
        }

        // Add to orders, overwriting if there is something newer
        this.orders.next({
          ...this.orders.value,
          [orderId]: orderError,
        });
      },
    );
  }

  loadSingleFrac(fracId: number): void {
    if (this.frac404s[fracId]) {
      return;
    }
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/v2/trucking_vendor/frac/${fracId}`)),
      )
      .subscribe(
        (singleFrac) => {
          const allFracs = this.subject.value.fracs || [];
          let inPlaceUpdate = false;
          const updatedFracs = allFracs.map((frac) => {
            if (frac.id === singleFrac.id) {
              inPlaceUpdate = true;
              return { ...singleFrac };
            }
            return frac;
          });
          if (!inPlaceUpdate) {
            updatedFracs.push(singleFrac);
          }
          this.set('fracs', updatedFracs);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.frac404s[fracId] = true;
          }
        },
      );
  }

  loadSingleDistributionCenter(dcId: number): void {
    if (this.dc404s[dcId]) {
      return;
    }
    this.crudService.httpClientReady
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/trucking_vendor/distribution_center/${dcId}`)),
      )
      .subscribe(
        (singleDc) => {
          const allDcs = this.subject.value.dcs || [];
          let inPlaceUpdate = false;
          const updatedDcs = allDcs.map((dc) => {
            if (dc.id === singleDc.id) {
              inPlaceUpdate = true;
              return { ...singleDc };
            }
            return dc;
          });
          if (!inPlaceUpdate) {
            updatedDcs.push(singleDc);
          }
          this.set('dcs', updatedDcs);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.dc404s[dcId] = true;
          }
        },
      );
  }

  private logFrac(frac, message) {
    let fracId = frac.id;
    if (message === 'ordersInProgress') {
      fracId = frac.fracId;
    }
    if (this.localStorageService.getItem('SOCKET_LOGGING')) {
      console.log(
        `${new Date()} message ${message} for frac ${fracId} Pending Orders: ${this.getPendingOrders(
          frac.orders,
        )} InProgress Orders: ${this.getInProgressOrders(frac.orders)}`,
      );
    }
  }

  private logAfterUpdate(data, message) {
    let fracId = data.id;
    if (message === 'ordersInProgress') {
      fracId = data.fracId;
    }
    const fracs: { id: number; orders: Order[] }[] = this.subject.value['fracs'] || [];
    const fracIndex = fracs.findIndex((f) => f.id === data.fracId);
    const frac = fracs[fracIndex];

    if (this.localStorageService.getItem('SOCKET_LOGGING') && frac) {
      console.log(
        `${new Date()} after update message ${message} for frac ${fracId} Pending Orders: ${this.getPendingOrders(
          frac.orders,
        )} InProgress Orders: ${this.getInProgressOrders(frac.orders)}`,
      );
    }
  }

  private getPendingOrders(orders: any[]) {
    return orders && orders.length > 0
      ? orders.filter((order) => order.orderStatus === 'pending' || order.orderStatus === 'driver_rejected').length
      : 0;
  }

  private getInProgressOrders(orders: any[]) {
    return orders && orders.length > 0 ? orders.filter((order) => order.orderStatus === 'driver_accepted').length : 0;
  }

  updateData(data) {
    if (data.objectType === 'frac' && data.channel === 'frac') {
      this.logFrac(data.body, data.channel);
      this.updateCompleteFrac(data.body);
      this.logAfterUpdate(data.body, data.channel);
    } else if (data.objectType === 'frac' && data.channel === 'trucksList') {
      this.logFrac(data.body, data.channel);
      this.updateOrder(data.body);
      this.logAfterUpdate(data.body, data.channel);
    } else if (data.objectType === 'fracOrders' && data.channel === 'ordersInProgress') {
      this.logFrac(data.body, data.channel);
      this.updateFracOrders(data.body);
      this.logAfterUpdate(data.body, data.channel);
    }
  }

  updateCompleteFrac(data) {
    // 0 is the default from server if ID can't be found.
    if (data && data.site && data.site.id !== 0) {
      let fracs = this.subject.value['fracs'] || [];
      const index = fracs.findIndex((frac) => frac.site.id === data.site.id);
      if (index === -1) {
        fracs = [...fracs, data];
      } else {
        fracs = [...fracs.slice(0, index), { ...data }, ...fracs.slice(index + 1)];
      }
      this.subject.next({
        ...this.subject.value,
        fracs,
      });

      // store orders
      let currentOrders = this.orders.value;
      if (data.orders) {
        (data as { orders: Order[] }).orders.forEach((order) => {
          currentOrders = {
            ...currentOrders,
            [order.id]: order,
          };
          if (this.fracAndLoadToOrderId[order.fracId]) {
            this.fracAndLoadToOrderId[order.fracId][order.loadNumber] = order.id;
          } else {
            this.fracAndLoadToOrderId[order.fracId] = {
              [order.loadNumber]: order.id,
            };
          }
        });
        this.orders.next(currentOrders);
      }
    }
  }

  updateOrder(data: { id: number; orders?: [Order] }) {
    if (data.orders && data.orders[0].id !== 0) {
      let currentOrders = this.orders.value;
      // Update orders
      if (data.orders) {
        data.orders.forEach((order) => {
          const orderAsStored = currentOrders[order.id];
          if (isOrder(orderAsStored)) {
            const existingOrder = {
              ...orderAsStored,
            };
            existingOrder.eta = order.eta || existingOrder.eta;
            if (existingOrder.user) {
              existingOrder.user.lastTimeSeen = order.user.lastTimeSeen || existingOrder.user.lastTimeSeen;
              existingOrder.user.activeSession = order.user.activeSession || existingOrder.user.activeSession;
              existingOrder.user.lastLngLat = order.user.lastLngLat || existingOrder.user.lastLngLat;
            }
            currentOrders = {
              ...currentOrders,
              [order.id]: existingOrder,
            };
          }
        });
        this.orders.next(currentOrders);
      }
      // Update fracs
      const fracs: { id: number; orders: Order[] }[] = this.subject.value['fracs'] || [];
      const fracIndex = fracs.findIndex((f) => f.id === data.id);
      if (fracIndex !== -1) {
        let selectedFrac = fracs[fracIndex];
        const updatedOrder = data.orders[0];
        const orderIndex = (selectedFrac.orders || []).findIndex((order) => order.id === updatedOrder.id);
        if (orderIndex !== -1) {
          const existingOrder = {
            ...selectedFrac.orders[orderIndex],
          };
          existingOrder.eta = updatedOrder.eta || existingOrder.eta;
          if (existingOrder.user) {
            existingOrder.user.lastTimeSeen = updatedOrder.user.lastTimeSeen || existingOrder.user.lastTimeSeen;
            existingOrder.user.activeSession = updatedOrder.user.activeSession || existingOrder.user.activeSession;
            existingOrder.user.lastLngLat = updatedOrder.user.lastLngLat || existingOrder.user.lastLngLat;
          }

          selectedFrac = {
            ...selectedFrac,
            orders: [
              ...selectedFrac.orders.slice(0, orderIndex),
              existingOrder,
              ...selectedFrac.orders.slice(orderIndex + 1),
            ],
          };
          // Set new fracs
          this.subject.next({
            ...this.subject.value,
            fracs: [...fracs.slice(0, fracIndex), selectedFrac, ...fracs.slice(fracIndex + 1)],
          });
        }
      }
    }
  }

  updateFracOrders(data) {
    const fracs: { id: number; orders: Order[] }[] = this.subject.value['fracs'] || [];
    const fracIndex = fracs.findIndex((f) => f.id === data.fracId);
    if (fracIndex !== -1) {
      const selectedFrac = {
        ...fracs[fracIndex],
        orders: data.orders,
      };
      // Set new fracs
      this.subject.next({
        ...this.subject.value,
        fracs: [...fracs.slice(0, fracIndex), selectedFrac, ...fracs.slice(fracIndex + 1)],
      });
    }
  }

  setOrder(order: Order): void {
    const existingOrders = this.orders.value;
    this.orders.next({
      ...existingOrders,
      [order.id]: order,
    });
    const frac = this.subject.value.fracs.find((f) => f.id === order.fracId);
    if (frac) {
      const updatedOrders = [...(frac.orders || [])];
      const thisOrderIndex = updatedOrders.find((o: Order) => o.id === order.id);
      if (thisOrderIndex === -1) {
        updatedOrders.push(order);
      } else {
        updatedOrders.splice(thisOrderIndex, 1, order);
      }

      const updatedFracs = (this.subject.value.fracs || []).map((f) => {
        if (f.id === frac.id) {
          return {
            ...frac,
            orders: updatedOrders,
          };
        }
        return f;
      });
      this.subject.next({
        ...this.subject.value,
        fracs: updatedFracs,
      });
    }
  }

  patchOrder(id: number, partial: Partial<Order>): Observable<boolean> {
    return this.orderApiService.patchOrder(id, partial).pipe(
      tap((response: Partial<Order>) => {
        const existingOrder = this.orders.value[response.id];
        if (isOrder(existingOrder)) {
          const updatedOrder: Order = { ...existingOrder, ...response };
          this.setOrder(updatedOrder);
        }
      }),
      tap(() => {
        this.snackBar.open('Order Updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  markBoxPickedUp(id: number): Observable<boolean> {
    return this.orderApiService.markBoxPickedUp(id).pipe(
      tap((response: Partial<Order>) => {
        const existingOrder = this.orders.value[response.id];
        if (isOrder(existingOrder)) {
          existingOrder.boxPickup.beforeOrder = response.boxPickup.beforeOrder;
          existingOrder.boxPickup.completed = response.boxPickup.completed;
          this.setOrder(existingOrder);
        }
      }),
      tap(() => {
        this.snackBar.open('Order Updated', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  markPreload(id: number, notes: string): Observable<boolean> {
    return this.orderApiService.markPreload(id, notes).pipe(
      tap((response: Partial<Order>) => {
        const existingOrder = this.orders.value[response.id];
        if (isOrder(existingOrder)) {
          const updatedOrder: Order = { ...existingOrder, ...response };
          this.setOrder(updatedOrder);
        }
      }),
      tap(() => {
        this.snackBar.open('Order marked as Preload', null, { duration: 5000 });
      }),
      mapTo(true),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.errorService.showError(error);
        return of(false);
      }),
    );
  }

  public reloadFracList(): void {
    this.http.get<Frac[]>(`${environment.api}/frac`).subscribe((fracs) => {
      this.set('fracs', fracs);
    });
  }

  getSingleOrder(): Observable<Order | OrderError> {
    return this.singleOrder.asObservable();
  }
}
