import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Data, EmailDateTime } from '~models/index';
import { share } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EmailDateTimeService {
  private get route(): string {
    return environment.api + '/email_date_time';
  }

  private emailDateTimes$$ = new BehaviorSubject<Data<EmailDateTime[]>>({
    data: [],
    meta: {
      loaded: false,
      loading: false,
    },
  });

  public get emailDateTimes$(): Observable<Data<EmailDateTime[]>> {
    return this.emailDateTimes$$.asObservable().pipe(share());
  }

  constructor(private http: HttpClient) {
    this.loadingComplete = this.loadingComplete.bind(this);
    this.starLoading();
  }

  private loadingOn() {
    const current = this.emailDateTimes$$.value;
    this.emailDateTimes$$.next({
      ...current,
      meta: {
        ...current.meta,
        loading: true,
      },
    });
  }

  private loadingComplete(data: EmailDateTime[]) {
    const current = this.emailDateTimes$$.value;
    this.emailDateTimes$$.next({
      ...current,
      data,
      meta: {
        ...current.meta,
        loading: false,
        loaded: true,
      },
    });
  }

  private starLoading() {
    this.loadingOn();
    this.http.get(this.route).subscribe(this.loadingComplete, () => {
      this.loadingComplete([]);
    });
  }
}
