import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RunboardLogisticsCluster, UnclusteredRunboardSites } from '~dispatcher/models/frac.model';
import { DispatcherFracsService } from '~dispatcher/services/dispatcher-fracs.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-dispatcher-frac-list-summary',
  styleUrls: ['./dispatcher-frac-list-summary.component.scss'],
  templateUrl: './dispatcher-frac-list-summary.component.html',
})
export class DispatcherFracListSummaryComponent implements OnInit {
  private fracLogClusters$: Observable<(RunboardLogisticsCluster | UnclusteredRunboardSites)[]>;
  public pendingCount$: Observable<number>;
  public upForGrabsCount$: Observable<number>;
  public actionNeededCount$: Observable<number>;

  constructor(private fracsService: DispatcherFracsService) {}

  public ngOnInit() {
    this.fracLogClusters$ = this.fracsService.fracClusterSummaries$;
    this.setupPendingCount();
    this.setupUpForGrabsCount();
    this.setupActionNeededCount();
  }

  private setupPendingCount() {
    this.pendingCount$ = this.fracLogClusters$.pipe(
      map((clusters) => {
        return clusters.reduce((allPendingCount, cluster) => {
          return (
            allPendingCount +
            cluster.siteSummaries.reduce((clusterPendingCount, summary) => {
              return clusterPendingCount + summary.pendingLoadCount;
            }, 0)
          );
        }, 0);
      }),
    );
  }

  private setupUpForGrabsCount() {
    this.upForGrabsCount$ = this.fracLogClusters$.pipe(
      map((clusters) => {
        return clusters.reduce((allPendingCount, cluster) => {
          return (
            allPendingCount +
            cluster.siteSummaries.reduce((upForGrabsCount, summary) => {
              return upForGrabsCount + summary.upForGrabsLoadCount;
            }, 0)
          );
        }, 0);
      }),
    );
  }

  private setupActionNeededCount() {
    this.actionNeededCount$ = this.fracLogClusters$.pipe(
      map((clusters) => {
        return clusters.reduce((allPendingCount, cluster) => {
          return (
            allPendingCount +
            cluster.siteSummaries.reduce((actionNeededCount, summary) => {
              return actionNeededCount + summary.actionNeededCount;
            }, 0)
          );
        }, 0);
      }),
    );
  }
}
