import { Component, Input, OnInit } from '@angular/core';
import { Order } from '~models/order.model';

@Component({
  selector: 'sa-dc-order-direction-pill',
  templateUrl: './dc-order-direction-pill.component.html',
  styleUrls: ['./dc-order-direction-pill.component.scss'],
})
export class DcOrderDirectionPillComponent implements OnInit {
  @Input() public order: Order;

  constructor() {}

  ngOnInit(): void {}
}
