<div class="nav-sand-vendor">
  <ul class="top-nav nav-list font-medium no-padding nav-sand-vendor__top">
    <li>
      <a routerLink="/sand-vendor" routerLinkActive="true" saAutomatedSelector="menu__spot-market">
        Spot Market
      </a>
    </li>
    <li>
      <a routerLink="/sand-vendor/exclusion-list" routerLinkActive="true" saAutomatedSelector="menu__exclusion_ist">
        Exclusion List
      </a>
    </li>

    <!-- <li>
      <a routerLink="/loaders" routerLinkActive="true" saAutomatedSelector="menu__loading-facilities"
        >Loading Facilities</a
      >
    </li> -->

    <li>
      <a routerLink="/users" routerLinkActive="true" saAutomatedSelector="menu__users">Users</a>
    </li>

    <li>
      <a routerLink="/notifications" routerLinkActive="true" saAutomatedSelector="menu__notification-preferences"
        >Notification Preferences</a
      >
    </li>
    <li>
      <a routerLink="/sand-vendor/training-videos" routerLinkActive="true" saAutomatedSelector="menu__training_videos"
        >Training Videos</a
      >
    </li>
    <li style="flex: 1"></li>

    <p class="info-text">Download Our Other Apps</p>
  </ul>

  <ul class="bottom-nav nav-list font-medium no-padding">
    <li>
      <a (click)="goToSandi()"
        >SANDi<sup style="font-size: .5em;font-weight: 600;">TM</sup> for iPhone & iPad
        <i class="material-icons arrow-text">
          arrow_right_alt
        </i></a
      >
    </li>
    <li>
      <a (click)="goToGoHawkAndroid()"
        >GoHawk for Android
        <i class="material-icons arrow-text">
          arrow_right_alt
        </i></a
      >
    </li>
    <li>
      <a (click)="goToGoHawkIOS()"
        >GoHawk for iPhone & iPad
        <i class="material-icons arrow-text">
          arrow_right_alt
        </i></a
      >
    </li>
    <p class="info-text">Help</p>
  </ul>

  <ul class="bottom-nav nav-list font-medium">
    <li>
      <a href="mailto:help@shaleapps.com"
        >Email the ShaleApps Help Team
        <i class="material-icons arrow-text">
          arrow_right_alt
        </i></a
      >
    </li>
  </ul>

  <div class="nav-list font-medium">
    <div class="user">
      <div class="user-info font-medium">
        <div class="user-email">
          {{ userLabel.email || userLabel.name || 'Unknown' }} <span *ngIf="isAdmin" class="admin">(Admin)</span>
        </div>
        <div class="user-company">{{ userLabel.account || 'Unknown' }}</div>
      </div>
      <img class="logout" src="../../../assets/icons/signout.svg" alt="signout" (click)="logout()" />
    </div>
  </div>
</div>
