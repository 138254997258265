import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud.service';
import { Observable, throwError } from 'rxjs';
import { RunboardSummary } from 'src/app/ui-components/jobs-view/frac-detail/runboard-summary.model';
import { FeatureFlagService } from '../feature-flag.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Order } from '~models/order.model';
import { DriverCertificate, TrailerType } from '~v2Models/well.model';

@Injectable()
export class WellApiService {
  get wellRoute(): string {
    return environment.api + '/frac';
  }
  get archivedWellRoute(): string {
    return environment.api + '/frac/archived';
  }
  get storageRoute(): string {
    return environment.api + '/sand/storage';
  }
  get vendorRoute(): string {
    return environment.api + '/frac_vendor';
  }
  get clusterRoute(): string {
    return environment.api + '/frac_cluster';
  }
  get clusterTruckingRoute(): string {
    return environment.api + '/frac_cluster_trucking_vendor';
  }
  get driverCertificationsRoute(): string {
    return environment.api + '/driver_certifications';
  }
  get trailerTypesRoute(): string {
    return environment.api + '/trailer_types';
  }

  constructor(private crud: CrudService, private featureFlagService: FeatureFlagService) {}

  getWells() {
    return this.crud.get(this.wellRoute);
  }

  getPaginatedWells(params?: { page: string; size: string }) {
    return this.crud.get(this.wellRoute + '/archived/paginated', params);
  }

  getDriverCertifications(): Observable<DriverCertificate[]> {
    return this.crud.get(this.driverCertificationsRoute);
  }

  getTrailerTypes(): Observable<TrailerType[]> {
    return this.crud.get(this.trailerTypesRoute);
  }

  getArchivedWells() {
    return this.crud.get(this.archivedWellRoute);
  }

  getWell(id) {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(this.wellRoute + '/' + id)),
    );
  }

  getDistricts() {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${environment.api}/lmo/districts`)),
    );
  }

  getWellMinimal(id) {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(this.wellRoute + '/' + id + '/minimal')),
    );
  }

  getAllFracs() {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.wellRoute}/minimal_fracs`)),
    );
  }

  getAllOrderCounts(params) {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.wellRoute}/order_counts`, params)),
    );
  }

  getWellForBilling(id: number) {
    return this.crud.get(`${this.wellRoute}/${id}/for_billing`);
  }

  getFrac(id): Observable<Array<any>> {
    return this.crud.get(this.wellRoute + '/' + id + '/orders');
  }

  getArchiveEligibility(id): Observable<boolean> {
    return this.crud.get(this.wellRoute + '/' + id + '/check_archive_eligibility');
  }

  getOrdersForwardStatus(body): Observable<any> {
    return this.crud.post(`${this.wellRoute}/tooltip`, body);
  }

  getCompletedFrac(id, params): Observable<any> {
    return this.crud.get(this.wellRoute + '/' + id + '/completed_orders', params);
  }

  getBoxTechnologies(): Observable<{ id: number; name: string }[]> {
    return this.crud.get(this.storageRoute + '/box_technologies').pipe(map((value) => value.technologies));
  }

  getAllCompletedFrac(params): Observable<any> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.wellRoute}/completed_orders`, params)),
    );
  }

  activateWell(id) {
    return this.crud.post(this.wellRoute + '/' + id + '/activate');
  }

  archiveWell(id) {
    return this.crud.post(this.wellRoute + '/' + id + '/archive');
  }

  updateWell(id, body) {
    return this.crud.put(this.wellRoute + '/' + id, body);
  }

  createWell(well) {
    return this.crud.post(this.wellRoute, well);
  }

  getStorages() {
    return this.crud.get(this.storageRoute);
  }

  getStorage(id) {
    return this.crud.get(this.storageRoute + '/' + id);
  }

  createStorage(body) {
    return this.crud.post(this.storageRoute, body);
  }

  updateStorage(id, body) {
    return this.crud.put(this.storageRoute + '/' + id, body);
  }

  updateStorageSupplementalMesh(id, body) {
    return this.crud.put(this.storageRoute + '/' + id + '/supplemental_mesh', body);
  }

  downloadRecordUrl(id) {
    return this.crud.get(this.wellRoute + '/' + id + '/csvurl');
  }

  downloadVendorRecords(id) {
    return this.crud.get(this.wellRoute + '/' + id + '/vendor_csvdata');
  }

  downloadVendorRecordsUrl(id) {
    return this.crud.get(this.wellRoute + '/' + id + '/vendor_csvurl');
  }

  downloadVendorRecordsUrlForInvoice(id, invoiceNumber) {
    return this.crud.get(`${this.wellRoute}/${id}/vendor_csvurl/${invoiceNumber}`);
  }

  exportInvoices(id, body) {
    if (!id || isNaN(id)) {
      return throwError(new Error(`fracId: ${id} not valid`));
    }

    return this.crud.post(`${this.wellRoute}/${id}/export_invoice_orders`, body);
  }

  downloadReconcileRecrods(id) {
    return this.crud.get(this.wellRoute + '/' + id + '/reconciledata');
  }

  createCluster(body) {
    return this.crud.post(this.clusterRoute, body);
  }

  removeCluster(body) {
    return this.crud.patch(this.clusterRoute + '/remove', body);
  }

  addToCluster(id, body) {
    return this.crud.patch(this.clusterRoute + '/' + id + '/add', body);
  }

  createFracVendor(body) {
    return this.crud.post(this.clusterTruckingRoute, body);
  }

  getFracVendor() {
    return this.crud.get(this.clusterTruckingRoute);
  }

  editFracVendor(id, body) {
    return this.crud.patch(this.clusterTruckingRoute + '/' + id, body);
  }

  bidRequest(id, body) {
    return this.crud.patch(this.clusterTruckingRoute + '/' + id + '/bid', body);
  }

  rejectRequest(id) {
    return this.crud.patch(this.clusterTruckingRoute + '/' + id + '/reject', null);
  }

  awardRequest(id, body) {
    return this.crud.patch(this.clusterTruckingRoute + '/' + id + '/award', body);
  }

  declineFracVendor(id) {
    return this.crud.patch(this.clusterTruckingRoute + '/' + id + '/decline', null);
  }

  getInvoices(id, params?) {
    return this.crud.get(this.wellRoute + '/' + id + '/paginated_bills', params);
  }

  getInvoicesForAllFrac(params?) {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.wellRoute}/paginated_bills`, params)),
    );
  }

  getInvoicedOrders(id, params?) {
    return this.crud.get(this.wellRoute + '/' + id + '/invoiced_orders', params);
  }

  approveInvoiceChanges(id, body): Observable<any> {
    return this.crud.post(this.wellRoute + '/' + id + '/approve_invoiced_orders', body);
  }

  approveInvoiceChangesForAllFrac(id, body): Observable<any> {
    return this.crud.post(`${this.wellRoute}/approve_invoiced_orders`, body);
  }

  rejectInvoiceChanges(id, body): Observable<any> {
    return this.crud.post(this.wellRoute + '/' + id + '/reject_invoiced_orders', body);
  }

  rejectInvoiceChangesForAllFrac(id, body): Observable<any> {
    return this.crud.post(`${this.wellRoute}/reject_invoiced_orders`, body);
  }

  rejectApprovedInvoices(id, body): Observable<any> {
    return this.crud.post(this.wellRoute + '/' + id + '/unapprove_invoiced_orders', body);
  }

  rejectApprovedInvoicesForAllFrac(body): Observable<any> {
    return this.crud.post(`${this.wellRoute}/unapprove_invoiced_orders`, body);
  }

  getCSVDataForOrder(fracId, orderId): Observable<any> {
    return this.crud.get(this.wellRoute + '/' + fracId + '/' + orderId + '/csvdata');
  }

  getImportedDataForOrder(fracId, orderId): Observable<any> {
    return this.crud.get(this.wellRoute + '/' + fracId + '/' + orderId + '/importedurl');
  }

  getRunboardSummary(fracClusterId): Observable<RunboardSummary> {
    return this.crud.get(`${this.clusterRoute}/${fracClusterId}/run_board`);
  }

  loadSingleOrder(fracId: number, loadNumber: number): Observable<Order> {
    return this.crud.httpClientReady.pipe(
      filter((ready) => ready),
      take(1),
      switchMap(() => this.crud.get(`${this.wellRoute}/${fracId}/load/${loadNumber}`)),
    );
  }

  getFracTrailerType(id): Observable<Array<any>> {
    return this.crud.get(this.wellRoute + '/' + id + '/requiredTrailerType');
  }
}
