import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ValidateRouteService implements HttpInterceptor {
  environmentsToCheck = ['dev', 'stage'];

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes('sand.shaleapps.com/api') &&
      this.environmentsToCheck.includes(environment.featureFlagKey)
    ) {
      const expectedUrl = environment.api;
      if (!request.url.startsWith(expectedUrl)) {
        return throwError('Code: 0xur1b3n');
      }
    }
    // Let the request keep going if nothing is wrong or not a shaleapps domain or on prod
    return next.handle(request);
  }
}
