<div class="driver-detail-view-container">
  <sa-subnav [subnavMenu]="subnavMenu"></sa-subnav>

  <div class="content">
    <div class="trips" *ngIf="activeMenu === this.subnavLabels.trips">
      <mat-calendar [dateFilter]="dateFilter" [selected]="selectedDate" (selectedChange)="selectDate($event)">
      </mat-calendar>
    </div>
  </div>
</div>
