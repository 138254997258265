import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tooltipTime',
})
export class TooltipTimestampPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any): any {
    const timestamp = new Date(value).getTime();

    if (timestamp > new Date(new Date().getTime() - 60 * 60 * 24 * 1000).getTime()) {
      return this.datePipe.transform(timestamp, 'shortTime');
    } else {
      return this.datePipe.transform(timestamp, 'short');
    }
  }
}
