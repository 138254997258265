import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order, OrderError, isOrder } from '~models/order.model';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, filter, take, switchMap } from 'rxjs/operators';
import { StoreService } from 'src/app/services/store.service';
import { Frac } from '~models/frac.model';
import { OrderApiService } from 'src/app/services/api/order.api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { TrucksService } from 'src/app/trucks.service';

@Component({
  selector: 'sa-reassign-truck',
  templateUrl: './reassign-truck.component.html',
  styleUrls: ['./reassign-truck.component.scss'],
})
export class ReassignTruckComponent implements OnInit {
  public order$: Observable<Order | OrderError>;
  public frac$: Observable<Frac>;
  public isOrder = isOrder;
  public isLoading = false;
  public existingOrderLink$: Observable<Array<string | number>>;

  constructor(
    private dialogRef: MatDialogRef<ReassignTruckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private storeService: StoreService,
    private orderApiService: OrderApiService,
    private errorHandler: ErrorHandlingService,
    private truckService: TrucksService,
  ) {}

  ngOnInit() {
    if (this.data) {
      this.storeService.loadSingleOrderByOrderId(this.data);
      this.order$ = this.storeService.getOrderForId(this.data);
      this.frac$ = this.order$.pipe(
        filter((order) => isOrder(order)),
        take(1),
        tap((order: Order) => this.storeService.loadSingleFrac(order.fracId)),
        switchMap((order: Order) => this.storeService.getFracById(order.fracId)),
      );
      this.existingOrderLink$ = combineLatest(this.order$, this.frac$).pipe(
        filter(([order, frac]) => !!order && !!frac),
        map(([order, frac]: [Order, Frac]) => [
          '/',
          'map',
          'jobs',
          'detail',
          frac.id,
          'progress',
          'order',
          order.loadNumber,
        ]),
      );
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  unassign() {
    this.isLoading = true;
    this.orderApiService.unAssignOrderById(this.data).subscribe(
      (res) => {
        this.truckService.reloadTruckResults(false);
        this.dialogRef.close(true);
        this.isLoading = false;
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
        this.isLoading = false;
      },
    );
  }

  complete() {
    this.isLoading = true;
    this.orderApiService.completeOrderById(this.data).subscribe(
      (res) => {
        this.truckService.reloadTruckResults(false);
        this.dialogRef.close(true);
        this.isLoading = false;
      },
      (err) => {
        this.errorHandler.showError(err, 5000);
        this.isLoading = false;
      },
    );
  }
}
