import { Injectable } from '@angular/core';
import { CrudService } from '~services/crud.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, take, switchMap, map, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { ErrorHandlingService } from '~services/error-handling.service';
import * as moment from 'moment';
import { AuthService } from '~services/auth.service';
import { UserService } from '~services/user.service';

// Using this for both frac cluster and distribution center trucking requests
// For FCTV requests, the DC parameters will be null, and vice-versa
export interface TruckingRequestFromServer {
  id: number;
  fracClusterId?: number;
  fracClusterName?: string;
  distributionCenterId?: number;
  distributionCenterName?: string;
  lmoId: number;
  lmoName: string;
  truckingVendorId: number;
  truckingVendorName: string;
  startTimestamp: string;
  endTimestamp: string;
  status: 'pending' | 'awarded' | 'rejected';
  statusTimestamp: string;
  bidNumberOfTrucks: string;
  bidTimestamp: string;
  createdTimestamp: string;
  updatedTimestamp: string;
  allocationPercent: number;
  currentTruckPoolAllocationPercent: number;
  driversInPool: number;
  fracSites?: {
    id: number;
    name: string;
    lngLat: [number, number];
  }[];
  distributionCenterSite?: {
    id: number;
    name: string;
    lngLat: [number, number];
  };
  truckingVendorPoolSizeMessage: string;
  truckPoolSize: number;
  description: string;
  contractType?: string;
}

export interface TruckingRequest extends TruckingRequestFromServer {
  humanReadableStatusTime: string;
}

export interface PendingTruckingRequest extends TruckingRequest {
  status: 'pending';
}

export interface AwardedTruckingRequest extends TruckingRequest {
  status: 'awarded';
}

@Injectable({
  providedIn: 'root',
})
export class TruckingRequestVendorService {
  private allFCTVRequests$$: BehaviorSubject<TruckingRequest[]> = new BehaviorSubject([]);
  private allDCTVRequests$$: BehaviorSubject<TruckingRequest[]> = new BehaviorSubject([]);
  private pendingRequests$$: BehaviorSubject<PendingTruckingRequest[]> = new BehaviorSubject([]);
  private awardedRequests$$: BehaviorSubject<AwardedTruckingRequest[]> = new BehaviorSubject([]);

  public get pendingRequests$(): Observable<PendingTruckingRequest[]> {
    return this.pendingRequests$$.asObservable();
  }

  public get awardedRequests$(): Observable<AwardedTruckingRequest[]> {
    return this.awardedRequests$$.asObservable();
  }

  public getFCTVRequestById(fctvId: number): Observable<TruckingRequest> {
    return this.allFCTVRequests$$.asObservable().pipe(
      map((requests) => {
        for (const request of requests) {
          if (request.id === fctvId) {
            return request;
          }
        }
      }),
    );
  }

  public getDCTVRequestById(dctvId: number): Observable<TruckingRequest> {
    return this.allDCTVRequests$$.asObservable().pipe(
      map((requests) => {
        for (const request of requests) {
          if (request.id === dctvId) {
            return request;
          }
        }
      }),
    );
  }

  constructor(private crudService: CrudService, private authService: AuthService, userService: UserService) {
    this.updateOneFCTVRequest = this.updateOneFCTVRequest.bind(this);
    this.updateOneDCTVRequest = this.updateOneDCTVRequest.bind(this);
    this.addOneFCTVRequest = this.addOneFCTVRequest.bind(this);
    this.addOneDCTVRequest = this.addOneDCTVRequest.bind(this);

    combineLatest([this.allFCTVRequests$$.asObservable(), this.allDCTVRequests$$.asObservable()]).subscribe(
      ([fctvRequests, dctvRequests]) => {
        this.replaceAllRequests(fctvRequests || [], dctvRequests || []);
      },
    );

    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if (userService.isDispatcherAccount()) {
          this.getFCTVRequests();
          this.getDCTVRequests();
        }
      } else {
        this.allFCTVRequests$$.next([]);
        this.allDCTVRequests$$.next([]);
      }
    });
  }

  public declineFCTVRequest(requestId: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() =>
          this.crudService.patch(`${environment.api}/frac_cluster_trucking_vendor/${requestId}/reject`, {}),
        ),
      )
      .subscribe(this.updateOneFCTVRequest);
  }

  public declineDCTVRequest(requestId: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() =>
          this.crudService.patch(`${environment.api}/distribution_center_trucking_vendor/${requestId}/reject`, {}),
        ),
      )
      .subscribe(this.updateOneDCTVRequest);
  }

  public acceptFCTVRequest(requestId: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() =>
          this.crudService.post(`${environment.api}/frac_cluster_trucking_vendor/${requestId}/vendor_award`, {}),
        ),
      )
      .subscribe(this.updateOneFCTVRequest);
  }

  public acceptDCTVRequest(requestId: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() =>
          this.crudService.post(`${environment.api}/distribution_center_trucking_vendor/${requestId}/vendor_award`, {}),
        ),
      )
      .subscribe(this.updateOneDCTVRequest);
  }

  public getFCTVRequests() {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/frac_cluster_trucking_vendor`)),
      )
      .subscribe((requests) => this.allFCTVRequests$$.next(requests));
  }

  public getDCTVRequests() {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/distribution_center_trucking_vendor`)),
      )
      .subscribe((requests) => this.allDCTVRequests$$.next(requests));
  }

  private replaceAllRequests(fctvRequests: TruckingRequestFromServer[], dctvRequests: TruckingRequestFromServer[]) {
    fctvRequests = fctvRequests || [];
    dctvRequests = dctvRequests || [];

    const pending: PendingTruckingRequest[] = [];
    const awarded: AwardedTruckingRequest[] = [];
    fctvRequests
      .map(serverToLocalRequest)
      .filter(isPendingOrAcceptedRequest)
      .forEach((request) => {
        if (isPendingRequest(request)) {
          pending.push(request);
        } else {
          awarded.push(request);
        }
      });

    dctvRequests
      .map(serverToLocalRequest)
      .filter(isPendingOrAcceptedRequest)
      .forEach((request) => {
        if (isPendingRequest(request)) {
          pending.push(request);
        } else {
          awarded.push(request);
        }
      });

    pending.sort((a, b) => moment(a.updatedTimestamp).diff(moment(b.updatedTimestamp)));
    awarded.sort((a, b) => moment(a.updatedTimestamp).diff(moment(b.updatedTimestamp)));

    this.pendingRequests$$.next(pending);
    this.awardedRequests$$.next(awarded);
  }

  private updateOneFCTVRequest(serverRequest: TruckingRequestFromServer): void {
    const request = serverToLocalRequest(serverRequest);
    const currentArray = this.allFCTVRequests$$.value;
    const index = currentArray.findIndex((r) => r.id === serverRequest.id);
    if (index !== -1) {
      const newArray = [...currentArray.slice(0, index), request, ...currentArray.slice(index + 1)];
      this.allFCTVRequests$$.next(newArray);
    } else {
      this.addOneFCTVRequest(serverRequest);
    }
  }

  private updateOneDCTVRequest(serverRequest: TruckingRequestFromServer): void {
    const request = serverToLocalRequest(serverRequest);
    const currentArray = this.allDCTVRequests$$.value;
    const index = currentArray.findIndex((r) => r.id === serverRequest.id);
    if (index !== -1) {
      const newArray = [...currentArray.slice(0, index), request, ...currentArray.slice(index + 1)];
      this.allDCTVRequests$$.next(newArray);
    } else {
      this.addOneDCTVRequest(serverRequest);
    }
  }

  private addOneFCTVRequest(serverRequest: TruckingRequestFromServer): void {
    const request = serverToLocalRequest(serverRequest);
    const currentRequests = this.allFCTVRequests$$.value;
    this.allFCTVRequests$$.next([...currentRequests, request]);
  }

  private addOneDCTVRequest(serverRequest: TruckingRequestFromServer): void {
    const request = serverToLocalRequest(serverRequest);
    const currentRequests = this.allDCTVRequests$$.value;
    this.allDCTVRequests$$.next([...currentRequests, request]);
  }
}

function serverToLocalRequest(serverRequest: TruckingRequestFromServer): TruckingRequest {
  return {
    ...serverRequest,
    humanReadableStatusTime: moment.duration(moment().diff(moment(serverRequest.createdTimestamp))).humanize(),
  };
}

export function isPendingOrAcceptedRequest(
  request: TruckingRequest,
): request is PendingTruckingRequest | AwardedTruckingRequest {
  return request.status === 'awarded' || request.status === 'pending';
}

export function isPendingRequest(
  request: PendingTruckingRequest | AwardedTruckingRequest,
): request is PendingTruckingRequest {
  return request.status === 'pending';
}
