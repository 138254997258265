<sa-top-nav></sa-top-nav>

<div class="loader-analytics-component">
  <div *ngIf="loadData$ | async; let loaderData" class="load-data">
    <div>
      <div class="load-data-label">PO Number</div>
      <div>{{ loaderData.poNumber }}</div>
    </div>
    <div>
      <div class="load-data-label">BOL Number</div>
      <div>{{ loaderData.bolNumber }}</div>
    </div>
    <div>
      <div class="load-data-label">Load Weight</div>
      <div>{{ loaderData.loadWeight | poLoaderPoundTonConversion: false | poLoaderPoundTonUnit }}</div>
    </div>
    <div>
      <div class="load-data-label">Truck Number</div>
      <div>{{ loaderData.truckNumber | dashIfNothing }}</div>
    </div>
    <div>
      <div class="load-data-label">Pickup Time</div>
      <div>{{ loaderData.pickupTime | inTimezone: loaderData.loaderTimezone | dashIfNothing }}</div>
    </div>
  </div>

  <div class="table-wrap">
    <table mat-table [dataSource]="activeDataSource" matSort>
      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i class="material-icons" *ngIf="load.goodMatch" [matTooltip]="'Loader data matched to this order'"
              >check_circle_outline</i
            >
            <a [routerLink]="['/', 'lmo', 'frac', load.orderFracId, 'orders', load.orderId]">
              {{ load.orderId }}
            </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="matchReasons">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Match Reasons</th>
        <td mat-cell *matCellDef="let load">
          <div class="match-reasons">
            <ng-container *ngFor="let reason of load.matchReasons">
              <div *ngIf="toMatchReasonText(reason, load); let reasonText" class="match-reason">
                {{ reasonText }}
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="siteVisitStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Entered Mine At</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i class="material-icons" *ngIf="isSiteVisitMatch(load)" [matTooltip]="'Site visit matches pickup time'"
              >check</i
            >
            <div>{{ load.siteVisitStart | inTimezone: loaderTimezone | dashIfNothing }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="siteVisitEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Exited Mine At</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i class="material-icons" *ngIf="isSiteVisitMatch(load)" [matTooltip]="'Site visit matches pickup time'"
              >check</i
            >
            <div>{{ load.siteVisitEnd | inTimezone: loaderTimezone | dashIfNothing }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="poNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PO Number</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i class="material-icons" *ngIf="isPONumberMatch(load)" [matTooltip]="'PO number matches'">check</i>
            <div>{{ load.poName }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bolNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>BOL Number</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i class="material-icons" *ngIf="isBOLNumberMatch(load)" [matTooltip]="'BOL number matches'">check</i>
            <div>{{ load.bolNumber }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="truckNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Truck Number</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <i
              class="material-icons"
              *ngIf="isTruckNumberMatch(load) || isPartialTruckNumberMatch(load)"
              [matTooltip]="truckNumberMatchTooltip(load)"
              >check</i
            >
            <div>{{ load.truckNumber }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderLoadWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Load Weight</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <div>{{ load.orderLoadWeight | poLoaderPoundTonConversion: false | poLoaderPoundTonUnit }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="boxNames">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Box ID</th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <ng-container *ngIf="load.boxNames?.length > 0; else noBoxes">
              <ng-container *ngFor="let name of load.boxNames">
                <div>
                  {{ name }}
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noBoxes>
              <div>
                -
              </div>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="setMatch">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let load">
          <div class="table-cell">
            <div *ngIf="!load.goodMatch; else goodMatch" class="unmatched-buttons">
              <button mat-raised-button color="primary" (click)="markGoodMatch(load)">
                Match to This Order
              </button>
            </div>
            <ng-template #goodMatch>
              <div class="matched-buttons">
                <i class="material-icons" [matTooltip]="'Loader data matched to this order'">check_circle_outline</i>
                <button mat-raised-button color="warn" (click)="unmatchOrder(load)">
                  Unmatch This Order
                </button>
              </div>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let load; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-spinner color="primary" [diameter]="25" *ngIf="!(loadData$ | async)"></mat-spinner>
</div>
