import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EmailData, LoaderIntegrationLoad } from '~models/emailSettings.model';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailSettingsV2Service } from '~services/email-settings-v2.service';
import { UserService } from '~services/user.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { take, filter } from 'rxjs/operators';
import { CrewDataRecord, CrewDataService, FracData } from '~services/crew-data.service';

@Component({
  selector: 'sa-crew-data-on-date',
  templateUrl: './crew-data-on-date.component.html',
  styleUrls: ['./crew-data-on-date.component.scss'],
})
export class CrewDataOnDateComponent implements OnInit, OnDestroy {
  activeDataSource: MatTableDataSource<CrewDataRecord>;

  baseColumns = ['deliveredDate', 'fullWeight', 'bolNumber', 'poNumber', 'matched', 'sandiOrderId'];

  displayedColumns = this.baseColumns;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  subscriptions: Subscription[] = [];

  public fracData$: Observable<FracData>;

  public filter = 'all';

  private filter$$ = new BehaviorSubject<string>('all');

  public fracTimezone: string;
  public fracId: number;

  constructor(
    private route: ActivatedRoute,
    public crewDataService: CrewDataService,
    public userService: UserService,
    public router: Router,
  ) {}

  public async ngOnInit() {
    this.activeDataSource = new MatTableDataSource([]);
    this.activeDataSource.sort = this.sort;

    this.fracData$ = this.crewDataService.fracData$;

    this.filter = this.route.snapshot.queryParams['filter'];
    if (this.filter !== 'matched' && this.filter !== 'unmatched' && this.filter !== 'all') {
      this.router.navigate([], { queryParams: { filter: 'all' } });
      this.filter = 'all';
    }

    this.filter$$.next(this.filter);

    this.subscriptions.push(
      combineLatest([this.crewDataService.recordsForDate$, this.filter$$]).subscribe(([loads, filterString]) => {
        if (loads) {
          this.activeDataSource.data = this.filterLoads(loads, filterString);
        } else {
          this.activeDataSource.data = [];
        }
      }),
      this.fracData$.subscribe((fracData) => {
        if (fracData) {
          this.fracTimezone = fracData.fracTimezone;
          this.fracId = fracData.fracId;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  public filterTypeChange() {
    this.router.navigate([], { queryParams: { filter: this.filter }, skipLocationChange: true });
    this.filter$$.next(this.filter);
  }

  private filterLoads(loads: CrewDataRecord[], filterString: string): CrewDataRecord[] {
    if (filterString === 'matched') {
      return loads.filter((record) => record.matched);
    }

    if (filterString === 'unmatched') {
      return loads.filter((record) => !record.matched);
    }

    return loads;
  }
}
