<div class="add-user-group-container">
  <h2 mat-dialog-title>
    <span *ngIf="!userGroup.name">Add</span>
    <span *ngIf="userGroup.name">Edit</span>
    User Group
  </h2>

  <form *ngIf="addUserGroupForm" [formGroup]="addUserGroupForm" (submit)="submit()" autocomplete="off">
    <div>
      <mat-form-field>
        <input
          matInput
          placeholder="User Group Name"
          [formControl]="addUserGroupForm.controls['name']"
          saAutomatedSelector="user-group-creation__name"
        />
        <mat-error *ngIf="addUserGroupForm.controls['name'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field subscribed-users">
        <mat-select
          [formControl]="addUserGroupForm.controls['users']"
          placeholder="Users"
          [compareWith]="compareFn"
          saAutomatedSelector="user-group-creation__users"
          multiple
        >
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'Users'"
              [noEntriesFoundLabel]="'No User found'"
              [formControl]="filterUser"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let user of filteredUserOptions | async" [value]="user">
            {{ user.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addUserGroupForm.controls['users'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="actions" matDialogActions fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-flat-button color="primary" mat-dialog-close color="accent">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        saAutomatedSelector="user-group-creation__submit-button"
        [disabled]="submitting"
      >
        {{ submitting ? 'Submitting...' : ' Submit' }}
      </button>
    </div>
  </form>
</div>
