import { Component, Directive, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line
  selector: 'button[sa-button]',
})
export class SaButtonDirective {
  @HostBinding('class') button = 'button sa-button';
}

@Directive({
  // tslint:disable-next-line
  selector: 'button[sa-secondary-button]',
})
export class SaSecondaryButtonDirective {
  @HostBinding('class') button = 'sa-button button-secondary';
}

@Directive({
  // tslint:disable-next-line
  selector: 'button[sa-danger-button]',
})
export class SaDangerButtonDirective {
  @HostBinding('class') button = 'sa-button button-danger';
}

@Component({
  selector: 'button[sa-button],' + 'button[sa-secondary-button],' + 'button[sa-danger-button],',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {}
