import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../services/user.service';
import { WellApiService } from '../services/api/well.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WellService } from '../services/well.service';
import { ErrorHandlingService } from '../services/error-handling.service';
import { OrderCount } from './billing-orders/billing-orders.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LOCAL_STORAGE_CONST } from '../constants/local-storage-constants';
import { getFracName } from 'src/app/ui-components/pipes/frac-name.pipe';
import { MatDialog } from '@angular/material/dialog';
import { NewBillingPortalOptInComponent } from '../ui-components/new-billing-portal-opt-in/new-billing-portal-opt-in.component';

@Component({
  selector: 'sa-completed-orders',
  templateUrl: './completed-orders.component.html',
  styleUrls: ['./completed-orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompletedOrdersComponent implements OnInit, OnDestroy {
  displayNav = false;
  isVendor = false;
  todayDate = new Date();
  userLabel: { name: string; email: string; account: string };
  fracId: string;
  navLinks = [];
  downloadProgress = false;
  linkMap = {
    needDataForTicketing: 'notBilledCount',
    waitingForTicketApproval: 'approvedByDispatcherCount',
    readyForInvoicing: 'approvedByLmo',
    waitingForInvoiceApproval: 'billedCount',
    invoiceApproved: 'approvedCount',
  };
  counts: OrderCount;
  wellName: string;
  orderedAfter: FormControl = new FormControl(null);
  dispatcherTabs = ['needDataForTicketing', 'readyForInvoicing'];
  lmoTabs = ['waitingForTicketApproval', 'waitingForInvoiceApproval'];

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.wellService.setSelectedFracs([]);
  }

  constructor(
    private userService: UserService,
    private wellApiService: WellApiService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlingService,
    private router: Router,
    public wellService: WellService,
    private matDialog: MatDialog,
  ) {
    this.isVendor = !(this.userService.isLMOAccount() || this.userService.isShaleAccount());
  }

  ngOnInit() {
    this.fracId = this.route.snapshot.params['id'];
    const localOrderedAfterFilter = localStorage.getItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER);
    if (localOrderedAfterFilter) {
      this.orderedAfter.setValue(new Date(localOrderedAfterFilter));
      this.wellService.setOrderedAfterFilter(localOrderedAfterFilter);
    }
    this.userLabel = this.userService.getLabel();
    if (this.userService.isDispatcherAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('/map');
    } else if (this.userService.isLMOAccount() && this.userService.isCrewLeader()) {
      this.router.navigateByUrl('//lmo');
    }
    this.navLinks = [
      {
        path: 'needDataForTicketing',
        label: 'Awaiting Loader Data',
        selector: 'billing__tab_awaiting_loader_data',
      },
      {
        path: 'waitingForTicketApproval',
        label: 'Waiting for Ticket Approval',
        selector: 'billing__tab_waiting_for_ticket_approval',
      },
      {
        path: 'readyForInvoicing',
        label: 'Ready for Invoicing',
        selector: 'billing__tab_ready_for_invoicing',
      },
      {
        path: 'waitingForInvoiceApproval',
        label: 'Waiting for Invoice Approval',
        selector: 'billing__tab_waiting_for_invoice_approval',
      },
      {
        path: 'invoiceApproved',
        label: 'Invoice Approved',
        selector: 'billing__tab_invoice_approved',
      },
    ];
    this.setOrderedAfterListener();
    this.wellService.setSelectedFracs([{ id: this.fracId }]);
    this.wellService.orderCounts$.subscribe((counts) => {
      this.counts = { ...counts };
    });
    this.wellService.billingDetails$.subscribe((well) => {
      if (well.id === parseInt(this.fracId, 10)) {
        this.wellName = getFracName(well);
      }
    });
  }

  setOrderedAfterListener() {
    this.orderedAfter.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        localStorage.setItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER, this.orderedAfter.value);
        this.wellService.setOrderedAfterFilter(this.orderedAfter.value);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_CONST.ORDERED_AFTER_FILTER);
        this.wellService.setOrderedAfterFilter('');
      }
    });
  }

  backToWellsUrl(): string[] {
    if (this.userService.isDispatcherAccount()) {
      return ['/', 'billing'];
    } else {
      return ['/', 'lmo'];
    }
  }

  exportAll() {
    this.downloadProgress = true;
    if (this.isVendor) {
      this.wellApiService.downloadVendorRecordsUrl(this.fracId).subscribe(
        (data) => {
          window.open(data, '_blank');
          this.downloadProgress = false;
        },
        (error) => {
          this.downloadProgress = false;
          this.errorHandler.showError('Error downloading the file.');
        },
      );
    } else {
      this.wellApiService.downloadRecordUrl(this.fracId).subscribe(
        (data) => {
          window.open(data, '_blank');
          this.downloadProgress = false;
        },
        (error) => {
          this.downloadProgress = false;
          this.errorHandler.showError('Error downloading the file.');
        },
      );
    }
  }

  clearOrderedAfterFilter() {
    this.orderedAfter.setValue(null);
  }

  checkCountForTab(path: string) {
    return (
      this.counts &&
      ((this.userService.isDispatcherAccount() && this.dispatcherTabs.find((tabLink) => tabLink === path)) ||
        (this.userService.isLMOAccount() && this.lmoTabs.find((tabLink) => tabLink === path)))
    );
  }

  menuClick() {
    this.displayNav = !this.displayNav;
  }

  ngOnDestroy() {
    this.wellService.setSelectedFracs([]);
  }

  public optIn() {
    this.matDialog.open(NewBillingPortalOptInComponent, {
      width: ' 500px',
    });
  }
}
