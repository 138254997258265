import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagService, processFlag } from './services/feature-flag.service';
import { map } from 'rxjs/operators';

interface FeatureFlaggedItem extends Record<string, any> {
  featureFlag?: string;
}

@Pipe({
  name: 'featureFlag',
})
export class FeatureFlagPipe implements PipeTransform {
  constructor(private featureFlagService: FeatureFlagService) {}

  transform(value: FeatureFlaggedItem[], args?: any): Observable<FeatureFlaggedItem[]> {
    return this.featureFlagService.flags$.pipe(
      map((flags) => {
        return value.filter((item) => {
          if (Reflect.has(item, 'featureFlag')) {
            return processFlag(flags, item.featureFlag);
          }
          return true;
        });
      }),
    );
  }
}
