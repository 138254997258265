import { Truck, Trailer } from './truck.model';
import { PurchaseOrder } from '~lmo/models/purchaseOrders.model';

export type OrderStatus =
  | 'pending'
  | 'canceled'
  | 'driver_rejected'
  | 'completed'
  | 'driver_accepted'
  | 'dispatched'
  | 'declined';

export interface RerouteInformation {
  uid: string;
  orderId: number;
  fracId: number;
  fracName: string;
  mineId: number;
  mineName: string;
  loadNumber: number;
  orderStatus: OrderStatus;
}

const tabMap: Record<OrderStatus, 'pending' | 'progress' | 'completed'> = {
  pending: 'pending',
  canceled: 'completed',
  driver_rejected: 'pending',
  completed: 'completed',
  driver_accepted: 'progress',
  dispatched: 'progress',
  declined: 'completed',
};

export function getTabFromStatus(orderStatus: OrderStatus): 'pending' | 'progress' | 'completed' {
  if (tabMap[orderStatus]) {
    return tabMap[orderStatus];
  }
  return 'completed';
}

export interface Order {
  acceptTimestamp: string;
  additionCharge: number;
  additionDeduction: number;
  actualLoadWeight?: number;
  billingStatus: string;
  bolNumber: string;
  boostPercent: number;
  boxes: {
    actualLoadWeight?: number;
    id: number;
    loadWeight: 43500;
    name: string;
    orderId: 44849;
  }[];
  bulkStorageNumber: string;
  comment: string;
  createTimestamp: string;
  dataMatched: string;
  dataUploadTimestamp: string;
  deadheadCost: number;
  deadheadCostPerMile: number;
  deadheadFreeMileage: number;
  description: string;
  dispatchTimestamp: string;
  dropOffFreeTime: number;
  estimateDeliveryTimestamp: string;
  eta: number;
  etaStatus: 'arrived' | 'Others?';
  fracId: number;
  hourlyDetentionRate: number;
  id: number;
  lineHaul: number;
  lmoLoaderEmail: {
    csvConverted: boolean;
    emailSettingsId: number;
    files: null;
    id: number;
    lmoLoaderEmailSettings: null;
    processed: boolean;
    sender: string;
    uploadCompleted: boolean;
    uploadTime: string;
  };
  loaded?: boolean;
  loadNumber: number;
  loadWeight: number;
  loadingDetention: number;
  mesh: {
    id: number;
    type: string;
    preferredOrder: number;
  };
  trailerType: {
    trailerTypeId: number;
    trailerTypeName: string;
    trailerTypeDisplayName: string;
  };
  mine: {
    id: number;
    site: {
      id: number;
      lngLat: [number, number];
      name: string;
    };
  };
  distributionCenter: {
    id: number;
    site: {
      id: number;
      lngLat: [number, number];
      name: string;
    };
  };
  orderStatus: OrderStatus;
  orderStatusChangeHistory: {
    orderId: number;
    changedBy: string;
    changedTimestamp: string;
  };
  pickupFreeTime: number;
  poNumber: string;
  purchaseOrder: PurchaseOrder;
  reroutedFrom: RerouteInformation;
  reroutedTo: RerouteInformation;
  saUniqueId: string;
  startTimestamp: string;
  ticketNumber: string;
  truck: Truck;
  trailer: Trailer;
  unloadingDetention: number;
  updateTimestamp: string;
  uploadedFiles: UploadedBillingFile[];
  user: {
    activeSession: boolean;
    deleted: boolean;
    id: number;
    lastLngLat: [number, number];
    lastTimeSeen: string;
    lastUsedTruck: any;
    name: string;
    phone: string;
    subcarrier: number;
    subcarrierName: string;
    userStatus: {
      currentOrderId: number;
      exceptionDescription: string;
      exceptionTitle: string;
      exceptionURL: string;
      isException: boolean;
      lastLngLat: [number, number];
      lastTimeSeen: string;
      shiftEndTimestamp: string;
      shortTitle: string;
      type: string;
      userId: number;
    };
  };
  vendor: {
    autopilotEnabled: boolean;
    createdTimestamp: string;
    id: number;
    inNetwork: boolean;
    name: string;
    updatedTimestamp: string;
    canAcceptLoadForDriver: boolean;
  };
  vendorContractId: number;
  waitAtLoader: number;
  waitAtWell: number;
  isPreLoad: boolean;
  loaderDataLocked: boolean;
  loHiPreloadActive: boolean;
  loHiPreloadPaused: boolean;
  boxPickup: {
    id: number;
    siteName: string;
    siteRadius: number;
    ownerName: string;
    lngLat: [number, number];
    entranceLngLat: [number, number];
    entranceSpeedLimit: number;
    beforeOrder: boolean;
    completed: boolean;
    directions: string;
    currentTask: boolean;
  };
  pendingBoxPickup: boolean;
  isOrderPending: boolean;
  assetStatus: string;
}

export class OrderError {
  id: number;
  isOrderError = true;
  isGone = false;
}

export class UploadedBillingFile {
  id: number;
  orderId: number;
  fileName: string;
  uploadedTimestamp: string;
  category?: string;
}

export function isOrder(o: Order | OrderError): o is Order {
  return o && !(o as OrderError).isOrderError;
}
