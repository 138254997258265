<div class="invite-form-container">
  <h2 mat-dialog-title>
    Add
    <span *ngIf="accountType == 2">LMO Account</span>
    <span *ngIf="accountType == 3">Trucking Vendor</span>
  </h2>

  <form [formGroup]="inviteForm" (submit)="submit()" autocomplete="off">
    <div>
      <mat-form-field>
        <input matInput placeholder="Account Name" [formControl]="inviteForm.controls['name']" />
        <mat-error *ngIf="inviteForm.controls['name'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="accountType === 3">
        <input matInput placeholder="SCAC" [formControl]="inviteForm.get('scac')" />
        <mat-error *ngIf="inviteForm.get('scac').hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <br />

      <mat-form-field>
        <input matInput placeholder="Username" [formControl]="inviteForm.controls['username']" />
        <mat-error *ngIf="inviteForm.controls['username'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          [formControl]="inviteForm.controls['email']"
          placeholder="Email"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="inviteForm.controls['email'].hasError('required')">
          Email is required
        </mat-error>
        <mat-error
          *ngIf="inviteForm.controls['email'].hasError('email') && !inviteForm.controls['email'].hasError('required')"
        >
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" placeholder="Password" [formControl]="inviteForm.controls['password']" />
        <mat-error *ngIf="inviteForm.controls['password'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          type="password"
          placeholder="Confirm Password"
          [formControl]="inviteForm.controls['confirmPassword']"
        />
        <mat-error *ngIf="inviteForm.controls['confirmPassword'].hasError('required')">
          Input is required
        </mat-error>
        <mat-error *ngIf="inviteForm.controls['password'].hasError('match')">
          Passwords do not match
        </mat-error>
      </mat-form-field>

      <mat-checkbox *ngIf="accountType === 2" [formControl]="inviteForm.controls['isSandiLite']"
        >SANDi Lite Account</mat-checkbox
      >

      <mat-checkbox *ngIf="accountType === 3" [formControl]="inviteForm.controls['isBrokerage']"
        >Brokerage Account</mat-checkbox
      >
    </div>

    <div class="actions" matDialogActions fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-flat-button color="primary" mat-dialog-close color="accent">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="submitting">
        {{ submitting ? 'Inviting...' : ' Invite' }}
      </button>
    </div>
  </form>
</div>
