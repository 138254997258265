<div class="cancel-dialog-component">
  <div mat-dialog-content>
    <h3>{{ data.context }}</h3>
    <p [innerHTML]="data.desc"></p>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" (click)="cancelAction('close')">{{ data.button[0] }}</button>
    <div class="right-button-wrap">
      <button mat-button class="right-button" mat-dialog-close tabindex="-1" (click)="cancelAction('cancel')">
        {{ data.button[1] }}
      </button>
      <button mat-button color="primary" (click)="continueAction()" cdkFocusInitial>{{ data.button[2] }}</button>
    </div>
  </div>
</div>
