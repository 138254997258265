<div class="login">
  <div class="content">
    <div>
      <div>
        <img class="shale-logo" src="../../assets/icons/shale-logo.svg" />
      </div>
      <form [formGroup]="loginForm" (keyup.enter)="submitLogin()" novalidate>
        <p class="header">Login to ShaleApps SANDi<sup style="font-size: .5em;font-weight: 600;">TM</sup></p>
        <mat-form-field>
          <input
            matInput
            [formControl]="loginForm.controls['email']"
            placeholder="Email"
            [errorStateMatcher]="matcher"
            saAutomatedSelector="login__email"
            autocomplete="email"
          />
          <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field class="password-input">
          <input
            matInput
            [formControl]="loginForm.controls['password']"
            type="password"
            placeholder="Password"
            saAutomatedSelector="login__password"
            autocomplete="current-password"
          />
          <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>

        <div>
          <a mat-button (click)="openSSOEmail()">Login via SSO</a>
        </div>

        <div class="environment-picker" *saOnlyEnv="['dev', 'stage']">
          <button type="button" [class.is-active]="isDev()" (click)="useDev()">Dev</button>
          <button type="button" [class.is-active]="isStage()" (click)="useStage()">Stage</button>
          <button type="button" [class.is-active]="isProd()" (click)="useProd()" class="prod">Prod</button>
        </div>

        <div class="login-conditions">
          By clicking Sign In, you agree to our
          <a href="http://privacy.shaleapps.com" target="_blank">Terms and Conditions</a>
        </div>
      </form>

      <div class="login-actions">
        <sa-network-active-button [active]="activeRequest$ | async">
          <button sa-button saAutomatedSelector="login__sign-in-button" (click)="submitLogin()">
            Sign In
          </button>
        </sa-network-active-button>
        <a
          [routerLink]="['/', 'reset-password']"
          [queryParams]="{ email: loginForm.get('email').value }"
          saAutomatedSelector="login__forgot-password-button"
          >Forgot your password?</a
        >
      </div>
    </div>
    <div>
      <div class="updates gray hide-handheld">
        <div class="font-headline dark-gray">Last Mile Made Simple</div>
        <div class="font-medium">About ShaleApps SANDi®</div>
        <p>
          ShaleApps SANDi® eliminates Sand NPT and Truck Detention to deliver sand with fewer trucks – guaranteed.
        </p>
        <p>
          <a href="http://www.shaleapps.com/sand" target="_blank">Learn More</a>
        </p>
      </div>
    </div>

    <div class="login-footer gray hide-handheld">
      ShaleApps SANDi v{{ versionService.currentVersion }} – Download the Mobile App to Simplify Your Last Mile
      Logistics
      <a href="http://www.shaleapps.com/sand">Get Started</a>
    </div>
  </div>
</div>

<ng-template #ssoEmail>
  <form (ngSubmit)="ssoLogin()" class="new-po-form">
    <mat-form-field class="form-email form-field">
      <input #newContractName matInput placeholder="Email" [formControl]="ssoEmailForm" autocomplete="off" />
    </mat-form-field>
    <br />
    <button
      class="submit-btn"
      type="submit"
      color="primary"
      mat-raised-button
      [disabled]="ssoEmailForm.invalid || running"
    >
      Login Via SSO
    </button>
    <button class="cancel-btn" type="button" mat-raised-button (click)="closeTab()">
      Cancel
    </button>
  </form>
</ng-template>
