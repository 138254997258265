<sa-nav [visible]="displayNav$$ | async" (onMenuClose)="displayNav$$.next(false)"></sa-nav>

<div class="completed-order-component">
  <div class="top-menu">
    <div class="side-menu">
      <img
        class="menu-icon"
        src="../../../assets/icons/menu-24.svg"
        (click)="menuClick()"
        saAutomatedSelector="menu__open"
      />
      <div class="new-billing-portal">
        We are working on a new billing portal - <span class="opt-in" (click)="optIn()">try it out</span>
      </div>
    </div>
    <div class="logo-wrap"><img class="shaleapps-icon" src="../../../assets/icons/logo.svg" /></div>
    <div class="account-name">
      {{ userLabel?.email || userLabel?.name || 'Unknown' }}
    </div>
  </div>

  <div class="frac-header">
    <a class="back-wrap" [routerLink]="backToWellsUrl()">
      <i class="material-icons">keyboard_arrow_left</i>
    </a>
    <mat-form-field class="form-field">
      <mat-select
        #allFracSel
        [formControl]="selectedFracs"
        placeholder="Select Fracs"
        [compareWith]="compareFn"
        multiple
        saAutomatedSelector="billing__select_fracs"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'Fracs'"
            [noEntriesFoundLabel]="'No Frac found'"
            [formControl]="filterFracs"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let frac of filteredFracsOptions | async"
          [value]="frac"
          saAutomatedSelector="{{ 'billing__select_' + frac?.name }}"
        >
          {{ frac?.name }} <span *ngIf="frac.billingComplete">(Completed)</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="ordered-after-filter">
      <h3>Ordered After:</h3>
      <mat-form-field>
        <input
          matInput
          class="datepicker-input"
          placeholder="Ordered After"
          autocomplete="off"
          [formControl]="orderedAfter"
          [max]="todayDate"
          [owlDateTime]="orderedAfterFilter"
          [owlDateTimeTrigger]="orderedAfterFilter"
        />
        <owl-date-time #orderedAfterFilter></owl-date-time>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="clearOrderedAfterFilter()">Clear</button>
    </div>
  </div>

  <div class="select-all">
    <mat-checkbox [checked]="allSelected" (change)="toggleAllSelection()"> Select All Frac</mat-checkbox>
    <mat-checkbox [checked]="allCompletedSelected" (change)="toggleAllCompletedSelection()">
      Select All Billing Completed Fracs</mat-checkbox
    >
    <mat-checkbox [checked]="allNotCompletedSelected" (change)="toggleAllNotCompletedSelection()">
      Select All Billing Not Completed Fracs</mat-checkbox
    >
  </div>

  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="[link.path]"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      [saAutomatedSelector]="link.selector"
    >
      {{ link.label }}
      <span class="count-wrap" *ngIf="checkCountForTab(link.path)">{{ counts[linkMap[link.path]] }}</span>
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
