<sa-top-nav></sa-top-nav>

<div class="w-full">
  <div class="archived-wells-component container">
    <div class="heading flex flex-row justify-between">
      <h2 class="text-black">Archived Wells</h2>
    </div>

    <div class="w-full min-h-screen bg-gray-50">
      <!-- Header section with search and filters -->
      <div class="w-full px-4 pt-6">
        <div class="max-w-[2000px] mx-auto">
          <div class="grid grid-cols-8 md:grid-cols-8 gap-4">
            <!-- Search bar -->
            <div class="relative col-span-5">
              <mat-form-field class="w-full">
                <input matInput [(ngModel)]="searchValue" placeholder="Search..." (keyup.enter)="onSearch()" />
              </mat-form-field>
            </div>

            <!-- Search type selector -->
            <div class="col-span-1">
              <mat-form-field class="w-full">
                <mat-label>Search By</mat-label>
                <mat-select [(ngModel)]="searchType" (selectionChange)="onSearch()">
                  <mat-option value="well">Well Name</mat-option>
                  <mat-option value="crew">Crew Name</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Date Range filter -->
            <div class="col-span-1">
              <mat-form-field class="w-full">
                <mat-label>Choose archived date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    [(ngModel)]="selectedStartDate"
                    placeholder="Start date"
                    [readonly]="true"
                    (keydown)="$event.preventDefault()"
                  />
                  <input
                    matEndDate
                    [(ngModel)]="selectedEndDate"
                    placeholder="End date"
                    [readonly]="true"
                    (keydown)="$event.preventDefault()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker (closed)="onDateSearch()"> </mat-date-range-picker>
                <button
                  *ngIf="selectedStartDate || selectedEndDate"
                  matSuffix
                  mat-icon-button
                  color="primary"
                  (click)="clearDates()"
                  matTooltip="Clear dates"
                >
                  <mat-icon>clear</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-span-1">
              <button class="w-full" (click)="onSearch()" mat-flat-button color="primary">Search</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Table section -->
      <div class="px-4 py-2">
        <div class="max-w-[2000px] mx-auto">
          <div class="bg-white rounded-lg shadow bg-opacity-0 overflow-x-auto">
            <!-- Loading Spinner -->
            <div *ngIf="isLoading" class="flex justify-center items-center py-8">
              <mat-spinner diameter="40"></mat-spinner>
            </div>

            <!-- Empty State -->
            <div
              *ngIf="!isLoading && (!dataSource || dataSource.data.length === 0)"
              class="flex flex-col items-center justify-center py-16"
            >
              <p class="text-xl font-medium text-gray-600 mb-2">No Results Found</p>
              <p class="text-gray-500">Try adjusting your search criteria or filters</p>
            </div>

            <!-- Table Content -->
            <div *ngIf="!isLoading && dataSource && dataSource.data.length > 0" [class.opacity-50]="isLoading">
              <mat-table [dataSource]="dataSource" class="w-full">
                <!-- Well Name Column -->
                <ng-container matColumnDef="wellName">
                  <mat-header-cell *matHeaderCellDef class="min-w-[700px] column-well-name">Well Name</mat-header-cell>
                  <mat-cell *matCellDef="let well" class="min-w-[700px] column-well-name">
                    <div class="truncate" [matTooltip]="well | fracName" matTooltipShowDelay="500">
                      <a (click)="openWellForm(well.id)">{{ well | fracName }}</a>
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- Crew Name Column -->
                <ng-container matColumnDef="crewName">
                  <mat-header-cell *matHeaderCellDef class="w-[100px] column-date ml-2">Crew Name</mat-header-cell>
                  <mat-cell *matCellDef="let well" class="w-[100px] column-date ml-2">{{ well.crewName }}</mat-cell>
                </ng-container>

                <!-- Prefill Date Column -->
                <ng-container matColumnDef="prefillDate">
                  <mat-header-cell *matHeaderCellDef class="w-[100px] column-date ml-2">Prefill Date</mat-header-cell>
                  <mat-cell *matCellDef="let well" class="w-[100px] column-date ml-2">{{
                    well.prefillDate | date
                  }}</mat-cell>
                </ng-container>

                <!-- Frac Date Column -->
                <ng-container matColumnDef="fracDate">
                  <mat-header-cell *matHeaderCellDef class="w-[100px] column-date ml-2">Frac Date</mat-header-cell>
                  <mat-cell *matCellDef="let well" class="w-[100px] column-date ml-2">{{
                    well.fracDate | date
                  }}</mat-cell>
                </ng-container>

                <!-- Archived Date Column -->
                <ng-container matColumnDef="archivedDate">
                  <mat-header-cell *matHeaderCellDef class="w-[100px] column-date ml-2">Archived Date</mat-header-cell>
                  <mat-cell *matCellDef="let well" class="w-[100px] column-date ml-2">{{
                    well.archivedAt | date
                  }}</mat-cell>
                </ng-container>

                <!-- Invoicing Button Column -->
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef class="justify-center column-invoicing-action ml-2 invisible"
                    >Action</mat-header-cell
                  >
                  <mat-cell *matCellDef="let well" class="justify-center column-invoicing-action ml-2">
                    <button
                      mat-flat-button
                      color="primary"
                      class="px-4"
                      [routerLink]="['/', 'invoicing', 'orders_list', 'waiting_for_ticket_approval']"
                      [queryParams]="{ fracIds: well.id }"
                    >
                      Go To Invoicing
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              </mat-table>
            </div>

            <!-- Paginator -->
            <mat-paginator
              *ngIf="dataSource && dataSource.data.length > 0"
              [length]="totalElements"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              [pageIndex]="pageIndex"
              (page)="handlePageEvent($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
