import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '~services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from '~services/error-handling.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-signin-with-custom-token',
  styleUrls: ['./signin-with-custom-token.component.scss'],
  templateUrl: './signin-with-custom-token.component.html',
})
export class SigninWithCustomTokenComponent implements OnInit {
  public working$ = new BehaviorSubject(true);
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorService: ErrorHandlingService,
  ) {}

  async ngOnInit() {
    const token = this.route.snapshot.paramMap.get('jwt');
    if (token) {
      try {
        await this.authService.loginWithCustomToken(token);
      } catch (error) {
        this.errorService.showError(error.message);
        this.working$.next(false);
      }
    } else {
      this.errorService.showError('Invalid Token, please login again');
      this.working$.next(false);
    }
  }
}
