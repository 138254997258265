<div class="filtered-autocomplete">
  <button
    type="button"
    class="select-button"
    mat-raised-button
    *ngIf="!(isSelecting$$ | async)"
    (click)="startSelection()"
    [disabled]="isDisabled$ | async"
    saAutomatedSelector="{{ 'filt_auto__' + selector }}"
  >
    {{ buttonText }}
  </button>
  <mat-form-field [class.hidden]="!(isSelecting$$ | async)">
    <input
      type="text"
      [placeholder]="placeholderText"
      aria-label="Number"
      matInput
      [formControl]="searchBox"
      [matAutocomplete]="auto"
      #autoTrigger
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <ng-container *ngIf="isSelecting$$ | async">
        <mat-optgroup *ngFor="let group of groupedFilteredOptions$ | async" [label]="group.name">
          <mat-option *ngFor="let option of group.data" [value]="option">
            {{ displayFn(option) }}
          </mat-option>
        </mat-optgroup>
        <mat-option
          *ngFor="let option of filteredOptions$ | async"
          [value]="option"
          (onSelectionChange)="onSelection($event, option)"
        >
          {{ displayFn(option) }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
