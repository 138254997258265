<div>
  <i *ngIf="isAdmin" class="material-icons floating-plus" (click)="startAddCrew()">add</i>
  <div>
    <div class="grid grid-cols-2 text-gray-600 text-sm mb-3" [class.grid-cols-3]="isAdmin">
      <div>
        Name
      </div>
      <div>
        Leader(s)
      </div>
      <div *ngIf="isAdmin" class="text-right"></div>
    </div>
    <div [class.grid-cols-3]="isAdmin" *ngFor="let groupCrew of crews$ | async">
      <div class="grid grid-cols-2 mb-3" [class.grid-cols-3]="isAdmin" *ngFor="let crew of groupCrew.crews">
        <div>
          {{ crew.name }}
        </div>
        <div>
          {{ crew.leaders || [] | pluck: 'name' | joinWithAnd }}
        </div>
        <div *ngIf="isAdmin" class="text-right">
          <button mat-icon-button (click)="startEditCrew(crew)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addCrewModal>
  <div mat-dialog-title>
    Add Crew
  </div>
  <div>
    <form [formGroup]="addCrewForm" class="flex flex-col gap-2" (ngSubmit)="doAddCrew()">
      <mat-form-field>
        <mat-label>Crew Name*</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Leader*</mat-label>
        <mat-select formControlName="leader">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="User"
              noEntriesFoundLabel="User not found"
              [formControl]="leaderSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of possibleLeaders$ | async" [value]="user.id">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" mat-raised-button color="primary">Add</button>
    </form>
  </div>
</ng-template>

<ng-template #updateCrewModal>
  <div mat-dialog-title>Update {{ (selectedCrew$$ | async).name }}</div>
  <div>
    <form [formGroup]="editCrewForm" class="flex flex-col gap-2" (ngSubmit)="doEditCrew()">
      <mat-form-field>
        <mat-label>Crew Name*</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="editCrewForm.get('name').hasError('required')">
          Crew name is required
        </mat-error>
      </mat-form-field>
      <button type="submit" mat-raised-button color="primary">Update Name</button>
    </form>
    <div class="my-3 text-xl">Leaders</div>
    <div *ngFor="let leader of (selectedCrew$$ | async).leaders" class="flex justify-between items-center">
      <div>
        {{ leader.name }}
      </div>
      <div *ngIf="(selectedCrew$$ | async).leaders.length > 1">
        <button mat-icon-button (click)="removeLeader(leader.id)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="my-3 text-xl">Add Leader</div>
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Leader*</mat-label>
        <mat-select [formControl]="addLeaderFormControl">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="User"
              noEntriesFoundLabel="User not found"
              [formControl]="leaderSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of possibleLeaders$ | async" [value]="user">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-raised-button color="primary" (click)="addLeader(addLeaderFormControl.value)">
        Add {{ addLeaderFormControl.value?.name }}
      </button>
    </div>
  </div>
</ng-template>
