import { Injectable } from '@angular/core';
import { WellApiService } from './api/well.api.service';
import { BehaviorSubject, Observable, combineLatest, of, interval } from 'rxjs';
import { share, map, filter, switchMap, take, publishReplay, refCount, tap } from 'rxjs/operators';
import {
  RunboardSummary,
  UnassignedUserSummary,
  OrderSummary,
  UserStatusWithExtra,
} from 'src/app/ui-components/jobs-view/frac-detail/runboard-summary.model';
import { CrudService } from './crud.service';
import { RouterStateService } from './router-state.service';
import { StoreService } from './store.service';
import { MapUtilService, MapUtilState } from './map-util.service';
import { FeatureFlagService } from './feature-flag.service';
import { AuthService } from './auth.service';

// Attached to every frac as frac.cluster
interface FracCluster {
  id: number;
  name: string;
  createdTimestamp: string;
  virtual: boolean;
  purchaseOrderIds: number[];
  truckPoolSizeMessage: string;
  autopilotLevel: 'off' | 'semi' | 'full';
}

@Injectable({
  providedIn: 'root',
})
export class RunboardService {
  private currentFracClusterId: number;
  private runboardSummary$$ = new BehaviorSubject<RunboardSummary>(null);

  // Returns the runboard summary
  public get runboardSummary$(): Observable<RunboardSummary> {
    return this.runboardSummary$$.asObservable().pipe(share());
  }

  public activeDrivers$: Observable<OrderSummary[]> = this.runboardSummary$.pipe(
    filter((summary) => !!summary),
    map((summary) =>
      summary.orderSummaries.filter(
        (order) => order.orderStatus === 'driver_accepted' || order.orderStatus === 'dispatched',
      ),
    ),
    publishReplay(1),
    refCount(),
  );

  public clusterInfo$: Observable<FracCluster> = combineLatest(this.store.select('fracs'), this.runboardSummary$).pipe(
    map(([wells, summary]: [{ cluster: FracCluster }[], RunboardSummary]) => {
      if (summary) {
        const index = wells.findIndex((well) => well.cluster.id === summary.fracClusterId);
        if (index !== -1) {
          return wells[index].cluster;
        }
      }
      return null;
    }),
    publishReplay(1),
    refCount(),
  );

  public unassignedUsers$: Observable<UnassignedUserSummary[]> = this.runboardSummary$.pipe(
    filter((summary) => !!summary),
    map((summary) => summary.unassignedUserSummaries || []),
    publishReplay(1),
    refCount(),
  );

  public notAvailableForLoads$: Observable<UnassignedUserSummary[]> = this.unassignedUsers$.pipe(
    filter((unassignedUsers) => !!unassignedUsers),
    map((unassignedUsers) => unassignedUsers.filter((summary) => !summary.userStatus.isReusable)),
    publishReplay(1),
    refCount(),
  );

  public availableUndispatchedDriver$: Observable<UnassignedUserSummary[]> = this.unassignedUsers$.pipe(
    filter((unassignedUsers) => !!unassignedUsers),
    map((unassignedUsers) => unassignedUsers.filter((summary) => summary.userStatus.isReusable)),
    publishReplay(1),
    refCount(),
  );

  public needsDriver$: Observable<OrderSummary[]> = this.runboardSummary$.pipe(
    filter((summary) => !!summary),
    map((summary) => summary.orderSummaries.filter((order) => order.orderStatus === 'pending')),
    publishReplay(1),
    refCount(),
  );

  public selectedDriver$: Observable<UserStatusWithExtra> = combineLatest(
    this.routerState.routerState$,
    this.runboardSummary$,
  ).pipe(
    switchMap(([routerState, runboardSummary]) => {
      if (
        runboardSummary &&
        routerState.params.fracClusterId &&
        +routerState.params.fracClusterId === runboardSummary.fracClusterId &&
        routerState.params.userId
      ) {
        const orderUserIsOn = runboardSummary.orderSummaries.find(
          (order) => order.userStatus && +order.userStatus.userId === +routerState.params.userId,
        );
        // This driver is on an order, we can grab the user status from the order itself.
        if (orderUserIsOn) {
          this.store.loadSingleOrderByOrderId(orderUserIsOn.orderId);
          return combineLatest(
            this.store.getOrderForId(orderUserIsOn.orderId) as Observable<any>,
            this.store.getFracByOrderId(orderUserIsOn.orderId),
          ).pipe(
            map(([selectedOrder, frac]) => {
              const returner: any = {
                ...orderUserIsOn.userStatus,
                order: orderUserIsOn,
                userId: orderUserIsOn.userId,
                userName: orderUserIsOn.userName,
              };
              // If we have all of the information from the store, we might as well attach it so we can get information like frac name
              if (selectedOrder) {
                if (frac) {
                  selectedOrder = {
                    ...selectedOrder,
                    frac,
                  };
                }
                returner.selectedOrder = selectedOrder;
              }
              return returner;
            }),
          );
        }
        const userSummary = runboardSummary.unassignedUserSummaries.find(
          (summary) => +summary.userId === +routerState.params.userId,
        );
        if (userSummary) {
          // Since we are using a switchMap now to handle the store observables, need an observable here
          return of({
            ...userSummary.userStatus,
            userId: userSummary.userId,
            userName: userSummary.userName,
          });
        }
      }
      // Since we are using a switchMap now to handle the store observables, need an observable here
      return of(null);
    }),
    filter((order) => !!order),
    publishReplay(1),
    refCount(),
  );

  public driverPool$: Observable<{ message: string; shouldShow: boolean }> = this.runboardSummary$.pipe(
    filter((summary) => !!summary),
    map((summary) => summary.truckPoolSize),
    publishReplay(1),
    refCount(),
  );

  // Allows the runboard service to begin tracking a fracCluster by ID
  public set fracClusterId(fracClusterId: number) {
    if (!fracClusterId) {
      this.currentFracClusterId = null;
      return;
    }
    // Ensure a number
    fracClusterId = +fracClusterId;
    // Make sure that we are getting a number and that it isn't the same number. If it is the same number, we don't need to refresh
    if (!isNaN(fracClusterId) && this.currentFracClusterId !== fracClusterId) {
      this.currentFracClusterId = fracClusterId;
      this.loadRunboardSummary();
    }
  }

  constructor(
    private wellApiService: WellApiService,
    private crudService: CrudService,
    private routerState: RouterStateService,
    private store: StoreService,
    private mapUtilService: MapUtilService,
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
  ) {
    this.loadRunboardSummary = this.loadRunboardSummary.bind(this);
    // Listen to the state of the router and if there is a fracClusterId we can assume we will need the
    // runboard summary for it so begin loading it.
    this.routerState.routerState$.subscribe((currentState) => {
      if (currentState.params.fracClusterId) {
        this.fracClusterId = currentState.params.fracClusterId;
        this.store
          .select<{ cluster: FracCluster }[]>('fracs')
          .pipe(
            filter((wells) => wells && wells.length > 0),
            take(1),
          )
          .subscribe((wells) => {
            // Get all of the wells in the cluster so we can paint all of them
            const wellsInCluster = wells.filter(
              (well) => well.cluster.id === Number(currentState.params.fracClusterId),
            );
            if (wellsInCluster.length) {
              // This is kind of a hack, we zoom in on the first frac we find in the cluster because we assume
              // they will all be close to each other. Eventually we may remake the map to accept multiple fracs?
              if (currentState.params.userId) {
                this.mapUtilService.updateMap({
                  frac: wellsInCluster,
                  state: MapUtilState.RunboardSummaryDriver,
                  order: null,
                });
              } else {
                this.mapUtilService.updateMap({
                  frac: wellsInCluster,
                  state: MapUtilState.RunboardSummary,
                  order: null,
                });
              }
            }
          });
      } else {
        // Stop tracking anything
        this.fracClusterId = null;
      }
    });

    interval(5 * 60 * 1000)
      .pipe(switchMap(() => this.featureFlagService.isFlagActive('runboardPolling').pipe(take(1))))
      .subscribe((isFlagActive) => {
        if (isFlagActive) {
          this.loadRunboardSummary();
        }
      });

    this.authService.getSocketEventListener().subscribe((event) => {
      if (event === 'logout') {
        this.currentFracClusterId = null;
      }
    });
  }

  clearRunboard() {
    this.fracClusterId = null;
    const emptyRunboard: RunboardSummary = {
      fracClusterId: this.currentFracClusterId,
      truckPoolSizeMessage: '',
      orderSummaries: [] as OrderSummary[],
      unassignedUserSummaries: [] as UnassignedUserSummary[],
      truckPoolSize: {
        message: '',
        shouldShow: false,
      },
    };

    this.runboardSummary$$.next(emptyRunboard);
  }

  public reloadIfMatchedFracClusterId(fracClusterId: number) {
    if (fracClusterId === this.currentFracClusterId) {
      this.loadRunboardSummary();
    }
  }

  public loadRunboardSummary(): void {
    if (this.currentFracClusterId) {
      this.crudService.httpClientReady
        .pipe(
          filter(Boolean),
          take(1),
          switchMap(() => this.wellApiService.getRunboardSummary(this.currentFracClusterId)),
          take(1),
        )
        .subscribe(
          (response) => {
            if (response) {
              const justInCaseSomethingDoesntComeThrough: RunboardSummary = {
                fracClusterId: this.currentFracClusterId,
                truckPoolSizeMessage: '',
                orderSummaries: [] as OrderSummary[],
                unassignedUserSummaries: [] as UnassignedUserSummary[],
                truckPoolSize: {
                  message: '',
                  shouldShow: false,
                },
              };

              this.runboardSummary$$.next(Object.assign(justInCaseSomethingDoesntComeThrough, response));
            }
          },
          (error) => console.error(error),
        );
    }
  }
}
