import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '~services/user.service';
import { DetailView } from '~models/detail-view';
import { DetailViewModels } from '../../constants/detail-view-models';
import { Subcarrier } from '~models/subcarrier.model';
import { CrudService } from '~services/crud.service';
import { filter, map, take } from 'rxjs/operators';
import { SubcarrierApiService } from '~services/api/subcarrier.api.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'sa-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['../../settings/user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierComponent implements OnInit {
  displayedColumns = ['name', 'scacCode', 'billingCode', 'driverCount', 'action'];
  dataSource: MatTableDataSource<Subcarrier> = new MatTableDataSource();

  detailView: DetailView = {
    open: false,
    modelName: null,
    model: null,
  };

  constructor(
    public userService: UserService,
    private subcarrierApiService: SubcarrierApiService,
    private crudService: CrudService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.crudService.httpClientReady.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.subcarrierApiService
        .getSubcarriers()
        .pipe(
          map((data) => {
            if (!data) {
              return [];
            }
            data.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return data;
          }),
        )
        .subscribe((data) => {
          this.dataSource = new MatTableDataSource(data);
        });
    });
  }

  sortTable(event) {
    switch (event.active) {
      case 'name':
        const sortedName = this.dataSource.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        if (event.direction === 'desc') {
          sortedName.reverse();
        }
        this.dataSource.data = sortedName;
        break;
      case 'scacCode':
        const sortedScacCode = this.dataSource.data.sort((a, b) => {
          return a.scacCode.localeCompare(b.scacCode);
        });
        if (event.direction === 'desc') {
          sortedScacCode.reverse();
        }
        this.dataSource.data = sortedScacCode;
        break;
      case 'billingCode':
        const sortedBillingCode = this.dataSource.data.sort((a, b) => {
          return a.billingCode.localeCompare(b.billingCode);
        });
        if (event.direction === 'desc') {
          sortedBillingCode.reverse();
        }
        this.dataSource.data = sortedBillingCode;
        break;
    }
  }

  openSubcarrierDetailView(subcarrier: Subcarrier) {
    this.detailView.open = true;
    this.detailView.modelName = DetailViewModels.carrier;
    this.detailView.model = subcarrier;
  }

  closeSubcarrierDetailView() {
    this.detailView.open = false;
    this.detailView.modelName = null;
    this.detailView.model = null;
  }

  subcarrierUpdated() {
    this.loadData();
  }
}
