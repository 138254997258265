<form *ngIf="analyticsForm" [formGroup]="analyticsForm" autocomplete="off">
  <div class="date-wrap">
    <mat-form-field>
      <input
        matInput
        class="datepicker-input "
        placeholder="Choose start date"
        autocomplete="off"
        [formControl]="analyticsForm.controls['startDate']"
        [max]="today"
        [owlDateTime]="dt1"
        [owlDateTimeTrigger]="dt1"
        saAutomatedSelector="bulk-export__start-Date"
      />
      <span *ngIf="analyticsForm.controls['startDate'].value" (click)="analyticsForm.controls['startDate'].reset()"
        ><i class="material-icons">clear</i></span
      >
      <span *ngIf="!analyticsForm.controls['startDate'].value" [owlDateTimeTrigger]="dt1"
        ><i class="material-icons">calendar_today</i></span
      >
      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
      <mat-error *ngIf="analyticsForm.controls['startDate'].hasError('required')">
        Start date is required
      </mat-error>
    </mat-form-field>

    <i class="material-icons">arrow_forward</i>

    <mat-form-field>
      <input
        matInput
        class="datepicker-input "
        placeholder="Choose end date"
        autocomplete="off"
        [min]="analyticsForm.get('startDate').value || today"
        [max]="today"
        [formControl]="analyticsForm.controls['endDate']"
        [owlDateTime]="dt2"
        [owlDateTimeTrigger]="dt2"
        saAutomatedSelector="bulk-export__end-Date"
      />
      <span *ngIf="analyticsForm.controls['endDate'].value" (click)="analyticsForm.controls['endDate'].reset()"
        ><i class="material-icons">clear</i></span
      >
      <span *ngIf="!analyticsForm.controls['endDate'].value" [owlDateTimeTrigger]="dt2"
        ><i class="material-icons">calendar_today</i></span
      >
      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
      <mat-error *ngIf="analyticsForm.controls['endDate'].hasError('required')">
        End date is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-2">
    <mat-form-field>
      <mat-select placeholder="All Loaders" [compareWith]="compareFn" [formControl]="analyticsForm.controls['mine']">
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'All Loaders'"
            [noEntriesFoundLabel]="'No matching loader found'"
            [formControl]="filterLoader"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">
          All Loaders
        </mat-option>

        <mat-option
          *ngFor="let mine of filteredMines$ | async; trackBy: filteredMines$ | trackByKey: 'id'"
          [value]="mine"
        >
          {{ mine.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex flex-row items-center">
      <mat-slide-toggle [formControl]="isPLOExportControl" color="primary">Export by PLO</mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="isPLOExportControl.value" class="grid grid-cols-2 gap-2">
    <mat-form-field>
      <mat-select placeholder="Select PLO" [compareWith]="compareFn" [formControl]="analyticsForm.controls['frac']">
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'Select PLO'"
            [noEntriesFoundLabel]="'No matching PLO found'"
            [formControl]="filterPloNumber"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let plo of filteredPloNumbers$ | async; trackBy: filteredPloNumbers$ | trackByKey: 'id'"
          [value]="plo"
        >
          {{ plo.ploNumber }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!isPLOExportControl.value" class="grid grid-cols-2 gap-2">
    <mat-form-field>
      <mat-select
        placeholder="All Purchase Orders"
        [compareWith]="compareFn"
        [formControl]="analyticsForm.controls['purchaseOrder']"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'All Purchase Orders'"
            [noEntriesFoundLabel]="'No matching Purchase Order found'"
            [formControl]="filterPurchaseOrder"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">
          All Purchase Orders
        </mat-option>
        <mat-option
          *ngFor="let po of filteredPurchaseOrders$ | async; trackBy: filteredPurchaseOrders$ | trackByKey: 'id'"
          [value]="po"
        >
          {{ po.name }} - {{ po.meshType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex flex-row-reverse ">
    <button
      mat-flat-button
      color="primary"
      [disabled]="analyticsForm?.invalid || isDownloading"
      (click)="exportLoaderAnalytics()"
    >
      Export
    </button>
  </div>
</form>
