<div class="confirm-dialog-component">
  <div mat-dialog-content>
    <h3>{{ _data.title }}</h3>
    <h5>{{ _data.desc }}</h5>
  </div>
  <div mat-dialog-actions>
    <div class="right-button-wrap">
      <button mat-stroked-button class="right-button" mat-dialog-close>{{ _data.cancelButtonText }}</button>
      <button mat-stroked-button color="warn" [mat-dialog-close]="true">{{ _data.submitButtonText }}</button>
    </div>
  </div>
</div>
