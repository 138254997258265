<div class="sa-flex-container">
  <div class="sa-flex-row">
    <div class="sa-flex-col actions">
      <button type="button" mat-raised-button (click)="centerMarkerOnMap()">Center Marker On Map</button>
      <button type="button" mat-raised-button (click)="centerMapOnMarker()">Center Map On Marker</button>
      <div class="show-sites-toggle">
        <mat-slide-toggle [(ngModel)]="showOtherSites">Show Other Sites</mat-slide-toggle>
      </div>
      <div class="show-sites-toggle" *ngIf="!site || !site.outerRadius">
        <mat-slide-toggle [(ngModel)]="showOuterSite">Staging Geofence</mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="sa-flex-row map-row">
    <div #map class="map"></div>
  </div>
  <ng-container [formGroup]="locationForm">
    <div class="sa-flex-row form-row">
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            [placeholder]="showOuterSite ? 'Drop-off Latitude' : 'Latitude'"
            type="number"
            formControlName="latitude"
            (blur)="handleLatitudeInputChange($event.target.value)"
            saAutomatedSelector="well-creation__latitude"
          />
          <mat-error *ngIf="locationForm.get('latitude').hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              locationForm.get('latitude').hasError('latitude') && !locationForm.get('latitude').hasError('required')
            "
          >
            Latitude value should be between -90 and 90
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            [placeholder]="showOuterSite ? 'Drop-off Longitude' : 'Longitude'"
            type="number"
            formControlName="longitude"
            saAutomatedSelector="well-creation__longitude"
            (blur)="handleLongitudeInputChange($event.target.value)"
          />
          <mat-error *ngIf="locationForm.get('longitude').hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              locationForm.get('longitude').hasError('longitude') && !locationForm.get('longitude').hasError('required')
            "
          >
            Longitude value should be between -180 and 180
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            [placeholder]="showOuterSite ? 'Drop-off Radius' : 'Radius'"
            type="number"
            step="0.1"
            formControlName="radius"
            saAutomatedSelector="well-creation__radius"
          />
          <mat-error *ngIf="locationForm.get('radius').hasError('required')">
            Input is required
          </mat-error>
          <mat-error *ngIf="locationForm.get('radius').invalid && !locationForm.get('radius').hasError('required')">
            Value Must be between 0.01 and 10
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div
      class="grid grid-cols-3 form-row"
      *ngIf="locationForm.get('outerLatitude') && locationForm.get('outerLongitude') && locationForm.get('outerRadius')"
    >
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Staging Latitude"
            type="number"
            formControlName="outerLatitude"
            saAutomatedSelector="well-creation__outerLatitude"
          />
          <mat-error *ngIf="locationForm.get('outerLatitude').hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              locationForm.get('outerLatitude').hasError('outerLatitude') &&
              !locationForm.get('outerLatitude').hasError('required')
            "
          >
            Latitude value should be between -90 and 90
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Staging Longitude"
            type="number"
            formControlName="outerLongitude"
            saAutomatedSelector="well-creation__outerLongitude"
          />
          <mat-error *ngIf="locationForm.get('outerLongitude').hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="
              locationForm.get('outerLongitude').hasError('outerLongitude') &&
              !locationForm.get('outerLongitude').hasError('required')
            "
          >
            Longitude value should be between -180 and 180
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Staging Radius"
            type="number"
            step="0.1"
            formControlName="outerRadius"
            saAutomatedSelector="well-creation__outerRadius"
          />
          <mat-error *ngIf="locationForm.get('outerRadius').hasError('required')">
            Input is required
          </mat-error>
          <mat-error
            *ngIf="locationForm.get('outerRadius').invalid && !locationForm.get('outerRadius').hasError('required')"
          >
            Value Must be between 0 and 10
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-form-field class="form-field">
          <input
            matInput
            placeholder="Travel Time From Staging Pad to Well (minutes)"
            type="number"
            step="1"
            formControlName="minutesFromStagingPadToFrac"
            saAutomatedSelector="well-creation__stagingRoadIsOneWay"
          />
        </mat-form-field>
      </div>
      <div class="sa-flex-col">
        <mat-label class="pr-2">Staging Road is One Way only</mat-label>
        <mat-slide-toggle
          formControlName="stagingRoadIsOneWay"
          color="primary"
          saAutomatedSelector="well-creation__stagingRoadIsOneWay"
        ></mat-slide-toggle>
      </div>
    </div>
  </ng-container>
</div>
