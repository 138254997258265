<div class="map-search-input-container search" [ngClass]="{ focused: focused }">
  <i class="material-icons menu" (click)="menuClick()">menu</i>
  <input
    #input
    (focus)="focused = true"
    (blur)="focused = false"
    (input)="onInput(input.value)"
    type="text"
    placeholder="Search"
  />
  <i class="material-icons" *ngIf="!input.value">search</i>
  <i class="material-icons clear" (click)="clear()" *ngIf="input.value">clear</i>
</div>
