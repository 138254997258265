<div class="user-detail-view-container">
  <div class="header">
    <ng-container>
      <span *ngIf="!subcarrier?.id">New Subcarrier</span>
      <span *ngIf="subcarrier?.id">Edit Subcarrier</span>
    </ng-container>
    <i class="material-icons" (click)="closeClicked()">close</i>
  </div>
  <div class="detail-content">
    <div class="content-header">Carrier Info</div>
    <form [formGroup]="formGroup">
      <mat-form-field style="width: 100%;">
        <input
          matInput
          formControlName="name"
          placeholder="Name"
          autocomplete="off"
          (blur)="updateSubcarrier('name')"
        />
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <input
          matInput
          formControlName="scacCode"
          placeholder="SCAC"
          autocomplete="off"
          (blur)="updateSubcarrier('scacCode')"
        />
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <input
          matInput
          formControlName="billingCode"
          placeholder="Vendor Code"
          autocomplete="off"
          (blur)="updateSubcarrier('billingCode')"
        />
      </mat-form-field>
    </form>

    <ng-container *ngIf="subcarrier?.id">
      <button type="button" sa-danger-button (click)="removeSubcarrier(subcarrier?.id)">
        Remove Subcarrier
      </button>
    </ng-container>

    <ng-container *ngIf="!subcarrier?.id">
      <button type="button" [disabled]="buttonDisabled || formGroup.invalid" sa-button (click)="createSubcarrier()">
        Create Subcarrier
      </button>
    </ng-container>
  </div>
</div>
