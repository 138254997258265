import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { LmoTruckingVendorsService } from '~lmo/services';
import { TruckingVendorContract } from '~lmo/models/trucking-vendor.model';
import { TruckingVendor } from '~v2Models/trucking-vendor.model';
import { UserService } from '~services/user.service';
import { fuse } from '~utilities/fuse';
import { MatSnackBar } from '@angular/material/snack-bar';

const searchOptions: Fuse.FuseOptions<TruckingVendor> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

export interface CostPerLoadValue {
  vendorContractId: number;
  minimumMileage: number;
  maximumMileage: number;
  cost: number;
}

export interface FormValue {
  name: string;
  vendors: TruckingVendor[];
  hourlyDetentionRate: number;
  maxDetentionPerLoad: number;
  pickupFreeTime: number;
  dropoffFreeTime: number;
  deadheadFreeMileage: number;
  deadheadCostPerMile: number;
  costsPerLoad: CostPerLoadValue[];
  costType: 'fixed' | 'per_ton';
  expirationTime: string;
  allowDeadheadLinking: boolean;
}

@Component({
  selector: 'sa-vendor-contract-creation',
  templateUrl: './vendor-contract-creation.component.html',
  styleUrls: ['./vendor-contract-creation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class VendorContractCreationComponent implements OnInit {
  public networkActive$: Observable<boolean>;
  @Input() public clone: TruckingVendorContract | {};
  @Output() public onCloseClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  public vendorForm: FormGroup;
  public costsPerLoad: FormArray;
  public compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  public vendors$: Observable<TruckingVendor[]>;
  public vendorFilter = new FormControl();

  public get formValue(): FormValue {
    return this.vendorForm.value as FormValue;
  }

  constructor(
    private fb: FormBuilder,
    private lmoTruckingVendorService: LmoTruckingVendorsService,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.networkActive$ = this.lmoTruckingVendorService.networkActive$;
    this.vendors$ = combineLatest(
      this.lmoTruckingVendorService.vendors$.pipe(map(fuse<TruckingVendor>(searchOptions))),
      this.vendorFilter.valueChanges.pipe(debounceTime(100), startWith('')),
    ).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.data;
        }
        return fused.fuse.search(searchTerm) as any;
      }),
    );
    this.buildform();
  }

  compare(a, b) {
    if (a.vendorName < b.vendorName) {
      return -1;
    }
    if (a.vendorName > b.vendorName) {
      return 1;
    }
    return 0;
  }

  compareMileage(a, b) {
    if (a.minimumMileage < b.minimumMileage) {
      return -1;
    }
    if (a.minimumMileage > b.minimumMileage) {
      return 1;
    }
    return 0;
  }

  buildform() {
    if (isTruckingVendorContract(this.clone)) {
      this.vendorForm = this.fb.group({
        name: [`${this.clone.name} (Copy)`, [Validators.required]],
        expirationTime: [this.clone.expirationTime, []],
        vendors: [[], [Validators.required, Validators.minLength(1)]],
        hourlyDetentionRate: [this.clone.hourlyDetentionRate / 100, [Validators.required, Validators.min(0)]],
        maxDetentionPerLoad: [this.clone.maxDetentionPerLoad / 100, [Validators.min(0)]],
        pickupFreeTime: [this.clone.pickupFreeTime, [Validators.required, Validators.min(0)]],
        dropoffFreeTime: [this.clone.dropoffFreeTime, [Validators.required, Validators.min(0)]],
        deadheadFreeMileage: [this.clone.deadheadFreeMileage, [Validators.required, Validators.min(0)]],
        deadheadCostPerMile: [this.clone.deadheadCostPerMile / 100, [Validators.required, Validators.min(0)]],
        costsPerLoad: this.fb.array(this.getExistingCostsPerLoad()),
        costType: [this.clone.costType, Validators.required],
        allowDeadheadLinking: [this.clone.allowDeadheadLinking],
      });
    } else {
      this.vendorForm = this.fb.group({
        name: [null, [Validators.required]],
        expirationTime: [null, []],
        vendors: [[], [Validators.required, Validators.minLength(1)]],
        hourlyDetentionRate: [null, [Validators.required, Validators.min(0)]],
        maxDetentionPerLoad: [null, [Validators.min(0)]],
        pickupFreeTime: [null, [Validators.required, Validators.min(0)]],
        dropoffFreeTime: [null, [Validators.required, Validators.min(0)]],
        deadheadFreeMileage: [null, [Validators.required, Validators.min(0)]],
        deadheadCostPerMile: [null, [Validators.required, Validators.min(0)]],
        costsPerLoad: this.fb.array([this.initCostPerLoad()]),
        costType: ['fixed', Validators.required],
        allowDeadheadLinking: [false],
      });
    }
  }

  getExistingCostsPerLoad(): FormGroup[] {
    if (!isTruckingVendorContract(this.clone)) {
      return [this.initCostPerLoad()];
    }

    const costsPerLoad = this.clone.costsPerLoad;
    costsPerLoad.sort(function(a, b) {
      return a.minimumMileage - b.minimumMileage;
    });

    const newCostsPerLoad = costsPerLoad.map((load) => {
      return this.fb.group({
        vendorContractId: [null, []],
        minimumMileage: [load.minimumMileage, [Validators.required, Validators.min(0)]],
        maximumMileage: [load.maximumMileage, [Validators.required]],
        cost: [load.cost / 100, [Validators.required, Validators.min(0)]],
      });
    });

    return newCostsPerLoad;
  }

  initCostPerLoad() {
    return this.fb.group({
      vendorContractId: [null, []],
      minimumMileage: [0 as number, [Validators.required, Validators.min(0)]],
      maximumMileage: [0 as number, [Validators.required]],
      cost: [0 as number, [Validators.required, Validators.min(0)]],
    });
  }

  addCostPerLoad() {
    const costsPerLoad = this.vendorForm.get('costsPerLoad') as FormArray;
    costsPerLoad.push(this.initCostPerLoad());
  }

  removeLoad(position) {
    const costsPerLoad = this.vendorForm.get('costsPerLoad') as FormArray;
    costsPerLoad.removeAt(position);
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  public async onSubmit() {
    if (this.vendorForm.valid) {
      const value = this.formValue;
      const body = {
        name: value.name,
        expirationTime: value.expirationTime,
        lmoId: this.userService.accountId(),
        hourlyDetentionRate: Math.round(value.hourlyDetentionRate * 100),
        maxDetentionPerLoad: Math.round(value.maxDetentionPerLoad * 100),
        deadheadCostPerMile: Math.round(value.deadheadCostPerMile * 100),
        pickupFreeTime: Math.round(value.pickupFreeTime),
        dropoffFreeTime: Math.round(value.dropoffFreeTime),
        deadheadFreeMileage: Math.round(value.deadheadFreeMileage),
        costsPerLoad: value.costsPerLoad.map((item) => ({
          ...item,
          cost: Math.round(item.cost * 100),
        })),
        costType: value.costType,
        allowDeadheadLinking: value.allowDeadheadLinking,
      };
      const selectedVendorIds = value.vendors.map((vendor) => vendor.id);
      const success = await this.lmoTruckingVendorService.createTruckingContracts(selectedVendorIds, body);
      if (success) {
        this.snackBar.open('Successfully created contract', null, {
          duration: 5000,
          panelClass: ['snackbar-success'],
        });
        this.closeClicked(true);
      }
    }
  }

  closeClicked(close) {
    this.onCloseClicked.emit(close);
  }

  public clearExpirationDate() {
    this.vendorForm.get('expirationTime').setValue(null);
  }

  public today() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }
}

function isTruckingVendorContract(contract: TruckingVendorContract | {}): contract is TruckingVendorContract {
  return !!(contract as TruckingVendorContract).id;
}
