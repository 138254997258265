import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { CrudService } from './crud.service';
import { filter, take, switchMap } from 'rxjs/operators';
import { environment } from '~environments/environment';

export interface OrderPrediction {
  hasPrediction: boolean;
  predictionCreationTime: string;
  redictedEndTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private currentOrderPrediction$$: BehaviorSubject<OrderPrediction> = new BehaviorSubject<OrderPrediction>(null);

  public get currentOrderPrediction$(): Observable<OrderPrediction> {
    return this.currentOrderPrediction$$.asObservable();
  }

  constructor(private userService: UserService, private crudService: CrudService) {}

  loadPrediction(fracId: number, loadNumber: number): void {
    this.crudService.httpClientReady
      .pipe(
        filter((ready) => ready),
        filter(() => this.userService.isShaleappsEmail()),
        take(1),
        switchMap(() => this.crudService.get(`${environment.api}/v2/order/prediction`, { fracId, loadNumber })),
      )
      .subscribe((result: { prediction: OrderPrediction }) => {
        this.currentOrderPrediction$$.next(result.prediction);
      });
  }
}
