<div class="reason-dialog-component">
  <ng-container *ngIf="data === 'approved_by_dispatcher'">
    <h1 mat-dialog-title>Send Back to Vendor?</h1>
    <h3>Please enter the reason you are sending this ticket back to the vendor.</h3>
  </ng-container>
  <ng-container *ngIf="data === 'approved_by_lmo'">
    <h1 mat-dialog-title>Send Back to Vendor?</h1>
    <h3>
      Please enter the reason you are rejecting this invoice. Please mention specific load numbers that need changes.
    </h3>
  </ng-container>
  <ng-container *ngIf="data === 'approved_by_lmo-edit'">
    <h1 mat-dialog-title>Send Back to Customer for Approval?</h1>
    <h3>
      Please enter the reason for changes to this load.
    </h3>
  </ng-container>
  <div mat-dialog-content>
    <mat-form-field class="full">
      <input
        matInput
        type="text"
        [(ngModel)]="pushBackReason"
        placeholder="Reason"
        saAutomatedSelector="send_back__reason"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="continueAction()"
      cdkFocusInitial
      saAutomatedSelector="send_back__submit"
    >
      Send Back to
      <span *ngIf="data === 'approved_by_dispatcher'">Vendor</span>
      <span *ngIf="data === 'approved_by_lmo'">Vendor</span>
      <span *ngIf="data === 'approved_by_lmo-edit'">Customer</span>
    </button>
    <button mat-button color="primary" (click)="cancelAction()">Cancel</button>
  </div>
</div>
