import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Action {
  action: string;
  oldValue: any;
  newValue: any;
}

interface DetailedHistory {
  createdTimestamp: string;
  operation: string;
  actionList: Action[];
  username: string;
}

@Component({
  selector: 'sa-lmo-purchase-order-timeline',
  styleUrls: ['./lmo-purchase-order-timeline.component.scss'],
  templateUrl: './lmo-purchase-order-timeline.component.html',
})
export class LmoPurchaseOrderTimelineComponent implements OnInit {
  public po: any;
  public detailedHistory: DetailedHistory[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.po = data.po;
  }

  public ngOnInit() {
    if (this.po && this.po.purchaseOrderHistoryDetails) {
      this.detailedHistory = this.po.purchaseOrderHistoryDetails;
    }
  }

  public trackByDetail(index: number, detail: DetailedHistory): any {
    return detail.createdTimestamp;
  }

  public trackByChange(index: number, change: Action): number {
    return index;
  }
}
