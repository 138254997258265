import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subcarrier } from '~models/subcarrier.model';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubcarrierApiService } from '~services/api/subcarrier.api.service';
import { ErrorHandlingService } from '~services/error-handling.service';

@Component({
  selector: 'sa-assign-subcarrier-dialog',
  templateUrl: './assign-subcarrier-dialog.component.html',
  styleUrls: ['./assign-subcarrier-dialog.component.scss'],
})
export class AssignSubcarrierDialogComponent implements OnInit {
  public subcarriers$ = new BehaviorSubject<Subcarrier[]>(null);
  public driverId$ = new BehaviorSubject<number>(null);
  public isSubcarrierListLoaded = new BehaviorSubject<boolean>(false);
  public assignSubcarrierFormGroup: FormGroup;
  @Output() public onSubcarrierAssigned = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subcarrierList: Subcarrier[]; driverId: number },
    public dialogRef: MatDialogRef<AssignSubcarrierDialogComponent>,
    private fb: FormBuilder,
    private subcarrierApiService: SubcarrierApiService,
    private errorHandler: ErrorHandlingService,
  ) {}

  ngOnInit(): void {
    this.driverId$.next(this.data.driverId);
    if (this.data.subcarrierList.length === 0) {
      this.isSubcarrierListLoaded.next(false);
    } else {
      this.subcarriers$.next(this.data.subcarrierList);
      this.isSubcarrierListLoaded.next(true);
      this.setupForm();
    }
  }

  private setupForm() {
    this.assignSubcarrierFormGroup = this.fb.group({
      subcarrier: [null, [Validators.required]],
    });
  }

  public assignSubcarrier() {
    if (this.assignSubcarrierFormGroup.invalid) {
      return;
    }
    const subcarrierId = this.assignSubcarrierFormGroup.controls['subcarrier'].value;
    const body = {
      driverId: this.driverId$.value,
      subcarrierId: subcarrierId,
    };
    this.subcarrierApiService.assignDriverToSubcarrier(body).subscribe(
      (response) => {
        this.onSubcarrierAssigned.emit(true);
        this.dialogRef.close(true);
      },
      (err) => {
        this.onSubcarrierAssigned.emit(false);
        this.errorHandler.showError(err);
      },
    );
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
