import { Component, OnInit } from '@angular/core';
import { OrderService, OrderPrediction } from '~services/order.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'sa-order-prediction',
  templateUrl: './order-prediction.component.html',
  styleUrls: ['./order-prediction.component.scss'],
})
export class OrderPredictionComponent implements OnInit {
  currentPrediction$: Observable<OrderPrediction>;

  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.currentPrediction$ = this.orderService.currentOrderPrediction$;
  }
}
