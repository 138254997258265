<sa-top-nav></sa-top-nav>

<div class="bulk-export-wrap">
  <h2>Bulk Export</h2>
  <h3>You can bulk export the data between a specific time period</h3>

  <form *ngIf="downloadDataForm" [formGroup]="downloadDataForm" autocomplete="off" (submit)="downloadData()">
    <div class="date-wrap">
      <mat-form-field>
        <input
          matInput
          class="datepicker-input "
          placeholder="Choose start date"
          autocomplete="off"
          [formControl]="downloadDataForm.controls['startDate']"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          saAutomatedSelector="bulk-export__start-Date"
        />
        <span [owlDateTimeTrigger]="dt1"><i class="material-icons">calendar_today</i></span>
        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        <mat-error *ngIf="downloadDataForm.controls['startDate'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>

      <i class="material-icons">arrow_forward</i>

      <mat-form-field>
        <input
          matInput
          class="datepicker-input "
          placeholder="Choose end date"
          autocomplete="off"
          [min]="downloadDataForm.get('startDate').value || todayDate"
          [max]="todayDate"
          [formControl]="downloadDataForm.controls['endDate']"
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          saAutomatedSelector="bulk-export__end-Date"
        />
        <span [owlDateTimeTrigger]="dt2"><i class="material-icons">calendar_today</i></span>
        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
        <mat-error *ngIf="downloadDataForm.controls['endDate'].hasError('required')">
          Input is required
        </mat-error>
      </mat-form-field>
      <div class="export-option">
        <h3>Export By:</h3>
        <mat-radio-group [formControl]="downloadDataForm.controls['byApprovedTime']">
          <mat-radio-button [value]="false">Dispatch Timestamp</mat-radio-button>
          <mat-radio-button [value]="true">Approval Timestamp</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-checkbox *saFeatureFlag="'bulkExportEmailCheckbox'" [formControl]="downloadDataForm.controls['email']"
        >Send via Email</mat-checkbox
      >
    </div>

    <div class="button-wrap">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        saAutomatedSelector="downloadButton"
        [disabled]="isDownloading"
      >
        {{ isDownloading ? 'Downloading' : 'Download' }}
      </button>
      <mat-spinner color="white" [diameter]="25" *ngIf="isDownloading"></mat-spinner>
    </div>
  </form>
</div>
