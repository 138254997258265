<div class="order-table-component">
  <div class="content-wrap" [ngClass]="{ selected: selection.selected.length && !detailView }">
    <div class="side-widget" *ngIf="urlRoute === 'readyForInvoicing'">
      <ng-container
        *ngIf="
          (wellService.billingDetails$ | async)?.pushedbackBills &&
          (wellService.billingDetails$ | async)?.pushedbackBills.length
        "
      >
        <div class="widget-wrap invoice-filter">
          <div class="widget-row">
            <div class="label">Declined Invoices</div>
          </div>
          <div class="invoice-wrap">
            <mat-select [(ngModel)]="pushedBackInvoiceNumber" (selectionChange)="fetchData()">
              <mat-option [value]="''">All Orders</mat-option>
              <mat-option
                *ngFor="let invoice of (wellService.billingDetails$ | async).pushedbackBills"
                [value]="invoice.InvoiceNumber"
                >SA{{ invoice.InvoiceNumber }},
                <span class="invoice-cost">${{ (invoice.TotalCost / 100).toFixed(2) }}</span></mat-option
              >
            </mat-select>
          </div>
        </div>
      </ng-container>
      <div class="widget-wrap">
        <div class="widget-row">
          <div class="label">Attachment</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="attachmentFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="widget-row">
          <div class="label">Data Matched</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="dataMatchedFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="widget-row">
          <div class="label">Data Fast Tracked</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="fastTrackedFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="length-div">
          <div>Total Orders</div>
          <div *ngIf="!isLoading">{{ orderCount }}</div>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="side-widget" *ngIf="urlRoute === 'needDataForTicketing'">
      <div class="widget-wrap">
        <div class="title-wrap secondary-title" *ngIf="truckingVendorsList.length && isLmoAccount">
          <h2>Trucking Vendors</h2>
        </div>
        <ng-container *ngIf="isLmoAccount">
          <div class="widget-row" *ngFor="let vendor of truckingVendorsList">
            <div class="label">{{ vendor.truckingVendorName }}</div>
            <div class="filter-slider-wrap">
              <mat-slide-toggle color="primary" [(ngModel)]="vendor.filter" (change)="fetchData()"></mat-slide-toggle>
            </div>
          </div>
        </ng-container>
        <div class="widget-row" [ngClass]="{ 'top-margin': this.truckingVendorsList.length }">
          <div class="label">Attachment</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="attachmentFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="widget-row">
          <div class="label">Data Matched</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="dataMatchedFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="widget-row" matTooltip="Filter Loads Awaiting Data for over 48 hours">
          <div class="label">More than 48hrs</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="olderThanTwoDays" (change)="loadRecent()"></mat-slide-toggle>
          </div>
        </div>
        <div class="length-div">
          <div>Total Orders</div>
          <div *ngIf="!isLoading">{{ orderCount }}</div>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="side-widget" *ngIf="urlRoute === 'waitingForTicketApproval'">
      <div class="widget-wrap">
        <div class="title-wrap">
          <img src="../../assets/icons/edit-widget.png" alt="Edit" />
          <h2>Needs Manual Approval</h2>
        </div>
        <div class="widget-row">
          <div class="label">Attachment</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="attachmentFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="widget-row">
          <div class="label">Data Matched</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle color="primary" [(ngModel)]="dataMatchedFilter" (change)="fetchData()"></mat-slide-toggle>
          </div>
        </div>
        <div class="length-div">
          <div>Total Orders</div>
          <div *ngIf="!isLoading">{{ orderCount }}</div>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="side-widget" *ngIf="urlRoute === 'invoiceApproved' && !groupByInvoiceFilter.value">
      <div class="widget-wrap">
        <div class="widget-row">
          <div class="label">Sent by API</div>
          <div class="filter-slider-wrap">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="receivedByCustomer"
              (change)="fetchData()"
            ></mat-slide-toggle>
          </div>
        </div>
        <div class="length-div">
          <div>Total Orders</div>
          <div *ngIf="!isLoading">{{ orderCount }}</div>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="table-wrap" *ngIf="!showOrderTable()">
      <div class="search-bar">
        <input
          type="text"
          placeholder="Search Invoices"
          autofocus
          (ngModelChange)="inputValueChanged($event)"
          [(ngModel)]="searchData"
          saAutomatedSelector="billing_order_table__search"
        />
        <div class="batch-view" *ngIf="checkForInvoiceTabs()">
          <mat-slide-toggle color="primary" [formControl]="groupByInvoiceFilter">Group by Invoice</mat-slide-toggle>
        </div>
      </div>

      <div class="loader-wrap" *ngIf="isLoading">
        <mat-spinner [diameter]="100"></mat-spinner>
      </div>
      <div class="sub-table-wrap">
        <table mat-table *ngIf="!isLoading" [dataSource]="invoiceDataSource" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="column === 'select'">
                <mat-checkbox
                  color="primary"
                  (change)="$event ? invoiceMasterToggle() : null"
                  [checked]="selection.hasValue() && isAllInvoiceSelected()"
                  [indeterminate]="selection.hasValue() && !isAllInvoiceSelected()"
                  saAutomatedSelector="billing_order_table__select_all"
                >
                </mat-checkbox>
              </ng-container>
              <ng-container *ngIf="column === 'InvoiceNumber'"> Invoice Number</ng-container>
              <ng-container *ngIf="column === 'TotalCost'"> Total Cost</ng-container>
              <ng-container *ngIf="column === 'OrderCount'"> Orders</ng-container>
              <ng-container *ngIf="column === 'statusChangedTime'"> Status Timestamp</ng-container>
              <ng-container *ngIf="column === 'vendorName'"> Vendor </ng-container>
              <ng-container *ngIf="column === 'Actions'"> Actions</ng-container>
              <ng-container *ngIf="column === 'Export'"> Export </ng-container>
              <ng-container *ngIf="column === 'ApprovedByName'"> Approved By </ng-container>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              (click)="updateSubOrders(element)"
              saAutomatedSelector="{{ 'billing_order_table__data_' + column }}"
              [automatedName]="element[column]"
            >
              <mat-checkbox
                *ngIf="column === 'select'"
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null"
                [checked]="selection.isSelected(element)"
              >
              </mat-checkbox>
              <span
                *ngIf="
                  column !== 'Actions' && column !== 'Export' && column !== 'select' && column !== 'statusChangedTime'
                "
              >
                <span *ngIf="column === 'InvoiceNumber' && !element[column]?.startsWith('SA')">SA</span>
                <span *ngIf="column === 'TotalCost'">$ {{ (element[column] / 100).toFixed(2) }}</span>
                <span *ngIf="column !== 'TotalCost'">{{ element[column] || '--' }}</span>
              </span>
              <span *ngIf="column === 'statusChangedTime'">{{
                datePipe.transform(element[column], 'shortDate') || '--'
              }}</span>
              <div *ngIf="column === 'Export'" class="button-row">
                <button
                  *ngIf="!exporting[i]"
                  mat-stroked-button
                  color="primary"
                  (click)="exportInvoiceDetails($event, element, i)"
                  saAutomatedSelector="billing_order_table__export"
                >
                  Export
                </button>
                <mat-spinner *ngIf="exporting[i]" [diameter]="25"></mat-spinner>
              </div>
              <div *ngIf="column === 'Actions'" class="button-row">
                <ng-container *ngIf="element.canUnapprove; else cannotReject">
                  <button mat-stroked-button color="primary" (click)="rejectApprovedInvoice($event, element)">
                    Undo Approval
                  </button>
                </ng-container>
                <ng-template #cannotReject>
                  <div>--</div>
                </ng-template>
              </div>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="sub-content-wrap" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="loader-wrap" *ngIf="isSubLoading">
                  <mat-spinner [diameter]="100"></mat-spinner>
                </div>
                <table class="mat-elevation-z8" *ngIf="!isSubLoading" mat-table [dataSource]="element.orders">
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let order">
                      <div class="status-wrap">
                        <span
                          matTooltip="{{ checkForEditableOrder(order) }}"
                          *ngIf="!isLmoAccount && checkForEditableOrder(order) !== ''"
                        >
                          <i class="material-icons error-icon-gray">lock</i>
                        </span>
                        <div
                          class="financial-change-wrap"
                          matTooltip="Non-financial impact changes"
                          *ngIf="checkForNonFinancialImpact(order)"
                        >
                          <i class="material-icons">priority_high</i>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="loadNumber">
                    <th mat-header-cell *matHeaderCellDef>Load #, Driver and Truck</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3 class="blue-text">
                          {{ helperService.convertToShaleAppsLoadNumberWithFlag(order.id) }}
                        </h3>
                        <h3>{{ order?.user?.name }}<span *ngIf="order.truck">,</span> {{ order?.truck?.name }}</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vendor">
                    <th mat-header-cell *matHeaderCellDef>Vendor</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3>{{ order.vendor.name }}</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Loader</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3>{{ order.mine?.site.name }}</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dispatchTimestamp">
                    <th mat-header-cell *matHeaderCellDef>Dispatched Date</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3>{{ datePipe.transform(order.dispatchTimestamp, 'shortDate') || '--' }}</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="mesh">
                    <th mat-header-cell *matHeaderCellDef>Payload</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3>{{ order.mesh?.type }} Mesh</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actualLoadWeight">
                    <th mat-header-cell *matHeaderCellDef>Load Weight</th>
                    <td mat-cell *matCellDef="let order">
                      <div class="order-column">
                        <h3 class="center-aligned">{{ getLoadWeight(order) }}</h3>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef>Cost</th>
                    <td mat-cell *matCellDef="let order">
                      <ng-template #costBreakdownTemplate>
                        <span>Line Haul Cost: {{ order.lineHaul / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Boost Line Haul Cost: {{ calculateBoostCharge(order) / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Deadhead Cost: {{ order.deadheadCost / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Loading Detention: {{ order.loadingDetention / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Unloading Detention: {{ order.unloadingDetention / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Fuel Surcharge: {{ order.fuelSurcharge / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Additional Charges: {{ order.additionCharge / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                        <span>Additional Deduction: {{ order.additionDeduction / 100 | currency: 'USD':'$ ' }}</span
                        ><br />
                      </ng-template>
                      <div
                        class="order-column"
                        placement="top"
                        [tooltip]="costBreakdownTemplate"
                        content-type="template"
                      >
                        <h3>
                          {{ order.totalPrice / 100 | currency: 'USD':'$ ' }}
                        </h3>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="subTableColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let order; columns: subTableColumns"
                    (click)="selectRow(order)"
                    saAutomatedSelector="billing_order_table__order"
                  ></tr>
                </table>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: columnsToDisplay"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"
            saAutomatedSelector="billing_order_table__batch"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="example-detail-row"
            saAutomatedSelector="billing_order_table__batch_order"
          ></tr>
        </table>
      </div>

      <mat-paginator
        [ngClass]="isLoading ? 'hidePage' : ''"
        (page)="fetchPageData($event)"
        [length]="this.invoiceDataSource.data.length"
        pageSize="100"
        [pageSizeOptions]="[100]"
        #paginatorInvoice
      >
      </mat-paginator>
    </div>

    <div class="table-wrap" *ngIf="showOrderTable()">
      <div class="search-bar">
        <input
          type="text"
          placeholder="Search"
          (ngModelChange)="inputValueChanged($event)"
          [(ngModel)]="searchData"
          autofocus
          saAutomatedSelector="billing_order_table__search"
        />
        <div class="batch-view" *ngIf="checkForInvoiceTabs()">
          <mat-slide-toggle color="primary" [formControl]="groupByInvoiceFilter">Group by Invoice</mat-slide-toggle>
        </div>
      </div>

      <div class="loader-wrap" *ngIf="isLoading">
        <mat-spinner [diameter]="100"></mat-spinner>
      </div>

      <div class="sub-table-wrap">
        <table
          [matSortActive]="pageColumn"
          [matSortDirection]="pageOrderBy"
          (matSortChange)="sortListener($event)"
          *ngIf="!isLoading"
          mat-table
          [dataSource]="activeDataSource"
          matSort
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                saAutomatedSelector="billing_order_table__select_all"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef><i class="material-icons error-icon-white">edit</i></th>
            <td mat-cell *matCellDef="let order">
              <span class="edit-icon-wrap" matTooltip="{{ checkForApprove(order) }}"
                ><i class="material-icons error-icon" *ngIf="checkForApprove(order) !== ''">edit</i></span
              >
              <span matTooltip="{{ checkForEditableOrder(order) }}">
                <i class="material-icons error-icon-gray" *ngIf="!isLmoAccount && checkForEditableOrder(order) !== ''"
                  >lock</i
                >
              </span>
              <span matTooltip="{{ checkForMissing(order) }}"
                ><img
                  src="../../../assets/icons/warn-orange.svg"
                  alt="Missing"
                  *ngIf="order.bolNumber === '' || !order.bolNumber"
              /></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="loadNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Load #, Driver and Truck</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3 class="blue-text">
                  {{ helperService.convertToShaleAppsLoadNumberWithFlag(order.id) }}
                  <span *ngIf="checkForPushBack(order)" class="pushed-back-span">Pushed Back</span>
                  <span *ngIf="order.dataMatched" class="pushed-back-span matched-tag">Data Matched</span>
                  <span *ngIf="order.fastTracked" class="pushed-back-span matched-tag">Fast Tracked</span>
                  <span
                    *ngIf="getTooltipData(order)"
                    class="pushed-back-span matched-tag"
                    placement="top"
                    [tooltip]="getTooltipData(order)"
                    >Not Fast Tracked</span
                  >
                  <span
                    class="pushed-back-span"
                    *ngIf="order.duplicatedBolOrderIds && order.duplicatedBolOrderIds.length"
                    >Duplicate BOL
                  </span>
                  <span *ngIf="checkIfReceivedByCustomer(order)" class="pushed-back-span gray-tag"
                    >Not Sent by API</span
                  >
                </h3>
                <h3>{{ order?.user?.name }}<span *ngIf="order.truck">,</span> {{ order?.truck?.name }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3><span *ngIf="!order.invoiceNumber?.startsWith('SA')">SA</span>{{ order.invoiceNumber }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="vendor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3>{{ order.vendor?.name }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loader</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3>{{ order.mine?.site?.name }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dispatchTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dispatched Date</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3>{{ datePipe.transform(order.dispatchTimestamp, 'shortDate') || '--' }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="mesh">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payload</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3>{{ order.mesh?.type }} Mesh</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actualLoadWeight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Load Weight</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3 class="center-aligned">{{ getLoadWeight(order) }}</h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <h3>
                  {{ order.totalPrice / 100 | currency: 'USD':'$ ' }}
                </h3>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Export">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let order; let i = index">
              <div class="button-row">
                <button
                  *ngIf="!exporting[i]"
                  mat-stroked-button
                  color="primary"
                  (click)="exportInvoiceDetails($event, order, i)"
                  saAutomatedSelector="billing_order_table__export"
                >
                  Export
                </button>
                <mat-spinner *ngIf="exporting[i]" [diameter]="25"></mat-spinner>
              </div></td
          ></ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let order">
              <div class="button-row">
                <ng-container *ngIf="order.canUnapprove; else cannotReject">
                  <button mat-stroked-button color="primary" (click)="rejectApprovedInvoice($event, order)">
                    Undo Approval
                  </button>
                </ng-container>
                <ng-template #cannotReject>
                  <div>--</div>
                </ng-template>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="setting">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let order">
              <div class="order-column">
                <img src="../../assets/icons/more.svg" alt="More" />
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let order; columns: displayedColumns"
            (click)="selectRow(order)"
            saAutomatedSelector="billing_order_table__order"
          ></tr>
        </table>
      </div>
      <mat-paginator
        [ngClass]="isLoading ? 'hidePage' : ''"
        (page)="fetchPageData($event)"
        [length]="this.activeDataSource.data.length"
        pageSize="100"
        [pageSizeOptions]="[100]"
        #paginator
      ></mat-paginator>
    </div>
  </div>
  <div class="footer-wrap" *ngIf="selection.selected.length && !detailView">
    <h3>{{ selection.selected.length }} Order<span *ngIf="selection.selected.length > 1">s</span> Selected</h3>
    <button
      mat-stroked-button
      *ngIf="urlRoute === 'readyForInvoicing' && !singleOrderPerInvoice"
      (click)="openTicketModal()"
      [disabled]="isLmoAccount || savingData"
      saAutomatedSelector="billing_order_table__batch_for_invoicing"
    >
      Batch for Invoicing
    </button>
    <button
      mat-stroked-button
      *ngIf="urlRoute === 'readyForInvoicing' && singleOrderPerInvoice"
      (click)="batchOrdersForInvoicing()"
      [disabled]="isLmoAccount || savingData"
      saAutomatedSelector="billing_order_table__send_for_invoicing"
    >
      Send for Invoicing
    </button>
    <button
      mat-stroked-button
      *ngIf="urlRoute === 'waitingForTicketApproval'"
      (click)="approveOrders()"
      [disabled]="!isLmoAccount || savingData"
      saAutomatedSelector="billing_order_table__approve_changes"
    >
      Approve Changes
    </button>
    <div class="button-wrap" *ngIf="urlRoute === 'waitingForInvoiceApproval'">
      <button
        mat-stroked-button
        (click)="approveBulkInvoices($event)"
        [disabled]="!isLmoAccount || savingData"
        saAutomatedSelector="billing_order_table__approve_invoices"
      >
        Approve Invoices
      </button>
      <button
        color="error"
        mat-stroked-button
        (click)="rejectBulkInvoices($event)"
        [disabled]="!isLmoAccount || savingData"
      >
        Reject Invoices
      </button>
    </div>
  </div>
</div>

<sa-completed-order-detail
  [@slideInOut]
  *ngIf="detailView"
  [selectedOrderId]="selectedOrderId"
  [selectedFracId]="selectedFracId"
  [loaderList]="(wellService.billingDetails$ | async)?.fracMines || []"
  [driverList]="driverList"
  [payloadList]="payloadList"
  [truckList]="truckList"
  (onMenuClose)="close($event)"
>
</sa-completed-order-detail>

<div class="back-layer-component" [@fadeInOut] *ngIf="detailView"></div>
