import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsApiService } from '../../services/api/constants.api.service';
import { UserService } from '../../services/user.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { ConfigValuesService } from '~services/config-values.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DistributionCenterApiService } from '~services/api/distribution-center.api.service';

export interface StorageCreationComponentData {
  createdByAPI?: boolean;
  storage?: any;
  constraint?: string;
}

@Component({
  selector: 'sa-dc-storage-creation',
  templateUrl: './dc-storage-creation.component.html',
  styleUrls: ['./dc-storage-creation.component.scss'],
})
export class DistributionCenterStorageCreationComponent {
  public isHalAccount = false;
  storage;
  storages;
  meshTypes;
  materialNumbers;
  storageTypes;
  storageForm: FormGroup;
  submitting = false;
  compareFn: ((o1: any, o2: any) => boolean) | null = this.compareByValue;
  materialCompareFn: ((o1: any, o2: any) => boolean) | null = this.compareByMaterial;

  boxSizes: any[] = [1, 2];
  canEdit = false;
  maxWeight: number;

  boxTechnologies$: Observable<{ id: number; name: string }[]>;

  constructor(
    public dialogRef: MatDialogRef<DistributionCenterStorageCreationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: StorageCreationComponentData,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private constantsApiService: ConstantsApiService,
    private distributionCenterApiService: DistributionCenterApiService,
    private errorHandler: ErrorHandlingService,
    private userService: UserService,
    private configService: ConfigValuesService,
  ) {
    this.isHalAccount = this.userService.isHalAccount();
    this.canEdit = userService.canEditWell();
    this.constantsApiService.getStorageTypes().subscribe((storageTypes) => {
      this.storageTypes = storageTypes;
      this.checkAndBuildForm(data);
    });

    this.constantsApiService.getMeshTypes().subscribe((meshTypes) => {
      this.meshTypes = meshTypes.sort((a, b) => (a.preferredOrder < b.preferredOrder ? -1 : 1));
      this.checkAndBuildForm(data);
    });

    this.constantsApiService.getMaterialNumbers().subscribe((materialNumbers) => {
      this.materialNumbers = Object.entries(
        materialNumbers.reduce(function(result, elm) {
          (result[elm['meshName']] = result[elm['meshName']] || []).push(elm);
          return result;
        }, {}),
      );
      this.materialNumbers.sort((a, b) => (a.length && b.length ? a[0].localeCompare(b[0]) : 0));
      if (data && data.storage && data.storage.mesh) {
        this.materialNumbers = this.materialNumbers.filter((group) => group[0] === data.storage.mesh.type);
      }
      this.checkAndBuildForm(data);
    });

    this.boxTechnologies$ = this.distributionCenterApiService.getDistributionCenterBoxTechnologies();
  }

  private checkAndBuildForm(data: { createdByAPI?: boolean; storage?: any; constraint?: string }) {
    if (this.storageTypes && this.meshTypes && (this.materialNumbers || !this.data.createdByAPI)) {
      this.data.storage ? this.buildForm(data.storage) : this.buildForm();
    } else {
    }
  }

  isBox() {
    const value = this.storageForm.get('storageType').value;
    return value && value.name === 'Box';
  }

  getBox() {
    this.storageTypes.forEach((storage) => {
      if (storage.name === 'Box') {
        return storage;
      }
    });
    return null;
  }

  get meshName() {
    const meshValue = this.storageForm.get('mesh').value;
    return meshValue && meshValue.type;
  }

  buildForm(storage?) {
    this.configService.configs$.pipe(take(1)).subscribe((configs) => {
      let boxValue = 1;
      if (storage && storage.numberBoxes) {
        boxValue = storage.numberBoxes;
      }
      this.maxWeight = configs.silo_max;
      boxValue = this.boxSizes[boxValue - 1];
      this.storageForm = this.fb.group({
        name: [{ value: storage ? storage.name : null, disabled: !this.canEdit }, [Validators.required]],
        capacity: [
          {
            value: this.getCapacityForStorageType(),
            disabled: !this.canEdit,
          },
          [Validators.required, Validators.min(0), Validators.max(100000000)],
        ],
        storageType: [
          {
            value: storage
              ? storage.storageType
              : this.data.constraint
              ? this.data.constraint
              : this.data.createdByAPI
              ? this.storageTypes[1]
              : null,
            disabled: !this.canEdit || this.data.constraint,
          },
          [Validators.required],
        ],
        mesh: [
          {
            value: storage ? storage.mesh : null,
            disabled: !this.canEdit || storage || this.data.createdByAPI,
          },
          [Validators.required, Validators.min(0)],
        ],
        numberBoxes: [boxValue, [Validators.nullValidator]],
        loadWeight: [
          {
            value: storage ? storage.loadWeight : null,
            disabled: !this.canEdit,
          },
          [Validators.required, Validators.min(1), Validators.max(this.maxWeight)],
        ],
        boxTechnology: [storage ? { id: storage.boxTechnologyId } : null],
      });

      if (this.isHalAccount) {
        this.storageForm.addControl(
          'material',
          this.fb.control(
            {
              value: storage ? storage.materialNumber : '',
              disabled:
                !this.canEdit || (this.data.createdByAPI && !this.userService.hasPermission('material_manager')),
            },
            [Validators.required],
          ),
        );
        this.storageForm.addControl(
          'salesOrderLineItem',
          this.fb.control(
            {
              value: storage ? storage.salesOrderLineItem : '',
              disabled:
                storage && storage.salesOrderLineItem && storage.salesOrderLineItem !== '' ? true : !this.canEdit,
            },
            [Validators.required],
          ),
        );
        this.storageForm.get('material').valueChanges.subscribe((materialNumber) => {
          this.setMesh(materialNumber);
        });
      }
    });
  }

  private setMesh(materialNumber) {
    this.materialNumbers.forEach((materialGroup) => {
      const selectedMaterial = materialGroup[1].find((material) => material.materialNumber === materialNumber);
      if (selectedMaterial) {
        const selectedMesh = this.meshTypes.find((mesh) => mesh.id === selectedMaterial.meshId);
        this.storageForm.get('mesh').setValue(selectedMesh);
      }
    });
  }

  getCapacityForStorageType(): number {
    // Editing an existing storage
    if (this.data.storage) {
      // Handle box storages
      if (this.data.storage.storageType && this.data.storage.storageType.name === 'Box') {
        if (!this.data.storage.capacity || !this.data.storage.loadWeight) {
          return 0;
        }
        return Math.floor((this.data.storage.capacity / this.data.storage.loadWeight) * this.data.storage.numberBoxes);
      }
      // handle regular silos
      return this.data.storage.capacity || null;
    }
    // new storage
    return null;
  }

  submit() {
    if (this.storageForm.valid) {
      let numberOfBoxes;
      let boxTechnology;
      if (this.storageForm.controls['storageType'].value.id === 2) {
        numberOfBoxes = this.storageForm.controls['numberBoxes'].value;
        if (numberOfBoxes.value) {
          numberOfBoxes = numberOfBoxes.value;
        }
        boxTechnology = this.storageForm.get('boxTechnology').value.id;
        if (boxTechnology <= 0) {
          this.snackBar.open('Box Technology Required', null, {
            duration: 3000,
          });
          return;
        }
      }
      const requestBody = {
        name: this.storageForm.controls['name'].value.trim(),
        storageType: this.storageForm.controls['storageType'].value,
        mesh: this.storageForm.controls['mesh'].value,
        capacity: this.storageForm.controls['capacity'].value,
        numberBoxes: numberOfBoxes,
        boxTechnologyId: boxTechnology,
        loadWeight: this.storageForm.get('loadWeight').value,
      };

      if (this.isHalAccount) {
        requestBody['materialNumber'] = this.storageForm.get('material').value;
        requestBody['salesOrderLineItem'] = this.storageForm.get('salesOrderLineItem').value;
      }

      if (this.isBox()) {
        requestBody.capacity = (requestBody.capacity * requestBody.loadWeight) / requestBody.numberBoxes;
      }

      this.submitting = true;
      if (this.data.storage) {
        this.distributionCenterApiService.updateDistributionCenterStorage(this.data.storage.id, requestBody).subscribe(
          (resp) => {
            this.submitting = false;
            this.snackBar.open('Storage successfully edited', null, {
              duration: 5000,
            });

            this.dialogRef.close(resp);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      } else {
        this.submitting = false;
        this.distributionCenterApiService.createDistributionCenterStorage(requestBody).subscribe(
          (resp) => {
            this.snackBar.open('Storage successfully created', null, {
              duration: 5000,
            });
            this.dialogRef.close(resp);
          },
          (err) => {
            this.submitting = false;
            this.errorHandler.showError(err, 5000);
          },
        );
      }
    }
  }

  compareByValue(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  compareByMaterial(o1, o2) {
    return o1 && o2 && o1 === o2;
  }
}
